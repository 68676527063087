//客户授权信息
export default {
    enterprise_name: "客户名称",
    enterprise_no: "客户编号",
    organization_code: "组织机构代码证",
    lglNm: "法定代表人姓名",
    lglIdCard: "法定代表人身份证号码",

    lglctfAuthorizationRgstDt: "法定代表人授权起始日",
    lglctfAuthorizationExpDt: "法定代表人授权到期日",
    authorization_file:"上传法人授权协议影印件",
}
