//融单开立表单
export default {
    extraction: "开立人*",
    open_credit_code: "开立人统一社会信用代码*",
    receive: "融单初始持有人*",
    receive_credit_code: "融单初始持有人社会信用代码*",
    mx_amount:'额度*',
    usable_amount: "可用额度*",
    amount: "开具金额*",
    sign_agreement: "我同意",
    open_day: "开立日*",
    payment_day: "承诺付款日*",
    Upload_contracts: "上传合同*",
    Upload_invoice: "上传发票*",
    Upload_dispatch: "上传发货单",
    Upload_shipping: "上传运输单",
    synthesis_rate: "预计融资息费率*",
    remarks: "备注",
    add_days_number: "增加天数",
    receivable:"发票总金额*",
    //静态资源 不用在数据库里面加这些字段
    // usable_amount_capital: "大写额度*",
    sign_agreement2: "《融单开立协议》*",
    view_agreement: "查看融单开立协议",
    view_case: "查看案例",
    check_opinion: "审核意见",
    amount_capital: "大写金额*",
    guarantor:"开立保证人",
    guaranteed_payment_day:"保证到期日",
}