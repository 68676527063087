//应收帐管理-发票信息
export default {
  customer_name: "客户名称",
  invoice_number: "发票号",
  billing_date: "开票日期",
  purchaser: "购买方",
  seller: "销售方",
  amount: "发票金额",
  entry_time: "录入时间",
  receivables_transfer: "应收账款转让对价款",
  trade_contract_no: "对应的贸易合同号",
  notes: "备注"
}

