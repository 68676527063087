import React from 'react';
import {connect} from 'react-redux'
import {logoutAction} from '../state/actions'

class Logout extends React.Component {

  componentDidMount() {
    this.props.logoutAction(this.props.auth);
  };

  render() {
    return <h1>退出登录</h1>
  }
}

export default connect(
  state => ({auth: state.auth}),
  {logoutAction}
)(Logout);
