import React, { useState } from 'react'
import {
  Container,
  CssBaseline,
  Typography,
} from '@material-ui/core';
import { Home as AppHome, OfflineBolt } from '@material-ui/icons';
import { connect } from 'react-redux';
import Routers1 from './sub-routers'
import { BASE } from './common'
import { bars } from '../components'
// import { BarMenu, bars } from '../components'
import { NavList, RouteList, reduxLink } from '../config/reusable'
import { DEFAULT_LOGIN_PAGE } from '../config/constants'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { loginInfos } from '../components/numToText'

const useStyles = makeStyles(theme => ({
  wrap: {
    flexFlow: 'row wrap',
  },
}));

function RiskManagement(props) {
  const classes = useStyles();
  const [logout, setLogout] = useState(false);
  const loginInfo = loginInfos();
  var url = "";
  if(loginInfo.organization !== undefined && loginInfo.organization.name === "融单平台"){
    url = '/融单/全部融单';
  }else{
    url = '/融单/融资申请审核';
  }
  const [toRedirect, setToRedirect] = useState(url);

  const initRouters = () => {
    try {
      const loginInfo = loginInfos();
      const roleId = loginInfo.roles[0].id;
      const initRouters1 = Routers1.filter((item) => {
        if(loginInfo.organization !== undefined && loginInfo.organization.name === "融单平台"){
          if (loginInfo.user.isAdmin && roleId === 10) {//管理员
            setToRedirect('/系统管理/口令更改')
            return !(item.title === '额度审批' || item.title === '融单' || item.title === '额度管理' || item.title === '利率管理' || item.title === '授权审批' 
              || item.title === '外部融资' || item.title === '银行预审') ? item : null
          } else if (roleId === 19) {//一级管理员
            setToRedirect('/系统管理/口令更改')
            return !(item.title === '额度审批' || item.title === '融单' || item.title === '额度管理' || item.title === '利率管理' || item.title === '授权审批' 
            || item.title === '外部融资' || item.title === '银行预审' || item.title === '系统日志') ? item : null
          } else if (roleId === 20) {//二级管理员
            setToRedirect('/系统管理/口令更改')
            return !(item.title === '额度审批' || item.title === '融单' || item.title === '额度管理' || item.title === '利率管理' || item.title === '授权审批' 
              || item.title === '用户管理' || item.title === '外部融资' || item.title === '银行预审' ) ? item : null
          } else if (roleId === 1 || roleId === 2 || roleId === 16) {//业务部
            return !(item.title === '用户管理' || item.title === '系统管理' || item.title === '授权审批' || item.title === '系统日志') ? item : null
          } else if (roleId === 3 || roleId === 4) {//风险部
            return !(item.title === '用户管理' || item.title === '系统管理' || item.title === '授权审批' || item.title === '系统日志') ? item : null
          } else if (roleId === 5 || roleId === 6 || roleId === 17) {//财务部
            return !(item.title === '用户管理' || item.title === '系统管理' || item.title === '额度审批' || item.title === '授权审批' || item.title === '系统日志') ? item : null
          } else if (roleId === 8) {//总经理
            return !(item.title === '用户管理' || item.title === '系统管理' || item.title === '系统日志') ? item : null
          } else if (roleId === 9) {//综合部经办人
            return !(item.title === '利率管理' || item.title === '用户管理' || item.title === '系统管理' || item.title === '额度审批' || item.title === '授权审批' || item.title === '系统日志') ? item : null
          } else if (roleId === 14) {//董事长
            return !(item.title === '利率管理' || item.title === '用户管理' || item.title === '系统管理' || item.title === '系统日志') ? item : null
          }  else if (roleId === 15) {//法务
            return !(item.title === '利率管理' || item.title === '用户管理' || item.title === '系统管理' || item.title === '额度审批'|| item.title === '授权审批' || item.title === '系统日志') ? item : null
          } else if (roleId === 18) {//中信预审
            return !(item.title === '利率管理' || item.title === '用户管理' || item.title === '系统管理' || item.title === '额度审批'|| item.title === '授权审批' 
                  || item.title === '额度管理' || item.title === '融单'      || item.title === '保后管理'|| item.title === '应收账款管理'   
                  || item.title === '还款'     || item.title === '运营管理' || item.title === '合同管理' || item.title === '流程管理' || item.title === '系统日志') ? item : null
          } else if(roleId === 21){//光大预审
            return!( item.title === '利率管理' || item.title === '用户管理' || item.title === '系统管理' || item.title === '额度审批'|| item.title === '授权审批' 
                  || item.title === '额度管理' || item.title === '融单'    || item.title === '保后管理'|| item.title === '应收账款管理'   
                  || item.title === '还款'     || item.title === '运营管理' || item.title === '合同管理' || item.title === '流程管理' || item.title === '系统日志'
                  ) ? item : null
          }else if(roleId === 22){//北京银行预审
            return!( item.title === '利率管理' || item.title === '用户管理' || item.title === '系统管理' || item.title === '额度审批'|| item.title === '授权审批' 
                  || item.title === '额度管理' || item.title === '融单'      || item.title === '保后管理'|| item.title === '应收账款管理'   
                  || item.title === '还款'     || item.title === '运营管理' || item.title === '合同管理' || item.title === '流程管理' || item.title === '系统日志'
                  ) ? item : null
          } else {
            return null
          }
        }else{
          if (loginInfo.user.isAdmin && roleId === 10) {//超级管理员
            setToRedirect('/用户管理/用户管理')
            return (item.title === '用户管理' || item.title === '融单') ? item : null
          }else{
            return (item.title === '融单') ? item : null
          }
        }
      })
      return initRouters1
    } catch (error) {
      setLogout(true);
    }

  }
  const [Routers] = useState(initRouters);
  if (logout) {
    return <Redirect to={DEFAULT_LOGIN_PAGE} />
  }
  return (
    <>
      <CssBaseline />
      <Container fixed style={{ maxWidth: '100%' }}>
        <bars.Bar2>
          <Typography color="inherit" variant="caption">
            {reduxLink({ icon: OfflineBolt, path: BASE, title: '风险管理' })}
          </Typography>
          <Typography color="inherit" variant="caption">
            {/*<Link href="/home" color="inherit" variant="h6"><AppHome/></Link>*/}
            {reduxLink({ icon: AppHome, path: '/home', title: '' })}
          </Typography>
          {/* <BarMenu /> */}
          <NavList className={classes.wrap} navs={Routers} />
        </bars.Bar2>
        <div>
          <RouteList
            routes={Routers}
            redirect={{ from: BASE, to: `${BASE}${toRedirect}` }}
          />
        </div>
      </Container>
    </>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(RiskManagement)
