import React, { Component } from 'react';
import Select from 'react-select';
import {
    TextField,
    Button,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Select as Select2,
    InputLabel,
    MenuItem,
    CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Formik } from 'formik';
import { C111Formik } from './formikValidate';
import FormikError from './formikError';
import { fetching } from '../../config/utils';
import { formatDate } from '../../components/date'

const yesornoList = ['是', '否'];
const registerPlaceList = ['境内', '境外'];
const enterpriseScaleList = ['大型', '中型', '小型', '微型'];
const enterpriseTypeList = ['合资', '独资', '国有', '私营', '全民所有制', '集体所有制', '股份制', '有限责任公司']
const countryPlaceList = ['中国大陆', '中国香港'];
const relationshipList = ['其他']
const customerTypesList = ['一般公司']
const accountingSystemList = ['新会计准则', '旧会计准则']

const loadingStyle = {
    position: "absolute",
    color: green[500],
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -17,
}
const useStyles = (theme => ({
    formControl: {
        marginTop: '16px',
        marginBottom: '8px',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    width: '100%',
    label: {
        textAlign: 'left',
    },
    radio: {
        flexDirection: 'row',
    },
}));

const styles = {
    width: '100%',
    margin: '16px 0 8px 0'
}

class Form1 extends Component {
    state = {
        form: this.getInitState(),
        selectCustomerList: [],
        loading: false,
    }
    getInitState() {
        const { exercise = {} } = this.props;
        const form2 = {
            select_customer: '',
            enterprise_name: "",
            enterprise_no: "",
            organization_code: "",
            issue_unit: "",
            issue_date: new Date(),
            validity_period: "",
            yearly_check: "是",
            organization_type: "企业法人",
            register_place: "境内",
            country_register_place: "中国大陆",
            country_businessr_place: "中国大陆",
            register_address: "",
            industry_strategy: "",
            industry_code: "",
            national_standard_industry: "",
            customer_relationship_type: "其他",
            customer_types: "一般公司",
            accounting_system: "新会计准则",
            enterprise_type: "有限责任公司",
            enterprise_scale: "中型",
            enterprise_employees: "",
        }
        if (exercise.save === '1') {
            form2.select_customer = ''
        } else {
            form2.select_customer = { value: exercise.enterprise_name, label: exercise.enterprise_name, enterprise_no: exercise.enterprise_no }
        }
        return { ...form2, ...exercise }
    }
    async componentDidMount() {
        if (!this.state.form.enterprise_no) {
            this.queryEnterpriseInfo();
        }
    }

    queryEnterpriseInfo = () => {
        fetching('/msapi/organizations', {
            method: 'GET',
        }).then(res => {
            if (res) {
                try {
                    let datas = res.filter((item) => {
                        item.value = item.name;
                        item.label = item.name;
                        return (item.id !== 1) ? item : null;
                    })
                    this.setState({ selectCustomerList: datas });
                } catch (error) {
                }
            } else {
            }
        })
    }
    //选择客户
    handleChange2 = (name, selectedOption) => {
        let form = { ...this.state.form }
        form.enterprise_no = selectedOption.enterprise_no
        form.organization_code = selectedOption.credit_no
        this.setState({ form: { ...form } })
        return selectedOption;
    }
    uploadChange = (file) => {

    };
    conversion = (date) => {
        if (date instanceof Object && date.getTime()) {
            return formatDate(date, 'yyyy/MM/dd');
        }
        return date
    }
    //保存
    handleSave = (values) => {
        this.setState({ loading: true })
        const { auth } = this.props;
        const { save, id } = this.state.form;
        let ids = (save === '2' || save === '3') ? id : '';
        let method = (save === '2' || save === '3') ? 'POST' : 'POST';
        values.enterprise_no = this.state.form.enterprise_no || ''
        values.enterprise_name = values.select_customer.value || ''
        values.organization_code = this.state.form.organization_code
        values.issue_date = this.conversion(values.issue_date)

        values.auth = auth;//用户信息
        const formData = {};
        const excludes = ['createdAt', 'updatedAt', 'checkOpen', 'role_name', 'status', 'id', 'Uploads', 'select_customer', 'tableData', 'save', 'DialogTitle', 'status2', 'open', 'User', 'Role', 'Status', 'Organization', 'UserId', 'OrganizationId', 'StatusId', 'RoleId'];
        for (let name in values) {
            if (!excludes.includes(name)) {
                formData[name]= values[name];
            }
        }
        formData.id = values.id

        // POST
        fetching(`/msapi/customer_info/save/${ids}`, {
		method:"post",
            body: JSON.stringify(formData),
        }).then((data) => {
            this.setState({ loading: false })
            try {
                if(data){
                    if(data.msg){
                        alert(ids ? `编辑失败，${data.msg}` : `新增失败，${data.msg}` )
                        return;
                    }
                    this.props.onSubmit({
                        ...this.state.form,
                    })
                }else {
                    alert(ids ? `编辑失败` : `新增失败` )
                }

            } catch (error) {}
        })
    };

    render() {
        const { exercise = {}, formStatic, classes } = this.props;
        const { form, selectCustomerList } = this.state;
        return (
            <Formik
                //初始化数据
                initialValues={{
                    ...form
                }}
                //表单验证
                validate={(values) => {
                    return C111Formik(values, formStatic);
                }}
                //验证提交
                onSubmit={(values) => {
                    this.handleSave(values);
                }}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                        <form method="post" encType="multipart/form-data">
                            <Grid item>
                                <div style={{ margin: '5px 0' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_customer}</span>
                                    <Select
                                        isSearchable
                                        value={values.select_customer}
                                        onChange={e => setFieldValue('select_customer', this.handleChange2.bind(this, 'select_customer', e)())}
                                        options={selectCustomerList}
                                        isDisabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'select_customer'} />
                                </div>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.enterprise_no}
                                        value={form.enterprise_no}
                                        name='enterprise_no'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.organization_code}
                                        value={form.organization_code}
                                        name='organization_code'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    {/* <FormikError touched={touched} errors={errors} name={'organization_code'} /> */}
                                </Grid>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.issue_unit}
                                        value={values.issue_unit}
                                        name='issue_unit'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'issue_unit'} />
                                </Grid>

                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                                    <Grid item xs={3}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label={formStatic.issue_date}
                                            disabled={exercise.save === '3'}
                                            value={values.issue_date}
                                            onChange={e => setFieldValue('issue_date', e)}
                                            onBlur={handleBlur}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        <FormikError touched={touched} errors={errors} name={'issue_date'} />
                                    </Grid>
                                </MuiPickersUtilsProvider>

                                <Grid item xs={3}>
                                    <FormControl component="fieldset" style={styles} className={classes.radio}>
                                        <FormLabel component="legend">{formStatic.yearly_check}</FormLabel>
                                        <RadioGroup className={classes.radio} style={{ flexDirection: 'row' }} aria-label="gender" name='yearly_check' value={values.yearly_check}
                                            onChange={e => setFieldValue('yearly_check', e.target.value)}>
                                            {yesornoList.map(item => {
                                                return (
                                                    <FormControlLabel
                                                        key={item}
                                                        name={item}
                                                        value={item}
                                                        disabled={exercise.save === '3'}
                                                        control={<Radio />}
                                                        label={item} />
                                                )
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'yearly_check'} />
                                </Grid>

                            </Grid>

                            <Grid container justify="space-between">
                                <Grid item xs={3}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.register_place}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.register_place}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('register_place', e.target.value)}>
                                            >
                      {registerPlaceList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'register_place'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.country_register_place}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.country_register_place}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('country_register_place', e.target.value)}>
                                            >
                      {countryPlaceList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'country_register_place'} />
                                </Grid>

                                <Grid item xs={3}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.country_businessr_place}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.country_businessr_place}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('country_businessr_place', e.target.value)}>
                                            >
                      {countryPlaceList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'country_businessr_place'} />
                                </Grid>
                            </Grid>

                            <Grid container justify="space-between">
                                <TextField
                                    label={formStatic.register_address}
                                    value={values.register_address}
                                    name='register_address'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    margin='normal'
                                    inputProps={{ maxLength: 50 }}
                                    fullWidth
                                    disabled={exercise.save === '3'}
                                />
                                <FormikError touched={touched} errors={errors} name={'register_address'} />
                            </Grid>

                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.industry_strategy}
                                        value={values.industry_strategy}
                                        name='industry_strategy'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'industry_strategy'} />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.industry_code}
                                        value={values.industry_code}
                                        name='industry_code'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'industry_code'} />
                                </Grid>
                            </Grid>
                            <Grid container justify="space-between">
                                <TextField
                                    label={formStatic.national_standard_industry}
                                    value={values.national_standard_industry}
                                    name='national_standard_industry'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    margin='normal'
                                    inputProps={{ maxLength: 50 }}
                                    fullWidth
                                    disabled={exercise.save === '3'}
                                />
                                <FormikError touched={touched} errors={errors} name={'national_standard_industry'} />
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={3}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.customer_relationship_type}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.customer_relationship_type}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('customer_relationship_type', e.target.value)}>
                                            >
                      {relationshipList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'customer_relationship_type'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.customer_types}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.customer_types}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('customer_types', e.target.value)}>
                                            >
                      {customerTypesList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'customer_types'} />
                                </Grid>

                                <Grid item xs={3}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.accounting_system}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.accounting_system}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('accounting_system', e.target.value)}>
                                            >
                      {accountingSystemList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'accounting_system'} />
                                </Grid>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={3}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.enterprise_type}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.enterprise_type}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('enterprise_type', e.target.value)}>
                                            >
                      {enterpriseTypeList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'enterprise_type'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.enterprise_scale}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.enterprise_scale}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('enterprise_scale', e.target.value)}>
                                            >
                      {enterpriseScaleList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'enterprise_scale'} />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.enterprise_employees}
                                        value={values.enterprise_employees}
                                        name='enterprise_employees'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'enterprise_employees'} />
                                </Grid>
                            </Grid>
                            <div style={{ margin: '20px 0', position: 'relative' }}>
                                {exercise.save !== '3' &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={this.state.loading}
                                    >
                                        {formStatic.save}
                                        {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
                                    </Button>
                                }
                            </div>
                        </form>
                    )}
            />
        );
    }
}

export default withStyles(useStyles)(Form1);
