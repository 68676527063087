import React, { useState } from 'react'
import {
  Container,
  CssBaseline,
  Typography,
  // Link,
} from '@material-ui/core';
import { connect } from 'react-redux';
import {Home as AppHome, SupervisorAccount} from '@material-ui/icons';
import { Redirect } from 'react-router-dom'
import Routers from './sub-routers'
import {BASE} from './common'
import {bars} from '../components'
import { DEFAULT_LOGIN_PAGE } from '../config/constants'
import {NavList, RouteList, reduxLink} from '../config/reusable'
import { loginInfos } from '../components/numToText'
function CustomerInfo () {
  const logoutInfo = () => {
    let data = loginInfos()
    return data.id ? false : true
  }
  const [logout] = useState(logoutInfo);
  if (logout) {
    return <Redirect to={DEFAULT_LOGIN_PAGE} />
  }
  return (
    <>
    <CssBaseline />
    <Container fixed style={{maxWidth:'100%'}}>
      <bars.Bar2>
        <Typography>
          {/*<Link href={BASE} color="inherit" variant="h6"><SupervisorAccount/>客戶信息</Link>*/}
          {reduxLink({icon:SupervisorAccount, path:BASE, title: '客戶管理'})}
        </Typography>
        <Typography>
          {reduxLink({icon:AppHome, path:'/home', title: ''})}
          {/*<Link href="/home" color="inherit" variant="h6"><AppHome/></Link>*/}
        </Typography>
        {/* <BarMenu /> */}
        <NavList navs={Routers}/>
      </bars.Bar2>
      <div>
        <RouteList
          routes={Routers}
          redirect={{from: BASE, to: `${BASE}/客户基本信息/客户概况`}}
        />
      </div>
    </Container>
    </>
  )
}


const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(CustomerInfo)
