import React, { Component } from 'react'
// import { fetching } from '../../config/utils';
import {
  TextField,
  Button,
  Grid,
} from '@material-ui/core'
import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { r14_1_2 } from '../formStatic';
import { Formik } from 'formik';
import FormikError from './formikError';
import { C1412Formik } from './formikValidate';
import { formatDate } from '../../components/date';

const { object } = require('yup');
const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }
}));
class C141Form extends Component {
  state = {
    form: this.getInitState(),
    formStatic: { ...r14_1_2 },//静态资源
  }
  getInitState() {
    const time = new Date();
    const form = {
      customer_name: "",//客户名称
      contract_number: "",//合同编号
      signing_time: time,//签订时间
      party_a: "",//甲方
      party_b: "",//乙方
      contract_name: "",//合同名称
      contract_validity: time,//合同有效期限
      entry_time: time,//录入时间
      receivables_transfer: "",//应收账款转让对价款
      pledge_contract_no: "",//质押合同号
      notes: ""//备注

    }
    return form
  }
  handleChange = (e) => {
    const { form } = this.state
    const { name, value } = e.target;
    form[name] = value;
    this.setState({ form: { ...form } })

  }
  //保存 提交
  handleSubmit = (values) => {
    const form = {...values};
    if (form.contract_validity instanceof Object ) {
      form.contract_validity = formatDate(form.contract_validity, 'yyyy/MM/dd');
    }
    if (form.signing_time instanceof Object ) {
      form.signing_time = formatDate(form.signing_time, 'yyyy/MM/dd');
    }
    if (form.entry_time instanceof Object ) {
      form.entry_time = formatDate(form.entry_time, 'yyyy/MM/dd');
    }

  }
  handleReset = () => {
    let form2 = this.getInitState();
    this.setState({ form: form2 })
  }

  render() {
    const { formStatic, form } = this.state;
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        validationSchema={object().shape(C1412Formik(formStatic))}
        //验证提交
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
            <form style={{maxWidth:'800px',margin:'0 auto'}}>
              <h4>贸易合同录入</h4>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.customer_name}
                    value={values.customer_name}
                    name='customer_name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                  />
                  <FormikError touched={touched} errors={errors} name={'customer_name'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.contract_name}
                    value={values.contract_name}
                    name='contract_name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                  />
                  <FormikError touched={touched} errors={errors} name={'contract_name'} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                  <Grid container justify="space-between">
                    <Grid item xs={5}>
                      <TextField
                        label={formStatic.contract_number}
                        value={values.contract_number}
                        name='contract_number'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin='normal'
                        fullWidth
                      />
                      <FormikError touched={touched} errors={errors} name={'contract_number'} />
                    </Grid>
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        style={{ width: '100%' }}
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline"
                        label={formStatic.contract_validity}
                        value={values.contract_validity}
                        onChange={e => setFieldValue('contract_validity', e)}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'contract_validity'} />
                    </Grid>


                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                  <Grid container justify="space-between">
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        style={{ width: '100%' }}
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline"
                        label={formStatic.signing_time}
                        value={values.signing_time}
                        onChange={e => setFieldValue('signing_time', e)}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'signing_time'} />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        label={formStatic.receivables_transfer}
                        value={values.receivables_transfer}
                        name='receivables_transfer'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin='normal'
                        fullWidth
                      />
                      <FormikError touched={touched} errors={errors} name={'receivables_transfer'} />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.party_a}
                    value={values.party_a}
                    name='party_a'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                  />
                  <FormikError touched={touched} errors={errors} name={'party_a'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.pledge_contract_no}
                    value={values.pledge_contract_no}
                    name='pledge_contract_no'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                  />
                  <FormikError touched={touched} errors={errors} name={'pledge_contract_no'} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                  <Grid container justify="space-between">
                    <Grid item xs={5}>
                      <TextField
                        label={formStatic.party_b}
                        value={values.party_b}
                        name='party_b'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin='normal'
                        fullWidth
                      />
                      <FormikError touched={touched} errors={errors} name={'party_b'} />
                    </Grid>
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        style={{ width: '100%' }}
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline"
                        label={formStatic.entry_time}
                        value={values.entry_time}
                        onChange={e => setFieldValue('entry_time', e)}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'entry_time'} />
                    </Grid>

                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={formStatic.notes}
                  value={values.notes}
                  name='notes'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                />
              </Grid>
              <div style={{ padding: '20px 0' }}>
                <Button
                  style={{ width: '100px', marginRight: '10px' }}
                  color='primary'
                  variant='contained'
                  onClick={handleSubmit}
                >
                  {'保存'}
                </Button>
                {/* <Button
                  style={{ width: '100px' }}
                  color='primary'
                  variant='contained'
                  onClick={this.handleReset}
                >
                  {'重置'}
                </Button> */}
              </div>
            </form>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(C141Form);



