import React, { Component } from 'react'
import { Formik } from 'formik';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { fetching } from '../../config/utils';
import { check } from './check'
import {
  TextField,
  Button,
  Grid,
  Select,
  FormControl,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputLabel
} from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import FormikError from './formikError';
import SimpleExpansionPanel from './Panels'
import { issueFormik } from './formikValidate';
import { DEFAULT_CONTRCT_URL , DEFAULT_PAYMENT_URL} from '../../config/constants';
// import { numberParseChina } from '../numToText';

import TreeView from '@material-ui/lab/TreeView';//202009630
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';//202009630
import ChevronRightIcon from '@material-ui/icons/ChevronRight';//202009630
import TreeItem from '@material-ui/lab/TreeItem';//202009630

import {
  LocationOn,
  Attachment,
  MonetizationOn,
  AccountTree
} from '@material-ui/icons'//20200930

const { object } = require('yup');

const styles = {
  textAlign: 'left',
  margin: '0 10px',
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
class YSForm extends Component {
  state = {
    form: this.getInitState(),
    form2: this.getInitState2(),
    open2: false,
    imgUrl: '',
    times: null,
    signatureMsg: '',
    second: 60,//验证码倒计时
    secondNumber: '',//验证码is
    userList: [
      { id: 1, name: '退回上一级' },
      { id: 2, name: '退回经办人' },
    ],
    distributeList: [        //对接的银行列表
      { id: 1, name: '善美保理' },
      { id: 2, name: '招商银行' },
      { id: 3, name: 'aaa' },
      { id: 4, name: 'bbb' },
      { id: 4, name: 'ccc' },
    ],
    auth: this.props.auth || {}
  }
  getInitState() {
    const { exercise, auth } = this.props
    exercise.StatusId = exercise.status_id || exercise.statusId

    // const sign_agreement = exercise.rolseId === '2' ? '1': '';
    let sign_agreement = '1';
    let phone = auth.phone ? auth.phone : '15719478614';
    let isverification_code = false //判断是否验证
    let KLStart = true;
    let rolesId = auth.roles ? auth.roles[0].id : 0;
    if (exercise.checkStatus === '3' && exercise.table === 'm2-4') {
      KLStart = false;
      isverification_code = true
    } else if (exercise.checkStatus === '3' && exercise.table === 'm7-5') {
       KLStart = false;
       isverification_code = true
    } else if (exercise.checkStatus === '5' && (exercise.table === 'm3-1-1')) {
      isverification_code = exercise.mxf_number ? false : true;
      KLStart = exercise.open_day ? false : true;
    } else if ((exercise.table === 'r6-3-1' || exercise.table === 'r6-2-1' || exercise.table === 'r14-6-1') && rolesId === 9) {
      KLStart = false;
    } else if (exercise.table === 'm3-2-2' && exercise.StatusId === 5) {
      KLStart = false;
      isverification_code = true
    }

    // if (exercise.checkStatus === '6') {
    //  isverification_code = true
    // }
    const form = {
      opinion_list: [],
      ...exercise,
      sign_agreement, //签订协议
      check_opinion: '',
      verification_code: '',
      isverification_code,
      form_no: exercise.checkStatus || '3',
      KLStart,
      phone,
      returnBy: '退回上一级',
      distribute: '善美保理',
      repayment_ways: "中金支付", //20210609 新增弹框展示字段
      cmb_rz_type: '',

    }

    return form
  }
  getInitState2() {
    const { exercise } = this.props
    let sign_agreement = '';
    if (exercise.table === 'm2-4' || exercise.table === 'r6-2-1' || exercise.table === 'm3-1-1' || exercise.table === 'm7-3') {

      sign_agreement = exercise.mxc_number ? check.sign_flow : check.sign_agreement;
    }
    else if (exercise.table === 'm3-1-9' || exercise.table === 'r6-4-1') {
      sign_agreement = check.sign_flow
    }
    else if (exercise.table === 'm3-2-2' || exercise.table === 'r6-3-1') {
      sign_agreement = check.sign_financing
    }
    return { ...check, sign_agreement };
  }

  componentWillUnmount() {
    clearInterval(this.state.times);
  }
  //  //签章
  sign_agreements = () => {

    const { fdd_file_name, fdd_yssq_name, fdd_ysqr_name, fdd_ystz_name, fdd_ysxxb_name, fdd_yshz_name, KLStart, isverification_code,
      table, open_day, StatusId = '', fdd_file_path, open_credit_code, verification_code } = this.state.form;
    const { auth, form, signatureMsg, secondNumber } = this.state;
    const rolesId = auth.roles ? auth.roles[0].id : '';
    let Url = '';
    let body = {};
    if (table === 'm7-5') {//应收开具复核
      Url = '/dzqz/extsignAuto/YSA';//应收合同-供应商签章
      body = {
        credit_no: form.open_credit_code,//开立方社会信用代码
        fdd_file_name: fdd_file_name,//开立合同编号
        fdd_yssq_name: fdd_yssq_name,
        fdd_ysqr_name: fdd_ysqr_name,
        fdd_ystz_name: fdd_ystz_name,
        fdd_ysxxb_name: fdd_ysxxb_name,
        fdd_yshz_name: fdd_yshz_name,
        //签章重复问题处理--20201102
        type: 4,//1-开立；2-流转；3-融资；4-应收
        oid: this.state.form.id,//开立/流转/融资id
      }
    } else if (table === 'r14-6-1' && rolesId === 9) {//应收审核
      Url = '/dzqz/extsignAuto/YSB';//应收合同-平台签章
      body = {
        fdd_file_name: fdd_file_name,//应收合同编号
        fdd_yssq_name: fdd_yssq_name,
        fdd_ysqr_name: fdd_ysqr_name,
        fdd_ystz_name: fdd_ystz_name,
        fdd_ysxxb_name: fdd_ysxxb_name,
        fdd_yshz_name: fdd_yshz_name,
        //签章重复问题处理--20201102
        type: 4,//1-开立；2-流转；3-融资；4-应收
        oid: this.state.form.id,//开立/流转/融资id
      }
    }
    //if (!KLStart && signatureMsg !== '正在签订中...') {
    if ( signatureMsg !== '正在签订中...') {
      if (Url === '') {
        this.setState({
          form: { ...form, KLStart: true },
          signatureMsg: '签章还在更新中...'
        })
      }
      else if (!isverification_code || rolesId === 9) {
        this.setState({
          signatureMsg: '正在签订中...',

        })
        //签章
        fetching(Url, {
          method: 'POST',
          body: JSON.stringify(body)
        }, 2).then(res => {
          if (res !== undefined) {
            if (res.code === 200) {
              window.open(res.data.viewpdf_url || fdd_file_path);
              this.setState({
                form: { ...form, KLStart: true },
                signatureMsg: '签订成功'
              })
            } else {
              this.setState({
                signatureMsg: res.msg || '签章失败'
              })
            }
          }

        })
      }
      else if (isverification_code && secondNumber !== '' && verification_code === secondNumber) {
        this.setState({
          signatureMsg: '正在签订中...',

        })
        //签章
        fetching(Url, {
          method: 'POST',
          body: JSON.stringify(body)
        }, 2).then(res => {
          if (res !== undefined) {
            if (res.code === 200) {
              window.open(res.data.viewpdf_url || fdd_file_path);
              this.setState({
                form: { ...form, KLStart: true },
                signatureMsg: '签订成功'
              })
            } else {
              this.setState({
                signatureMsg: res.msg || '签章失败'
              })
            }
          }

        })
      } else if (isverification_code) {
        alert('验证码输入不正确');
      }


    }

  }

  handleFileChange = e => {
    this.setState({
      [e.target.name]: e.target.files[0],
    })
  };

  //查看协议
  viewAgreement = () => {
    // this.setState({ open2: !this.state.open2 })
    const { fdd_file_path = '', mxf_number } = this.state.form;
    if (fdd_file_path && fdd_file_path !== 'null') {
      window.open(fdd_file_path)
    } else {
      if (mxf_number) {
        window.open(DEFAULT_CONTRCT_URL + 'contract/template/rz.pdf')
      } else {
        window.open(DEFAULT_CONTRCT_URL + 'contract/template/ys.pdf')
      }
    }
  }
  viewyssq = () => {
    const { fdd_yssq_path = '' } = this.state.form
    if (fdd_yssq_path && fdd_yssq_path !== 'null') {
      window.open(fdd_yssq_path)
    }
  }

  viewysqr = () => {
    const { fdd_ysqr_path = '' } = this.state.form
    if (fdd_ysqr_path && fdd_ysqr_path !== 'null') {
      window.open(fdd_ysqr_path)
    }
  }

  viewystz = () => {
    const { fdd_ystz_path = '' } = this.state.form
    if (fdd_ystz_path && fdd_ystz_path !== 'null') {
      window.open(fdd_ystz_path)
    }
  }

  viewysxxb = () => {
    const { fdd_ysxxb_path = '' } = this.state.form
    if (fdd_ysxxb_path && fdd_ysxxb_path !== 'null') {
      window.open(fdd_ysxxb_path)
    }
  }

  viewyshz = () => {
    const { fdd_yshz_path = '' } = this.state.form
    if (fdd_yshz_path && fdd_yshz_path !== 'null') {
      window.open(fdd_yshz_path)
    }
  }


  //放款金额冻结
  frozenBalance = () => {
    //放款查找余额冻结余额 20210420
    const formFB = this.state.form;
    formFB.applicant = formFB.extraction;
    if(formFB.payment_type === "还款日不固定"){
      formFB.net_financing_amount = formFB.applyAmount
    }
    const body5 ={
    id: formFB.id,
    extraction:formFB.extraction,
    applicant: formFB.extraction,
    amount: formFB.amount,
    netFinancingAmount: formFB.net_financing_amount,
    mxNumber: formFB.receivableNo,
    mxfNumber: formFB.financingNo,
    url: window.location.href,
  }
    fetching('/loan/frozenBalance', {
      method: 'POST',
      body: JSON.stringify(body5)
    }).then(res => {
      if (res) {
        try {
          if(res.code===200){ //未冻结
              //表单提交调用
            let url = DEFAULT_PAYMENT_URL+"gateway/new/bidding_frozen";
            if(window.confirm("进入冻结页面,是否要冻结这笔给"+formFB.applicant+",融资金额为"+formFB.applyAmount+"元,冻结融资净额为"+formFB.net_financing_amount+"元的这笔款项?")){
              //this. postExcelFile(res.data,'http:192.168.1.5:8088/gateway/new/bidding_frozen')
              this.postExcelFile(res.data,url)
              return true;  //打开冻结页面                
            }else{
              return false; //不打开冻结页面                  
            }
          //查找是否冻结成功,如果冻结成功,走向rdcw2 发送url
          } else if ( res.code === 300 ) { //冻结成功  继续往下走
            alert("这笔融资金额已经冻结成功,请进行审核操作")
          } else { 
            alert(res.msg)
            return;//冻结失败原因提示,不走后面逻辑
          }
        } catch (error) {
            alert('冻结发起失败')
          }
      }
    }) 
  }

  //融资分配查看收款方首款权限
  paymentRegistrationStatus = () => {
    const formFB = this.state.form;
    const body5 ={
    id: formFB.id,
    extraction:formFB.extraction,
    applicant: formFB.applicant,
    amount: formFB.amount,
    netFinancingAmount: formFB.net_financing_amount,
    mxNumber: formFB.mx_number,
    mxfNumber: formFB.mxf_number,
  }
    fetching('/loan/paymentRegistrationStatus', {
      method: 'POST',
      body: JSON.stringify(body5)
    }).then(res => {
      if (res) {
        try {
          if(res.code===200){ 
            alert(res.msg)
          } else { 
            alert(res.msg)
            return;//冻结失败原因提示,不走后面逻辑
          }
        } catch (error) {
            alert('查询融资人收款权限失败')
            }
      } 
    }) 
  }

  //查看收款方收款权限
  receiveRegistrationStatus = () => {
    const formFB = this.state.form;
    const body5 ={
    id: formFB.id,
    extraction:formFB.extraction,
    applicant: formFB.receive || formFB.applicant,
    amount: formFB.amount,
    netFinancingAmount: formFB.net_financing_amount,
    mxNumber: formFB.mx_number,
    mxfNumber: formFB.mxf_number,
  }
    fetching('/loan/paymentRegistrationStatus', {
      method: 'POST',
      body: JSON.stringify(body5)
    }).then(res => {
      if (res) {
        try {
          if(res.code===200){ 
            alert(res.msg)
          } else { 
            alert(res.msg)
            return;//冻结失败原因提示,不走后面逻辑
          }
        } catch (error) {
            alert('查询接收人收款权限失败')
            }
      }
    }) 
  }


  cmbRzVerify = () => {
    const formCmb = this.state.form;
    if (formCmb.distribute !== '招商银行') {
      alert('善美分配不需推送招行');
      return
    }
    fetching(`/cmb/applyFinancing?mxfNumber=${formCmb.mxf_number}`).then(res => {
      if (res) {
          alert(res.msg);
          if (res.result === 'success'){
            this.state.form.cmb_rz_type = '1';
          }else {
            this.state.form.cmb_rz_type = '0';
          }
          }
        })

  }


  //放款支付
  releasePaymentModel = () => {
    //线上打款功能----20210417
    const formFB = this.state.form;
    formFB.applicant = formFB.extraction;
    if(formFB.payment_type === "还款日不固定"){
      formFB.net_financing_amount = formFB.applyAmount
    }
       let body2 = {
       mxf_number:formFB.financingNo,
       open_credit_code:formFB.open_credit_code,
      url: window.location.href,
      }
       fetching('/loan/thaw', { //第一次调用 放款查询操作
          method: 'POST',
          body: JSON.stringify(body2)
         }).then(res2 => {
            try {
             if (res2.code === 200) { //放款失败调用放款接口
              let url = DEFAULT_PAYMENT_URL+"gateway/shanmei/release_payment_model";
              if(window.confirm("进入放款页面,是否要支付这笔给"+formFB.applicant+",融资金额为"+formFB.applyAmount+"元,融资净额为"+formFB.net_financing_amount+"元的这笔款项?")){
               //this. postExcelFile(res2.data,'http://192.168.1.11:8088/gateway/new/transaction_transfer')
               //this. postExcelFile(res2.data,'http://192.168.1.11:8089/gateway/shanmei/release_payment_model')
               this. postExcelFile(res2.data,url)
               return true;  //打开冻结页面                
                }else{
               return false; //不打开冻结页面                  
              }
                
             } else if (res2.code === 300) { //放款成功
                    alert("这笔融资资金已经放款成功,请进行审核操作")
             } else {
              alert(res2.msg)
              return;
             }
            } catch (error) {
              alert('支付发起失败')
            }
     })
        }
    
//冻结释放
  freezeRelease  = () => {
    const formFR = this.state.form;
      let mxf_number = formFR.mxf_number ;
     
        }

  //查看单据
  viewAgreement2 = () => {
    // this.setState({ open2: !this.state.open2 })
    const { mxc_number, fdd_kld_path = '', fdd_lzd_path = '' } = this.state.form;

    if (mxc_number) {
      window.open(fdd_lzd_path)
    } else {
      window.open(fdd_kld_path)
    }
  }
  //发送验证码
  onVerificationCode = () => {
    const { table, mxc_number, phone, flow_date, mxf_number } = this.state.form;

    let url = '';
    if (table === 'm2-4' || table === 'm7-3') {
      url = `/api/sms/KL?phone=${phone}`;
    } else if (table === 'm3-1-1' && !mxc_number) {
      url = `/api/sms/KL?phone=${phone}`;
    } else if (table === 'm3-1-1' && flow_date) {
      url = `/api/sms/LZ?phone=${phone}`;
    } else if (table === 'm3-1-1' && mxf_number) {
      url = `/api/sms/RZ?phone=${phone}`;
    } else if (table === 'm3-1-9') {
      url = `/api/sms/LZ?phone=${phone}`;
    } else if (table === 'm3-2-2') {
      url = `/api/sms/RZ?phone=${phone}`;
    } else if (table === 'r6-3-1') {
      url = `/api/sms/RZ?phone=${phone}`;
    }else if (table === 'm7-5') {
      url = `/api/sms/KL?phone=${phone}`;
    }


    if (url === '') {
      alert('短信接口为空')
      return;
    }
    fetching(url, {
      method: 'GET'
    }).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          const times = setInterval(() => {
            const { second } = this.state;
            if (second === 0) {
              clearInterval(times);
              this.setState({
                second: 60
              })
            } else {
              this.setState({
                second: second - 1
              })
            }
          }, 1000)
          this.setState({
            secondNumber: res.data,
            times
          })
        } else {
          this.setState({
            second: 60
          })
          alert(res.msg || '验证码发送失败')
        }
  
      } else {
        alert('验证码发送失败')
      }

    })

  }

  //生成form 表单
  postExcelFile(params, url) { // params是post请求需要的参数，url是请求url地址
    var form = document.createElement('form')
    form.style.display = 'none'
    form.action = url
    form.method = 'post'
    //重新打开新的窗口设置form target属性
    form.target = '_blank'
    document.body.appendChild(form)
    for (var key in params) {
      var input = document.createElement('input')
      input.type = 'hidden'
      input.name = key
      input.value = params[key]
      form.appendChild(input)
    }
    form.submit()
    form.remove()
  }

//下载放款回单
handleUpload = () => {
  let type = 40;//中金/清算支付回单类型(订死的)
  const { mxf_number } = this.state.form;
  if (!mxf_number) {
    return;
  }
  fetching(`/loan/thaw?mxf_number=${mxf_number}`, {
    method: 'POST',
  }).then(res => {
    try {
      if (res === null) {
        alert("该单未进行中金放款操作,暂无放款回单")
      } else if (res !== null ){
        if (res.status === 1) { //已完成放款操作
         //let url = `http://192.168.1.11:8089/gateway/payment/downladReceipt?txSn=${res.serialNumber}&type=${type}`; 
        let url = {DEFAULT_PAYMENT_URL} + `/gateway/payment/downladReceipt?txSn=${res.serialNumber}&type=${type}`;
          window.open(url)
        } else {
          alert("该单完成放款操作,暂无放款回单")
        } 
      }
    } catch (error) { }
  })
}
  // 提交
  handleSubmit = async (form2, name) => {
    const { auth = {}, secondNumber } = this.state;
    const stateForm = this.state.form;
    const { exercise } = this.props
    const form = form2 ? { ...form2 } : stateForm;
    const text = name || '取消';
    let typePath = '/msapi/flow/save';//审核接口
    let typePath2 = '/dzqz/generateContract/RZ';//单据填充
    let fillingBody = {};
    const rolesId = auth.roles ? auth.roles[0].id : '';
    let flowId = 'issue_id'

    //融资合同填充
    const body4 = {

      application_number: stateForm.open_credit_code ? stateForm.open_credit_code : form.organization.credit_no,//	融资方 社会信用代码1
      amount: form.amount,//	融资金额
      right_resource: form.right_resource || '是',//	追索权1
      financing_maturity: form.financing_maturity,//	融资期限（天）
      payment_day: form.payment_day,//	承诺付款日
      financing_time: form.financing_time,//	融资申请时间
      synthesis_rate: form.synthesis_rate,//	预计融资息费率
      interest: form.interest,//	预计融资息费金额
      id: form.id,//	融资 id
    }
    const body5 ={
      id: form.id,
      extraction:form.extraction,
      applicant: form.extraction,
      amount: form.applyAmount,
      netFinancingAmount: form.net_financing_amount,
      mxNumber: form.receivableNo,
      mxfNumber: form.financingNo,
      url: window.location.href,
    }

    const body6 = {
      openCreditCode: form.open_credit_code,
      id: form.id,//	融资 id
    }

    //应收
    if (exercise.table === 'm7-3' || exercise.table === 'r14-6-4'
      || exercise.table === 'r14-6-1' || exercise.table === 'r14-6-4' || exercise.table === 'm7-5' || exercise.table === 'm7-4-3') {
      typePath = '/msapi/rflow/save';
      flowId = 'receivable_id';
      typePath2 = '/dzqz/generateContract/YS';
      fillingBody = { ...body6 };
    }
    //融资
    else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' ||
      exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') {
      typePath = '/msapi/fflow/save';
      typePath2 = '/dzqz/generateContract/RZ';
      flowId = 'financing_id'
      fillingBody = { ...body4 };

    }
    //签收verification_code
    else if (exercise.table === 'm3-1-1') {
      if (form.flow_date) {
        typePath = '/msapi/cflow/save';
        flowId = 'circulation_id'
      } else if (form.mxf_number) {
        typePath = '/msapi/fflow/save';
        flowId = 'financing_id'
      } else
        typePath = '/msapi/flow/save';
    }

    //参数
    const body = {
      distribute:form.distribute,//2021-04-20
      ttid: form.ttid,//20210225
      organization_id: form.organizationId,//20200910
      auth,//用户信息
      check_opinion: form.check_opinion,//审核意见
      receivableNo: form.receivableNo || '', //可以字符串拼接
      audit_status: form.status,  //传状态id name form.status
      sign_agreement: form.sign_agreement,//签订融单协议
      form_no: stateForm.form_no,
      returnBy: form.returnBy,
      mxc_number: form.mxc_number || '',
      mxf_number: form.mxf_number || '',
      [flowId]: form.id,
      statusId:form.status_id,
    }
    if (form.flow_date) body.cid = form.id

    let body2 = {
        mxf_number:form.financingNo,
        open_credit_code: form.open_credit_code,
        url: window.location.href,
       }
    if (form.flow_date) body.cid = form.id
  
    //提交表单

    if (text !== '取消') {
      if(form.StatusId === 4 && stateForm.verification_code === "" && body.mxf_number === "" && rolesId !== 1){
        alert('请您进行验证码校验');
      } else if (form.StatusId === 4  && rolesId === 1 && (form.returnBy !== '退回经办人' || stateForm.form_no === '3')) {
        alert('当前页面只能进行退回经办人操作');
      } else if (stateForm.isverification_code && stateForm.verification_code !== secondNumber && stateForm.form_no !== '2') {
        alert('验证码输入不正确');
      } else if (!stateForm.KLStart && stateForm.form_no !== '2' && stateForm.form_no !== '6') {
        alert('你还没有签订协议');
      } else if (form.distribute === '招商银行') {
        fetching(`/cmb/cmbFindFinancingById?id=${form.id}`).then(res => {
        if (res === undefined && res.data.cmbRzStatus === "") {
          alert("招行融资推送失败无法提交，请重新提交！")
          return
         }else {
          if (stateForm.form_no === "2") {
            const oid = form.id;
            //type--1:开立；2：流转；3：融资
            var type = 0;
            if (form.returnBy.indexOf('经办人') !== -1 || (form.returnBy.indexOf('上一级') !== -1 && auth.roles[0].id === 12)) {
              //开立： m2-4:融单开具复核; m2-5-3:已退回融单; r6-2-1:融单开立审核; r6-2-4:已退回融单
              if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3' || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4') {
                type = 1;
              } else if (exercise.table === 'm3-1-9' || exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' || exercise.table === 'r6-4-8') {
                //流转： m3-1-9:融单流转复核; m3-1-10:已退回流转; r6-4-1:融单流转审核; r6-4-8:已退回流转
                type = 2;
              } else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' || exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') {
                //融资: m3-2-2:融资复核; m3-2-8:已退回融资; r6-3-1:融资申请审核; r6-3-6:已退回融资
                type = 3;
              }
              fetching(`/invoiceVerification/fpyzwDelete?type=${type}&oid=${oid}`).then(res => {
                if (res !== undefined && res.code === 200) {
                  //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
                  alert(res.msg);
                } else {
                  alert(`发票验真原有记录标记清除失败！`);
                }
              })
            }
          }
         
          fetching(typePath, {
            method: 'POST',
            body: JSON.stringify(body)
          }).then(res => {
            if (res && exercise.table === 'r6-3-1' && rolesId === 3) {
              fetching(typePath2, {
                method: 'POST',
                body: JSON.stringify(fillingBody)
              }).then(res2 => {
                if (res2.code !== 200) {
                  alert('合同填充失败')
                }
              })
            } else if (res && form.mxf_number && res.statusId === 11) {//融资放款 20210417
              fetching('/dzqz/extsignAuto/RZC', {
                method: 'POST',
                body: JSON.stringify({
                  id: form.id
                })
              }).then(res2 => {
                if (res2.code === 200) {
                  alert('已给开立人邮箱发送《应收账款转让通知（善美融单保理）》')
                } else {
                  alert('发送邮件失败')
                }
              })
              let body1 = {
                c_id: form.mxc_number,
                mx_number:form.mx_number,
                mxf_number:form.mxf_number,
              }
              fetching('/dzqz/ffilling', {
                method: 'POST',
                body: JSON.stringify(body1)
              }).then(res2 => {
                try {
                  if (res2.code === 200) {
                    if (res2.error) {
                      alert(res2.error)
                      return;
                    }
                    alert('善美融单回单填充成功')
                  } else {
                    //alert(res.msg)
                  }
                } catch (error) {
                  alert('《善美融单回单》填充失败')
                }
              })
            } else if (res && (stateForm.form_no === '2' && (rolesId === 12 || rolesId === 13 || form.returnBy === '退回经办人'))) {
              fetching('/fdd/withdraw', {
                method: 'POST',
                body: JSON.stringify({
                  contract_id: form.fdd_file_name
                })
              })
      
            } else if (!res) {
              //alert('操作失败');
            }
           // window.location.reload()
          })
          this.props.onSubmit({
            ...form,
            submit_name: text
          })
          //window.location.reload()
        }
        })
    }  else if (rolesId === 5 && (exercise.table === 'r6-3-1' ||  exercise.table === 'r14-6-1')) {// 冻结拦截查询
      fetching('/loan/frozenBalance', {
        method: 'POST',
        body: JSON.stringify(body5)
      }).then(res5 => {
        
        if(res5.code===200){ //未冻结
          alert("该笔资金还未进行冻结,请进行冻结操作")
          return;
          //查找是否冻结成功,如果冻结成功,走向rdcw2 发送url
        } else if ( res5.code === 300 ) { //冻结成功
          //退回操作员时，清除原有发票验真的记录（将原有记录标记为已过期不可用状态）
      if (stateForm.form_no === "2") {
        const oid = form.id;
        //type--1:开立；2：流转；3：融资
        var type = 0;
        if (form.returnBy.indexOf('经办人') !== -1 || (form.returnBy.indexOf('上一级') !== -1 && auth.roles[0].id === 12)) {
          //开立： m2-4:融单开具复核; m2-5-3:已退回融单; r6-2-1:融单开立审核; r6-2-4:已退回融单
          if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3' || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4') {
            type = 1;
          } else if (exercise.table === 'm3-1-9' || exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' || exercise.table === 'r6-4-8') {
            //流转： m3-1-9:融单流转复核; m3-1-10:已退回流转; r6-4-1:融单流转审核; r6-4-8:已退回流转
            type = 2;
          } else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' || exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') {
            //融资: m3-2-2:融资复核; m3-2-8:已退回融资; r6-3-1:融资申请审核; r6-3-6:已退回融资
            type = 3;
          }
          fetching(`/invoiceVerification/fpyzwDelete?type=${type}&oid=${oid}`).then(res => {
            if (res !== undefined && res.code === 200) {
              //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
              alert(res.msg);
            } else {
              alert(`发票验真原有记录标记清除失败！`);
            }
          })
        }
      }
     
      fetching(typePath, {
        method: 'POST',
        body: JSON.stringify(body)
      }).then(res => {
        if (res && exercise.table === 'r6-3-1' && rolesId === 3) {
          fetching(typePath2, {
            method: 'POST',
            body: JSON.stringify(fillingBody)
          }).then(res2 => {
            if (res2.code !== 200) {
              alert('合同填充失败')
            }
          })
        } else if (res && form.mxf_number && res.statusId === 11) {//融资放款 20210417
          fetching('/dzqz/extsignAuto/RZC', {
            method: 'POST',
            body: JSON.stringify({
              id: form.id
            })
          }).then(res2 => {
            if (res2.code === 200) {
              alert('已给开立人邮箱发送《应收账款转让通知（善美融单保理）》')
            } else {
              alert('发送邮件失败')
            }
          })
          let body1 = {
            c_id: form.mxc_number,
            mx_number:form.mx_number,
            mxf_number:form.mxf_number,
          }
          fetching('/dzqz/ffilling', {
            method: 'POST',
            body: JSON.stringify(body1)
          }).then(res2 => {
            try {
              if (res2.code === 200) {
                if (res2.error) {
                  alert(res2.error)
                  return;
                }
                alert('善美融单回单填充成功')
              } else {
                //alert(res.msg)
              }
            } catch (error) {
              alert('《善美融单回单》填充失败')
            }
          })
                 
        } else if (res && (stateForm.form_no === '2' && (rolesId === 12 || rolesId === 13 || form.returnBy === '退回经办人'))) {
          fetching('/fdd/withdraw', {
            method: 'POST',
            body: JSON.stringify({
              contract_id: form.fdd_file_name
            })
          })
        
        } else if (!res) {
          //alert('操作失败');
        }
        window.location.reload()
      })
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
      //window.location.reload()
      //alert("冻结成功") 
        } else {
          alert(res5.msg)
          return;//冻结失败原因提示,不走后面逻辑
          }
      })
    } else if (rolesId === 6 && (exercise.table === 'r6-3-1' || exercise.table === 'r14-6-1')) { // 放款拦截查询
      fetching('/loan/thaw', { 
        method: 'POST',
        body: JSON.stringify(body2)
         }).then(res6 => {
             try {
               if (res6.code === 200) { //放款失败调用放款接口
                 if (res6.error) {
                   alert(res6.error)
                   return;
                 }
                alert('您还没有进行放款操作,请进行放款操作!')
               } else if (res6.code === 300) { //放款成功
                alert("放款成功")  
                //退回操作员时，清除原有发票验真的记录（将原有记录标记为已过期不可用状态）
      if (stateForm.form_no === "2") {
        const oid = form.id;
        //type--1:开立；2：流转；3：融资
        var type = 0;
        if (form.returnBy.indexOf('经办人') !== -1 || (form.returnBy.indexOf('上一级') !== -1 && auth.roles[0].id === 12)) {
          //开立： m2-4:融单开具复核; m2-5-3:已退回融单; r6-2-1:融单开立审核; r6-2-4:已退回融单
          if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3' || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4') {
            type = 1;
          } else if (exercise.table === 'm3-1-9' || exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' || exercise.table === 'r6-4-8') {
            //流转： m3-1-9:融单流转复核; m3-1-10:已退回流转; r6-4-1:融单流转审核; r6-4-8:已退回流转
            type = 2;
          } else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' || exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') {
            //融资: m3-2-2:融资复核; m3-2-8:已退回融资; r6-3-1:融资申请审核; r6-3-6:已退回融资
            type = 3;
          }
          fetching(`/invoiceVerification/fpyzwDelete?type=${type}&oid=${oid}`).then(res => {
            if (res !== undefined && res.code === 200) {
              //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
              alert(res.msg);
            } else {
              alert(`发票验真原有记录标记清除失败！`);
            }
          })
        }
      }
     
      fetching(typePath, {
        method: 'POST',
        body: JSON.stringify(body)
      }).then(res => {
        if (res && exercise.table === 'r6-3-1' && rolesId === 3) {
          fetching(typePath2, {
            method: 'POST',
            body: JSON.stringify(fillingBody)
          }).then(res2 => {
            if (res2.code !== 200) {
              alert('合同填充失败')
            }
          })
        } else if (res && form.mxf_number && res.statusId === 11) {//融资放款 20210417
          try {
            fetching('/dzqz/extsignAuto/RZC', {
              method: 'POST',
              body: JSON.stringify({
                id: form.id
              })
            }).then(res2 => {
              if (res2.code === 200) {
                alert('已给开立人邮箱发送《应收账款转让通知（善美融单保理）》')
              } else {
                alert('发送邮件失败')
              }
            })
            let body1 = {
              c_id: form.mxc_number,
              mx_number:form.mx_number,
              mxf_number:form.mxf_number,
            }
            fetching('/dzqz/ffilling', {
              method: 'POST',
              body: JSON.stringify(body1)
            }).then(res2 => {
              try {
                if (res2.code === 200) {
                  if (res2.error) {
                    alert(res2.error)
                    return;
                  }
                  alert('善美融单回单填充成功')
                } else {
                  //alert(res.msg)
                }
              } catch (error) {
                alert('《善美融单回单》填充失败')
              }
            })
          } catch (error){

          }
          
                 
    

        } else if (res && (stateForm.form_no === '2' && (rolesId === 12 || rolesId === 13 || form.returnBy === '退回经办人'))) {
          fetching('/fdd/withdraw', {
            method: 'POST',
            body: JSON.stringify({
              contract_id: form.fdd_file_name
            })
          })
        } else if (!res) {
         // alert('操作失败');
        }
        window.location.reload()
      })
      this.props.onSubmit({
        ...form,
        submit_name: text
      })  
      //window.location.reload()                                                     
     } else {
                alert(res6.msg)
                return;
               }
            } catch (error) {
              alert('支付发起失败')
              }
        })
      } else {

        
        //退回操作员时，清除原有发票验真的记录（将原有记录标记为已过期不可用状态）
        if (stateForm.form_no === "2") {
          const oid = form.id;
          //type--1:开立；2：流转；3：融资
          var type = 0;
          if (form.returnBy.indexOf('经办人') !== -1 || (form.returnBy.indexOf('上一级') !== -1 && auth.roles[0].id === 12)) {
            //开立： m2-4:融单开具复核; m2-5-3:已退回融单; r6-2-1:融单开立审核; r6-2-4:已退回融单
            if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3' || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4') {
              type = 1;
            } else if (exercise.table === 'm3-1-9' || exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' || exercise.table === 'r6-4-8') {
              //流转： m3-1-9:融单流转复核; m3-1-10:已退回流转; r6-4-1:融单流转审核; r6-4-8:已退回流转
              type = 2;
            } else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' || exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') {
              //融资: m3-2-2:融资复核; m3-2-8:已退回融资; r6-3-1:融资申请审核; r6-3-6:已退回融资
              type = 3;
            }
            fetching(`/invoiceVerification/fpyzwDelete?type=${type}&oid=${oid}`).then(res => {
              if (res !== undefined && res.code === 200) {
                //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
                //alert(res.msg);
              } else {
                alert(`发票验真原有记录标记清除失败！`);
              }
            })
          }
        }
        if (stateForm.form_no === "3" &&  rolesId === 1 && (exercise.table === 'r14-6-1' || exercise.table === 'r14-6-4')) {
          fetching('/dzqz/generateContract/YSSQ', {
              method: 'POST',
              body: JSON.stringify(fillingBody)
            }).then(res2 => {
              if (res2.code !== 200) {
                alert('保理申请书填充失败')
              }
            })
            fetching('/dzqz/generateContract/YSQR', {
              method: 'POST',
              body: JSON.stringify(fillingBody)
            }).then(res2 => {
              if (res2.code !== 200) {
                alert('应收确认书填充失败')
              }
            })
            fetching('/dzqz/generateContract/YSTZ', {
              method: 'POST',
              body: JSON.stringify(fillingBody)
            }).then(res2 => {
              if (res2.code !== 200) {
                alert('应收通知书填充失败')
              }
            })
            fetching('/dzqz/generateContract/YSXXB', {
              method: 'POST',
              body: JSON.stringify(fillingBody)
            }).then(res2 => {
              if (res2.code !== 200) {
                alert('保理信息表填充失败')
              }
            })
            fetching('/dzqz/generateContract/YSHZ', {
              method: 'POST',
              body: JSON.stringify(fillingBody)
            }).then(res2 => {
              if (res2.code !== 200) {
                alert('应收回执填充失败')
              }
            })
        }
        fetching(typePath, {
          method: 'POST',
          body: JSON.stringify(body)
        }).then(res => {
          try{
            if (res && form.mxf_number && res.statusId === 11) {//融资放款 20210417
            fetching('/dzqz/extsignAuto/RZC', {
              method: 'POST',
              body: JSON.stringify({
                id: form.id
              })
            }).then(res2 => {
              if (res2.code === 200) {
                alert('已给开立人邮箱发送《应收账款转让通知（善美融单保理）》')
              } else {
                alert('发送邮件失败')
              }
            })
            let body1 = {
              c_id: form.mxc_number,
              mx_number:form.mx_number,
              mxf_number:form.mxf_number,
            }
            fetching('/dzqz/ffilling', {
              method: 'POST',
              body: JSON.stringify(body1)
            }).then(res2 => {
              try {
                if (res2.code === 200) {
                  if (res2.error) {
                    alert(res2.error)
                    return;
                  }
                  alert('善美融单回单填充成功')
                } else {
                  //alert(res.msg)
                }
              } catch (error) {
                alert('《善美融单回单》填充失败')
              }
            })

          } else if (res && (stateForm.form_no === '2' && (rolesId === 12 || rolesId === 13 || form.returnBy === '退回经办人'))) {
            fetching('/fdd/withdraw', {
              method: 'POST',
              body: JSON.stringify({
                contract_id: form.fdd_file_name
              })
            })
            this.props.onSubmit({
              ...form,
              submit_name: text
            })
          } else if (res && res.statusId === 6 && !res.mxf_number) {
            alert('拒签成功');
            this.props.onSubmit({
              ...form,
              submit_name: text
            })
            //window.location.reload()
          } 
        } catch (error) {
          alert("错误:"+ error)
       }
         // window.location.reload()
     })
        this.props.onSubmit({
          ...form,
          submit_name: text
        })
        window.location.reload()
      }


    } else {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
      window.location.reload()
    }
  }
  handleChange2 = ({ target: { name, value } }) => {
    const { form } = this.state
    let isverification_code = form.isverification_code
    if (value === '6') {
      isverification_code = true
    }
    this.setState({
      form: { ...form, [name]: value, isverification_code }
    })
  }

  handleChange3 = ({ target: { name, value } }) => {
    const { form } = this.state
    this.setState({
      form: { ...form, [name]: value}
    })
    return value
  }
  render() {
    const { form2, form, imgUrl, userList, second, secondNumber, signatureMsg ,distributeList} = this.state;
    const { exercise , classes, auth } = this.props;
    let rolesId = auth.roles ? auth.roles[0].id : 0;
    const isSign_agreement = () => {


      if (
        (form.form_no === '3' || form.form_no === '5') &&
        (exercise.table === 'm2-4' || exercise.table === 'm3-1-1' || exercise.table === 'm7-5') && exercise.roleId !== 14) {
        if (exercise.flow_date) {//流转日期--代表此单是流转单
          return false
        } else return true
      }else if (exercise.table === 'm7-5' && exercise.roleId === 14){
        return true
      }else if ((exercise.table === 'r6-3-1' || exercise.table === 'r6-2-1' || exercise.table === 'r14-6-1') && rolesId === 9 ) {
        return true
      }
      else if (exercise.table === 'm3-2-2' && exercise.StatusId === 5) {
        return true
      }
      else return false;
    }
    // const checkOption = () => {
    //   if (form.form_no === '3' || form.form_no === '5') {
    //     if (exercise.mxf_number) return true;
    //     return false;
    //   } else return true;
    // }
    const check_opinion_label = () => {
      if (form.form_no === '3' && rolesId !== 5) return form2.check_opinion
      else if (form.form_no === '2') return form2.return_reason
      else return form2.remarks

    }

    const distribute = () => {
      if (form.form_no === '3' && rolesId !== 5) return form2.check_opinion
      else if (form.form_no === '2') return form2.return_reason
      else return form2.remarks

    }

    //融单流转树形图--20200930
    const renderTree = (nodes) => (
      <TreeItem key={nodes.id} nodeId={nodes.id} 
      collapseIcon={ (nodes.flagTree === 1 ? <LocationOn/> : ( nodes.type === 1 ? <AccountTree/> : (nodes.type === 2 ? <Attachment/> : <MonetizationOn/> ) ) )} 
      expandIcon={<ExpandMoreIcon />} 
      endIcon={ (nodes.flagTree === 1 ? <LocationOn/> : ( nodes.type === 1 ? <AccountTree/> : (nodes.type === 2 ? <Attachment/> : <MonetizationOn/> ) ) )} 
      label={ (nodes.type === 1 ? nodes.mx_number : nodes.mxt_number) + ":" + nodes.extraction + ":" + nodes.amount }  
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    );

    const status = (handleSubmit, values) => {
      if (form.form_no === '3') {

        return <Grid container justify="center" style={{ padding: '20px 0' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      } else if (form.form_no === '2') {
        return <Grid container justify="center" style={{ padding: '20px 0' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      } else if (form.form_no === '5') {
        return <Grid container justify="center" style={{ padding: '20px 0' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      } else if (form.form_no === '6') {
        return <Grid container justify="center" style={{ padding: '20px 0' }}>

          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      }
    }
    //签订协议类型 exercise.table
    // const agreementTple = () => {}
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        validationSchema={object().shape(issueFormik(form2, form, rolesId))}
        //验证提交
        onSubmit={(values) => {
          this.handleSubmit(values, form2.check);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <form method="post" encType="multipart/form-data">

{((form.form_no === '3' &&  rolesId === 5) || (form.form_no === '3' &&  rolesId === 6)) &&
                  <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.mxf_number}:</Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.receivableNo}
                  </Typography>
                </Grid>}
                {((form.form_no === '3' &&  rolesId === 5) || (form.form_no === '3' &&  rolesId === 6)) &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.applicant}:</Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.extraction}
                  </Typography>
                </Grid>}
                {((form.form_no === '3' &&  rolesId === 5 && form.payment_type !== "还款日不固定") || (form.form_no === '3' &&  rolesId === 6 && form.payment_type !== "还款日不固定")) &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.net_financing_amount}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.net_financing_amount}元</span>
                  </Typography>
                </Grid> }
                {((form.form_no === '3' &&  rolesId === 5 && form.payment_type === "还款日不固定") || (form.form_no === '3' &&  rolesId === 6 && form.payment_type === "还款日不固定")) &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.net_financing_amount}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.applyAmount}元</span>
                  </Typography>
                </Grid> }
                {(form.form_no === '3' &&  rolesId === 5 && form.payment_type !== "还款日不固定")  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.frozenAmount}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.net_financing_amount}元</span>
                  </Typography>
                </Grid>}
                {(form.form_no === '3' &&  rolesId === 5 && form.payment_type === "还款日不固定")  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.frozenAmount}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.applyAmount}元</span>
                  </Typography>
                </Grid>}
                {(form.form_no === '3' &&  rolesId === 6 && form.payment_type !== "还款日不固定")  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.paymentAmount}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.net_financing_amount}元</span>
                  </Typography>
                </Grid>}
                {(form.form_no === '3' &&  rolesId === 6 && form.payment_type === "还款日不固定")  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.paymentAmount}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.applyAmount}元</span>
                  </Typography>
                </Grid>}
                {(form.form_no === '3' &&  rolesId === 5)  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.frozen_ways}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.repayment_ways}
                  </Typography>
                </Grid>}
                {(form.form_no === '3' &&  rolesId === 6)  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.payment_ways}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.repayment_ways}
                  </Typography>
                </Grid>}
                
              {/* { form.form_no !== '8' && rolesId !== 16 &&
                <SimpleExpansionPanel title="历史审核意见">
                  {
                    form.opinion_list.map((item, index) => {
                      return (
                        <div style={{ margin: '10px 0' }} key={index}>
                          <Typography component="p">
                            {item.name}
                          </Typography>
                          <Typography component="p">
                            {item.check_opinion}
                          </Typography>
                        </div>
                      )
                    })

                  }
                </SimpleExpansionPanel>
              } */}
              <FormControl component="fieldset">

              </FormControl>
              { form.form_no !== '8' && rolesId !== 9 && rolesId !== 16 && <Grid item xs={12}>
                <TextField
                  multiline
                  rows='4'
                  label={check_opinion_label()}
                  value={values.check_opinion}
                  name='check_opinion'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={false}
                />
                <FormikError touched={touched} errors={errors} name={'check_opinion'} />
              </Grid>}

              {
                 rolesId === 16 &&
                <FormControl style={{ width: '100%', margin: '10px 0' }}>
                  <InputLabel id="demo-simple-select-label">{form2.distribute}</InputLabel>
                  
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="distribute"
                    value={values.distribute}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('distribute', this.handleChange3(e))}
                  >
                   {
                      distributeList.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              }


              {(form.form_no === '8') &&
                <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
                >
                {renderTree(form.RDTree)}
              </TreeView>}

              {(form.form_no === '3' || form.form_no === '2')  && rolesId !== 16 &&
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="gender" name="form_no" value={form.form_no} onChange={this.handleChange2}>
                  <FormControlLabel value="3" control={<Radio />} label="同意" />

                  <FormControlLabel
                    disabled={rolesId === 11 || rolesId === 5 || rolesId === 6 || rolesId === 9}
                    value="2" control={<Radio />} label="退回" />
                </RadioGroup>}

                {(form.form_no === '3' || form.form_no === '2') && rolesId === 16 &&
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="gender" name="form_no" value={form.form_no} onChange={this.handleChange2}>
                  <FormControlLabel value="3" control={<Radio />} label="分配" />

                  <FormControlLabel
                    disabled={rolesId === 9 || rolesId === 5 || rolesId === 6 || exercise.StatusId === 5}
                    value="2" control={<Radio />} label="退回" />
                </RadioGroup>}
              {(form.form_no === '5' || form.form_no === '6') &&
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="gender" name="form_no" value={form.form_no} onChange={this.handleChange2}>
                  <FormControlLabel value="5" control={<Radio />} label="签收" />
                  <FormControlLabel disabled={exercise.roleId === 14} value="6" control={<Radio />} label="拒签收" />
                </RadioGroup>}
              {
                isSign_agreement() &&
                <div>
                  <Grid container>

                    {rolesId !== 9 && <Grid item xs={12}>
                      <Grid container alignItems="flex-end">
                        <TextField
                          label={form2.verification_code}
                          value={form.verification_code}
                          name='verification_code'
                          onChange={this.handleChange2}
                          margin='normal'
                          style={{ width: '167px' }}
                          disabled={false}
                        />
                        {second === 60 ?
                          <Button onClick={this.onVerificationCode} style={{ margin: ' 0  0 8px 10px' }}
                            variant='contained'>{form2.verification_code2}
                          </Button> :
                          <Button style={{ margin: ' 0  0 8px 10px' }}
                            variant='contained'>{second + form2.verification_code3}
                          </Button>}
                      </Grid>
                      {secondNumber !== '' && <div style={{ color: ' rgba(0, 0, 0, 0.42)', fontSize: '12px' }}>
                        {'验证码也发送到你的手机 ' + form.phone}
                      </div>}
                      <FormikError touched={touched} errors={errors} name={'verification_code'} />
                    </Grid>}
                    <div style={{ margin: '20px 0 10px 0' }}>
                      <Button
                        onClick={this.sign_agreements}
                        color="primary">{"签订《协议》"}</Button>

                      <Button onClick={this.viewAgreement} style={{ marginLeft: '10px' }}
                        variant='contained' >{"查看应收合同"}</Button>
                      <Button onClick={this.viewyssq} style={{ marginLeft: '10px' }}
                        variant='contained' >{"查看应收申请书"}</Button>
                      <Button onClick={this.viewysqr} style={{ marginLeft: '10px' }}
                        variant='contained' >{"查看应收确认书"}</Button>
                      <Button onClick={this.viewystz} style={{ marginLeft: '10px' }}
                        variant='contained' >{"查看应收通知书"}</Button>
                      <Button onClick={this.viewysxxb} style={{ marginLeft: '10px' }}
                        variant='contained' >{"查看保理信息表"}</Button>
                      <Button onClick={this.viewyshz} style={{ marginLeft: '10px' }}
                        variant='contained' >{"查看应收回执"}</Button>
                    </div>

                  </Grid>
                  <div style={{ marginLeft: '10px' }}>{signatureMsg}</div>

                </div>
              }
              {
                (form.form_no === '3' &&  rolesId === 5) &&
                <Button onClick={this.frozenBalance} style={{ margin: '10px 0' }}
                  variant='contained' >{'放款金额冻结'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 6) &&
                <Button onClick={this.releasePaymentModel} style={{ margin: '10px 0' }}
                  variant='contained' >{'放款支付'}</Button>   
              }
              {
                form.form_no === '2' &&
                <FormControl style={{ width: '100%', margin: '10px 0' }}>
                  <InputLabel id="demo-simple-select-label">{form2.returnBy}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="returnBy"
                    value={values.returnBy}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {
                      userList.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              }
              {
                status(handleSubmit, values)
              }
              <Dialog maxWidth={'md'} onClose={this.viewAgreement} aria-labelledby="customized-dialog-title"
                open={this.state.open2}>
                <MuiDialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  id="customized-dialog-title">
                  <span>{form2.view_agreement}</span>
                </MuiDialogTitle>
                <MuiDialogContent style={{ minWidth: '600px', minHeight: '400px' }}>
                  <img src={imgUrl} alt='' />
                </MuiDialogContent>
                <Button onClick={this.viewAgreement} style={{ width: '100px', margin: '20px 0 20px 24px' }}
                  variant='contained'>返回</Button>
              </Dialog>
            </form >
          )
        }
      />

    )
  }
}

export default withStyles(useStyles)(YSForm)
