
import getSubRouters from './helper';

 export const subRootAry131 = [
    '押品管理',
    '出账流程查询',
    '综合查询',
    '出账审批查询',
    '消息提醒查询',
    '还款情况查询',
  ];
const PARENT_PATH = '/risk-management/放款';
const PATH = '查询';
export default getSubRouters(PARENT_PATH, PATH, subRootAry131);