//客户概况
export default {
    orgName: "企业名称",
    orgCode: "客户编号",
    organization_code: "社会信用代码",
    legalPersonName: "法代人姓名",
    legalPersonGender: "法人性别",
    legalPersonNo:"法代人身份证号",

    legalPhone:"法代人手机号",
    idCardValidDateStart: "身份证有效期始",
    idCardValidDateEnd: "身份证有效期止",
    //增加下载按钮
    idCardPhotoPreUrl:"法代身份证人像面地址",
    idCardPhotoBackUrl: "法代身份证国徽面地址",
    businessLicenseImgsUrl: "营业执照图片地址",

    orgRegLimitDateStart: "成立日期",
    orgRegLimitDateEnd:"营业期限",
    created_at: "发起时间",

    orgRegAddress:"企业注册详细地址",

    
    contactsNameFirst: "联系人1姓名",
    contactsPhoneFirst: "联系人1电话",
    contactsRelationFirst: "联系人1关系",

    contactsNameSecond: "联系人2姓名",
    contactsPhoneSecond: "联系人2电话",
    contactsRelationSecond:"联系人2关系",

    issuingAuthority:"签发机关",
    legalPersonAddress:"住址",
    legalPersonNation:"企业联系电话区号",

    orgContactPhone:"企业联系电话",
    orgManageAddress:"企业实际经营地址",
    orgManageCity: "企业实际所在城市",

    orgManageProvince: "企业实际所在省份",
    orgManageQx: "企业实际所在区县",
    orgManageScope: "经营范围",

    orgRegCity: "企业注册城市",
    orgRegProvince: "企业注册省份",
    orgRegQx: "企业注册区县",
    orgSize: "企业规模",

    //交易信息推送
    select_customer: "选择订单",

    select_order_detail: "子订单详情",


    productName:"产品",
    count:"数量",
    unitPrice:"单价",
    amount:"金额",
    

    sellerName:"卖方企业名称",
    sellerOrgCode:"卖方社会信用代码",

    buyerName:"买方企业名称",
    buyerOrgCode:"买方社会信用代码",

    orderDate:"下单时间",
    orderStatus:"订单状态",
    
    // sellerName:"卖方企业名称",
    // sellerName:"卖方企业名称",
    // sellerName:"卖方企业名称",
    // sellerName:"卖方企业名称",
    // sellerName:"卖方企业名称",
    // sellerName:"卖方企业名称",
    // sellerName:"卖方企业名称",
    // sellerName:"卖方企业名称",
    // sellerName:"卖方企业名称",
    // sellerName:"卖方企业名称",
    // sellerName:"卖方企业名称",
    // sellerName:"卖方企业名称",
    // sellerName:"卖方企业名称",

    
}
