import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
} from '@material-ui/core';
import {
  Restore as RestoreIcon,
  Favorite as FavoriteIcon,
  LocationOn as LocationOnIcon,
  Folder as FolderIcon,
} from '@material-ui/icons';
import {Copyright} from '../../config/reusable';

const useStyles = makeStyles({
  root: {
    width: 500,
    margin: 'auto',
  },
});

export default function ({handlePage}) {
  const classes = useStyles();
  const [value, setValue] = React.useState('recents');

  function handleChange(event, newValue) {
    handlePage(parseInt(newValue));
    setValue(newValue);
  }

  return (
    <>
      <BottomNavigation value={value} onChange={handleChange} className={classes.root}>
        <BottomNavigationAction label="服务选项" value="1" icon={<LocationOnIcon/>}/>
        {/*<BottomNavigationAction label="关于我们" value="2" icon={<RestoreIcon/>}/>*/}
        <BottomNavigationAction label="联系我们" value="3" icon={<FavoriteIcon/>}/>
        <BottomNavigationAction label="市场" value="4" icon={<FolderIcon/>}/>
      </BottomNavigation>
      <Box color="primary.main" mt={4}>
        <Copyright opt='body1'/>
      </Box>
    </>
  );
}
