import React, { Component } from 'react';
// import {
//   ViewComfy, Edit
// } from '@material-ui/icons'
import {
  Button,
  Typography,
  Grid,
} from '@material-ui/core'
// import MaterialTable from '../../vendors/material-table';
// import { capitalize, fetching } from '../../config/utils';
import { fetching } from '../../config/utils';
import { core_business_url } from '../core_business_url';
import { C151Form, C152Form} from './index'
// import Dialog from '../../components/commonForm/Dialog';
import { r15_1, common, r15_2} from '../formStatic'
import { loginInfos } from '../../components/numToText'
import { alertDialog as AlertDialog } from '../../components';


// copied from 05-.../DataView.js
class View extends Component {
  state = {
    columns: [{}],
    data: [],
    hasToken: sessionStorage.getItem("authToken"),
    dataUrl: { url: { query: '' } },
    id: 10000,
    roleList: [],
    form2: { ...common },
    exercise: {
      save: '1',
      DialogTitle: '新增',
      open: false,
      status2: '1',
      formOpen: false,
    },
    alertInfo: {
      open: false,
      content: '',
    },

  };
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  }
  //获取数据
  componentDidMount() {
    const { table } = this.props;
    let auth = loginInfos();
    this.setState({ auth });
    fetching('/msapi/roles/find', { method: 'GET' }).then(data => {
      this.setState({ roleList: data || [] })
    })
    fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {
        if (data !== undefined && data !== null) {
          const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
          //根据不同的页面，获取不同的接口地址，渲染不同的数据
          const dataUrl = core_business_url(auth, table).find(item => item.name === table);
          const dataUrl2 = dataUrl || {};
          this.setState({ columns, dataUrl: dataUrl2 });
          this.onQuery();
        }
      })
  }

  //查询数据
  onQuery = () => {
    const { table } = this.props;
    const { roles = [{ id: 0 }] } = this.state.auth;
    try {
      const { url } = this.state.dataUrl;
      let fetchingData = [];
      if (table === 'r15-1') {
        if (roles[0].id === 5) fetchingData = [fetching(url.query)]
        else if (roles[0].id === 6) fetchingData = [fetching(url.query1), fetching(url.query3), fetching(url.query5)]
        else if (roles[0].id === 8) fetchingData = [fetching(url.query2), fetching(url.query3)]
        else fetchingData = [fetching(url.query3)]
      } else if (table === 'r15-2') {
        if (roles[0].id === 5) fetchingData = [fetching(url.query)]
        else if (roles[0].id === 1) fetchingData = [fetching(url.query1), fetching(url.query3), fetching(url.query5)]
        else if (roles[0].id === 8) fetchingData = [fetching(url.query2), fetching(url.query3)]
        else fetchingData = [fetching(url.query3)]
      } else {

      }
      Promise.all(fetchingData).then(values => {
        let data = []
        values.forEach(item => {
          if (item instanceof Array) {
            data = data.concat(item);
          }
        })
        this.setState({ data })
        if (table === 'r15-1' || table === 'r15-2') {
          this.handleData(data);
        }
      });
    } catch (e) { }
  }
  handleData = (data) => {
    const { form2 } = this.state
    const { table } = this.props;
    const { roles = [{ id: 0 }] } = this.state.auth;
    if (data instanceof Array && data.length > 0) {
      let data1 = data[0]
      // fetching(dataUrl.url.get2 + `${data1._id}`).then(resData => {
      if (data1.status_id === 7) {
        if (roles[0].id === 5 && data1.role_id === 6) {
          this.setState({
            exercise: { save: '2', formOpen: true, ...data1, }
          })
        } else if ((roles[0].id === 6 || roles[0].id === 1) && data1.role_id === 8) {
          this.setState({
            exercise: { save: '3', DialogTitle: form2.check, formOpen: true, ...data1, }
          })

        } else {
          this.setState({
            exercise: { save: '3', formOpen: true, ...data1, }
          })

        }
      } else if (data1.status_id === 3 || data1.status_id === 2) {
        if (roles[0].id === 5) {
          this.setState({
            exercise: { save: '3', formOpen: true, ...data1, }
          })
        } else {
          this.setState({
            exercise: { save: '3', DialogTitle: form2.check, formOpen: true, ...data1, }
          })

        }

      } else {
        this.setState({
          exercise: { save: '3', formOpen: true, ...data1, }
        })
      }
      // })

    } else if (table === "r15-1") {
      this.setState({
        exercise: { save: '1', formOpen: false, hint: "暂无数据", botton: "设置利率" }
      })
    } else if (table === "r15-2") {
      this.setState({
        exercise: { save: '1', formOpen: false, hint: "暂无数据", botton: "设置期限" }
      })
    }
  }

  onRef = (ref) => {
    this.child = ref
  }

  FormSubmit2 = (newData) => {

    newData.formDate = new Date();
    if (newData.submit_name !== 'cancle') {
      this.onQuery();
    }
  }
  // 对象查找
  findObject = (obj, data) => {
    let index = data.findIndex(item => item.id === obj.id);
    return index;
  }
  //新增开具表单
  // handleToggle = () => {
  //   const { form2 } = this.state
  //   this.setState({
  //     exercise: { ...this.state.exercise, DialogTitle: form2.add, save: '1', open: true, }
  //   })
  // }

  //表格删除
  // onDelete = async oldData => {
  //   const { dataUrl } = this.state
  //   const returnedData = await fetching(dataUrl.url.get + oldData._id, { method: 'DELETE' })
  //   returnedData && this.onQuery();
  //   return returnedData;
  // }

  handleToggle1 = () => {
    const { exercise } = this.state;
    this.setState({
      exercise: { ...exercise, save: '1', formOpen: true, }
    })
  }
  // handleDelete = () => {
  //   const { data, dataUrl } = this.state;
  //   if (data instanceof Array && data.length > 0) {
  //     let data1 = data[0]
  //     fetching(dataUrl.url.get + `${data1._id}`, { method: 'DELETE' }).then(resData => {
  //       try {
  //         if (resData) { this.onQuery() }
  //       } catch (error) {

  //       }

  //     })
  //   }
  // }

  handleDelete = (e, row) => {
    this.setState({ alertInfo: { ...row, open: true, content: '确定删除该数据吗' } })
  };
  handleConfirm = (alertInfo) => {
    const { dataUrl } = this.state
    if (alertInfo.flag) {
      fetching(dataUrl.url.get + `${alertInfo._id}`, { method: 'POST' }).then(data => {
        this.setState({ alertInfo: { open: false, content: '' } })
        this.onQuery();
      })
    } else {
      this.setState({ alertInfo: { open: false, content: '' } })
    }
  }

  handleUpdate = (e, data1) => {
    this.setState({ exercise: { ...data1, save: '2', formOpen: true } })
  }

  render() {
//debugger
    const { data, dataUrl, exercise, auth, alertInfo } = this.state;
    const table = this.props.table || '';
    const C151 = () => {
      let roles = auth ? auth.roles : [{ id: 0 }];

      let data1 = (data instanceof Array && data.length > 0) ? data[0] : {};
      return <div >
        {exercise.formOpen ? (
          <div >
            {(data1.status_id === 7 || data1.status_id === 9) && <Grid container className="dovbox" style={{ justifyContent: 'flex-end' }}>

              {roles[0].id === 5 && data1.role_id === 6 && < Button variant="contained" color="primary" onClick={e => { this.handleDelete(e, data1) }}>删除</Button>}
              {data1.status_id === 9 && roles[0].id === 5 && <Button style={{ margin: '0 10px' }} variant="contained" color="primary" onClick={e => { this.handleUpdate(e, data1) }}>编辑</Button>}
            </Grid>
            }
            <C151Form auth={auth} formStatic={{ ...common, ...r15_1 }} exercise={{ ...exercise, table }} table={table} dataUrl={dataUrl}
              onSubmit={this.FormSubmit2} />
          </div>

        ) : (
            <div>
              <Typography variant="body2" gutterBottom >{exercise.hint || '暂无数据'}</Typography>
              {roles[0].id === 5 && <Button variant="contained" color="primary" onClick={this.handleToggle1}>{exercise.botton || '设置利率'}</Button>}
            </div>
          )
        }
      </div>
    }
    const C152 = () => {
      let roles = auth ? auth.roles : [{ id: 0 }];
      let data1 = (data instanceof Array && data.length > 0) ? data[0] : {};

      return <div >
        {exercise.formOpen ? (
          <div >
            {(data1.status_id === 7 || data1.status_id === 9) && <Grid container className="dovbox" style={{ justifyContent: 'flex-end' }}>

              {roles[0].id === 5 && data1.role_id === 1 && < Button variant="contained" color="primary" onClick={e => { this.handleDelete(e, data1) }}>删除</Button>}
              {data1.status_id === 9 && roles[0].id === 5 && <Button style={{ margin: '0 10px' }} variant="contained" color="primary" onClick={e => { this.handleUpdate(e, data1) }}>编辑</Button>}
            </Grid>
            }
            <C152Form auth={auth} formStatic={{ ...common, ...r15_2 }} exercise={{ ...exercise, table }} table={table} dataUrl={dataUrl}
              onSubmit={this.FormSubmit2} />
          </div>

        ) : (
            <div>
              <Typography variant="body2" gutterBottom >{exercise.hint || '暂无数据'}</Typography>
              {roles[0].id === 5 && <Button variant="contained" color="primary" onClick={this.handleToggle1}>{exercise.botton || '设置期限'}</Button>}
            </div>
          )
        }
      </div>
    }
    return <div>
      {(table === 'r15-1')  && <C151></C151>}
      {(table === 'r15-2') && <C152></C152>}
      {/* <MaterialTable
        title={title}
        onSearchChange={this.onSearchChange}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        path={path}
        columns={columns}
        data={data}
        editable={editable}
        localization={localization}
        options={options}
      /> */}
      <AlertDialog alertInfo={alertInfo} handleConfirm={this.handleConfirm}></AlertDialog>
    </div>
  }
}

// const editable2 = (that, name, auth) => {
//   const { roles = [{ id: 0 }] } = auth;
//   let editable = {};
//   let name2 = name;
//   if (name === 'r15-1' && (roles[0].id !== 5)) {
//     name2 = 'sh15-1';
//   }
//   switch (name2) {
//     case 'r15-1':
//       editable = { onRowDelete: that.onDelete };
//       break;
//     default:
//     // editable = { onRowDelete: that.onDelete };
//   }
//   return editable;
// }
// const localization = {
//   header: {
//     actions: '操作'
//   }
// }
// const options = {
//   actionsCellStyle: { padding: '0 10px' },
//   headerStyle: { //表格头部
//     backgroundColor: '#1d4583',
//     color: '#FFF',
//     whiteSpace: 'nowrap'
//   },
//   rowStyle: { //表行
//     backgroundColor: '#fff',
//     whiteSpace: 'nowrap'
//   },
//   sorting: true,  //排序
//   // selection: true //多选框
// }
const setView = Comp => (collection, name) => {
  return class extends Component {
    render() {
      return <Comp table={collection} tableName={name} {...this.props} />
    }
  }
}

export const SetView = setView(View);

