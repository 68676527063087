import React from 'react';
import { makeStyles,  } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardContent,
  Paper,
  Box,
  Typography,
  Divider,
  // Fab,
} from '@material-ui/core';
// import {showLogo} from '../../config/reusable'

const contents = [
  {
    "title": "业  务  咨  询",
    "info": [{
      "name": "王飞",
      "mb": "18092139330",
      "qq": "275646250",
      "email": "wangfei@shccig.com"
    }, {
      "name": "王嘉怡",
      "mb": "13709211237",
      "qq": "594940206",
      "email": "wangjy@shccig.com"
    }]
  },
  {
    "title": "业  务  办  理",
    "info": [{
      "name": "赵雅婷",
      "mb": "15248177329",
      "qq": "2119458845",
      "email": "zhaoyt@shccig.com"
    }, {
      "name": "屈柏羽",
      "mb": "13383017602",
      "qq": "1013370037",
      "email": "quby@shccig.com"
    }]
  },
  {
    "title": "财 务",
    "info": [{
      "name": "张雯雯",
      "mb": "18992820218",
      "qq": "2296935467",
      "email": "zhangww@shccig.com"
    }]
  },
  {
    "title": "法 务",
    "info": [{
      "name": "张雅",
      "mb": "18729340304",
      "qq": "604827378",
      "email": "zhangya@shccig.com"
    }]
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1, 1),
    height: '70vh',
  },
  paper: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    }
  },
  box: {
    backgroundColor: '#166ca3',
    margin: `${theme.spacing(2)}px auto`,
  },
  category: {
    color: 'white',
    writingMode: 'vertical-rl',
    padding: 'auto 0',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  card: {
    maxWidth: 500,
    margin: theme.spacing(3, 3),
  },
  cardDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flex: '1 0 auto',
  },
  cardHeading: {
    fontWeight: "bold"
  },
  cardDivider: {
    margin: theme.spacing(1),
  },
  fabMargin: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: '#166ca3',
    opacity: 0.9,
  },
  title: {
    opacity: 0.9,
    paddingRight: theme.spacing(2),
  }
}));

function ItemFab({title}) {
  const classes = useStyles();
  return (
    <Typography className={classes.title} component='span'>
      {title}.
    </Typography>
  );
  // return (
  //   <Fab size="middle" aria-label="add" className={classes.fabMargin}>
  //     {title}
  //   </Fab>
  // )
}

function NameCard({name, mb, qq, email}) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.cardDetails}>
        <CardContent className={classes.cardContent}>
          <Typography component="h5" variant="h5" className={classes.cardHeading}>
            {name}
          </Typography>
          <Divider className={classes.cardDivider} light />
          <Typography variant="subtitle2" color="textPrimary" align={"left"}>
            <ItemFab title="MB" />
            {mb}
          </Typography>
          <Typography variant="subtitle2" color="textPrimary" align={"left"}>
            <ItemFab title="QQ"  />
            {qq}
          </Typography>
          <Typography variant="subtitle2" color="textPrimary" align={"left"}>
            <ItemFab title="email" />
            {email}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}

function setNameCards(info) {
  return info.map((item, inx) => {
    const {name, mb, qq, email} = item;
    return (
      <NameCard name={name} mb={mb} qq={qq} email={email} key={inx++}/>
    );
  });
}

export default function () {
  const classes = useStyles();
  const list = contents.map((item, inx) => {
    const {title, info} = item;
    return (
      <Paper className={classes.paper} key={inx++}>
        <Grid container style={{direction: 'flex', flexDirection: 'row'}}>
          <Grid item className={classes.box}>
            <Box m={4}>
              <Typography variant="h4" component="h2" className={classes.category}>
                {title}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Typography component={'span'}>
              {setNameCards(info)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    )
  });
  // const logo = showLogo('善美保理');

  return (
    <Grid container className={classes.root}>
      {list}
    </Grid>
  );
}
