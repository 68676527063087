import React from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  CalendarViewDay,
  Description,
  AssignmentTurnedIn,
  OpenInBrowser,
  CalendarViewDayOutlined,
} from '@material-ui/icons';
import Accordion from '../components/Accordion'
import subRouters, { SetView } from './common'
import C21, { subRootAry } from './containers/C2_1'
import { loginInfos} from '../components/numToText';
import { dataAnalysisForm,riskForm } from './containers2'

const PARENT_PATH = subRouters['融单管理'];


const Info1 = [
  {
    path: "我的融单",
    icon: CalendarViewDay,
    component: SetView('m2-1'),
  },
  // {
  //   path: "申请单列表",
  //   icon: FormatListBulleted,
  //   component: SetView('m2-2'),
  // },
  {
    path: '融单开具制单',
    icon: Description,
    component: SetView('m2-3'),

  },
  {
    path: '融单开具复核',
    icon: AssignmentTurnedIn,
    component: SetView('m2-4'),
  },
  {
    path: "数据分析",
    icon: CalendarViewDay,
    component: dataAnalysisForm,
  },
 /*{ 
    path: "风控系统",
    icon: CalendarViewDayOutlined,
    component: riskForm ,
  },*/
  {
    path: '融单开立 ',
    icon: OpenInBrowser,
  }
];


const subRootAry1 = subRootAry.map(item => {
  switch (item) {
    case '开立中融单':
      return { path: '开立中融单', component: SetView('m2-5-1') }
    case '已开融单':
      return { path: '已开融单', component: SetView('m2-5-2') }
    case '已退回融单':
      return { path: '已退回融单', component: SetView('m2-5-3') }
    case '已拒签融单':
    return { path: '已拒签融单', component: SetView('m2-5-5') }
    case '已开融单融资明细':
    return { path: '已开融单融资明细', component: SetView('m2-5-6') }
    case '批量审核':
      return { path: '批量审核', component: SetView('m2-5-4') }
    default:
      return item;
  }
})

export const Menu2 = () => {


  let roleId = 0;
  let auth = loginInfos();
  try {
    if (auth) {
      roleId = auth.roles[0].id || 0;
    }
  } catch (e) {
    roleId = 0;
  }

  let Info = Info1;
  Info = Info.filter((item) => {
    if (roleId === 11) {
      return (item.path !== "融单开具复核") ? item : null
    } else if (roleId === 13) {
      return (item.path !== "融单开具制单" && item.path !== "融单开具复核") ? item : null
    } else {
      return (item.path !== "融单开具制单") ? item : null
    }

  })

  const list = Info.map((item, index) => {
    const CompIcon = item.icon;
    return index === (Info.length - 1) ? <C21 key="C21" /> : (
      <ListItem
        button
        component={Link}
        to={`${PARENT_PATH}/${item.path}`}
        key={item.path}>
        <ListItemIcon>
          <CompIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText primary={item.path} />
      </ListItem>
    )
  });

  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
};

export const Content2 = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${Info1[0].path}`}
      />
      {Info1.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${PARENT_PATH}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${PARENT_PATH}/${item.path}`}
              render={() => <h2>{item.path}</h2>}
              key={item.path}
            />
          )
        }
      })}
      {subRootAry1.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${PARENT_PATH}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${PARENT_PATH}/${item}`}
              render={() => <h2>{item}</h2>}
              key={item}
            />
          )
        }
      })}
    </Switch>
  )
};
