import React, {Fragment, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  TextField,
  Button,
  InputAdornment,
  DialogActions,
} from '@material-ui/core';
import {
  PhoneIphone,
  Sms,
  VpnKey,
  Code,
  Compare,
  Save,
  Close
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 400,
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function (props) {
  const classes = useStyles();
  const {
    values: {cell, sms, passwd1, passwd2},
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleClose,
    isValid,
    setFieldTouched
  } = props;

  const PSD = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/ //密码
  const pwd1 = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{7,18}$/

  const passwd2Blur = (name, e) => {
    if (!pwd1.test(name)) errors.passwd1 = '密码不符合规范。';
    if (name !== passwd1) errors.passwd2 = '验证口令不一致。';
    setFieldTouched(name, true, false);
  };

  const [step, setStep] = useState(1);

  const [code, setCode] = useState('');

  const getCellCode = () => {
    const formCell = cell.replace(/[\(\s\)\-]/g, '');
    if (!formCell) {
      alert('请输入手机号');
      return;
    }

   /* const url = `/api/sms/ZC?phone=${formCell}`;
    fetch(url)
      .then(res => res.json())
      .then(data => {
        if (data.code === 200) {
          setCode(data.data);
          setStep(2);
        } else {
          alert(data.msg);
        }
      })
      .catch(err => console.log(err));*/
   const url1 = `/msapi/user/findUserByAccount?phone=${formCell}`;
    fetch(url1)
    .then(res => res.json())
    .then(data => {
      if (data.code === 200) {
        const url = `/api/sms/WJMM?phone=${formCell}`;
        fetch(url)
        .then(res => res.json())
        .then(data => {
          if (data.code === 200) {
            setCode(data.data);
            setStep(2);
          } else {
            alert(data.msg);
          }
        })
        .catch(err => console.log(err));
      } else {
        alert(data.msg);
        return;
      }
    })
    .catch(err => console.log(err));
  };

  // sms, code, value
  const validateSmsCode = (value) => {
    if (!value) {
      alert('请输入验证码');
      return;
    }

    if (value && value === code) setStep(3);
    else if (value) alert('验证码错误');
  };

  return (
    <Fragment>
      <form className={classes.root} onSubmit={handleSubmit}>
        <div>
          <TextField
            className={classes.margin}
            id="cell"
            name={"cell"}
            label={"请输入11位注册手机号码，进行短信验证"}
            value={cell}
            onChange={handleChange}
            placeholder="手机号码为11位"
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIphone/>
                </InputAdornment>
              ),
            }}
            error={Boolean(errors.cell)}
            helperText={touched.cell ? errors.cell : ''}
          />
          <Button onClick={getCellCode} color="primary" variant="outlined" endIcon={<Code />}>
            获取验证码
          </Button>
        </div>
        {[2, 3].includes(step) && (
          <div>
            <TextField
              className={classes.margin}
              id="sms"
              name="sms"
              label="请输入短信验证号码"
              value={sms}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Sms/>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.sms)}
              helperText={touched.sms ? errors.sms : ''}
            />
            <Button onClick={() => validateSmsCode(sms)} color="primary" variant="outlined" endIcon={<Compare/>}>
              短信验证
            </Button>
          </div>
        )}
        {step === 3 && (
          <Fragment>
            <div>
              <TextField
                className={classes.margin}
                id="passwd1"
                name="passwd1"
                type="password"
                label={"请输入新口令(最少8位,且必须包含字母、数字和特殊字符)"}
                placeholder="新口令"
                value={passwd1}
                onChange={handleChange}
                onBlur={() => passwd2Blur(passwd1)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKey/>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(errors.passwd1)}
                helperText={touched.passwd1 ? errors.passwd1 : ''}
              />
            </div>
            <div>
              <TextField
                className={classes.margin}
                id="passwd2"
                name="passwd2"
                type="password"
                label={"请再次输入新口令(最少8位,且必须包含字母、数字和特殊字符)"}
                placeholder="新口令"
                value={passwd2}
                onChange={handleChange}
                onBlur={() => passwd2Blur(passwd2)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKey/>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(errors.passwd2)}
                helperText={touched.passwd2 ? errors.passwd2 : ''}
              />
            </div>
          </Fragment>
        )}

        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary" endIcon={<Close/>}>
            取消
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={!isValid} endIcon={<Save/>}>
            提交
          </Button>
        </DialogActions>

      </form>
    </Fragment>
  );
}
