const crypto = require('crypto');

const getSha1 = (str) => {
  return crypto.createHash('sha1').update(str).digest('hex');
};


const getMd5 = (str) => {
  return crypto.createHash('md5').update(str).digest('hex');
};


/**
 * signKey 为商户签名字符串，所有请求数据为(k,v)形式;
 * 把所有请求的数据根据 k 按 a-z 排序，按照 signKey + k=v&k=v + signKey 的方式拼接，其中 v 为空或者 k 为 sign 时不参与拼接;
 * 对拼接后的字符串进行 sha1 运算(小写);
 * 再对字符串进行 md5 运算，即得到签名 sign 的值;
 */
const processSignKey = (body) => {
  const keys = Object.keys(body).sort();
  let algorithm = '';
  keys.forEach(k => {
    const v = body[k];
    if (k !== 'sign' && v) {
      algorithm += `${k}=${v}&`;
    }
  });
  return algorithm.replace(/&$/, '');
};

// 签名：signKey -> 排序 -> 拼接 -> 小写 -> sha1 -> md5
const setSignKey = (body, signKey) => {
  const concatStr = processSignKey(body);
  const auditStr = signKey + concatStr + signKey;
  const signature = getMd5(getSha1(auditStr).toLowerCase());
  return signature;
};


export default setSignKey;
