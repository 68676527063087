import React, { Component } from 'react'

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { DEFAULT_UPLOADS_URL,DEFAULT_EXPORT_URL } from '../../config/constants'

import { fetching } from '../../config/utils'
const styles = (theme) =>
  createStyles({
    root: {
      maxWidth: '1000px'
    },
  });
class Upload extends Component {
  state = {
    value: '',
    name: '',
    mold:'',
    open: false,
    open2: false,
    message: '',
    fileList: [],
    imgUrl: '',
  }

  componentWillMount() {
    if (this.props.fileList instanceof Array) {
      this.setState({ fileList: this.props.fileList })
    }
  }

  handleChange = (e) => {
    const { uploadName = '',mold='' } = this.props;
    let files = e.currentTarget.files;
    let type2 = files[0].type;
    let fileList = Array.from(files);
    if (files[0] && files[0].size / 1024 / 1024 > 100) {
      alert('上传文件大小不能超过100M')
    } else if(uploadName === 'Upload_contracts_invoice'){
      //在某电脑上却出现了通过<input type="file">获取到的file对象中，type属性为""，
      const ary = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        '', // 由获取的file.type为空字符串
      ];
      if(ary.includes(type2)){
        this.props.uploadChange({ file: fileList[0], uploadName,mold })
        this.setState({ fileList });
      } else {
        alert('合同发票编号只能上传xlsx格式')
      }
    }  else{
      this.props.uploadChange({ file: fileList[0], uploadName,mold })
      this.setState({ fileList });
    }
  };

  //消息提示
  handleClose = () => {
    this.setState({ open: false });
  };

  //删除上传文件
  handleUplaodClose = ({ target: index }) => {
    let fileList = this.state.fileList;
    fileList.splice(index, 1);
    this.setState({ fileList, value: '' });
  }
  //图片模态框
  handleClose2 = () => {
    this.setState({ open2: false });
  }
  //下载模板
  downloadTemplate =()=>{
    window.open(DEFAULT_UPLOADS_URL+ 'fapiao_template.xlsx')
  }

  //下载应收模板
  downloadYSTemplate =()=>{
    window.open(DEFAULT_UPLOADS_URL+ 'yszkdjmb.xlsx')
  }

  //查看实例
  viewFile = () => {

    const files = this.state.fileList;
    const { mold='', uploads = {}, uploadName = '' } = this.props;
    if( uploadName  === 'Upload_invoice'){
      if(!this.props.exercise.upload_invoice){
        alert('你还没有上传文件')
        return
      }
    }else if( uploadName  === 'Upload_contracts'){
      if(!this.props.exercise.upload_contracts){
        alert('你还没有上传文件')
        return
      }
    }else if(uploadName  === 'Upload_contracts_invoice'){
      if(!this.props.exercise.upload_contracts_invoice){
        alert('你还没有上传文件')
        return
      }
    }else if(uploadName  === 'Upload_rest'){
      if(!this.props.exercise.upload_rest){
        alert('你还没有上传文件')
        return
      }
    }
	if(files[0] == null) {
      fetching(DEFAULT_EXPORT_URL+`msapi/upload/download?id=`+this.props.exercise.id+"&type="+parseInt(mold)+"&field="+uploadName, { 
        method: 'get',
      }).then(data => {
        if(data){
          alert(data.msg)
          return
        }else{
        window.location.href=DEFAULT_EXPORT_URL+ `msapi/upload/download?id=`+this.props.exercise.id+"&type="+parseInt(mold)+"&field="+uploadName
        }
      })
    }else{
      let imgUrl = URL.createObjectURL(files[0])
      window.open(imgUrl)
    }
    

  
    // window.location.href=DEFAULT_EXPORT_URL+ `msapi/upload/download?id=`+361+"&type="+parseInt(1)+"&field="+'Upload_invoice'
    //window.location.href=DEFAULT_EXPORT_URL+ `msapi/upload/download?id=`+this.props.exercise.id+"&type="+parseInt(mold)+"&field="+uploadName
    
    // fetch(DEFAULT_EXPORT_URL+ `msapi/upload/download?id=`+this.props.exercise.id+"&type="+parseInt(mold)+"&field="+uploadName)
    // const organization = fetch(`/api/upload/download?id=`+this.props.exercise.id+"&type="+parseInt(mold)+"&field="+uploadName);
    // if (files[0] instanceof Object) {
    //   let imgUrl = URL.createObjectURL(files[0]) || '';
    //   if (files[0].type === 'image/jpeg' || files[0].type === 'image/png' || files[0].type === 'image/jpg') {
    //     this.setState({ open2: true, imgUrl });
    //   } else {
    //     window.open(imgUrl)
    //   }
    // } else {
    //   let imgUrl2 = uploads[uploadName] ? DEFAULT_UPLOADS_URL + uploads[uploadName] : '';
    //   let typeIndex = imgUrl2.lastIndexOf('.');
    //   if (typeIndex !== -1) {

    //     window.open(imgUrl2)
    //   } else {
    //     alert('你还没有上传文件')
    //   }
    // }
  }
  render() {
    const { message, open, open2, value, imgUrl, fileList } = this.state;

    const { msgClose, uploadClose,exercise={}, disabled = false,uploadName='',mold='',
      fileName = true, view = '下载', name = '新增' } = this.props;
    const action = () => {
      if (msgClose) {
        return [
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]
      } else {
        return []
      }
    }
    return (
      <div className="uploadBox">
        <div style={{ margin: '15px 0' }}>
          <Button disabled={disabled} variant='contained'
                  style={{ position: 'relative', marginRight: '10px', overflow: 'hidden' }}>
            <input type="file" name={this.props.uploadName} mold={this.props.mold} value={value} className="uploadFile" onChange={this.handleChange}></input>
            {name}
          </Button>
          {( exercise.save === '3'|| exercise.save === '2')&& <Button variant='contained' onClick={this.viewFile}>{"下载"}</Button>}
          {((exercise.save !== '3') && (exercise.table !== 'm7-2') && uploadName === 'Upload_contracts_invoice') && <Button variant='contained' onClick={this.downloadTemplate}>{'下载模板'}</Button>}
          {((exercise.save !== '3') && (exercise.table === 'm7-2') && uploadName === 'Upload_contracts_invoice') && <Button variant='contained' onClick={this.downloadYSTemplate}>{'下载模板'}</Button>}
        </div>

        {
          fileName &&
          fileList.map((item, index) => {
            return (
              <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <span title={item.name} className="fileName" style={{ margin: '0 10px' }}>{item.name}</span>
                {uploadClose && <IconButton
                  key="close"
                  aria-label="close"
                  color="primary"
                  index={index}
                  size="small"
                  onClick={this.handleUplaodClose}
                >
                  <CloseIcon />
                </IconButton>}
              </div>
            )
          })
        }

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open}
          style={{ background: '#09f' }}
          autoHideDuration={4000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{message}</span>}
          action={action()}
        />
        <Dialog maxWidth={'lg'} onClose={this.handleClose2} aria-labelledby="customized-dialog-title" open={open2}>
          <MuiDialogTitle id="customized-dialog-title">
            <span>{view}</span>
            <IconButton aria-label="close" onClick={this.handleClose2}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <MuiDialogContent style={{ minWidth: '600px', minHeight: '400px' }}>
            <img src={imgUrl} alt='' />
          </MuiDialogContent>

        </Dialog>
      </div>
    )
  }
}
export default withStyles(styles)(Upload);
