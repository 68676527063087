import React from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  AccountCircle as AccountCircleIcon,
  VerifiedUser as VerifiedUserIcon,
} from '@material-ui/icons';
import Accordion from '../components/Accordion'
import subRouters, { SetView } from './common'
import { accountForm} from './containers2'
const PARENT_PATH = subRouters['用户管理'];

const Info = [
  {
    path: "用户管理",
    icon: AccountCircleIcon,
    component: SetView('m5-1'),
  },
  {
    path: "支付管理",
     icon: AccountCircleIcon,
     component: accountForm,
  },
  {
    path: "融单系统银行账户信息",
    icon: VerifiedUserIcon,
    component: SetView('m5-2'),
  },
];

export const Menu5 = () => {
  const list = Info.map(item => {
    const CompIcon = item.icon;
    return (
      <ListItem
        button
        component={Link}
        to={`${PARENT_PATH}/${item.path}`}
        key={item.path}>
        <ListItemIcon>
          <CompIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText primary={item.path} />
      </ListItem>
    )
  });

  return (
    <div>
      {list}
      <ListItem>
        <Accordion />
      </ListItem>
    </div>
  );
}

export const Content5 = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${Info[0].path}`}
      />
      {Info.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${PARENT_PATH}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${PARENT_PATH}/${item.path}`}
              render={() => <h2>{item.path}</h2>}
              key={item.path}
            />
          )
        }
      })
      }
    </Switch>
  )
};
