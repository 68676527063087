
import getSubRouters from './helper';

export const subRootAry4 = [
    '融资款收回方式登记',
    '额度发放和收回流水查询',
    '未到期借据查询',
  ];


const PARENT_PATH = '/risk-management/保后管理';
  const PATH = '台账管理';
  export default getSubRouters(PARENT_PATH, PATH, subRootAry4);
