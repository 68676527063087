import React, { Component } from 'react'
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  TextField,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import FormikError from '../../components/commonForm/formikError';
import { IpFormik } from './formikValidate'

const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }
}));
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    form: this.getInitState(),
    form2: this.props.form2 || {},//静态资源
    loading: false,
  }
  async componentDidMount() {

  }

  getInitState() {
    const { exercise } = this.props
    const form = {
      // new_password: "",
      // confirm_password: "",
      ip: "",
      description: "",
      partner: ""
    }
    return  { ...form, ...exercise }
  }

  //保存 提交
  handleSubmit = (form2, name) => {
    let form = form2 || this.state.form;
    const text = name || '撤销';

    //let ids = form.id;
    let id = form.id;
    let { ip, description,partner } = form2
    let params = {
      id,
      ip,
      description,
      partner
    }
    //提交表单s
    if (text === '提交') {
      this.setState({ loading: true})
        fetching(`/msapi/user/addOrUpdateIpWhiteMsg`, {
          method: 'post',
          body: JSON.stringify(params)
        }).then((data) => {
          this.setState({ loading: false})
          try {
            if(data){
              if(data.msg){
                alert(data.msg)
                //return;
              }
              this.props.onSubmit({
                ...form,
                submit_name: text
              })
            }else {
              alert('提交失败')
            }
          } catch (error) {console.log(error)}
        })
      
    } else {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
    }
  }

  render() {
    const form2 = this.state.form2;
    const form = this.state.form;

    const { exercise } = this.props;

    const status = (handleSubmit, isSubmitting, values) => {
      return<div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
      <div style={{ position: 'relative' }}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          disabled={this.state.loading}
          name={form2.submit}
          style={{ marginRight: '10px' }}
        >
          {form2.submit}
          {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
        </Button>
      </div>
        <Button
          color='primary'
          variant='contained'
          onClick={this.handleSubmit.bind(this, values, '撤销')}
          name={form2.revocation}
          disabled={isSubmitting}
          style={{ marginRight: '10px' }}
        >
          {form2.revocation}
        </Button>
      </div>
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        validate={(values) => {
          return IpFormik(values, form2);
        }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, '提交');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
            <form>

              <Grid container justify="space-between">

                <TextField
                  label={form2.ip}
                  value={values.ip}
                  name='ip'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'ip'} />
              </Grid>
              
                <Grid container>
                  <TextField
                    label={form2.description}
                    value={values.description || ''}
                    name='description'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    //type='password'
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'description'} />
                </Grid>
              

              <Grid container>
                <TextField
                  //label={form2.new_password}
                  label={form2.partner}
                  value={values.partner || ''}
                  name='partner'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  //type='password'
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'partner'} />
              </Grid>
              {
                status(handleSubmit, isSubmitting, values)
              }
            </form>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);