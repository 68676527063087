import React, { Component } from 'react';
import Select from 'react-select';

import {
    TextField,
    Button,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Select as Select2,
    InputLabel,
    MenuItem,
    Typography,
    Checkbox,
    FormGroup,
    InputAdornment,
    Input,
    CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { form1Formik } from './formikValidate';
import FormikError from '../../../components/commonForm/formikError';
import { fetching } from '../../../config/utils';
import { numberfun, commafy } from '../../../components/numToText';
import GuarantorForm from './guarantorForm';
import MortgageForm from './mortgageForm';
import PledgeForm from './pledgeForm';

//授信方式
const creditList = ['综合额度', '单笔额度'];
//额度类型
const limitList = ['单一客户', '集团客户'];
//额度标志
const limitMarkList = ['可循环额度', '不可循环额度'];
//是否指定使用客户
const appointCustomerList = ['不指定使用客户', '指定使用客户'];
//敞口
const exposureList = ['敞口', '非敞口'];
const quotaList = ['保理额度', '财司切分'];
const entrustedPaymentList = ['是', '否'];


const useStyles = (theme => ({
    formControl: {
        marginTop: '16px',
        marginBottom: '8px',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    width: '100%',
    label: {
        textAlign: 'left',
    },
    radio: {
        flexDirection: 'row',
    },
    textField: {
        width: '100%',
        marginBottom: '8px',
        marginTop: '16px',
    },
}));

const styles = {
    width: '100%',
    margin: '16px 0 8px 0'
}
const loadingStyle = {
    position: "absolute",
    color: green[500],
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -17,
}
class Form1 extends Component {
    state = {
        flag : "",
        form: this.getInitState(),
        selectCustomerList: [],
        guarantFlag: true,
        guarantFlagStatus1: true,
        guarantFlagStatus2: true,
        guarantyWay: this.getInitGuarantyWay() || {},
        guarantInfo1: this.props.guarantInfo1 || {},
        guarantInfo2: this.props.guarantInfo2 || {},
        guarantInfo3: this.props.guarantInfo3 || {},
        guarantyList: [
            { id: 1, name: '信用', checked: false },
            { id: 2, name: '抵押', checked: false },//mortgage
            { id: 3, name: '质押', checked: false },//pledge
            { id: 4, name: '保证', checked: false },//guarantor
        ],
        guarantFlag1 : "",
        guarantFlag2 : "",
        guarantFlag3 : "",
        guarantFlag4 : "",

        guarantInfoFlag1 : "",
        guarantInfoFlag2 : "",
        guarantInfoFlag3 : ""
    }
    getInitState() {
        const { form = {}, } = this.props;
        if (form.credit_way=== '综合授信') {
            form.credit_way= '综合额度';
        } else if (form.credit_way=== '单笔授信'){
            form.credit_way= '单笔额度';
        }
        if ( form.quota === "保理授信") {
            form.quota= "保理额度";
        }
        return { ...form }
    }
    getInitGuarantyWay() {
        const { form = {}, } = this.props;
        let info = {}
        info.id = form.id ? form.id : '';
        if (['guarantor_id', 'mortgage_id', 'pledge_id',].includes(form)) {
            for (let name in form) {
                info[name] = form[name]
            }
        }
        return { ...info }
    }
    async componentWillMount() {
        const { form, exercise = {} } = this.props;
        const { guarantyList } = this.state;

        if (exercise.save !== '3') {
            this.queryEnterpriseInfo();
        }
        let guarantyWay1 = form.guaranty_way ? form.guaranty_way.split(",") : '';
        if (!guarantyWay1) {
            return
        }
        let guarantyList1 = guarantyList.map((item) => {
            if (guarantyWay1.includes(item.name)) {
                item.checked = true
            } else {
                item.checked = false
            }
            return item
        })
        this.setState({ guarantyList: guarantyList1 })
    }
    queryEnterpriseInfo = () => {
        fetching('/msapi/customer_info/find', {
            method: 'GET',
        }).then(res => {
            if (res) {
                try {
                    let datas = res.filter((item) => {
                        item.value = item.enterprise_name;
                        item.label = item.enterprise_name;
                        // return (item.id !== 1 && !datas1.includes(item.enterprise_no))?item:null;
                       // return (item.id !== 1) ? item : null;
	       return  item;
                    })
                    this.setState({ selectCustomerList: datas });
                } catch (error) {
                }
            } else {
            }
        })
    }

    handleGuarantyWay = ({ target: { name, checked } }) => {
        //只要有质押，额度标志自动默认“不可循环额度”--20201222
        //var limit_mark_name = "";
        const { guarantyList } = this.state;
        guarantyList.map((item) => {
            if (item.name === name) {
                item.checked = checked
            }
            /*if (item.name === '质押' && item.checked) {//只要有质押，额度标志自动默认“不可循环额度”--20201222
                limit_mark_name = "质押";
            }*/
            return item;
        })
        let guarantFlag = true;
        let guarantFlagStatus1 = true;
        let guarantFlagStatus2 = true;
        if (name === '信用' && checked === false){
            this.state.guarantFlag1 = true;
            this.setState({ guarantyList })
        } else if (name === '抵押'  && checked === true ){
            this.state.guarantFlag2 = false;//抵押  
            if(this.state.guarantInfo1.mortgage_type===undefined){
                guarantFlag = false;
            }else{
                guarantFlag = true;
            }          
            this.setState({ guarantFlag, guarantyList })
         } else if ( name === '质押' && checked === true){
            this.state.guarantFlag3 = false;//质押
            if(this.state.guarantInfo2.pledge_type===undefined){
                guarantFlagStatus1 = false;
            }else{
                guarantFlagStatus1 = true;
            }
            this.setState({ guarantFlagStatus1, guarantyList })
         } else if (name === '保证' && checked === true) {
            this.state.guarantFlag4 = false;//保证
            if(this.state.guarantInfo3.guarantor_name===undefined){
                guarantFlagStatus2 = false;
            }else{
                guarantFlagStatus2 = true;
            }
            this.setState({ guarantFlagStatus2, guarantyList })
         } else if (name === '信用' && checked === true){
            this.state.guarantFlag1 = true;
            this.setState({ guarantyList })
         } else if (name === '抵押'  && checked === false ){
            this.state.guarantFlag2 = true;//抵押
            guarantFlag = true;            
            this.setState({ guarantFlag, guarantyList })
         } else if ( name === '质押' && checked === false){
            this.state.guarantFlag3 = true;//质押
            guarantFlagStatus1 = true;
            this.setState({ guarantFlagStatus1, guarantyList })
         } else if (name === '保证' && checked === false) {
            this.state.guarantFlag4 = true;//保证
            guarantFlagStatus2 = true
            this.setState({ guarantFlagStatus2, guarantyList })
         }

        // if (!this.state.guarantFlag2 && this.state.guarantFlag2 !== ""  ) {
        //     guarantFlag = false;
            
        // } else if (!this.state.guarantFlag3 && this.state.guarantFlag3 !== ""){
        //     guarantFlagStatus1 = false;
           
        // } else if (!this.state.guarantFlag4 && this.state.guarantFlag4 !== "" ){
        //     guarantFlagStatus2 = false;
            
        // }
        /*if (name === '抵押' || name === '质押' || name === '保证') {
            guarantFlag = checked ? false : true
        }*/

        //只要有质押，额度标志自动默认“不可循环额度”--20201222
        /*if (limit_mark_name === '质押') {
            this.state.form.limit_mark = "不可循环额度";
        } else {
            this.state.form.limit_mark = "可循环额度";
        }*/
        // this.setState({ guarantFlag, guarantyList })
        // this.setState({ guarantFlagStatus1, guarantyList })
        // this.setState({ guarantFlagStatus2, guarantyList })
        
    }
    //上一步
    handleBack = () => {
        this.props.handleBack();
    };
    //下一步
    handleNext = (values) => {
        const { form, guarantFlag,guarantFlagStatus1,guarantFlagStatus2, guarantyList, guarantInfo1, guarantInfo2, guarantInfo3 } = this.state;
        let mxAmount = values.mx_amount.replace(/,/g,'');  //20210408 授信申请额度限制在10亿以内
        if(Number(mxAmount) > Number(2000000000)){
            alert("额度金额超限,请修改额度申请金额")
            return;
        }
        if (!this.state.flag && this.state.flag !== "") {//额度来源检测20210520
            alert("该企业用户额度来源为总公司额度分配,不能进行额度申请,请注意!!!!!")
            return;
        }
        
        if (guarantFlag && guarantFlagStatus1 && guarantFlagStatus2) {
            values.enterprise_no = form.enterprise_no || ''
            values.enterprise_name = values.select_customer.value || ''
            let guarantyWay1 = []
            guarantyList.forEach((item) => {
                if (item.checked && item.name === '抵押') {
                    values.mortgage_id = guarantInfo1.mortgage_id || guarantInfo1.id
                    guarantyWay1.push(item.name)
                } else if (item.checked && item.name === '质押') {
                    values.pledge_id = guarantInfo2.pledge_id || guarantInfo2.id
                    guarantyWay1.push(item.name)
                } else if (item.checked && item.name === '保证') {
                    values.guarantor_id = guarantInfo3.guarantor_id || guarantInfo3.id
                    guarantyWay1.push(item.name)
                } else if (item.checked) {
                    guarantyWay1.push(item.name)
                }
                else {
                    return null;
                }
            })
            values.guaranty_way = guarantyWay1.join(",")
            if (!values.guaranty_way) {
                alert('请选择担保方式')
                return;
            }
            this.state.form.limit_mark = form.limit_mark
            //values.limit_mark = form.limit_mark
            this.props.handleNext(values);
        }
    };

    //保存  guarantor_id/mortgage_id/pledge_Id
    handleSave = (values) => {
        if (!this.state.flag && this.state.flag !== "") {//额度来源检测20210520
            alert("该企业用户额度来源为总公司额度分配,不能进行额度申请,请注意!!!!!")
            return;
        }
        const { form, guarantFlag,guarantFlagStatus1,guarantFlagStatus2, guarantyList, guarantInfo1, guarantInfo2, guarantInfo3 } = this.state;
        if (guarantFlag && guarantFlagStatus1 && guarantFlagStatus2) {
            if (values.select_customer) {
                //values.limit_mark = form.limit_mark
                this.props.handleLoading(true)
            } else {
                alert('请选择客户')
                return;
            }
            values.enterprise_no = form.enterprise_no || ''
            values.enterprise_name = values.select_customer.value || ''

            //只要有质押，额度标志自动默认“不可循环额度”--20201222
            //var limit_mark_name = "可循环额度";

            let guarantyWay1 = []
            guarantyList.forEach((item) => {
                if (item.checked && item.name === '抵押') {
                    values.mortgage_id = guarantInfo1.mortgage_id || guarantInfo1.id
                    guarantyWay1.push(item.name)
                } else if (item.checked && item.name === '质押') {
                    //limit_mark_name = "不可循环额度";//只要有质押，额度标志自动默认“不可循环额度”--20201222
                    values.pledge_id = guarantInfo2.pledge_id || guarantInfo2.id
                    guarantyWay1.push(item.name)
                } else if (item.checked && item.name === '保证') {
                    values.guarantor_id = guarantInfo3.guarantor_id || guarantInfo3.id
                    guarantyWay1.push(item.name)
                } else if (item.checked) {
                    guarantyWay1.push(item.name)
                }
                else {
                    return null;
                }
            })
            values.guaranty_way = guarantyWay1.join(",")
            this.state.form.limit_mark = form.limit_mark
            //values.limit_mark = limit_mark_name;//只要有质押，额度标志自动默认“不可循环额度”--20201222
            
            this.props.handleSave(values);
        }
    };

    //额度来源检测20210520
    historySource = (value) => {
        fetching(`/amount/usedAmount/historySource?enterprise_no=${value.enterprise_no}`).then(res => {//查看额度来源
            if (res) {
                if (res.data !== "" && res.data.length > 0) {
                    alert('该公司客户额度来源为总公司额度分配,不能进行额度申请操作,请注意!!!!')
                    this.state.flag = false;
                    //return false;
                } else {
                    this.state.flag = true;
                }
            }
             })
        fetching(`/msapi/credit1/getOrgMsg?creditNo=${value.organization_code}`).then(data => {
                if(null !== data && null !== data.data && data.data.id !== undefined){
                    fetching(`/smrd/distribution_recycling/getSubQaOrRec?organizationId=${data.data.id}`).then(data => {
                        if(data === 0){
                          alert('有待审核的额度分配及回收业务时，不可发起额度申请!')
                          this.state.flag = false;
                        } 
                      })
                } 
              })
        };

    //输入框值改变
    handleChange3 = (name, value) => {
        let value2 = value;
        if (name === 'select_customer') {
            this.setState({ form: { ...this.state.form, enterprise_no: value.enterprise_no } })
            this.historySource(value);//额度来源检测20210520
        } else if (name === 'mx_amount') {
            value2 = numberfun(value2, 12, 2);
        } else if (name === 'credit_way') {
            let limit_mark = value === '单笔额度' ? '不可循环额度' : '可循环额度'
            this.setState({ form: { ...this.state.form, limit_mark } })
        }
        return value2;
    }
    //输入框失去焦点 金额 千分位
    handleBlur2 = (name, value) => {
        return commafy(value);
    }
    //担保信息
    FormSubmit = (data) => {
        const { guarantInfo1, guarantInfo2, guarantInfo3 } = this.state;
        //this.setState({ guarantFlag: true, })
        this.props.FormSubmit1(data)
        if (data.guarantInfo1) {
            this.state.guarantFlag = true;
            this.state.guarantInfoFlag1 = true;
            this.setState({ guarantInfo1: { ...guarantInfo1, ...data.guarantInfo1, } })
        } else if (data.guarantInfo2) {
            this.state.guarantInfoFlag2 = true;
            this.state.guarantFlagStatus1 = true;
            this.setState({ guarantInfo2: { ...guarantInfo2, ...data.guarantInfo2, } })
        } else if (data.guarantInfo3) {
            this.state.guarantInfoFlag3 = true;
            this.state.guarantFlagStatus2 = true;
            this.setState({ guarantInfo3: { ...guarantInfo3, ...data.guarantInfo3, } })
        }
    }

    render() {
        const { activeStep, exercise = {}, formStatic, classes, auth } = this.props;
        const { form, selectCustomerList, guarantyWay, guarantInfo1, guarantInfo2, guarantInfo3, guarantFlag,guarantFlagStatus1,guarantFlagStatus2, guarantyList } = this.state;
        const GuarantyWay = (guarantyWay) => {
            switch (guarantyWay.guaranty_way) {
                case '抵押':
                    return <MortgageForm key='1' auth={auth} guarantyWay={guarantyWay} formStatic={formStatic} FormSubmit={this.FormSubmit} guarantInfo={guarantInfo1} />;
                case '质押':
                    return <PledgeForm key='2' auth={auth} guarantyWay={guarantyWay} formStatic={formStatic} FormSubmit={this.FormSubmit} guarantInfo={guarantInfo2} />;
                case '保证':
                    return <GuarantorForm key='3' auth={auth} guarantyWay={guarantyWay} formStatic={formStatic} FormSubmit={this.FormSubmit} guarantInfo={guarantInfo3} />;
                default:
                    return '';
            }
        }

        return (
            <Formik
                //初始化数据
                initialValues={{
                    ...form
                }}
               //表单验证
               validate={(values) => {
                return form1Formik(values, formStatic, guarantyList);
            }}
                //验证提交
                onSubmit={(values) => {

                    this.handleNext(values);
                }}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                        <form method="post" encType="multipart/form-data">
                            <Grid item xs={12}>
                                <div style={{ margin: '5px 0' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_customer}</span>
                                    <Select
                                        isSearchable
                                        value={values.select_customer}
                                        onChange={e => setFieldValue('select_customer', this.handleChange3.bind(this, 'select_customer', e)())}
                                        options={selectCustomerList}
                                        isDisabled={exercise.save !== '1'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'select_customer'} />
                                </div>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.enterprise_no}
                                        value={form.enterprise_no}
                                        name='enterprise_no'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl className={classes.textField}>
                                        <InputLabel htmlFor="mx_amount">{formStatic.mx_amount}</InputLabel>
                                        <Input
                                            name="mx_amount"
                                            value={values.mx_amount}
                                            onChange={e => setFieldValue('mx_amount', this.handleChange3.bind(this, 'mx_amount', e.target.value)())}
                                            onBlur={e => setFieldValue('mx_amount', this.handleBlur2.bind(this, 'mx_amount', e.target.value)())}
                                            endAdornment={<InputAdornment position="end">元</InputAdornment>}
                                            disabled={exercise.save === '3'}
                                            inputProps={{
                                                maxLength: 50,
                                            }}
                                        />
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'mx_amount'} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset" style={styles}>
                                    <FormLabel component="legend" style={{ fontSize: '12px' }}>{formStatic.guaranty_way}</FormLabel>
                                    <FormGroup row name='guaranty_way'>
                                        {guarantyList.map((item, index) => {
                                            return (
                                                <FormControlLabel
                                                    key={index}
                                                    disabled={exercise.save === '3'}
                                                    control={<Checkbox checked={item.checked}
                                                        onChange={this.handleGuarantyWay}
                                                        name={item.name}
                                                        value={item.name} />}
                                                    label={item.name} />
                                            )
                                        })}
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            {guarantyList.map((item) => {
                                if ((!(item.name.guaranty_way === '信用')) && item.checked) {
                                    return (
                                        GuarantyWay({ ...guarantyWay, guaranty_way: item.name, save: exercise.save, mx_amount: values.mx_amount, credit1_id: values.id || '' })
                                    )
                                } else {
                                    return null;
                                }
                            })}
                            {guarantFlag ? (
                                    <FormikError touched={touched} errors={errors} name={'guaranty_way'} />
                                ) : (
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', color: '#f00', marginTop: '10px' }}>
                                            <Typography variant="body2" gutterBottom >请录入抵押信息</Typography>
                                        </div>
                                    )}
                           {guarantFlagStatus1 ? (
                                    <FormikError touched={touched} errors={errors} name={'guaranty_way'} />
                                ) : (
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', color: '#f00', marginTop: '10px' }}>
                                            <Typography variant="body2" gutterBottom >请录入质押信息</Typography>
                                        </div>
                                    )}
                            {guarantFlagStatus2 ? (
                                    <FormikError touched={touched} errors={errors} name={'guaranty_way'} />
                                ) : (
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', color: '#f00', marginTop: '10px' }}>
                                            <Typography variant="body2" gutterBottom >请录入保证信息</Typography>
                                        </div>
                                    )}
                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.credit_way}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.credit_way}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('credit_way', this.handleChange3.bind(this, 'credit_way', e.target.value)())}>
                                            >
                                            {creditList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'credit_way'} />
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.limit_type}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.limit_type}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('limit_type', e.target.value)}>
                                            >
                                            {limitList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'limit_type'} />
                                </Grid>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.apply_credit_no}
                                        value={values.apply_credit_no}
                                        name='apply_credit_no'
                                        onChange={handleChange}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={true}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'apply_credit_no'} />
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.limit_mark}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.limit_mark}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('limit_mark', e.target.value)}>
                                            >
                                            {limitMarkList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.is_appoint_customer}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.is_appoint_customer}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('is_appoint_customer', e.target.value)}>
                                            >
                                            {appointCustomerList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'is_appoint_customer'} />
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.exposure}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.exposure}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('exposure', e.target.value)}>
                                            >
                                            {exposureList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'exposure'} />
                                </Grid>
				<Grid item xs={5}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.quota}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.quota}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('quota', e.target.value)}>
                                            >
                                            {quotaList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'quota'} />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} >
                                <FormControl component="fieldset" style={styles} className={classes.radio}>
                                    <FormLabel component="legend" style={{ fontSize: '12px' }}>{formStatic.entrusted_payment}</FormLabel>
                                    <RadioGroup className={classes.radio} style={{ flexDirection: 'row' }} aria-label="gender" name='entrusted_payment' value={values.entrusted_payment}
                                        onChange={e => setFieldValue('entrusted_payment', e.target.value)}>
                                        {entrustedPaymentList.map(item => {
                                            return (
                                                <FormControlLabel
                                                    key={item}
                                                    name={item}
                                                    value={item}
                                                    disabled={exercise.save === '3'}
                                                    control={<Radio />}
                                                    label={item} />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormControl>
                                <FormikError touched={touched} errors={errors} name={'entrusted_payment'} />
                            </Grid>
                            <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={this.handleBack}
                                >
                                    上一步
                            </Button>

                                <Button style={{ margin: '0 10px' }} variant="contained" color="primary" onClick={handleSubmit}>
                                    下一步
                            </Button>
                                {exercise.save !== '3' &&
                                    <div style={{ position: 'relative' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={this.props.loading}
                                            onClick={this.handleSave.bind(this, values)}
                                        >
                                        保存</Button>
                                        {this.props.loading && <CircularProgress size={24} style={loadingStyle} />}
                                    </div>
                                }
                            </div>
                        </form>
                    )}
            />
        );
    }
}

export default withStyles(useStyles)(Form1);
