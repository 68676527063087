
import getSubRouters from './helper';

 export const C2_3subRootAry = [
    '客户类型变更',
    '财务报表识别'
  ];

  const PARENT_PATH = '/risk-management/customer-info';
  const PATH = '功能维护';
export default getSubRouters(PARENT_PATH, PATH, C2_3subRootAry);
