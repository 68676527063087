import getSubRouters from './helper';

 export const subRootAry2 = [
    '开立日志',
    '流转日志',
    '融资日志',
    '额度日志',
    '企业关联日志',
    '额度分配日志',
    '额度回收日志',
    '应收账款日志',

  ];

const PARENT_PATH = '/risk-management/系统日志';
const PATH = '业务操作日志';
export default getSubRouters(PARENT_PATH, PATH, subRootAry2);