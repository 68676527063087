//实际控制人
export default {
    enterprise_name:"客户名称",
    enterprise_no:"客户编号",
    actual_controller:"实际控制人姓名",
    associated_info:"关联信息",
    legal_other_duty:"法人其他任职信息",
    judicial_info:"司法查控信息",
    net_assets: "净资产",
    paid_in_capital: "实收资本",
    risk_warning_info: "风险预警信息",
    overdue_info: "逾期信息",
    credit_attention_bad: "征信关注及不良"
}
