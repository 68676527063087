
//表格公共接口列表
export const core_business_url = [
    {
        name: "c1-1-1",
        title: '客户概况',
        url: { query: '' }
    },
    {
        name: "c1-1-2",
        url: { query: '' },
        title: '营业执照'
    },
    {
        name: "c1-1-3",
        url: { query: '' },
        title: '验资信息'
    },
    {
        name: "c1-1-4",
        url: { query: '' },
        title: '联系方式'
    },
    {
        name: "c1-1-5",
        url: { query: '' },
        title: '公司章程'
    },
    {
        name: "c1-1-7",
        url: { query: '' },
        title: '内外部客户'
    },
    {
        name: "c1-1-8",
        url: { query: '' },
        title: '企业管理员账号'
    },
    {
        name: "c1-2",
        url: { query: '' },
        title: '管理层信息'
    },
    {
        name: "c1-3",
        url: { query: '' },
        title: '自然人实际控制人'
    },
    {
        name: "c1-4",
        url: { query: '' },
        title: '非自然人实际控制人'
    },
    {
        name: "c2-1",
        url: { query: '' },
        title: '主要财务资料'
    },
    {
        name: "c3-1",
        url: { query: '' },
        title: '客户评级与限额'
    },
    {
        name: "c4-1-1",
        url: { query: '' },
        title: '风险预警信息'
    },
    {
        name: "c4-1-2",
        url: { query: '' },
        title: '逾期信息'
    },
    {
        name: "c4-2",
        url: { query: '' },
        title: '征信关注及不良'
    },
    {
        name: "c4-2",
        url: { query: '' },
        title: '关联关系信息'
    },
    {
        name: "c4-2",
        url: { query: '' },
        title: '上下游企业'
    },

]