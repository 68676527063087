
import getSubRouters from './helper';

export const subRootAry92 = [
    '核心企业明细',
    '供应商交易统计',
  ];

const PARENT_PATH = '/risk-management/运营管理';
  const PATH = '数据统计';
  export default getSubRouters(PARENT_PATH, PATH, subRootAry92);