
//表格公共接口列表
export const core_business_url = (auth, table) => {
    if (!auth.id) return [];
    else {
        return [
            {
                name: "c1-1-1",
                title: '月度客户概况',
                url: { query: `/msapi/customer_info/find?user_id=${auth.id}`, get: '/msapi/customer_info/find',get2: '/msapi/customer_info/find' }
            },
            {
                name: "c1-1-2",
                // url: { query: `/api/license`,get: '/api/license',get2: '/api/license',getUploads:`/api/upload/find?license=` },
                url: { query: `/msapi/license/find?user_id=${auth.id}`,get: '/msapi/license/findAll',get2: '/msapi/license/find',getUploads:`/msapi/upload/find?license=` },
                title: '营业执照'
            },
            {
                name: "c1-1-3",
                url: { query: '' },
                title: '验资信息'
            },
            {
                name: "c1-1-4",
                url: { query: '' },
                title: '联系方式'
            },
            {
                name: "c1-1-5",
                url: { query: '' },
                title: '公司章程'
            },
            {
                name: "c1-1-6",
                url: { query: `/msapi/customer_info_add/getCusInfoAddDataList?user_id=${auth.id}`, get: '/msapi/customer_info_add/getCusInfoAddDataList',get2: '/msapi/customer_info_add/getCusInfoAddDataList' },
                //url: { query: `/msapi/ccb/find?user_id=${auth.id}`,get: '/msapi/ccb/findAll',get2: '/msapi/ccb/find',getUploads:`/msapi/upload/find?license=` },
                title: '客户信息补录'
            },
	    {
                name: "c1-1-7",
                url: { get: '/msapi/account/findAllAccount' },
                title: '内外部客户'
            },
      	    {
                name: "c1-1-8",
                url: { get: '/msapi/user/findAllAdmin' },
                title: '月度企业管理员账号'
            },
            {
                name: "c1-2",
                // url: { query: `/api/customer_admin`,get: '/api/customer_admin',get2: '/api/customer_admin' },
                url: { query: `/msapi/customer_admin/find?user_id=${auth.id}`,get: '/msapi/customer_admin/findAll',get2: '/api/customer_admin' },
                title: '管理层信息'
            },
            {
                name: "c1-3",
                // url: { query: `/api/people`,get: '/api/people?natural=1',get2: '/api/people' },
                url: { query: `/msapi/people/find?natural=1&user_id=${auth.id}`,get: '/msapi/people/find?natural=1',get2: '/api/people' },
                title: '自然人实际控制人'
            },
            {
                name: "c1-4",
                // url: { query: `/api/people`,get: '/api/people?natural=1',get2: '/api/people' },
                url: { query:  `/msapi/people/find?natural=0&user_id=${auth.id}` ,get: '/msapi/people/find?natural=0',get2: '/api/people'},
                title: '非自然人实际控制人'
            },
            {
                name: "c1-5",
                url: { query: `/msapi/customer_info_add/legalPersonAuthorizationPeriod/getLgAuthorDataList?user_id=${auth.id}`, 
                get: '/msapi/customer_info_add/legalPersonAuthorizationPeriod/getLgAuthorDataList',get2: '/msapi/customer_info_add/legalPersonAuthorizationPeriod/deleteAuthMsg?id=' },
                
                title: '法人授权信息查看'
            },
        ]
    }

}