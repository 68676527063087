//额度分配表单
export default {
    select_subsidiary: "选择集团客户子公司",
    select_customer: "选择集团客户",
    enterprise_name: "集团客户名称",
    enterprise_no: "集团客户编号",
    subsidiary_name: "子公司名称",
    subsidiary_no: "子公司编号",
    mx_amount: "子公司总额度",
    usable_amount: "子公司可用额度",
    subsidiary_amount: "子公司回收额度",
    subsidiary_date: "子公司回收额度时间",
}