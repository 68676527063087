//额度申请第一个表单
export default {
    guaranty_way: "担保方式",
    credit_way: "额度方式",
    limit_type: "额度类型",
    apply_credit_no: "额度书号",
    limit_mark: "额度标志",
    enterprise_name: "客户名称",

    mx_amount: "额度金额*",
    is_appoint_customer: "是否指定使用客户",
    entrusted_payment: "受托支付",
    exposure: "敞口/非敞口",
    quota:"额度来源",


    guarantor_type:"担保人类型",
    guarantor_name:"担保人",
    id_card:"身份证号",
    guarantee_amount:"保证金",
    guarantee_period:"担保期限",

    guarantor_enterprise:"担保单位",
    legal_person:"法人",
    unified_social_credit_code: "统一社会信用代码",

    mortgage_type:"抵押物类型",
    real_estate_mortgage: "不动产抵押",
    chattel_mortgage: "动产抵押",
    mortgage_name: "抵押人姓名",
    mortgage_value: "抵押物价值",
    mortgage_position: "抵押物位置",
    mortgage_ratio: "抵押物比例",
    mortgage_desc:"质押物描述",

    pledge_type:"抵押物类型",
    pledge_name: "抵押人姓名",
    pledge_value: "抵押物价值",
    pledge_position: "抵押物位置",
    pledge_ratio: "抵押物比例",
    pledge_desc:"质押物描述",

}