import React, { Component } from 'react';
import {
  ViewComfy,
  CloudUpload,
  CloudDownload,//替换图表20200923
  AssignmentTurnedIn,
  Payment,
  MonetizationOn,
  Edit,
  ArrowForward,
  Search,
  DeleteForever,//删除--20201103
} from '@material-ui/icons'
import {
  Button,
  ButtonGroup,
  Grid,
} from '@material-ui/core'
import MaterialTable from '../vendors/material-table';
import { defer, capitalize, fetching } from '../config/utils';
import { core_business_url } from './core_business_url';
import { connect } from 'react-redux';
import { C11 } from './containers'
import { Dialog, C23Form, C318Form, C321Form, C41Form, C51Form, C52Form, C61Form, C72Form, C751Form, C42ViewList} from './containers2';//还款弹窗--20201218
import { f2_3, f3_1_8, f3_2_1, f5_1, common , f4_1, f7_2, f7_5_1} from './formStatic'//还款弹窗--20201218
import { Form1, CommonDialog, CheckBoxForm, YSForm, CheckBoxDownForm } from '../components/commonForm'
import { loginInfos } from '../components/numToText'
import setSignKey from '../components/payment/config';
import { C151Form, } from '../01-risk-management/InterestRate';
import { r15_1 } from '../01-risk-management/formStatic'
import { DEFAULT_EXPORT_URL } from '../config/constants'
import { formatDate, getNowFormatDate } from '../components/date';
import { DownloadTimeForm, DialogForm } from './downloadTime';//时间选择弹窗--20201118
import { values } from 'lodash';
import { DEFAULT_ACCOUNT_URL , DEFAULT_PAYMENT_URL} from '../config/constants';
export const BASE = '/business';

const subRouters = [
  '额度管理', //'amount'
  '融单管理',
  '融单流转',
  '应收账款',
  '还款管理',
  '合同管理',
  '外部融资',
  '用户管理',
].reduce((obj, url) => {
  obj[url] = `${BASE}/${url}`;
  return obj;
}, {});

export default subRouters;

class View extends Component {
  state = {
    columns: [{}],
    sumData:[],
    sum: {
      sumData:[],
      page:1,
      rowsPerPage:5,
    },
    page:1,
    rowsPerPage:5,
    sumAmount: 0,
    sumCount: 0,
    data: [],
    hasToken: sessionStorage.getItem("authToken"),
    dataUrl: { url: { query: '' } },
    index: 10000,
    form2: { ...common },
    auth: {},//登录信息
    params: {},
    exercise: {
      save: '1',
      DialogTitle: '新增',
      open: false,
      checkOpen: false,
      status2: '1',
      open2: false,
      table: this.props.table || ''
    },
    opinion_list: [],
    query: '',
    orderBy: '',
    orderDirection: ''
  };

  componentDidMount() {
    const { table } = this.props;
    fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {
        if (!data) return;
        let auth = loginInfos();
        const core_business_url2 = core_business_url(auth, table);
        const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
        //根据不同的页面，获取不同的接口地址，渲染不同的数据
        const dataUrl = core_business_url2.find(item => item.name === table);
        const dataUrl2 = dataUrl || {};
        const localStorageData = this.localStorageData(table, auth.account) || [];
        this.setState({ columns, dataUrl: dataUrl2, data: localStorageData, auth });
        if (table === 'm1' || table === 'md') {

          //this.OnQuerys3(auth);
        }
        this.fetchings(dataUrl2, table, auth);
      })
  }

  //根据条件查询数据
  fetchings = (dataUrl2, table, auth) => {
     table  = this.props.table;
    if (dataUrl2.url) {
      if (dataUrl2.url.query) {
        if (table === 'm5-1') {//m5-1:用户管理
          this.onQuery1();
        } else if (table === 'm5-2'){
          this.onQueryCompany();
        } else if (table === 'm3-1-2') {//m3-1-2:可用融单
          this.onQueryUsable();
        } else if (
          table === 'm2-1' || table === 'm2-5-1' || table === 'm3-1-1' ||
          table === 'm3-1-7' || table === 'm3-2-2' || table === 'm7-1' ||
          (table === 'm2-5-3' && (auth.roles)[0].id === 11) ||
          (table === 'm3-1-10' && (auth.roles)[0].id === 11) ||
          (table === 'm3-2-8' && (auth.roles)[0].id === 11)) {
          this.onQuery2(table);
        } else if(table === 'm2-5-2'){
          this.onQueryIssueSummations()
        } else if(table === 'm7-5'){
          this.onQuery3()
        }else{
          this.onQuery();
        }

      }
    }
  }

  onQuery3 = (table) => {
    try {
      const { url } = this.state.dataUrl;
      let fetchingData = [];
      fetchingData = [fetching(url.query), fetching(url.query1)]
      Promise.all(fetchingData).then(data => {
        if (data !== undefined && data !==null) {
          if(data[0].length === 0){
            data = data[1]
          }else{
            data = data[0]
          }
          let datas = data.filter(item => {
             //过滤到所有查询出来的列表的已删除信息--20201223
             if (item.StatusId === 12) {
              return null;
            }
            /*
            //m3-1-3:支付融单查看;m3-2-3:融资查看;m2-1:我的融单;m2-5-1：开立中融单
            //if (table === 'm3-1-3' || table === 'm3-2-3' || table === 'm2-5-1' || table === 'm2-1') {
            if (table === 'm3-1-3' || table === 'm3-2-3') {
              //status:12-已删除;11-已融资;7-已退回;6-拒签收;5-已签收
              if (item.StatusId === 11 || item.StatusId === 7 || item.StatusId === 5 || item.StatusId === 6) {
                return null;
              }
            }*/
            if (item.statusId !== 1) {
              //前台只能看到审核中或完成
              if (item.statusId === 8 || item.statusId === 10) {
                item.status = '审核中'
              } else {
                item.status = item.status ? item.status.name : '';
              }

              item.operator = item.user ? item.user.name : '';
              item.payer = item.mx_payer ? item.mx_payer : item.extraction;
              // item.payer = '融单平台';  
              /*let Flows = item.Flows || item.FinancingFlows || item.CirculationFlows || [];
              if (Flows.length > 0) {
                item.reason = Flows[0].check_opinion*/
              let flows = item.flows || item.financingFlows || item.circulationFlows || [];
                if (flows.length > 0) {
                item.reason = flows[flows.length - 1].check_opinion
              }

              return item;
            } else {
              return null;
            }
          })
          /*if (table === 'm3-1-3' || table === 'm3-2-3') {//m3-1-3:支付融单查看;m3-2-3:融资查看;m2-1:我的融单;m2-5-1：开立中融单
            datas = datas.filter(item => {
              //if (item.StatusId !== 11 && item.StatusId !== 7 && item.StatusId !== 5 && item.StatusId !== 6) {
              if (item.StatusId !== 12 && item.StatusId !== 11 && item.StatusId !== 7 && item.StatusId !== 5 && item.StatusId !== 6) {
              //if (item.StatusId !== 12) {//status:12-已删除;11-已融资;7-已退回;6-拒签收;5-已签收
                return item;
              } else {
                return null;
              }
            })
          }*/

          this.setState({ data: datas });
        }
  
      });
    } catch (e) {
    }

  }


  onQueryCompany = () => {
    const { url } = this.state.dataUrl;
    const creditNo = this.state.auth.organization.credit_no;
    fetching(url.query+`?creditNo=${creditNo}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          this.setState({ data: res.data });
        } else {
          alert(res.msg || '查询失败')
        }
      } else {
        alert('查询失败')
      }
    });
  }
  //查询数据
  onQuery = () => {
    const { table } = this.props;
    try {
      const { url } = this.state.dataUrl;
      //还款查询
      //var type = 2;//type: 1-后端/全部；2-前端/指定公司
      if (table === 'm4-1' || table === 'm4-2' || table === 'm4-3' || table === 'm4-4' || table === 'm7-4-4' || table === 'm7-4-5') {//[客户端]:m4-1:待还款; m4-2:还款审批; m4-3:已还款; m4-4:未还款
        //is_westcol_done: 0: 待还款, 1: 审核中, 2. 还款中, 3. 已退回, 4. 已还款, 5. 未还款//defaultValue: 0
        //type: 1-后端/全部；2-前端/指定公司
        this.onQueryRepayment();
        return;
      }

      if (table === 'm4-5') {// m4-5:还款信息
        this.onQueryReturnInformation();
        return;
      }
      if (table === 'm4-6' || table === 'm3-2-10') {// m4-6:还款明细
        this.getRepaymentDetails();
        return;
      }
      if(table === 'm3-2-11'){
        this.getFinancingRate();
        return;
      }
      if (table === 'm2-3' || table === 'm3-1-8' || table === 'm3-2-1') { //m2-3:融单开具制单;m3-1-8:融单流转;m3-2-1:融资申请
        this.onQuerySave();
        return;
      }
      if (table === 'm6-1' || table === 'm6-2' || table === 'm6-3' || table === 'm6-6') { //m6-1:注册合同管理;m6-2:开立合同管理;m6-3:融资合同管理;m6-6:应收合同管理
        this.onQueryContract();
        return;
      } 
      if (table === "m8-1-1" || table === "m8-1-2" || table === "m8-2-1" || table === "m8-2-2" || table === "m8-3-1" || table === "m8-3-2") {
        this.onQueryBankFinancing(table);
        return;
      }
      fetching(url.query).then(data => {
	if (data !== undefined && data !==null) {
        if(table === 'md'|| table === 'mh'){
          data.data.forEach(item => {
            if (table === 'md' && item.amountStatus === 1) {
             //item.status = "已冻结";
               item.status = "部分已冻结";
            } else {
              item.status = "正常";
            }
              item.creditStartDate = formatDate(new Date(Date.parse(item.creditStartDate)), 'yyyy-MM-dd');
              item.creditDueDate = formatDate(new Date(Date.parse(item.creditDueDate)), 'yyyy-MM-dd');
            })         
          this.setState({ data: data.data });
        } else{
        if (data !== undefined && data !==null) {
          let datas = data.filter(item => {
             //过滤到所有查询出来的列表的已删除信息--20201223
             if (item.StatusId === 12) {
              return null;
            }
            /*
            //m3-1-3:支付融单查看;m3-2-3:融资查看;m2-1:我的融单;m2-5-1：开立中融单
            //if (table === 'm3-1-3' || table === 'm3-2-3' || table === 'm2-5-1' || table === 'm2-1') {
            if (table === 'm3-1-3' || table === 'm3-2-3') {
              //status:12-已删除;11-已融资;7-已退回;6-拒签收;5-已签收
              if (item.StatusId === 11 || item.StatusId === 7 || item.StatusId === 5 || item.StatusId === 6) {
                return null;
              }
            }*/
            if (item.statusId !== 1) {
              //前台只能看到审核中或完成
              if (item.statusId === 8 || item.statusId === 10) {
                item.status = '审核中'
              } else {
                item.status = item.status ? item.status.name : '';
              }

              item.operator = item.user ? item.user.name : '';
	       if(table === 'm3-1-4' || table === 'm3-2-10'){
                item.payer = item.circulation ? item.circulation : item.extraction;
              }else{
                item.payer = item.mx_payer ? item.mx_payer : item.extraction;
              }
              //item.payer = item.mx_payer ? item.mx_payer : item.extraction;
              // item.payer = '融单平台';  
              /*let Flows = item.Flows || item.FinancingFlows || item.CirculationFlows || [];
              if (Flows.length > 0) {
                item.reason = Flows[0].check_opinion*/
              let flows = item.flows || item.financingFlows || item.circulationFlows || [];
                if (flows.length > 0 && item.status_id === 7) {
                item.reason = flows[flows.length - 1].check_opinion
              }

              return item;
            } else {
              return null;
            }
          })
          /*if (table === 'm3-1-3' || table === 'm3-2-3') {//m3-1-3:支付融单查看;m3-2-3:融资查看;m2-1:我的融单;m2-5-1：开立中融单
            datas = datas.filter(item => {
              //if (item.StatusId !== 11 && item.StatusId !== 7 && item.StatusId !== 5 && item.StatusId !== 6) {
              if (item.StatusId !== 12 && item.StatusId !== 11 && item.StatusId !== 7 && item.StatusId !== 5 && item.StatusId !== 6) {
              //if (item.StatusId !== 12) {//status:12-已删除;11-已融资;7-已退回;6-拒签收;5-已签收
                return item;
              } else {
                return null;
              }
            })
          }*/

          this.setState({ data: datas });
        }
	}
      }
      })
    } catch (e) {
    }
  }

  //银行直融查询
  onQueryBankFinancing = (table) => {
    try {
      const { url } = this.state.dataUrl;
      fetching(url.query).then(res => {
        if (res !== undefined) {
          if (res.code === 200) {
            let datas = res.data.filter(item => {
              item.operator = item.user ? item.user.name : '';
              return item;
            })
            this.setState({ data: datas });
          } else {
            alert(res.msg || '查询失败')
          }
        } else {
          alert('查询失败')
        }

      })
    } catch (e){
      console.log(e);
    }

  }

  //还款信息查询--20201229
  onQueryReturnInformation=()=>{
    try {
      const { url } = this.state.dataUrl;
      //还款查询
      fetching(url.query).then(res => {
        if (res !== undefined) {
          if (res.code === 200) {
            let datas = res.data.filter(item => {
              //过滤到所有查询出来的列表的已删除信息--20201223
              if (item.StatusId === 12) {
                return null;
              }
              item.operator = item.user ? item.user.name : '';
              return item;
            })
            this.setState({ data: datas });
          } else {
            alert(res.msg || '查询失败')
          }
        } else {
          alert('查询失败')
        }

      })
    } catch (e) {
    }
  }

  //还款明细查询--20210508
  getRepaymentDetails=()=>{
    try {
      const { url } = this.state.dataUrl;
      const enterprise_no = this.state.auth.organization.enterprise_no;
      //查询
      fetching(url.query+enterprise_no).then(data => {
        if (data !== undefined && data !==null) {
          let datas = data.filter(item => {
            item.receive = (Number(item.accountPayee)/100) ? (Number(item.accountPayee)/100) : (Number(item.accountPayer)/100);
            item.pay = (Number(item.accountPayer)/100) ? (Number(item.accountPayer)/100) : (Number(item.accountPayee)/100);
            if (item.status === 1) {
              item.payStatus = "成功";
            } else {
              item.payStatus = "失败";
            }
            return item;
          })
          this.setState({ data : datas });
        } else {
          alert('查询失败')
        }
      })
    } catch (e) {
    }
  }
  getFinancingRate =() =>{
    try{
      const { url } = this.state.dataUrl;  
      const creditNo = this.state.auth.organization.credit_no;
      // const creditNo='91610000078618636Q'
      fetching(`${url.query+creditNo}`).then(res => {
        if(res){
          this.setState({ data: res })
          }
      })
    }catch (e) {
    }
  }
  
  //[客户端]还款查询--20201222
  onQueryRepayment = () => {
    try {
      const { url } = this.state.dataUrl;
      //还款查询
      var type = 2;//type: 1-后端/全部；2-前端/指定公司
      fetching(url.query + type).then(res => {
        if (res !== undefined) {
          if (res.code === 200) {
            let datas = res.data.filter(item => {
              //过滤到所有查询出来的列表的已删除信息--20201223
              if (item.StatusId === 12) {
                return null;
              }
              if(item.payment_type === "还款日不固定"){
                item.amount = item.amount + item.interest
              }
              item.operator = item.user ? item.user.name : '';
              return item;
            })
            this.setState({ data: datas });
          } else {
            alert(res.msg || '查询失败')
          }
        } else {
          alert('查询失败')
        }

      })
    } catch (e) {
    }
  }

  //合同管理查询
  onQueryContract = () => {
    try {
      const { auth } = this.state;
      const { url } = this.state.dataUrl;
      fetching(`${url.query}${auth.organization.credit_no}`).then(res => {
        if (res !== undefined) {
          if (res.code === 200) {
            this.setState({ data: res.data });
          } else {
            alert(res.msg || '查询失败')
          }
        } else {
          alert('查询失败')
        }
      })
    } catch (e) {
    }
  }
  //查询已保存
  onQuerySave = () => {

    try {
      const { url } = this.state.dataUrl;
      fetching(url.query).then(data => {
        if (data !== undefined && data !==null) {
          let datas = data.map(item => {
            item.status = item.status ? item.status.name : '';
            item.operator = item.user ? item.user.name : '';
            item.payer = '善美平台';
            //开立日
            item.open_day = item.open_day ? item.open_day : item.flow_date;
            return item;

          })
          this.setState({ data: datas });
        }

      })
    } catch (e) {
    }
  }
  //查询企业额度
  async OnQuerys3(auth) {
    const { organization } = auth;
    //获取企业授信额度
    const fet = await fetching('/msapi/totalamount/find?organization_id=' + organization.id).then(data => {
      if (Array.isArray(data) && data.length > 0) {
        const { mx_amount, synthesis_rate, used_amount, usable_amount } = data[0];
        this.setState({ params: { synthesis_rate, mx_amount, usable_amount, used_amount } })
      }
      return data;
    })
    return fet;
  }
  //查询多个状态
  onQuery2 = (table) => {
    try {
      const { url } = this.state.dataUrl;
      let fetchingData = [];
      if (table === 'm2-5-1') fetchingData = [fetching(url.query), fetching(url.query1), fetching(url.query2)]
      else if (table === 'm2-1') fetchingData = [fetching(url.query), fetching(url.query2), fetching(url.query3)]
      // else if (table === 'm2-5-2') fetchingData = [fetching(url.query), fetching(url.query2)]
      else if (table === 'm2-5-3') fetchingData = [
        fetching(url.query), fetching(url.query3)
      ]
      //else if (table === 'm3-1-1') fetchingData = [fetching(url.query), fetching(url.query2), fetching(url.query3)]
      else if (table === 'm3-1-1') fetchingData = [fetching(url.query), fetching(url.query2), fetching(url.query3), fetching(url.query4), fetching(url.query5)]
      else if (table === 'm3-1-3') fetchingData = [fetching(url.query), fetching(url.query2)]
      else if (table === 'm3-1-7') fetchingData = [fetching(url.query), fetching(url.query2)]
      //else if (table === 'm3-1-7') fetchingData = [fetching(url.query), fetching(url.query2), fetching(url.query3), fetching(url.query4)]
      else if (table === 'm3-1-10') fetchingData = [
        fetching(url.query), /*fetching(url.query2),*/ fetching(url.query3)
      ]
      else if (table === 'm3-2-2') fetchingData = [fetching(url.query), fetching(url.query2)]
      else if (table === 'm3-2-8') fetchingData = [
        fetching(url.query), fetching(url.query2)
      ]
      else if (table === 'm7-1') fetchingData = [fetching(url.query), fetching(url.query2)]

      Promise.all(fetchingData).then(values => {
        let data = []
        values.forEach(item => {
          if (item instanceof Array) {
            data = data.concat(item);
          }
        })
        data = data.filter(item => {
          /*if ((table === 'm2-5-1' || table === 'm2-1' || table === 'm3-1-3' || table === 'm3-2-3') && item.StatusId === 12) {//m3-1-3:支付融单查看;m3-2-3:融资查看;m2-1:我的融单;m2-5-1：开立中融单
            return null;
          }*/
          //过滤到所有查询出来的列表的已删除信息--20201223
          item.statusId = item.status_id;
          if (item.statusId === 12) {
            return null;
          }
          if (item.statusId !== 1) {
            //前台只能看到审核中或完成
            if (item.statusId === 8 || item.statusId === 10) {
              item.status = '审核中'
            } else {
              item.status = item.status ? item.status.name : '';
            }
            item.operator = item.user ? item.user.name : '';
            //William: 如果是开立过来 支付方=开立方   如果是流转过来的   支付方=流转方
            //item.payer = '融单平台';
	    if(table === 'm3-2-8' || table === 'm3-1-1'){
	     if (item.mxf_number !== undefined){
              item.payer = item.circulation ? item.circulation : item.extraction;
	     } else {
		item.payer = item.mx_payer ? item.mx_payer : item.extraction;	
             }//else{
              //item.payer = item.mx_payer ? item.mx_payer : item.extraction;
            }
            //item.payer = item.mx_payer ? item.mx_payer : item.extraction;
            /*let Flows = item.Flows || item.FinancingFlows || item.CirculationFlows || [];
            if (Flows.length > 0) {
              item.reason = Flows[0].check_opinion*/
            let flows = item.flows || item.financingFlows || item.circulationFlows || [];
            if (flows.length > 0 && item.status_id === 7) {
              item.reason = flows[flows.length - 1].check_opinion
            }
            return item;
          } else {
            return null;
          }

        });
        this.setState({ data });
      });
    } catch (e) {
    }

  }
  //查询可用融单页面
  onQueryUsable = () => {
    const { url } = this.state.dataUrl;
    let fetchingData = [fetching(url.query), fetching(url.query2)]
    Promise.all(fetchingData).then(values => {
      let data = []
      values.forEach(item => {
        if (item instanceof Array) {
          data = data.concat(item);
        }
        
      })
      data = data.filter(item => {

        item.operator = item.user ? item.user.name : '';
        item.payer = item.mx_payer ? item.mx_payer : item.extraction;
        // item.payer = '融单平台';
        return item;

        // William Jiang: 後臺已經做了判斷了。
        //可用金额为0了就不显示
        // if (Number(item.usable_amount) > 0) {
        //   return item;
        // }
      });
      this.setState({ data });
    });
  }
  //查询角色数据
  onQuery1 = () => {
    try {
      const { url } = this.state.dataUrl;
      fetching(`${url.query}`).then(data => {
        if (data !== undefined && data !==null) {
          let datas = data.map(item => {
            if (item.status) {
              item.status = item.status.name;
            }
            if (item.roles && item.roles.length > 0) {
              item.roleName = item.roles[0].name;
            }
            return item
          })
          this.setState({ data: datas });
        }

      })
    } catch (e) {
    }
  }
  //获取本地保存的数据
  localStorageData = (table, name) => {
    let localStorageData = [];
    let issue = null;
    if (table === 'm2-3' || table === 'm3-1-8' || table === 'm3-2-1') {
      issue = localStorage.getItem('issue');
    }
    if (issue) {
      let issue2 = JSON.parse(issue);
      let issue3 = issue2.find(item => item.name === name);
      if (issue3) localStorageData = issue3[table];
    }
    return localStorageData;
  }

  //核心企业3表单提交
  FormSubmit = (newData, data) => {
    // let datas = data || this.state.data || [];
    const { dataUrl, exercise, auth } = this.state;
    if (newData.submit_name === '提交') {
      if (newData.formDates) {
        // datas = this.deleteSave(newData.formDates);
      } else {
        setTimeout(() => {
          this.fetchings(dataUrl, exercise.table, auth)
        }, 1000)
      }
      this.setState({
        exercise: { ...this.state.exercise, open: false, open2: false }
      })
    } else {
      setTimeout(() => {
        this.fetchings(dataUrl, exercise.table, auth)
      }, 1000)
      this.setState({
        exercise: { ...this.state.exercise, open: false, open2: false }
      })
    }

  }
  //用户管理
  FormSubmit2 = (newData) => {
    newData.formDate = new Date();
    if (newData.submit_name === '提交') {
      this.onQuery1();
    }
    this.setState({
      exercise: { ...this.state.exercise, open: false }
    })
  }

  //提交修改的银行卡信息2021.3.19
  FormSubmit4 = (newData) => {
    newData.formDate = new Date();
    if (newData.submit_name === '提交') {
      this.onQueryCompany();
    }
    this.setState({
      exercise: { ...this.state.exercise, open: false }
    })
  }
  //公共表单提交
  FormSubmit3 = (newData) => {
    const { dataUrl, exercise, auth } = this.state;

    this.setState({
      exercise: { ...this.state.exercise, checkOpen: false }
    })

    setTimeout(() => {
      this.fetchings(dataUrl, exercise.table, auth)
    }, 1000)
  }
  // 对象查找
  findObject = (obj, data) => {
    let index = data.findIndex(item => item.id === obj.id);
    return index;
  }
  //新增开具表单
  handleToggle = (open) => {
    //处理[可用融单]既有开立又有流转时，先点击流转功能弹出弹窗后未刷新页面，立即使用[可用融单]中的开立进行流转/融资，致使ocid_from出错--20210118
    delete this.state.exercise.mxc_number;
    
    const { table } = this.props;
    const { form2, exercise, auth } = this.state
    if (open) {
      if (table === 'm3-1-2') {//m3-1-2:可用融单
        this.onQueryUsable();
      }
      this.setState({
        exercise: { ...exercise, open: false, open2: false }
      })
    } else {
      //操作员发起开立: m2-3:融单开具制单 -- 20210119
      /*if (exercise.save === '1' && auth.department.id === 7 && exercise.table === 'm2-3') {//开立新增：单独[融单开具制单]-融资-save=1
        const organizationId = auth.organization.id;
        const enterpriseName = auth.organization.name;//20210111
        const openCreditCode = auth.organization.credit_no;//20210119
        fetching('/msapi/totalamount/find?organization_id=' + organizationId).then(data => {
          if (Array.isArray(data) && data.length > 0) {
            const { used_amount } = data[0];
    
            //增加[可用额度]检测校准--20210118
            if (exercise.save === '1' && auth.department.id === 7) {//开立新增：单独[融单开具制单]-融资-save=1
              //enterpriseName, String openCreditCode, Double usedAmount
              const usedAmount = used_amount ? used_amount : 0;
              fetching(`/amount/usedAmount/getOUsedAmount?enterpriseName=${enterpriseName}&openCreditCode=${openCreditCode}&usedAmount=${usedAmount}`).then(res => {
                if (res.code === 200) {
                  this.setState({
                    exercise: { ...exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
                  })
                } else {
                  alert(res.msg || '可用额度拦截查验失败！')
                }
              })
            }
          }
        })
      } else {*/
        this.setState({
          exercise: { ...exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
        })
      //}
    }
  }
  //融资
  financings = (e, row) => {
    const { form2, exercise } = this.state
    const body5 ={
      id: row.id,
      extraction:row.extraction,
      applicant: row.receive || row.applicant,
      amount: row.amount,
      netFinancingAmount: row.net_financing_amount,
      mxNumber: row.mx_number,
      mxfNumber: row.mxf_number,
    }
      fetching('/loan/paymentRegistrationStatus', {
        method: 'POST',
        body: JSON.stringify(body5)
      }).then(res => { 
     //if (false) {//2022/12/19日修改 
     if (res.code !== 200) {
          let url = DEFAULT_ACCOUNT_URL;
          if(window.confirm("您的清算支付账户暂未注册，请进入清算注册界面完成注册！如有问题请联系：029-81132605/029-81772305")){
            window.open(url)
            return true;  //打开清算注册页面                
          }else{
            return false; //不打开                 
          }
        } else {
          this.setState({
            exercise: { ...exercise, ...row, status2: '1', DialogTitle: form2.add, save: '4', open: false, open2: true }
          })
        }
      })
  }
  //流转
  payers = (e, row) => {
    const { form2, exercise } = this.state
    this.setState({
      exercise: { ...exercise, ...row, status2: '1', DialogTitle: form2.add, save: '4', open: true, open2: false }
    })
  }

  //公共表单
  handleCommon = (open) => {
    const { form2, exercise } = this.state;

    if (open) {
      this.setState({
        exercise: { ...exercise, checkOpen: false, },
      })
      window.location.reload()//签收页面强制刷新功能
    } else {

      this.setState({
        exercise: { ...exercise, status2: '2', DialogTitle: form2.check, save: '3', checkOpen: true },
      })
    }
  }

  onQueryIssueSummations = () => {
    let fetchingData = [];
    const { table } = this.props;
    const { url } = this.state.dataUrl;
     if(table === 'm2-5-2') fetchingData = [fetching(url.query), fetching(url.query2)]
        Promise.all(fetchingData).then(values => {
          let data = []
          values.forEach(item => {
          if (item instanceof Array) {
            data = data.concat(item);
          }
        })
        let datas = data.filter(item => { 
          item.operator = item.user ? item.user.name : '';
          item.payer = item.circulation ? item.circulation : item.extraction;
          item.status = item.status ? item.status.name : '';
          return item;
        })
          this.state.sum.sumData=datas;
          this.setState({ data:datas });
          this.handleChild(this.state.sum)
        });
    }
  //支付表单
  handlePayment = () => {
    const { form2, exercise } = this.state;
    this.setState({
      exercise: { ...exercise, status2: '2', DialogTitle: form2.check, save: '3', checkOpen: true },
    })
  };

  //  表格新增
  onAdd = newData => {

    return defer(0).then(console.log('newData ---: ', newData));
  }
  //表格编辑
  onUpdate = (newData, oldData) => {

    return defer(60).then(console.log(' newData, oldData ---: ', newData, oldData));
  }
  //表格删除
  onDelete = async oldData => {
    const { dataUrl } = this.state
    if (oldData.formDates) {
      this.deleteSave(oldData.formDates);
    } else {
      const returnedData = await fetching(dataUrl.url.get2 + oldData.id, { method: 'DELETE' })
      returnedData && this.onQuery();
      return returnedData;
    }


  }

  //用户删除
  onDelete1 = async oldData => {
    const { dataUrl } = this.state
    if (oldData.formDates) {
      this.deleteSave(oldData.formDates);
    } else {
     if (oldData.isAdmin === true) {
        alert("企业管理员账户不能删除!!!")
        this.onQuery1();
        return null;
      } else {
       const returnedData = await fetching(dataUrl.url.get2 + oldData.id, { method: 'GET' })
       returnedData && this.onQuery1();
       return returnedData;
      }
    }
  }
  //表格删除已保存状态的数据
  deleteSave = (formDates) => {
    let issue = localStorage.getItem('issue');
    issue = issue ? JSON.parse(issue) : [];
    let name = this.state.auth.account
    const { table } = this.props;
    let index = issue.findIndex(item => item.name === name);//获取对应账号数据的index
    let index2 = issue[index][table].findIndex(item => item.formDates === formDates)
    if (index2 !== -1) issue[index][table].splice(index2, 1);
    localStorage.setItem('issue', JSON.stringify(issue));
    let data = issue[index][table];
    this.setState({ data: JSON.parse(JSON.stringify(data)) });
    return data;
  }
  //编辑用户
  onUpdateUser = (e, row) => {
    const { form2, dataUrl, exercise } = this.state
    let status2 = '1'
    // this.setState({ exercise: { ...exercise, ...row, save: '2', DialogTitle: form2.edit, status2, open: true, } });
    fetching(dataUrl.url.get + row.id).then(data => {
      if (data !== undefined && data !==null)
        this.setState({ exercise: { ...exercise, ...data, save: '2', DialogTitle: form2.edit, status2, open: true, } });
    })
  }
  handleChild = (data)=> {
    this.$Child.childConsole(data);    // this上有了子组件方法
 }

  //将数据置为已删除状态（12）--20201103
  onDeleteStatus = (e, row) => {
    const { dataUrl, exercise, auth } = this.state;//20201207
    const id = row.id;
    const { table } = this.props;
    var url = "";
    if (table === 'm2-5-3' || table === 'm2-5-5') {//已退回融单
      url = `/msapi/issue/del/${id}/put_status`;
    } else if (table === 'm3-1-10') {//已退回流转
      url = `/msapi/circulation/del/${id}/put_status`;
    } else if (table === 'm3-2-8') {//已退回融资
      url = `/msapi/financing/del/${id}/put_status`;
    } else if (table === 'm7-2') {
      url = `/msapi/receivable/del/${id}`;
    }
    if(window.confirm("是否删除该条数据,数据删除后不可恢复")){
    fetching(url).then(res => {
      if (res !== undefined && res !==null) {
        //if (!res.status) alert(res.msg)
        //res && this.onQuery(); 
        alert("删除成功");
        this.fetchings(dataUrl, exercise.table, auth);//20201207
      } else {
        alert("删除失败" + res);
      }
    })
    return true;
  } else {
    return false;
  }
  }


  //编辑开具制单
  onUpdate2 = (e, row) => {
    const { form2, dataUrl, exercise } = this.state
    let status2 = '1'
    // this.setState({ exercise: { ...exercise, ...row, save: '2', DialogTitle: form2.edit, status2, open: true, } });
    fetching(dataUrl.url.get2 + row.id).then(data => {
      if (data !== undefined && data !==null) {
        data.Upload_contracts = '';
        data.Upload_invoice = '';
        data.Upload_contracts_invoice = '';
        data.Upload_rest = '';
        this.setState({
          exercise: {
            ...exercise, ...data, save: '2',
            DialogTitle: form2.edit, status2, open: true,
          }
        });
      } else {
        alert('查询失败')
      }
    })
  }
  //编辑保存开具制单
  onUpdate3 = (e, row) => {
    const { form2, exercise } = this.state
    let status2 = '1'
    this.setState({
      exercise: {
        ...exercise, ...row, save: '2',
        DialogTitle: form2.edit, status2, open: true,
      }
    });

  }
  //查看开具制单1
  onview = (e, row) => {
    const { form2, dataUrl, exercise } = this.state
    // this.setState({ exercise: { ...exercise, ...row, save: '3', status2: '', DialogTitle: form2.view, open: true, } });
    const url = dataUrl.url.get2 || dataUrl.url.query;
    fetching(url + row.id).then(data => {
      if (data !== undefined && data !==null) {
        this.setState({
          exercise: {
            ...exercise,
            ...data,
            save: '3',
            status2: '',
            DialogTitle: form2.view,
            open: true,
          }
        });
      }

    })

  }

  onCebSkip = (e,row) =>{
    if (row.distribute === '光大银行'){
      const mxf_number = row.mxf_number
      const UpGwMsg = "";
      fetching(`/msapi/ceb/gatewayCebString?mxf_number=` + mxf_number,{
        method:'get' , 
      }).then(res =>{
        if(res.code === 200){
            const param ={
              "UpGwMsg":res.data
            }
            Post("https://ebank.cebbank.com/cebent/preRELLogin.do?_locale=zh_CN", param);
            function  Post(URL, PARAMS) {
              var temp = document.createElement("form");
              temp.action = URL;
              temp.method = "post";
                    //如需新打开窗口 form 的target属性要设置为'_blank'
              temp.target = "_blank";
              temp.style.display = "none";
              for (var x in PARAMS) {
                var opt = document.createElement("textarea");
                opt.name = x;
                opt.value = PARAMS[x];
                temp.appendChild(opt);
              }
              document.body.appendChild(temp);
              temp.submit();
              return temp;
            }
        
        }
      })
    } else {
      alert("非光大直融不需要进行光大数字签名")
    }
  }
  //开具复核-审核
  onCheck = (e, row) => {
    // department_id  department

    const { form2, dataUrl, exercise, auth } = this.state
    const url = dataUrl.url.get || dataUrl.url.query;
    let mxc_number = ``;
    if (row.flow_date) mxc_number = `cid=${row.id}`
    else if (row.mxf_number) mxc_number = `mxf_number=${row.mxf_number}`;

    let department_cond = ``;
    if (parseInt(auth.department.id) === 7) department_cond = `department_id=7`;
    fetching(url + `?mx_number=${row.mx_number}&${department_cond}&${mxc_number}`).then(data => {
      this.setState({
        exercise: {
          ...exercise,
          ...row,
          save: '3',
          DialogTitle: form2.check,
          status2: '2',
          rolseId: '1',
          checkOpen: true,
          checkStatus: '3',
          opinion_list: data || []
        },
      });
    })
  }
  //开具复核-退回
  onCheck2 = (e, row) => {
    const { dataUrl, exercise, auth } = this.state
    const url = dataUrl.url.get || dataUrl.url.query;
    let mxc_number = ``;
    if (row.flow_date) mxc_number = `cid=${row.id}`
    else if (row.mxf_number) mxc_number = `mxf_number=${row.mxf_number}`

    let department_cond = ``;
    if (parseInt(auth.department.id) === 7) department_cond = `department_id=7`;

    fetching(url + `?mx_number=${row.mx_number}&${department_cond}&${mxc_number}`).then(data => {
      this.setState({
        exercise: {
          ...exercise,
          ...row,
          save: '3',
          DialogTitle: '退回',
          status2: '2',
          checkStatus: '2',
          checkOpen: true,
          opinion_list: data || []
        },
      });
    })
  }
  
  //融单流转 签收
  signFor = (e, row) => {
    const { form2, exercise, auth } = this.state;
    let mxc_number = ``;
    let url = `/msapi/flow/find`
    if (row.flow_date) {//流转
      mxc_number = `cid=${row.id}`
      url = `/msapi/cflow/find`
    }
    else if (row.mxf_number) {//融资
      mxc_number = `mxf_number=${row.mxf_number}`
      url = `/msapi/fflow/find`
    }

    fetching(url + `?mx_number=${row.mx_number}&department_id=${auth.department.id}&${mxc_number}`).then(values => {

      this.setState({
        exercise: {
          ...exercise,
          ...row,
          save: '3',
          DialogTitle: form2.signFor,
          status2: '2',
          checkStatus: '5',
          checkOpen: true,
          opinion_list: values || []
        }
      });
    });

  }
  //融单流转 拒绝签收
  signFor2 = (e, row) => {

    const { form2, exercise, auth } = this.state;
    let mxc_number = ``;
    let url = `/msapi/flow/find`
    if (row.flow_date) {
      mxc_number = `cid=${row.id}`
      url = `/msapi/cflow/find`
    }
    else if (row.mxf_number) {
      mxc_number = `mxf_number=${row.mxf_number}`
      url = `/msapi/fflow/find`
    }

    fetching(url + `?mx_number=${row.mx_number}&department_id=${auth.department.id}&${mxc_number}`).then(values => {

      this.setState({
        exercise: {
          ...exercise,
          ...row,
          save: '3',
          DialogTitle: form2.refuse_signFor,
          status2: '2',
          checkStatus: '6',
          checkOpen: true,
          opinion_list: values || []
        }
      });
    });
  }
  //重置密码
  onResetPwd = (e, row) => {
    const { form2 } = this.state
    this.setState({ exercise: { id: row.id, account: row.account, DialogTitle: form2.resetPwd, open: true, } });
  }
  //搜索框Change
  onSearchChange = (query) => {
    console.log('onSearchChange', query);
  }
  // 对data数据进行排序
  sort = (orderBy, orderDirection,data) => {
    // 对数据进行排序操作
      switch (orderBy) {
        // case -1:  
        //   this.state.orderBy = ''
        //   this.state.orderDirection = ''
        // this.handleSearchChange(this.state.query)
        //   break;
        case 0:
          data.sort((a, b) => {
              var aa = a.status;
              var bb = b.status
            if (orderDirection === 'desc') {
              return bb.localeCompare(aa, 'en-US-u-kn-true');
            } else {
              return aa.localeCompare(bb, 'en-US-u-kn-true');
            }
          });
         break;
        case 1:
          data.sort((a, b) => {
            var aa = a.mx_number;
            var bb = b.mx_number
          if (orderDirection === 'desc') {
            return bb.localeCompare(aa);
          } else {
            return aa.localeCompare(bb);
          }
        });
          break;
        case 2:
            data.sort((a, b) => {
              var aa = a.extraction;
              var bb = b.extraction
            if (orderDirection === 'desc') {
              return aa.localeCompare(bb, 'en-US-u-kn-true');
            } else {
              return bb.localeCompare(aa, 'en-US-u-kn-true');
            }
          });
          break;
        case 3:
          data.sort((a, b) => {
            var aa = a.receive;
            var bb = b.receive
          if (orderDirection === 'desc') {
            return bb.localeCompare(aa, 'en-US-u-kn-true');
          } else {
            return aa.localeCompare(bb, 'en-US-u-kn-true');
          }
        });
          break;
        case 4:
          data.sort((a, b) => {
            var aa = a.amount;
            var bb = b.amount
          if (orderDirection === 'desc') {
            return bb - aa;
          } else {
            return aa - bb;
          }
        });
          break;
        case 5:
          data.sort((a, b) => {
            var aa = a.payment_day;
            var bb = b.payment_day
          if (orderDirection === 'desc') {
            return bb.localeCompare(aa, 'en-US-u-kn-true');
          } else {
            return aa.localeCompare(bb, 'en-US-u-kn-true');
          }
        });
          break;
        case 6:
          data.sort((a, b) => {
            var aa = a.open_day;
            var bb = b.open_day
          if (orderDirection === 'desc') {
            return bb.localeCompare(aa, 'en-US-u-kn-true');
          } else {
            return aa.localeCompare(bb, 'en-US-u-kn-true');
          }
        });
          break;
        case 7:
          data.sort((a, b) => {
            var aa = a.operator;
            var bb = b.operator
          if (orderDirection === 'desc') {
            return aa.localeCompare(bb, 'en-US-u-kn-true');
          } else {
            return bb.localeCompare(aa, 'en-US-u-kn-true');
          }
        });
          break;
        case 8:
          data.sort((a, b) => {
            var aa = a.reviewer;
            var bb = b.reviewer
          if (orderDirection === 'desc') {
            return bb - aa;
          } else {
            return aa - bb;
          }
        });
          break;
        case 9:
          data.sort((a, b) => {
            var aa = a.remarks;
            var bb = b.remarks
          if (orderDirection === 'desc') {
            return bb - aa;
          } else {
            return aa - bb;
          }
        });
          break;
        default:
      }
  };

  // 搜索框统计融单合计金额
  handleSearchChange = (searchText) => {
    if(this.props.table === 'm2-5-2'){
      this.state.query = searchText;
      var oldData = this.state.data.slice();
      if (searchText === '') {
        if (this.state.orderDirection === '') {
          this.state.sum.sumData = oldData
          this.state.sum.page = 1
          this.handleChild(this.state.sum);
        } else {
          this.sort(this.state.orderBy,this.state.orderDirection,oldData)
          this.state.sum.sumData = oldData
          this.state.sum.page = 1
          this.handleChild(this.state.sum);
        }
        
      }else {
        if (this.state.orderDirection === '') {
          this.searchChange(searchText,oldData)
          this.state.sum.page = 1
          this.handleChild(this.state.sum);
        } else {
          this.sort(this.state.orderBy,this.state.orderDirection,oldData)
          this.searchChange(searchText,oldData)
          this.state.sum.page = 1
          this.handleChild(this.state.sum);
        }
      }
    }
  }

  // 排序统计融单合计金额
  handleSort = (orderBy, orderDirection) => {
    if(this.props.table === 'm2-5-2'){
      this.state.orderBy = orderBy
      this.state.orderDirection = orderDirection
      var oldData = this.state.data.slice()
      if (this.state.query === '') {
        if (orderBy === -1) {
          this.state.sum.sumData = oldData
          this.state.sum.page = 1
          this.handleChild(this.state.sum);
        } else {
          this.sort(orderBy, orderDirection,oldData)
          this.state.sum.sumData = oldData
          this.state.sum.page = 1
          this.handleChild(this.state.sum);
        }
      } else {
        if (orderBy === -1) {
          this.searchChange(this.state.query,oldData)
          this.state.sum.page = 1
          this.handleChild(this.state.sum);
        } else {
          this.searchChange(this.state.query,oldData)
          this.sort(orderBy, orderDirection,this.state.sum.sumData)
          this.state.sum.page = 1
          this.handleChild(this.state.sum);
        }
      }
    }
  }

  // 搜索data中包含的字符
  searchChange = (searchText,data) => {
    //debugger
    if (this.props.table === 'm2-5-2') {
        this.state.query = searchText
      if (searchText !== "" || this.state.orderBy !== '') {
        var newData = data.filter(item => {
          var amount = item.amount + ''
          switch (true) {
            case (item.status.includes(searchText)):
              return item.status.includes(searchText)
            case (item.mx_number.includes(searchText)):
              return item.mx_number.includes(searchText);
            case (item.extraction.includes(searchText)):
              return item.extraction.includes(searchText);
            case (item.receive.includes(searchText)):
              return item.receive.includes(searchText);
            case (amount.includes(searchText)):
              return amount.includes(searchText);
            case (item.payment_day.includes(searchText)):
              return item.payment_day.includes(searchText);
            case (item.open_day.includes(searchText)):
              return item.open_day.includes(searchText);
            case (item.operator.includes(searchText)):
              return item.operator.includes(searchText);
              default:
          }
        });
        this.state.sum.sumData = newData
        //debugger
      }
    }
  };
  //页面更改事件
  onChangePage = (query) => {
    console.log('onChangePage', query);
    if(query!==0){
      this.state.sum.page=query+1
    }else{
      this.state.sum.page=1;
    }
    if(this.props.table === 'm2-5-2'){
    this.handleChild(this.state.sum);
    }
  }
  //处理每页的行更改（pageSize）
  onChangeRowsPerPage = (query) => {
    console.log('onChangeRowsPerPage', query);
    if(query){
      this.state.sum.rowsPerPage=query
      this.state.sum.page=1
    }
    if(this.props.table === 'm2-5-2'){
    this.handleChild(this.state.sum);
    }
  }
  //处理多选选择的事件
  onSelectionChange = (data) => {
    console.log('onSelectionChange', data);
  }


  //查看信息表
  onViewInfo = (e, row) => {
    if (!row.fdd_xxb_path) {
      alert('暂无《善美融单信息单》')
      return;
    }
    window.open(row.fdd_xxb_path)
  }
  //下载信息表
  onUploadInfo = (e, row) => {
    if (!row.fdd_xxb_name) {
      alert('暂无《善美融单信息单》')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_xxb_name}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  //下载融资回单
  onUploadRZHD = (e, row) => {
    if (!row.fdd_rzhd_path) {
    //if (!row.fdd_file_name) {
      alert('暂无《善美融资回单》')
      return;
    }else{
	window.open(row.fdd_rzhd_path)
     }    
  }

  //跳转浙商H5网关
  onForwardCZBH5 = (e, row) => {
    if (row.distribute === '浙商银行') {
      fetching(`/msapi/czb/applyFinancingGetH5?mxfNumber=${row.mxfNumber}&traceno=${row.czbSerialNumber}`).then(res => {
        if (res !== undefined) {
          if (res.code === 200) {
            window.open(res.data)
          } else {
            alert(`跳转失败,${res.msg}`)
          }
        } else {
          alert(`跳转失败`)
        }
      })
    
    }
	
       
  }

  //查询浙商直融结果
  applyFinancingResult = (e, row) => {
    if (row.distribute === '浙商银行') {
      fetching(`/msapi/czb/applyFinancingResult?mxfNumber=${row.mxfNumber}`).then(res => {
        if (res !== undefined) {
          if (res.code === 200) {
            alert(`查询成功,${res.msg}`)
          } else {
            alert(`查询失败,${res.msg}`)
          }
        } else {
          alert(`查询失败`)
        }
      })
    
    }
	
       
  }

  //查看合同
  onViewContract = (e, row) => {
    if (!row.fddFilePath || row.fddFilePath === 'null') {
      alert('暂无合同')
      return;
    }
    window.open(row.fddFilePath)
  }

  //下载应收转让通知书
  onUploadZrFile = (e, row) => {
    if (!row.zr_file_path || row.zr_file_path === 'null') {
      alert('暂无应收转让通知书')
      return;
    }
    window.open(row.zr_file_path)
  }

  //下载合同
  onUploadContract = (e, row) => {
    if (!row.fddFileName || row.fddFileName === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fddFileName}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract2 = (e, row) => {
    if (!row.fdd_yssq_name || row.fdd_yssq_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_yssq_name}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract3 = (e, row) => {
    if (!row.fdd_ysqr_name || row.fdd_ysqr_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_ysqr_name}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract4 = (e, row) => {
    if (!row.fdd_ystz_name || row.fdd_ystz_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_ystz_name}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract5 = (e, row) => {
    if (!row.fdd_ysxxb_name || row.fdd_ysxxb_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_ysxxb_name}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract6 = (e, row) => {
    if (!row.fdd_yshz_name || row.fdd_yshz_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_yshz_name}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

     //下载放款回单
handleUpload = (e, row) => {
  try { 
  let type = 40;//中金/清算分账回单类型(订死的)
  if (!row.serialNumber || row.serialNumber === 'null') {
    alert('暂无回单')
    return;
  }  
  fetching(`/loan/getReceipt?txSn=${row.serialNumber}&type=${type}`,{
    method: 'GET',
  }).then(res => {
    if (res !== undefined && res.data !== null && res.data !== undefined && res.data !== "") {
      if(JSON.parse(res.data).data.status === null || JSON.parse(res.data).data.status === '20'){
        alert(`下载失败,`+ JSON.parse(res.data).data.responseMessage)
      } else {
      //window.open(res.data)
      window.open(JSON.parse(res.data).data.downloadUrl)
      }
    } else {
      alert(`下载失败,请联系管理员`)
    }
  })
    } catch (error) { }
  
}

 //下载还款回单
 handleUpload1 = (e, row) => {
  try { 
  let url = "";
  if (!row.orderNo || row.orderNo === 'null') {
    alert('暂无回单')
    return;
  }  
  if(!row.paId || row.paId === "null"){
   let type = 40;//中金/清算支付回单类型(订死的)
   url = `/loan/getrRepaymentReceipt?txSn=${row.serialNumber}&type=${type}&orderNo=${row.orderNo}`
  } else {
  let type = 40;//中金/清算分账回单类型(订死的)
   url = `/loan/getrRepaymentReceipt?txSn=${row.serialNumber}&type=${type}&paId=${row.paId}&orderNo=${row.orderNo}&id=${row.id}`
  } 
  fetching(url,{
    method: 'GET',
  }).then(res => {
    if (res !== undefined && res.data !== null && res.data !== undefined && res.data !== "") {
      if (res.data.data.length !== undefined) {
        let datas = res.data.data.filter(item => {
          if (item.data.status === '20') {
            alert(`下载失败,`+ item.data.responseMessage)
          } else {
            window.open(item.data.downloadUrl)
          }
          return null;
        })
      } else {
        if (res.data.data.status === '20') {
          alert(`下载失败,`+ res.data.data.responseMessage)
        } else {
          window.open(res.data.data.downloadUrl)
        }
      }
      
    } else {
      alert(`下载失败,请联系管理员`)
    }
  })
    } catch (error) { }
  
}

  export = () => {
    const table = this.props.table
    const { auth } = this.state;
    let creditNo = auth.organization.credit_no
    let url = ''
    if (table === 'm6-1') {
      url = 'contract/excel/zc?creditNo='
    } else if (table === 'm6-2') {
      url = 'contract/excel/issues?creditNo='
    } else if (table === 'm6-3') {
      url = 'contract/excel/financings?creditNo='
    }
    if (!url) {
      alert('接口为空')
      return
    }
    window.open(`${DEFAULT_EXPORT_URL}${url}${creditNo}`)
    // fetching(`${url}${creditNo}`).then(res => {
    //   try {
    //     if (res.code === 200) {
    //       window.open(res.data)
    //     } else {
    //       alert(`导出失败,${res.msg}`)
    //     }
    //   } catch (error) {

    //   }

    // })
  }

  //触发打开时间段选项弹窗--20201118
  exportTableYWD = (e) => {
    const { exercise, form2, dataUrl, auth } = this.state;
    let creditNo = auth.organization.credit_no;
    //open: true--详情页；checkOpen: true--公共表单
    //type: 1-后端/全部；2-前端/指定公司
    this.setState({
      exercise: { ...exercise, save: '3', DialogTitle: form2.exportTable, open: false, tableName: dataUrl.name, status2: '1', checkFlag: true, creditNo, type: 2 }
    })
  }

  //触发打开时间段选项弹窗--20201118
  handleToggleYWD = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open: false }
      })
    } else {
      this.setState({
        exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
      })
    }
  }

  //还款申请--20201218
  onRepayment = (e, rowData) => {
    const { exercise } = this.state;
    this.setState({
      exercise: { ...exercise, ...rowData, status2: '2', DialogTitle: '', save: '3', open: true },
    })

  }
  //还款审批--20201218
  onRepayment2 = (e, rowData) => {
    this.setState({
      checkExercise: {
        ...rowData,
        DialogTitle: '',
        checkStatus: '3',
        save: '1',
      },
      exercise: { ...rowData, save: '1', status2: '2', DialogTitle: '', open: true },

    });
  }
  //已还款查看--20201218
  onViewRepayment = (e, rowData) => {
    this.setState({
      checkExercise: {
        ...rowData,
        DialogTitle: '',
        checkStatus: '3',
        save: '3',
      },
      exercise: { ...rowData, save: '3', status2: '2', DialogTitle: '', open: true },

    });
  }

   //编辑修改银行卡信息--2021.04.07
onUpdate4 = (e, row) => {
  const { form2,  exercise } = this.state
  let status2 = '1'
  fetching('/msapi/account/findById?id=' + row.id).then(data => {  
    this.setState({ exercise: { ...exercise, ...data, save: '2', DialogTitle: form2.edit, status2, checkStatus: '3',open: true, } });
  })
  }

  //批量审核
  onCheck3 = (e, row) => {
    const { form2, dataUrl, exercise, auth } = this.state;
    const { roles = [{ id: 0 }] } = auth;
    let roleId = roles[0].id;
    let query = dataUrl.url.get || dataUrl.url.query;
    let DialogTitle = form2.check;
    let table = dataUrl.name;
    if (roleId === 5 || roleId === 6) {
      DialogTitle = '批量复核';
    } else{
      DialogTitle = '批量操作';
    }
    if (table === 'm4-1') {
      query = query+2;
    }
    fetching(query).then(data => {
      this.setState({
        exercise: {
          ...exercise, ...data, save: '1', DialogTitle,
          rolseId: '2',
          status2: '2', checkOpen: true, checkStatus: '3',

        },
      });
    })

  }
 onCheck4 = (e, row) => {
    const { form2, dataUrl, exercise, auth } = this.state;
    const { roles = [{ id: 0 }] } = auth;
    const { url } = this.state.dataUrl;
    let fetchingData = [];
    let roleId = roles[0].id;
    let query = dataUrl.url.get || dataUrl.url.query;
    let DialogTitle = '批量下载';
    fetchingData = [fetching(url.query), fetching(url.query2)]
    Promise.all(fetchingData).then(values => {
          let data = []
          values.forEach(item => {
            if (item instanceof Array) {
              data = data.concat(item);
            }
          })
          data = data.filter(item => {
            item.start_day = item.open_day ? item.open_day : item.flow_date ;
            item.status = item.status ? item.status.name : '';
            item.operator = item.user ? item.user.name : '';
            item.payer = item.mx_payer ? item.mx_payer : item.extraction;
            // item.role_name = this.queryName(String(item.roleId), String(item.status_id), item)
            return item;
          })
          this.setState({
            exercise: {
              ...exercise, data: data, save: '1', DialogTitle,
              rolseId: '2',
              status2: '2', checkOpen: true, checkStatus: '3',
    
            },
          });
        });

  }

  render() {
    //const { columns, data, exercise, dataUrl, auth, opinion_list, params } = this.state;
    const { columns, data, exercise, dataUrl, auth, opinion_list, params, checkExercise = {} } = this.state;//还款弹窗--20201218
    const title = dataUrl.title || '';
    const { table = '' } = this.props;
    const path = capitalize(table);
    // const actions =  actions2(this, table);
    const editable = editable2(this, table);
    const { form2 } = this.state

    //导出按钮--20201118
    const C6 = () => {
      return <div style={{ textAlign: 'end', marginBottom: '10px' }}>
        <Button variant="contained" color="primary" onClick={this.exportTableYWD}>导出</Button>
      </div>
    }

    //时间段选择弹窗--20201118
    const DownloadTime = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <DialogForm handleToggle={this.handleToggleYWD} exercise={{ ...exercise, addBtnName: common.exportTable, isBtn }}>
          <DownloadTimeForm form2={{ ...common }} exercise={{ ...exercise }} onSubmit={this.FormSubmit} />
      </DialogForm>
    </div>
}

    //公共表单
    const C21 = () => {
      return <div style={{ justifyContent: 'flex-end' }}>
        <CommonDialog handleToggle={this.handleCommon}
          exercise={{ ...exercise, addBtnName: '公共表单', isBtn: false, }}>
          <Form1 auth={auth} opinion_list={[opinion_list]} exercise={{ ...exercise, table }}
            onSubmit={this.FormSubmit3} />
        </CommonDialog>
      </div>
    }

    //应收公共表单
    const C99 = () => {
      return <CommonDialog handleToggle={this.handleCommon}
        exercise={{ ...exercise, addBtnName: '公共表单', isBtn: false, }}>
        <YSForm auth={auth} opinion_list={opinion_list} exercise={{ ...exercise, table }}
          onSubmit={this.FormSubmit3} />
      </CommonDialog>
    }


    //批量操作弹框
    const CPL = () => {
      return <CommonDialog handleToggle={this.handleCommon}
        exercise={{ ...exercise, addBtnName: '公共表单', isBtn: false, }}>
        <CheckBoxForm auth={auth} opinion_list={[]} exercise={{ ...exercise, table }}
          onSubmit={this.FormSubmit3} />
      </CommonDialog>
    }

    const T4 = () => {
      return <div style={{ textAlign: 'start', marginBottom: '10px' }}>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button style={{ marginRight: '10px',backgroundColor: 'rgb(29, 69, 131)'}} variant="contained" color="primary" onClick={() => this.onCheck3()}>批量操作</Button>
        </ButtonGroup>
      </div>
    }
     //开立合计
     const C24= () => {     
      return (
        <div>
            <C42ViewList state={this.state} onRef={(ref)=> {this.$Child=ref}}>
            </C42ViewList>
        </div>
    )
      }
    //融单开具
    const C23 = () => {
      let isBtn = table === 'm2-3' ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName1, isBtn }}>
          <C23Form auth={auth} form2={{ ...common, ...f2_3 }} exercise={{ ...exercise, ...params }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }
    //应收账款开具
    const C72 = () => {
      let isBtn = table === 'm7-2' ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName6, isBtn }}>
          <C72Form auth={auth} form2={{ ...common, ...f7_2 }} exercise={{ ...exercise, ...params }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    //应收融资
    const C751 = () => {
      let isBtn = table === 'm3-2-1' ? true : false;
      let open = exercise.open;
      if (table === 'm3-1-2') {
        open = exercise.open2;
      }
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName3, isBtn, open }}>
          <C751Form auth={auth} form2={{ ...common, ...f7_5_1 }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    //融单流转
    const C318 = () => {
      let isBtn = table === 'm3-1-8' ? true : false;
      let addBtnName = table === 'm3-1-2' ? common.addBtnName5 : common.addBtnName2;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName, isBtn }}>
          <C318Form auth={auth} form2={{ ...common, ...f3_1_8 }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }
    //融单融资
    const C321 = () => {
      
      let isBtn = table === 'm3-2-1' ? true : false;
      let open = exercise.open;
      if (table === 'm3-1-2') {
        open = exercise.open2;
      }
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName3, isBtn, open }}>
          <C321Form auth={auth} form2={{ ...common, ...f3_2_1 }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    //还款申请
    const C41 = () => {
      let isBtn = false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, isBtn }}>
          <C41Form auth={auth} form2={{ ...common, ...f4_1 }} dataUrl={dataUrl} exercise={{ ...exercise, table }} checkExercise={{ ...checkExercise, table }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    //用户管理
    const C51 = () => {
      let isBtn = table === 'm5-1' ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName4, isBtn }}>
          {(exercise.DialogTitle === form2.edit || exercise.DialogTitle === form2.add) &&
            <C52Form form2={{ ...common, ...f5_1 }} exercise={{ ...exercise }}
              onSubmit={this.FormSubmit2} />
          }
          {exercise.DialogTitle === form2.resetPwd &&
            <C51Form form2={{ ...common, ...f5_1 }} exercise={{ ...exercise }}
              onSubmit={this.FormSubmit2} />
          }

        </Dialog>
      </div>
    }

    //查看利率表
    const C329 = () => {
      let data1 = (data instanceof Array && data.length > 0) ? data[0] : null;
      return <div>
        {data1 ? (
          <div style={{ justifyContent: 'flex-end' }}>
            <C151Form auth={auth} formStatic={{ ...r15_1 }} exercise={{ ...exercise, ...data1, save: '3', ...table, DialogTitle: form2.view, }} table={table} dataUrl={dataUrl} />
          </div>
        ) : (
            <div style={{ justifyContent: 'flex-end' }}>
              暂无利率信息
            </div>
          )}
      </div>
    }

    //修改银行开信息弹出框--20210319 
    const R61 = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
      <Dialog handleToggle={this.handleToggle} exercise={{ ...exercise, isBtn }}>
          <C61Form  auth={auth} form2={{ ...common, ...f4_1 }} dataUrl={dataUrl} exercise={{ ...exercise, table }} checkExercise={{ ...checkExercise, table }}
           onSubmit={this.FormSubmit4} />
      </Dialog>
      </div>
      }

    // 合同管理
    /*const C6 = () => {
      return <div style={{ textAlign: 'end', marginBottom: '10px' }}>
        <Button variant="contained" color="primary" onClick={this.export}>导出</Button>
      </div>
    }*/

    // 批量下载
    const C22 = () => {
      return <CommonDialog handleToggle={this.handleCommon}
        exercise={{ ...exercise, addBtnName: '公共表单', isBtn: false, }}>
        <CheckBoxDownForm auth={auth} opinion_list={[]} exercise={{ ...exercise, table }}
          onSubmit={this.FormSubmit3} />
      </CommonDialog>
    }
    // 原始融单批量下载
    const T5 = () => {
      return <div style={{ textAlign: 'start', marginBottom: '10px' }}>
        <ButtonGroup size="small" aria-label="small outlined button group">
          <Button style={{ marginRight: '10px', backgroundColor: 'rgb(29, 69, 131)' }} variant="contained" color="primary" onClick={() => this.onCheck4()}>批量下载</Button>
        </ButtonGroup>
      </div>
    }

    return <div>
      {(table === 'm4-1' ) && <T4></T4>}
      {(table === 'm4-1' ) && <CPL></CPL>}
      {(table ==='m3-1-7' ) && <C22></C22>}
      {(table ==='m3-1-7' ) && <T5></T5>}
      {(table === 'm2-5-2' || table === 'm3-1-4' || table === 'm3-1-5' || table === 'm3-1-6' || table === 'm6-1' || table === 'm6-2'
       || table === 'm6-3' || table === 'm3-1-7' || table === 'm3-1-12') && <C6></C6>}
      {(table === 'm2-5-2' || table === 'm3-1-4' || table === 'm3-1-5' || table === 'm3-1-6' || table === 'm6-1' || table === 'm6-2' 
       || table === 'm6-3' || table === 'm3-1-7' || table === 'm3-1-12') && <DownloadTime></DownloadTime>}
      {table === 'm1' && <C11 params={params}></C11>}
      {(table === 'm2-4' || table === 'm3-1-9' || table === 'm3-1-1' ||
        table === 'm3-2-2' || table === 'm2-5-3' ||
        table === 'm3-1-10' || table === 'm3-2-8' || table === 'm4-2' || table === 'm7-4-4') && <C21></C21>}
      {(table === 'm7-3' || table === 'm7-5' || table === 'm7-4-3') && <C99></C99>}
      {(table === 'm2-3' || table === 'm2-4' ||
        table === 'm2-5-1' || table === 'm2-5-2' ||
        table === 'm2-5-3' || table === 'm2-5-4' ||
        table === 'm3-1-1') && <C23></C23>}
      {(table === 'm7-2' || table === 'm7-3' || table === 'm7-4-1' || table === 'm7-4-2' || table === 'm7-5' || table === 'm7-4-3') && <C72></C72>}
      {(table === 'm3-1-8' || table === 'm3-1-9' || table === 'm3-1-10' ||
        table === 'm3-1-2' || table === 'm3-1-3' || table === 'm3-1-5') && <C318></C318>}
      {(table === 'm3-2-1' || table === 'm3-2-2' || table === 'm3-2-3' ||
        table === 'm3-2-8' || table === 'm3-1-2' ||
        table === 'm3-2-5' || table === 'm3-1-4') && <C321></C321>}
      {(table === 'm4-1' || table === 'm4-2' || table === 'm4-3' || table === 'm4-4'|| table === 'm4-5' || table === 'm7-4-4' || table === 'm7-4-5') && <C41></C41>}
      {(table === 'm5-1') && <C51></C51>}
      {(table === 'm5-2') && <R61></R61>}
      {(table === 'm3-2-9') && <C329></C329>}
      {/*(table === 'm6-1' || table === 'm6-2' || table === 'm6-3') && <C6></C6>*/}
      {(table !== 'm3-2-9') && <MaterialTable
        title={title}
        onSearchChange={this.handleSearchChange}
        onOrderChange={this.handleSort}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        onSelectionChange={this.onSelectionChange}
        path={path}
        columns={columns}
        data={data}
        actions={actions2(this, table, auth, exercise)}
        editable={editable}
        localization={localization}
        options={options(table)}
      />
      }
      {(table === 'm2-5-2') && <C24></C24>}
    </div>
  }
}
const actions2 = (that, name, auth, exercise) => {
  const { roles = [{ id: 0 }] } = auth;
  let name2 = name;
  let roleId = roles[0].id;
  if ((name === 'm2-5-3' || name === 'm3-1-10' || name === 'm3-2-8') &&
    (roleId === 12 || roleId === 13)) {
    name2 = 'sh2-5-3';
  } else if ((name === 'm2-5-3' || name === 'm3-1-10' || name === 'm3-2-8') && roleId === 11) {
    name2 = 'bj2-5-3';
  }
  let actions = [];
  switch (name2) {
    case 'm1':
      actions = [];
      break;
    case 'm2-3':
      actions = [
        { icon: Edit, tooltip: '编辑', onClick: that.onUpdate2 }
      ];
      break;
    case 'm2-4':
      actions = [
        { icon: 'save', tooltip: '审核', onClick: that.onCheck },
        // { icon: Backup, tooltip: '退回', onClick: that.onCheck2 },
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
      break;
    case 'm7-2':
      actions = [
	{ icon: Edit, tooltip: '编辑', onClick: that.onUpdate2 },
        { icon: DeleteForever, tooltip: '删除', onClick: that.onDeleteStatus }
      ];
      break;
    case 'm2-5-1':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
      break;
    case 'm2-5-2':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
      break;
    case 'sh2-5-3':
      actions = [
        { icon: 'save', tooltip: '审核', onClick: that.onCheck },
        // { icon: Backup, tooltip: '退回', onClick: that.onCheck2 },
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
      break;
    case 'bj2-5-3':
      // william hide on 03-20:
      if (roleId === 11) {
        actions = [{ icon: Edit, tooltip: '编辑', onClick: that.onUpdate2 },
      { icon: DeleteForever, tooltip: '删除', onClick: that.onDeleteStatus }]//删除--20201103
      } else {
        actions = [{ icon: Edit, tooltip: '编辑', onClick: that.onUpdate2 }]
      }
      break;
    case 'm2-5-4':
      actions = [
        { icon: 'save', tooltip: '审核', onClick: that.onCheck }
      ];
      break;
    case 'm2-5-5':
      actions = [
        { icon: DeleteForever, tooltip: '删除', onClick: that.onDeleteStatus }//删除--20210701
      ];
      break;
    case 'm3-1-1':
      if (roleId !== 13 && roleId !== 12) {
        actions = [
          { icon: AssignmentTurnedIn, tooltip: '签收', onClick: that.signFor },
        ];
      }
      break;
    case 'm3-1-2':
      if (roleId === 11) {
        actions = [
          { icon: MonetizationOn, tooltip: '融资', onClick: that.financings },
          { icon: Payment, tooltip: '流转', onClick: that.payers },
        ];
      }
      break;
    case 'm3-1-3':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
      break;
    case 'm3-1-4':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        { icon: CloudUpload, tooltip: '下载', onClick: that.onUploadRZHD },
      ];
      break;
    case 'm3-1-5':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
      break;
    case 'm3-1-7':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onViewInfo },
        //{ icon: CloudUpload, tooltip: '下载', onClick: that.onUploadInfo }
        { icon: CloudDownload, tooltip: '下载', onClick: that.onUploadInfo }//替换图表20200923
      ];
      break;
    case 'm3-1-8':
      actions = [
        { icon: Edit, tooltip: '编辑', onClick: that.onUpdate2 },
      ];
      break;
    case 'm3-1-9':
      actions = [
        { icon: 'save', tooltip: '审核', onClick: that.onCheck },
        // { icon: Backup, tooltip: '退回', onClick: that.onCheck2 },
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
      break;
    case 'm3-2-1':
      actions = [{ icon: Edit, tooltip: '编辑', onClick: that.onUpdate2 }];
      break;
    case 'm3-2-2':
      actions = [
        { icon: 'save', tooltip: '审核', onClick: that.onCheck },
        // rowData => ({
        //   icon: Backup,
        //   tooltip: '退回',
        //   onClick: that.onCheck2,
        //   hidden: rowData.statusId === 5
        // }),
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
      break;
    case 'm3-2-3':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
      break;
    case 'm3-2-5':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
      break;
    case 'm3-2-10'://下载放款回单
      actions = [
        { icon: CloudUpload, tooltip: '下载支付电子回单', onClick: that.handleUpload }
      ];
      break;
    case 'm3-2-12':
      actions = [
        { icon: CloudUpload, tooltip: '下载转让通知书', onClick: that.onUploadZrFile }
      ];
      break;
    case 'm4-1'://待还款
      if (roleId === 11) {
        actions = [
          { icon: MonetizationOn, tooltip: '申请还款', onClick: that.onRepayment }
        ];
      }
      break;
    case 'm4-2'://还款审批（客户端）
      if (roleId === 12) {
        actions = [
          { icon: MonetizationOn, tooltip: '还款审批', onClick: that.onRepayment2 }
        ];
      }
      break;
      // case 'm4-5'://还款信息
    //   if (roleId === 13) {
    //     actions = [
    //       { icon: MonetizationOn, tooltip: '申请还款', onClick: that.onRepayment }
    //     ];
    //   }
    //   break;
  case 'm4-6'://下载还款回单
      actions = [
        { icon: CloudUpload, tooltip: '下载支付电子回单', onClick: that.handleUpload1 }
      ];
    break; 
  case 'm5-1':
      actions = [{ icon: Edit, tooltip: '编辑', onClick: that.onUpdateUser }, {
        icon: 'save',
        tooltip: '重置密码',
        onClick: that.onResetPwd
      }];
      break;
  case 'm5-2'://客户端修改银行账号信息
      actions = [
        { icon: Edit, tooltip: '编辑', onClick: that.onUpdate4 }
      ];
    break;
  case 'm6-1':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onViewContract },
        { icon: CloudUpload, tooltip: '下载', onClick: that.onUploadContract }
      ];
      break;
  case 'm6-2':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onViewContract },
        { icon: CloudUpload, tooltip: '下载', onClick: that.onUploadContract }
      ];
      break;
  case 'm6-3':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onViewContract },
        { icon: CloudUpload, tooltip: '下载', onClick: that.onUploadContract }
      ];
      break;
  case 'm6-6':
    actions = [
      { icon: ViewComfy, tooltip: '查看', onClick: that.onViewContract },
      { icon: CloudUpload, tooltip: '下载保理合同', onClick: that.onUploadContract },
      { icon: CloudUpload, tooltip: '下载申请书', onClick: that.onUploadContract2 },
      { icon: CloudUpload, tooltip: '下载确认书', onClick: that.onUploadContract3 },
      { icon: CloudUpload, tooltip: '下载通知书', onClick: that.onUploadContract4 },
      { icon: CloudUpload, tooltip: '下载信息表', onClick: that.onUploadContract5 },
      { icon: CloudUpload, tooltip: '下载回执', onClick: that.onUploadContract6 }
    ];
        break;
  case 'm7-3':
    actions = [
      { icon: 'save', tooltip: '审核', onClick: that.onCheck },
      // { icon: Backup, tooltip: '退回', onClick: that.onCheck2 },
      { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
    ];
    break;
  case 'm7-4-1':
    actions = [
      { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
    ];
    break;
  case 'm7-4-2':
    actions = [
      { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
    ];
    break;
  case 'm7-4-3':
    if (roleId === 11) {
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
    }else{
      actions = [
        { icon: 'save', tooltip: '审核', onClick: that.onCheck },
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
    }
    break;
  case 'm7-4-4':
    if (roleId === 11) {
      actions = [
        { icon: MonetizationOn, tooltip: '申请还款', onClick: that.onRepayment }
      ];
    }
    break;   
  case 'm7-4-5':
    if (roleId === 12) {
      actions = [
        { icon: MonetizationOn, tooltip: '还款审批', onClick: that.onRepayment2 }
      ];
    }
    break;  
  case 'm7-5':
    if (roleId === 11){
      actions = [
        { icon: 'save', tooltip: '签收', onClick: that.onCheck },
        // { icon: Backup, tooltip: '退回', onClick: that.onCheck2 },
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
    }else{
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
    }
    break;
  case 'm8-2-1':
      if (roleId === 11) {
        actions = [
          { icon: ArrowForward, tooltip: '跳转浙商H5网关', onClick: that.onForwardCZBH5 },
          { icon: Search, tooltip: '查询浙商直融结果', onClick: that.applyFinancingResult },
        ];
      }
      break;  
    default:
      actions = [];
  }
  return actions;
}
const editable2 = (that, name) => {
  let editable = {};
  switch (name) {
    // case 'm1':
    //   editable = { onRowAdd: that.onAdd, };
    //   break;
    case 'm2-3':
      editable = { onRowDelete: that.onDelete };
      break;
    case 'm3-1-8':
      editable = { onRowDelete: that.onDelete };
      break;
    case 'm3-2-1':
      editable = { onRowDelete: that.onDelete };
      break;
    case 'm5-1':
      editable = { onRowDelete: that.onDelete1 };
      break;
    default:
    // editable = { onRowDelete: that.onDelete };
  }
  return editable;
}
const localization = {
  header: {
    actions: '操作'
  }
}
const options = (table) => {
  const options = {
    actionsCellStyle: { padding: '0 10px' },
    headerStyle: { //表格头部
      backgroundColor: '#1d4583',
      color: '#FFF',
      whiteSpace: 'nowrap'
    },
    rowStyle: { //表行
      backgroundColor: '#fff',
      whiteSpace: 'nowrap'
    },
    sorting: true,  //排序
    // selection: true //多选框
  }
  //m2-5-2:已开融单;m3-1-5:已支付融单;m3-1-7:原始融单(客户端);m3-1-6:已到期融单
  if (table !== ('m2-5-2' && 'm3-1-5' && 'm3-1-6' && 'm6-1' && table === 'm6-2' && table === 'm6-3'  && table === 'm6-6' && table === 'm3-1-7') ) {
    options.exportButton = true //导出
  }
  return options;
}
const setView = Comp => collection => {
  return class extends Component {
    render() {
      return <Comp table={collection} {...this.props} />
    }
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
});

const Views = connect(mapStateToProps)(View)
export const SetView = setView(Views);
