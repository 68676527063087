import React, { Component } from 'react'
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  TextField,
  Button,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import FormikError from './formikError';
import { userFormik } from './formikValidate'
import { loginInfos } from '../../components/numToText';
const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }
}));
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {

  state = {
    form: this.getInitState(),
    form2: this.props.form2 || {},//静态资源
    roles: [],
    departments: [],
    changePwd: false,
    loading: false,
  }
  async componentDidMount() {

    this.handleQueryRole();
    // this.loadResources('departments', 'departments');


  }


  getInitState() {
    const exercise1 = this.props.exercise
    const { password, ...exercise } = exercise1;
    if (exercise.roles) {
      exercise.role_id = exercise.roles.length > 0 ? exercise.roles[0].id : "";
    }
    if (exercise.department) {
      exercise.department_id = exercise.department ? exercise.department.id : "";
    }



    const form = {
      name: "",
      account: "",
      password: "",
      confirm_password: "",
      phone: "",
      department_id: "",
      role_id: "",
      desc: "",
      DialogTitle: "创建用户",
      save: "1", //判断是新增（1）还是编辑（2）

    }
    return exercise.save !== '1' ? exercise : form
  }

  //查询企业角色
  handleQueryRole() {
    fetching('/msapi/roles/findc?department_id=7', {
      method: 'GET'
    }).then(data => {
      this.setState({ roles: data })
    })
  }

  loadResources = async (resource, url = resource) => {
    fetching(`/msapi/${url}`).then(data => {
      this.setState({ [resource]: data })
    })
  };

  handleChange2 = ({ target: { value, name } }) => {
    //根据部门查询当前部门下的角色
    // this.loadResources('roles', `roles/${value}`)
    this.setState({
      form: { ...this.state.form, [name]: value }
    })
    return value;
  }

  //保存 提交
  handleSubmit = (form2, name) => {

    let loginInfo = loginInfos() || {};

    let form = form2 || this.state.form;
    const text = name || '撤销';
    if (text !== '提交') {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
      return;
    }
    this.setState({ loading: true })

    let method = this.state.form.save === '2' ? 'POST' : 'POST';
    let ids = form.save === '2' ? this.state.form.id : '';
    let params;

    if (form.save === '1') {
      let { DialogTitle, status2, save, formDate, confirm_password, ...params1 } = form
      params = params1
      params.organization_id = loginInfo.organization.id
      // params.enterprise_id = loginInfo.user.enterprise_id
    } else if (form.save === '2') {
      let params2 = {
        id: form.id,
        account: form.account,
        role_id: form.role_id,
        phone: form.phone,
        desc: form.desc,
        name: form.name,
      }
      params = params2
    }
    //提交表单
    if (text === '提交') {
      if(form2.roles!==undefined){
        if( form2.role_id !== form2.roles[0].id){
          alert("用户角色不能进行修改")
          this.setState({ loading: false })
           return
          }
      }
      fetching(`/msapi/user/save/${ids}`, {
        method:"post",
        // token: this.state.hasToken,
        body: JSON.stringify(params)
      }).then((data) => {
        this.setState({ loading: false })
        try {
          if (data !== undefined && data !==null) {
            if (data.error) {
              alert(data.error.message)
              return;
            }
            this.props.onSubmit({
              ...form,
              submit_name: text
            })
          }else {
            alert('提交失败')
          }
        } catch (error) { console.log(error) }
      })
    } else {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
    }
  }

  render() {
    const form2 = this.state.form2;
    const form = this.state.form;

    const { exercise } = this.props;

    const status = (handleSubmit, isSubmitting, values) => {
      return<div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
      <div style={{ position: 'relative' }}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          disabled={this.state.loading}
          name={form2.submit}
          style={{ marginRight: '10px' }}
        >
          {form2.submit}
          {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
        </Button>
      </div>
        <Button
          color='primary'
          variant='contained'
          onClick={this.handleSubmit.bind(this, values, '撤销')}
          name={form2.revocation}
          disabled={isSubmitting}
          style={{ marginRight: '10px' }}
        >
          {form2.revocation}
        </Button>
      </div>
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        validate={(values) => {
          return userFormik(values, form2);
        }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, '提交');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting
        }) => (
            <form>
              <Grid container >
                <TextField
                  label={form2.name}
                  value={values.name || ''}
                  name='name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'|| values.isAdmin}
                />
                <FormikError touched={touched} errors={errors} name={'name'} />
              </Grid>
              <Grid container justify="space-between">
                <TextField
                  label={form2.account}
                  value={values.account}
                  name='account'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3' || values.id}
                />
                <FormikError touched={touched} errors={errors} name={'account'} />
              </Grid>
              {(exercise.save === '1') &&
                <div>
                  <Grid container>
                    <TextField
                      label={form2.password+"请输入新口令(最少8位,且必须包含字母、数字和特殊字符)"}
                      value={values.password || ''}
                      name='password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin='normal'
                      fullWidth
                      type='password'
                      disabled={exercise.save === '3'}
                    />
                    <FormikError touched={touched} errors={errors} name={'password'} />
                  </Grid>
                  <Grid container>
                    <TextField
                      label={form2.confirm_password+"请再次输入新口令(最少8位,且必须包含字母、数字和特殊字符)"}
                      value={values.confirm_password || ''}
                      name='confirm_password'
                      type='password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin='normal'
                      fullWidth
                      disabled={exercise.save === '3'}
                    />
                    <FormikError touched={touched} errors={errors} name={'confirm_password'} />
                  </Grid>
                </div>
              }
              <Grid container>
                <TextField
                  label={form2.phone}
                  value={values.phone || ''}
                  name='phone'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'phone'} />
              </Grid>
              {(exercise.department != null && exercise.department.id !== 7) && <Grid container>
                <FormControl className={`${this.props.classes.field} ${this.props.classes.top}`}>
                  <InputLabel htmlFor="department_id">{form2.department_id}</InputLabel>
                  <Select
                    value={values.department_id || ''}
                    onChange={e => setFieldValue('department_id', this.handleChange2.bind(this, e)())}
                    onBlur={handleBlur}
                    inputProps={{
                      name: "department_id",
                      id: "department_id",
                    }}
                    disabled={exercise.save === '3'}
                  >
                    {this.state.departments.map(r => <MenuItem value={r.id} key={r.id}>{r.name}</MenuItem>)}
                  </Select>
                </FormControl>
                <FormikError touched={touched} errors={errors} name={'department_id'} />
              </Grid>
              }
              <Grid container>
                <FormControl className={`${this.props.classes.field} ${this.props.classes.top}`}>
                  <InputLabel htmlFor="role_id">{form2.role_id}</InputLabel>
                  <Select
                    value={values.role_id || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      name: "role_id",
                      id: "role_id",
                    }}
                    disabled={exercise.save === '3' || values.id }
                  >
                    {this.state.roles.map(r => <MenuItem value={r.id} key={r.id} >{r.name}</MenuItem>)}
                  </Select>
                </FormControl>
                <FormikError touched={touched} errors={errors} name={'role_id'} />
              </Grid>
              <Grid container>
                <TextField
                  multiline
                  rows='4'
                  label={form2.desc}
                  value={values.desc || ''}
                  name='desc'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'desc'} />
              </Grid>
              {
                status(handleSubmit, isSubmitting, values)
              }
            </form>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);



