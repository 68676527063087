import { delcommafy } from "../../components/numToText";
// const numbers = /^\d{1,12}$/ //数字
const numbers = /^\+?[1-9]\d*$/;



//授权审批
export const C161Formik = (values, formStatic) => {
  let errors = {};
  if (!values.amount) {
    errors.amount = formStatic.amount
  }else if(!numbers.test(delcommafy(values.amount))){
    errors.amount = "请输入整数"
  }
  if (!values.select_assigner) {
    errors.select_assigner = formStatic.select_assigner
  }
  return errors;
}


