import getSubRouters from './helper';

export const subRootAry1 = [
  "可用融单",//[可用融单][融单签收]位置替换--20201215
  "融单签收",//[可用融单][融单签收]位置替换--20201215
  "支付融单查看",
  "已支付融单",
  "已到期融单",
  "未到期融单",
  "原始融单",
//  "融单流转", william
  "融单流转复核",
  "已退回流转",
  "已拒签流转",
];

const PARENT_PATH = '/business/融单流转';
const PATH = '融单收支';

export default getSubRouters(PARENT_PATH, PATH, subRootAry1);
