//公用的名称
export default {
    view: "查看",
    query: "查询",
    reset: "重置",
    select_customer: "选择客户",
    select_organization: "选择机构",
    enterprise_no: "客户编号",
    check: '审核',
    update: '编辑',
    export: '导出',

}