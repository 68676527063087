
import getSubRouters from './helper';

 export const subRootAry45 = [
    '额度延时申请',
    '额度延时申请查看',
    '额度延时申请审核',
    '已退回额度延时申请',
    ];
const PARENT_PATH = '/risk-management/额度审批';
const PATH = '额度延时';
export default getSubRouters(PARENT_PATH, PATH, subRootAry45);
