const roleName = (role_id, roleList) => {
    let roleName1 = roleList.filter((item) => {
        return (item.id === Number(role_id)) ? item : null;
    })
    return roleName1.length > 0 ? roleName1[0].name : '';
}

export const getProcess = (role_id, table, roleList) => {
    let role_name;
    if (table === 'r4-1-1') {
        return role_name = roleName(role_id, roleList)
    } else if (table === 'r4-1-2' || table === 'r4-1-3' || table === 'r4-3-2') {
        switch (role_id) {
            case 16 :
            case 1:
	   // case 16 :
                role_name = roleName(3, roleList);
                break;
            case 3:
                role_name = roleName(8, roleList);
                break;
            case 8:
                role_name = roleName(14, roleList);
                break;
            case 14:
                role_name = '已完成';;
                break;

            default: role_name = '已完成';
        }
        return role_name
    }else if (table === 'r4-1-4') {

        switch (role_id) {
            case 1:
            case 16 :
                role_name = roleName(1, roleList);
                break;
            case 2:
                role_name = roleName(1, roleList);
                break;
            case 3:
                role_name = roleName(1, roleList);
                break;
            case 4:
                role_name = roleName(3, roleList);
                break;
            case 8:
                role_name = roleName(3, roleList);
                break;
             case 14:
                role_name = roleName(8, roleList);
                break; 
            default: role_name = '已完成';
        }

        return role_name
     } else if (table === 'r4-4-2') {
        switch (role_id) {
            case 1:
                role_name = roleName(3, roleList);
                break;
            case 3:
                role_name = roleName(8, roleList);
                break;
            case 8:
                role_name = '已完成';
                break;  
            default: role_name = '已完成';
        }

        return role_name
    }else if (table === 'r4-3-2') {
        switch (role_id) {
            case 1:
                role_name = roleName(3, roleList);
                break;
            case 3:
                role_name = roleName(8, roleList);
                break;
            case 8:
                role_name = '已完成';
                break;  
            default: role_name = '已完成';
        }

        return role_name
    }else if(table === 'r4-5-3'){
        switch (role_id) {
            case 1:
                role_name = roleName(3, roleList);;
                break;
            case 3:
                role_name = roleName(8, roleList);
                break;
	    case 8:
                role_name = '已完成';
                break;   
            default: ;
        }
        return role_name
    }
}
//表格公共接口列表
const getRole = (role_id, table) => {
    let role_ids;
    if (table === 'r4-1-2' ) {//授信申请审核
        switch (role_id) {
            case 1:
                role_ids = 0;
                break;
            // case 2:
            //     role_ids = 1;
            //     break;
            case 3: //rdfx1查到rdyw1
                role_ids = 1;
                break;
            // case 4:
            //     role_ids = 3;
            //     break;
            case 8://rdzjl查到rdfx1
                role_ids = 3;
                break;
            case 14:
                role_ids = 8;
                break;
            default: role_ids = 0;
        }
        return role_ids
    } else if (table === 'r4-1-4' ) { //已退回授信申请
        switch (role_id) {
            case 1:
                role_ids = 3;
                break;
            // case 2:
            //     role_ids = 3;
            //     break;
            case 3:
                role_ids = 8;
                break;
            // case 4:
            //     role_ids = 8;
            //     break;
            case 8:
                role_ids = 14;
                break;

            default: ;
        }
        return role_ids
    } else if ( table === 'r4-3-2' ||  table === 'r4-4-2') { //授信额度冻结，解冻审核
        switch (role_id) {
            case 1:
                role_ids = 0;
                break;
            // case 2:
            //     role_ids = 1;
            //     break;
            case 3: //rdfx1查到rdyw1
                role_ids = 1;
                break;
            // case 4:
            //     role_ids = 3;
            //     break;
            case 8://rdzjl查到rdfx1
                role_ids = 3;
                break;
            default: ;
        }
        return role_ids
    }else if ( table === 'r4-3-4' || table === 'r4-4-4') { //已退回授信额度停用申请,已退回授信额度启用申请
        switch (role_id) {
            case 1:
                role_ids = 3;
                break;
            // case 2:
            //     role_ids = 3;
            //     break;
            case 3:
                role_ids = 8;
                break;
            // case 4:
            //     role_ids = 8;
            //     break;
            default: ;
        }
        return role_ids
    }else if (table === 'r4-5-1' ) {
        switch (role_id) {
            case 1:
                role_ids = 0;
                break;
            case 3:
                role_ids = 1;
                break;
            case 8:
                role_ids = 3;
                break;    
            default: ;
        }
        return role_ids
    }else if (table === 'r4-5-2' ) {
        switch (role_id) {
            case 1:
                role_ids = 3;
                break;
            case 3:
                role_ids = 8;
                break; 
            default: ;
        }
        return role_ids
    }
   
}

export const core_business_url = (auth, table) => {
    if (!auth.id) return [];
    else {
        const { roles } = auth;
        //let role_id = getRole(roles[0].id, table) || '';
        let role_id = getRole(roles[0].id, table) ;
        return [
            {
                name: "r4-1-1",
                url: { query: `/msapi/credit1/find?status_id=1&user_id=${auth.id}`, 
                get: `/msapi/credit1/delete/`, 
                get2: `/msapi/aflow1/find?credit1_id=`, 
                getUploads: `/msapi/upload/find?credit1_id=` },
                title: '新增额度申请'
            },
            {
                name: "r4-1-2",
                url: { query: `/msapi/credit1/find?status_id=3&role_id=${role_id}`, 
                get: '/msapi/credit1/find/', 
                get2: `/msapi/aflow1/find?credit1_id=`,
                get3: `/msapi/aflow1/save`, 
                getUploads: `/msapi/upload/find?credit1_id=` },
                title: '额度申请审核'
            },
            {
                name: "r4-1-3",
                url: { 
                    query: '/msapi/credit1/find?status_id=3', 
                    query2: '/msapi/credit1/find?status_id=9', 
                    get: '/msapi/credit1/find/', 
                    get2: `/msapi/aflow1/find?credit1_id=`, 
                    getUploads: `/msapi/upload/find?credit1_id=` },
                title: '额度申请查询'
            },
            {
                name: "r4-1-4",
                url: { 
                    query: `/msapi/credit1/find?status_id=7&role_id=${role_id}`, 
                    get: `/msapi/credit1/delete/`,  
                    get2: `/msapi/aflow1/find?credit1_id=`,
                    get3: `/msapi/aflow1/save`, 
                    getUploads: `/msapi/upload/find?credit1_id=` },
                title: '已退回额度申请'
            },
            {
                name: "r4-3-1",
                url: { 
                    query: '/amount/usedAmount/quotaFreezingList/', 
                    get: '', 
                    get2: `/amount/usedAmount/quotaFreezingGetOne?id=`, 
                    get3: `/amount/usedAmount/saveTotalAmountFlows` },
                title: '新增额度停用'
            },
            {
                name: "r4-3-2",
                url: { 
                    //query: `/amount/usedAmount/quotaFreezingList?amount_status=3&role_id=${role_id}`, 
	    query: `/amount/usedAmount/quotaFreezingCheckList?amount_status=3&role_id=${role_id}`, 
                    get: '', 
                    get2: `/amount/usedAmount/quotaFreezingGetOne?id=`, 
                    get3: `/amount/usedAmount/saveTotalAmountFlows` },
                title: '额度停用审核'
            },
            {
                name: "r4-3-3",
                url: { 
                    query: '/amount/usedAmount/quotaFreezingList?amount_status=1', 
                    get: '', 
                    get2: '', 
                    get3: '' },
                title: '额度停用查询'
            },
            {
                name: "r4-3-4",
                url: { 
                    query: `/amount/usedAmount/quotaFreezingList?amount_status=7&role_id=${role_id}`, 
                    get: '', 
                    get2: `/amount/usedAmount/quotaFreezingGetOne?id=`, 
                    get3: `/amount/usedAmount/saveTotalAmountFlows`,
                    get4:'/amount/usedAmount/deleteTotalAmountFlows/'
 },
                title: '已退回额度停用申请'
            },
            {
                name: "r4-4-1",
                url: { 
                    query: '/amount/usedAmount/quotaUnfreezeList/', 
                    get: '', 
                    get2: `/amount/usedAmount/quotaFreezingGetOne?id=`, 
                    get3: `/amount/usedAmount/saveTotalAmountFlows` },
                title: '新增额度启用'
            },
            {
                name: "r4-4-2",
                url: { 
                    query: `/amount/usedAmount/quotaUnfreezeList?thawing_status=3&role_id=${role_id}`, 
                    get: '', 
                    get2: `/amount/usedAmount/quotaFreezingGetOne?id=`, 
                    get3: `/amount/usedAmount/saveTotalAmountFlows` },
                title: '额度启用审核'
            },
            /*{
                name: "r4-4-3",
                url: { 
                    query: `/amount/usedAmount/quotaUnfreezeList?thawing_status=9`, 
                    get: '', 
                    get2: '', 
                    get3: '' },
                title: '额度启用查询'
            },*/
            {
                name: "r4-4-4",
                url: { 
                    query: `/amount/usedAmount/quotaUnfreezeList?thawing_status=7&role_id=${role_id}`, 
                    get: '', 
                    get2: `/amount/usedAmount/quotaFreezingGetOne?id=`, 
                    get3: `/amount/usedAmount/saveTotalAmountFlows` ,
		    get4:'/amount/usedAmount/deleteTotalAmountFlows/'},
                title: '已退回额度启用申请'
            },
	     {
                name: "r4-5-4",
                url: { 
                    query: `/amount/usedAmount/GetTotalAmount/`, 
                },
                  
                title: '额度延时申请'
            },
             {
                name: "r4-5-1",
                url: { 
                    query: `/amount/usedAmount/GetDelayTotalAmount?delay_status_id=1&role_id=${role_id}`, 
                    get2: `/amount/usedAmount/GetDelayAmountsFlows?ttid=`
                },
                  
                title: '额度延时申请审核'
            },
	    {
                name: "r4-5-2",
                url: { 
                    query: `/amount/usedAmount/GetDelayTotalAmount?delay_status_id=2&role_id=${role_id}`, 
                },
                title: '已退回额度延时申请'
            },
            {
                name: "r4-5-3",
                url: { 
                    query: '/amount/usedAmount/GetDelayTotalAmount?', 
                },
                title: '额度延时申请查看'
            },
	    {
                name: "r4-6-1",
                url: { 
                    query: `/msapi/bigtree/getUserPushList`, 
                    
                },
                  
                title: '大树风控额度准入查看推送'
            },
            {
                name: "r4-6-2",
                url: { 
                    query: `/msapi/bigtree/getTransactionDataPushList`, 
                    
                },
                  
                title: '化工交易信息查看推送'
            },
            {
                name: "r4-6-3",
                url: { 
                    query: `/msapi/bigtree/getBtCreditList`, 
                   
                },
                title: '大树风控额度查看推送'
            },

        ]
    }

}
