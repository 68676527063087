//营业执照
export default {
    enterprise_name:"客户名称",
    enterprise_no:"客户编号",
    business_license_no:"营业执照注册号",
    yearly_check:"是否年检",
    establishment_date:"成立日期",
    validity_date_of_license:"执照有效期起始日",
    expiry_date_of_license:"执照有效期到期日",
    is_validity:"是否有效",
    yearly_check_date:"年检日期",
    business_scope:"经营范围",
    registered_assets:"注册资本",
    registered_assets_currency:"注册资本币种",
    business_license_file:"上传营业执照扫描件",

}
