// 注册-供应商注册表单
export default {
    title: "融单系统注册",
    account: "账户名称",
    name: "姓名",
    organization_id: "请选择组织",
    department_id: "请选择部门",
    role_id: "请选择角色",
    email: "邮件",
    phone: "手机号码",
    password: "密码",
    more: "其他",
    province: "请选择省份",
    city: "请选择城市",
    district: "请选择区/县",
    allowExtraEmails: "希望了解更多",
    submit_apply:"提交注册申请",
    to_login:" 已经注册？点击登陆",
}