import React, { Component } from 'react'
import Select from 'react-select';
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  Button,
  Grid,
  TextField,
  CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { numberfun, commafy, delcommafy } from '../../components/numToText';
import FormikError from '../../components/commonForm/formikError';
import { C53Formik } from './formikValidate'
import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { formatDate } from '../../components/date'
import CheckForm from '../../components/commonForm/CheckForm';

const useStyles = (theme => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    form: this.getInitState(),
    formStatic: this.props.formStatic || {},//静态资源
    selectCustomerList: [],
    selectSubsidiaryList: [],
    amountList: [],
    loading: false,
  }
  async componentWillMount() {
    const { exercise } = this.props;
    if (exercise.save !== '3') {
      this.queryEnterpriseInfo();
      if (exercise.save === '2') {
        this.queryAmount(exercise.sub_company_id)
      }
    }

  }

  getInitState() {
    const { exercise } = this.props
    let mx_amount = exercise.mx_amount ? {limitMark:exercise.limit_mark, mxAmount:exercise.mx_amount} : '';
    const form = {
      select_customer: "",
      select_subsidiary: "",
      mx_amount: '',
      usable_amount: '',
      subsidiary_amount: '',
      subsidiary_date: new Date(),
    }
    if (exercise.save !== '1') {
      form.select_customer = {
        id: exercise.enterprise_id,
        value: exercise.enterprise_name,
        label: exercise.enterprise_name,
        enterprise_no: exercise.enterprise_no,
        enterprise_name: exercise.enterprise_name
      }
      form.select_subsidiary = {
        sub_company_id: exercise.sub_company_id,
        value: exercise.sub_company_name,
        label: exercise.sub_company_name,
        sub_company_no: exercise.sub_company_no,
        sub_company_name: exercise.sub_company_name
      }
    }
    return exercise.save === '1' ? { ...form } : { ...form, ...exercise, mx_amount }
  }
  queryEnterpriseInfo = () => {
    fetching('/msapi/organizations', {
      method: 'GET',
    }).then(res => {
      if (res !== undefined && res !== null) {
        try {
          let datas = res.filter((item) => {
            item.value = item.name;
            item.label = item.name;
            item.enterprise_name = item.name;
            return (item.id !== 1) ? item : null;
          })
          this.setState({ selectCustomerList: datas });
        } catch (error) {
        }
      } else {
        alert('提交失败')
      }
    })
  }

  //输入框值改变
  handleChange1 = (name, value) => {
    let value2 = value;
    if (name === 'select_customer') {
      this.querySubsidiary(value.enterprise_no,value)
    } else if (name === 'select_subsidiary') {
      this.queryAmount(value.sub_company_id)
    } else if (name === 'mx_amount') {
      value2 = numberfun(value2, 12);
    } else if (name === 'subsidiary_amount') {
      value2 = numberfun(value2, 12,2);
    }
    return value2;
  }

  //输入框失去焦点 金额 千分位
  handleBlur1 = (name, value) => {
    return commafy(value);
  }
  /*queryAmount = (organization_id) => {
    if (!organization_id) {
      return;
    }
    fetching('/msapi/totalamount/find?organization_id=' + organization_id, {
      method: 'GET',
    }).then(res => {
      if (res !== undefined && res !== null) {
        try {
          if (res.length > 0) {
            const { mx_amount, usable_amount } = res[0]
            this.setState({ form: { ...this.state.form, mx_amount: commafy(mx_amount), usable_amount: commafy(usable_amount) } });
          } else {
            alert('该子公司客户暂无额度')
          }
        } catch (error) {
        }
      } else {
      }
    })
  }*/

  queryAmount = (organization_id) => {
    if (!organization_id) {
      return;
    }
    fetching(`/amount/usedAmount/sourceGetAmount?organizationId=${organization_id}`).then(res => {//查看额度来源
      if (res !== undefined && res.data !== "" && res.data.length > 0) {
        fetching(`/amount/usedAmount/quotaRecycleGetAmount?organizationId=${organization_id}`).then(res => {
          if (res !== undefined && res !== null) {
            try {
              if (res.data.length > 0) {
                this.setState({ amountList: res.data });
              } else {
                alert('该子公司客户暂无可用额度')
              }
            } catch (error) {
            }
          } else {
          }
        })
      } else {
        alert('该子公司客户暂无可回收额度')
      }
     })
  }

  querySubsidiary = (enterprise_no,value) => {
    fetching(`/amount/usedAmount/recycleGetFilterAmount?organizationId=${value.id}`).then(res => {//查看额度来源
      this.setState({ selectSubsidiaryList: [] });
      this.setState({ amountList: [] });
      if (res !== undefined && res.data !== "" && res.data.length > 0) {
    fetching(`/smrd/business_management/find?enterprise_no=${enterprise_no}&status_id=9`, {
      method: 'GET',
    }).then(res => {
      try {
        if (res !== undefined && res.length > 0) {
          /*const { subsidiaries = [] } = res[0]
          let datas = subsidiaries.filter((item) => {
            item.value = item.sub_company_name;
            item.label = item.sub_company_name;
            return item;
          })
          this.setState({ selectSubsidiaryList: datas });*/

          //企业关联不是一次完成的--20200916
          if (res.length === 1) {
            const { subsidiaries = [] } = res[0];

            let datas = subsidiaries.filter((item) => {
              item.value = item.sub_company_name;
              item.label = item.sub_company_name;
              return item;
            })
            this.setState({ selectSubsidiaryList: datas });

          } else {
            const subsidiaries = [];
            for (const su of res) {
              const sub1 = su.subsidiaries;
              if ( !Array.isArray(sub1) || sub1.length === 0) {
                continue;
              }
              for (const s of sub1) {
                subsidiaries.push(s);
              }
            }

            let datas = subsidiaries.filter((item) => {
              item.value = item.sub_company_name;
              item.label = item.sub_company_name;
              return item;
            })
            this.setState({ selectSubsidiaryList: datas });
          }
          
        } else {
          alert('该集团客户暂未关联子公司')
        }
      } catch (error) { }
    })
  } else {
    alert("该集团客户暂无可用额度或额度已被冻结,请进行确认!!!")
  }
 })
}
  //保存 提交
  handleSubmit = (values, name) => {
    const { auth } = this.props;
    const text = name || 'cancle';
    if (text === 'submit') {
      this.setState({ loading: true })
    }
    let paramsDate = { ...values } || { ...this.state.form };
    let ids = paramsDate._id || '';
    //日期、金额转换
    if (paramsDate.subsidiary_date instanceof Object && paramsDate.subsidiary_date.getTime()) {
      paramsDate.subsidiary_date = formatDate(paramsDate.subsidiary_date, 'yyyy-MM-dd');
    }
    const amountList = ['subsidiary_amount', 'mx_amount', 'usable_amount'];
    amountList.forEach(item => {
      if (item === 'subsidiary_amount') {
        paramsDate[item] = delcommafy(paramsDate[item]);
      } else {
        paramsDate[item] = delcommafy(this.state.form[item]);
      }
    })
    let { select_customer, select_subsidiary } = paramsDate
    let params = {
      ttid: this.state.form.ttid,//20210224
      enterprise_id: select_customer.id,
      enterprise_name: select_customer.enterprise_name,
      enterprise_no: select_customer.enterprise_no,
      sub_company_id: select_subsidiary.sub_company_id || '',
      sub_company_name: select_subsidiary.sub_company_name || '',
      sub_company_no: select_subsidiary.sub_company_no || '',
      subsidiary_date: paramsDate.subsidiary_date,
      subsidiary_amount: paramsDate.subsidiary_amount,
      //mx_amount: paramsDate.mx_amount,
      mx_amount: this.state.form.mx_amount.mxAmount ? this.state.form.mx_amount.mxAmount : paramsDate.mx_amount,//20210416
      usable_amount: paramsDate.usable_amount,
      limit_mark: values.mx_amount.limitMark,
      auth,
      _id : ids,
    }
    //params._id = ids
    //提交表单
    if (text === 'submit') {
      //1.额度回收提交及扣除子公司的额度
      var qAC = params;
      var typeAC = 0;//1-额度分配;2-额度收回
      const ttid = qAC.ttid;
      const oid = qAC.enterprise_id;
      const subAmount = qAC.subsidiary_amount;
      fetching(`/amount/usedAmount/firstHandleQaAndQc?ttid=${ttid}&oid=${oid}&subAmount=${subAmount}&typeAC=${2}`).then(res => {
	if (res !== undefined && res !== null) {
          if (res.code !== 200)  {
            alert(res.msg || '额度处理失败！')
            return;
          } else{
        fetching(`/smrd/quota_recycle/save/${ids}`, { //修改新增合并--20201229
          method: ids ? 'POST' : 'POST',
        //fetching(`/smrd/quota_recycle/${ids}`, {
          //method: ids ? 'PUT' : 'POST',
          body: JSON.stringify(params)
        }).then((data) => {
          this.setState({ loading: false })
          try {
            if (data !== undefined && data !== null) {
              if (data.msg) {
                alert(data.msg)
                return;
              }
              this.props.onSubmit({
                ...params,
                submit_name: text
              })
            }
          } catch (error) {
            console.log(error)
            // alert(`${ids ? '额度回收编辑失败' : '额度分配提交失败'}`)
          }
        })
      } 
        }
       })
    } else {
      this.props.onSubmit({
        ...params,
        submit_name: text
      })
    }
  }
  //审核意见
  FormSubmit3 = (data) => {
    this.props.onSubmit({
      ...data,
      submit_name: 'submit'
    })
  }

  handleChange2 = (selectedOption) => {
    const { amount } = this.state.form;
    this.setState({
      form: {
        ...this.state.form,
        ttid: selectedOption.id,//20210223
        mx_amount: commafy(selectedOption.mxAmount),//20210223
        usable_amount: commafy(selectedOption.usableAmount),
      }
    })
    return selectedOption;
  }

  getOptionLabel = data => {
      return data.limitMark + data.mxAmount
  }
  getOptionValue = data => {
    return data.id
  }

  render() {
    const { form, formStatic, selectCustomerList, selectSubsidiaryList, amountList , loading } = this.state
    const { exercise, auth, dataUrl } = this.props;
    const checkform = () => {
      return <CheckForm auth={auth} exercise={exercise} dataUrl={dataUrl}
        onSubmit={this.FormSubmit3}
      />
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        validate={(values) => {
          return C53Formik(values, formStatic, form.usable_amount);
        }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, 'submit');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
            <div>
              <form>
                <Grid item xs={12}>
                  <div style={{ margin: '5px 0 20px' }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_customer}</span>
                    <Select
                      isSearchable
                      value={values.select_customer}
                      onChange={e => {setFieldValue('select_customer', this.handleChange1.bind(this, 'select_customer', e)());
                        setFieldValue('select_subsidiary','');
                        setFieldValue('mx_amount','');
                        setFieldValue('usable_amount',this.state.form.usable_amount = '')
                      }}
                      options={selectCustomerList}
                      isDisabled={exercise.save === '3'}
                    />
                    <FormikError touched={touched} errors={errors} name={'select_customer'} />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ margin: '5px 0' }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_subsidiary}</span>
                    <Select
                      isSearchable
                      value={values.select_subsidiary}
                      onChange={e => {setFieldValue('select_subsidiary', this.handleChange1.bind(this, 'select_subsidiary', e)());
                      setFieldValue('mx_amount','');
                      setFieldValue('usable_amount',this.state.form.usable_amount = '')
                    }}
                      options={selectSubsidiaryList}
                      isDisabled={exercise.save === '3'}
                    />
                    <FormikError touched={touched} errors={errors} name={'select_subsidiary'} />
                  </div>
                </Grid>
                <Grid container justify="space-between">
                  <Grid item xs={5}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.mx_amount}</span>
                      <Select
                      isSearchable 
                      value={values.mx_amount}
                      onBlur={handleBlur}
                      getOptionLabel={this.getOptionLabel}
                      getOptionValue={this.getOptionValue}
                      onChange={e => setFieldValue('mx_amount', this.handleChange2(e))}
                      options={amountList}
                      isDisabled={exercise.save === '3' || exercise.save === '4'}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label={formStatic.usable_amount}
                      value={form.usable_amount}
                      name='usable_amount'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin='normal'
                      inputProps={{ maxLength: 50 }}
                      fullWidth
                      disabled={exercise.save === '3'}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="space-between">
                  <Grid item xs={5}>
                    <TextField
                      label={formStatic.subsidiary_amount}
                      value={values.subsidiary_amount}
                      name='subsidiary_amount'
                      onChange={e => setFieldValue('subsidiary_amount', this.handleChange1.bind(this, 'subsidiary_amount', e.target.value)())}
                      onBlur={e => setFieldValue('subsidiary_amount', this.handleBlur1.bind(this, 'subsidiary_amount', e.target.value)())}
                      margin='normal'
                      inputProps={{ maxLength: 50 }}
                      fullWidth
                      disabled={exercise.save === '3'}
                    />
                    <FormikError touched={touched} errors={errors} name={'subsidiary_amount'} />
                  </Grid>
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        //format="yyyy/MM/dd"  20201230
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="date-picker-inline"
                        label={formStatic.subsidiary_date}
                        value={values.subsidiary_date}
                        disabled={exercise.save === '3'}
                        onChange={e => setFieldValue('subsidiary_date', this.handleChange1.bind(this, 'subsidiary_date', e)())}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'subsidiary_date'} />
                    </Grid>
                      </MuiPickersUtilsProvider>*/}
                </Grid>
                {exercise.save !== '3' &&
                  <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
                    <div style={{ position: 'relative' }}>
                      <Button
                        style={{ margin: '0 10px' }}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        提交
                    </Button>
                      {loading && <CircularProgress size={24} style={loadingStyle} />}
                    </div>
                    <Button variant="contained" color="primary" onClick={this.handleSubmit.bind(this, values, 'cancle')}>取消</Button>
                  </div>
                }
              </form>
              {(exercise.save !== '1') &&
                <div>
                  {checkform()}
                </div>}
            </div>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);






