import React, { Component } from 'react';
import {
  Button,
  Grid,
  FormControl,
  Select as Select2,
  MenuItem,
  Typography,
  CircularProgress,
  Box,
  LinearProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { form3Formik } from './formikValidate';
import { Upload } from '../../../components/uploadText';

const { object } = require('yup');


const fileType = ["原件", "复印件", "扫描件", '附件'];
global.count = 0;
const useStyles = (theme => ({
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  width: '100%',
  center: {
    alignItems: 'center',
  },
  error: {
    color: '#f00',
  },
}));
const styles = {
  width: '100%',
  margin: '0 0 8px 0'
}
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
let fileList = [
  { id: 1, fileName: 'credit_approval', text: '额度审批书*', type: '原件', file: '', typeValue: 'credit_approval_type', require: true ,uploadLoading:0},
  { id: 2, fileName: 'licences_copies', text: '营业执照正副本复印件', type: '复印件', file: '', typeValue: 'licences_copies_type', require: false },
  { id: 3, fileName: 'account_permit_copies', text: '开户许可证复印件', type: '复印件', file: '', typeValue: 'account_permit_copies_type', require: false },
  { id: 4, fileName: 'other_business_license', text: '其他经营许可证复印件', type: '复印件', file: '', typeValue: 'other_business_license_type', require: false },
  { id: 5, fileName: 'constitution', text: '章程', type: '复印件', file: '', typeValue: 'constitution_type', require: false },
  { id: 6, fileName: 'capital_verification_report', text: '验资报告', type: '复印件', file: '', typeValue: 'capital_verification_report_type', require: false },
  { id: 7, fileName: 'legal_idcard_and_resume', text: '法人代表身份证', type: '复印件', file: '', typeValue: 'legal_idcard_and_resume_type', require: false },
  // { id: 8, fileName: 'legal_resume', text: '法人代表简历', type: '复印件', file: '', typeValue: 'legal_resume_type', require: false },
  { id: 9, fileName: 'Introduction', text: '公司简介', type: '附件', file: '', typeValue: 'Introduction_type', require: false },
  { id: 10, fileName: 'audit_reports_and_statements', text: '审计报告及报表', type: '复印件', file: '', typeValue: 'audit_reports_and_statements_type', require: false },
  { id: 11, fileName: 'tax_return', text: '纳税申报表', type: '原件', file: '', typeValue: 'tax_return_type', require: false },
  { id: 12, fileName: 'business_registration_info', text: '工商登记信息', type: '复印件', file: '', typeValue: 'business_registration_info_type', require: false },
  { id: 13, fileName: 'enterprise_credit_report', text: '企业征信查询报告', type: '原件', file: '', typeValue: 'enterprise_credit_report_type', require: false },
  { id: 14, fileName: 'contracts_and_invoices', text: '合同、发票', type: '复印件', file: '', typeValue: 'contracts_and_invoices_type', require: false },
  { id: 15, fileName: 'other_supplely_info', text: '其他补充资料', type: '附件', file: '', typeValue: 'other_supplely_info_type', require: false },
]

class Form3 extends Component {
  state = {
    form: this.getInitState(),
    fileList: fileList,
    uploads: this.props.uploads || {}
  }
  //如果有异步设置state值的情况，在组件销毁时清除所有的state状态
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }

  getInitState() {
    const { form = {}, exercise = {}, uploads } = this.props;
    let form2 = {};
    fileList.forEach((item) => {
      if (exercise.save === '1') {
        form2[item.fileName] = ''
        form2[item.typeValue] = item.type
        
      } else {
        form2[item.fileName] = uploads[item.fileName] || ''
        form[item.typeValue] = (form[item.typeValue] && form[item.typeValue] !== 'null') ? form[item.typeValue] : item.type

      }
    })
    return { ...form, ...form2 }
  }
  uploadFile(e){
    let form = this.state.form;
    var fordata = new FormData();
    if (form.status_id === 9 ) {//补充上传文件
        fordata.append('type',4);
        fordata.append('fileName',e.file);
        fordata.append('fileSource',e.uploadName);
        fordata.append('id',form.id);
        const options = {
          method: "POST",
          body: fordata
        }
        //global.count = (global.count+1)
        return fetch(`/msapi/upload/reUpload`,options) 
    } else {
      fordata.append('type',4);
      fordata.append('fileName',e.file);
      const options = {
        method: "POST",
        body: fordata
      }
      global.count = (global.count+1)
      return fetch(`/msapi/upload/upload`,options)
    }
  }
  uploadHandler = async (e,type) => {
	this.setState({progressLength : 0})
    	this.setState({progress : true})
    	const timer = setInterval(() => {
      		this.setState({progressLength : (this.state.progressLength >= 99 ? 99 : this.state.progressLength + 1) })
    	}, 30);
    let indexId = '';
    indexId = await this.uploadFile(e).then(res => {
      if (res) {
        if (!res.status){
          alert(res.msg)         
        }else{
        global.count = global.count - 1
          return res.json()
        }
      }else{
        return null
      }
    }).then(r=>{      
      if (r.error.code === 200) {//无错
          clearInterval(timer);
          this.setState({progressLength : 100})
          setTimeout(() => {
            alert("文件上传成功");
            this.setState({ progress: false })
          }, 500)
          return r.data
        } else {//业务失败
          alert(r.error.message)
          this.setState({ progress: false })
          return null;
        }
    }) 
    if(type === 1){
      this.state.form.credit_approval_id= indexId 
    }else if(type === 2){
      this.state.form.licences_copies_id= indexId 
    }else if(type === 3){
      this.state.form.account_permit_copies_id= indexId 
    }else if(type === 4){
      this.state.form.other_business_license_id= indexId
    }else if(type === 5){
        this.state.form.constitution_id= indexId 
    }else if(type === 6){
        this.state.form.capital_verification_report_id= indexId 
    }else if(type === 7){
        this.state.form.legal_idcard_and_resume_id= indexId 
    }else if(type === 9){
        this.state.form.Introduction_id= indexId 
    }else if(type === 10){
        this.state.form.audit_reports_and_statements_id= indexId 
    }else if(type === 11){
        this.state.form.tax_return_id= indexId
    }else if(type === 12){
        this.state.form.business_registration_info_id= indexId
    }else if(type === 13){
        this.state.form.enterprise_credit_report_id= indexId
    }else if(type === 14){
        this.state.form.contracts_and_invoices_id= indexId
    }else if(type === 15){
        this.state.form.other_supplely_info_id= indexId
    }
    return true
    
  }

  //下一步
  handleNext = (values) => {
   if  (this.state.form.credit_approval === null || this.state.form.credit_approval === "") {
    if(this.state.form.credit_approval_id===undefined){
            alert("额度审批书不能为空，请先上传额度申请书")
            return
          }
   }
     
    values.credit_approval_id = this.state.form.credit_approval_id
    values.licences_copies_id = this.state.form.licences_copies_id
    values.account_permit_copies_id = this.state.form.account_permit_copies_id
    values.other_business_license_id = this.state.form.other_business_license_id
    values.constitution_id = this.state.form.constitution_id
    values.capital_verification_report_id = this.state.form.capital_verification_report_id
    values.legal_idcard_and_resume_id = this.state.form.legal_idcard_and_resume_id
    values.Introduction_id = this.state.form.Introduction_id
    values.audit_reports_and_statements_id = this.state.form.audit_reports_and_statements_id
    values.tax_return_id = this.state.form.tax_return_id
    values.business_registration_info_id = this.state.form.business_registration_info_id
    values.enterprise_credit_report_id = this.state.form.enterprise_credit_report_id
    values.contracts_and_invoices_id = this.state.form.contracts_and_invoices_id
    values.other_supplely_info_id = this.state.form.other_supplely_info_id
    this.props.handleNext(values);
  };
  //上一步
  handleBack = () => {
    this.props.handleBack();
  };
  //保存
  handleSave = (values) => {
    values.credit_approval_id = this.state.form.credit_approval_id
    values.licences_copies_id = this.state.form.licences_copies_id
    values.account_permit_copies_id = this.state.form.account_permit_copies_id
    values.other_business_license_id = this.state.form.other_business_license_id
    values.constitution_id = this.state.form.constitution_id
    values.capital_verification_report_id = this.state.form.capital_verification_report_id
    values.legal_idcard_and_resume_id = this.state.form.legal_idcard_and_resume_id
    values.Introduction_id = this.state.form.Introduction_id
    values.audit_reports_and_statements_id = this.state.form.audit_reports_and_statements_id
    values.tax_return_id = this.state.form.tax_return_id
    values.business_registration_info_id = this.state.form.business_registration_info_id
    values.enterprise_credit_report_id = this.state.form.enterprise_credit_report_id
    values.contracts_and_invoices_id = this.state.form.contracts_and_invoices_id
    values.other_supplely_info_id = this.state.form.other_supplely_info_id
    this.props.handleLoading(true)
    this.props.handleSave(values);
  };
  render() {
    const { activeStep, exercise = {}, formStatic, classes } = this.props;
    const { form, fileList,progress, progressLength } = this.state;
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        validationSchema={object().shape(form3Formik(formStatic))}
        //验证提交
        onSubmit={(values) => {
          if(global.count === 0 || global.count < 0){
            this.handleNext(values);
          }else{
            alert("文件正在上传请稍后!")
          }
        }}
        render={({
          values,
          // errors,
          // touched,
          // handleChange,
          // handleBlur,
          handleSubmit,
          setFieldValue,
          // isSubmitting,
        }) => (
            <form method="post" encType="multipart/form-data">
              <Grid container justify="space-around" alignItems='center' alignContent='center'>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom >
                    {formStatic.data_list}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1" gutterBottom>
                    {formStatic.file_type}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                  <Typography variant="body1" gutterBottom>
                    {formStatic.handle}
                  </Typography>
                </Grid>
              </Grid>

		 {/* 上传进度条 */}

              { progress &&
                  <div>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate"  value={progressLength}/>
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                      progressLength
                    )}%`}</Typography>
                  </Box>
                </Box>
                </div>
                }
              {fileList.map((items) => {
                return (
                  <div key={items.id}>
                    <Grid container justify="space-around" alignItems='center'>
                      <Grid item xs={4}>
                        <Typography variant="inherit" gutterBottom className={items.require ? classes.error : ''}>
                          {items.text}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl style={styles} className={classes.formControl}>
                          {/* <InputLabel id="demo-simple-select-label">{formStatic.file_type}</InputLabel> */}
                          <Select2
                            style={{ fontSize: '0.875rem', maxWidth: '90px' }}
                            id="demo-simple-select"
                            value={values[items.typeValue] || '原件'}
                            disabled={exercise.save === '3' }
                            onChange={e => setFieldValue(items.typeValue, e.target.value)}>
                        {fileType.map(item => {
                              return <MenuItem style={{ fontSize: '0.875rem' }} key={item} value={item}>{item}</MenuItem>
                            })}
                          </Select2>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <Upload disabled={exercise.save === '3' && ( exercise.status_id !== 9 || (this.props.auth.roles[0].id !== 1 && this.props.auth.roles[0].id !== 3)  )}
                          fileList={[values[items.fileName]]}
                          // fileList={uploads[items.fileName] ? [uploads[items.fileName]] : [form[items.fileName]]}
                          uploadName={items.fileName} view={formStatic.view} type='file'
                          mold = "4"
                          exercise={exercise}
                        //   uploadChange={e => setFieldValue(items.fileName, e.file)}
                        // >
                        uploadChange={e=>this.uploadHandler( e, items.id)}
                        >
                        </Upload>
                      </Grid>
                    </Grid>
                  </div>
                )
              })
              }
              <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                >
                  上一步
                            </Button>
                <Button style={{ margin: '0 10px' }} variant="contained" color="primary" onClick={handleSubmit}>
                  下一步
                            </Button>
               
                {exercise.save !== '3' &&
                  <div style={{ position: 'relative' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.props.loading}
                      onClick={this.handleSave.bind(this, values)}
                    >
                      保存</Button>
                    {this.props.loading && <CircularProgress size={24} style={loadingStyle} />}
                  </div>
                }
              </div>
            </form >
          )}
      />
    );
  }
}

export default withStyles(useStyles)(Form3);