// const f2_3 = require('./f2_3.js');
import f2_3 from './f2_3.js';
import f3_1_8 from './f3_1_8.js';
import f3_2_1 from './f3_2_1.js';
import f5_1 from './f5_1.js';
import r1_3 from './r1-3.js';
import r1_5 from './r1-5.js';
import r4_1_1_1 from './r4_1_1_1.js';
import r4_1_1_2 from './r4_1_1_2.js';
import r4_1_1_3 from './r4_1_1_3.js';
import r4_1_1_5 from './r4_1_1_5.js';
import r5_1 from './r5_1.js';
import r5_2 from './r5_2.js';
import r5_3 from './r5_3.js';
import r14_1_2 from './r14_1_2';
import r14_2_2 from './r14_2_2';
import r14_3_2 from './r14_3_2';
import r15_1 from './r15_1';
import r15_2 from './r15_2';
import r16 from './r16';
import userPush from './userPush.js';

import mortgage from './mortgage.js';
import pledge from './pledge.js';
import guarantor from './guarantor.js';

import common from './common.js';

const r4 = { ...r4_1_1_1, ...r4_1_1_2, ...r4_1_1_3, ...r4_1_1_5, ...common, ...mortgage, ...pledge, ...guarantor };

export {
    f2_3,
    f3_1_8,
    f3_2_1,
    f5_1,
    r1_3,
    r1_5,
    r4_1_1_1,
    r4_1_1_2,
    r4_1_1_3,
    r4_1_1_5,
    r4,
    r5_1,
    r5_2,
    r5_3,
    r14_1_2,
    r14_2_2,
    r14_3_2,
    r15_1,
    r15_2,
    r16,
    common,
    userPush,
}
