export default {
    change_user: "切换账户",
    mine_account: "我的账户",
    logout: "退出",
    user_account: "账号：",
    account: "账号",
    name: "姓名",
    department: "部门",
    role: "角色",
    updata_password: "修改密码",
    cancle: "取消",
    submit: "提交",
    confirm_logout_title: "退出登录确认",
    confirm_logout_content: "确定退出吗？",
    no: "否",
    yes: "是",
}