import React from 'react'
export default function FormikError(props) {
    const { touched, errors, name } = props;
    if (touched[name] && errors[name]) {
        return <div className="errorMsg">{errors[name]}</div>
    } else {
        return null;
    }

}
