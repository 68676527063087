import {
  Search as SearchIcon,
  Functions as FunctionsIcon,
  QueryBuilder as QueryBuilderIcon,
  LocalFlorist as LocalFloristIcon,
  ChangeHistory as ChangeHistoryIcon,
  AccessibleForwardOutlined as AccessibleForwardOutlinedIcon,
} from '@material-ui/icons';
import {subRouters, getMenu, getContent} from './common';
import { SetView } from './CreditManager/commonTable'
const PARENT_PATH = subRouters['额度管理'];

const Info = [
  {
    path: "额度查询",
    icon: SearchIcon,
    component: SetView('r5-4')
  },
  {
    path: "历史额度查询",
    icon: ChangeHistoryIcon,
    component: SetView('r5-5')
  },
  {
    path: "操作明细",
    icon: AccessibleForwardOutlinedIcon,
    component: SetView('r5-6')
  },
  {
    path: "企业管理",
    icon: QueryBuilderIcon,
    component: SetView('r5-1')
  },
  {
    path: "额度分配",
    icon: LocalFloristIcon,
    component: SetView('r5-2')
  },
  {
    path: "额度回收",
    icon: FunctionsIcon,
    component: SetView('r5-3')
  },
  // {
  //   path: "融单流转（加查询）",
  //   icon: Description
  // },
];

export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];
