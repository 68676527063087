//融单开立表单
export default {
    name: "姓名*",
    account: "用户名*",
    password: "密码*",
    confirm_password: "确认密码*",
    phone: "联系电话",//非必填
    department_id: "部门*",//融单用户 需要的字段
    role_id: "角色*",//角色id
    desc: "描述",//非必填
}
