
import getSubRouters from './helper';

export const subRootAry2 = [
    '发票查询',
    '发票录入',
  ];


const PARENT_PATH = '/risk-management/应收账款管理';
  const PATH = '发票信息';
  export default getSubRouters(PARENT_PATH, PATH, subRootAry2);
