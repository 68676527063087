import React, { Component } from 'react'
import Select from 'react-select';
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  Button,
  Grid,
  FormControl,
  InputAdornment,
  Input,
  InputLabel,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { numberfun, commafy, delcommafy } from '../../components/numToText';
import { C161Formik } from './formikValidate'
import FormikError from '../../components/commonForm/formikError';

const useStyles = (theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: '#fff',
    [theme.breakpoints.up(700 + theme.spacing(2) * 2)]: {
      width: 700,
      minHeight: 300,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '100px'
    },
  },
  textField: {
    width: '100%',
    marginBottom: '8px',
    marginTop: '16px',
  },

}));
class Form extends Component {
  state = {
    form: this.getInitState(),
    userList: [],
    formStatic: this.props.formStatic || {},//静态资源
  }
  async componentWillMount() {
    const { exercise = {} } = this.props;
    if (exercise.save !== '3') {
      fetching(`/msapi/user/find?organization_id=1`).then(data => {
        if (data !== undefined && data !== null) {
          let data1 = data.map((item) => {
            return { id: item.id, value: item.name, label: item.name }
          })
          this.setState({ userList: data1 });
        }
      })
    }

  }

  getInitState() {
    const { exercise = {}, auth, table } = this.props
    const { roles = [{ id: 0 }], id } = auth;
    let form = {
      amount: '',
      assigner: '',
      select_assigner: '',
      assigner_id: '',
      category: '',
      authorizer: roles[0].name,
      authorizer_id: id,
    }
    if (table === 'r16-1') {
      form.category = '融资'
    } else {
      form.category = table === 'r16-2' ? '开立' : '流转'
    }
    if (exercise.save !== '1') {
      exercise.amount = commafy(exercise.amount)
      exercise.select_assigner = { id: exercise.assigner_id, value: exercise.assigner, label: exercise.assigner }
    }
    return exercise.save === '1' ? { ...form } : { ...form, ...exercise }
  }

  //输入框值改变
  handleChange3 = (name, value) => {
    let value2 = value;
    if (name === 'amount') {
      value2 = numberfun(value2, 12);
    }
    return value2;
  }
  //输入框失去焦点 金额 千分位
  handleBlur2 = (name, value) => {
    return commafy(value);
  }


  //保存 提交
  handleSubmit = (values, handle) => {
    const text = handle || 'cancle';
    let { select_assigner, timestamp, isActive, save, formOpen, auth, __v, _id, ...paramsDate } = values || this.state.form;
    let ids = paramsDate._id || '';
    if (select_assigner) {
      paramsDate.assigner = select_assigner.value
      paramsDate.assigner_id = select_assigner.id
    }
    paramsDate.auth = this.props.auth;
    paramsDate.amount = delcommafy(paramsDate.amount);
    paramsDate._id = values._id
    paramsDate.ids = paramsDate._id
    //提交表单
    if (text === 'submit') {
      fetching(`/smrd/sqsp/save/${ids}`, {
        method: ids ? 'POST' : 'POST',
        body: JSON.stringify(paramsDate)
      }).then((data) => {
        try {
          if (data !== undefined && data !== null) {
            if (data.msg) {
              alert(`${ids?'授权审批编辑失败':'授权审批提交失败'},${data.msg}`)
              return;
            }
            this.props.onSubmit({
              ...paramsDate,
              submit_name: text
            })
          }else {
            alert('提交失败')
          }
        } catch (error) {
          console.log(error)
        }

      })
    } else {
      this.props.onSubmit({
        ...paramsDate,
        submit_name: text
      })
    }
  }

  render() {
    const { form, formStatic, userList } = this.state
    const { exercise = {}, classes, table } = this.props;
    const amount_label = () => {
      if (table === 'r16-1') return formStatic.financing_amount
      else if (table === 'r16-2') return formStatic.issue_amount
      else return formStatic.circulations_amount
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        validate={(values) => {
          return C161Formik(values, formStatic);
        }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, 'submit');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
            <div className={classes.layout}>
              <form >
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={12}>
                    <FormControl className={classes.textField}>
                      <InputLabel htmlFor="amount">{amount_label()}</InputLabel>
                      <Input
                        name="amount"
                        value={values.amount}
                        onChange={e => setFieldValue('amount', this.handleChange3.bind(this, 'amount', e.target.value)())}
                        onBlur={e => setFieldValue('amount', this.handleBlur2.bind(this, 'amount', e.target.value)())}
                        endAdornment={<InputAdornment position="end">元</InputAdornment>}
                        disabled={exercise.save === '3'}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </FormControl>
                    <FormikError touched={touched} errors={errors} name={'amount'} />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ margin: '10px 0', textAlign: 'left' }}>
                      <span style={{ fontSize: '14px' }}>{formStatic.assigner}</span>
                      <Select
                        isSearchable
                        value={values.select_assigner}
                        onChange={e => setFieldValue('select_assigner', this.handleChange3.bind(this, 'select_assigner', e)())}
                        options={userList}
                        isDisabled={exercise.save === '3'}
                      />
                      <FormikError touched={touched} errors={errors} name={'select_assigner'} />
                    </div>
                  </Grid>
                </Grid>
                <div style={{ margin: '20px 0', display: 'block', alignItems: 'center' }}>
                  {exercise.save !== '3' && <div>
                    <Button style={{ margin: '0 10px' }} variant="contained" color="primary" onClick={handleSubmit}>提交</Button>
                    <Button variant="contained" color="primary" onClick={this.handleSubmit.bind(this, values, 'cancle')}>撤销</Button>
                  </div>
                  }
                </div>
              </form>

            </div>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);



