const { string } = require('yup');
// const decimals = /^([1-9]|[1-9]\d|100)(\.\d{1,2})?$/ //数字1-100 保留两位小数
// const numbers = /^\d{1,12}$/ //数字
const phone = /^1[3456789]\d{9}$/ //手机号码
const pwd1 = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/ //密码
const pwd = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,18}$/ //密码
let length = 50;
let lenMsg = '长度不能超过50'



//注册

export const enterpriseFormik = (values, form2) => {

  let errors = {};
  if (!values.enterprise_name) {//姓名
    errors.enterprise_name = form2.enterprise_name;
  }
  if (!values.cell) {//用户名
    errors.cell = form2.cell;
  } else if (!phone.test(values.cell)) {
    errors.phone = '手机号码格式不正确';
  }
  if (!values.password) {
    errors.password = form2.password;
  } else if (!pwd.test(values.password)) {
    errors.password = '密码必须包含字母、数字和特殊字符，且在8~18位之间';
  } else if (!values.validate_code) {
    errors.validate_code = form2.validate_code;
  } else if (values.validate_code !== values.password) {//两次密码不一致
    errors.validate_code = "两次输入密码不一致";
  }
  if (!values.cell_validate_code) {
    errors.cell_validate_code = form2.cell_validate_code;
  }
  return errors;
}

export const customSealFormik = (form2 = {}) => {

  return {
    // enterprise_name:
    //   string()
    //     .max(length, lenMsg)
    //     .trim().required(form2.enterprise_name),
    // cell:
    //   string()
    //     .max(length, lenMsg)
    //     .trim().required(form2.cell)
    //     .matches(phone, '手机号格式不正确'),
    password:
      string()
        .max(length, lenMsg)
        .trim().required(form2.password),
    validate_code:
      string()
        .trim().required(form2.validate_code),
    agreement3:
      string()
        .trim().required(form2.agreement3),
    // .matches(validate_code!==password, '两次输入密码不一致'),
    // graph_validate_code:
    //   string()
    //     .trim().required(form2.graph_validate_code),
    // cell_validate_code:
    //   string()
    //     .trim().required(form2.cell_validate_code),
    // invite_cell_code:
    //   string()
    //     .trim().required(form2.invite_cell_code),
    // invite_people:
    //   string()
    //     .trim().required(form2.invite_people),
  }
}


//注册
export const moreInfoFormik = (form2 = {}) => {

  return {
    operator_email:
      string()
        .max(length, lenMsg)
        .trim().required(form2.operator_email),
    registry_address:
      string()
        .max(length, lenMsg)
        .trim().required(form2.registry_address),
    contact_address:
      string()
        .max(length, lenMsg)
        .trim().required(form2.contact_address),
    issue_invoice_enterprise_full_name:
      string()
        .trim().required(form2.issue_invoice_enterprise_full_name),
    tin:
      string()
        .trim().required(form2.tin),
    address:
      string()
        .trim().required(form2.address),
    enterprise_cell:
      string()
        .trim().required(form2.enterprise_cell),
    bank:
      string()
        .trim().required(form2.bank),
    bank_account:
      string()
        .trim().required(form2.bank_account),
    send_invoice_enterprise_full_name:
      string()
        .trim().required(form2.send_invoice_enterprise_full_name),
    receiver_name:
      string()
        .trim().required(form2.receiver_name),
    receiver_cell:
      string()
        .trim().required(form2.receiver_cell),
    receiver_address:
      string()
        .trim().required(form2.receiver_address),
    // agreement:
    //   string()
    //     .trim().required(form2.agreement),
  }
}
