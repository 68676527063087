
import getSubRouters from './helper';

export const subRootAry132 = [
   '出账审批流程',
   '手工还款流程',
   '押品入库管理',
   '押品出库管理',
 ];
const PARENT_PATH = '/risk-management/放款';
const PATH = '流程';
export default getSubRouters(PARENT_PATH, PATH, subRootAry132);