import React, { Component } from 'react';
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  Box,
  LinearProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { form5Formik } from './formikValidate';
import { Upload } from '../../../components/uploadText';
const { object } = require('yup');
global.count = 0;
const useStyles = (theme => ({
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  width: '100%',
  center: {
    alignItems: 'center',
  },
  error: {
    color: '#f00',
  },
}));
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
let fileList = [
  { id: 1, fileName: 'file1_obj', text: '扫描版*', type: '原件', file: '', typeValue: 'file1_type', name: '调查报告', require: true },
]
class Form5 extends Component {
  state = {
    form: this.getInitState(),
    fileList: fileList,
    uploads: this.props.uploads || {},
    actions: 0,
  }

  getInitState() {
    const { form = {}, exercise = {}, uploads } = this.props;
    let form2 = {};
    fileList.forEach((item) => {
      if (exercise.save === '1') {
        form2[item.fileName] = ''
        form2[item.typeValue] = item.type
      } else {
        form2[item.fileName] = uploads[item.fileName] || ''
      }
    })
    return { ...form, ...form2 }
  }
  uploadFile(e){
    let form = this.state.form;
    var fordata = new FormData();
    if (form.status_id === 9 ) {//补充上传文件
        fordata.append('type',4);
        fordata.append('fileName',e.file);
        fordata.append('fileSource',e.uploadName);
        fordata.append('id',form.id);
        const options = {
          method: "POST",
          body: fordata
        }
        global.count = (global.count+1)
        return fetch(`/msapi/upload/reUpload`,options) 
    } else {
      fordata.append('type',4);
      fordata.append('fileName',e.file);
      const options = {
        method: "POST",
        body: fordata
      }
      global.count = (global.count+1)
      return fetch(`/msapi/upload/upload`,options)
    }
  }
  uploadHandler = async (e,type) => {
	this.setState({progressLength : 0})
    	this.setState({progress: true})
  	const timer = setInterval(() => {
    	  this.setState({progressLength : (this.state.progressLength >= 99 ? 99 : this.state.progressLength + 1) })
   	 }, 30);
    let indexId = '';
    indexId = await this.uploadFile(e).then(res => {
      if (res) {
        if (!res.status){
          alert(res.msg)         
        }else{
    global.count = global.count - 1
          return res.json()
        }
      }else{
        return null
      }
    }).then(r=>{      
      if (r.error.code === 200) {//无错
          clearInterval(timer);
          this.setState({progressLength : 100})
          setTimeout(() => {
            alert("文件上传成功");
            this.setState({ progress: false })
          }, 500)
          return r.data
        } else {//业务失败
          alert(r.error.message)
          this.setState({ progress: false })
          return null;
        }
    }) 
    if(type === 1){
      this.state.form.file1_obj_id= indexId 
    }
    return true
    
  }

  //保存
  handleNext = (values) => { 
    values.file1_obj_id = this.state.form.file1_obj_id
    this.props.handleNext(values);
  };
  //上一步
  handleBack = (values) => {
    this.props.handleBack(values);
  };
  //保存
  handleSave = (values) => {
    values.file1_obj_id = this.state.form.file1_obj_id
    this.setState({ actions: 1 })
    this.props.handleLoading(true)
    this.props.handleSave(values);
  };
  //提交
  handleSubmit1 = (values) => {
    values.file1_obj_id = this.state.form.file1_obj_id
    this.setState({ actions: 2 })
    this.props.handleLoading(true)
    this.props.handleSubmit1(values);
  };
  // getApprovalNotice = () => {
  //   const { fdd_sxsp_path = '' } = this.state.form;
  //   window.open(fdd_sxsp_path)
  // }

  render() {
    const { activeStep, exercise = {}, formStatic, classes } = this.props;
    const { form, actions, progress, progressLength } = this.state;

    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证   encType="multipart/form-data"
        validationSchema={object().shape(form5Formik(formStatic))}
        //验证提交
        onSubmit={(values) => {
          if(global.count === 0){
          this.handleSubmit1(values);
        }else{
          alert("文件正在上传请稍后!")
        }
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <form method="post" >
              <Grid container justify="space-around" alignItems='center' alignContent='center'>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom >
                    {formStatic.file_name}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1" gutterBottom>
                    {formStatic.file_type}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                  <Typography variant="body1" gutterBottom>
                    {formStatic.handle}
                  </Typography>
                </Grid>
              </Grid>
		{/* 上传进度条 */}
              { progress &&
                  <div>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate"  value={progressLength}/>
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                      progressLength
                    )}%`}</Typography>
                  </Box>
                </Box>
                </div>
                }
              {fileList.map((items) => {
                return (
                  <div key={items.id}>
                    <Grid container justify="space-around" >
                      <Grid item xs={4}>
                        <Typography variant="inherit" gutterBottom className={items.require ? classes.error : ''}>
                          {items.text}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="inherit" gutterBottom>
                          {items.type}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} >
                        <Upload disabled={exercise.save === '3' && ( exercise.status_id !== 9 || (this.props.auth.roles[0].id !== 1 && this.props.auth.roles[0].id !== 3)) }
                          fileList={[values[items.fileName]]}
                          //  fileList={uploads[items.fileName]? [uploads[items.fileName]] : [form[items.fileName]]}
                          uploadName={items.fileName} view={formStatic.view}
                          mold = "4"
                          exercise={exercise}
                          //uploadChange={e => setFieldValue(items.fileName, e.file)}
                          //deleteFile={e => setFieldValue(items.fileName, '')}>
                          uploadChange={e=>this.uploadHandler( e, items.id)}>

                          </Upload>
                      </Grid>
                    </Grid>
                  </div>
                )
              })
              }
              <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>

                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack.bind(this, values)}
                >
                  上一步
                </Button>
                {exercise.save !== '3' &&
                  // <Button style={{ margin: '0 10px' }} variant="contained" color="primary" onClick={this.handleSave.bind(this, values)}>保存</Button>
                  <div style={{ position: 'relative' }}>
                    <Button
                      style={{ margin: '0 10px' }}
                      variant="contained"
                      color="primary"
                      disabled={this.props.loading}
                      onClick={this.handleSave.bind(this, values)}
                    >
                      保存</Button>
                    {this.props.loading && actions === 1 && <CircularProgress size={24} style={loadingStyle} />}
                  </div>
                }
                {exercise.save !== '3' &&
                  // <Button variant="contained" color="primary" onClick={handleSubmit}>提交</Button>
                  <div style={{ position: 'relative' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.props.loading}
                      onClick={handleSubmit}
                    >
                      提交</Button>
                    {this.props.loading && actions === 2 && <CircularProgress size={24} style={loadingStyle} />}
                  </div>
                }

              </div>

            </form>
          )}
      />
    );
  }
}

export default withStyles(useStyles)(Form5);