
import getSubRouters from './helper';

export const subRootAry1 = [
    '贸易合同查询',
    '贸易合同录入',
  ];


const PARENT_PATH = '/risk-management/应收账款管理';
  const PATH = '贸易合同信息';
  export default getSubRouters(PARENT_PATH, PATH, subRootAry1);
