// const f2_3 = require('./f2_3.js');
import f2_3 from './f2_3.js';
import f3_1_8 from './f3_1_8.js';
import f3_2_1 from './f3_2_1.js';
import f5_1 from './f5_1.js';
import f14_5_1 from './f14_5_1.js';
import common from './common.js';
import f4_1 from './f4_1.js';//还款弹窗--20201218
// const common = require('./common.json');
import f14_5_2 from './f14_5_2.js';
import zd from './zd.js';
import f7_2 from './f7_2.js';
import f7_5_1 from './f7_5_1.js';
import f6_7 from './f6_7.js';

export {
    f2_3,
    f3_1_8,
    f3_2_1,
    f5_1,
    common,
    f14_5_1,
    f14_5_2,
    f4_1,//还款弹窗--20201218
    zd,
    f7_2,
    f7_5_1,
    f6_7,
}