import React, {Component} from "react";
import {Formik} from "formik";
import * as yup from "yup";
import withStyles from "@material-ui/core/styles/withStyles";
import Form from "./form";
import ProTip from '../../home/Info/ProTip'
import {Button, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import { fetching } from '../../config/utils'

const styles = theme => ({
  dialog: {
    margin: theme.spacing(1),
  },
});

const validationSchema = yup.object().shape({
  cell: yup.string().min(11).required('请输入11位注册手机号码'),
  sms: yup.number().required('请输入短信验证码'),
  passwd1: yup.string().min(8, '最少8位,且必须包含字母、数字和特殊字符').required('请输入口令'),
  passwd2: yup.string().min(8, '最少8位,且必须包含字母、数字和特殊字符').required('请再次输入口令')
});

class ResetPasswordForm extends Component {
  state = {open: false};

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  /*handleSubmit = values => {
   return fetching(`/msapi/user/update_passwdByPhoneCode?id=${values.cell}&code=${values.sms}&pwd=${values.passwd1}`, {
    //return fetching(`/msapi/user/update_passwdByPhoneCode?id=${values.cell}&pwd=${values.passwd1}&code=${values.sms}`, {
   // return fetching(`/msapi/user/update_passwd?id=${values.cell}&pwd=${values.passwd1}`, {
      method: 'put',
      headers: {'Content-Type': 'application/json; charset=UTF-8'}
      //body: JSON.stringify({my_id: values.cell, my_passwd: values.passwd1})
    }, 99)
      //.then(res => res.json())
      .then(res => {
        if (res.data.status === 200) {
          this.handleClose();
          alert(res.data.msg);
        } else {
          alert(res.data.msg)
        }
      });
  };*/
handleSubmit = values =>  {
    var pwd = values.passwd1;
    const body = {
      id: values.cell, 
      code: values.sms , 
      pwd: pwd
    };
    const HEADERS = {
      "Accept": "application/json",
      "Content-Type": "application/json; charset=UTF-8",
    };
    const options = {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify(body)
    };
    return  fetch("/msapi/auth/updatePwd", options)
    .then(res => res.json())
    .then(data => {
    if (data.data.status === 200) {
            this.handleClose();
            alert(data.data.msg);
          } else {
            alert(data.data.msg)
          }
        });
  };

  render() {
    const classes = this.props;
    const values = {cell: '', sms: '', passwd1: '', passwd2: ''};
    return (
      <div>
        <Button color ="primary"  onClick={this.handleClickOpen} style={{ color: '#e7e7e7' }}>
          忘记密码?🔑
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-reset-password-title"
        >
          <DialogTitle id="form-reset-password-title" className={classes.dialog}>
            <ProTip message="重置融单企业管理员口令"/>
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={values}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
              render={props => <Form {...props} handleClose={this.handleClose}/>}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ResetPasswordForm);
