import React from 'react';
import TabPanels from './TabPanels'
import getSubRouters from './helper';

const PARENT_PATH = '/business/应收账款';
const PATH = '应收账款';

export const subRootAry = [
  "开具中应收账款",
  "已开应收账款",
  "已退回应收账款",
  //"可用应收账款",
];

// option 1:
export function tabPanels(ary = []) {
  return <TabPanels ary={ary}/>;
}

export default getSubRouters(PARENT_PATH, PATH, subRootAry);
