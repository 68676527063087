import React, {Component } from 'react'

import { CircularProgress } from '@material-ui/core'

import { green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { DEFAULT_UPLOADS_URL,DEFAULT_EXPORT_URL } from '../../config/constants'
// import { fetching } from '../../config/utils'
// import CustomizedSnackbars from '../message';

const useStyles = (theme => ({
  error: {
    color: '#F56C6C',
  },
  pass: {
    color: '#67C23A',
  },
  uploadBox: {
    width: '100%',
    paddingRight: '10px',
  },
  noEdit: {
    color: '#989898'
  },
  isEdit: {
    color: '#409EFF'
  }
}));

const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '26%',
  left: '74%',
  // marginTop: -12,
  // marginLeft: -17,
}

class Upload extends Component {
  state = {
    value: '',
    name: '',
    mold: '',
    open: false,
    open2: false,
    message: '',
    fileList: [],
    imgUrl: '',
  }

  componentWillMount() {
    if (this.props.fileList instanceof Array) {
      this.setState({ fileList: this.props.fileList })
    }
  }

  handleChange = (e) => {

    const {uploadName = '' ,mold = '' , } = this.props;
    let files = e.currentTarget.files;
    let type2 = files[0].type;
    let fileList = Array.from(files);
    if (files[0].size / 1024 / 1024 > 10) {
      this.setState({ open: true, message: '上传文件大小不能超过10M', value: '' });
      return;
    }
    if (
      type2 === 'image/png' || type2 === 'image/jpeg' ||
      type2 === 'image/jpg' || type2 === 'application/msword' || type2 === 'application/pdf' ||
      type2 === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.props.uploadChange({ file: fileList[0], uploadName ,mold})
      this.setState({ fileList });
    } else {
      alert('上传文件格式只能是jpg/jpeg/png/pdf/doc/docx')
    }
  }
  //消息提示
  handleClose = () => {
    this.setState({ open: false });
  }
  //删除上传文件
  handleUplaodClose = ({ target: index }) => {
    let fileList = this.state.fileList;
    fileList.splice(index, 1);
    this.setState({ fileList, value: '' });
  }
  //图片模态框
  handleClose2 = () => {
    this.setState({ open2: false });
  }
  //查看实例
  // viewFile = () => {
  //   const files = this.state.fileList;
  //   if (files[0] instanceof Object) {
  //     let imgUrl = URL.createObjectURL(files[0]) || '';
  //     this.setState({ open2: true, imgUrl });
  //   } else {
  //     let imgUrl2 = DEFAULT_UPLOADS_URL + files[0] || '';
  //     if (/\.pdf$/i.test(imgUrl2)) {
  //       this.setState({ imgUrl: imgUrl2 });
  //       return window.open(imgUrl2);
  //     } else {
  //       this.setState({ open2: true, imgUrl: imgUrl2 });
  //     }
  //   }
  // };

    //查看实例
    viewFile = () => {

      const files = this.state.fileList;
      //debugger
      if (files[0] instanceof Object) {
        let imgUrl1 = URL.createObjectURL(files[0]) || '';
        if (files[0].type === 'image/jpeg' || files[0].type === 'image/png' || files[0].type === 'image/jpg' || 
          files[0].type === 'application/pdf' || files[0].type === 'application/msword' ||
          files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          window.open(imgUrl1)
        } else {
          alert('doc/docx文件格式暂不支持预览')
        }
      } else {  
      const { mold='', uploads = {}, uploadName = '' } = this.props;
      let imgUrl = DEFAULT_EXPORT_URL + `msapi/upload/view?id=`+this.props.exercise.id+"&type="+parseInt(mold)+"&field="+uploadName
      //let imgUrl2 = DEFAULT_UPLOADS_URL + `msapi/upload/view?id=`+this.props.exercise.id+"&type="+parseInt(mold)+"&field="+uploadName
      window.open(imgUrl)
      }

    }

    
    /*//查看实例
    viewFile = () => {
      const files = this.state.fileList;
      var imgExt = [".png", ".jpg", ".jpeg", ".bmp", ".gif"];
      if (files[0] instanceof Object) {
        let imgUrl = URL.createObjectURL(files[0]) || '';
        if (files[0].type === 'image/jpeg' || files[0].type === 'image/png' || files[0].type === 'image/jpg') {
          this.setState({ open2: true, imgUrl });
        } else {
          window.open(imgUrl)
        }
      } else {
        let imgUrl2 = DEFAULT_UPLOADS_URL + files[0] || '';
        var i = imgUrl2.lastIndexOf(".");
        var ext = i > -1 ? imgUrl2.substring(i) : '';
        if (imgExt.includes(ext)) {
          this.setState({ open2: true, imgUrl: imgUrl2 });
        } else {
          window.open(imgUrl2)
        }


      }

    }*/

  render() {
    const { message, open, open2, value, imgUrl, fileList, uploads,form} = this.state;
    const { msgClose, uploadClose, disabled = false, view = '查看', classes, } = this.props;
    const action = () => {
      if (msgClose) {
        return [
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]
      } else {
        return []
      }
    }
    return (
      <div className="uploadBox" style={{ display: 'flex', flexDirection: 'row', width: 'auto', minWidth: '10px', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ margin: '5px 0', display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}>
          <div variant='contained' className={disabled ? classes.noEdit : classes.isEdit}
            style={{ position: 'relative', marginRight: '10px', overflow: 'hidden', textDecoration: 'underline' }}>
            <input disabled={disabled} type="file" name={this.props.uploadName} value={value} className="uploadFile" onChange={this.handleChange}></input>
            上传
          </div>
          
        </div>
        {
          (fileList[0] && fileList.length > 0 && fileList[0] !== '0' ) &&
        
          fileList.map((item, index) => {
            return (
              <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ textDecoration: 'underline', color: '#409EFF', cursor: 'pointer' }} variant='contained' onClick={this.viewFile}>{view}</div>
                <span title={item.name ? item.name : ''} className={item ? classes.pass : classes.error} style={{ margin: '0 10px' }}>{item ? '已上传' : '未上传'}</span>
                {uploadClose && <IconButton
                  key="close"
                  aria-label="close"
                  color="primary"
                  index={index}
                  size="small"
                  onClick={this.handleUplaodClose}
                >
                  <CloseIcon />
                </IconButton>}
              </div>
            )
            
          })
   
        }
      
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open}
          style={{ background: '#09f' }}
          autoHideDuration={4000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{message}</span>}
          action={action()}
        />
        <Dialog maxWidth={'lg'} onClose={this.handleClose2} aria-labelledby="customized-dialog-title" open={open2}>
          <MuiDialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            id="customized-dialog-title">
            <span>{view}</span>
            {/* <IconButton aria-label="close"  onClick={this.handleClose2}>
             <CloseIcon />
             </IconButton> */}
          </MuiDialogTitle>
          <MuiDialogContent style={{ minWidth: '600px', minHeight: '400px' }}>
            <img src={imgUrl} alt='' />
          </MuiDialogContent>
        </Dialog>
      </div>
    )
  }
}
export default withStyles(useStyles)(Upload);
