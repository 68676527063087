import {
  QueryBuilder as QueryBuilderIcon,
} from '@material-ui/icons';
import {subRouters, getMenu, getContent} from './common';
import { SetView } from './Authorization/commonTable'
const PARENT_PATH = subRouters['授权审批'];

const Info = [
  {
    path: "融资授权",
    icon: QueryBuilderIcon,
    component: SetView('r16-1')
  },
  {
    path: "开立授权",
    icon: QueryBuilderIcon,
    component: SetView('r16-2')
  },
  {
    path: "流转授权",
    icon: QueryBuilderIcon,
    component: SetView('r16-3')
  },
];

export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];