import React, { Component } from 'react';
import {
  ViewComfy, Edit, AccessTimeOutlined
} from '@material-ui/icons'
import MaterialTable from '../../vendors/material-table';
import { defer, capitalize, fetching } from '../../config/utils';
import { core_business_url, getProcess } from './core_business_url';
import { DialogForm1, CommonDialog } from '../../components/commonForm'
import {
  Button
} from '@material-ui/core';
import { DownloadTimeForm, DialogForm } from '../../02-core_business/downloadTime';//时间选择弹窗--20201117
import  Dialog from './Dialog';
import  BtUserPushForm from './BtUserPushForm';
import BtTransactionDataPushForm from './BtTransactionDataPushForm';
import { common ,userPush } from '../formStatic';
import ApplyIndex from './applyIndex';
import { loginInfos } from '../../components/numToText'
import CheckForm from './CheckForm';
import FreezingCheckForm from './FreezingCheckForm';//额度冻结解冻 20210803
import { formatDate, getNowFormatDate } from '../../components/date';
import ApplyCheckForm from '../CreditManager/ApplyCheckForm';//授信延时申请

class View extends Component {
  state = {
    columns: [{}],
    data: [],
    hasToken: sessionStorage.getItem("authToken"),
    dataUrl: { url: { query: '' } },
    id: 10000,
    form2: { ...common },
    exercise: {
      save: '1',
      DialogTitle: '新增',
      checkOpen: false,
      open: false,
      status2: '1'
    },
    //auth: {},//登录信息
    auth: this.props.auth || {},//{},//登录信息
    opinion_list: [],
    roleList: [],
    checkExercise: {},
    signExercise: {},
    guarantInfo1: {},
    guarantInfo2: {},
    guarantInfo3: {},
  };
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  }
  //获取数据
  componentDidMount() {
    const { table } = this.props;
    let auth = loginInfos();
    this.setState({ auth });
    fetching('/msapi/roles/find', { method: 'GET' }).then(data => {
      this.setState({ roleList: data || [] })
    })
    fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {
        if (data !== undefined && data !== null) {
          const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
          //根据不同的页面，获取不同的接口地址，渲染不同的数据
          const dataUrl = core_business_url(auth, table).find(item => item.name === table);
          const dataUrl2 = dataUrl || {};
          this.setState({ columns, dataUrl: dataUrl2 });
          if (table === 'r4-1-3') {
            this.onQuery2(table);
          } else if(table === 'r4-3-1'|| table === 'r4-3-2' || table === 'r4-3-3' || table === 'r4-3-4' ||
          table === 'r4-4-1'|| table === 'r4-4-2' || table === 'r4-4-3' || table === 'r4-4-4'){
           this.onQuery3();//额度停用/启用
          } else if(table === 'r4-5-1'|| table === 'r4-5-2' || table === 'r4-5-3'){
           this.onQuery4();//授信延时
          } else if(table === 'r4-5-4'){
            this.onQuery5();//授信延时
          } else if(table === 'r4-6-1'|| table === 'r4-6-2' || table === 'r4-6-3' ){
            this.onQuery6();//大树风控授信
          } else {
            this.onQuery();
          }
        }

      })
  }

  //查询数据
  onQuery = () => {
    const { table } = this.props;
    const { url = {} } = this.state.dataUrl;
    const { roles = [{ id: 0 }] } = this.state.auth;
    fetching(roles[0].id === 9 ? url.query1 : url.query, { method: 'GET' }).then(data => {
      if (data !== undefined && roles[0].id !==2 && roles[0].id !== 4 && table !== 'r4-1-4') {
        let datas = data.map(item => {
          if (item.status) {
            item.status = item.status.name;
          }
            item.role_name = getProcess(item.role.id, table, this.state.roleList);
          return item;
        })
        this.setState({ data: datas });
      }else if(data !== undefined && table === 'r4-1-4' && roles[0].id !== 14 && roles[0].id !==2 && roles[0].id !== 4 ){
        let datas = data.map(item => {
          if (item.status) {
            item.status = item.status.name;
          }
            item.role_name = getProcess(item.role.id, table, this.state.roleList);
          return item;
        })
        this.setState({ data: datas });
      }
    })

  }

  //查询额度停用数据
  onQuery3 = () => {
    const { table } = this.props;
    const { url = {} } = this.state.dataUrl;
    const { roles = [{ id: 0 }] } = this.state.auth;

    fetching(url.query, { method: 'GET' }).then(res => {
      if (res !== undefined && res.data) {
        let datas = res.data.map(item => {
          if (item.organization) {
            item.enterprise_name = item.organization.name;
          }
          item.creditStartDate = formatDate(new Date(Date.parse(item.creditStartDate)), 'yyyy-MM-dd');
          item.creditDueDate = formatDate(new Date(Date.parse(item.creditDueDate)), 'yyyy-MM-dd');
          //item.credit_start_date = item.createdAt
          /*if (item.Status) {
            item.status = item.Status.name;
          }*/
         if( table ==='r4-4-2'/* || table === 'r4-3-2'*/){
          if (!item.fdd_file_name && item.roleId === 8) {
            item.role_name = '已完成'
          } else {
            item.role_name = getProcess(item.roleId, table, this.state.roleList);

          }
        }
         if (table === 'r4-3-2') {
            item.role_name = getRoleName(item.roleId, table, this.state.roleList,item.amountStatus);
         }
          return item;
        })
        this.setState({ data: datas });
      }
    })
 }
 
 //查询授信延期数据
 onQuery4 = () => {
      const { table } = this.props;
      const { url = {} } = this.state.dataUrl;
      const { roles = [{ id: 0 }] } = this.state.auth;
      let status;	
      fetching(url.query, { method: 'GET' }).then(res => {
        if (res !== undefined && res.data) {
          let datas =res.data.map(item => {
            if (item.organization) {
              item.enterprise_name = item.organization.name;
            }
            item.creditStartDate = formatDate(new Date(Date.parse(item.creditStartDate)), 'yyyy-MM-dd');
            item.creditDueDate = formatDate(new Date(Date.parse(item.creditDueDate)), 'yyyy-MM-dd');
            item.delayDate = formatDate(new Date(Date.parse(item.delayDate)), 'yyyy-MM-dd');
            if(table === 'r4-5-3'){
	    status = item.delayStatusId;
            switch(item.delayStatusId){
                case 1:
                item.delayStatusId = '审核中';
                break;
                case 2:
                item.delayStatusId = '退回';
                break;
                case 3:
                item.delayStatusId = '已完成';
                break;
                default: item.delayStatusId='';
              }
	     if(status !== 2){
                if (!item.fdd_file_name && item.delayRoleId === 8) {
                  item.role_name = '已完成'
                } else {
                  item.role_name = getProcess(item.delayRoleId, table, this.state.roleList);
                }
              }else {
                switch(item.delayRoleId){
                  case 8:
                    item.role_name = '风险经理';
                  break;
                case 3:
                  item.role_name = '业务经理';
                    break; 
                default: ;
                }
              }
            }
            return item;
          })
          this.setState({ data: datas });
        }
      })
  
    }

  //查询多个状态
  onQuery2 = (table) => {
    try {
      const { url } = this.state.dataUrl;
      let fetchingData = [];
      if (table === 'r4-1-3') fetchingData = [fetching(url.query), fetching(url.query2)]
      Promise.all(fetchingData).then(values => {
        let data = []
        values.forEach(item => {
          if (item instanceof Array) {
            data = data.concat(item);
          }
        })
        data = data.map(item => {
          if (item.status) {
            item.status = item.status.name;
          }
          if (!item.fdd_file_name && item.role.id === 14) {
            item.role_name = '已完成'
          } else {
            item.role_name = getProcess(item.role.id, table, this.state.roleList);

          }
          return item;
        })
        this.setState({ data });
      });
    } catch (e) {
    }

  }

  onQuery5 = () => {
    const { url = {} } = this.state.dataUrl;
    fetching(url.query, { method: 'GET' }).then(res => {
      if (res !== undefined && res !== null) {
        let datas =res.map(item => {
          if (item.organization) {
            item.enterprise_name = item.organization.name;
          }
          item.creditStartDate = formatDate(new Date(Date.parse(item.creditStartDate)), 'yyyy-MM-dd');
          item.creditDueDate = formatDate(new Date(Date.parse(item.creditDueDate)), 'yyyy-MM-dd');
          return item;
        })
        this.setState({ data: datas });
      }
    })
  }

  onQuery6 = () => {
    const { url = {} } = this.state.dataUrl;
    fetching(url.query, { method: 'GET' }).then(res => {
      if (res !== undefined && res !== null) {
        let datas =res.map(item => {
          return item;
        })
        this.setState({ data: datas });
      }
    })
  }

  onRef = (ref) => {
    this.child = ref
  }

  //  table新增
  onAdd = newData => {
    let data = this.state.data;
    data.unshift(newData);
    this.setState({ data })
    return defer(0).then();
  }
  //表单新增
  FormSubmit = (newData = {}) => {
    this.setState({
      exercise: { ...this.state.exercise, open: false }
    })
    setTimeout(() => {
      this.onQuery();
    }, 500)
  }
  // 对象查找
  findObject = (obj, data) => {
    let index = data.findIndex(item => item.id === obj.id);
    return index;
  }
  //新增开具表单
  handleToggle = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open: false,checkOpen:false }
      })
      //window.location.reload()//授信页面强制新
    } else {
      this.setState({
        checkExercise: { save: '2', },
        exercise: { status2: '1', DialogTitle: form2.add, save: '1', open: true,}
      })
    }
  }
  //公共审核表单提交
  FormSubmit3 = (newData) => {
    const table = this.props.table || '';
    if (newData.statusId === 9 && table === 'r4-1-2') {
      //fetching(`/msapi/amount/query/${newData.credit1_id}/sync1`, { method: 'GET' }).then(data => { })
      //授信数据处理(单条已审批完成的授信)：--20210203
      fetching(`/amount/usedAmount/handleCredit1s?credit1Id=${newData.credit1_id}`).then(res => {
        if (res !== undefined && res.code !== 200)  {
          alert(res.msg || '额度处理失败！')
        } else {
          alert('额度处理成功！')
        }
      })
    }
    this.setState({
      exercise: { ...this.state.exercise, open: false,checkOpen:false }
    })
    setTimeout(() => {
      this.onQuery();
    }, 500)
  }

  //冻结/解冻审核表单提交
   FormSubmit4 = (newData) => { 
    this.setState({
      exercise: { ...this.state.exercise, open: false,checkOpen:false }
    })
    setTimeout(() => {
      this.onQuery3();
    }, 500)
  }
   //授信延期审核表单提交
   FormSubmit2 = (newData) => {
    this.setState({
      exercise: { ...this.state.exercise, open: false,checkOpen:false }
    })
    setTimeout(() => {
      this.onQuery4();
    }, 500)
  }
//准入推送接口
  BtMsgPush = (e,row) => {
    fetching(`/msapi/bigTree/btUserPush?msgId=${row.msgId}`).then(res => {
      if (res !== undefined && res !== null) {
          alert(res.msg);
          }
        })
  }
//大树查看
btOnview = (e, row) => {
      const { form2, dataUrl } = this.state
      const table = this.props.table || '';
      setTimeout(() => {
            this.setState({
              checkExercise: {
                ...row,
                Status: row.status,
                DialogTitle: form2.check,
                status2: '2',
                rolseId: '1',
                checkStatus: '3',
                save: '3',
               },
               exercise: { ...row, save: '3', status2: '', DialogTitle: form2.view, open: true, }
              });
            }, 500)
            
          }

  //撤回
  onWithdraw = (e, row) => {
    const { dataUrl, } = this.state;
    const table = this.props.table || '';
    fetching(dataUrl.url.get + `${row.id}/withdraw`, { method: 'PUT' }).then((res) => {
      try {
        this.onQuery2(table);
      } catch (error) { }
    })
  }
  //表格删除
  onDelete = async oldData => {
    const { dataUrl } = this.state
    const returnedData = await fetching(dataUrl.url.get + oldData.id, { method: 'GET' })
    returnedData && this.onQuery();
    return returnedData;

  }
    //已退回授信额度停用申请表格删除
    onDelete2 = async oldData => {
      //debugger
      const { dataUrl } = this.state
      const returnedData = await fetching(dataUrl.url.get4 + oldData.id + '/1', { method: 'GET' })
      setTimeout(() => {
        alert("删除成功");
      }, 500)
      returnedData && this.onQuery3();
      return returnedData;
  
    }

	      //已退回授信额度停用申请表格删除
     onDelete3 = async oldData => {
        //debugger
        const { dataUrl } = this.state
        const returnedData = await fetching(dataUrl.url.get4 + oldData.id + '/2', { method: 'GET' })
        setTimeout(() => {
          alert("删除成功");
        }, 500)
        returnedData && this.onQuery3();
        return returnedData;
    
      }

  getGuarantInfo = (id, guaranty_way) => {
    let url = '';
    let guarantyWay1 = guaranty_way ? guaranty_way.split(",") : '';
    guarantyWay1.forEach((item) => {
      if (item === '抵押') {
        url = '/msapi/mortgage/find?credit1_id=' + id
      } else if (item === '质押') {
        url = '/msapi/pledge/find?credit1_id=' + id
      } else if (item === '保证') {
        url = '/msapi/guarantor/find/?credit1_id=' + id
      }
      if (url) {
        fetching(url, {
          method: 'GET',
        }).then(datas => {
          try {
            if (item === '抵押') {
              this.setState({ guarantInfo1: datas[datas.length - 1] });
            } else if (item === '质押') {
              this.setState({ guarantInfo2: datas[datas.length - 1] });
            } else if (item === '保证') {
              this.setState({ guarantInfo3: datas[datas.length - 1] });
            }
          } catch (error) {
          }
        })
      }

    })

  }


  //编辑
  onUpdate2 = (e, row) => {
    const { form2, dataUrl } = this.state
    let status2 = '1'
    const table = this.props.table || '';
    if (row.id && row.guaranty_way) {
      this.getGuarantInfo(row.id, row.guaranty_way);
    }
    setTimeout(() => {
      if (table === 'r4-1-1') {
        this.setState({
          checkExercise: { ...row, Status: row.status, DialogTitle: form2.check, status2: '2', rolseId: '1', checkOpen: true, checkStatus: '3', save: '2', opinion_list: [] },
          exercise: { ...row, save: '2', status2: '', DialogTitle: form2.edit, open: true, }
        });
      } else {
        fetching(dataUrl.url.get2 + `${row.id}`).then(data => {
          this.setState({
            checkExercise: {
              ...row,
              Status: row.status,
              DialogTitle: form2.check,
              status2: '2',
              rolseId: '1',
              // checkOpen: true,
              checkStatus: '3',
              save: '3',
              opinion_list: data || []
            },
            exercise: { ...row, save: '2', DialogTitle: form2.edit, status2, open: true, }
          })
        })
      }
    }, 500)

  };

  //查看
  onview = (e, row) => {
    const { form2, dataUrl } = this.state
    const table = this.props.table || '';
    if (row.id && row.guaranty_way) {
      this.getGuarantInfo(row.id, row.guaranty_way);
    }
    setTimeout(() => {
      if (table === 'r4-1-1') {
        this.setState({
          checkExercise: {
            ...row,
            Status: row.status,
            DialogTitle: form2.check,
            status2: '2',
            rolseId: '1',
            checkStatus: '3',
            save: '2',
            opinion_list: []
          },
          exercise: { ...row, save: '3', status2: '', DialogTitle: form2.view, open: true, }
        });
      } else {
        fetching(dataUrl.url.get2 + `${row.id}`).then(data => {
          this.setState({
            checkExercise: {
              ...row,
              Status: row.status,
              DialogTitle: form2.check,
              status2: '2',
              rolseId: '1',
              checkStatus: '3',
              save: '3',
              opinion_list: data || []
            },
            exercise: { ...row, save: '3', status2: '', DialogTitle: form2.view, open: true, }
          });
        })

      }
    }, 500)

  }
  //审核
  onCheck = (e, row) => {
    const { form2, dataUrl } = this.state
    setTimeout(() => {
      fetching(dataUrl.url.get2 + `${row.id}`).then(data => {
        this.setState({
          checkExercise: {
            ...row,
            Status: row.status,
            DialogTitle: form2.check,
            status2: '2',
            rolseId: '1',
            checkStatus: '3',
            save: '1',
            opinion_list: data || []
          },
          exercise: { ...row, save: '3', status2: '', DialogTitle: form2.check, checkOpen: true, }
        });
      })
    }, 500)

  }

  //额度冻结/解冻审核
  freezingCheck = (e, row) => {
    const { form2, dataUrl ,auth } = this.state
    let rolesId = auth.roles ? auth.roles[0].id : 0;
    setTimeout(() => {
      fetching(dataUrl.url.get2 + `${row.id}`).then(data => {
        if (rolesId ===1 ) {
          this.setState({
            checkExercise: {
              ...row,
              Status: row.Status,
              DialogTitle: form2.add,
              status2: '2',
              rolseId: '1',
              checkStatus: '3',
              save: '1',
              opinion_list: data || []
            },
            exercise: { ...row, save: '3', status2: '', DialogTitle: form2.add, checkOpen: true, }
          });
        } else{
          this.setState({
            checkExercise: {
              ...row,
              Status: row.Status,
              DialogTitle: form2.check,
              status2: '2',
              rolseId: '1',
              checkStatus: '3',
              save: '1',
              opinion_list: data || []
            },
            exercise: { ...row, save: '3', status2: '', DialogTitle: form2.check, checkOpen: true, }
          });
        }
        
      })
    }, 500)

  }
  
  freezingDelayCheck = (e, row) => {
    const { form2, dataUrl ,auth } = this.state
    setTimeout(() => {
      this.setState({
        checkExercise: {
          ...row,
          Status: row.Status,
          DialogTitle: form2.check,
          status2: '2',
          rolseId: '2',
          checkStatus: '3',
          save: '2',
          opinion_list: row || []
        },
        exercise: { ...row, save: '2', status2: '', DialogTitle: form2.check, open: true, }
      });
    }, 500)   
  }
  
   //触发授信申请延时弹框
  applyDelay  = (e, row) => {
    const {  form2} = this.state
      this.setState({
        exercise: { 
          ...row,
          save: '3',
          DialogTitle: form2.applyDelay, 
          open: true, 
        }
      });
  }

  //触发打开时间段选项弹窗--20240407
  exportTableYWD = (e) => {
    const { exercise, form2, dataUrl } = this.state;
    //open: true--详情页；checkOpen: true--公共表单
    //type: 1-后端/全部；2-前端/指定公司
    this.setState({
      exercise: { ...exercise, save: '3', DialogTitle: form2.exportTable, open: false, tableName: dataUrl.name, status2: '1', checkFlag: true, type: 1 }
    })
  }

    //触发打开时间段选项弹窗--20240408
    handleToggleYWD = (open) => {
      const { form2 } = this.state
      if (open) {
        this.setState({
          exercise: { open: false }
        })
      window.location.reload()
      } else {
        this.setState({
          exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
        })
      }
    }
  //搜索框Change
  onSearchChange = (query) => {

  }
  //页面更改事件
  onChangePage = (query) => {

  }
  //处理每页的行更改（pageSize）
  onChangeRowsPerPage = (query) => {

  }
  render() {
    const { columns, data, dataUrl, auth, exercise, checkExercise, guarantInfo1, guarantInfo2, guarantInfo3, } = this.state;
    const table = this.props.table || '';
    const title = dataUrl.title || '';
    const path = capitalize(this.props.table);
    const actions = actions2(this, this.props.table, auth);
    const editable = editable2(this, this.props.table, auth);
    //授信申请
    const R41 = () => {
      let isBtn = table === 'r4-1-1' ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <DialogForm1 handleToggle={this.handleToggle} exercise={{ ...exercise, addBtnName: '额度申请', isBtn }}>
             <ApplyIndex dataUrl={dataUrl} auth={auth} guarantInfo1={guarantInfo1} guarantInfo2={guarantInfo2} guarantInfo3={guarantInfo3} table={table}  form2={{ ...common }} exercise={{ ...exercise }} checkExercise={{...checkExercise}}
            onSubmit={this.FormSubmit} onSubmit3={this.FormSubmit3} />
        </DialogForm1>
      </div>
    }
    //审核
    const Check = () => {
      let isBtn = false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <CommonDialog handleToggle={this.handleToggle} exercise={{ ...exercise, isBtn }}>
             <CheckForm dataUrl={dataUrl} auth={auth} exercise={{ ...checkExercise, table }}
        onSubmit={this.FormSubmit3}
      />
        </CommonDialog>
      </div>
    }

    //冻结/解冻审核
    const FreezingCheck = () => {
      let isBtn = false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <CommonDialog handleToggle={this.handleToggle} exercise={{ ...exercise, isBtn }}>
             <FreezingCheckForm dataUrl={dataUrl} auth={auth} exercise={{ ...checkExercise, table }}
        onSubmit={this.FormSubmit4}
      />
        </CommonDialog>
      </div>
    }

    //授信延时审核
    const ApplyCheck = () => {
      let isBtn = false;
        return <div style={{ justifyContent: 'flex-end' }}>
          <DialogForm1 handleToggle={this.handleToggle} exercise={{ ...exercise, isBtn }}>
               <ApplyCheckForm dataUrl={dataUrl} auth={auth} exercise={{ ...exercise, table }}
          onSubmit={this.FormSubmit2}
        />
          </DialogForm1>
        </div>
      }
//客户信息准入
  const UserPush = () => {
      let isBtn = false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName6, isBtn }}
        >
          <BtUserPushForm auth={auth} formStatic={{ ...common, ...userPush }} exercise={exercise}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

//交易信息推送
 const TransactionDataPush = () => {
      let isBtn = false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName6, isBtn }}
        >
          <BtTransactionDataPushForm auth={auth} formStatic={{ ...common, ...userPush }} exercise={exercise}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    //导出按钮--20240408
  const C6 = () => {
    return <div style={{ textAlign: 'end', marginBottom: '10px' }}>
      <Button variant="contained" color="primary" onClick={this.exportTableYWD}>导出</Button>
    </div>
  }
  //时间段选择弹窗--20240408
  const DownloadTime = () => {
    let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <DialogForm handleToggle={this.handleToggleYWD} exercise={{ ...exercise, addBtnName: common.exportTable, isBtn }}>
          <DownloadTimeForm form2={{ ...common }} exercise={{ ...exercise }} onSubmit={this.FormSubmit} />
        </DialogForm>
    </div>
  }


    return <div>
      {(table === 'r4-1-1' || table === 'r4-1-2' || table === 'r4-1-3' || table === 'r4-1-4') && <R41></R41>}
      {(table === 'r4-1-1' || table === 'r4-1-2' || table === 'r4-1-3' || table === 'r4-1-4') && <Check></Check>}
      {(table === 'r4-3-1' || table === 'r4-3-2' || table === 'r4-3-3' || table === 'r4-3-4' 
     || table === 'r4-4-1' || table === 'r4-4-2' || table === 'r4-4-3' || table === 'r4-4-4') && <FreezingCheck></FreezingCheck>}
      {(table === 'r4-5-1' || table === 'r4-5-2' || table === 'r4-5-4') && <ApplyCheck></ApplyCheck>}
      {(table === 'r4-6-1'  ) && <UserPush></UserPush>}
      {( table === 'r4-6-2' ) && <TransactionDataPush></TransactionDataPush>}
      {(table === 'r4-1-3' ) && <C6></C6>}
      {(table === 'r4-1-3' ) && <DownloadTime></DownloadTime>}

      <MaterialTable
        title={title}
        onSearchChange={this.onSearchChange}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        path={path}
        columns={columns}
        data={data}
        actions={actions}
        editable={editable}
        localization={localization}
        options={options}
      />
    </div>
  }
}
const actions2 = (that, name, auth) => {
  const { roles = [{ id: 0 }] } = auth;
  let roleId = roles[0].id;
  let actions = [];
  let name2 = name;

  if (name === 'r4-1-4' && (roles[0].id !== 1)) {
    name2 = 'sh4-1-4';
  }
  switch (name2) {
    case 'r4-1-1':
      actions = [
        { icon: Edit, tooltip: '编辑', onClick: that.onUpdate2 },
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
      ];
      break;
    case 'r4-1-2':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        { icon: Edit, tooltip: '审核', onClick: that.onCheck },
      ];
      break;
    case 'r4-1-3':
      actions = [
        // rowData => ({
        //   icon: Undo,
        //   tooltip: '撤回',
        //   onClick: that.onWithdraw,
        //   hidden: (!(Number(rowData.roleId) === 1 && Number(rowData.statusId) === 3))
        // }),
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
      ];
      break;
    case 'r4-1-4':
      actions = [
        { icon: Edit, tooltip: '编辑', onClick: that.onUpdate2 },
      ];
      break;
    case 'sh4-1-4':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        { icon: Edit, tooltip: '审核', onClick: that.onCheck },
      ];
      break;
    case 'r4-3-1':
        if (roleId === 1) {
          actions = [
            { icon: Edit, tooltip: '新增', onClick: that.freezingCheck }
            ];
        }
          break;
    case 'r4-3-2':
          /*if (roleId ===2 || roleId ===3 || roleId ===4 || roleId ===8 || roleId ===14) {   // 4814
            actions = [
          { icon: Edit, tooltip: '审核', onClick: that.freezingCheck },
        ];
        }*/
        actions = [
          rowData => ({
            icon: Edit,
            tooltip: '审核',
            onClick: that.freezingCheck,
            hidden: (!((roleId === 3  &&  Number(rowData.roleId) === 1 && Number(rowData.amountStatus) === 3)   ||  (roleId ===8 && Number(rowData.roleId) === 3 && Number(rowData.amountStatus) === 3) ))
          })
         ];
          break;
    case 'r4-3-3':
        actions = [
          ];
          break;
    case 'r4-3-4':
        actions = [
          { icon: Edit, tooltip: '编辑', onClick: that.freezingCheck },
          ];
          break;
    case 'r4-4-1':
        if (roleId === 1) {
        actions = [
          { icon: Edit, tooltip: '新增', onClick: that.freezingCheck }
          ];
        }
          break;
    case 'r4-4-2':
        actions = [
          { icon: Edit, tooltip: '审核', onClick: that.freezingCheck }
          ];
          break;
    case 'r4-4-3':
        actions = [
          ];
          break;
    case 'r4-4-4':
        actions = [
          { icon: Edit, tooltip: '审核', onClick: that.freezingCheck },
          ];
          break;
     case 'r4-5-1':
        if (roleId === 8 || roleId === 3){
        actions = [
          { icon: Edit, tooltip: '审核', onClick: that.freezingDelayCheck },
        ];
        }
        break;
    case 'r4-5-2':
        if (roleId ===  1 || roleId === 3 ){
        actions = [
          { icon: Edit, tooltip: '审核', onClick: that.freezingDelayCheck },
        ];
        }
        break;
    case 'r4-5-4':
      if(roleId === 1){
        actions = [{ icon: AccessTimeOutlined, tooltip: '额度延时申请', onClick: that.applyDelay,}];
      }
      break;
    case 'r4-6-1':
      if (roleId === 1 ){
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.btOnview },
        { icon: 'save', tooltip: '准入信息推送大树', onClick: that.BtMsgPush },
      ];
      }
      break;
    case 'r4-6-2':
      if (roleId === 1 ){
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.btOnview },
        { icon: 'save', tooltip: '交易信息推送大树', onClick: that.BtMsgPush },
      ];
      } else {
        actions = [
          { icon: ViewComfy, tooltip: '查看', onClick: that.btOnview },
        ];
      }
      break;
    case 'r4-6-3':
      if (roleId === 1 ){
      actions = [
        { icon: 'save', tooltip: '额度申请信息推送大树', onClick: that.BtMsgPush },
      ];
      }
      break; 
    default:
      actions = [];
  }
  return actions;
}
const editable2 = (that, name, auth) => {
  const { roles = [{ id: 0 }] } = auth;
  let editable = {};
  let name2 = name;
  if (name === 'r4-1-4' && (roles[0].id === 1)) {
    name2 = 'sh4-1-4';
  }
  if(name ==='r4-3-4' && (roles[0].id === 1) ){
    name2 = 'sr4-3-4'
  }
  if(name ==='r4-4-4' && (roles[0].id === 1) ){
    name2 = 'sr4-4-4'
  }
  switch (name2) {
    case 'r4-1-1':
      editable = { onRowDelete: that.onDelete };
      break;
    case 'sh4-1-4':
      editable = { onRowDelete: that.onDelete };
      break;
    case 'sr4-3-4':
      editable = { onRowDelete: that.onDelete2 };
      break;
    case 'sr4-4-4':
      editable = { onRowDelete: that.onDelete3 };
      break;
    default:
    // editable = { onRowDelete: that.onDelete };
  }
  return editable;
}
const localization = {
  header: {
    actions: '操作'
  }
}
const options = {
  actionsCellStyle: { padding: '0 10px' },
  headerStyle: { //表格头部
    backgroundColor: '#1d4583',
    color: '#FFF',
    whiteSpace: 'nowrap'
  },
  rowStyle: { //表行
    backgroundColor: '#fff',
    whiteSpace: 'nowrap'
  },
  sorting: true,  //排序
  // selection: true //多选框
}
const setView = Comp => (collection, name) => {
  return class extends Component {
    render() {
      return <Comp table={collection} tableName={name} {...this.props} />
    }
  }
}

const roleName = (role_id, roleList) => {
  let roleName1 = roleList.filter((item) => {
      return (item.id === Number(role_id)) ? item : null;
  })
  return roleName1.length > 0 ? roleName1[0].name : '';
}

export const getRoleName = (role_id, table, roleList,status) => {
  let role_name;
   if ( table === 'r4-3-2' && status !== 7) {
      switch (role_id) {
          case 16 :
          case 1:
              role_name = roleName(3, roleList);
              break;
          case 3:
              role_name = roleName(8, roleList);
              break;
          case 8:
              role_name = roleName(14, roleList);
              break;
          case 14:
              role_name = '已完成';
              break;  
          default: role_name = '已完成';
      }
      return role_name
  } else if ( table === 'r4-3-2' && status === 7) {
    switch (role_id) {
        case 16 :
        case 1:
        case 3:
            role_name = roleName(1, roleList);
            break;
        case 8:
            role_name = roleName(3, roleList);
            break; 
        case 14:
          role_name = '已完成';
          break;  
        default: role_name = '已完成';
    }
    return role_name
} else if (table === 'r4-4-2') {
      switch (role_id) {
          case 1:
              role_name = roleName(3, roleList);
              break;
          case 3:
              role_name = roleName(8, roleList);
              break;
          case 8:
              role_name = '已完成';
              break;  
          default: role_name = '已完成';
      }

      return role_name
  }
}

export const SetView = setView(View);
