import React, { Component } from 'react';

import {
    TextField,
    Button,
    Grid,
  
    CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';

import { withStyles } from '@material-ui/core/styles';

import { Formik } from 'formik';
import { C116Formik } from './formikValidate';
import FormikError from './formikError';
import { fetching } from '../../config/utils';
import { formatDate } from '../../components/date'


const loadingStyle = {
    position: "absolute",
    color: green[500],
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -17,
}
const useStyles = (theme => ({
    formControl: {
        marginTop: '16px',
        marginBottom: '8px',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    width: '100%',
    label: {
        textAlign: 'left',
    },
    radio: {
        flexDirection: 'row',
    },
}));

const styles = {
    width: '100%',
    margin: '16px 0 8px 0'
}

class Form1 extends Component {
    //debugger
    state = {
        form: this.getInitState(),
        form2: this.props.form2 || {},//静态资源
        selectCustomerList: [],
        loading: false,
    }
    getInitState() {
        const { exercise = {} } = this.props;
        const form2 = {
            select_customer: '',
            enterprise_name: "",
            enterprise_no: "",
            organization_code: "",
            lglNm: "",
            lglIdCard: "",
            lgCardFileFront: false,
            lgCardFileBack: false,
            lglctfRgstDt: formatDate(new Date(), 'yyyy-MM-dd'),
            lglctfExpDt: formatDate(new Date(), 'yyyy-MM-dd'),
            business_license_file: false,
            ctfRgstDt: formatDate(new Date(), 'yyyy-MM-dd'),
            ctfExpDt: formatDate(new Date(), 'yyyy-MM-dd'),

        
            uploads: {},
        }
        if (exercise.save === '1') {
            form2.select_customer = ''
        } else {
            form2.select_customer = { value: exercise.enterprise_name, label: exercise.enterprise_name, enterprise_no: exercise.enterprise_no }
        }
        return { ...form2, ...exercise }
    }
    async componentDidMount() {
        if (!this.state.form.enterprise_no) {
            this.queryEnterpriseInfo();
        }
    }

    queryEnterpriseInfo = () => {
        fetching('/msapi/organizations', {
            method: 'GET',
        }).then(res => {
            if (res) {
                try {
                    let datas = res.filter((item) => {
                        item.value = item.name;
                        item.label = item.name;
                        return (item.id !== 1) ? item : null;
                    })
                    this.setState({ selectCustomerList: datas });
                } catch (error) {
                }
            } else {
            }
        })
    }
  //大树文件下载1
  downFile1 = (row) => {
    let url = "";
    if (row.idCardPhotoPreUrl !== null) {
        url = row.idCardPhotoPreUrl;
        window.open(url);
    } else {
        alert("该笔信息暂无文件,请联系管理员")
    }
}
    //大树文件下载2
  downFile2 = (row) => {
    let url = "";
    if (row.idCardPhotoBackUrl !== null) {
        url = row.idCardPhotoBackUrl;
        window.open(url);
    } else {
        alert("该笔信息暂无文件,请联系管理员")
    }
}
    //大树文件下载3
  downFile3 = (row) => {
    let url = "";
    if (row.idCardPhotoBackUrl !== null) {
        url = row.businessLicenseImgsUrl;
        window.open(url);
    } else {
        alert("该笔信息暂无文件,请联系管理员")
    }
    
  }
    //选择客户
    handleChange2 = (name, selectedOption) => {
        let form = { ...this.state.form }
        //form.enterprise_no = selectedOption.enterprise_no
        //form.organization_code = selectedOption.credit_no
        let enterprise_no = selectedOption.enterprise_no;
        let organization_code = selectedOption.credit_no;
        let url = `/msapi/customer_info_add/getCustomerMsg?enterprise_no=${enterprise_no}&credit_no=${organization_code}`;
        fetching(url,{
            method:'GET',
        }).then(res => {
        if (res) {
                if(res.data){
                    this.props.exercise.enterprise_no=true
                    this.props.exercise.organization_code=true
                    alert("同一客户不可重复补录多条信息,可选编辑!")
                 }else{
                 form.enterprise_no = selectedOption.enterprise_no
                 form.organization_code = selectedOption.credit_no
                 this.setState({ form: { ...form } })
                 }
            }
        })
        return selectedOption;
    }
    dateChange = (e) => {
        //const { extraction } = this.state.form
        if (e !== null) {
            let open_day = formatDate(e, 'yyyy-MM-dd');
        // if (e.getTime()) {
        //   let open_day = formatDate(e, 'yyyy-MM-dd');
        //   fetching(`/msapi/issue/find/${extraction}/${open_day}/open_before_due`).then(res => {
        //     if (res) {
        //       // william jiang: remove the alert, but keep the logic.
        //       if (!res.status) alert(res.msg)
        //       this.setState({
        //         is_open_day: res.status
        //       })
        //     }
        //   })
        // }
        return open_day;
      } else {
        alert("请选择日期");
      }
 }
    uploadChange = (file) => {

    };

    uploadFile(e){
            var fordata = new FormData();
            fordata.append('type',9);
            fordata.append('fileName',e.file);
            const options = {
              method: "POST",
              body: fordata
            }
            return fetch(`/msapi/upload/upload`,options)
          }
          uploadHandler = async (e,type) => {
            let indexId = '';
            indexId = await this.uploadFile(e).then(res => {
              if (res) {
                if (!res.status){
                  alert(res.msg)         
                }else{
                  return res.json()
                }
              }else{
                return null
              }
            }).then(r=>{      
              return r.data
            }) 
            if(type === 1){
              this.state.form.lgCardFileFrontId= indexId 
              this.state.form.lgCardFileFront = true
            } else if(type === 2){
              this.state.form.lgCardFileBackId = indexId 
              this.state.form.lgCardFileBack = true
            }  else if(type === 3){
              this.state.form.business_license_fileId= indexId 
              this.state.form.business_license_file = true
            }
        return true
    }

    conversion = (date) => {
        if (date instanceof Object && date.getTime()) {
            return formatDate(date, 'yyyy/MM/dd');
        }
        return date
    }
    //保存
    handleSave = (values) => {
        values.lgCardFileFrontId = this.state.form.lgCardFileFrontId
        values.lgCardFileBackId = this.state.form.lgCardFileBackId
        values.business_license_fileId = this.state.form.business_license_fileId
        this.setState({ loading: true })
        const { auth } = this.props;
        const { save, id } = this.state.form;
        let ids = (save === '2' || save === '3') ? id : '';
        let method = (save === '2' || save === '3') ? 'POST' : 'POST';
        values.enterprise_no = this.state.form.enterprise_no || ''
        values.enterprise_name = values.select_customer.value || ''
        values.organization_code = this.state.form.organization_code
        values.lglNm = this.state.form.lglNm
        if(values.lgCardFileFrontId !==null && values.lgCardFileFrontId !==0){
            values.lgCardFileFront = true;
        }else{
            values.lgCardFileFront = false;
        }  
        if(values.lgCardFileBackId !==null && values.lgCardFileBackId !==0){
            values.lgCardFileBack = true;
        }else{
            values.lgCardFileBack = false;
        }  
        if(values.business_license_fileId !==null && values.business_license_fileId !==0){
            values.business_license_file = true;
        }else{
            values.business_license_file = false;
        }  
        //values.issue_date = this.conversion(values.issue_date)

        values.auth = auth;//用户信息
        // const formData = {};
        // const excludes = ['createdAt', 'updatedAt', 'checkOpen', 'role_name', 'status', 'id', 'Uploads', 'select_customer', 'tableData', 'save', 'DialogTitle', 'status2', 'open', 'User', 'Role', 'Status', 'Organization', 'UserId', 'OrganizationId', 'StatusId', 'RoleId'];
        // for (let name in values) {
        //     if (!excludes.includes(name)) {
        //         formData[name]= values[name];
        //     }
        // }
        // formData.id = values.id

        // POST
        fetching(`/msapi/customer_info_add/save/${ids}`, {
            method:"post",
            body: JSON.stringify(values),
            //body: JSON.stringify(values),
        }).then((data) => {
            this.setState({ loading: false })
            try {
                if(data){
                    if(data.msg){
                        alert(ids ? `编辑失败，${data.msg}` : `新增失败，${data.msg}` )
                        return;
                    }
                    this.props.onSubmit({
                        ...this.state.form,
                    })
                }else {
                    alert(ids ? `编辑失败` : `新增失败` )
                }

            } catch (error) {}
        })
    };

    render() {
        const { exercise = {}, formStatic, classes } = this.props;
        const { form, selectCustomerList ,form2} = this.state;
        return (
            <Formik
                //初始化数据
                initialValues={{
                    ...form
                }}
                //表单验证
                validate={(values) => {
                    return C116Formik(values, formStatic);
                }}
                //验证提交
                onSubmit={(values) => {
                    this.handleSave(values);
                }}
                render={({
                    values,
                    errors,
                    touched,
                    uploads,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                        <form method="post" encType="multipart/form-data">
                            <Grid container justify="space-between">
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.orgName}
                                        value={values.orgName}
                                        name='orgName'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgName'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.orgCode}
                                        value={values.orgCode}
                                        name='orgCode'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgCode'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.legalPersonName}
                                        value={values.legalPersonName}
                                        name='legalPersonName'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'legalPersonName'} />
                                </Grid>
                              </Grid>
                            
                              <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.legalPersonNo}
                                        value={values.legalPersonNo}
                                        name='legalPersonNo'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'legalPersonNo'} />
                                </Grid>
                                
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.legalPersonGender}
                                        value={values.legalPersonGender}
                                        name='legalPersonGender'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'legalPersonGender'} />
                                </Grid>
                              </Grid>

                              <Grid container justify="space-between">
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.legalPhone}
                                        value={values.legalPhone}
                                        name='legalPhone'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'legalPhone'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.idCardValidDateStart}
                                        value={values.idCardValidDateStart}
                                        name='idCardValidDateStart'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'idCardValidDateStart'} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label={formStatic.idCardValidDateEnd}
                                        value={values.idCardValidDateEnd}
                                        name='idCardValidDateEnd'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'idCardValidDateEnd'} />
                                </Grid>
                              </Grid>
                              <Grid container justify="space-between">
                                <Grid item xs={4}>
                                    <TextField
                                        label={formStatic.orgRegLimitDateStart}
                                        value={values.orgRegLimitDateStart}
                                        name='orgRegLimitDateStart'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgRegLimitDateStart'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.orgRegLimitDateEnd}
                                        value={values.orgRegLimitDateEnd}
                                        name='orgRegLimitDateEnd'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgRegLimitDateEnd'} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label={formStatic.created_at}
                                        value={values.created_at}
                                        name='created_at'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'created_at'} />
                                </Grid>
                              </Grid>
                              <Grid container justify="space-between">
                                <Grid item xs={12}>
                                    <TextField
                                        label={formStatic.orgRegAddress}
                                        value={values.orgRegAddress}
                                        name='orgRegAddress'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgRegAddress'} />
                                </Grid>
                              </Grid>
                              <Grid container justify="space-between">
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.contactsNameFirst}
                                        value={values.contactsNameFirst}
                                        name='contactsNameFirst'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'contactsNameFirst'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.contactsPhoneFirst}
                                        value={values.contactsPhoneFirst}
                                        name='contactsPhoneFirst'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'contactsPhoneFirst'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.contactsRelationFirst}
                                        value={values.contactsRelationFirst}
                                        name='contactsRelationFirst'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'contactsRelationFirst'} />
                                </Grid>
                              </Grid>
                              <Grid container justify="space-between">
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.contactsNameSecond}
                                        value={values.contactsNameSecond}
                                        name='contactsNameSecond'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'contactsNameSecond'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.contactsPhoneSecond}
                                        value={values.contactsPhoneSecond}
                                        name='contactsPhoneSecond'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'contactsPhoneSecond'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.contactsRelationSecond}
                                        value={values.contactsRelationSecond}
                                        name='contactsRelationSecond'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'contactsRelationSecond'} />
                                </Grid>
                              </Grid>
                              <Grid container justify="space-between">
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.issuingAuthority}
                                        value={values.issuingAuthority}
                                        name='issuingAuthority'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'issuingAuthority'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.legalPersonAddress}
                                        value={values.legalPersonAddress}
                                        name='legalPersonAddress'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'legalPersonAddress'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.legalPersonNation}
                                        value={values.legalPersonNation}
                                        name='legalPersonNation'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'legalPersonNation'} />
                                </Grid>
                              </Grid>
                              <Grid container justify="space-between">
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.orgContactPhone}
                                        value={values.orgContactPhone}
                                        name='orgContactPhone'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgContactPhone'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.orgManageAddress}
                                        value={values.orgManageAddress}
                                        name='orgManageAddress'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgManageAddress'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.orgManageCity}
                                        value={values.orgManageCity}
                                        name='orgManageCity'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgManageCity'} />
                                </Grid>
                              </Grid>
                              <Grid container justify="space-between">
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.orgManageProvince}
                                        value={values.orgManageProvince}
                                        name='orgManageProvince'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgManageProvince'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.orgManageQx}
                                        value={values.orgManageQx}
                                        name='orgManageQx'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgManageQx'} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.orgManageScope}
                                        value={values.orgManageScope}
                                        name='orgManageScope'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgManageScope'} />
                                </Grid>
                              </Grid>
                              <Grid container justify="space-between">
                                <Grid item xs={2}>
                                    <TextField
                                        label={formStatic.orgRegCity}
                                        value={values.orgRegCity}
                                        name='orgRegCity'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgRegCity'} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        label={formStatic.orgRegProvince}
                                        value={values.orgRegProvince}
                                        name='orgRegProvince'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgRegProvince'} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        label={formStatic.orgRegQx}
                                        value={values.orgRegQx}
                                        name='orgRegQx'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgRegQx'} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        label={formStatic.orgSize}
                                        value={values.orgSize}
                                        name='orgSize'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orgSize'} />
                                </Grid>
                              </Grid>
                    {<Button onClick={this.downFile1} style={{ margin: '5px 0' }}
                        variant='contained' >{'法代身份证人像面下载'}</Button>
                    }
                    {<Button onClick={this.downFile2} style={{ margin: '5px 0' }}
                        variant='contained' >{'法代身份证国徽面下载'}</Button>
                    }
                    {<Button onClick={this.downFile3} style={{ margin: '5px 0' }}
                        variant='contained' >{'营业执照下载'}</Button>
                    }
                            <div style={{ margin: '20px 0', position: 'relative' }}>
                                {exercise.save !== '3' &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={this.state.loading}
                                    >
                                        {formStatic.save}
                                        {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
                                    </Button>
                                }
                            </div>
                        </form>
                    )}
            />
        );
    }
}

export default withStyles(useStyles)(Form1);