// date.js
export function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;
};
function padLeftZero(str) {
    return ('00' + str).substr(str.length);
};

    // var startTime=formatDate(date1, 'yyyy-MM-dd hh:mm')
export function getNowFormatDate() {
    let date = new Date();
    let seperator = "-";
    let year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString();
    let strdate = date.getDate().toString();
    if (month >= 1 && month <= 9){
        month = "0" + month;
    }
    if (strdate >= 0 && strdate <= 9){
        strdate = "0" + strdate;
    }
    let currentdate = year + seperator + month + seperator + strdate;
    return currentdate;
};
    