import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function ResponsiveDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [maxWidth] = React.useState('xs');
    const [fullWidth] = React.useState(true);

    const handleClose = (e, val) => {
        props.handleConfirm({ ...props.alertInfo, flag: val })
    };

    return (
        <div>
            <Dialog
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                open={props.alertInfo.open}
                onClose={e => { handleClose(e, false) }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{props.alertInfo.title || '提示'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.alertInfo.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={e => { handleClose(e, true) }} color="primary" >
                        确定
          </Button>
                    <Button onClick={e => { handleClose(e, false) }} color="primary" autoFocus >
                        取消
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}