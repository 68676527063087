
import getSubRouters from './helper';

export const subRootAry9 = [
    '风险预警解除查询',
    '风险预警认定查询',
    '未到期预警额度查询',
    '预警级别调整查询',
    '风险预警流程',

  ];


const PARENT_PATH = '/risk-management/保后管理';
  const PATH = '风险预警';
  export default getSubRouters(PARENT_PATH, PATH, subRootAry9);
