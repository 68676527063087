import React, { Component } from 'react';
import Select from 'react-select';
import {
    TextField,
    Button,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Select as Select2,
    InputLabel,
    MenuItem,
    CircularProgress,
    Box,
    LinearProgress,
    Typography
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { Upload } from '../../components/upload';
import { DEFAULT_EXPORT_URL } from '../../config/constants';
import { withStyles } from '@material-ui/core/styles';
import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Formik } from 'formik';
import { C116Formik } from './formikValidate';
import FormikError from './formikError';
import { fetching } from '../../config/utils';
import { formatDate } from '../../components/date'

// const yesornoList = ['是', '否'];
// const registerPlaceList = ['境内', '境外'];
// const enterpriseScaleList = ['大型', '中型', '小型', '微型'];
// const enterpriseTypeList = ['合资', '独资', '国有', '私营', '全民所有制', '集体所有制', '股份制', '有限责任公司']
// const countryPlaceList = ['中国大陆', '中国香港'];
// const relationshipList = ['其他']
// const customerTypesList = ['一般公司']
// const accountingSystemList = ['新会计准则', '旧会计准则']

const loadingStyle = {
    position: "absolute",
    color: green[500],
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -17,
}
const useStyles = (theme => ({
    formControl: {
        marginTop: '16px',
        marginBottom: '8px',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    width: '100%',
    label: {
        textAlign: 'left',
    },
    radio: {
        flexDirection: 'row',
    },
}));

const styles = {
    width: '100%',
    margin: '16px 0 8px 0'
}

class Form1 extends Component {
    state = {
        form: this.getInitState(),
        form2: this.props.form2 || {},//静态资源
        selectCustomerList: [],
        loading: false,
    }
    getInitState() {
        const { exercise = {} } = this.props;
        const form2 = {
            select_customer: '',
            enterprise_name: "",
            enterprise_no: "",
            organization_code: "",
            lglNm: "",
            lglIdCard: "",
            lglctfAuthorizationRgstDt: formatDate(new Date(), 'yyyy-MM-dd'),
            lglctfAuthorizationExpDt: formatDate(new Date(), 'yyyy-MM-dd'),
            authorization_file: false,
            uploads: {},
        }
        if (exercise.save === '1') {
            form2.select_customer = ''
        } else {
            form2.select_customer = { value: exercise.enterprise_name, label: exercise.enterprise_name, enterprise_no: exercise.enterprise_no }
        }
        return { ...form2, ...exercise }
    }
    async componentDidMount() {
        if (!this.state.form.enterprise_no) {
            this.queryEnterpriseInfo();
        }
    }

    queryEnterpriseInfo = () => {
        fetching('/msapi/organizations', {
            method: 'GET',
        }).then(res => {
            if (res) {
                try {
                    let datas = res.filter((item) => {
                        item.value = item.name;
                        item.label = item.name;
                        return (item.id !== 1) ? item : null;
                    })
                    this.setState({ selectCustomerList: datas });
                } catch (error) {
                }
            } else {
            }
        })
    }
    //选择客户
    handleChange2 = (name, selectedOption) => {
        let form = { ...this.state.form }
       
        let enterprise_no = selectedOption.enterprise_no;
        let organization_code = selectedOption.credit_no;
        let url = `/msapi/customer_info_add/legalPersonAuthorizationPeriod/getLgAuthorMsg?enterprise_no=${enterprise_no}&credit_no=${organization_code}`;
        fetching(url,{
            method:'GET',
        }).then(res => {
        if(res !== undefined && res.data){
               this.props.exercise.enterprise_no=true
               this.props.exercise.organization_code=true
               alert("同一客户不可重复录入多条信息,可选编辑!")
            }else{
            form.enterprise_no = selectedOption.enterprise_no
            form.organization_code = selectedOption.credit_no
            this.setState({ form: { ...form } })
            }
        })
        return selectedOption;
    }
    dateChange = (e) => {
        if (e !== null) {
            let open_day = formatDate(e, 'yyyy-MM-dd');
        return open_day;
      } else {
        alert("请选择日期");
      }
 }
    uploadChange = (file) => {

    };

    uploadFile(e){
            var fordata = new FormData();
            fordata.append('type',10);
            fordata.append('fileName',e.file);
            const options = {
              method: "POST",
              body: fordata
            }
            return fetch(`/msapi/upload/upload`,options)
          }
          uploadHandler = async (e,type) => {
		this.setState({progressLength : 0})
            this.setState({ progress: true })
            const timer = setInterval(() => {
            this.setState({progressLength : (this.state.progressLength >= 99 ? 99 : this.state.progressLength + 1) })
            }, 30);
            let indexId = '';
            indexId = await this.uploadFile(e).then(res => {
              if (res) {
                if (!res.status){
                  alert(res.msg)         
                }else{
                  return res.json()
                }
              }else{
                return null
              }
            }).then(r=>{
	if (r.error.code === 200) {//无错
                clearInterval(timer);
                this.setState({progressLength : 100})
                setTimeout(() => {
                  alert("文件上传成功");
                  this.setState({ progress: false })
                }, 500)
                return r.data
              } else {//业务失败
                alert(r.error.message)
                this.setState({ progress: false })
                return null;
              }
            }) 
            if(type === 1){
              this.state.form.authorization_file_id= indexId 
              this.state.form.authorization_file = true
            } 
        return true
    }

    conversion = (date) => {
        if (date instanceof Object && date.getTime()) {
            return formatDate(date, 'yyyy/MM/dd');
        }
        return date
    }
    //保存
    handleSave = (values) => {
        values.authorization_file_id = this.state.form.authorization_file_id
        this.setState({ loading: true })
        const { auth } = this.props;
        const { save, id } = this.state.form;
        let ids = (save === '2' || save === '3') ? id : '';
        let method = (save === '2' || save === '3') ? 'POST' : 'POST';
        values.enterprise_no = this.state.form.enterprise_no || ''
        values.enterprise_name = values.select_customer.value || ''
        values.organization_code = this.state.form.organization_code
        //values.lglNm = this.state.form.lglNm
        if(values.authorization_file_id !==null && values.authorization_file_id !==0){
            values.authorization_file = true;
        }else{
            values.authorization_file = false;
        }  
        

        values.auth = auth;//用户信息
        // POST
        fetching(`/msapi/customer_info_add/legalPersonAuthorizationPeriod/save/${ids}`, {
            method:"post",
            body: JSON.stringify(values),
        }).then((data) => {
            this.setState({ loading: false })
            try {
                if(data){
                    if(data.msg){
                        alert(ids ? `编辑失败，${data.msg}` : `新增失败，${data.msg}` )
                        return;
                    }
                    this.props.onSubmit({
                        ...this.state.form,
                    })
                }else {
                    alert(ids ? `编辑失败` : `新增失败` )
                }

            } catch (error) {}
        })
    };

    render() {
        const { exercise = {}, formStatic, classes } = this.props;
        const { form, selectCustomerList ,form2, progress, progressLength} = this.state;
        return (
            <Formik
                //初始化数据
                initialValues={{
                    ...form
                }}
                //表单验证
                validate={(values) => {
                    return C116Formik(values, formStatic);
                }}
                //验证提交
                onSubmit={(values) => {
                    this.handleSave(values);
                }}
                render={({
                    values,
                    errors,
                    touched,
                    uploads,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                        <form method="post" encType="multipart/form-data">
                            <Grid item>
                                <div style={{ margin: '5px 0' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_customer}</span>
                                    <Select
                                        isSearchable
                                        value={values.select_customer}
                                        onChange={e => setFieldValue('select_customer', this.handleChange2.bind(this, 'select_customer', e)())}
                                        options={selectCustomerList}
                                        isDisabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'select_customer'} />
                                </div>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.enterprise_no}
                                        value={form.enterprise_no}
                                        name='enterprise_no'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.organization_code}
                                        value={form.organization_code}
                                        name='organization_code'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    {/* <FormikError touched={touched} errors={errors} name={'organization_code'} /> */}
                                </Grid>
                            </Grid>
                              <Grid container justify="space-between">
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                                    <Grid item xs={4}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label={formStatic.lglctfAuthorizationRgstDt}
                                            disabled={exercise.save === '3'}
                                            value={values.lglctfAuthorizationRgstDt}
                                            //onChange={e => setFieldValue('lglctfAuthorizationRgstDt', e)}
                                            onChange={e => setFieldValue('lglctfAuthorizationRgstDt', this.dateChange.bind(this, e)())}
                                            onBlur={handleBlur}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        <FormikError touched={touched} errors={errors} name={'issue_date'} />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                                    <Grid item xs={4}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label={formStatic.lglctfAuthorizationExpDt}
                                            disabled={exercise.save === '3'}
                                            value={values.lglctfAuthorizationExpDt}
                                            //onChange={e => setFieldValue('lglctfExpDt', e)}
                                            onChange={e => setFieldValue('lglctfAuthorizationExpDt', this.dateChange.bind(this, e)())}
                                            onBlur={handleBlur}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        <FormikError touched={touched} errors={errors} name={'issue_date'} />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                                <Grid item xs={3}>
                                    <TextField
                                        label={formStatic.lglNm}
                                        value={values.lglNm}
                                        name='lglNm'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'lglNm'} />
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                    <TextField
                                        label={formStatic.lglIdCard}
                                        value={values.lglIdCard}
                                        name='lglIdCard'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'lglIdCard'} />
                                </Grid>
				{/* 上传进度条 */}
                                { progress &&
                                    <div>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgress variant="determinate"  value={progressLength}/>
                                    </Box>
                                    <Box sx={{ minWidth: 35 }}>
                                        <Typography variant="body2" color="text.secondary">{`${Math.round(
                                        progressLength
                                        )}%`}</Typography>
                                    </Box>
                                    </Box>
                                    </div>
                                    }
                              <Grid container justify="space-between">
                                <Grid item xs={12}>
                                    <Upload 
                                    uploadName="authorization_file"
                                    disabled={exercise.save === '3'}
                                    mold="10"
                                    uploads={uploads}
                                    name={formStatic.authorization_file} 
                                    view={form2.view_case}
                                    exercise={exercise}
                                    inputProps={{ maxLength: 50 }}
                                    fullWidth
                                    uploadChange={e=>setFieldValue('authorization_file',this.uploadHandler( e, 1))}> 
                                    </Upload>
                                    <FormikError touched={touched} errors={errors} name={'authorization_file'} />
                                </Grid>
                              </Grid>
                            <div style={{ margin: '20px 0', position: 'relative' }}>
                                {exercise.save !== '3' &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={this.state.loading}
                                    >
                                        {formStatic.save}
                                        {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
                                    </Button>
                                }
                            </div>
                        </form>
                    )}
            />
        );
    }
}

export default withStyles(useStyles)(Form1);
