import React, { Component } from 'react';

import {

  Typography,
  ExpansionPanelDetails,
  List,
  Grid,
  TextField,
  Table,
 
} from '@material-ui/core'

import {
  AlternateEmail,
  Edit  
} from '@material-ui/icons'


import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { fetching } from '../../config/utils';
import { form_signup3 } from '../../../src/signup/formStatic'//'../formStatic'src/signup/formStatic/index
import { DEFAULT_ACCOUNT_URL } from '../../config/constants' //支付注册路径
import { loginInfos } from '../../../src/components/numToText'; //当前登录用户
const useStyles = (theme => ({
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    marginRight: '0',
    display: 'flex',
    alignItems: 'flex-end',
  },
  field: {
    width: '100%',
  },
  errorMsg: {
    color: '#f00',
    textAlign: 'center',
    height: '20px',
    cursor: 'pointer',
  },
  left: {
    textAlign: 'left',
  }
}));
const styles = {
  textAlign: 'left',
  margin: '0 10px',
  // lineHeight: '30px'
}

class dataAnalysisForm extends Component {
  state = {
    hasToken: sessionStorage.getItem("authToken"),
    form1: {
      credit_no: '',
    },
    active: '',//记录当前是否注册状态
    enterpriseCertificationStatus: '',//前往注册和帮卡
    personCertificationStatus: '',//已经注册了绑卡或者没有分配市场
    band:'',//记录市场绑定状态
    banding: '',//市场绑定
    loginInfo:[],//当前登录信息
    listCers: [form_signup3.certification_type1],
    open: false,
    imgUrl: "",
    open_id: localStorage.getItem("registerForm1") ? JSON.parse(localStorage.getItem("registerForm1")).cell : '',
    seal_person_url: '',//法人章
    message: '',
    value: '',
    data: [], 
    auth: {},
    columns : [
      {
        title: '公司名称',
        field: 'accountName',
    
      },
      {
        title: '卡状态',
        field: 'state'
      },
      {
        title: '市场编号',
        field: 'marketCode'
      },

      {
        title: '备注',
      },
    ],
   
    bankCard: {
      branchBankCode: "",
      bankCardNo: "",
      bankDetailName: "",
      bankName: "",
    },
  }

  


  //如果有异步设置state值的情况，在组件销毁时清除所有的state状态
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }

  viewAgreement = () => {
    this.setState({ open: false });
  }
  



  //初始化看该企业是否已经在清算平台注册过
  async componentDidMount() {
     this.getPhoneByAccount();
     //this.getRegisterBank();
  }

 //查看当前用户是否已经进行了注册与绑卡
  getPhoneByAccount = () => {
    
    let token =  this.state.hasToken 
    let sid =   sessionStorage.getItem("sid")
    let url = `https://www.erongdan.com/dist/#/oauth?token=${token}&sid=${sid}`;
   
   
  
    window.open(url)
}
    


 




 //表格编辑
onUpdate2 = (e, row) => {
   //对银行卡进行解绑 
   if(row.active === 1){
    alert('该银行卡已解绑');
    return;
   }

  fetching(`/msapi/account/unCard?id=`+row.id, {
      method: 'get',
     }).then(res => {
      if (res !== undefined && res.data) {
        
      } else {
        alert(res.msg)
      }
    });
 }

 onRef = (ref) => {
  this.child = ref
}


//搜索框Change
onSearchChange = (query) => {
  console.log('onSearchChange', query);
}
//页面更改事件
onChangePage = (query) => {
  console.log('onChangePage', query);
}

//处理每页的行更改（pageSize）
onChangeRowsPerPage = (query) => {
  console.log('onChangeRowsPerPage', query);
}

  render() {
    //const { activeStep, classes } = this.props;
    const { active ,personCertificationStatus,banding,loginInfo,band,columns,data,auth} = this.state;
    const editable = editable2(this, data);
    return (
       <form >
       {/*<Typography variant="body1" gutterBottom>
         <h2>支付账号信息注册与绑卡</h2>
    </Typography>*/}
          {/*<div align="left" >
          <ExpansionPanelDetails >
              <List style={{ width: '50%' }}>
                <Typography style={{ whiteSpace: 'normal' }} >
                  {`公司：${loginInfo.name}`}
                </Typography>
                <Typography  style={{ whiteSpace: 'normal' }}>
                  {`账号：${loginInfo.account}`}
                </Typography>
                <Typography  style={{ whiteSpace: 'normal' }}>
                  {`电话：${loginInfo.phone}`}
                </Typography>
                <Typography  style={{ whiteSpace: 'normal' }}>
                  {'开户状态:'}
                  {(active === '0'  ) && 
                   <span style={{ paddingLeft: '10px', color: '#f00', cursor: 'pointer', textDecoration: 'underline' }}  onClick={this.handleCertification}>{'请进行支付账号信息注册与绑卡点击前往>>'}</span>
                  }
                  {(active === '1' ) &&
                     <span style={{ paddingLeft: '10px', color: '#090', cursor: 'pointer', textDecoration: 'underline' }} >{personCertificationStatus}</span>
                  }
                  {(active === '3' ) &&
                     <span style={{ paddingLeft: '10px', color: '#090', cursor: 'pointer', textDecoration: 'underline' }} onClick={this.handleCertification}>{'请重新进行支付平台注册>>'}</span>
                  } 
                   
                </Typography>
                <Typography  style={{ whiteSpace: 'normal' }}>
                  {'市场绑定状态:'}
                  {(active ===  '1' && band === "" ) &&
                     <span style={{ paddingLeft: '10px', color: '#f00', cursor: 'pointer', textDecoration: 'underline' }}  onClick={this.bindingMarket}>{'请点击进行绑定市场>>'}</span>
                  }
                  {(active ===  '0' ) &&
                     <span style={{ paddingLeft: '10px', color: '#f00', cursor: 'pointer', textDecoration: 'underline' }}  >{'未绑定>>'}</span>
                  } 
                  {(band ===  '1' ) &&
                     <span style={{ paddingLeft: '10px', color: '#090', cursor: 'pointer', textDecoration: 'underline' }}>  {banding}</span>
                  }
                  </Typography>
              </List>
            </ExpansionPanelDetails>
          </div>   
          {/*<div  style={{ width: '100%' }} >
             <MaterialTable
                 title='绑卡信息'
                 onSearchChange={this.onSearchChange}
                 onChangePage={this.onChangePage}
                 onChangeRowsPerPage={this.onChangeRowsPerPage}  
                 columns={columns}
                 data={data}
                 actions={actions2(this, auth)}
                 editable={editable}
                 localization={localization}
                 options={options}
            >
            </MaterialTable>  
          </div>*/}
      </form>
    );
    
  }
 
}
const editable2 = (that, name) => {
  let editable = {};
  editable = { onRowAdd: that.onAdd, };
  return editable;
}

/*const actions2 = (that, auth) => {
  let actions = [];
  actions = [{ icon: Edit, tooltip: '解绑银行卡', onClick: that.onUpdate2 }];
   return actions;
}*/


const localization = {
  header: {
    actions: '操作'
  }
}

const options = {
  actionsCellStyle: { padding: '0 10px' },
  headerStyle: { //表格头部
    backgroundColor: '#1d4583', //'#039be5',
    color: '#FFF',
    whiteSpace: 'nowrap'
  },
  rowStyle: { //表行
    backgroundColor: '#fff',
    whiteSpace: 'nowrap'
  },
  sorting: true,  //排序
  exportButton: true,
}

export default withStyles(useStyles)(dataAnalysisForm);
