import {
  PlaylistAddCheck,
  ListAlt,
  CallToAction
} from '@material-ui/icons';
import {subRouters, getMenu, getContent} from './common';
import{SetView} from './commonTable'

const PARENT_PATH = subRouters['流程管理'];

const Info = [
  {
    path: "待办任务列表",
    icon: ListAlt,
    component: SetView('r10-1')
  },
  {
    path: "本人完成任务",
    icon: PlaylistAddCheck,
    component: SetView('r10-2')
  }, {
    path: "全部结束任务",
    icon: CallToAction,
    component: SetView('r10-3')
  },
];

export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];