//抵押表单
export default {
    mortgage_type:"抵押物类型",
    real_estate_mortgage: "不动产抵押",
    chattel_mortgage: "动产抵押",
    mortgage_name: "抵押人姓名",
    mortgage_value: "抵押物价值",
    mortgage_position: "抵押物位置",
    mortgage_ratio: "抵押物比例",
    mortgage_desc:"质押物描述",
    guarantee_amount:"保证金",
    guarantee_period:"担保期限",
    user_id:'',//用户id,我传了auth给你
    cerdit1_id:'',//授信1 id
    unified_social_credit_code: "统一社会信用代码",
    warrant_no:'权证号'
}