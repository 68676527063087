import React from 'react';
import { Switch, Route, Link,Redirect } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Message,
  MailOutline,
  Person,
  PermIdentity,
} from '@material-ui/icons';
import Accordion from '../components/Accordion'
import { SetView } from './BasicInfo/commonTable'
import C11, { subRootAry } from './containers/C1_1'
import { subRouters } from './common';

const PARENT_PATH = subRouters['客户基本信息'];

const Info = [
  {
    path: "基础信息",
    icon: Message,
    component: C11, // SetView('c1-1-1'),
  },
  {
    path: "管理层信息",
    icon: MailOutline,
    component: SetView('c1-2'),
  }, {
    path: "自然人实际控制人",
    icon: Person,
    component: SetView('c1-3'),
  },
  {
    path: "非自然人实际控制人",
    icon: PermIdentity,
    component: SetView('c1-4'),
  },
  {
    path: "客户授权期限管理",
    icon: PermIdentity,
    component: SetView('c1-5'),
  },
]

const view = {};
subRootAry.forEach((item, index) => {
  switch (item) {
    case '客户概况':
      view[item] = SetView('c1-1-1');
      break;
    case '营业执照':
      view[item] = SetView('c1-1-2');
      break;
    case '验资信息':
      view[item] = SetView('c1-1-3');
      break;
    case '联系方式':
      view[item] = SetView('c1-1-4');
      break;
    case '公司章程':
      view[item] = SetView('c1-1-5');
      break;
    case '客户信息补录':
      view[item] = SetView('c1-1-6');
      break;
    case '内外部客户':
      view[item] = SetView('c1-1-7');
      break;
    case '企业管理员账号':
      view[item] = SetView('c1-1-8');
      break;
    default:
      throw new Error('Unknown step');
  }
})

// export default [
//   getMenu(PARENT_PATH, Info),
//   getContent(PARENT_PATH, Info,subRootAry)
// ]







export const Menu1 = () => {
  const list = Info.map((item, index) => {
    const CompIcon = item.icon;
    switch (index) {
      case 0:
        return <C11 key="C11" />;
      default:
        return (
          <ListItem button component={Link} to={`${PARENT_PATH}/${item.path}`} key={item.path}>
            <ListItemIcon>
              <CompIcon />
            </ListItemIcon>
            <ListItemText primary={item.path} />
          </ListItem>
        );
    }
  })
  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
}

export const Content1 = () => {
  return (
    <Switch>
        <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${subRootAry[0]}`}
      />
      {Info.map(item => {
        if (item.component) {
          return <Route
            path={`${PARENT_PATH}/${item.path}`}
            component={item.component}
            key={item.path}
          />
        } else {
          return (
            <Route
              path={`${PARENT_PATH}/${item.path}`}
              render={() => <h2>{item.path}</h2>}
              key={item.path}
            />
          )
        }
      })}

      {subRootAry.map(item => {
        if (view[item]) {
          return (
            <Route
              path={`${PARENT_PATH}/${item}`}
              component={view[item]}
              key={item}
            />
          )
        } else {
          return (
            <Route
              path={`${PARENT_PATH}/${item}`}
              render={() => <h2>{item}</h2>}
              key={item}
            />
          )
        }

      })}
    </Switch>
  )
}
