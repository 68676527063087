// 授信模块用到的审核表单——dxm
import React, { Component } from 'react'
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { fetching } from '../../config/utils';
import { check } from '../../components/commonForm/check'
import {
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import FormikError from '../../components/commonForm/formikError';
import SimpleExpansionPanel from '../../components/commonForm/Panels'
import { checkFormik } from '../../components/commonForm/formikValidate';

const useStyles = (theme => ({
  radio: {
    flexDirection: 'row',
  },
}));
const styles = {
  width: '100%',
  margin: '16px 0 8px 0'
}
const List = [{ id: '3', text: '同意' }, { id: '2', text: '退回' },]
class CheckForm extends Component {
  state = {
    form: this.getInitState(),
    form2: { ...check },
    userList: [
      { id: 1, name: '退回上一级' },
      { id: 2, name: '退回经办人' },
    ],
    auth: this.props.auth || {},
  }

  getInitState() {
    const { exercise, } = this.props
    const form = {
      opinion_list: [],
      ...exercise,
      check_opinion: '',
      form_no: '3',
      returnBy: '退回上一级',
    }
    return form
  }

  // 提交
  handleSubmit = (form2, name) => {
    const { url } = this.props.dataUrl
    const { auth } = this.state;
    const form = form2 || this.state.form;
    const text = name || '取消';
    //参数
    const body = {
      auth,//用户信息
      check_opinion: form.check_opinion,//审核意见
      audit_status: form.status,  //传状态id name form.status
      form_no: form.form_no,//2:退回，3：通过，5：签收，6：代签收
      returnBy: form.returnBy
    }
    if (form.table === 'r4-1-2' || form.table === 'r4-1-4') {//r4-1-2：授信申请审核；r4-1-4：已退回授信申请
      body.credit1_id = form.id
    } else {
      body.credit2_id = form.id
    }
    //提交表单的url
    let url1 = url.get3
    if (text !== '取消') {
      fetching(url1, {
        method: 'POST',
        body: JSON.stringify(body)
      }).then(res => {
        try {
          if (res) {
            if (res.msg) {
              alert(`审核失败,${res.msg}`)
              return;
            }
            this.props.onSubmit({
              ...res,
              submit_name: text
            })
          }
        } catch (error) {
          console.log('error')
        }


      })

    } else {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
    }
  }
  render() {
    const { form2, form, userList } = this.state;
    const { exercise, classes } = this.props;
    const check_opinion_label = (form_no) => {
      if (form_no === '3') return form2.check_opinion
      else if (form_no === '2') return form2.return_reason
      else return form2.remarks
    }
    const status = (handleSubmit, isSubmitting, values) => {
      return <Grid container justify="center" style={{ padding: '20px 0' }}>
        <Button
          color="primary"
          variant='contained'
          onClick={handleSubmit}
          disabled={isSubmitting}
          name={form2.submit}
          style={{ marginRight: '10px' }}
        >
          {form2.submit}
        </Button>
        <Button
          color="primary"
          variant='contained'
          onClick={this.handleSubmit.bind(this, values, form2.cancel)}
          name={form2.cancel}
          disabled={isSubmitting}
          style={{ marginRight: '10px' }}
        >
          {form2.cancel}
        </Button>
      </Grid>
    }
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        validate={(values) => {
          return checkFormik(values, form2);
        }}
        //验证提交
        onSubmit={(values) => {
          this.handleSubmit(values, form2.check);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <form method="post" encType="multipart/form-data">
              <SimpleExpansionPanel title="历史审核意见">
                <Typography variant="inherit" style={{ textAlign: 'left' }} gutterBottom>经办人：{form.organization.name}-{form.user.name}</Typography>
                {
                  form.opinion_list.map((item, index) => {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 0', textAlign: 'left', }} key={index}>
                        <Typography variant="inherit" gutterBottom>
                          {item.name}
                        </Typography>
                        <Typography variant="inherit" gutterBottom>
                          {item.check_opinion}
                        </Typography>
                      </div>
                    )
                  })
                }
              </SimpleExpansionPanel>
              {exercise.save !== '3' &&
                <div>
                  <Grid item xs={12} >
                    <FormControl component="fieldset" style={styles} className={classes.radio}>
                      {/* <FormLabel component="legend" style={{ fontSize: '12px' }}></FormLabel> */}
                      <RadioGroup className={classes.radio} style={{ flexDirection: 'row' }} aria-label="gender" name='entrusted_payment' value={values.form_no}
                        onChange={e => setFieldValue('form_no', e.target.value)}>
                        {List.map(item => {
                          return (
                            <FormControlLabel
                              key={item.id}
                              name={item.id}
                              value={item.id}
                              disabled={exercise.save === '3'}
                              control={<Radio />}
                              label={item.text} />
                          )
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      rows='4'
                      label={check_opinion_label(values.form_no)}
                      value={values.check_opinion}
                      name='check_opinion'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      margin='normal'
                      fullWidth
                      disabled={false}
                    />
                    <FormikError touched={touched} errors={errors} name={'check_opinion'} />
                  </Grid>
                  {
                    values.form_no === '2' &&
                    <FormControl style={{ width: '100%', margin: '10px 0' }}>
                      <InputLabel id="demo-simple-select-label">{form2.returnBy}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="returnBy"
                        value={values.returnBy}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {
                          userList.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  }
                  {
                    status(handleSubmit, isSubmitting, values)
                  }
                </div>
              }
            </form>
          )}
      />

    )
  }
}

export default withStyles(useStyles)(CheckForm)
