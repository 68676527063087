import React, { Component } from 'react';
import Select from 'react-select';
import {
  Select as Select2,
  TextField,
  Button,
  Grid,
  Typography,
  LinearProgress,
  Box,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import { formatDate } from '../../components/date'
import { commafy } from '../../components/numToText';
import { Upload1 } from '../../components/upload';
import FormikError from '../../02-core_business/containers2/formikError';
import { Alert } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';



const useStyles = (theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  width: '100%',
  label: {
    textAlign: 'left',
  },
  radio: {
    flexDirection: 'row',
  },
}));

const styles = {
  width: '100%',
  margin: '16px 0 8px 0'
}

class Form extends Component {
  state = {
    form: this.getInitState(),
    form2: this.props.form2 || {},//静态资源
    selectCustomerList: [],
    loading: false,
    measure_date: null,
    remark: null,
    regulatoryId: null,
    currentPeriodId: null,
    singlePenId: null,
  }
  getInitState () {
    //debugger
    const { exercise = {} } = this.props;
    const form = {
      measure_date: null,
      remark: null,
    }
    const form2 = {
      measure_date: "请输入名称",
      remark: "请输入备注",
    }
    return { ...form2, ...exercise, ...form }
  }
  handleChange = ({ target: { name, value } }) => {
    //debugger
    let { form } = this.state;
    let value2 = value
    this.setState({
      form: { ...form, [name]: value2, }
    })
    return value2;
  }

  uploadChange = (file) => {

  };

  //输入框失去焦点
  handleBlur = (value) => {

    return commafy(value);
  }

  //输入框空格
  handleBlur4 = (value) => {
    return value.replace(/\s+/g, "");
  }

  conversion = (date) => {
    if (date instanceof Object && date.getTime()) {
      return formatDate(date, 'yyyy/MM/dd');
    }
    return date
  }
  //文件上传
  uploadHandler = async (e, type) => {
    //debugger
    this.setState({ progressLength: 0 })
    this.setState({ progress: true })
    const timer = setInterval(() => {
      this.setState({ progressLength: (this.state.progressLength >= 99 ? 99 : this.state.progressLength + 1) })
    }, 30);

    let indexId = '';
    indexId = await this.uploadFile(e)
      .then(res => {
        if (res) {
          if (!res.status) {
            alert(res.msg)
          } else {
            global.count = global.count - 1
            return res.json()
          }
        } else {
          return null
        }
      }).then(r => {
        //debugger
        if (r.msg !== null) {
          clearInterval(timer);
          setTimeout(() => {
            alert(r.msg)
            this.setState({ progress: false })
          }, 500)
          return null;
        } else {
          clearInterval(timer);
          this.setState({ progressLength: 100 })
          setTimeout(() => {
            alert("文件上传成功");
            this.setState({ progress: false })
          }, 500)
          //debugger
          return r.data
        }
      })

    if (type === 1) {
      //debugger
      this.state.form.regulatoryId = indexId
      this.state.form.Upload_contracts = true
    } else if (type === 2) {
      this.state.form.currentPeriodId = indexId
      this.state.form.Upload_invoice = true
    } else if (type === 3) {
      this.state.form.singlePenId = indexId
      this.state.form.Upload_contracts_invoice = true
    }

    return true
  }

  uploadFile (e) {
    //debugger
    var fordata = new FormData();
    fordata.append('type', 12);
    fordata.append('fileName', e.file);
    const options = {
      method: "POST",
      body: fordata
    }
    global.count = (global.count + 1)
    return fetch(`/msapi/upload/upload`, options)

  };

  //保存
  handleSave = (values) => {
    values.measure_date = values.measure_date.replace(/\s+/g,"");; //去除空格
    values.remark = values.remark.replace(/\s+/g,"");; //去除空格
    const body2 = {
      measureDate: values.measure_date,//公司名称
      remark: values.remark,//业务名称
      uploadTime: values.upload_time,//上传时间
      regulatoryId: this.state.form.regulatoryId,//监管指标统计表id
      currentPeriodId: this.state.form.currentPeriodId,//当期资产负债表id
      singlePenId: this.state.form.singlePenId,//单笔业务债务人表id
    }
    if (body2.measureDate != null && body2.measureDate != '' && body2.remark != null && body2.remark != '' &&
      body2.regulatoryId != null && body2.currentPeriodId != null &&
      body2.singlePenId != null) {
      this.setState({ loading: true, loadingDsb: true, })
      fetching(`/msapi/risk/saveMonthlyRiskGovernance`, {
        method: "post",
        body: JSON.stringify(body2),
      }).then((res) => {
        //debugger
        if (res.data) {
          if (res.msg) {
            alert(`保存失败，${res.msg}`)
            return;
          }
          // this.props.onSubmit({
          //   ...this.state.form,
          // })
          window.location.reload()
        } else {
          alert(`保存失败`)
          window.location.reload()
        }
      })
    } else {
      this.setState({ showAlert: true })

    }
  }
  render () {
    const { uploads } = this.state;
    const { form2, progress, progressLength, showAlert } = this.state;
    const { form } = this.state;
    const { exercise = {}, classes } = this.props;
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        validate={(values) => {
          values = values
        }}
        //验证提交
        //验证提交
        onSubmit={(values) => {
          this.handleSave(values);
        }}
        render={({
          errors,
          touched,
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form method="post" encType="multipart/form-data" className={classes.root} noValidate autoComplete="off">

            {/* 上传进度条 */}
            {/* {progress && <LinearDeterminate/>} */}

            {progress &&
              <div>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate" value={progressLength} />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                      progressLength
                    )}%`}</Typography>
                  </Box>
                </Box>
              </div>
            }
            {showAlert && <Alert action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  this.setState({ showAlert: false })
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            } color="warning">
              <strong>警告:</strong> 您有未填写内容或未上传的文件，请检查.
            </Alert>
            }


            <TextField onChange={handleChange} value={values.measure_date} name='measure_date' required label="名称" />
            <TextField onChange={handleChange} value={values.remark} name='remark' required label="备注" />

            <Grid container justify="space-between">
              <Grid item xs={12}>
                <Upload1 disabled={exercise.status2 === '2' || exercise.save === '3'}
                  uploadName="Upload_contracts"
                  mold="7"
                  uploads={uploads}
                  name={'上传监管指标统计表模板'} view={form2.view_case}
                  exercise={exercise}
                  uploadChange={e => setFieldValue('上传监管指标统计表模板', this.uploadHandler(e, 1))}>
                </Upload1>
                <FormikError touched={touched} errors={errors} name={'上传监管指标统计表模板'} />
              </Grid>

            </Grid>
            <Grid item xs={12}>
              <Upload1 disabled={exercise.status2 === '2' || exercise.save === '3'}
                uploadName="Upload_invoice"
                mold="7"
                uploads={uploads}
                exercise={exercise}
                name={'上传当期资产负债表'} view={form2.view_case}
                uploadChange={e => setFieldValue('上传当期资产负债表', this.uploadHandler(e, 2))}>
              </Upload1>
              <FormikError touched={touched} errors={errors} name={'上传当期资产负债表'} />
            </Grid>

            <Grid container justify="space-between">
              <Grid item xs={12}>
                <Upload1 disabled={exercise.status2 === '2' || exercise.save === '3'}
                  uploadName="Upload_contracts_invoice"
                  mold="7"
                  uploads={uploads}
                  exercise={exercise}
                  name={'上传单笔业务债务人统计'} view={form2.view_case}
                  uploadChange={e => setFieldValue('上传单笔业务债务人统计', this.uploadHandler(e, 3))}>
                </Upload1>
                <FormikError touched={touched} errors={errors} name={'上传单笔业务债务人统计'} />
              </Grid>

            </Grid>
            <div style={{ float: 'right', marginBottom: '10px', position: 'relative' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={this.state.loading}
              >
                {form2.save}
              </Button>
            </div>
          </ form>
        )}
      />
    );
  }
}

export default withStyles(useStyles)(Form);