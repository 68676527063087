import Form1 from './form1';
import Form2 from './form2';
import Form3 from './form3';
import Form4 from './form4';
import Form5 from './form5';

export {
    Form1,
    Form2,
    Form3,
    Form4,
    Form5,
}