import { delcommafy } from '../../../components/numToText';
const { string } = require('yup');
// const decimals = /^([1-9]|[1-9]\d|100)(\.\d{1,2})?$/ //数字1-100 保留两位小数
const id = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/
const numbers = /^\+?[1-9]\d*$/; //大于0的正整数
const numbers1 = /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/ //大于0的数字包含小数
//const creditNo = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;; //社会信用代码
const creditNo = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/ //社会信用代码

//授信发起
export const form1Formik = (values, form2, guarantyList) => {

  let errors = {};
  if (guarantyList instanceof Array) {
    let guarantyWay1 = []
    guarantyList.forEach((item) => {
      if (item.checked) {
        guarantyWay1.push(item.name)
      }
    })
    values.guaranty_way = guarantyWay1.join(',') || ''
  }
  if (!values.select_customer) {
    errors.select_customer = form2.select_customer;
  }
  if (!values.mx_amount) {
    errors.mx_amount = form2.mx_amount;

  } else if (!numbers1.test(delcommafy(values.mx_amount))) {
    errors.mx_amount = '请输入10位以内并大于0的数字';
  }
  if (!values.guaranty_way) {
    errors.guaranty_way = form2.guaranty_way;
  }
  return errors;
}

export const form2Formik = (values, form2) => {
  let errors = {};
  if (values.is_allow_equivalent_deposit === '是') {
    if (!values.equivalent_info) {
      errors.equivalent_info = form2.equivalent_info;
    }
  }
  return errors;
}
export const form3Formik = (form2 = {}) => {
  // return {
  //    credit_approval:
  //      string()
  //        .trim().required(form2.credit_approval),
  // }
}
export const form5Formik = (form2 = {}) => {
  return {
    file_obj:
      string()
        .trim().required(form2.file_obj),
    file1_obj:
      string()
        .trim().required(form2.file1_obj),
  }
}

export const guarantorFormFormik = (values, form2) => {
  let errors = {};
  if (values.guarantor_type === '单位') {
    if (!values.guarantor_enterprise) {
      errors.guarantor_enterprise = form2.guarantor_enterprise;
    }
  } else {
    if (!values.guarantor_name) {
      errors.guarantor_name = form2.guarantor_name;
    }
    if (!values.id_card) {
      errors.id_card = form2.id_card;
    } else if (!id.test(values.id_card)) {
      errors.id_card = "身份证号码格式不正确";
    }
  }
  if (!values.guarantee_amount) {
    errors.guarantee_amount = form2.guarantee_amount;
  } else if (!numbers1.test(delcommafy(values.guarantee_amount))) {
    errors.guarantee_amount = '请输入10位以内大于0的数字';
  }
  if (!values.guarantee_period) {
    errors.guarantee_period = form2.guarantee_period;
  } else if (!numbers.test(values.guarantee_period)) {
    errors.guarantee_period = '请输入正整数';
  }
  return errors;
}

export const mortgageFormFormik = (values, form2) => {
  let errors = {};
  if (!values.mortgage_name) {
    errors.mortgage_name = form2.mortgage_name;
  }
  if (!values.unified_social_credit_code) {
    errors.unified_social_credit_code = form2.unified_social_credit_code;
  }else if (!creditNo.test(values.unified_social_credit_code)) {
    errors.unified_social_credit_code = '社会信用代码不合法';
  }
  if (!values.warrant_no) {
    errors.warrant_no = form2.warrant_no;
  }
  if (!values.mortgage_value) {
    errors.mortgage_value = form2.mortgage_value;
  } else if (!numbers1.test(delcommafy(values.mortgage_value))) {
    errors.mortgage_value = '请输入10位以内的正整数';
  }
  if (!values.mortgage_position) {
    errors.mortgage_position = form2.mortgage_position;
  }
  if (!values.guarantee_amount) {
    errors.guarantee_amount = form2.guarantee_amount;
  }
  if (!values.guarantee_amount) {
    errors.guarantee_amount = form2.guarantee_amount;
  } else if (!numbers1.test(delcommafy(values.guarantee_amount))) {
    errors.guarantee_amount = '请输入10位以内大于0的数字';
  }
  if (!values.guarantee_period) {
    errors.guarantee_period = form2.guarantee_period;
  } else if (!numbers.test(values.guarantee_period)) {
    errors.guarantee_period = '请输入正整数';
  }
  return errors;
}

export const pledgeFormFormik = (values, form2) => {
  let errors = {};
  if (!values.pledge_name) {
    errors.pledge_name = form2.pledge_name;
  }
  if (!values.unified_social_credit_code) {
    errors.unified_social_credit_code = form2.unified_social_credit_code;
  }else if (!creditNo.test(values.unified_social_credit_code)) {
    errors.unified_social_credit_code = '社会信用代码不合法';
  }
  if (!values.warrant_no) {
    errors.warrant_no = form2.warrant_no;
  }
  if (!values.pledge_value) {
    errors.pledge_value = form2.pledge_value;
  } else if (!numbers1.test(delcommafy(values.pledge_value))) {
    errors.pledge_value = '请输入10位以内的正整数';
  }
  if (!values.pledge_position) {
    errors.pledge_position = form2.pledge_position;
  }
  if (!values.guarantee_amount) {
    errors.guarantee_amount = form2.guarantee_amount;
  } else if (!numbers1.test(delcommafy(values.guarantee_amount))) {
    errors.guarantee_amount = '请输入10位以内大于0的数字';
  }
  if (!values.guarantee_period) {
    errors.guarantee_period = form2.guarantee_period;
  } else if (!numbers.test(values.guarantee_period)) {
    errors.guarantee_period = '请输入正整数';
  }
  return errors;
}






