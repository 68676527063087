// 授信模块用到的审核表单——dxm
import React, { Component } from 'react'
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { fetching } from '../../config/utils';
import { check } from '../../components/commonForm/check'
import {
  TextField,
  Button,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import { checkFormikDelay ,checkFormikDelayFile } from '../../components/commonForm/formikValidate';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import cnLocale from "date-fns/locale/zh-CN";
import { formatDate } from '../../components/date';
import DateFnsUtils from '@date-io/date-fns';
import SimpleExpansionPanel from '../../components/commonForm/Panels'
import Typography from '@material-ui/core/Typography';
import FormikError from '../../components/commonForm/formikError';
import { values } from 'lodash';
import { Tune } from '@material-ui/icons';
import { Upload } from '../../components/upload';

const useStyles = (theme => ({
  radio: {
    flexDirection: 'row',
  },
}));
const styles = {
  width: '100%',
  margin: '16px 0 8px 0'
}
const List = [{ id: '3', text: '同意' }, { id: '2', text: '退回' },]
class CheckForm extends Component {
  state = {
    form: this.getInitState(),
    formStatic: this.props.formStatic || {},//静态资源
    form2: { ...check },
    exercise : {
      applyOpen:false,
    },
    userList: [
      { id: 1, name: '退回上一级' },
      { id: 2, name: '退回经办人' },
    ],
    auth: this.props.auth || {},
  }

  getInitState() {
    const open_day = new Date();
    const { exercise, auth } = this.props
    const form = {
      opinion_list: exercise.delayAmountsFlowsList,
      date: this.props.exercise,
      delayDate: open_day,
      creditDueDate:  this.props.exercise.creditDueDate, 
      ...exercise,
      check_opinion: '',
      form_no: '3',
      organization : auth.organization,
      role:auth.role,
      user:auth.user,
      returnBy: '退回上一级',
      delayFile:"",
      delayFileId:0
    }
    return form
  }

  handleChange3 = (name, value) => {
    if (name === 'delayDate') {
      if (!(value instanceof Object && value.getTime())) {//判断日期是否为有效值
        return;
      }
      if (name === 'delayDate') {
      this.state.form.delayDate = value;
      }
      return value;
    }
  }

   //额度来源检测20210520
   historySource = (value) => {
   
    };

  // 提交
  handleSubmit = (form2, name) => {
    const { auth } = this.state;
    const form = form2 || this.state.form;
    form2.delayFileId = this.state.form.delayFileId;
    const text = name || '取消';
    let type = 0;  //0 延时申请 1 延时审核
    //参数
    if (form2.delayDate instanceof Object) {
    form.delayDate = formatDate(form2.delayDate, 'yyyy-MM-dd');
    }
    const body = {
      auth,//用户信息
      check_opinion: form.check_opinion,//审核意见
      audit_status: form.status,  //传状态id name form.status
      form_no: form.form_no,//2:退回，3：通过，
      returnBy: form.returnBy,
      delayDate: form.delayDate,
      statusId: form.delayStatusId,
      delayFileId: form.delayFileId

    }
    if(form.table === 'r4-5-1' || form.table === 'r4-5-2' || form.table === 'r4-5-3' ){
      body.type=1
    }else{
      body.type=type
    }
    body.ttid = form.date.id;
    
    if (text !== '取消') {
      //是否可延时校验
      fetching(`/smrd/distribution_recycling/find?organizationId=${form.organizationId}`).then(data => {
        if(data === 0){
          alert('有待审核的额度分配及回收业务时，不可发起额度延迟申请!')
          return;
        }else{
          fetching(`/amount/usedAmount/historyDelaySource?organizationId=${form.organizationId}&ttid=${form.date.id}`).then(res => {//查看额度来源
            if (res !== undefined && res.data !== "" && res.data.length > 0) {
                alert('该公司客户额度来源为总公司额度分配,不能进行额度延时操作!')
                return;
              }else{
      	  	fetching(`/msapi/credit1/saveDelay`, {
          	   method: 'post',
                   body: JSON.stringify(body)
     		 }).then(res => {
        	  try {
                    if (res) {
                      if (res.msg) {
             		 alert(`额度延时提交失败,${res.msg}`)
                         return;
            		}
   		         this.props.onSubmit({
                         ...res,
              		 submit_name: text
                        })
                    }else {
                      alert('提交失败')
                    }
                  } catch (error) {
                    console.log('error')
                  }
                })
		 } 
             })
        }  
      })
    }else{
      this.props.onSubmit({
        ...body,
        submit_name: text
      })
    }
  }

  uploadHandler = async (e,type) => {
    let indexId = '';
     indexId = await this.uploadFile(e)
      .then(res => {
        if (res) {
           if (!res.status){
              alert(res.msg)         
            }else{
              global.count = global.count - 1
              return res.json()
            }
        }else{
           return null
        }
      }).then(r=>{ 
       if (r.error.code === 200) {//无错
          alert("文件上传成功");
         /*clearInterval(timer);
         this.setState({progressLength : 100})
         setTimeout(() => {
           alert("文件上传成功");
           this.setState({ progress: false })
         }, 500)*/
         return r.data
       } else {//业务失败
         alert(r.error.message)
         //this.setState({ progress: false })
         return null;
       }
      })

    if(type === 11){
      this.state.form.delayFileId = indexId 
      this.state.form.delayFile = true
    }

    return true
  }

  uploadFile(e){
    var fordata = new FormData();
    fordata.append('type',11);
    fordata.append('fileName',e.file);
    const options = {
      method: "POST",
      body: fordata
    }
    global.count = (global.count+1)
    return fetch(`/msapi/upload/upload`,options)
  };


  render() {
    const { exercise, classes} = this.props;
    const { form2, form ,auth, userList, formStatic} = this.state;
    let rolesId = auth.roles ? auth.roles[0].id : 0;
    const check_opinion_label = (form_no) => {
      if (form_no === '3') return form2.check_opinion
      else if (form_no === '2') return form2.return_reason
      else return form2.remarks
    }
    const status = (handleSubmit, isSubmitting, values) => {
      return <Grid container justify="center" style={{ padding: '20px 0' }}>
        <Button
          color="primary"
          variant='contained'
          onClick={handleSubmit}
          disabled={isSubmitting}
          name={form2.submit}
          style={{ marginRight: '10px' }}
        >
          {form2.submit}
        </Button>
        <Button
          color="primary"
          variant='contained'
          onClick={this.handleSubmit.bind(this, values, form2.cancel)}
          name={form2.cancel}
          disabled={isSubmitting}
          style={{ marginRight: '10px' }}
        >
          {form2.cancel}
        </Button>
      </Grid>
    }
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
         //表单验证
         validate={(values) => {
          if (values.DialogTitle === "审核") {
             return checkFormikDelay(values, form2, formStatic);
          }else{
            return checkFormikDelayFile(values);
          }
        }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, '提交');
        }}
        render={({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <form method="post">
            {exercise.save === '3' && rolesId === 1 && 
            <div>
              <TextField
                  label={'额度结束时间'}
                  value={form.creditDueDate}
                  name='creditDueDate'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  inputProps={{ maxLength: 50 }}
                  fullWidth
                  disabled={true}
                  >
              </TextField>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  label="额度延长日"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="date-picker-inline"
                  value={values.delayDate}
                  onChange={e => setFieldValue('delayDate', this.handleChange3.bind(this, 'delayDate', e)())}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
		<Grid item xs={5}>
                    <Upload disabled={this.props.auth.roles[0].id !== 1} 
                      type="file"
                      name="文件上传"
        	      exercise={exercise}
                      uploadName='delayFile' view={formStatic.view}
                      mold = "11"
                      uploadChange={e=>setFieldValue('delayFile',this.uploadHandler(e, 11))}
                      ></Upload>
                    <FormikError touched={touched} errors={errors} name={'delayFile'} />
                  </Grid>      
                  </MuiPickersUtilsProvider>
                <div>
                  {
                    status(handleSubmit, isSubmitting, values)
                  }
                </div>
                </div>
        }
        {exercise.save === '2'  && 
            <div>
              <SimpleExpansionPanel title="历史审核意见">
                <Typography style={{ textAlign: 'left' }} variant="inherit" gutterBottom>经办人：{form.organization.name}-{form.user.name}</Typography>
                {
                  form.delayAmountsFlowsList.map((item, index) => {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 0', textAlign: 'left', }} key={index}>
                        <Typography variant="inherit" gutterBottom>
                          {item.name}
                        </Typography>
                        <Typography variant="inherit" gutterBottom>
                          {item.checkOpinion}
                        </Typography>
                      </div>
                    )
                  })
                }
              </SimpleExpansionPanel>
		<Grid item xs={5}>
                    <Upload disabled={this.props.auth.roles[0].id !== 1} 
                      type="file"
                      name="文件上传"
                      exercise={exercise}
                      uploadName='delayFile' view={formStatic.view}
                      mold = "11"
                      uploadChange={e=>setFieldValue('delayFile',this.uploadHandler(e, 11))}
                      ></Upload>
                    <FormikError touched={touched} errors={errors} name={'delayFile'} />
                  </Grid>
              <Grid item xs={12}>
                    <TextField
                      multiline
                      rows='4'
                      label={check_opinion_label(values.form_no)}
                      value={values.check_opinion}
                      name='check_opinion'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      margin='normal'
                      fullWidth
                      disabled={false}
                    />
                    <FormikError touched={touched} errors={errors} name={'check_opinion'} />
                  </Grid>
	         {(values.form_no === '2') &&
                    <FormControl style={{ width: '100%', margin: '10px 0' }}>
                      <InputLabel id="demo-simple-select-label">{form2.returnBy}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="returnBy"
                        value={values.returnBy}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {
                          userList.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                            )
                          })
                        }
                      </Select>
		     </FormControl>
		     }
                      <Grid item xs={12} >
                    <FormControl component="fieldset" style={styles} className={classes.radio}>
                      <RadioGroup className={classes.radio} style={{ flexDirection: 'row' }} aria-label="gender" name='entrusted_payment' value={values.form_no}
                        onChange={e => setFieldValue('form_no', e.target.value)}>
                        {List.map(item => {
                          return (
                            <FormControlLabel
                              key={item.id}
                              name={item.id}
                              value={item.id}
                              disabled={exercise.save === '3'}
                              control={<Radio />}
                              label={item.text} />
                          )
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                   
                  {
                    status(handleSubmit, isSubmitting, values)
                  }
            </div>
        }
            </form>
          )}
      />

    )
  }
}

export default withStyles(useStyles)(CheckForm)
