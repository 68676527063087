import React from 'react';
import { commafy } from '../../components/numToText';

export default (props) => {


    const { params } = props;

    return <div>
        <div className="dovbox">
            <label>
                <span>我的额度：</span>
                <span>{commafy(params.mx_amount)}</span>
                <span> (元)</span>
            </label>
            <label>
                <span>已用额度：</span>
                <span>{commafy(params.used_amount)}</span>
                <span> (元)</span>
            </label>
            <label>
                <span>可用额度：</span>
                <span>{commafy(params.usable_amount)}</span>
                <span> (元)</span>
            </label>
        </div>

    </div>

}


