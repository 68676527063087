// 注册-核心企业注册表单
export default {
  process: "注册处理流程",
  first_step: "企业注册",
  second_step: "实名认证",
  third_step:"自定义印章",
  last_step: "补充信息",
  back: "返回上一步",
  next: "下一步",
  confirm: "确定",
  success_text: "感谢您的注册",
  to_login: "请使用手机号及密码登录，前往登录",
  website: "Your Website",
  yes_text: "是",
  no_text: "否",
  view_agreement: "查看协议",
  agreement1: "我已阅读并同意",
  agreement2: "《用户认证协议》",
  agreement4: "《用户授权协议》",
  license_file1: "营业执照",
  legal_representative_file1: "法人身份证",
  operator_certificate_file1: "经办人身份证",
  license_start_date: "营业执照开始日期",
  license_end_date: "营业执照结束日期",
  legal_representative_start_date: "法人证件开始日期",
  legal_representative_end_date: "法人证件结束日期",
  operator_certificate_start_date: "证件起始日期",
  operator_certificate_end_date: "证件到期日期",

  regFormPath: "信息登记表",
  bank_detail_name: "开户行支行",
  query_status: "查询企业实名认证状态",
  desc: "（所有附件上传要加盖企业公章，以扫描或拍照方式确保企业公章为红色，附件支持图片或者PDF文件）",

  certification_title: "实名认证",
  load_info: "资料清单下载",
  temporarily_save: "企业认证",
  corporate_enterprise: "法人企业",
  unincorporated_enterprise: "非法人企业",
  getCellCode: "获取验证码",
  official_seal:'上传企业公章',
  corporate_seal:'上传法人章*',
  corporate_seal_text:'上传法人章,建议图片尺寸：134*60像素',
  official_seal_text:'上传公章,建议图片尺寸：166*166像素',


}