import React, { Component } from 'react'
import Select from 'react-select';
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  TextField,//20201228
  Button,
  Grid,
  Checkbox,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import CheckForm from '../../components/commonForm/CheckForm';
import FormikError from '../../components/commonForm/formikError';
import { C51Formik } from './formikValidate'

const useStyles = (theme => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 280,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    form: this.getInitState(),
    formStatic: this.props.formStatic || {},//静态资源
    selectCustomerList: [],
    checked: [],
    left: [],
    right: this.getInitRight(),
    leftChecked: [],
    rightChecked: [],
    leftSelect: [],//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
    rightSelect: [],//企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228
    leftName: '',//[备选企业]搜索关键字--20201228
    rightName: '',//[选中企业]搜索关键字--20201228
    loading: false,
  }
  async componentWillMount() {
    const { exercise = {} } = this.props;
    // const { left, right, checked } = this.state;
    if (exercise.save !== '3') {
      this.queryEnterpriseInfo(exercise.enterprise_no);
    } else {
      //企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228
      this.setState({ rightSelect: this.state.right });
    }
    // let leftChecked = this.intersection(checked, left);
    // let rightChecked = this.intersection(checked, right);
    // this.setState({ leftChecked, rightChecked })
  }

  getInitState() {
    const { exercise = {} } = this.props
    const form = {
      select_customer: '',
      enterprise_name: "",
      enterprise_no: "",
    }
    if (exercise.save !== '1') {
      form.select_customer = {
        value: exercise.enterprise_name,
        label: exercise.enterprise_name,
        enterprise_no: exercise.enterprise_no,
        enterprise_name: exercise.enterprise_name
      }
    }
    return exercise.save === '1' ? { ...form } : { ...form, ...exercise }
  }
  getInitRight() {
    const { exercise = {} } = this.props;
    if (exercise.save !== '1' && exercise.subsidiaries instanceof Array) {
      let subsidiaries = exercise.subsidiaries.map((item) => {
        let items = {
          id: item.sub_company_id,
          enterprise_name: item.sub_company_name,
          enterprise_no: item.sub_company_no
        }
        return items
      })
      return subsidiaries
    }
    return []
  }
  queryEnterpriseInfo = (enterprise_no) => {
    const { right = [] } = this.state;
    fetching('/msapi/organizations', {
      method: 'GET',
    }).then(res => {
      if (res !== undefined && res !== null) {
        try {
          let left = [];
          let datas = res.filter((item) => {
            item.value = item.name;
            item.label = item.name;
            item.enterprise_name = item.name;
            if (item.id !== 1 && enterprise_no !== item.enterprise_no && this.findObject(item, right) === -1) {
              let obj = {};
              obj.id = item.id
              obj.enterprise_no = item.enterprise_no
              obj.enterprise_name = item.name
              left.push(obj)
            }
            return (item.id !== 1 && enterprise_no !== item.enterprise_no) ? item : null;
            
          })
          let leftSelect = left;//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
          this.setState({ selectCustomerList: datas, left, leftSelect });
          //this.setState({ selectCustomerList: datas, left });
        } catch (error) {
        }
      } else {
      }
    })
  }

  //企业管理模块关联企业时,[备选企业]/[选中企业]可以按照名字搜索分公司名字--20201228
  handleInputChange = (name, value) => {
    let leftSelect = this.state.left;//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
    let rightSelect = this.state.right;//企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228

    let leftName = "";//[备选企业]搜索关键字--20201228
    let rightName = "";//[选中企业]搜索关键字--20201228
    
    if (name === 'leftName') {//选择[备选企业]
      if (null !== value && value !== "") {
        leftName = value;//[备选企业]搜索关键字--20201228
        leftSelect = this.state.left.filter((item) => {
          if ( item.enterprise_name.includes(value) ) {
            return item;
          }
          return null;
        })
      }
      this.setState({ leftSelect, leftName });//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
    } else if (name === 'rightName') {//选择[选中企业]
      if (null !== value && value !== "") {
        rightName = value;//[选中企业]搜索关键字--20201228
        rightSelect = this.state.right.filter((item) => {
          if ( item.enterprise_name.includes(value) ) {
            return item;
          }
          return null;
        })
      }
      this.setState({ rightSelect, rightName });//企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228
    }
  }
    
  
  
  //[备选企业]/[选中企业]中值随着左右移动按钮变化后,企业管理模块关联企业时,[备选企业]/[选中企业]可以按照名字搜索分公司名字--20201228
  companySelectChange = (listLeft, listRight) => {
    let leftSelect = listLeft;//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
    let rightSelect = listRight;//企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228

    const leftName = this.state.leftName;//[备选企业]搜索关键字--20201228
    const rightName = this.state.rightName;//[选中企业]搜索关键字--20201228

    if (null !== leftName && leftName !== "") {
      leftSelect = listLeft.filter((item) => {
        if ( item.enterprise_name.includes(leftName) ) {
          return item;
        }
        return null;
      })
    }

    if (null !== rightName && rightName !== "") {
      rightSelect = listRight.filter((item) => {
        if ( item.enterprise_name.includes(rightName) ) {
          return item;
        }
        return null;
      })
    }

    this.setState({ leftSelect, rightSelect });
  }

  //输入框值改变
  handleChange3 = (name, value) => {
    let value2 = value;
    if (name === 'select_customer') {
      this.setState({ checked: [], leftChecked: [], rightChecked: [], right: [] })
      this.queryEnterpriseInfo(value.enterprise_no)
    }
    return value2;
  }

  // 对象查找
  findObject = (obj, data) => {
    let index = data.findIndex(item => item.enterprise_no === obj.enterprise_no);
    return index;
  }
  handleToggle = (value) => {
    const { checked, left, right } = this.state;
    const currentIndex = this.findObject(value, checked);
    let newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    let leftChecked = this.intersection(newChecked, left);
    let rightChecked = this.intersection(newChecked, right);
    //this.setState({ checked: newChecked, leftChecked, rightChecked })
    if (leftChecked.length>0) {
      let sub_company_no = leftChecked[0].enterprise_no;
      fetching(`/smrd/business_management/findc?sub_company_no=${sub_company_no}`).then(res => {
        if(res !== undefined && res !== null){
          if(res.length>0) {
            alert("该企业已经被关联过,不能再次进行关联操作")
          } else{
            fetching(`/smrd/business_management/findc?enterprise_no=${sub_company_no}`).then(res => {
              if(res !== undefined && res !== null){
                if(res.length>0){
                  alert("该企业已经被作为总公司关联过,不能关联为子公司")
                } else {
                  this.setState({ checked: newChecked, leftChecked, rightChecked })
                }
              }
            })
          }
        }
      })
    } else if(rightChecked.length>0){    
            this.setState({ checked: newChecked,rightChecked, leftChecked })
          }
    
  };

  handleAllRight = () => {
    const { right, left } = this.state;
    let data = right.concat(left)
    this.setState({ right: data, left: [] })

    //[备选企业]/[选中企业]中值随着左右移动按钮变化后,企业管理模块关联企业时,[备选企业]/[选中企业]可以按照名字搜索分公司名字--20201228
    this.companySelectChange([], data);
  };

  handleCheckedRight = () => {
    const { right, left, leftChecked, checked } = this.state;
    let dataLeft = this.not(left, leftChecked)
    let dataRight = right.concat(leftChecked)
    let dataChecked = this.not(checked, leftChecked)
    this.setState({ left: dataLeft, right: dataRight, checked: dataChecked, leftChecked: [] })

    //[备选企业]/[选中企业]中值随着左右移动按钮变化后,企业管理模块关联企业时,[备选企业]/[选中企业]可以按照名字搜索分公司名字--20201228
    this.companySelectChange(dataLeft, dataRight);
  };

  handleCheckedLeft = () => {
    const { right, left, rightChecked, checked } = this.state;
    let dataLeft = left.concat(rightChecked)
    let dataRight = this.not(right, rightChecked)
    let dataChecked = this.not(checked, rightChecked)
    this.setState({ left: dataLeft, right: dataRight, checked: dataChecked, rightChecked: [] })

    //[备选企业]/[选中企业]中值随着左右移动按钮变化后,企业管理模块关联企业时,[备选企业]/[选中企业]可以按照名字搜索分公司名字--20201228
    this.companySelectChange(dataLeft, dataRight);
  };

  handleAllLeft = () => {
    const { right, left } = this.state;
    let data = left.concat(right)
    this.setState({ left: data, right: [] })

    //[备选企业]/[选中企业]中值随着左右移动按钮变化后,企业管理模块关联企业时,[备选企业]/[选中企业]可以按照名字搜索分公司名字--20201228
    this.companySelectChange(data, []);
  };

  not = (a, b) => {
    let bIds = b.map(item => item.enterprise_no ? item.enterprise_no : null)
    let data = a.filter(value => value.enterprise_no ? !bIds.includes(value.enterprise_no) : null);
    return data;
  }
  intersection(a, b) {

    let bIds = b.map(item => item.enterprise_no ? item.enterprise_no : null)
    let data = a.filter(value => value.enterprise_no ? bIds.includes(value.enterprise_no) : null);
    return data;
  }
  //保存 提交
  handleSubmit = (values, name) => {
    const text = name || 'cancle';
    if(this.state.right.length<=0 && text === 'submit'){
      alert("请选择关联的子公司")
      return;
    }
    if (text === 'submit') {
      this.setState({ loading: true })
    }
    const { auth } = this.props;
    let paramsDate = values || this.state.form;
    let ids = paramsDate._id || '';
    let subsidiaries = this.state.right.map((item) => {
      let items = {
        sub_company_id: item.id,
        sub_company_name: item.enterprise_name,
        sub_company_no: item.enterprise_no
      }
      return items
    })
    let { select_customer } = paramsDate
    let params = {
      //_id: ids,//修改新增合并--20201111
      subsidiaries,
      auth,
      enterprise_name: select_customer.enterprise_name,
      enterprise_no: select_customer.enterprise_no,
      timestamp : new Date()
    }
    params._id = values._id//补丁20201230
    params.ids = params._id//补丁20201230
    //提交表单
    if (text === 'submit') {
      fetching(`/smrd/business_management/save/${ids}`, {
        method: ids ? 'POST' : 'POST',
      //fetching(`/smrd/business_management/`, {//修改新增合并--20201229
        //method: 'POST',//修改新增合并--20201111
      //fetching(`/smrd/business_management/${ids}`, {
        //method: ids ? 'PUT' : 'POST',
        body: JSON.stringify(params)
      }).then((data) => {
        this.setState({ loading: false })
        try {
          if (data !== undefined && data !== null) {
            if (data.msg) {
              alert(`${ids ? '企业管理编辑失败' : '企业管理提交失败'},${data.msg}`)
              return;
            }
            this.props.onSubmit({
              ...params,
              submit_name: text
            })
          }else {
            alert('提交失败')
          }
        } catch (error) {
          console.log(error)
          // alert(`${ids ? '企业管理编辑失败' : '企业管理提交失败'}`)
        }


      })
    } else {
      this.props.onSubmit({
        ...params,
        submit_name: text
      })
    }
  }
  //审核意见
  FormSubmit3 = (data) => {
    this.props.onSubmit({
      ...data,
      submit_name: 'submit'
    })
  }

  render() {
    const { form, formStatic, selectCustomerList, checked, left, right, leftChecked, rightChecked, loading, leftSelect, rightSelect, leftName, rightName, } = this.state
    const { exercise, classes, auth, dataUrl } = this.props;
    const checkform = () => {
      return <CheckForm auth={auth} exercise={exercise} dataUrl={dataUrl}
        onSubmit={this.FormSubmit3}
      />
    }
    const customList = items => (
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {items.map(value => {
            const labelId = `transfer-list-item-${value.enterprise_no}-label`;
            return (
              <ListItem key={value.enterprise_no} role="listitem" button onClick={this.handleToggle.bind(this, value)}>
                {/* <ListItem key={value} role="listitem" button> */}
                <ListItemIcon>
                  <Checkbox
                    checked={this.findObject(value, checked) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.enterprise_name} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    );
    return (
      <Formik
        initialValues={{
          ...form
        }}
        validate={(values) => {
          return C51Formik(values, formStatic);
        }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, 'submit');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
            <div>
              <form>
                <Grid item xs={12}>
                  <div style={{ margin: '10px' }}>
                    <span style={{ fontSize: '14px' }}>{formStatic.select_customer}</span>
                    <Select
                      isSearchable
                      value={values.select_customer}
                      onChange={e => setFieldValue('select_customer', this.handleChange3.bind(this, 'select_customer', e)())}
                      options={selectCustomerList}
                      isDisabled={exercise.save === '3'}
                    />
                    <FormikError touched={touched} errors={errors} name={'select_customer'} />
                  </div>
                </Grid>
                <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                  <Grid item>
                    <Typography variant="body2" gutterBottom>备选企业</Typography>
                    <TextField
                      id="outlined-margin-dense"
                      placeholder="select..."
                      defaultValue=""
                      className={classes.textField}
                      value={values.leftName}
                      onChange={e => setFieldValue('leftName', this.handleInputChange.bind(this, 'leftName', e.target.value)())}
                      disabled={exercise.save === '3'}
                      margin="dense"
                      variant="outlined"
                    />
                    {customList(leftSelect)}
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={this.handleAllRight}
                        disabled={left.length === 0 || exercise.save === '3'}
                        aria-label="move all right"
                      >
                        ≫
          </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={this.handleCheckedRight}
                        disabled={leftChecked.length === 0 || exercise.save === '3'}
                        aria-label="move selected right"
                      >
                        &gt;
          </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={this.handleCheckedLeft}
                        disabled={rightChecked.length === 0 || exercise.save === '3'}
                        aria-label="move selected left"
                      >
                        &lt;
          </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={this.handleAllLeft}
                        disabled={right.length === 0 || exercise.save === '3'}
                        aria-label="move all left"
                      >
                        ≪
          </Button>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" gutterBottom>选中企业</Typography>
                    <TextField
                      id="outlined-margin-dense"
                      placeholder="select..."
                      defaultValue=""
                      className={classes.textField}
                      value={values.rightName}
                      onChange={e => setFieldValue('rightName', this.handleInputChange.bind(this, 'rightName', e.target.value)())}
                      margin="dense"
                      variant="outlined"
                    />
                    {customList(rightSelect)}
                  </Grid>
                </Grid>
                {exercise.save !== '3' &&
                  <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
                    <div style={{ position: 'relative' }}>
                      <Button
                        style={{ margin: '0 10px' }}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        提交
                    </Button>
                      {loading && <CircularProgress size={24} style={loadingStyle} />}
                    </div>

                    <Button variant="contained" color="primary" onClick={this.handleSubmit.bind(this, values, 'cancle')}>取消</Button>
                  </div>
                }
              </form>
              {(exercise.save !== '1') &&
                <div>
                  {checkform()}
                </div>}

            </div>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);



