import React, { Component } from 'react'
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  TextField,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core'
import MaterialTable from '../../vendors/material-table';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import FormikError from '../commonForm/formikError';
//import { pwdFormik } from './formikValidate'
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import cnLocale from "date-fns/locale/zh-CN";
import { DEFAULT_EXPORT_URL } from '../../config/constants';
import { formatDate } from '../date';//20200918
export const BASE = '/business';

const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }
}));
const loadingStyle = {
  position: "absolute",
  color: green[500],
  width: '80%',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    form: this.getInitState(),
    loading: false,
    columns: [{}],
  }
  /*async componentDidMount() {
  }*/

  //获取数据
  componentDidMount() {
    const table = "invoice-list";

    fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {
        if (data !== undefined && data !==null) {
          //let auth = loginInfos();
          const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
          this.setState({ columns });
        }
      })
  }

  getInitState() {
    const { exercise } = this.props;

    const form = {
      form_no: exercise.checkStatus || '3',
      InvoiceList: exercise.InvoiceList || [],
      returnBy: '退回上一级'
    }

    return form;
  }


  render() {
    //const { columns, data, dataUrl, exercise, auth, opinion_list, params, RDTreeFlag } = this.state;
    const { columns, form, dataUrl } = this.state;
    const title = '';
    //const title = dataUrl.title || '';
    const { table = '' } = this.props;
    //const path = capitalize(table);
    //const actions = actions2(this, table, auth);
    //const editable = editable2(this, table);

    return <div>
      <MaterialTable
        title={title}
        //onSearchChange={this.onSearchChange}
        //onChangePage={this.onChangePage}
        //onChangeRowsPerPage={this.onChangeRowsPerPage}
        //path={path}
        columns={columns}
        data={form.InvoiceList}
        //actions={actions}
        //editable={editable}
        //localization={localization}
        options={options(table)}
      />
    </div>
  }
}

const options = (table) => {
  const options = {
    actionsCellStyle: { padding: '0 10px' },
    headerStyle: { //表格头部
      backgroundColor: '#1d4583',
      color: '#FFF',
      whiteSpace: 'nowrap'
    },
    rowStyle: { //表行
      backgroundColor: '#fff',
      whiteSpace: 'nowrap'
    },
    sorting: true,  //排序
    // selection: true //多选框
  }
  /*if (table === 'r6-3-7') {
    options.exportButton = true //导出
  }*/
  return options;

}

export default withStyles(useStyles)(Form);



