
import React from 'react';
import TabPanels from './TabPanels'
import getSubRouters from './helper';

const PARENT_PATH = '/business/外部融资';
const PATH = '招行融资';

export const subRootAry1 = [
    '招行融资查看',
    '招行已完成融资',
  ];

// option 1:
export function tabPanels(ary = []) {
  return <TabPanels ary={ary}/>;
}

export default getSubRouters(PARENT_PATH, PATH, subRootAry1);
