import React, { Component } from 'react'
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle2 = withStyles(styles)(props => {
  const { children, mx_number, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children + '\n\n'}{mx_number}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});
class CommonDialog extends Component {

  state = {
    checkOpen: false,
    save: '1',
    DialogTitle: '',
    isBtn: true,
    ...this.props.exercise
  }
  componentDidMount() {

  }

  handleToggle = () => {
    this.props.handleToggle(true);
  }
  handleToggle2 = () => {
    this.props.handleToggle();

  }

  render() {
    const { checkOpen } = this.state
    const exercise = { ...this.state }
    return (
      <>
        <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
          {exercise.isBtn && <Button onClick={this.handleToggle2} variant="contained" color="primary">{exercise.addBtnName}</Button>}
          <MuiDialog
            open={checkOpen}
            onClose={this.handleToggle}
            fullWidth
            maxWidth='sm'
          >
            {/* <DialogTitle>{exercise.DialogTitle}</DialogTitle> */}
            <DialogTitle2 id="customized-dialog-title" mx_number={exercise.mx_number} onClose={this.handleToggle}>
              {exercise.DialogTitle}
            </DialogTitle2>
            <DialogContent>
              {this.props.children}
            </DialogContent>
          </MuiDialog>
        </div>
      </>
    )
  }
}

export default CommonDialog
