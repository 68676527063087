import C2_1 from './C2_1';
import C2_2 from './C2_2';
import C2_3 from './C2_3';
import C3_1 from './C3_1';
import C4_1 from './C4_1';
import C4_2 from './C4_2';
import C4_3 from './C4_3';
import C4_4 from './C4_4';
import C4_5 from './C4_5';
import C4_6 from './C4_6';

import C5_2 from './C5_2';
import C6_2 from './C6_2';
import C6_3 from './C6_3';
import C6_4 from './C6_4';
import C6_5 from './C6_5';
import C71 from './C7_1';
import C72 from './C7_2';
import C73 from './C7_3';
import C74 from './C7_4';
import C79 from './C7_9';
import C9_1 from './C9_1';
import C9_2 from './C9_2';
import C13_1 from './C13_1';
import C13_2 from './C13_2';
import C141 from './C14_1';
import C142 from './C14_2';
import C143 from './C14_3';
import C144 from './C14_4';
import C145 from './C14_5';
import C146 from './C14_6';
import C18_1 from './C18_1';
import C18_2 from './C18_2';
import C18_3 from './C18_3';
import C18_4 from './C18_4';
import C18_5 from './C18_5';
import C18_6 from './C18_6';
import C18_8 from './C18_8';
import C18_9 from './C18_9';
import C19_1 from './C19_1';
import C19_2 from './C19_2';
import C19_3 from './C19_3';
import C20_1 from './C20_1';
import C20_2 from './C20_2';
import C20_3 from './C20_3';
import C21_1 from './C21_1';
import R96Form from './R96Form';
export {
  C2_1,
  C2_2,
  C2_3,
  C3_1,
  C4_1,
  C4_2,
  C4_3,
  C4_4,
  C4_5,
  C4_6,
  C5_2,
  C6_2,
  C6_3,
  C6_4,
  C6_5,
  C71,
  C72,
  C73,
  C74,
  C79,
  C9_1,
  C9_2,
  C13_1,
  C13_2,
  C141,
  C142,
  C143,
  C144,
  C145,
  C146,
  C18_1,
  C18_2,
  C18_3,
  C18_4,
  C18_5,
  C18_6,
  C18_8,
  C18_9,
  C19_1,
  C19_2,
  C19_3,
  C20_1,
  C20_2,
  C20_3,
  C21_1,
  R96Form,
}
