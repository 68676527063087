import Notification from './Notification';

let toast

// 将回调函数参数放置于带有默认参数的duration 前
// 可以在使用的时候避免设置回调同时需要指定时长的问题
// 原因是es6在要跳过一个带有默认参数的时候必须指定其值或者为undefined
const notice = (type,text,onClose,duration=2000,) =>{
    // 防止生成多个容器
    if (!toast) {
        toast = Notification
    }
    if(!text){
        return;
    }else{
        text = text.toString();
        // 添加一个消息
        return toast.add({
        type,
        text,
        onClose,
        duration,
        })
    }
}

// 未引入配置方法
export default {
    info:(text,onClose,duration)=>notice('info',text,onClose,duration),
    success:(text,onClose,duration)=>notice('success',text,onClose,duration),
    error:(text,onClose,duration)=>notice('error',text,onClose,duration),
    warning:(text,onClose,duration)=>notice('warning',text,onClose,duration),
    hide:()=>{if(toast){ return toast.destroy()}},
}