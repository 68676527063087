import getSubRouters from './helper';

 export const subRootAry4 = [
    '北金所保理融资发起',
    '北金所非保理融资查看',
    '北金所保理融资查看',
    '北金所已完成融资',
  ];

const PARENT_PATH = '/risk-management/外部融资';
const PATH = '北金所融资';
export default getSubRouters(PARENT_PATH, PATH, subRootAry4);