import React, { Component } from 'react'
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  Button,
  Grid,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';


global.count = 0;
const useStyles = (theme => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 280,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));



const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    form: this.getInitState(),
    formStatic: this.props.formStatic || {},//静态资源
    selectCustomerList: [],
    checked: [],
    left: [],
    right: this.getInitRight(),
    leftChecked: [],
    rightChecked: [],
    leftSelect: [],//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
    rightSelect: [],//企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228
    leftName: '',//[备选企业]搜索关键字--20201228
    rightName: '',//[选中企业]搜索关键字--20201228
    loading: false,
  }


  getInitState() {
    const { exercise = {} } = this.props
    //debugger
    const form = {
      select_customer: '',
      enterprise_name: "",
      enterprise_no: "",
      data: [],
      //form_no: '2',
    }
    if (exercise.save !== '1') {
      form.select_customer = {
        value: exercise.enterprise_name,
        label: exercise.enterprise_name,
        enterprise_no: exercise.enterprise_no,
        enterprise_name: exercise.enterprise_name
      }
    }
    return exercise.save === '1' ? { ...form } : { ...form, ...exercise }
  }
  getInitRight() {
    const { exercise = {} } = this.props;
    if (exercise.save !== '1' && exercise.subsidiaries instanceof Array) {
      let subsidiaries = exercise.subsidiaries.map((item) => {
        let items = {
          id: item.sub_company_id,
          enterprise_name: item.sub_company_name,
          enterprise_no: item.sub_company_no
        }
        return items
      })
      return subsidiaries
    }
    return []
  }
  queryEnterpriseInfo = (enterprise_no) => {
    const { exercise = {} } = this.props;
    const { right = [] } = this.state;
      if (exercise.data) {
        try {
          let left = [];
          let datas = exercise.data.filter((item) => {
            item.value = item.mx_number  + item.extraction;
            item.label = item.mx_number + item.extraction ;
            item.enterprise_name = item.mx_number + item.extraction ;
            if ( true ) {
              let obj = {};
              obj.id = item.id
              obj.enterprise_no = item.mx_number
              obj.enterprise_name = item.mx_number +"-"+ item.extraction +"-"+ item.amount + "(元)" +"-"+ item.limit_mark +"-"+ item.payment_day
              left.push(obj)
            }
            return  item ;
            
          })
          let leftSelect = left;//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
          this.setState({ selectCustomerList: datas, left, leftSelect });
          //this.setState({ selectCustomerList: datas, left });
        } catch (error) {
        }
      } else {
      }
    
  }

  onCebSkip = () =>{
    let mxf_number = this.props.exercise.mxf_number;
    const UpGwMsg = "";
    fetching(`/msapi/ceb/gatewayCebAgainString?mxf_number=` + mxf_number,{
      method:'get' , 
    }).then(res =>{
      if(res.code === 200){
          const param ={
            "UpGwMsg":res.data
          }
          Post("https://ebank.cebbank.com/cebent/preRELLogin.do?_locale=zh_CN", param);
          function  Post(URL, PARAMS) {
            var temp = document.createElement("form");
            temp.action = URL;
            temp.method = "post";
                  //如需新打开窗口 form 的target属性要设置为'_blank'
            temp.target = "_blank";
            temp.style.display = "none";
            for (var x in PARAMS) {
              var opt = document.createElement("textarea");
              opt.name = x;
              opt.value = PARAMS[x];
              temp.appendChild(opt);
            }
            document.body.appendChild(temp);
            temp.submit();
            return temp;
          }
      
      } else {
        alert(res.msg)
      }
    })
}

  handleToggle = (value) => {
    const { checked, left, right } = this.state;
    const currentIndex =  -1;//this.findObject(value, checked);
    let newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    //this.state.form.data.push(value.enterprise_no);
    if (this.state.form.data.length === 0) {
      this.state.form.data.push(value.enterprise_no);
    } else {
      this.props.exercise.CreditSource = true;
      for (var i = 0; i < this.state.form.data.length; i++) { 
        if (this.state.form.data[i] === value.enterprise_no) {
          if ( i > -1) { 
            this.state.form.data.splice(i, 1); 
            this.props.exercise.CreditSource = false;
            }
        }
    }
    if (this.props.exercise.CreditSource) {
      this.state.form.data.push(value.enterprise_no);
     }
    }
    
    this.setState({ values: this.state.form.data })

    
  };

  not = (a, b) => {
    let bIds = b.map(item => item.enterprise_no ? item.enterprise_no : null)
    let data = a.filter(value => value.enterprise_no ? !bIds.includes(value.enterprise_no) : null);
    return data;
  }
  intersection(a, b) {

    let bIds = b.map(item => item.enterprise_no ? item.enterprise_no : null)
    let data = a.filter(value => value.enterprise_no ? bIds.includes(value.enterprise_no) : null);
    return data;
  }
  //保存 提交
  handleSubmit = (values, name) => {
    const text = name || 'cancle';
    if (text === 'submit') {
      this.setState({ loading: true })
    }
    const { auth } = this.props;
    let mxf_number = this.props.exercise.mxf_number;
    let form_no = this.props.exercise.form_no;
    if (text === 'submit') {
      fetching(`/msapi/ceb/getAgreementSign?mxf_number=` + mxf_number,{
        method:'get' , 
      }).then((res) =>{
        if(res.data === "1"){
        fetching(`/msapi/ceb/applyAgainCebFinancing?mxf_number=` + mxf_number, {
          method: 'GET' ,
        }).then((data) => {
          this.setState({ loading: false })
          try {
            if (data.code === 200) {
              alert(data.msg)
              window.location.reload();
            }else {
              alert('提交失败')
              return;
            }
          } catch (error) {
            console.log(error)
          }
        })
      } else{
        alert("保理协议未签署")
        window.location.reload();
      }
      })
    
    } else {
      window.location.reload();
    }
  }
  //审核意见
  FormSubmit3 = (data) => {
    this.props.onSubmit({
      ...data,
      submit_name: 'submit'
    })
  }

  

  sign_agreements = () => {
    const { fdd_file_name, KLStart, isverification_code,
      table,  fdd_file_path, open_credit_code,  distribute } = this.state.form;
    const { auth, form, signatureMsg, secondNumber } = this.state;
    let Url = '';
    let body = {};
   if (table === 'r18-5-1'){
      Url = '/dzqz/extsignAuto/RZCEBAGAIN';//光大再保融资合同
      body = {  
        credit_no: open_credit_code ? open_credit_code : form.organization.credit_no,//融资方社会信用代码
        fdd_file_name: fdd_file_name,//融资合同编号
        //签章重复问题处理--20201102
        type: 3,//1-开立；2-流转；3-融资
        oid: this.state.form.id,//开立/流转/融资id
      }
    } 
    if (!KLStart && signatureMsg !== '正在签订中...') {
      if (Url === '') {
        this.setState({
          form: { ...form, KLStart: true },
          signatureMsg: '签章还在更新中...'
        })
      }
      else if (!isverification_code) {
        this.setState({
          signatureMsg: '正在签订中...',

        })
        //签章
        fetching(Url, {
          method: 'POST',
          body: JSON.stringify(body)
        }, 2).then(res => {
          if (res.code === 200) {
            window.open(res.data.viewpdf_url || fdd_file_path);
            this.setState({
              form: { ...form, KLStart: true },
              signatureMsg: '签订成功'
            })
          } else {
            this.setState({
              signatureMsg: res.msg || '签章失败'
            })
          }

        })
      }
    }
  }

  uploadSubmit = () =>{
    let mxf_number = this.props.exercise.mxf_number;
    //type === 2  为在保理文件上送
    fetching(`/msapi/ceb/cebFileUpload?mxf_number=${mxf_number}&type=2`,{
      method:'get' ,
    }).then((res) => {
      alert(res.msg)
    })

  }


  render() {
    const { form,  loading } = this.state
    const { exercise, classes, auth, dataUrl } = this.props;
    return (
      <Formik
        initialValues={{
          ...form
        }}
        // validate={(values) => {
        //   return values;
        // }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, 'submit');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
            <div>
              <form>
                <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                  <Grid item xs={12}>
                  <Button
                        onClick={this.sign_agreements}
                        color="primary">{"签订《光大再保融资协议》"}</Button>
                  </Grid>
                  <Grid item xs={12}>
                  <Button
                  style={{ margin: '0 10px' }}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={this.uploadSubmit}>
                          文件上送
                        </Button>
                  </Grid>  
                  <Grid item xs={12}>
                  <Button
                  style={{ margin: '0 10px' }}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={this.onCebSkip}>
                          跳转光大进行数字签名
                        </Button>
                  </Grid> 
                </Grid>
                
                  <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
                    <div style={{ position: 'relative' }}>
                      <Button
                        style={{ margin: '0 10px' }}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        提交
                    </Button>
                      {loading && <CircularProgress size={24} style={loadingStyle} />}
                    </div>
                    <Button variant="contained" color="primary" onClick={this.handleSubmit.bind(this, values, 'cancle')}>取消</Button>
                  </div>
              </form>
              

            </div>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);



