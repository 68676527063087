//应收帐管理-贸易合同信息
export default {
  organization_name: "机构名称",
  enterprise_name: '客户名称',
  enterprise_no: '客户编号',
  debt_number: "借据号",
  grant_date: "发放日期",
  expire_date: "到期日期",
  interest_settlement: "结息方式",
  repayment_account: "还款账号",
  debit_interest_query: "欠息客户查询",
  overdue_query: "逾期客户查询",
  distance_expire_clientele: "距离到期的客户",


}
