import React, { Component } from 'react';
import {
  ViewComfy
} from '@material-ui/icons'
import MaterialTable from '../../../vendors/material-table';
import { capitalize, fetching } from '../../../config/utils';
import { core_business_url } from '../core_business_url';
import { C721QueryForm } from '../index'
// import Dialog from './Dialog';
import { r7_2_1, common } from '../formStatic'
import { loginInfos } from '../../../components/numToText'

class View extends Component {
  state = {
    columns: [{}],
    data: [],
    hasToken: sessionStorage.getItem("authToken"),
    dataUrl: { url: { query: '' } },
    formStatic: { ...common },
    auth: {}

  };
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  }
  //获取数据
  componentDidMount() {
    const { table } = this.props;
    let auth = loginInfos();
    this.setState({ auth });
    fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {
        if (data !== undefined && data !==null) {
          const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
          //根据不同的页面，获取不同的接口地址，渲染不同的数据
          const dataUrl = core_business_url(auth, table).find(item => item.name === table);
          const dataUrl2 = dataUrl || {};
          this.setState({ columns, dataUrl: dataUrl2 });
        }
      })
  }

  onRef = (ref) => {
    this.child = ref
  }


  FormSubmit2 = (newData) => {
    newData.formDate = new Date();


  }
  // 对象查找
  findObject = (obj, data) => {
    let index = data.findIndex(item => item.id === obj.id);
    return index;
  }
  //新增
  handleToggle = () => {
    const { formStatic } = this.state
    let status2 = '1'
    this.setState({
      exercise: { ...this.state.exercise, status2, DialogTitle: formStatic.add, save: '1', open: true }
    })
  }


  //查看
  onview = (e, row) => {
    const { formStatic, dataUrl } = this.state
    fetching(dataUrl.url.query + row.id).then(data => {
      this.setState({ exercise: { ...data, save: '3', status2: '', DialogTitle: formStatic.view, open: true, } });
    })

  }

  //搜索框Change
  onSearchChange = (query) => {
    console.log('onSearchChange', query);
  }
  //页面更改事件
  onChangePage = (query) => {
    console.log('onChangePage', query);
  }
  //处理每页的行更改（pageSize）
  onChangeRowsPerPage = (query) => {
    console.log('onChangeRowsPerPage', query);
  }

  render() {
    const { columns, data, dataUrl } = this.state;
    const title = dataUrl.title || '';
    const path = capitalize(this.props.table);
    const actions = actions2(this, this.props.table);
    const table = this.props.table || '';
    //查询财务报表
    const Q721 = () => {
      return <div style={{ justifyContent: 'flex-end' }}>
        <C721QueryForm formStatic={{ ...common, ...r7_2_1 }}
          onSubmit={this.FormSubmit2} />
      </div>
    }



    return <div>
      {(table === 'r7-2-1') && <Q721></Q721>}


      <MaterialTable
        title={title}
        onSearchChange={this.onSearchChange}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        path={path}
        columns={columns}
        data={data}
        actions={actions}
        localization={localization}
        options={options}
      />
    </div>
  }
}
const actions2 = (that, name) => {
  let actions = [];
  switch (name) {
    case 'r13-1-1':
      actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview },];
      break;
    case 'r13-2-3':
      actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview },];
      break;
    default:
      actions = [];
  }
  return actions;
}

const localization = {
  header: {
    actions: '操作'
  }
}
const options = {
  actionsCellStyle: { padding: '0 10px' },
  headerStyle: { //表格头部
    backgroundColor: '#1d4583',
    color: '#FFF',
    whiteSpace: 'nowrap'
  },
  rowStyle: { //表行
    backgroundColor: '#fff',
    whiteSpace: 'nowrap'
  },
  sorting: true,  //排序
  // selection: true //多选框
}
const setView = Comp => (collection, name) => {
  return class extends Component {
    render() {
      return <Comp table={collection} tableName={name} {...this.props} />
    }
  }
}

export const setViewRealTime = setView(View);

