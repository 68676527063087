//应收帐管理-贸易合同信息
export default {
    customer_name: "客户名称",
    contract_number: "合同编号",
    signing_time: "签订时间",
    party_a: "甲方",
    party_b: "乙方",
    contract_name: "合同名称",
    contract_validity: "合同有效期限",
    entry_time: "录入时间",
    receivables_transfer: "应收账款转让对价款",
    pledge_contract_no: "质押合同号",
    notes: "备注"
  }
