//财务报表录入情况明细查询
export default {
    organization_name:'机构名称',
    organization_no:'机构编号',
    enterprise_name:'客户名称',
    enterprise_no:'客户编号',
    start_period:'查询起始期数',
    end_period:'查询终止期数',
    input_time_interval:'报表录入时间区间',
    enterprise_type:'客户类别',
}