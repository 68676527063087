import React, { Component } from 'react';
import Select from 'react-select';
import {
    TextField,
    Button,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Select as Select2,
    InputLabel,
    MenuItem,
    CircularProgress,
    Box,
    LinearProgress,
    Typography
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { Formik } from 'formik';
import { C112Formik } from './formikValidate';
import FormikError from './formikError';
import { fetching } from '../../config/utils';
import { Upload } from '../../components/upload2';
import { formatDate } from '../../components/date'
import { numberfun, commafy, delcommafy } from '../../components/numToText';

const entrustedPaymentList = ['是', '否'];
const currencyList = ['人民币'];
const loadingStyle = {
    position: "absolute",
    color: green[500],
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -17,
}
const useStyles = (theme => ({
    formControl: {
        marginTop: '16px',
        marginBottom: '8px',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    width: '100%',
    label: {
        textAlign: 'left',
    },
    radio: {
        flexDirection: 'row',
    },
}));

const styles = {
    width: '100%',
    margin: '16px 0 8px 0'
}

class Form1 extends Component {
    state = {
        form: this.getInitState(),
        selectCustomerList: [],
        uploads: {},
        loading: false,
    }
    getInitState() {
        const { exercise = {} } = this.props;
        const form2 = {
            select_customer: '',
            enterprise_name: "",
            enterprise_no: "",
            business_license_no: "",
            yearly_check: "是",
            establishment_date: new Date(),
            validity_date_of_license: new Date(),
            expiry_date_of_license: new Date(),
            is_validity: "是",
            yearly_check_date: new Date(),
            business_scope: "",
            registered_assets: "",
            registered_assets_currency: "人民币",
            business_license_file: "",
        }
        if (exercise.save === '1') {
            form2.select_customer = ''
        } else {
            form2.select_customer = { value: exercise.enterprise_name, label: exercise.enterprise_name, enterprise_no: exercise.enterprise_no }
            form2.registered_assets = commafy(exercise.registered_assets)

        }
        return { ...form2, ...exercise }
    }
    uploadFile(e){
            var fordata = new FormData();
            fordata.append('type',5);
            fordata.append('fileName',e.file);
            const options = {
              method: "POST",
              body: fordata
            }
            return fetch(`/msapi/upload/upload`,options)
          }

          uploadHandler = async (e,type) => {
		this.setState({progressLength : 0})
            	this.setState({ progress: true })
            	const timer = setInterval(() => {
            		this.setState({progressLength : (this.state.progressLength >= 99 ? 99 : this.state.progressLength + 1) })
            	}, 30);
            let indexId = '';
            indexId = await this.uploadFile(e).then(res => {
              if (res) {
                if (!res.status){
                  alert(res.msg)         
                }else{
                  return res.json()
                }
              }else{
                return null
              }
            }).then(r=>{ 
             if (r.error.code === 200) {//无错
                clearInterval(timer);
                this.setState({progressLength : 100})
                setTimeout(() => {
                  alert("文件上传成功");
                  this.setState({ progress: false })
                }, 500)
                return r.data
              } else {//业务失败
                alert(r.error.message)
                this.setState({ progress: false })
                return null;
              }
            }) 
            if(type === 1){
              this.state.form.business_license_file_id= indexId 
            }
        return true
    }

    async componentWillMount() {
        const { exercise } = this.props;
        if (exercise.save !== '1') {
            fetching('/msapi/upload/find?license_id=' + exercise.id, { method: 'GET' }).then(data => {
                if (Array.isArray(data) && data.length > 0) {
                    let uploads = {}
                    data.forEach(item => {
                        uploads[item.field] = item.fpath;
                    })
                    this.setState({ uploads })
                }
            })
        }
        if (exercise.save !== '3') {
            this.queryEnterpriseInfo();
        }
    }
    queryEnterpriseInfo = () => {
        fetching('/msapi/customer_info/find', {
            method: 'GET',
        }).then(res => {
            if (res) {
                try {
                    let datas = res.filter((item) => {
                        item.value = item.enterprise_name;
                        item.label = item.enterprise_name;
                        // william:
                        //return (item.id !== 1) ? item : null;
                        return item;
                    })
                    this.setState({ selectCustomerList: datas });
                } catch (error) {
                }
            } else {
            }
        })
    }
    //选择客户
    handleChange2 = (name, selectedOption) => {
        let form = { ...this.state.form }
        form.enterprise_no = selectedOption.enterprise_no
        form.business_license_no = selectedOption.organization_code
        this.setState({ form: { ...form } })
        return selectedOption;
    }
    uploadChange = (file) => {
    };
    //输入框值改变
    handleChange3 = (name, value) => {
        let value2 = value;
        if (name === 'registered_assets') {
            value2 = numberfun(value2, 12);
        }
        return value2;
    }
    //输入框失去焦点 金额 千分位
    handleBlur2 = (name, value) => {
        return commafy(value);
    }
    //保存
    handleSave = (values) => {//
        values.business_license_file_id = this.state.form.business_license_file_id
        this.setState({ loading: true })
        const { auth } = this.props;
        const { save, id } = this.state.form;
        let ids = (save === '2' || save === '3') ? id : '';
        let method = (save === '2' || save === '3') ? 'POST' : 'POST';
        values.enterprise_no = this.state.form.enterprise_no || ''
        values.business_license_no = this.state.form.business_license_no || ''
        values.enterprise_name = values.select_customer.value || ''
        let dateList = ['establishment_date', 'validity_date_of_license', 'expiry_date_of_license', 'yearly_check_date']
        //日期转换
        dateList.forEach((item) => {
            if (values[item] instanceof Object && values[item].getTime()) {
                values[item] = formatDate(values[item], 'yyyy/MM/dd');
            }
        })
        values.auth = auth;//用户信息
        const amountList = ['registered_assets'];
        amountList.forEach(item => {
            values[item] = delcommafy(values[item]);
        })
        if(values.business_license_file_id !==null && values.business_license_file_id !==0){
            values.business_license_file = true;
        }else{
            values.business_license_file = false;
        }
        
        /*const formData = new FormData();
        const excludes = ['createdAt', 'updatedAt', 'checkOpen', 'role_name', 'status', 'id', 'Uploads', 'select_customer', 'tableData', 'save', 'DialogTitle', 'status2', 'open', 'User', 'Role', 'Status', 'Organization', 'UserId', 'OrganizationId', 'StatusId', 'RoleId'];
        for (let name in values) {
            if (!excludes.includes(name) && values[name] && values[name] !== 'null') formData.append(name, values[name]);
        }
        formData.id = values.id*/
        // POST
        fetching(`/msapi/license/save/${ids}`, {
            method,
            body: JSON.stringify(values),
        }).then((data) => {
            this.setState({ loading: false })
            try {
                if(data){
                    if(data.msg){
                        alert(ids ? `编辑失败，${data.msg}` : `新增失败，${data.msg}` )
                        return;
                    }
                    this.props.onSubmit({
                        ...this.state.form,
                    })
                }else {
                    alert(ids ? `编辑失败` : `新增失败` )
                }

            } catch (error) {}
        })
    };

    render() {
        const { exercise = {}, formStatic, classes } = this.props;
        const { form, selectCustomerList, uploads, progress, progressLength } = this.state;

        return (
            <Formik
                //初始化数据
                initialValues={{
                    ...form
                }}
                //表单验证
                validate={(values) => {
                    return C112Formik(values, formStatic);
                }}
                //验证提交
                onSubmit={(values) => {
                    this.handleSave(values);
                }}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                        <form method="post" encType="multipart/form-data">
                            <Grid item>
                                <div style={{ margin: '5px 0' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_customer}</span>
                                    <Select
                                        isSearchable
                                        value={values.select_customer}
                                        onChange={e => setFieldValue('select_customer', this.handleChange2.bind(this, 'select_customer', e)())}
                                        options={selectCustomerList}
                                        isDisabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'select_customer'} />
                                </div>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.enterprise_no}
                                        value={form.enterprise_no}
                                        name='enterprise_no'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.business_license_no}
                                        value={form.business_license_no}
                                        name='business_license_no'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    {/* <FormikError touched={touched} errors={errors} name={'business_license_no'} /> */}
                                </Grid>
                            </Grid>
                            <Grid container justify="space-between" >
                                <Grid item xs={5}>
                                    <FormControl component="fieldset" style={styles} className={classes.radio}>
                                        <FormLabel component="legend">{formStatic.yearly_check}</FormLabel>
                                        <RadioGroup className={classes.radio} style={{ flexDirection: 'row' }} aria-label="gender" name='yearly_check' value={values.yearly_check}
                                            onChange={e => setFieldValue('yearly_check', e.target.value)}>
                                            {entrustedPaymentList.map(item => {
                                                return (
                                                    <FormControlLabel
                                                        key={item}
                                                        name={item}
                                                        value={item}
                                                        disabled={exercise.save === '3'}
                                                        control={<Radio />}
                                                        label={item} />
                                                )
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <FormikError touched={touched} errors={errors} name={'yearly_check'} />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item xs={5}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label={formStatic.establishment_date}
                                            disabled={exercise.save === '3'}
                                            value={values.establishment_date}
                                            onChange={e => setFieldValue('establishment_date', e)}
                                            onBlur={handleBlur}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        <FormikError touched={touched} errors={errors} name={'establishment_date'} />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid container justify="space-between">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="space-between">
                                        <Grid item xs={5}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="yyyy/MM/dd"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label={formStatic.validity_date_of_license}
                                                disabled={exercise.save === '3'}
                                                value={values.validity_date_of_license}
                                                onChange={e => setFieldValue('validity_date_of_license', e)}
                                                onBlur={handleBlur}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                            <FormikError touched={touched} errors={errors} name={'validity_date_of_license'} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="yyyy/MM/dd"
                                                margin="normal"
                                                disabled={exercise.save === '3'}
                                                id="date-picker-inline"
                                                label={formStatic.expiry_date_of_license}
                                                value={values.expiry_date_of_license}
                                                onChange={e => setFieldValue('expiry_date_of_license', e)}
                                                onBlur={handleBlur}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                            <FormikError touched={touched} errors={errors} name={'expiry_date_of_license'} />
                                        </Grid>

                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <Grid container justify="space-between" alignItems='flex-end' >
                                        <Grid item xs={10}>
                                            <TextField
                                                label={formStatic.registered_assets}
                                                value={values.registered_assets}
                                                name='registered_assets'
                                                onChange={e => setFieldValue('registered_assets', this.handleChange3.bind(this, 'registered_assets', e.target.value)())}
                                                onBlur={e => setFieldValue('registered_assets', this.handleBlur2.bind(this, 'registered_assets', e.target.value)())}
                                                margin='normal'
                                                inputProps={{ maxLength: 50 }}
                                                fullWidth
                                                disabled={exercise.save === '3'}
                                            />
                                        </Grid>
                                        <Grid item xs={1} style={{ marginBottom: '8px' }}>元</Grid>
                                    </Grid>
                                    <FormikError touched={touched} errors={errors} name={'registered_assets'} />
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl style={styles} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{formStatic.registered_assets_currency}</InputLabel>
                                        <Select2
                                            id="demo-simple-select"
                                            value={values.registered_assets_currency}
                                            disabled={exercise.save === '3'}
                                            onChange={e => setFieldValue('registered_assets_currency', e.target.value)}>
                      {currencyList.map(item => {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            })}
                                        </Select2>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'registered_assets_currency'} />
                                </Grid>
                            </Grid>
                            <Grid container justify="space-between" >
                                <Grid item xs={5}>
                                    <FormControl component="fieldset" style={styles} className={classes.radio}>
                                        <FormLabel component="legend">{formStatic.is_validity}</FormLabel>
                                        <RadioGroup className={classes.radio} style={{ flexDirection: 'row' }} aria-label="gender" name='is_validity' value={values.is_validity}
                                            onChange={e => setFieldValue('is_validity', e.target.value)}>
                                            {entrustedPaymentList.map(item => {
                                                return (
                                                    <FormControlLabel
                                                        key={item}
                                                        name={item}
                                                        value={item}
                                                        disabled={exercise.save === '3'}
                                                        control={<Radio />}
                                                        label={item} />
                                                )
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'is_validity'} />
                                </Grid>

                                <Grid item xs={5} style={{ textAlign: 'left' }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label={formStatic.yearly_check_date}
                                            disabled={exercise.save === '3'}
                                            value={values.yearly_check_date}
                                            onChange={e => setFieldValue('yearly_check_date', e)}
                                            onBlur={handleBlur}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        <FormikError touched={touched} errors={errors} name={'yearly_check_date'} />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <Grid container justify="space-between">
                                <TextField
                                    multiline
                                    rows='4'
                                    label={formStatic.business_scope}
                                    value={values.business_scope}
                                    name='business_scope'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    margin='normal'
                                    fullWidth
                                    disabled={exercise.save === '3'}
                                />
                                <FormikError touched={touched} errors={errors} name={'business_scope'} />
                            </Grid>
                            {/* fileList={[form.business_license_file]} */}
				{/* 上传进度条 */}
                            { progress &&
                                <div>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress variant="determinate"  value={progressLength}/>
                                </Box>
                                <Box sx={{ minWidth: 35 }}>
                                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                                    progressLength
                                    )}%`}</Typography>
                                </Box>
                                </Box>
                                </div>
                                }
                            <Grid container justify="space-between">
                                <Upload disabled={exercise.save === '3'}
                                    name={formStatic.business_license_file}
                                    uploads={uploads.business_license_file}
                                    uploadName='business_license_file' view={formStatic.view}
				    exercise={exercise}
                                    mold = "5"
                                    uploadChange={e => setFieldValue('business_license_file', this.uploadHandler(e, 1))}>
                                    </Upload>
                            </Grid>
                            <div style={{ margin: '20px 0', position: 'relative' }}>
                                {exercise.save !== '3' &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={this.state.loading}
                                    >
                                        {formStatic.save}
                                        {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
                                    </Button>
                                }
                            </div>
                        </form>
                    )}
            />
        );
    }
}

export default withStyles(useStyles)(Form1);
