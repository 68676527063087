import React, { Component } from 'react'
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  TextField,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import FormikError from './formikError';
import { accountFormik } from './formikValidate' //校验修改后的数据


const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }
}));


const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}

class Form extends Component {
  state = {
    form: this.getInitState(),
    form2: this.props.form2 || {},//静态资源 
    loading: false,
  }


  getInitState() {
    const { ...exercise } =  this.props.exercise
    const form = exercise.data || {}
    //form.DialogTitle = "创建用户"
    return { ...form, ...exercise.data }
  }
   
 
  

  //保存 提交
  handleSubmit = (form2, name) => {
    let form = form2 || this.state.form;
    const text = name || '撤销';
    if (text !== '提交') {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
      return;
    }
    this.setState({ loading: true })
    let method = this.state.form.save === '2' ? 'POST' : 'POST';
   // 提交表单
    if (text === '提交') {
      fetching(`/msapi/account/updateBank`, {
        method,
        body: JSON.stringify(form)
      }).then((data) => {
        this.setState({ loading: false })
        try {
          if (data !== undefined && data !==null) {
            if (data.msg) {
              alert(data.msg)
              return;
            }
            this.props.onSubmit({
              ...form,
              submit_name: text
            })
          }else {
            alert('提交失败')
          }
        } 
        catch (error) { console.log(error) }
      })
    } else {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
    }
  }


  render() {
    const form2 = this.state.form2;
    const form = this.state.form;
    const { exercise } = this.props;
    const status = (handleSubmit, isSubmitting, values) => {
      return<div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
      <div style={{ position: 'relative' }}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          disabled={this.state.loading}
          name={form2.submit}
          style={{ marginRight: '10px' }}
        >
          {form2.submit}
          {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
        </Button>
      </div>
        <Button
          color='primary'
          variant='contained'
          onClick={this.handleSubmit.bind(this, values, '撤销')}
          name={form2.revocation}
          disabled={isSubmitting}
          style={{ marginRight: '10px' }}
        >
          {form2.revocation}
        </Button>
      </div>
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        //校验修改后的数据
        validate={(values) => {
          return accountFormik(values, form2);
        }}
        onSubmit={(
          values
        ) => {
          this.handleSubmit(values, '提交');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
            <form>
              <Grid container >
                <TextField
                  label={form2.enterpriseName}
                  value={values.enterpriseName || ''}
                  name='enterpriseName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '2'}
                />
                <FormikError touched={touched} errors={errors} name={'enterpriseName'} />
              </Grid>

              <Grid container >
                <TextField
                  label={form2.bank}
                  value={values.bank || ''}
                  name='bank'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{ maxLength: 50 }}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'bank'} />
              </Grid>

              <Grid container>
                <TextField
                  label={form2.bankAccount}
                  value={values.bankAccount || ''}
                  name='bankAccount'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'bankAccount'} />
              </Grid>
		
	      <Grid container>
                <TextField
                  label={form2.bank_number}
                  value={values.bank_number || ''}
                  name='bank_number'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'bank_number'} />
              </Grid>

              {
                status(handleSubmit, isSubmitting, values)
              }
            </form>
          )}
      />
    )
  }
}
export default withStyles(useStyles)(Form);

