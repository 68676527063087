import React, { Component } from 'react'
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  TextField,//20201228
  Button,
  Grid,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  CircularProgress,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { DEFAULT_EXPORT_URL } from '../../config/constants'


global.count = 0;
const useStyles = (theme => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 280,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    form: this.getInitState(),
    formStatic: this.props.formStatic || {},//静态资源
    selectCustomerList: [],
    checked: [],
    left: [],
    right: this.getInitRight(),
    leftChecked: [],
    rightChecked: [],
    leftSelect: [],//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
    rightSelect: [],//企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228
    leftName: '',//[备选企业]搜索关键字--20201228
    rightName: '',//[选中企业]搜索关键字--20201228
    loading: false,
  }
  async componentWillMount() {
    const { exercise = {} } = this.props;

    if (exercise.save !== '3') {
      this.queryEnterpriseInfo(exercise.mx_number);
    } else {
      //企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228
      this.setState({ rightSelect: this.state.right });
    }
  }

  getInitState() {
    const { exercise = {} } = this.props
    const form = {
      select_customer: '',
      enterprise_name: "",
      mx_number: "",
      data: [],
      //form_no: '2',
    }
    if (exercise.save !== '1') {
      form.select_customer = {
        value: exercise.enterprise_name,
        label: exercise.enterprise_name,
        mx_number: exercise.mx_number,
        enterprise_name: exercise.enterprise_name
      }
    }
    return exercise.save === '1' ? { ...form } : { ...form, ...exercise }
  }
  getInitRight() {
    const { exercise = {} } = this.props;
    if (exercise.save !== '1' && exercise.subsidiaries instanceof Array) {
      let subsidiaries = exercise.subsidiaries.map((item) => {
        let items = {
          id: item.sub_company_id,
          enterprise_name: item.sub_company_name,
          mx_number: item.sub_company_no
        }
        return items
      })
      return subsidiaries
    }
    return []
  }

  queryEnterpriseInfo = (mx_number) => {
    const { exercise = {} } = this.props;
    
      if (exercise.data) {
        try {
          let left = [];
          let datas = exercise.data.filter((item) => {
            if (item.fdd_xxb_name === null) {
              return item
            }
            item.value = item.mx_number  + item.extraction;
            item.label = item.mx_number + item.extraction ;
            item.enterprise_name = item.mx_number + item.extraction ;
            if ( true ) {
              let obj = {};
              obj.id = item.id
              obj.mx_number = item.mx_number
              obj.fdd_xxb_name = item.fdd_xxb_name
              obj.enterprise_name = item.mx_number +"-"+ item.extraction +"-"+ item.amount + "(元)" 
              left.push(obj)
            }
            return  item ;
            
          })
          let leftSelect = left;//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
          this.setState({ selectCustomerList: datas, left, leftSelect });
        } catch (error) {
        }
      } else {
      }
    
  }

  //企业管理模块关联企业时,[备选企业]/[选中企业]可以按照名字搜索分公司名字--20201228
  handleInputChange = (name, value) => {
    let leftSelect = this.state.left;//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
    let rightSelect = this.state.right;//企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228

    let leftName = "";//[备选企业]搜索关键字--20201228
    let rightName = "";//[选中企业]搜索关键字--20201228
    
    if (name === 'leftName') {//选择[备选企业]
      if (null !== value && value !== "") {
        leftName = value;//[备选企业]搜索关键字--20201228
        leftSelect = this.state.left.filter((item) => {
          if ( item.enterprise_name.includes(value) ) {
            return item;
          }
          return null;
        })
      }
      this.setState({ leftSelect, leftName });//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
    } else if (name === 'rightName') {//选择[选中企业]
      if (null !== value && value !== "") {
        rightName = value;//[选中企业]搜索关键字--20201228
        rightSelect = this.state.right.filter((item) => {
          if ( item.enterprise_name.includes(value) ) {
            return item;
          }
          return null;
        })
      }
      this.setState({ rightSelect, rightName });//企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228
    }
  }
    
  
  
  
  //输入框值改变
  handleChange3 = (name, value) => {
    let value2 = value;
    if (name === 'select_customer') {
      this.setState({ checked: [], leftChecked: [], rightChecked: [], right: [] })
      this.queryEnterpriseInfo(value.mx_number)
    }
    return value2;
  }

  
  handleToggle = (value) => {
    const { checked, left, right } = this.state;
    const currentIndex =  -1;//this.findObject(value, checked);
    let newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (this.state.form.data.length === 0) {
      this.state.form.data.push(value);
    } else {
      this.props.exercise.CreditSource = true;
      for (var i = 0; i < this.state.form.data.length; i++) { 
        if (this.state.form.data[i].id === value.id) {
          if ( i > -1) { 
            this.state.form.data.splice(i, 1); 
            this.props.exercise.CreditSource = false;
            }
        }
    }
    if (this.props.exercise.CreditSource) {
      this.state.form.data.push(value);
     }
    }
    
    this.setState({ values: this.state.form.data })

    
  };


  //保存 提交
  handleSubmit = (values, name) => {
    const text = name || 'cancle';
    if (values.data.length === 0 && text === 'submit') {
      alert("请选择单据")
      return;
    }
    if (text === 'submit') {
      this.setState({ loading: true })
    }
    //提交表单
    if (text === 'submit') {
      fetching(`/dzqz/downloadXXBList`, {
        method: 'POST' ,
        body: JSON.stringify(values.data)
      }).then((res) => {
        this.setState({ loading: false })
        try {
          if (res) {
            window.location.href = DEFAULT_EXPORT_URL + 'dzqz/getdownloadXXBList?zipFilePath='+res.data
	    setTimeout(() => {
              window.location.reload();
            }, 1000);
          }else {
            alert('提交失败')
            return;
          }
        } catch (error) {
          console.log(error)
         
        }
      })
    } else {
      window.location.reload();
    }
  }
  //审核意见
  FormSubmit3 = (data) => {
    this.props.onSubmit({
      ...data,
      submit_name: 'submit'
    })
  }

  render() {
    const { form, loading, leftSelect, rightSelect, leftName, rightName, } = this.state
    const { exercise, classes, auth, dataUrl } = this.props;
    // const checkform = () => {
    //   return <CheckForm auth={auth} exercise={exercise} dataUrl={dataUrl}
    //     onSubmit={this.FormSubmit3}
    //   />
    // }
    const customList = items => (
     
        <List dense component="div" role="list">
          {items.map(value => {
            const labelId = `transfer-list-item-${value.mx_number}-label`;
            return (
              <ListItem key={value.id} role="listitem" button onClick={this.handleToggle.bind(this, value)}>
                <ListItemIcon>
                  <Checkbox
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.enterprise_name} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
    );
    return (
      <Formik
        initialValues={{
          ...form
        }}
        // validate={(values) => {
        //   return values;
        // }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, 'submit');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
            <div>
              <form>
                <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                  <Grid item xs={12}>
                  <div style={{ margin: '10px' }}>
                    <Typography variant="body2" gutterBottom>待下载</Typography>
                    <TextField
                      id="outlined-margin-dense"
                      placeholder="select..."
                      defaultValue=""
                      className={classes.textField}
                      value={values.leftName}
                      onChange={e => setFieldValue('leftName', this.handleInputChange.bind(this, 'leftName', e.target.value)())}
                      disabled={exercise.save === '3'}
                      margin="dense"
                      variant="outlined"
                    />
                    {customList(leftSelect)}
                    </div>
                  </Grid>
                
                </Grid>
                {exercise.save !== '3' &&
                  <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
                    <div style={{ position: 'relative' }}>
                      <Button
                        style={{ margin: '0 10px' }}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        提交
                    </Button>
                      {loading && <CircularProgress size={24} style={loadingStyle} />}
                    </div>

                    <Button variant="contained" color="primary" onClick={this.handleSubmit.bind(this, values, 'cancle')}>取消</Button>
                  </div>
                }
              </form>
              

            </div>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);



