import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import MaterialTable from '../../../src/vendors/material-table' //'../vendors/material-table';
import {

  Typography,
  ExpansionPanelDetails,
  List,
  Grid,
  TextField,
  Table,
 
} from '@material-ui/core'

import {
  AlternateEmail,
  Edit  
} from '@material-ui/icons'


import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { fetching } from '../../config/utils';
import { form_signup3 } from '../../../src/signup/formStatic'//'../formStatic'src/signup/formStatic/index
import { DEFAULT_ACCOUNT_URL } from '../../config/constants' //支付注册路径
import { loginInfos } from '../../../src/components/numToText'; //当前登录用户
const useStyles = (theme => ({
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    marginRight: '0',
    display: 'flex',
    alignItems: 'flex-end',
  },
  field: {
    width: '100%',
  },
  errorMsg: {
    color: '#f00',
    textAlign: 'center',
    height: '20px',
    cursor: 'pointer',
  },
  left: {
    textAlign: 'left',
  }
}));
const styles = {
  textAlign: 'left',
  margin: '0 10px',
  // lineHeight: '30px'
}

class accountForm extends Component {
  state = {
    form1: {
      credit_no: '',
    },
    active: '',//记录当前是否注册状态
    enterpriseCertificationStatus: '',//前往注册和帮卡
    personCertificationStatus: '',//已经注册了绑卡或者没有分配市场
    band:'',//记录市场绑定状态
    banding: '',//市场绑定
    loginInfo:[],//当前登录信息
    listCers: [form_signup3.certification_type1],
    open: false,
    imgUrl: "",
    open_id: localStorage.getItem("registerForm1") ? JSON.parse(localStorage.getItem("registerForm1")).cell : '',
    seal_person_url: '',//法人章
    message: '',
    value: '',
    data: [], 
    auth: {},
    columns : [
      {
        title: '公司名称',
        field: 'accountName',
    
      },
      {
        title: '卡状态',
        field: 'state'
      },
      {
        title: '市场编号',
        field: 'marketCode'
      },

      {
        title: '备注',
      },
    ],
   
    bankCard: {
      branchBankCode: "",
      bankCardNo: "",
      bankDetailName: "",
      bankName: "",
    },
  }

  // async componentWillMount() {
  //   this.queryEnterpriseInfo();
  //   this.queryPersonInfo();
  //   this.queryAuthorization();
  // }

  //如果有异步设置state值的情况，在组件销毁时清除所有的state状态
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }

  viewAgreement = () => {
    this.setState({ open: false });
  }
  getCheckStatus = (val) => {
    let msg1;
    let msg2;
    switch (val) {
      case '0':
        msg1 = '请进行支付注册绑卡'
        msg2 = '已经注册和绑卡'
        break;
      default:
        msg1 = '正在实名认证中'
        msg2 = '正在实名认证中'
    }
    return { msg1: msg1, msg2: msg2, }
  }
  getCertType = (val) => {
    let msg1;
    switch (val) {
      case '0':
        msg1 = '身份证'
        break;
      case '1':
        msg1 = '护照'
        break;
      case 'B':
        msg1 = '香港居民来往内地通行证'
        break;
      case 'C':
        msg1 = '台湾居民来往大陆通行证'
        break;
      default:
        msg1 = ''
    }
    return msg1
  }



  //初始化看该企业是否已经在清算平台注册过
  async componentDidMount() {
     this.getPhoneByAccount();
     //this.getRegisterBank();
  }

 //查看当前用户是否已经进行了注册与绑卡
  getPhoneByAccount = () => {
    //通过当前登录手机号去数据库关联查询
    //携带企业名称
    let loginInfo = loginInfos();
    //loginInfo
    this.setState({ loginInfo: loginInfo});
    let name=loginInfo.organization.name //公司名称
    //社会统一信用代码
    let creditNo=loginInfo.organization.credit_no
    //获得organizationid
    let organizationId=loginInfo.organization.id
    fetching('/msapi/account/findCompanyStatus?name='+name,{
      method: 'GET',
    }).then(res => {
     if (res) {
      if(res.data !== '0' && res.data !== '2' ){
        if(res.data ==="1"){
        this.setState({ active: '1'});
        this.setState({ personCertificationStatus: '请进行支付账号信息注册与绑卡点击前往>>'});
       } else if (res.data ==="3"){
        this.setState({ active: '3'});
       }
      } else{
      fetching('/msapi/account/getOfAccount?name='+name+'&organizationId='+organizationId+'&creditNo='+creditNo, {
        method: 'GET',
      }).then(res => {
            if (res) {
              try {
                  //未注册
                  if(res.data==null){
                  this.setState({ active: '0'});
                  }
                  //已注册
                  if(res.data!=null){
                    if (res.data === '0') {
                      this.setState({ active: '1'});
                      this.setState({ personCertificationStatus: '注册与绑卡已经成功'});
                      fetching( '/msapi/account/getOfMarket?name='+name+'&organizationId='+organizationId,{
                        method: 'GET',
                      }).then(data => {
                        if(data !== null || data !== "undefined"){
                        if (data.marketCode !== ""){
                        this.setState({     band : '1'});
                        this.setState({     banding : '已绑定'});
                        } else {
                          alert("注册与绑卡已经成功,请进行开通市场操作");
                        }
                      }
                      })
                    }else if(res.data === '2'){
                      this.setState({ active: '2'});
                    }
                  }
              } catch (error) {
              }
          }
       });
      }
      }
      
 });
    
/*
   //查找已经绑定的卡
   fetching(`/account/findAll?name=`+name, {
    method: 'get',
    }).then(res => {
      if (res.data) {
        this.setState({ data: res.data })
        //const columns = Object.keys(res.data).map(c => ({ title: res.data[c], field: c }));
      } else {
        alert(res.msg)
      }
      });*/
  }

 


  //第一次前往注册绑卡
  handleCertification = () => {
    alert("在清算平台注册时,请务必保证企业名称与当前注册公司名称保持一致")
    window.open(`${DEFAULT_ACCOUNT_URL}`);   //20210401 
  };

   
  //绑定市场 20200429
  bindingMarket=()=>{

    let loginInfo = loginInfos();
    //loginInfo
    this.setState({ loginInfo: loginInfo});
    let name=loginInfo.organization.name //公司名称
    //社会统一信用代码
    let creditNo=loginInfo.organization.credit_no
    //获得organizationid
    let organizationId=loginInfo.organization.id
    fetching('/msapi/account/saveMarket?name='+name+'&organizationId='+organizationId+'&creditNo='+creditNo, {
      method: 'GET',
    }).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          alert(res.msg)
          window.location.reload()
       } else {
         alert(res.msg)
         return;
       }
      } else {
        return;
      }
   });
  }


    //获取企业实名认证信息
    queryEnterpriseInfo = () => {
    // fetching('/dzqz/findCompanyCertInfo', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     open_id: this.state.open_id,
    //   })
    // }, 2).then(res => {
    //   if (res.code === 200) {
    //     try {
    //       const { bankCard, manager, company } = res.data;
    //       this.setState({ bankCard: bankCard, manager: manager, company: company })
    //       let msg = this.getCheckStatus(company.status).msg1
    //       this.setState({ enterpriseCertificationStatus: msg })
    //     } catch (error) {}
    //   } else if (res.code === 400) {
    //     alert(res.msg)
    //   } else {
    //     this.setState({ enterpriseCertificationStatus: '请进行企业实名认证' })
    //   }
    // })
  }

  //个人实名认证
  handlePersonCertification = () => {
    // fetching('/dzqz/getPersonVerifyUrl', {
    //   method: 'post',
    //   body: JSON.stringify({ open_id: this.state.open_id })
    // }, 2).then(res => {
    //   if (res.data) {
    //     window.open(res.data.url)
    //   } else {
    //     alert(res.msg)
    //   }
    // });
  };


  //查询自动授权签署
  queryAuthorization = () => {
    // fetching(`/dzqz/company?open_id=${this.state.open_id}`, {
    //   method: 'get',
    //  }, 2).then(res => {
    //   if (res.data) {
    //     const { is_authorization, is_personage_authorization } = res.data
    //     this.setState({ authorization: { is_authorization, is_personage_authorization } })
    //   } else {
    //     alert(res.msg)
    //   }
    // });
  };

  //获取个人实名认证信息
  queryPersonInfo = () => {
    // fetching('/dzqz/findPersonCertInfo', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     open_id: this.state.open_id,
    //   })
    // }, 2).then(res => {
    //   if (res.code === 200) {
    //     try {
    //       const { person } = res.data;
    //       let msg = this.getCheckStatus(person.status).msg2
    //       let certType = this.getCertType(person.certType)
    //       this.setState({ person: { ...person, certType }, personCertificationStatus: msg })
    //     } catch (error) { }
    //   } else {
    //     this.setState({ personCertificationStatus: '请进行法人实名认证1111' })
    //   }
    // })
  }

  authorization = (e, value) => {
    // let url = ''
    // if (value === 1) {
    //   url = `/dzqz/authorization?open_id=${this.state.open_id}&differentiate=personage`
    // } else {
    //   url = `/dzqz/authorization?open_id=${this.state.open_id}`
    // }
    // fetching(url, {
    //   method: 'GET',
    // }, 2).then(res => {
    //   if (res.code === 200) {
    //     try {
    //       window.open(res.data)
    //     } catch (error) { }
    //   } else {
    //     alert(res.msg)
    //   }

    // })
  }

 //表格编辑
onUpdate2 = (e, row) => {
   //对银行卡进行解绑 
   if(row.active === 1){
    alert('该银行卡已解绑');
    return;
   }

  fetching(`/msapi/account/unCard?id=`+row.id, {
      method: 'get',
     }).then(res => {
      if (res !== undefined && res.data) {
        
      } else {
        alert(res.msg)
      }
    });
 }

 onRef = (ref) => {
  this.child = ref
}


//搜索框Change
onSearchChange = (query) => {
  console.log('onSearchChange', query);
}
//页面更改事件
onChangePage = (query) => {
  console.log('onChangePage', query);
}

//处理每页的行更改（pageSize）
onChangeRowsPerPage = (query) => {
  console.log('onChangeRowsPerPage', query);
}

  render() {
    //const { activeStep, classes } = this.props;
    const { active ,personCertificationStatus,banding,loginInfo,band,columns,data,auth} = this.state;
    const editable = editable2(this, data);
    return (
      <form >
        <Typography variant="body1" gutterBottom>
         <h2>支付账号信息注册与绑卡</h2>
        </Typography>
          <div align="left" >
          <ExpansionPanelDetails >
              <List style={{ width: '50%' }}>
                <Typography style={{ whiteSpace: 'normal' }} >
                  {`公司：${loginInfo.name}`}
                </Typography>
                <Typography  style={{ whiteSpace: 'normal' }}>
                  {`账号：${loginInfo.account}`}
                </Typography>
                <Typography  style={{ whiteSpace: 'normal' }}>
                  {`电话：${loginInfo.phone}`}
                </Typography>
                <Typography  style={{ whiteSpace: 'normal' }}>
                  {'开户状态:'}
                  {(active === '0'  ) && 
                   <span style={{ paddingLeft: '10px', color: '#f00', cursor: 'pointer', textDecoration: 'underline' }}  onClick={this.handleCertification}>{'请进行支付账号信息注册与绑卡点击前往>>'}</span>
                  }
                  {(active === '1' ) &&
                     <span style={{ paddingLeft: '10px', color: '#f00', cursor: 'pointer', textDecoration: 'underline' }} onClick={this.handleCertification}>{'请进行支付账号信息注册与绑卡点击前往>>'}</span>
                  }
                  {(active === '3' ) &&
                     <span style={{ paddingLeft: '10px', color: '#090', cursor: 'pointer', textDecoration: 'underline' }} onClick={this.handleCertification}>{'请重新进行支付平台注册>>'}</span>
                  } 
                   
                </Typography>
                <Typography  style={{ whiteSpace: 'normal' }}>
                  {'市场绑定状态:'}
                  {(active ===  '1' && band === "" ) &&
                     <span style={{ paddingLeft: '10px', color: '#f00', cursor: 'pointer', textDecoration: 'underline' }}  onClick={this.bindingMarket}>{'请点击进行绑定市场>>'}</span>
                  }
                  {(active ===  '0' ) &&
                     <span style={{ paddingLeft: '10px', color: '#f00', cursor: 'pointer', textDecoration: 'underline' }}  >{'未绑定>>'}</span>
                  } 
                  {(band ===  '1' ) &&
                     <span style={{ paddingLeft: '10px', color: '#090', cursor: 'pointer', textDecoration: 'underline' }}>  {banding}</span>
                  }
                  </Typography>
              </List>
            </ExpansionPanelDetails>
          </div>   
          <div  style={{ width: '100%' }} >
            {/* <MaterialTable
                 title='绑卡信息'
                 onSearchChange={this.onSearchChange}
                 onChangePage={this.onChangePage}
                 onChangeRowsPerPage={this.onChangeRowsPerPage}  
                 columns={columns}
                 data={data}
                 actions={actions2(this, auth)}
                 editable={editable}
                 localization={localization}
                 options={options}
            >
            </MaterialTable>  */}
          </div>
      </form>
    );
    
  }
}
const editable2 = (that, name) => {
  let editable = {};
  editable = { onRowAdd: that.onAdd, };
  return editable;
}

const actions2 = (that, auth) => {
  let actions = [];
  actions = [{ icon: Edit, tooltip: '解绑银行卡', onClick: that.onUpdate2 }];
   return actions;
}


const localization = {
  header: {
    actions: '操作'
  }
}

const options = {
  actionsCellStyle: { padding: '0 10px' },
  headerStyle: { //表格头部
    backgroundColor: '#1d4583', //'#039be5',
    color: '#FFF',
    whiteSpace: 'nowrap'
  },
  rowStyle: { //表行
    backgroundColor: '#fff',
    whiteSpace: 'nowrap'
  },
  sorting: true,  //排序
  exportButton: true,
}

export default withStyles(useStyles)(accountForm);
