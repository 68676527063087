export const SIGNUP_SUCCESS = '注册成功';
export const SIGNUP_FAIL = '注册失败';

export const LOGIN_SUCCESS = '登录成功';
export const LOGIN_FAIL = '登录失败';
export const LOGOUT_SUCCESS = '退出成功';
export const LOGOUT_FAIL = '退出失败';

export const ADD_USER = '添加一个用户';
export const GET_USER = '获取特定用户信息';
export const GET_USERS = '获取所有用户信息';
export const UPDATE_USER = '更新用户信息';
export const DELETE_USER = '删除用户';

// alias: menu1
export const GET_CORE_BUSINESS_SUPPLIER_LAYOUT = '获取核心企业/供应商布局数据';

// alias: menu2
export const GET_RISK_MANAGEMENT_LAYOUT = '获取风险控制布局数据';

// in case encType="multipart/form-data"
export const HEADERS = {
  "Accept": "application/json",
  "Content-Type": "application/json; charset=UTF-8",
  'Origin':"https://www.erongdan.com"
};
