// 风险管理模块用到的审核表单——dxm
import React, { Component } from 'react'
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { fetching } from '../../config/utils';
import { check } from '../../components/commonForm/check'
import {
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import FormikError from '../../components/commonForm/formikError';
import SimpleExpansionPanel from '../../components/commonForm/Panels'
import { checkFormik } from '../../components/commonForm/formikValidate';

const useStyles = (theme => ({
  radio: {
    flexDirection: 'row',
  },
}));
const styles = {
  width: '100%',
  margin: '16px 0 8px 0'
}
const List = [{ id: '3', text: '同意' }, { id: '2', text: '退回' },]
class CheckForm extends Component {
  state = {
    form: this.getInitState(),
    form2: { ...check },
    userList: [
      { id: 1, name: '退回上一级' },
      { id: 2, name: '退回经办人' },
    ],
    auth: this.props.auth || {}
  }

  getInitState() {
    const { exercise, } = this.props
    const form = {
      opinion_list: [],
      ...exercise,
      check_opinion: '',
      returnBy: '退回上一级',
      form_no: '3',
      isAllowQA: true,
    }

    return form
  }
  componentWillMount() {
    const { url } = this.props.dataUrl
    fetching(url.get2 + `${this.props.exercise._id}`).then(data => {
      this.setState({
        form: { ...this.state.form, opinion_list: data }
      })
    })
    let urlCredit = `/amount/usedAmount/getCreditAmountsList?id=`;
    fetching(urlCredit + `${this.props.exercise.sub_company_id}`).then(data => {
      if (null !== data && data.data.length !== 0){
        alert("客户有来源额度申请的有效额度,不能在进行额度分配!!!")
        this.state.form.isAllowQA= false;
      }
    })
  }

  // 提交
  handleSubmit = (form2, name) => {
    const { url } = this.props.dataUrl
    const { table } = this.props.exercise
    const { auth } = this.state;
    const form = form2 || this.state.form;
    const text = name || 'cancle';
    //参数
    let body = {
      auth,//用户信息
      check_opinion: form.check_opinion,//审核意见
      audit_status: form.status_id || '',
      form_no: form.form_no,//2:退回，3：通过
      returnBy: form.returnBy,//退回上一级、退回经办人
    }
    if (table === 'r15-1' || table === 'r15-2') {
      body.irid = this.props.exercise._id
    } else if (table === 'r5-1') {
      body.bm_id = this.props.exercise._id
    } else if (table === 'r5-2') {
      body.qa_id = this.props.exercise._id
    } else if (table === 'r5-3') {
      body.qr_id = this.props.exercise._id
    }
    let isAllow = this.state.form.isAllowQA;
    if (!isAllow && form.form_no !== "2") {
      alert("客户有来源额度申请的有效额度,请进行退回操作!!!")
      this.props.onSubmit({
        //...e,
        submit_name: text
      })
      return null;
    }
    //提交表单的url
    let url1 = url.get3
    if (text !== 'cancle') {
      fetching(url1, {
        method: 'POST',
        body: JSON.stringify(body)
      }).then(e => {
                //额度分配or额度收回审批完成，连接java的/usedAmount/handleQaAndQc接口处理数据
                 if ((table === 'r5-2'  && auth.roles[0].id === 8 && form.form_no !== "2") 
                      || (table === 'r5-3' && auth.roles[0].id === 3 && form.form_no !== "2")) {
                  var qAC = this.props.exercise;
                  var typeAC = 0;//1-额度分配;2-额度收回
                  const ttid = qAC.ttid;
                  const oid = qAC.enterprise_id;
                  const subOid = qAC.sub_company_id;
                  const subAmount = qAC.subsidiary_amount;
                  const cid = qAC._id;
                  var url = `/smrd/quota_allocation?id=${cid}`;
                  if (table === 'r5-2') {//r5-2:额度分配;r5-3:额度回收
                    typeAC = 1;
                  } else {
                    typeAC = 2;
                    url = `/smrd/quota_recycle?id=${cid}`;
                  }
                  //1.将审批完成的额度分配或者额度回收数据，放入amounts、total_amounts--20210207
                  fetching(`/amount/usedAmount/handleQaAndQc?ttid=${ttid}&oid=${oid}&subOid=${subOid}&subAmount=${subAmount}&cid=${cid}&typeAC=${typeAC}`).then(res => {
                    if (res) {
                      if (res.code !== 200)  {
                        alert(res.msg || '额度处理失败！')
                      } else {
                        //2.成功后更新额度分配or额度回收的handle_status_id = 1
                        fetching(url, {method: 'DELETE'}).then(resData => {
                        //fetching(`/smrd/quota_allocation/put_hsid?id=${cid}`).then(res => {
                        //fetching(`/smrd/quota_allocation/${form._id}/put_hsid`).then(res => {
                          this.props.onSubmit({
                            ...e,
                            submit_name: text
                          })
                        })
                      }
                    }
                  })
                    this.props.onSubmit({
                      ...e,
                      submit_name: text
                    })
          } else {
            this.props.onSubmit({
              ...e,
              submit_name: text
            })

	  } if((table === 'r5-2' || table === 'r5-3') && form.returnBy === "退回经办人" ||
           (form.form_no === "2" && form.returnBy === "退回上一级" && this.props.auth.roles[0].id === 3)){//额度分配退回经办人
            var qAC = this.props.exercise;
            var typeAC = 0;//1-额度分配;2-额度收回
            const ttid = qAC.ttid;
            const oid = qAC.enterprise_id;
            const subAmount = qAC.subsidiary_amount;
            if (table === 'r5-2') {//r5-2:额度分配;r5-3:额度回收
              typeAC = 3;
            } else {
              typeAC = 4;
            }
	   if (table !== 'r5-1') {
	  fetching(`/amount/usedAmount/firstHandleQaAndQc?ttid=${ttid}&oid=${oid}&subAmount=${subAmount}&typeAC=${typeAC}`).then(res => {
              if (res !== undefined && res.code !== 200)  {
                alert(res.msg || '额度处理失败！')
             }
            })
	   }
          }
          })
    } else {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
    }
  }
  render() {
    const { form2, form, userList } = this.state;
    const { exercise, classes } = this.props;
    const check_opinion_label = (form_no) => {
      if (form_no === '3') return form2.check_opinion
      else if (form_no === '2') return form2.return_reason
      else return form2.remarks
    }
    const status = (handleSubmit, isSubmitting, values) => {
      return <Grid container justify="center" style={{ padding: '20px 0' }}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          disabled={isSubmitting}
          name={form2.submit}
          style={{ marginRight: '10px' }}
        >
          {form2.submit}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={this.handleSubmit.bind(this, values, 'cancle')}
          name={form2.cancel}
          disabled={isSubmitting}
          style={{ marginRight: '10px' }}
        >
          {form2.cancel}
        </Button>
      </Grid>
    }
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        validate={(values) => {
          return checkFormik(values, form2);
        }}
        //验证提交
        onSubmit={(values) => {
          this.handleSubmit(values, 'submit');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <form method="post" encType="multipart/form-data">
              {/* {exercise.save === '3' &&<h4>审核</h4>} */}
              <SimpleExpansionPanel title="历史审核意见">
                <Typography style={{ textAlign: 'left', fontSize: '14px' }} variant="inherit" gutterBottom>经办人：{form.auth.organization.name}-{form.auth.user.account || ''}</Typography>
                {
                  form.opinion_list.map((item, index) => {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 0', textAlign: 'left', fontSize: '14px' }} key={index}>
                        <Typography variant="inherit" gutterBottom>
                          {item.auth.organization.name}-{item.auth.role.name}-{item.auth.user.account}
                        </Typography>
                        <Typography variant="inherit" gutterBottom >
                          {item.check_opinion}
                        </Typography>
                      </div>
                    )
                  })

                }
              </SimpleExpansionPanel>
              {exercise.save === '3' && exercise.DialogTitle === '审核' &&
                <div>
                  <Grid item xs={12} >
                    <FormControl component="fieldset" style={styles} className={classes.radio}>
                      {/* <FormLabel component="legend" style={{ fontSize: '12px' }}></FormLabel> */}
                      <RadioGroup className={classes.radio} style={{ flexDirection: 'row' }} aria-label="gender" name='entrusted_payment' value={values.form_no}
                        onChange={e => setFieldValue('form_no', e.target.value)}>
                        {List.map(item => {
                          return (
                            <FormControlLabel
                              key={item.id}
                              name={item.id}
                              value={item.id}
                              control={<Radio />}
                              label={item.text} />
                          )
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      rows='4'
                      label={check_opinion_label(values.form_no)}
                      value={values.check_opinion}
                      name='check_opinion'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      margin='normal'
                      fullWidth
                      disabled={false}
                    />
                    <FormikError touched={touched} errors={errors} name={'check_opinion'} />
                  </Grid>
                  {
                    values.form_no === '2' && <Grid item xs={5}>
                      <FormControl style={{ width: '100%', margin: '10px 0' }}>
                        <InputLabel id="demo-simple-select-label">{form2.returnBy}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="returnBy"
                          value={values.returnBy}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {
                            userList.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                  }
                  {
                    status(handleSubmit, isSubmitting, values)
                  }
                </div>
              }
            </form>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(CheckForm)
