import React, { Component } from 'react'
import moment from 'moment'
import { Formik } from 'formik';
import Select from 'react-select';
import {
  TextField,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  CircularProgress,
  LinearProgress,
  Box
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';

import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Upload } from '../../components/upload';
import { numberParseChina } from '../../components/numToText';
import { formatDate ,getNowFormatDate } from '../../components/date';
import FormikError from './formikError';
import { issueFormik } from './formikValidate';
import SimpleExpansionPanel from './Panels';
import { numberfun, commafy, delcommafy } from '../../components/numToText';
import { fetching } from '../../config/utils'
import { DEFAULT_CONTRCT_URL } from '../../config/constants'

const { object } = require('yup');
const useStyles = {
  root: {
    position: 'relative'
  },
};
global.count = 0;
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}

class Form extends Component {
  state = {
    selectCustomerList: [],
    form: this.getInitState(),
    form2: this.props.form2 || {},
    open2: false,
    length: 20,
    opinion_list: [],
    amountList: [],
    loading: false,
    loading2: false,
    loadingDsb: false,
    is_open_day: true,
    is_payment_day: true,
    uploads: {},
    messageOpen: {
      open: false,
      message: '提示'
    },
    paymentTypeList:  ['还款日固定', '还款日不固定', '机动性还款'],
  }

  getInitState() {
    const { exercise, auth = {} } = this.props
    const open_day = new Date();
    let extraction = '';
    let open_credit_code = '';
    let Upload_contracts='';
    let Upload_invoice='';
    let Upload_contracts_invoice='';
    let Upload_rest='';
    //let mx_amount = exercise.mx_amount ? commafy(exercise.mx_amount) : 0;//授信额度
    let mx_amount = exercise.mx_amount ? {limitMark:exercise.limit_mark, mxAmount:exercise.display_mx_amount} : 0;//授信额度
    let usable_amount = exercise.usable_amount ? commafy(exercise.usable_amount) : 0;//可用额度
    let amount = exercise.amount ? commafy(exercise.amount) : '';//开立额度
    let receivable = exercise.receivable ? commafy(exercise.receivable) : '';//应收账款金额
    //退回编辑需要  显示初始文件
    if((exercise.save === '2' && auth.roles[0].id === 11) || (exercise.status_id === 5 && auth.roles[0].id === 1)){
       exercise.upload.forEach(item => {
        switch(item.field){
          case 'Upload_contracts':
            Upload_contracts =item ? item : '';
            break;
          case 'Upload_invoice':
            Upload_invoice =item ? item : '';
            break;
          case 'Upload_contracts_invoice':
            Upload_contracts_invoice = item ? item : '';
            break;
          case 'Upload_rest':
            Upload_rest =item ? item : '';
            break;
        default: ;
        }   

     })
    }
    const ocid = exercise.id || '';

    if (exercise.StatusId === 7){
      exercise.open_day = getNowFormatDate();
  }

    if (auth.organization) {
      extraction = auth.organization.name;
      open_credit_code = auth.organization.credit_no;
    }
    const form = {
      ttid: 0,//total_amounts.id--20210223
      ocid,
      extraction,//开立方
      open_credit_code,//开立方信用代码
      receive: '',//接收方
      amount,//金额
      amount_capital: '', //金额大写
      open_day: open_day,//开立日
      payment_day: open_day,//承诺付款日
      payment_type: "还款日固定",
      synthesis_rate: 5.8,//预计综合融资息费率（%)
      remarks: '',  //备注
      receive_credit_code: '',//接收方信用代码
      mx_amount,//授信额度
      receivable,//应收账款金额
      usable_amount,//可用额度
      contractId:0,//合同ID
      //Upload_contracts: "", //上传合同
      Upload_contracts: false, //上传合同
      invoiceId:0,//发票ID
      Upload_invoice: false, //上传发票
      //Upload_invoice: "", //上传发票
      contractsInvoiceId:0,//合同发票文件ID
      //Upload_contracts_invoice: "", //上传合同 发票编号
      Upload_contracts_invoice: false, //上传合同 发票编号
      restId:0,//其它文件ID
      Upload_rest: "",//其他
      //Upload_rest: false,//其他
      sign_agreement: '1', //签订协议
      DialogTitle: "",
      status2: '1', //判断是开具还是复核
      save: "1", //判断是新增还是编辑
      imgUrl: '',
      guarantor: '', //开立担保人
      guaranteed_payment_day: open_day,//保证到期日
      table: exercise.table,
    }

    if (auth.roles[0].id === 11 && exercise.upload_contracts === true) {
    form.Upload_contracts = exercise.upload_contracts;//上传合同
    form.Upload_invoice = exercise.upload_invoice; //上传发票
    form.Upload_contracts_invoice = exercise.upload_contracts_invoice; //上传合同编号
    form.Upload_rest = exercise.upload_rest;//其他
    } else {
    form.Upload_contracts = "";//上传合同
    form.Upload_invoice = ""; //上传发票
    form.Upload_contracts_invoice =  ""; //上传合同编号
    form.Upload_rest = "";//其他
    }
    return exercise.save !== '1' ? {
      ...form, ...exercise, mx_amount, usable_amount, amount, receivable,Upload_contracts,Upload_invoice,Upload_contracts_invoice,Upload_rest,
      amount_capital: numberParseChina(exercise.amount)
    } : form
  }

  componentDidMount() {
    const { exercise, auth } = this.props;
    let department_id = ``; //william
    let organizationId = ''
    let enterpriseName = '';
    if (auth.department.id === 7) {
      department_id = `&department_id=${auth.department.id}`
      organizationId = auth.organization.id
      enterpriseName = auth.organization.name;//20210111
    } else {
      organizationId = exercise.organizationId
      enterpriseName = auth.organization.name;//20210111
    }
    //获取企业额度 department
    /*fetching('/msapi/totalamount/find?organization_id=' + organizationId).then(data => {
      if (Array.isArray(data) && data.length > 0) {
        var { mx_amount, usable_amount } = data[0];

        //获取授信记录--20210108
        if (usable_amount > 0) {//可用额度不为0

          //let enterpriseName = auth.organization.name;//20210106
          //let enterpriseName = exercise.organization.name || auth.organization.name;//20210111

          //额度来源方式：1.额度来自单独授信；2.额度来自集团分配
          fetching('/msapi/credit1/find?status_id=9&enterprise_name=' + enterpriseName).then(dataList => {
            //1.额度来自单独授信
            if (Array.isArray(dataList) && dataList.length > 0) {//1.单独授信
              //1.1 处理所有授信记录
              //1.2 处理收集的授信额度时间段amountTimeList（已处理后的授信记录）
              this.handleAmount(dataList, usable_amount, mx_amount);
            } else {//2.额度来自集团分配
            //2.额度来自集团分配
              //2.1查询所有总公司分配额度记录(已完成状态)
              fetching('/smrd/quota_allocation/find?status_id=9&sub_company_id=' + organizationId).then(dataqa => {
                if (Array.isArray(dataqa) && dataqa.length > 0) {
                  var enterpriseNameJT = dataqa[0].enterprise_name;//集团总公司名称
                  //2.2查询总公司授信记录
                  fetching('/msapi/credit1/find?status_id=9&enterprise_name=' + enterpriseNameJT).then(dataJTList => {
                    if (Array.isArray(dataJTList) && dataJTList.length > 0) {//总公司有授信记录
                      //2.3 处理所有授信记录
                      //2.4 处理收集的授信额度时间段amountTimeList（已处理后的授信记录）
                      this.handleAmount(dataJTList, usable_amount, mx_amount);
                    } else {//总公司无授信记录
                      this.setState({
                        form: {
                          ...this.state.form,
                          mx_amount: commafy(mx_amount),
                          usable_amount: commafy(0)
                        }
                      })
                    }
                  })
                } else {//无额度分配记录
                  this.setState({
                    form: {
                      ...this.state.form,
                      mx_amount: commafy(mx_amount),
                      usable_amount: commafy(0)
                    }
                  })
                }
              })
              
            }
          })
        } else {//查询的可用额度为0
          this.setState({
            form: {
              ...this.state.form,
              mx_amount: commafy(mx_amount),
              usable_amount: commafy(usable_amount)
            }
          })
        }
        
      }

    })*/
    //if (exercise.save === '3' || exercise.save === '2'){//20210630修改开立退回再编辑额度没有筛选
    if (exercise.save === '3' || exercise.save === '2'){
      fetching(`/amount/usedAmount/getTotalAmount?organizationId=${organizationId}`).then(res => {
          if (res) {
              res.data.forEach(item => {
               if (item.id === exercise.ttid) {
                  this.setState({
                  form: {
                      ...this.state.form,
                      //mx_amount: commafy(item.mxAmount),
                      mx_amount: {limitMark:item.limitMark, mxAmount:item.mxAmount},
                      usable_amount: commafy(item.usableAmount)
                    }
                  })
              }
              })
            this.setState({ amountList: res.data })
            
          }
      })
      }else {
       fetching(`/amount/usedAmount/issuesGetFilterAmount?organizationId=${organizationId}`).then(res => {
         if (res) {
           this.setState({ amountList: res.data })
         }
       })
      }
    if (exercise.save === '3' || exercise.save === '2') {
      //获取审核意见
      fetching(`/msapi/flow/find?mx_number=${exercise.mx_number}${department_id}`).then(res => {
        if (res) {
          this.setState({ opinion_list: res })
        }
      })
      //获取上传文件
      fetching('/msapi/upload/find?issue_id=' + exercise.id, { method: 'GET' }).then(data => {
        if (Array.isArray(data) && data.length > 0) {
          let uploads = {}
          data.forEach(item => {
            uploads[item.field] = item.fpath;
          })
          this.setState({ uploads })
        }
      })
    }
  }

  //--20210108
  //1.1 处理所有授信记录
  //1.2 处理收集的授信额度时间段amountTimeList（已处理后的授信记录）
  handleAmount = (dataList, usable_amount, mx_amount) => {

    //考虑授信时间有交叉，额度顺延情况--20210108
    var amountTimeList = [];//授信额度时间段list
    var usableAmount = 0.00;//可用额度

    const d = new Date();
    const year = d.getFullYear();
    const mouth = (d.getMonth() + 1) < 10 ? ('0' + (d.getMonth() + 1)) : (d.getMonth() + 1);
    const day = d.getDate() < 10 ? ('0' + d.getDate()) : d.getDate();
    const today = "" + year + mouth + day;

    //1.1 处理所有授信记录
    for (let i = 0; i < dataList.length; i++) {
      var credit1s = dataList[i];
      
      var credit_start_date = credit1s.credit_start_date.replace(/-/g,"");//开始时间
      var credit_due_date = credit1s.credit_due_date.replace(/-/g,"");//结束时间

      var flagCredit1s = false;//是否和已经收集的授信额度时间段amountTimeList中的时间段([有交集])

      for (let j = 0; j < amountTimeList.length; j++) {
        var amountTime = amountTimeList[j];
        //正处理的授信dataList[i]的期限，与已经收集的授信额度时间段amountTimeList[j]的时间段([有交集]): 4种情况
        //a.时间段小于等于基准时间段
        if ( credit_start_date >= amountTime.startTime && credit_due_date <= amountTime.endTime ) {
          flagCredit1s = true;//(是)和已经收集的授信额度时间段amountTimeList中的时间段([有交集])
        } else if ( credit_start_date <= amountTime.startTime && credit_due_date >= amountTime.endTime ) {
        //b.时间段大于等于基准时间段
          flagCredit1s = true;//有交集
          amountTimeList[j].startTime = credit_start_date;//基准开始时间点改变
          amountTimeList[j].endTime = credit_due_date;//基准结束时间点改变
        } else if ( credit_due_date >= amountTime.startTime && credit_due_date <= amountTime.endTime ) {
        //c.与基准的前半部分重合
          flagCredit1s = true;//有交集
          amountTimeList[j].startTime = credit_start_date;//基准开始时间点改变
        } else if ( credit_start_date >= amountTime.startTime && credit_start_date <= amountTime.endTime ) {
        //d.与基准的后半部分重合
          flagCredit1s = true;//有交集
          amountTimeList[j].endTime = credit_due_date;//基准结束时间点改变
        }

        if (flagCredit1s) {//有交集
          amountTimeList[j].amount += Number(credit1s.mx_amount);//额度相加
          break;
        }
      }

      if (flagCredit1s) {//有交集
        continue;
      } else {//无交集，新增
        var amountTime = {};
        amountTime.startTime = credit_start_date;
        amountTime.endTime = credit_due_date;
        amountTime.amount = Number(credit1s.mx_amount);
        amountTimeList.push(amountTime);
      }
    }

    //1.2 处理收集的授信额度时间段amountTimeList（已处理后的授信记录）
    for (let j = 0; j < amountTimeList.length; j++) {
      var amountTime = amountTimeList[j];
      //查找可用额度：开始时间 <= [today] && 结束时间 >= [today]
      if ( amountTime.startTime <= today && amountTime.endTime >= today ) {//未超过授信到期日
        usableAmount = amountTime.amount;//可用额度
        break;
      }
    }

    //1.3 额度矫正
    if (usableAmount > usable_amount) {
      usableAmount = usable_amount;
    }

    //1.4填充额度
    this.setState({
      form: {
        ...this.state.form,
        mx_amount: commafy(mx_amount),
        usable_amount: commafy(usableAmount)
      }
    })

  }

  handleChange = ({ target: { name, value } }) => {
    let { form } = this.state;
    let value2 = value
    if (name === 'add_days_number') {
      value2 = numberfun(value, 5) || 0;
    } else {
      value2 = numberfun(value, 12, 2) || 0;
    }
    this.setState({
      form: { ...form, [name]: value2, }
    })
    return value2;
  }

  //输入框值改变
  handleChange2 = (name, value) => {
    let value2 = value;
    let { amount_capital } = this.state.form;
    let usable_amount_capital = this.state.form.usable_amount_capital;
    if (name === 'amount') {
      //value2 = numberfun(value2, 12);
      value2 = numberfun(value2, 12,2);
      amount_capital = numberParseChina(value2);
    }
    this.setState({
      form: { ...this.state.form, [name]: value2, amount_capital, usable_amount_capital }
    })
    return value2;
  }

  uploadHandler = async (e,type) => {
	this.setState({progressLength : 0})
    	this.setState({ progress: true })
    	const timer = setInterval(() => {
      		this.setState({progressLength : (this.state.progressLength >= 99 ? 99 : this.state.progressLength + 1) })
    	}, 30);
     let indexId = '';
     indexId = await this.uploadFile(e)
      .then(res => {
        if (res) {
           if (!res.status){
              alert(res.msg)         
            }else{
              global.count = global.count - 1
              return res.json()
            }
        }else{
           return null
        }
      }).then(r=>{ 
        if (r.error.code === 200) {//无错
          clearInterval(timer);
          this.setState({progressLength : 100})
          setTimeout(() => {
            alert("文件上传成功");
            this.setState({ progress: false })
          }, 500)
          return r.data
        } else {//业务失败
          alert(r.error.message)
          this.setState({ progress: false })
          return null;
        }
      })

    if(type === 1){
      this.state.form.contractId = indexId 
      this.state.form.Upload_contracts = true
    }else if(type === 2){
      this.state.form.invoiceId = indexId
    this.state.form.Upload_invoice = true 
    }else if(type === 3){
      this.state.form.contractsInvoiceId= indexId
      this.state.form.Upload_contracts_invoice = true
    }else if(type === 4){
      this.state.form.restId= indexId 
      this.state.form.Upload_rest = true
    }

    return true
  }

  uploadFile(e){
    let form = this.state.form;
    var fordata = new FormData();
    if (form.status_id === 5 ) {//补充上传文件
        fordata.append('type',1);
        fordata.append('fileName',e.file);
        fordata.append('fileSource',e.uploadName);
        fordata.append('id',form.id);
        fordata.append('uploadFileName',e.file.name);
        const options = {
          method: "POST",
          body: fordata
        }
        global.count = (global.count+1)
        return fetch(`/msapi/upload/reUpload`,options) 
    } else {
      fordata.append('type',1);
      fordata.append('fileName',e.file);
      fordata.append('fileSource',e.uploadName);
      fordata.append('uploadFileName',e.file.name);
      const options = {
        method: "POST",
        body: fordata,
        timeout: 100
      }
      global.count = (global.count+1)
      return fetch(`/msapi/upload/upload`,options)
    }
    
  };
  //输入框失去焦点
  handleBlur2 = (value) => {

    return commafy(value);
  }

  //输入框失去焦点
  receivableHandleBlur = ({ target: { name, value } }) => {
    this.setState({
      form: { ...this.state.form, [name]: commafy(value), }
    })
  }

  //开立日
  handleChangeStartTime = (data) => {
    let open_day = new Date();
    if (data === null) {
      this.setState({ form: { ...this.state.form, open_day: new Date() } })
    } else {
      open_day = data;
      this.setState({ form: { ...this.state.form, open_day: data } })
    }
    return open_day;
  }

  //付款日
  handleChangeEndTime = (data) => {
    let payment_day = moment().format('YYYY-MM-DD');
    if (data === null) {
      this.setState({ form: { ...this.state.form, payment_day } })
    } else {
      payment_day = data;
      this.setState({ form: { ...this.state.form, payment_day: data } })
    }
    return payment_day;
  }

  handleFileChange = e => {
    this.setState({
      [e.target.name]: e.target.files[0],
    })
  };

  // 提交
  handleSubmit = async (form2, name) => {
    const { id } = this.state.form;
    const { is_open_day } = this.state;
    const { auth } = this.props;
    form2.limit_mark = form2.mx_amount.limitMark
    form2.receive = this.state.form.receive;//接收方以organizations查到的为准  20240307
    const form = form2 ? { ...form2 } : {};
    const text = name || '取消';
    let ids = id ? id : '';
    let method = id ? 'PUT' : 'POST';
    // form.amount_capital = amount_capital; //金额大写
    // form.receive_credit_code = receive_credit_code;//信用代码
    // form.add_days_number = add_days_number  //增加天数
    // form.receivable = receivable //应收账款金额
    const amountList = ['amount', 'usable_amount', 'mx_amount', 'receivable'];
    const dataList = ['contractId','invoiceId','contractsInvoiceId','restId','Upload_contracts','Upload_invoice','Upload_contracts_invoice','Upload_rest','amount_capital', 'receive_credit_code', 'receivable', 'mx_amount', 'usable_amount'];
    dataList.forEach(item => form[item] = this.state.form[item])
    //提交表单

    if (text === '提交' || text === '保存') {
      this.setState({ loading: true, loadingDsb: true, });
      if (moment(form.payment_day).isBefore(moment(form.open_day)) || moment(form.payment_day).isSame(moment(form.open_day))) {
        alert('承诺付款日要在开立日之后');
        return false;
      }
      else if(! this.in1year(form.open_day, form.payment_day)) {
        alert('承诺付款日要在开立日之后一年之内。');
        return false;
      }
      if (form.payment_type !== "还款日不固定"){
        if(moment(form.open_day).isBefore(formatDate((new Date()), 'yyyy-MM-dd'))){
          alert('开立日期不能小于当前日期');
          return false;
        }
      }
      if (form.guarantor !== "") {
        if (form.guaranteed_payment_day === undefined || form.guaranteed_payment_day === null) {
          alert('保证到期日期不能为空');
          return false;
        }
      }
      if(moment(form.guaranteed_payment_day).isBefore(formatDate((new Date()), 'yyyy-MM-dd'))){
        alert('保证到期日期不能小于当前日期');
        return false;
      }
      if (form.open_day instanceof Object) {
        form.open_day = formatDate(form.open_day, 'yyyy-MM-dd');
      }
      if (form.payment_day instanceof Object) {
        form.payment_day = formatDate(form.payment_day, 'yyyy-MM-dd');
      }
      const organization = await fetching(`/msapi/organizations?credit_no=${form.receive_credit_code}`);

      amountList.forEach(item => {
        form[item] = delcommafy(form[item]);
      })

      //20210201
      //查询已经使用的额度中：1.(作为总公司)分配给子公司的额度 - 收回的额度；2.(作为子公司)被总公司收回的额度 + 分配的额度；3.已经使用的额度合计； 4.已经过期的额度合计
      //增加[可用额度]检测校准--20210202
      const enterpriseName = form.extraction;
      const openCreditCode = form.open_credit_code;
      const usableAmount = form.usable_amount;
      //const mxAmount = form.mx_amount;
      const mxAmount = form.mx_amount ? form.mx_amount :this.state.form.mx_amount.mxAmount;//开立退回再次提交显示额度额度为零的bug 20210419
      var usedAmount = Number(mxAmount) - Number(usableAmount);
      const ttid = this.state.form.ttid;
      form.ttid = ttid;
     // fetching(`/amount/usedAmount/getOUsedAmount?openCreditCode=${openCreditCode}&usedAmount=${usedAmount}&ttid=${ttid}`).then(res => {
        //if (res.code === 200) {
       //   var usableAmountNew = Number(mxAmount) - Number(res.data);
          /*if ( ( Number(usableAmountNew) - Number(form.amount) ) < 0.0001 ) {
            alert(`实际可用金额已减少至${usableAmountNew}，开立金额不能大于实际可用金额!`)
            return;
          }*/

      if (form.usable_amount <= 0) {
        alert('可用额度必需大于0');
      }
      else if (form.amount <= 0) {
        alert('开具金额必须大于0');
      }
      else if (!is_open_day) {
        alert('开立日期不能大于额度到期日! 请重新选择开立日期。')
      }
      // 我们现在需要在开立日那里限制，承诺付款日不做限制
      // else if (!is_payment_day) {
      //   alert('承诺付款日不能大于授信到期日! 请重新选择承诺付款日。')
      // }
      else if (form.usable_amount < form.amount) {
        alert('开具金额不能大于可用额度');
      }
      else if (organization.length < 1 && text === '提交') {
        alert(`融单初始持有人企业没有进行注册`);
      }
      else if (auth.organization.name === form.receive) {
        alert(`融单初始持有人不能是自己`);
      } else {

        /*if (text === '提交') {
          form.status_id = form.statusId ? form.statusId : 2;
          form.type = 3;
          this.setState({ loading: true, loadingDsb: true, })
        }
        else if (text === '保存') {
          form.status_id = 1;
          form.type = 2;
          this.setState({ loading2: true, loadingDsb: true, })
        }*/
        form.status_id = form.statusId ? form.statusId : 2;
        form.type = 3;
        form.auth = auth;//用户信息
        // TODO:
        const excludes = ['DialogTitle', 'status2', 'save', 'imgUrl', 'formDates', 'table', 'open',
          'checkOpen', 'submit_name', 'tableData', 'receivable'];
        //const formData = new FormData();
        //开立填充
        const body2 = {

          extraction: form.extraction,//开立方
          receive: form.receive,//接收方
          // receiveCreditCode:"91610113MA6TXAM79E",
          // openCreditCode:"91610113MA6TXAM79E",
          openCreditCode: form.open_credit_code,//开立方信用代码
          receiveCreditCode: form.receive_credit_code,//接收方信用代码
          amount: form.amount,//金额
          receivable: form.receivable,//应收账款金额
          capital_amount: numberParseChina(form.amount), //金额大写
          manager: auth.name,//经办人
          paymentDate: form.payment_day,//付款日期
          maturityDate: form.payment_day,//到期日期
          circulationDate: form.open_day,//流转日期	yyyy-MM-dd
          mxNumber: '',  //融单编号
        }
        form.mx_amount = form.mx_amount ? form.mx_amount : this.state.form.mx_amount.mxAmount;//开立退回再次提交显示授信额度为零的bug 20210419

        // for (let name in form) {
        //   if (!excludes.includes(name) && form[name] && form[name] !== 'null') formData.append(name, form[name]);
        // }

        //开立提交之间。增加excel拦截校验--20210121
        //增加善美开立/流转/融资，发票数据上传时，数据格式，数据重复，当次使用累计金额与融单金额的比较查验拦截，将原有发票金额检测进行拆分调整
        //const formDataCheck = new FormData();

	//判断退回编辑时是否为null
        if(this.state.form.Upload_contracts_invoice.id){
        form.contractsInvoiceId=this.state.form.Upload_contracts_invoice.id;
        }
        const formCheck = {
          organization_id: auth.organization.id,
          amount: form.amount,//金额
          contractsInvoiceId: form.contractsInvoiceId, //excel上传文件id  1.28 zhao
          //Upload_contracts_invoice: form.Upload_contracts_invoice,
        };
        //for (let name in formCheck) {
        //  if (!excludes.includes(name) && formCheck[name] && formCheck[name] !== 'null') formDataCheck.append(name, formCheck[name]);
        //}
        //let methodCheck = 'POST';
        fetching('/smrd/fapiao_org/check_excels', {
          //methodCheck,
          method:"post",
          //body: formDataCheck,
          body: JSON.stringify(formCheck),
        }).then(res => {
          if(res !== null && res !== undefined){ 
          if (res.msg) {
            alert(`excel中数据检测不通过：,${res.msg}`)
            this.setState({ loading: false, loading2: false, loadingDsb: false })
            return false;
          }
        } else {
          alert("请检验excel表是否符合模板规范")
          return false;
        }
        // POST 开具提交
        fetching('/msapi/issue/save/'+ids, {
          method:"post",
          body: JSON.stringify(form),
        }).then(res => {
          // this.setState({ loading: false, loading2: false, loadingDsb: false })
          try {
            if (res) {
              if (res.msg) {
                this.setState({ loading: false, loading2: false, loadingDsb: false })
                alert(`融单开具失败,${res.msg}`)
                return false;
              }
              body2.mxNumber = res.data
              fetching('/dzqz/generateContract/KL', {
                method: 'POST',
                body: JSON.stringify(body2)
              }).then(res2 => {
                this.setState({ loading: false, loading2: false, loadingDsb: false })
                if (res2.code === 200) {
                  this.props.onSubmit({
                    ...form,
                    submit_name: text
                  })
                  alert('融单开具成功')
                } else {
                  alert(`合同填充失败,${res2.msg}`)
                }

              })
            } else {
              alert(`融单开具失败`)
            }
          } catch (error) {
            console.log(error)
            this.setState({ loading: false, loading2: false, loadingDsb: false })
          }
        });

        });
      }
    //} else {
     // alert(res.msg || '可用额度校验失败，请再次提交或者寻找工作人员协助！')
    //}
  //})
      
    } else {
      this.setState({ loading: false, loading2: false, loadingDsb: false });
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
    }
  }

  //查看协议
  viewAgreement = () => {
    const { fdd_file_path = '' } = this.state.form
    if (fdd_file_path && fdd_file_path !== 'null') {
      window.open(fdd_file_path)
    } else
      window.open(DEFAULT_CONTRCT_URL + 'contract/template/kl.pdf')
  }

  //下载协议
  ondownload = () => {
    const { fdd_file_name = '' } = this.state.form
    fetching(`/dzqz//downLoadContract?contract_id=${fdd_file_name}`, {
      method: 'GET'
    }).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(res.msg || '下载失败')
        }
      } else {
        alert('下载失败')
      }
    })
  }

  //接收方失去焦点
  handleBlur3 = (v) => {
   if (v !== "" ) {
    v=v.replace(/\s+/g,"")
   /* fetching(`/msapi/organizations?name=${v}`).then(res => {
      if (res.length > 0) {
        this.setState({
          form: { ...this.state.form, receive_credit_code: res[0].credit_no }
        })
      } else {
        this.setState({
          form: { ...this.state.form, receive_credit_code: '' }
        })
      }
    });*/
   fetching(`/msapi/customer_info_add/legalPersonAuthorizationPeriod/getLgAuthorStatusMsg?name=${v}`).then(res => {
      if (res !== undefined && res.data !== null) {
       if (res.data.status === "1") {
        fetching(`/msapi/organizations?name=${v}`).then(res => {
          if (res !== undefined && res.length > 0) {
            this.setState({
              form: { ...this.state.form, receive_credit_code: res[0].credit_no,receive: res[0].name }
            })
          } else {
            this.setState({
              form: { ...this.state.form, receive_credit_code: '' }
            })
          }
        });
      } else {
        alert("接收人法人授权信息已过期,暂无法接收融单,请联系客户经理处理");
        window.location.reload();
      }
 } else {
       fetching(`/msapi/organizations?name=${v}`).then(res => {
          if (res !== undefined && res.length > 0) {
            this.setState({
              form: { ...this.state.form, receive_credit_code: res[0].credit_no,receive: res[0].name }
            })
          } else {
            this.setState({
              form: { ...this.state.form, receive_credit_code: '' }
            })
          }
        });
 }
 });
}

    return v;
  }
 
   //输入框去除空格
  handleBlur4 = (value) => {
      return  value.replace(/\s+/g,"");
  }

  handleButtonClick = () => {
    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({ loading: false })
    }, 2000)
  }

  opendayChange = (e) => {
    const { extraction } = this.state.form
    if (e !== null) {
    if (e.getTime()) {
      let open_day = formatDate(e, 'yyyy-MM-dd');
      fetching(`/msapi/issue/find/${extraction}/${open_day}/open_before_due`).then(res => {
        if (res) {
          // william jiang: remove the alert, but keep the logic.
          if (!res.status) alert(res.msg)
          this.setState({
            is_open_day: res.status
          })
        }
      })
    }
    return e;
  } else {
    alert("请选择开立日");
  }
  }

  //承诺付款日在开立日之后一年内的限制
  //true: 承诺付款日在开立日1年之内，
  //false: 承诺付款日在开立日1年之后。
  in1year = (open_day, payment_day) => {
    const d1 = moment(open_day);
    const d2 = moment(d1).add(1, 'Y');
    return moment(payment_day).isBefore(d2);
  }

  paymentdayChange = (e) => {
    const {open_day} = this.state.form.open_day;
    const payment_day = e;
    if (!this.in1year(open_day, payment_day)) {
      alert(`承诺付款日${moment(payment_day).format('YYYY-MM-DD')}要在开立日${moment(open_day).format('YYYY-MM-DD')}的一年之内。`);
    }
    return e;
  }

  guaranteedPaymentdayChange = (e) => {
    const {open_day} = this.state.form.open_day;
    const guaranteed_payment_day = e;
    if(moment(guaranteed_payment_day).isBefore(formatDate((new Date()), 'yyyy-MM-dd'))){
      alert('保证到期日期不能小于当前日期,建议和承诺付款日一致');
      return false;
    }
    // if (!this.in1year(open_day, payment_day)) {
    //   alert(`承诺付款日${moment(payment_day).format('YYYY-MM-DD')}要在开立日${moment(open_day).format('YYYY-MM-DD')}的一年之内。`);
    // }
    return e;
  }
  
  guarantorChange = (e) => {
    //控制保证到期日显示还是隐藏
    this.setState({
      form: { ...this.state.form, guarantor: e }
    })
    return e;
  }

  // Deprecated: 承若时间: payment_day
  paymentdayChange1 = (e) => {
    const { extraction } = this.state.form
    if (e.getTime()) {
      let payment_day = formatDate(e, 'yyyy-MM-dd');
      fetching(`/msapi/issue/find/${extraction}/${payment_day}/payment_before_due`).then(res => {
        if (res) {
          // william jiang: remove the alert, but keep the logic.
          if (!res.status) alert(res.msg)
          this.setState({
            is_payment_day: res.status
          })
        }
      })
    }
    return e;
  }

  handleChange1 = (selectedOption) => {
    const { amount } = this.state.form;
    this.setState({
      form: {
        ...this.state.form,
        ttid: selectedOption.id,//20210223
        usedAmount: commafy(selectedOption.usedAmount),//20210223
        mx_amount: commafy(selectedOption.mxAmount),//20210223
        usable_amount: commafy(selectedOption.usableAmount),
      }
    })
    setTimeout(() => {
      this.handleChange({ target: { value: amount, name: 'amount' } });
    }, 300)
    return selectedOption;
  }
getOptionLabel = data => {
    return data.limitMark + data.mxAmount
}
getOptionValue = data => {
  return data.id
}

   //输入框值改变
   handleChange3 = (name, value) => {
    
    let value2 = value;
        this.setState({ form: { ...this.state.form, 
          receive_credit_code: value.organization_code,
          receive:value.enterprise_name  
        },
       })
    return value2;
}

getOptionLabel1 = data => {
  return data.receive
}

  render() {
    const { length, opinion_list, uploads ,amountList, paymentTypeList} = this.state;
    const { amount_capital, receive_credit_code } = this.state.form;
    const { form2, form, loading, loadingDsb, progress, progressLength } = this.state;
    const { exercise, auth = {}} = this.props;
    const roleId = auth.roles ? auth.roles[0].id : '';
    const status = (handleSubmit, values) => {

      if (exercise.status2 === '1' || (exercise.table === 'r6-2-3' && exercise.payment_type === '还款日不固定')) {
        return <Grid container justify="center" style={{ width: '100%', padding: '10px 0' }}>

          <div style={{ position: 'relative' }}>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSubmit}
              disabled={loadingDsb}
              name={form2.submit}
              style={{ marginRight: '10px' }}
            >
              {form2.submit}
            </Button>
            {loading && <CircularProgress size={24} style={loadingStyle} />}
          </div>

          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.revocation)}
            name={form2.revocation}
            disabled={loadingDsb}
            style={{ marginRight: '10px' }}
          >
            {form2.revocation}
          </Button>
        </Grid>
      }

    }
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        validationSchema={object().shape(issueFormik(form2))}
        //验证提交
        onSubmit={(values) => {
          if(global.count === 0){
            this.handleSubmit(values, '提交');
        }else{
          alert("文件正在上传请稍后!")
        }
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <form method="post" encType="multipart/form-data">
              {/* {<CustomizedSnackbars open={messageOpen.open} message={messageOpen.message}></CustomizedSnackbars>} */}
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.extraction}
                    value={values.extraction}
                    name='extraction'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    inputProps={{ maxLength: length }}
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'extraction'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.open_credit_code}
                    value={values.open_credit_code}
                    name='open_credit_code'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'open_credit_code'} />
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form2.mx_amount}</span> 
                  <Select
                    isSearchable 
                    value={values.mx_amount}
                    onBlur={handleBlur}
                    getOptionLabel={this.getOptionLabel}
                    getOptionValue={this.getOptionValue}
                    onChange={e => setFieldValue('mx_amount', this.handleChange1(e))}
                    options={amountList}
                    isDisabled={exercise.save === '3' || exercise.save === '4'}
                  />

                  <FormikError touched={touched} errors={errors} name={'mx_amount'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.usable_amount}
                    value={form.usable_amount}
                    name='usable_amount'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'usable_amount'} />
                </Grid>
              </Grid>


              {/*<Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.mx_amount}
                    value={form.mx_amount}
                    name='mx_amount'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />

                  <FormikError touched={touched} errors={errors} name={'mx_amount'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.usable_amount}
                    value={form.usable_amount}
                    name='usable_amount'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'usable_amount'} />
                </Grid>
        </Grid>*/}
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.receive}
                    value={values.receive}
                    name='receive'
                    onChange={handleChange}
                    onBlur={e => setFieldValue('receive', this.handleBlur3.bind(this, e.target.value)())}
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'receive'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.receive_credit_code}
                    value={receive_credit_code}
                    name='receive_credit_code'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'receive_credit_code'} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                  <Grid container justify="space-between">
                    <Grid item xs={5}>
                      <TextField
                        label={form2.guarantor}
                        value={values.guarantor}
                        name='guarantor'
                        //onChange={handleChange}
                        onChange={e => setFieldValue('guarantor', this.guarantorChange.bind(this, e.target.value)())}
                        //onBlur={handleBlur}
                        onBlur={e => setFieldValue('guarantor', this.handleBlur4.bind(this, e.target.value)())}
                        margin='normal'
                        fullWidth
                        disabled={exercise.save === '3'}
                      />
                      <FormikError touched={touched} errors={errors} name={'guarantor'} />
                    </Grid>
                    {( this.state.form.guarantor ) && <Grid item xs={5}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        disabled={exercise.save === '3'}
                        id="date-picker-inline"
                        label={form2.guaranteed_payment_day}
                        value={values.guaranteed_payment_day}
                        onChange={e => setFieldValue('guaranteed_payment_day', this.guaranteedPaymentdayChange.bind(this, e)())}
                        //onChange={handleChange}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'guaranteed_payment_day'} />
                    </Grid>}

                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.amount}
                    value={values.amount}
                    name='amount'
                    onChange={e => setFieldValue('amount', this.handleChange2.bind(this, 'amount', e.target.value)())}
                    onBlur={e => setFieldValue('amount', this.handleBlur2.bind(this, e.target.value)())}
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'amount'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    title={amount_capital}
                    label={form2.amount_capital}
                    name='amount'
                    value={amount_capital}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                </Grid>

              </Grid>

              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                  <Grid container justify="space-between">
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline"
                        label={form2.open_day}
                        disabled={exercise.save === '3'}
                        value={values.open_day}
                        onChange={e => setFieldValue('open_day', this.opendayChange.bind(this, e)())}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        //disabled={true}
                      />
                      <FormikError touched={touched} errors={errors} name={'open_day'} />
                    </Grid>
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        disabled={exercise.save === '3' && roleId !== 1}
                        id="date-picker-inline"
                        label={form2.payment_day}
                        value={values.payment_day}
                        onChange={e => setFieldValue('payment_day', this.paymentdayChange.bind(this, e)())}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'payment_day'} />
                    </Grid>

                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              {/* <Grid container justify="space-between">

                <Grid item xs={12}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.receivable}
                    value={form.receivable}
                    name='receivable'
                    onChange={e => setFieldValue('receivable',this.handleChange.bind(this,e)()) }
                    onBlur={this.receivableHandleBlur}
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                </Grid>
                <FormikError touched={touched} errors={errors} name={'receivable'} />

              </Grid> */}
              {/*<Grid item xs={12} >
                  <FormControl style={{ width: '100%', margin: '16px 0 8px 0' }}>
                      <FormLabel component="legend" style={{ fontSize: '12px' }}>{"到期日类型"}</FormLabel>
                      <RadioGroup  style={{ flexDirection: 'row' }} aria-label="gender" name='payment_type' value={values.payment_type}
                          onChange={e => setFieldValue('payment_type', e.target.value)}>
                          {paymentTypeList.map(item => {
                              return (
                                  <FormControlLabel
                                      key={item}
                                      name={item}
                                      value={item}
                                      disabled={exercise.save === '3'}
                                      control={<Radio />}
                                      label={item} />
                              )
                          })}
                      </RadioGroup>
                  </FormControl>
                  <FormikError touched={touched} errors={errors} name={'payment_type'} />
              </Grid>*/}
              {exercise.save === '3' &&
                <SimpleExpansionPanel title="历史审核意见">
                  {
                    opinion_list.map((item, index) => {
                      return (
                        <div style={{ margin: '10px 0' }} key={index}>
                          <Typography component="p">
                            {item.name}
                          </Typography>
                          <Typography component="p">
                            {item.check_opinion}
                          </Typography>
                        </div>
                      )
                    })
                  }
                </SimpleExpansionPanel>
              }
              <SimpleExpansionPanel title="档案登记*">
		{/* 上传进度条 */}
                { progress &&
                  <div>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate"  value={progressLength}/>
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                      progressLength
                    )}%`}</Typography>
                  </Box>
                </Box>
                </div>
                }
                <Grid container justify="space-between">
                  <Grid item xs={12}>
                  <Upload disabled={exercise.status2 === '2' || (exercise.save === '3' && (exercise.status_id !== 5 || (this.props.auth.roles[0].id !== 1 && this.props.auth.roles[0].id !== 3)) )}
                      uploadName="Upload_contracts"
                      mold="1"
                      uploads={uploads}
                      name={form2.Upload_contracts} view={form2.view_case}
                      exercise={exercise}
                      uploadChange={e=>setFieldValue('Upload_contracts',this.uploadHandler(e, 1))}> 
                      </Upload>
                    <FormikError touched={touched} errors={errors} name={'Upload_contracts'} />
                  </Grid>

                </Grid>
                <Grid item xs={12}>
                  <Upload disabled={exercise.status2 === '2' || (exercise.save === '3' && (exercise.status_id !== 5 || (this.props.auth.roles[0].id !== 1 && this.props.auth.roles[0].id !== 3)) )}
                    uploadName="Upload_invoice"
                    mold="1"
                    uploads={uploads}
                    exercise={exercise}
                    name={form2.Upload_invoice} view={form2.view_case}
                    uploadChange={e => setFieldValue('Upload_invoice',this.uploadHandler(e, 2))}>                      
                    </Upload>
                  <FormikError touched={touched} errors={errors} name={'Upload_invoice'} />
                </Grid>

                <Grid container justify="space-between">
                  <Grid item xs={12}>
                    <Upload disabled={exercise.status2 === '2' || (exercise.save === '3' && (exercise.status_id !== 5 || (this.props.auth.roles[0].id !== 1 && this.props.auth.roles[0].id !== 3)) )}
                      uploadName="Upload_contracts_invoice"
                      mold="1"
                      uploads={uploads}
                      exercise={exercise}
                      name={form2.Upload_contracts_invoice} view={form2.view_case}
                      uploadChange={e => setFieldValue('Upload_contracts_invoice',this.uploadHandler(e, 3))}></Upload>
                    <FormikError touched={touched} errors={errors} name={'Upload_contracts_invoice'} />
                  </Grid>

                </Grid>
                <Grid item xs={12}>
                  <Upload disabled={exercise.status2 === '2' || (exercise.save === '3' &&  (exercise.status_id !== 5 || (this.props.auth.roles[0].id !== 1 && this.props.auth.roles[0].id !== 3)) )}
                    uploadName="Upload_rest"
                    mold="1"
                    uploads={uploads}
                    exercise={exercise}
                    name={form2.Upload_rest} view={form2.view_case}
                    uploadChange={e => setFieldValue('Upload_rest',this.uploadHandler(e, 4))}></Upload>
                  <FormikError touched={touched} errors={errors} name={'Upload_rest'} />
                </Grid>
              </SimpleExpansionPanel>
              <FormikError touched={touched} errors={errors} name={'Upload_contracts'} />
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows='4'
                  label={form2.remarks}
                  value={values.remarks}
                  name='remarks'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'remarks'} />
              </Grid>
              {/* {roleId !== 11 && <div style={{ padding: '10px 0' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.sign_agreement === '1'}
                      disabled={exercise.save === '3'}
                      name='sign_agreement'
                      onChange={e => setFieldValue('sign_agreement', values.sign_agreement === '1' ? '' : '1')} />
                  }
                  label={form2.sign_agreement}
                />
                <span
                  style={{ color: '#f00', cursor: 'pointer' }}
                  onClick={this.viewAgreement}
                >{form2.sign_agreement2}</span>
                <FormikError touched={touched} errors={errors} name={'sign_agreement'} />
              </div>} */}
              {<div style={{ padding: '10px 0' }}>
                <Button
                  // color='primary'
                  variant='contained'
                  onClick={this.viewAgreement}
                  disabled={false}
                >
                  {form2.view_agreement}
                </Button>
                {(exercise.table === 'm2-5-2' || exercise.table === 'r6-2-3') && <Button
                  style={{ margin: '0 10px' }}
                  variant='contained'
                  onClick={this.ondownload}
                  disabled={false}
                >
                  {form2.download2}
                </Button>}
              </div>}

              {
                status(handleSubmit, values)
              }
            </form>
          )}
      />

    )
  }
}

export default withStyles(useStyles)(Form)
