//保证表单
export default {
    guarantor_type:"担保人类型",
    guarantor_name:"担保人",
    id_card:"身份证号",
    guarantee_amount:"保证金",
    guarantee_period:"担保期限",
    guarantor_enterprise:"担保单位",
    legal_person:"法人",
    unified_social_credit_code: "统一社会信用代码",
    user_id:'',//用户id,我传了auth给你
    cerdit1_id:'',//授信1 id
}