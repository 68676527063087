//质押表单
export default {
    pledge_type:"质押物类型",
    pledge_name: "质押人姓名",
    pledge_value: "质押物价值",
    pledge_position: "质押物位置",
    pledge_ratio: "质押物比例",
    pledge_desc:"质押物描述",
    guarantee_amount:"保证金",
    guarantee_period:"担保期限",
    user_id:'',//用户id,我传了auth给你
    cerdit1_id:'',//授信1 id
    unified_social_credit_code: "统一社会信用代码",
    warrant_no:'权证号'
}