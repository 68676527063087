
import getSubRouters from './helper';

export const subRootAry6 = [
    '应收账款审核',
    '应收账款查看',
    '已完成应收账款',
    '已退回应收账款',
  ];


const PARENT_PATH = '/risk-management/应收账款管理';
  const PATH = '应收账款';
  export default getSubRouters(PARENT_PATH, PATH, subRootAry6);
