//融单流转表单
export default {
    mx_number: "可用融单编号*",
    mx_payer: "融单流转人*",
    payer_credit_code: "流转人统一社会信用代码*",
    extraction: "开立人*",
    open_credit_code: "开立人统一社会信用代码*",
    receive: "接收人*",
    receive_credit_code: "接收人统一社会信用代码*",
    amount: "流转金额*",
    mx_amount:'融单金额*',
    usable_amount: "可用融单金额*",
    sign_agreement: "我同意",
    flow_date: "流转日期*",
    payment_day: "承诺付款日*",
    Upload_contracts: "上传合同*",
    Upload_invoice: "上传发票*",
    Upload_dispatch: "上传发货单",
    Upload_shipping: "上传运输单",
    synthesis_rate: "预计融资息费率*",
    remarks: "备注",

     //静态资源 不用在数据库里面加这些字段
     amount_capital: "金额大写*",
     sign_agreement2: "《融单流转协议》*",
     view_agreement: "查看融单流转协议",
     view_case: "查看案例",
     check_opinion: "审核意见",

}