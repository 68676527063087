// 授信模块用到的审核表单——dxm
import React, { Component } from 'react'
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { fetching } from '../../config/utils';
import { check } from '../../components/commonForm/check'
import { numberfun, commafy } from '../../components/numToText';
import {
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  InputAdornment,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import FormikError from '../../components/commonForm/formikError';
import SimpleExpansionPanel from '../../components/commonForm/Panels'
import { checkFormik, checkFormikbefa } from '../../components/commonForm/formikValidate';

const useStyles = (theme => ({
  radio: {
    flexDirection: 'row',
  },
}));
const styles = {
  width: '100%',
  margin: '16px 0 8px 0'
}
const List = [{ id: '3', text: '同意' }, { id: '2', text: '退回' },]
class FreezingCheckForm extends Component {
  state = {
    form: this.getInitState(),
    form2: { ...check },
    userList: [
      { id: 1, name: '退回上一级' },
      { id: 2, name: '退回经办人' },
    ],
    auth: this.props.auth || {},
  }

  getInitState() {
    const { exercise,auth } = this.props
    //debugger
    const form = {
      opinion_list: exercise.totalAmountFlow,
      ...exercise,
      check_opinion: '',
      form_no: '3',
      returnBy: '退回上一级',
      organization : auth.organization,
      role:auth.role,
      user:auth.user,
      beFrozenAmount: exercise.beFrozenAmount,
      thawAmount: exercise.thawAmount,
    }
    
    return form
  }

  // 提交
  handleSubmit = (form2, name) => {
    const { url } = this.props.dataUrl
    const { auth } = this.state;
    const form = form2 || this.state.form;
    const text = name || '取消';
    let type = 0;  //0 额度冻结 1 额度解冻
    //参数
    const body = {
      auth,//用户信息
      check_opinion: form.check_opinion,//审核意见
      audit_status: form.Status,  //传状态id name form.Status
      form_no: form.form_no,//2:退回，3：通过，5：签收，6：代签收
      returnBy: form.returnBy,
      beFrozenAmount: form.beFrozenAmount,
      thawAmount: form.thawAmount,
      ttid : form.id,
      ...form
    }
    if (form.table === 'r4-4-1'|| form.table === 'r4-4-2'|| form.table === 'r4-4-3'|| form.table === 'r4-4-4') {
      body.type = 1;
    } else {
      body.type = type;
    }
    // if (form.table === 'r4-1-2' || form.table === 'r4-1-4') {
    //   body.credit1_id = form.id
    // } else {
    //   body.credit2_id = form.id
    // }
    //提交表单的url
    let url1 = url.get3
    if (text !== '取消') {
      //debugger
      fetching(url1, {
        method: 'POST',
        body: JSON.stringify(body)
      }).then(res => {
        try {
          if (res) {
            if (res.code === 200) {
              alert(res.msg)
            } else {
              alert(`操作失败,${res.msg}`)
              return;
            }
            this.props.onSubmit({
              ...res,
              submit_name: text
            })
          }
        } catch (error) {
          console.log('error')
        }


      })

    } else {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
    }
    //window.location.reload()
  }
   //输入框值改变
   handleChange3 = (name, value) => {
    let value2 = value;
    if (name === 'beFrozenAmount' || name === 'thawAmount') {
      value2 = numberfun(value2, 12, 2);
    }
    return value2;
  }
  //输入框失去焦点 金额 千分位
  handleBlur2 = (name, value) => {
    return commafy(value);
  }
  render() {
    const { form2, form, userList ,auth} = this.state;
    const { exercise, classes } = this.props;
    const check_opinion_label = (form_no) => {
      if (form_no === '3') return form2.check_opinion
      else if (form_no === '2') return form2.return_reason
      else return form2.remarks
    }
    let rolesId = auth.roles ? auth.roles[0].id : 0;
    const status = (handleSubmit, isSubmitting, values) => {
      return <Grid container justify="center" style={{ padding: '20px 0' }}>
        <Button
          color="primary"
          variant='contained'
          onClick={handleSubmit}
          disabled={isSubmitting}
          name={form2.submit}
          style={{ marginRight: '10px' }}
        >
          {form2.submit}
        </Button>
        <Button
          color="primary"
          variant='contained'
          onClick={this.handleSubmit.bind(this, values, form2.cancel)}
          name={form2.cancel}
          disabled={isSubmitting}
          style={{ marginRight: '10px' }}
        >
          {form2.cancel}
        </Button>
      </Grid>
    }
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        //表单验证
        validate={(values) => {
          //debugger
          if (values.DialogTitle !== "新增") {
            return checkFormik(values, form2);
          } else{
            return checkFormikbefa(values, form2);
          }
        }}
        //验证提交
        onSubmit={(values) => {
          this.handleSubmit(values, form2.check);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <form method="post" encType="multipart/form-data">
              {form.form_no === '3'   &&
                  <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.enterprise_name}:</Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.enterprise_name}
                  </Typography>
                </Grid>}
                {form.form_no === '3'   &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.limitMark}:</Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.limitMark}
                  </Typography>
                </Grid>}
                {form.form_no === '3'   &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.mxAmount}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.mxAmount}元</span>
                  </Typography>
                </Grid> }
                {form.form_no === '3'   &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.usedAmount}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.usedAmount}元</span>
                  </Typography>
                </Grid> }
                {form.form_no === '3'   &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.usableAmount}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.usableAmount}元</span>
                  </Typography>
                </Grid> }
                
                {form.form_no === '3'   &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.creditStartDate}:</Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.creditStartDate}
                  </Typography>
                </Grid>}
                {form.form_no === '3'   &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.creditDueDate}:</Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.creditDueDate}
                  </Typography>
                </Grid>}
                { rolesId !== 1 && 
                <Grid item xs={12} className={classes.margin}>
                <Typography variant="body2" gutterBottom display='inline' style={styles} >
                  {form2.beFrozenAmount}:</Typography>
                <Typography variant="body2" gutterBottom display='inline' style={styles}>
                  {values.beFrozenAmount}
                </Typography>
              </Grid>}
              { rolesId !== 1 && values.table === 'r4-4-2' &&
                <Grid item xs={12} className={classes.margin}>
                <Typography variant="body2" gutterBottom display='inline' style={styles} >
                  {form2.thawAmount}:</Typography>
                <Typography variant="body2" gutterBottom display='inline' style={styles}>
                  {values.thawAmount}
                </Typography>
              </Grid>}
              { rolesId === 1 && values.table === 'r4-4-1' &&
                <Grid item xs={12} className={classes.margin}>
                <Typography variant="body2" gutterBottom display='inline' style={styles} >
                  {form2.frozenAmount}:</Typography>
                <Typography variant="body2" gutterBottom display='inline' style={styles}>
                  {values.frozenAmount}
                </Typography>
              </Grid>}
                
              { rolesId !== 1 && <SimpleExpansionPanel title="历史审核意见">
                {/*<Typography variant="inherit" style={{ textAlign: 'left' }} gutterBottom>经办人：{form.Organization.name}-{form.User.name}</Typography>*/}
                <Typography style={{ textAlign: 'left' }} variant="inherit" gutterBottom>经办人：{form.organization.name}-{form.user.name}</Typography>
                {
                  form.opinion_list.totalAmountFlow.map((item, index) => {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 0', textAlign: 'left', }} key={index}>
                        <Typography variant="inherit" gutterBottom>
                          {item.name}
                        </Typography>
                        <Typography variant="inherit" gutterBottom>
                          {item.checkOpinion}
                        </Typography>
                      </div>
                    )
                  })
                }
              </SimpleExpansionPanel>}
              {exercise.save !== '3' && rolesId !== 1&&
                <div>
                  <Grid item xs={12} >
                    <FormControl component="fieldset" style={styles} className={classes.radio}>
                      {/* <FormLabel component="legend" style={{ fontSize: '12px' }}></FormLabel> */}
                      <RadioGroup className={classes.radio} style={{ flexDirection: 'row' }} aria-label="gender" name='entrusted_payment' value={values.form_no}
                        onChange={e => setFieldValue('form_no', e.target.value)}>
                        {List.map(item => {
                          return (
                            <FormControlLabel
                              key={item.id}
                              name={item.id}
                              value={item.id}
                              disabled={exercise.save === '3'}
                              control={<Radio />}
                              label={item.text} />
                          )
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      rows='4'
                      label={check_opinion_label(values.form_no)}
                      value={values.check_opinion}
                      name='check_opinion'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      margin='normal'
                      fullWidth
                      disabled={false}
                    />
                    <FormikError touched={touched} errors={errors} name={'check_opinion'} />
                  </Grid>
                  {
                    (values.form_no === '2' && rolesId !== 1) &&
                    <FormControl style={{ width: '100%', margin: '10px 0' }}>
                      <InputLabel id="demo-simple-select-label">{form2.returnBy}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="returnBy"
                        value={values.returnBy}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {
                          userList.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  }
                  {
                    status(handleSubmit, isSubmitting, values)
                  }
                </div>
              }

            {exercise.save !== '3' && rolesId === 1&& 
                <div>  
                  {(values.table !== 'r4-4-1' && values.table !== 'r4-4-4' && rolesId === 1) &&
                <Grid item xs={12} >
                <FormControl className={classes.textField}>
                <InputLabel id="demo-simple-select-label">{'冻结金额'}</InputLabel>
                  <Input
                    label='冻结金额'
                    name='beFrozenAmount'
                    value={values.beFrozenAmount}
                    //onBlur={e => setFieldValue('beFrozenAmount', this.handleBlur2.bind(this, 'beFrozenAmount', e.target.value)())}
                    onChange={e => setFieldValue('beFrozenAmount', this.handleChange3.bind(this, 'beFrozenAmount', e.target.value)())}
                    inputProps={{ maxLength: 50 }}
                    endAdornment={<InputAdornment position="end">元</InputAdornment>}
                    disabled={false}
                    />
                    </FormControl>
                    <FormikError touched={touched} errors={errors} name={'beFrozenAmount'} />
                </Grid>
                  }
                  { (values.table === 'r4-4-1' ||  values.table === 'r4-4-4' && rolesId === 1) &&
                <Grid item xs={12} >
                <FormControl className={classes.textField}>
                <InputLabel id="demo-simple-select-label">{'解冻金额'}</InputLabel>
                  <Input
                    label='解冻金额'
                    name='thawAmount'
                    value={values.thawAmount}
                    //onBlur={e => setFieldValue('thawAmount', this.handleBlur2.bind(this, 'thawAmount', e.target.value)())}
                    onChange={e => setFieldValue('thawAmount', this.handleChange3.bind(this, 'thawAmount', e.target.value)())}
                    inputProps={{ maxLength: 50 }}
                    endAdornment={<InputAdornment position="end">元</InputAdornment>}
                    disabled={false}
                    />
                    </FormControl>
                    <FormikError touched={touched} errors={errors} name={'thawAmount'} />
                </Grid>
                  }
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      rows='4'
                      label={form2.remarks}
                      value={values.remarks}
                      name='remarks'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      margin='normal'
                      fullWidth
                      disabled={false}
                    />
                    <FormikError touched={touched} errors={errors} name={'remarks'} />
                  </Grid>
                  {
                    (values.form_no === '2' && rolesId !== 1) &&
                    <FormControl style={{ width: '100%', margin: '10px 0' }}>
                      <InputLabel id="demo-simple-select-label">{form2.returnBy}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="returnBy"
                        value={values.returnBy}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {
                          userList.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  }
                  {
                    status(handleSubmit, isSubmitting, values)
                  }
                </div>
              }
              
            </form>
          )}
      />

    )
  }
}

export default withStyles(useStyles)(FreezingCheckForm)
