// 注册-核心企业注册表单
export default {
  // first step  EnterpriseForm.js
  enterprise_name: "企业名称",
  cell: "手机号",
  password: "设置密码",
  validate_code: "确认密码",
  graph_validate_code: "图形验证码",
  cell_validate_code: "手机验证码",
  invite_cell_code: "邀请手机号或邀请码",
  invite_people: "无邀请人",

  unified_social_credit_code: "统一社会信用代码",
  legal_representative_name: "法定代表人姓名",
  legal_representative_certification_type: '证件类型',
  legal_representative_certificate_no: "法定代表人证件号",
  operator_name: "经办人姓名",
  operator_certification_type: '证件类型',
  operator_certificate_no: "经办人证件号",
  certification_type1: '身份证',

  upload_files: "上传法人章",


  personName: "法人姓名",
  type: '认账类型',
  certType: '证件类型',
  idCard: '身份证号',
  mobile: '手机号',
  auditFailReason: '不通过原因',
  idCardPhotoPath: '身份证图片',
  fork: '民族',
  birthday: '出生日期',
  sex: '性别',
  idCardDate: '证件有效期',
  issueAuthority: '颁发机构',
  personAddress: '地址',

  // last step  MoreInfoForm.js
  operator_email: "经办人邮箱",
  registry_address: "注册地址",
  contact_address: "通讯地址",
  issue_invoice_desc: "以下信息为开具增值税发票所用，请确保与税务部门备案一致",
  issue_invoice_enterprise_full_name: "企业全称",
  tin: "纳税人识别号",
  address: "地址",
  enterprise_cell: "电话",
  bank: "开户银行",
  bank_account: "开户银行账号",
  send_invoice_desc: "以下信息为寄送发票所用，请确保准确",
  send_invoice_enterprise_full_name: "企业全称",
  receiver_name: "收信人姓名",
  receiver_cell: "收信人电话",
  receiver_address: "收信人地址",
  province: "省份",
  market: "市",
  county: "县",
  information: "以下信息为企业信息",
  enterprise_size: "企业规模",
  industry_division: "行业划分",
  enterprise_nature: "企业性质"
}