//融单开立表单
export default {
    repayment_ways: "还款方式",
    mx_number: "融单编号",
    // mx_amount: "融单金额（元）",
    amount: "还款金额（元）",
    receive: "还款公司",
    payment_day: "承诺付款日",
    remarks: "备注",
    initiate: "提交",
    check_opinion:"审核意见",//还款审批恢复额度--20201222
    //check_opinion:"复核员意见",
}