//融单流转表单
export default {
    financingNo:"融资申请编号*",
    financingAmount: "融资申请金额*",
    receivableNo: "应收账款编号*",
    contractNo: "合同编号*",
    rate: "融资利率*",
    overdueRate: "逾期利率*",
    financingRatio: "折扣率*",
    loanAccountBank: "放款账号开户行*",
    loanAccountBankName:'放款账号开户名*',
    loanAccountNo: "放款账号*",
    acctAccountBank: "还款账号开户行*",
    acctAccountBankName: "还款账号开户名*",
    acctAccountNo: "还款账号*",
    Upload_contracts: "上传合同*",
    Upload_invoice: "上传发票*",
    Upload_dispatch: "上传发货单",
    Upload_shipping: "上传运输单",
    synthesis_rate: "预计融资息费率*",
    remarks: "备注",

     //静态资源 不用在数据库里面加这些字段
     sign_agreement2: "《融单流转协议》*",
     view_agreement: "查看融单流转协议",
     view_case: "查看案例",
     check_opinion: "审核意见",

}