import React, { Component } from 'react'
import Select from 'react-select';
import { fetching } from '../../../config/utils';
// import { fetching } from '../../config/utils';
import {
  TextField,
  Button,
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import SimpleExpansionPanel from '../Panels'

const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 0,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }

}));
class Form extends Component {
  state = {
    form: this.getInitState(),
    formStatic: this.props.formStatic || {},//静态资源
  }
  async componentDidMount() {
    this.queryEnterpriseInfo();
  }
  //如果有异步设置state值的情况，在组件销毁时清除所有的state状态
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }
  queryEnterpriseInfo = () => {
    fetching('msapi/credit2/find?status_id=9', {
      method: 'GET',
    }).then(res => {
      if (res) {
        try {
          let datas = res.filter(item => {
            item.value = item.enterprise_name;
            item.label = item.enterprise_name;
            return item.enterprise_no ? item : null;
          })
          this.setState({ selectCustomerList: datas });
        } catch (error) {
        }
      } else {
      }
    })
  }
  getInitState() {
    const form = {
      select_organization: '',
      select_customer: '',
      organization_name: '',
      organization_no: '',
      enterprise_name: '',
      enterprise_no: '',
      start_period: '',
      end_period: '',
      input_time_interval: '',
      enterprise_type: '',
    }
    return form
  }
  handleChange = (e) => {
    const { form } = this.state
    const { name, value } = e.target;
    form[name] = value;
    this.setState({ form: { ...form } })

  }
  //保存 提交
  handleSubmit = () => {
    // let form = this.state.form;
  }
  handleReset = () => {
    let form = this.getInitState();
    this.setState({ form: { ...form } })
  }
  handleExport = () => {

  }

  render() {

    const { formStatic, form, selectCustomerList } = this.state
    const { classes } = this.props;
    return (
      <form>
        <SimpleExpansionPanel title="查询条件">

          <Grid container justify="space-between">
            <Grid item xs={5}>
              <div style={{ margin: '5px 0', textAlign: 'left' }}>
                <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_organization}</span>
                <Select
                  isSearchable
                  value={form.select_organization}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  options={selectCustomerList}

                />
              </div>
            </Grid>
            <Grid item xs={5}>
              <TextField
                label={formStatic.organization_no}
                value={form.organization_no}
                name='organization_no'
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                margin='normal'
                inputProps={{ maxLength: 50 }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid item xs={5}>
              <div style={{ margin: '5px 0', textAlign: 'left' }}>
                <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_customer}</span>
                <Select
                  isSearchable
                  value={form.select_customer}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  options={selectCustomerList}

                />
              </div>
            </Grid>
            <Grid item xs={5}>
              <TextField
                label={formStatic.enterprise_no}
                value={form.enterprise_no}
                name='enterprise_no'
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                margin='normal'
                inputProps={{ maxLength: 50 }}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container justify="space-between">
            <Grid item xs={5}>
              <TextField
                className={classes.top}
                label={formStatic.start_period}
                value={form.start_period || ''}
                name='start_period'
                onChange={this.handleChange}
                // onBlur={handleBlur}
                margin='normal'
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                className={classes.top}
                label={formStatic.end_period}
                value={form.end_period || ''}
                name='end_period'
                onChange={this.handleChange}
                // onBlur={handleBlur}
                margin='normal'
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid item xs={5}>
              <TextField
                className={classes.top}
                label={formStatic.input_time_interval}
                value={form.input_time_interval || ''}
                name='input_time_interval'
                onChange={this.handleChange}
                // onBlur={handleBlur}
                margin='normal'
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                className={classes.top}
                label={formStatic.enterprise_type}
                value={form.enterprise_type || ''}
                name='enterprise_type'
                onChange={this.handleChange}
                // onBlur={handleBlur}
                margin='normal'
                fullWidth
              />
            </Grid>
          </Grid>
          <div style={{ padding: '10px 0' }}>
            <Button
              color='primary'
              variant='contained'
              onClick={this.handleSubmit}
              name={formStatic.query}
              style={{ marginRight: '10px' }}
            >
              {formStatic.query}
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={this.handleReset}
              name={formStatic.reset}
              style={{ marginRight: '10px' }}
            >
              {formStatic.reset}
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={this.handleExport}
              name={formStatic.export}
              style={{ marginRight: '10px' }}
            >
              {formStatic.export}
            </Button>
          </div>
        </SimpleExpansionPanel>
      </form>

    )
  }
}

export default withStyles(useStyles)(Form);



