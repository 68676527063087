// import React from 'react';
// import TabPanels from './TabPanels'
import getSubRouters from './helper';

const PARENT_PATH = '/customer-info/客户基本信息';
const PATH = '基础信息';

export const subRootAry = [
  "客户概况",
  "营业执照",
  "验资信息",
  "联系方式",
  "公司章程",
  "客户信息补录",
  "内外部客户",
  "企业管理员账号"
];
// option 1:
// export function tabPanels(ary = []) {
//   return <TabPanels ary={ary} />;
// }

export default getSubRouters(PARENT_PATH, PATH, subRootAry);

