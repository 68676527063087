//融单开立表单
export default {
    extraction: "开立人*",
    open_credit_code: "开立人统一社会信用代码*",
    receive: "接收人*",
    receive_credit_code: "接收人统一社会信用代码*",
    usable_amount: "可用额度*",
    usable_amount_capital: "可用额度大写*",
    amount: "金额*",
    amount_capital: "金额大写*",
    sign_agreement: "签订《融单开立协议》*",
    open_day: "开立日*",
    payment_day: "承诺付款日*",
    view_agreement: "查看协议",
    Upload_contracts: "上传合同*",
    Upload_invoice: "上传发票*", 
    Upload_dispatch: "上传发货单*",
    Upload_shipping: "上传运输单*",
    view_case: "查看案例",
    synthesis_rate: "预计综合融资息费率（%)*",
    check_opinion: "审核意见",
    remarks: "备注"
}