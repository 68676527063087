import React, { Component } from 'react'
import moment from 'moment'
import { Formik } from 'formik';
import {
  TextField,
  Button,
  Grid,
  Typography
} from '@material-ui/core'

import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Upload } from '../../components/upload';
import Select from 'react-select';
import { numberfun, numberParseChina, commafy, delcommafy } from '../../components/numToText'
import { formatDate } from '../../components/date'
import FormikError from './formikError';
import { circulationFormik } from './formikValidate';
import SimpleExpansionPanel from './Panels';
import { fetching, sleep } from '../../config/utils';
import { DEFAULT_CONTRCT_URL } from '../../config/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { DEFAULT_UPLOADS_URL } from '../../config/constants'

const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
const { object } = require('yup');
class Form extends Component {
  state = {
    form: this.getInitState(),
    form2: this.props.form2 || {},
    open2: false,
    opinion_list: [],
    uploads: [],
    loading: false,
    loading2: false,
    loadingDsb: false,
    mxNumberList: [] //可用融单编号
  }

  getInitState() {
    const { exercise, auth = {} } = this.props
    let receivableNo = exercise.data.receivableNo;
    let contractNo = exercise.data.contractNo;
    let coreCompanyName = exercise.data.coreCompanyName;
    let coreCompanyCode = exercise.data.coreCompanyCode;
    let companyName = exercise.data.companyName;
    let companyCode = exercise.data.companyCode;
    let receivableAmount = exercise.data.receivableAmount;
    let applyAmount = exercise.data.applyAmount;
    let beginDate = exercise.data.beginDate;
    let endDate = exercise.data.endDate;
    const form = {
      receivableNo,
      contractNo,
      coreCompanyName,
      coreCompanyCode,
      companyName,
      companyCode,
      receivableAmount,
      applyAmount,
      beginDate,
      endDate,
      Upload_contracts: "", //上传合同
      Upload_invoice: "", //上传发票
      Upload_contracts_invoice: "", //上传合同编号
      Upload_rest: "",//其他
      sign_agreement: '1', //签订协议
      DialogTitle: "",
      status2: '1', //判断是开具还是复核
      save: "1", //判断是新增还是编辑
      imgUrl: '',
      table: exercise.table || ''
    }
    const form2 = (exercise.save !== '1' && exercise.save !== '4') ? {
      ...form, ...exercise, 
    } : form;

    return form2;
  }

  componentDidMount() {
    const { exercise, auth } = this.props;
    let receivableNo = exercise.data.receivableNo;
    fetching(`/sl/slFindReceivableFile?receivableNo=${receivableNo}`).then(res => {
      if (res) {
        let uploads = []
        uploads = res.data
        this.setState({ uploads })
      }
    })
  }


  //查看协议
  viewAgreement = () => {
    const { fdd_file_path = '' } = this.state.form
    if (fdd_file_path && fdd_file_path !== 'null') {
      window.open(fdd_file_path)
    } else
      window.open(DEFAULT_CONTRCT_URL + 'contract/template/lz.pdf')
  }

  //查看实例
  viewFile = (type) => {
    const { uploads = [] } = this.state;
    for (let i = 0; i < uploads.length; i++){
      let imgUrl2 = uploads[i].fpath ? DEFAULT_UPLOADS_URL + uploads[i].fpath : '';
      let typeIndex = imgUrl2.lastIndexOf('.');
      if (typeIndex !== -1) {
        if (uploads[i].type === type){
          console.log(imgUrl2)
          window.open(imgUrl2, '_blank' )
        }
      } 
    }
  }
    



  render() {
    const { amount_capital } = this.state.form;
    const { mxNumberList, opinion_list } = this.state;
    const { form2, form, uploads, loading, loadingDsb } = this.state;
    const { exercise,} = this.props;
    // const roleId = auth.roles ? auth.roles[0].id : '';
    // const disableds = table === 'm3-1-8' ? loading : true

    const status = (handleSubmit, values) => {
      if (exercise.status2 === '1') {
        return <Grid container justify="center" style={{ width: '100%', padding: '10px 0' }}>

          <div style={{ position: 'relative' }}>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSubmit}
              disabled={loadingDsb}
              name={form2.submit}
              style={{ marginRight: '10px' }}
            >
              {form2.submit}
            </Button>
            {loading && <CircularProgress size={24} style={loadingStyle} />}
          </div>

          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.revocation)}
            name={form2.revocation}
            disabled={loadingDsb}
            style={{ marginRight: '10px' }}
          >
            {form2.revocation}
          </Button>
        </Grid>
      }
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        //表单验证
        validationSchema={object().shape(circulationFormik(form2))}
        onSubmit={(
          values
        ) => {
          this.handleSubmit(values, '提交');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          // isSubmitting,
        }) => (
            <form>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.receivableNo}
                    value={form.receivableNo}
                    name='mx_payer'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'receivableNo'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.contractNo}
                    value={form.contractNo}
                    name='payer_credit_code'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'contractNo'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.receivableAmount}
                    value={form.receivableAmount}
                    name='mx_amount'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'receivableAmount'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.applyAmount}
                    value={form.applyAmount}
                    name='usable_amount'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'applyAmount'} />
                </Grid>

              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.coreCompanyName}
                    value={form.coreCompanyName}
                    name='mx_payer'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'coreCompanyName'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.coreCompanyCode}
                    value={form.coreCompanyCode}
                    name='payer_credit_code'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'coreCompanyCode'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.companyName}
                    value={form.companyName}
                    name='extraction'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'companyName'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.companyCode}
                    value={form.companyCode}
                    name='open_credit_code'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'companyCode'} />
                </Grid>

              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                  <Grid container justify="space-between">
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline"
                        label={form2.beginDate}
                        disabled={exercise.save === '3'}
                        value={values.beginDate}
                        onChange={e => setFieldValue('beginDate', e)}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'beginDate'} />
                    </Grid>
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        disabled={true}
                        id="date-picker-inline"
                        label={form2.endDate}
                        value={form.endDate}
                        onChange={e => setFieldValue('endDate', e)}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'endDate'} />
                    </Grid>

                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <SimpleExpansionPanel title="附件*">
                <Grid container justify="space-between">
                  <Grid item xs={12}>
                 
                  <Button
                 onClick={() => this.viewFile("1")}
                 >
                 下载合同
                 </Button>
                 <Button
                 onClick={() => this.viewFile("2")}
                 >
                 下载运输凭证
                 </Button>
                 <Button
                 onClick={() => this.viewFile("3")}
                 >
                 下载验收凭证（化验单）
                 </Button>
                 <Button
                 onClick={() => this.viewFile("4")}
                 >
                 下载货转凭证
                 </Button>
                 <Button
                 onClick={() => this.viewFile("5")}
                 >
                 下载核算表
                 </Button>
                 <Button
                 onClick={() => this.viewFile("6")}
                 >
                 下载确认函
                 </Button>
                 <Button
                 onClick={() => this.viewFile("7")}
                 >
                 下载结算单
                 </Button>
                 <Button
                 onClick={() => this.viewFile("8")}
                 >
                 下载发票
                 </Button>
                 <Button
                 onClick={() => this.viewFile("9")}
                 >
                 下载其他材料
                </Button>
                  </Grid>
                </Grid>
              </SimpleExpansionPanel>
            </form>
          )}
      />
    )
  }
}

export default Form
