import React, { Component } from 'react'
import moment from 'moment'
import { Formik } from 'formik';
import {
  TextField,
  Button,
  Grid,
  Typography,
  LinearProgress,
  Box
} from '@material-ui/core'

import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Upload } from '../../components/upload';
import Select from 'react-select';
import { numberfun, numberParseChina, commafy, delcommafy } from '../../components/numToText'
import { formatDate } from '../../components/date'
import FormikError from './formikError';
import { circulationFormik } from './formikValidate';
import SimpleExpansionPanel from './Panels';
import { fetching } from '../../config/utils';
import { DEFAULT_CONTRCT_URL } from '../../config/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

global.count = 0;
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
const { object } = require('yup');
class Form extends Component {
  state = {
    form: this.getInitState(),
    form2: this.props.form2 || {},
    open2: false,
    opinion_list: [],
    uploads: {},
    loading: false,
    loading2: false,
    loadingDsb: false,
    mxNumberList: [] //可用融单编号
  }

  getInitState() {
    const { exercise, auth = {} } = this.props
    let Upload_contracts='';
    let Upload_invoice='';
    let Upload_contracts_invoice='';
    let Upload_rest='';
    const open_day = new Date(); //获取当时间
    const mx_number = exercise.mx_number ?
      { id: exercise.mx_number, mx_number: exercise.mx_number, mxc_number: exercise.mxc_number } : '';
    let mx_payer = '';
    let payer_credit_code = '';
    let mx_amount = exercise.mx_amount ? commafy(exercise.mx_amount) : 0;//授信额度
    let usable_amount = exercise.usable_amount ? commafy(exercise.usable_amount) : 0;//可用额度
    let amount = exercise.amount ? commafy(exercise.amount) : '';
    let synthesis_rate = exercise.synthesis_rate || 5.8;
    let extraction = exercise.extraction || '';
    let open_credit_code = exercise.open_credit_code || '';
    let payment_day = exercise.payment_day || open_day;
    //const ocid_from = exercise.mxc_number ? 'circulation' : 'issue';
   //退回编辑需要  显示初始文件
    if(exercise.save === '2' && auth.roles[0].id === 11){
       exercise.upload.forEach(item => {
        switch(item.field){
          case 'Upload_contracts':
            Upload_contracts =item ? item : '';
            break;
          case 'Upload_invoice':
            Upload_invoice =item ? item : '';
            break;
          case 'Upload_contracts_invoice':
            Upload_contracts_invoice = item ? item : '';
            break;
          case 'Upload_rest':
            Upload_rest =item ? item : '';
            break;
        default: ;
        }   

     })
    }
    var str = exercise.mxc_number;
    var ocid_from = undefined;
    if (RegExp(/RD2/).test(str)) {
       ocid_from = 'circulation' ;
    } else{
       ocid_from = 'issue';
    }
    const ocid = exercise.id || ''
    let mxc_number = exercise.mxc_number || '';
    if (auth.organization) {
      mx_payer = auth.organization.name;
      payer_credit_code = auth.organization.credit_no;
    }
    if (exercise.save === '4') {
      amount = '';
    }
    const form = {
      mx_number,//融单编号
      mx_payer,//融单流转方
      ocid,
      mxc_number,
      payer_credit_code,//支付方统一社会信用代码
      synthesis_rate,//预计综合融资息费率（%)
      //flow_date: open_day,//流转日期
      payment_day,//承诺付款日
      extraction,//开立方
      open_credit_code,//开立方信用代码
      mx_amount,//授信额度
      usable_amount,//可用额度
      ocid_from,

      receive: '',//接收方
      receive_credit_code: '',//接收方信用代码
      amount,//金额
      amount_capital: '', //金额大写
      remarks: '',  //备注
      Upload_contracts: "", //上传合同
      Upload_invoice: "", //上传发票
      Upload_contracts_invoice: "", //上传合同编号
      Upload_rest: "",//其他
      sign_agreement: '1', //签订协议
      DialogTitle: "",
      status2: '1', //判断是开具还是复核
      save: "1", //判断是新增还是编辑
      imgUrl: '',
      table: exercise.table || ''
    }

    if (auth.roles[0].id === 11 && exercise.upload_contracts === 1) {
      form.Upload_contracts = exercise.upload_contracts;//上传合同
      form.Upload_invoice = exercise.upload_invoice; //上传发票
      form.Upload_contracts_invoice = exercise.upload_contracts_invoice; //上传合同编号
      form.Upload_rest = exercise.upload_rest;//其他
      } else {
      form.Upload_contracts = "";//上传合同
      form.Upload_invoice = ""; //上传发票
      form.Upload_contracts_invoice =  ""; //上传合同编号
      form.Upload_rest = "";//其他
      }

    if (exercise.save === "3") { //流转日期查看不自动获取当前服务器时间;
      form.flow_date = exercise.open_day || exercise.flow_date || "";
    }  else {
      form.flow_date = open_day;
    }
    const form2 = (exercise.save !== '1' && exercise.save !== '4') ? {
      ...form, ...exercise, mx_number, amount, mx_amount, usable_amount, Upload_contracts,Upload_invoice,Upload_contracts_invoice,Upload_rest,
      amount_capital: numberParseChina(exercise.amount)
    } : form;
    




    return form2;
  }
  componentDidMount() {
    try {
      const { auth = {}, exercise } = this.props;

      //增加[可用额度]检测校准--20210115
      /*if (exercise.save === '4' && auth.department.id === 7) {//融资新增：[可用融单]-融资-save=4
        const mxNumber = exercise.mx_number;
        const mxcNumber = exercise.mxc_number;
        const usedAmount = exercise.used_amount;
        const mxAmount = exercise.mx_amount;
        const id = exercise.id;
        fetching(`/amount/usedAmount/getUsedAmount?mxNumber=${mxNumber}&mxcNumber=${mxcNumber}&usedAmount=${usedAmount}&mxAmount=${mxAmount}&id=${id}`).then(res => {
          if (res.code === 200) {
            var usableAmount = Number(mxAmount) - Number(res.data);
            this.state.form.usable_amount = usableAmount;
          } else {
            alert(res.msg || '可用额度查看失败')
          }
        })
      }*/
      // const { form } = this.state;
      if (exercise.save === '3') {
        //获取金额
        // fetching('/api/issue/find?mx_number=' + exercise.mx_number).then(res => {
        //   if (res && res.length > 0) {
        //     let { amount = 0, usable_amount = 0, mx_amount = 0 } = res[0];
        //     this.setState({
        //       form: { ...form, mx_amount: commafy(mx_amount), usable_amount: commafy(usable_amount) }
        //     })
        //   }
        // })
        //获取审核意见
        let department_id = ``;
        if (auth.department.id === 7) department_id = `&department_id=${auth.department.id}`
        fetching(`/msapi/cflow/find?mx_number=${exercise.mx_number}&cid=${exercise.id}&${department_id}`).then(res => {
          if (res) {
            this.setState({ opinion_list: res })
          }
        })
        //获取图片
        fetching('/msapi/upload/find?circulation_id=' + exercise.id, { method: 'GET' }).then(data => {
          if (Array.isArray(data) && data.length > 0) {
            let uploads = {}
            data.forEach(item => {
              uploads[item.field] = item.fpath;
            })
            this.setState({ uploads })
          }
        })
      } else {
        let query = `/msapi/issue/find?receive_credit_code=${auth.organization.credit_no}&status_id=5`;
        let query2 = `/msapi/circulation/find?receive_credit_code=${auth.organization.credit_no}&status_id=5`;
        Promise.all([fetching(query), fetching(query2)]).then(values => {
          let data = []
          values.forEach(item => {
            if (item instanceof Array) {
              data = data.concat(item);
            }
          })
          this.setState({ mxNumberList: data });
        });
      }

    } catch (e) { }
  }
  handleChange = (name, value) => {
    let value2 = value;
    let amount_capital = this.state.form.amount_capital;
    if (name === 'amount') {
      //value2 = numberfun(value2, 12);
      value2 = numberfun(value2, 12,2);
      amount_capital = numberParseChina(value2);
    }
    this.setState({
      form: { ...this.state.form, [name]: value2, amount_capital }
    })
    return value2;
  }
  //输入框失去焦点
  handleBlur2 = (value) => {
    return commafy(value);
  }
  //保存 提交
  handleSubmit = async (form2, name) => {
    this.setState({ loading: true, loadingDsb: true });
    const dataList = [
      'contractId','invoiceId','ciId','otherId',
      'synthesis_rate', 'extraction', 'open_credit_code',
      'usable_amount', 'payment_day', 'mx_amount', 'mxc_number', 'receive_credit_code'];
    const amountList = ['amount', 'usable_amount', 'mx_amount']; //增加千位符
    const { id, amount_capital } = this.state.form;
    const { auth } = this.props;
    form2.receive = this.state.form.receive;//接收方以organizations查到的为准  20240307
    form2.extraction = this.state.form.extraction;
    form2.open_credit_code = this.state.form.open_credit_code;
    form2.ocid = this.state.form.ocid;
    //form2.ocid_from = this.state.form.mxc_number ? 'circulation' : 'issue' ;
    if(this.state.form.save === '2' && auth.roles[0].id === 11){//此时为编辑
      form2.ocid_from = this.state.form.ocid_from;
    }
    let form = form2 ? { ...form2 } : this.state.form;
    const text = name || '取消';
    let ids = id ? id : '';
    let method =  'POST'; //id ? 'PUT' : 'POST';
    // let method = save === '2' && form2.status !== '已保存' ? 'PUT' : 'POST';
    // let ids = save === '2' && form2.status !== '已保存' ? id : '';
    form.amount_capital = amount_capital;
    dataList.forEach(item => form[item] = this.state.form[item])




    //提交表单
    if (text === '提交' || text === '保存') {
      this.setState({ loading: true, loadingDsb: true });
      let fdate1 = moment(new Date()).format('YYYY-MM-DD')
      let fdate2 = moment(form.flow_date).format('YYYY-MM-DD')
       if (moment(form.payment_day).isBefore(moment(new Date()))) {
         alert('承诺付款日已过期');
         return false;
       } else if (fdate2 < fdate1) {
        alert('流转日期不能小于当前日期');
	      return false;
      }
      if (form.flow_date instanceof Object) {
        form.flow_date = formatDate(form.flow_date, 'yyyy-MM-dd hh:mm:ss');
      }
      const organization = await fetching(`/msapi/organizations?credit_no=${form.receive_credit_code}`);
      amountList.forEach(item => {
        form[item] = delcommafy(form[item]);
      })
      if (form.usable_amount < form.amount) {
         alert('流转金额不能大于可用融单金额');
       } else if (form.amount <= 0) {
         alert('流转金额必须大于0');
       } else if (organization.length < 1 && text === '提交') {
         alert(`接收人企业没有进行注册`);
      } /*else if (form.open_credit_code === form.receive_credit_code) {
          alert('流转接收方不能为融单开立方,请更换接收方');
      } */else if (auth.organization.credit_no === form.receive_credit_code) {
        alert(`接收人不能是自己`);
      } else {
        form.mx_number = form.mx_number.mx_number || '';
        form.auth = auth;//用户信息
        if (text === '提交') {
          form.status_id = form.statusId ? form.statusId : 2;
          form.type = 3;
          this.setState({ loading: true, loadingDsb: true })
        }
        else if (text === '保存') {
          form.status_id = 1;
          form.type = 2;
          this.setState({ loading2: true, loadingDsb: true })
        }
        if (!form.mxc_number) {
          delete form.mxc_number
        }
        const excludes = ['DialogTitle', 'status2', 'save', 'imgUrl', 'formDates', 'table', 'mxNumberList', 'check_opinion', 'open',
          'checkOpen'];
        form.Upload_contracts=true;
        form.Upload_invoice=true;
        form.Upload_contracts_invoice=true;
        if(form.otherId){
          form.Upload_rest=true;
        }else{
          form.Upload_rest=false;
        }
        // const formData = new FormData();
        // for (let name in form) {
        //   if (!excludes.includes(name)) formData.append(name, form[name]);
        // }
        // let fddform = {
        //   extraction: form.mx_payer,
        //   receive: form.receive,
        //   mx_number: '',
        //   open_credit_code: form.payer_credit_code,
        //   receive_credit_code: form.receive_credit_code,
        //   parameter_map: {}
        // };

        //流转提交之间。增加excel拦截校验--20210121
        //增加善美开立/流转/融资，发票数据上传时，数据格式，数据重复，当次使用累计金额与融单金额的比较查验拦截，将原有发票金额检测进行拆分调整
        //const formDataCheck = new FormData();
        //判断退回编辑时是否为null
        if(form.ciId === null){
          if(this.state.form.Upload_contracts_invoice.id){
            form.ciId =this.state.form.Upload_contracts_invoice.id;
            }
          }
        const formCheck = {
          organization_id: auth.organization.id,
          amount: form.amount,//金额
          contractsInvoiceId: form.ciId ,//excel上传文件id  1.28 zhao
        };
        // for (let name in formCheck) {
        //   if (!excludes.includes(name) && formCheck[name] && formCheck[name] !== 'null') formDataCheck.append(name, formCheck[name]);
        // }
        //let methodCheck = 'POST';
        fetching('/smrd/fapiao_org/check_excels', {
          method:"post",
          body: JSON.stringify(formCheck),
        }).then(res => {
          if(res !== null && res !== undefined){
          if (res.msg) {
            alert(`excel中数据检测不通过：,${res.msg}`)
            this.setState({ loading: false, loading2: false, loadingDsb: false, })
            return false;
          }
        } else {
          alert("请检验excel表是否符合模板规范")
          return false;
        }

        fetching('/msapi/circulation/save/' + ids, {
          method:"post",
          // token: this.state.hasToken,
          body: JSON.stringify(form)//formData
        }).then(res => {
          this.setState({ loading: false, loading2: false, loadingDsb: false, })
          try {
            if (res) {
              if (res.msg) {
                alert(`融单流转失败,${res.msg}`)
                return false;
              }
              this.props.onSubmit({
                ...form,
                submit_name: text
              })
              alert('融单流转成功')
            } else {
              alert('融单流转失败')
            }
          } catch (error) { console.log(error) }
        })
      });

      }

    } else {
      this.setState({ loading: false, loading2: false, loadingDsb: false });
      await this.props.onSubmit({
        ...form,
        submit_name: text
      })
    }
  }
  uploadFile(e){
    let form = this.state.form;
    var fordata = new FormData();
    if (form.status_id === 5 ) {//补充上传文件
        fordata.append('type',3);
        fordata.append('fileName',e.file);
        fordata.append('fileSource',e.uploadName);
        fordata.append('id',form.id);
        fordata.append('uploadFileName',e.file.name);
        const options = {
          method: "POST",
          body: fordata
        }
        global.count = (global.count+1)
        return fetch(`/msapi/upload/reUpload`,options) 
    } else {
      fordata.append('type',3);
      fordata.append('fileName',e.file);
      fordata.append('fileSource',e.uploadName);
      fordata.append('uploadFileName',e.file.name);
      const options = {
        method: "POST",
        body: fordata
      }
      global.count = (global.count+1)
      return fetch(`/msapi/upload/upload`,options)
    }
  }
  ///上传文件并更新上传id
  uploadHandler = async (e,type) => {
	this.setState({progressLength : 0})
    	this.setState({ progress: true })
    	const timer = setInterval(() => {
     	 this.setState({progressLength : (this.state.progressLength >= 99 ? 99 : this.state.progressLength + 1) })
   	 }, 30);
    let indexId = '';
    indexId = await this.uploadFile(e).then(res => {
      if (res) {
        if (!res.status){
          alert(res.msg)         
        }else{
        global.count = global.count - 1
          return res.json()
        }
      }else{
        return null
      }
    }).then(r=>{      
      if (r.error.code === 200) {//无错
          clearInterval(timer);
          this.setState({progressLength : 100})
          setTimeout(() => {
            alert("文件上传成功");
            this.setState({ progress: false })
          }, 500)
          return r.data
        } else {//业务失败
          alert(r.error.message)
          this.setState({ progress: false })
          return null;
        }
    }) 
    if(type === 1){
      this.state.form.contractId = indexId
    }else if(type === 2){
      this.state.form.invoiceId = indexId
    }else if(type === 3){
      this.state.form.ciId = indexId
    }else if(type === 4){
      this.state.form.otherId = indexId 
    }
    return true
    
  }
  //查看协议
  viewAgreement = () => {
    const { fdd_file_path = '' } = this.state.form
    if (fdd_file_path && fdd_file_path !== 'null') {
      window.open(fdd_file_path)
    } else
      window.open(DEFAULT_CONTRCT_URL + 'contract/template/lz.pdf')
  }
  //接收方失去焦点
  handleBlur3 = (v) => {
 if (v !== "" ) {
   v=v.replace(/\s+/g,"")
   /* fetching(`/msapi/organizations?name=${v}`).then(res => {
      if (res.length > 0) {
        this.setState({
          form: { ...this.state.form, receive_credit_code: res[0].credit_no }
        })
      } else {
        this.setState({
          form: { ...this.state.form, receive_credit_code: '' }
        })
      }
    });*/
  fetching(`/msapi/customer_info_add/legalPersonAuthorizationPeriod/getLgAuthorStatusMsg?name=${v}`).then(res => {
    if (res !== undefined && res.data !== null ) {
      if (res.data.status === "1") {
      fetching(`/msapi/organizations?name=${v}`).then(res => {
        if (res !== undefined && res.length > 0) {
          this.setState({
            form: { ...this.state.form, receive_credit_code: res[0].credit_no ,receive: res[0].name}
          })
        } else {
          this.setState({
            form: { ...this.state.form, receive_credit_code: '' }
          })
        }
      });
  } else {
    alert("接收人法人授权信息已过期,暂无法接收融单,请联系客户经理处理");
    window.location.reload();
  }
 } else {
   fetching(`/msapi/organizations?name=${v}`).then(res => {
        if (res !== undefined && res.length > 0) {
          this.setState({
            form: { ...this.state.form, receive_credit_code: res[0].credit_no ,receive: res[0].name }
          })
        } else {
          this.setState({
            form: { ...this.state.form, receive_credit_code: '' }
          })
        }
      });
  }
 });
}
    return v;
  }
  //盟雄编号下拉
  handleChange2 = (selectedOption) => {
    this.setState({
      form: {
        ...this.state.form,
        mx_amount: commafy(selectedOption.mx_amount),
        usable_amount: commafy(selectedOption.usable_amount),
        mxc_number: selectedOption.mxc_number || '',
        extraction: selectedOption.extraction || "开立人",//开立方
        open_credit_code: selectedOption.open_credit_code || "开立人信用代码",//开立方信用代码
        synthesis_rate: selectedOption.synthesis_rate || 5.8,//预计综合融资息费率（%)
        payment_day: formatDate(new Date(selectedOption.payment_day), 'yyyy-MM-dd'), //承诺付款日
        ocid: selectedOption.id,
        ocid_from: selectedOption.mxc_number ? 'circulation' : 'issue',
      },
    })
    return selectedOption;
  }
  getOptionLabel = data => {
    if (data.mxc_number) {
      return '流转编号 ' + data.mxc_number
    } else {
      return '融单编号 ' + data.mx_number
    }
  }
  getOptionValue = data => {
    return data.id
  }
  render() {
    const { amount_capital } = this.state.form;
    const { mxNumberList, opinion_list } = this.state;
    const { form2, form, uploads, loading, loadingDsb, progress, progressLength } = this.state;
    const { exercise,} = this.props;
    // const roleId = auth.roles ? auth.roles[0].id : '';
    // const disableds = table === 'm3-1-8' ? loading : true

    const status = (handleSubmit, values) => {
      if (exercise.status2 === '1') {
        return <Grid container justify="center" style={{ width: '100%', padding: '10px 0' }}>

          <div style={{ position: 'relative' }}>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSubmit}
              disabled={loadingDsb}
              name={form2.submit}
              style={{ marginRight: '10px' }}
            >
              {form2.submit}
            </Button>
            {loading && <CircularProgress size={24} style={loadingStyle} />}
      
          </div>

          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.revocation)}
            name={form2.revocation}
            disabled={loadingDsb}
            style={{ marginRight: '10px' }}
          >
            {form2.revocation}
          </Button>
        </Grid>
      }
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        //表单验证
        validationSchema={object().shape(circulationFormik(form2))}
        onSubmit={(
          values
        ) => {
          if(global.count === 0){
            this.handleSubmit(values, '提交');
        }else{
          alert("文件正在上传请稍后!")
        }
         // this.handleSubmit(values, '提交');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          // isSubmitting,
        }) => (
            <form>
              <Grid item xs={12}>
                <div style={{ margin: '26px 0 8px 0', }}>
                  <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form2.mx_number}</span>
                  <Select
                    isSearchable
                    value={values.mx_number}
                    onBlur={handleBlur}
                    getOptionLabel={this.getOptionLabel}
                    getOptionValue={this.getOptionValue}
                    onChange={e => setFieldValue('mx_number', this.handleChange2(e))}
                    options={mxNumberList}
                    isDisabled={(exercise.save === '3' || exercise.save === '4')}
                  />
                </div>
                <FormikError touched={touched} errors={errors} name={'mx_number'} />
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.mx_amount}
                    value={form.mx_amount}
                    name='mx_amount'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'mx_amount'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.usable_amount}
                    value={form.usable_amount}
                    name='usable_amount'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'usable_amount'} />
                </Grid>

              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.mx_payer}
                    value={form.mx_payer}
                    name='mx_payer'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'mx_payer'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.payer_credit_code}
                    value={form.payer_credit_code}
                    name='payer_credit_code'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'payer_credit_code'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.extraction}
                    value={form.extraction}
                    name='extraction'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'extraction'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.open_credit_code}
                    value={form.open_credit_code}
                    name='open_credit_code'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'open_credit_code'} />
                </Grid>

              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                 className="TextFieldUnits2"
                  label={form2.synthesis_rate}
                  value={form.synthesis_rate}
                  name='synthesis_rate'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={true}
                />
                <FormikError touched={touched} errors={errors} name={'synthesis_rate'} />
              </Grid> */}
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.receive}
                    value={values.receive}
                    name='receive'
                    onChange={handleChange}
                    onBlur={e => setFieldValue('receive', this.handleBlur3.bind(this, e.target.value)())}
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'receive'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.receive_credit_code}
                    value={form.receive_credit_code}
                    name='receive_credit_code'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'receive_credit_code'} />
                </Grid>

              </Grid>


              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.amount}
                    value={values.amount}
                    name='amount'
                    onChange={e => setFieldValue('amount', this.handleChange.bind(this, 'amount', e.target.value)())}
                    onBlur={e => setFieldValue('amount', this.handleBlur2.bind(this, e.target.value)())}
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'amount'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    title={amount_capital}
                    label={form2.amount_capital}
                    name='amount'
                    value={amount_capital}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'amount_capital'} />
                </Grid>

              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                  <Grid container justify="space-between">
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd HH:mm:ss"
                        margin="normal"
                        id="date-picker-inline"
                        label={form2.flow_date}
                        disabled={exercise.save === '3'}
                        value={values.flow_date}
                        onChange={e => setFieldValue('flow_date', e)}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'flow_date'} />
                    </Grid>
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd HH:mm:ss"
                        margin="normal"
                        disabled={true}
                        id="date-picker-inline"
                        label={form2.payment_day}
                        value={form.payment_day}
                        onChange={e => setFieldValue('payment_day', e)}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'payment_day'} />
                    </Grid>

                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              {exercise.save === '3' &&
                <SimpleExpansionPanel title="历史审核意见">
                  {
                    opinion_list.map((item, index) => {
                      return (
                        <div style={{ margin: '10px 0' }} key={index}>
                          <Typography component="p">
                            {item.name}
                          </Typography>
                          <Typography component="p">
                            {item.check_opinion}
                          </Typography>
                        </div>
                      )
                    })

                  }
                </SimpleExpansionPanel>
              }
              <SimpleExpansionPanel title="档案登记*">
		 {/* 上传进度条 */}
                { progress &&
                  <div>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate"  value={progressLength}/>
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                      progressLength
                    )}%`}</Typography>
                  </Box>
                </Box>
                </div>
                }
                <Grid container justify="space-between">
                  <Grid item xs={12}>
                    <Upload disabled={exercise.status2 === '2' || (exercise.save === '3' && (exercise.status_id !== 5 || (this.props.auth.roles[0].id !== 1 && this.props.auth.roles[0].id !== 3)) )}
                      uploadName="Upload_contracts"
                      mold="3"
                      uploads={uploads}
                      name={form2.Upload_contracts} view={form2.view_case}
                      exercise={exercise}
                      // uploadChange={e => setFieldValue('Upload_contracts', e.file)}>
                        uploadChange={e=>setFieldValue('Upload_contracts',this.uploadHandler( e, 1))}> 
                      </Upload>
                    <FormikError touched={touched} errors={errors} name={'Upload_contracts'} />
                  </Grid>

                </Grid>
                <Grid item xs={12}>
                  <Upload disabled={exercise.status2 === '2' || (exercise.save === '3' && (exercise.status_id !== 5 || (this.props.auth.roles[0].id !== 1 && this.props.auth.roles[0].id !== 3)) )}
                    uploadName="Upload_invoice"
                    mold="3"
                    uploads={uploads}
                    exercise={exercise}
                    name={form2.Upload_invoice} view={form2.view_case}
                    // uploadChange={e => setFieldValue('Upload_invoice', e.file)}>
                    uploadChange={e=>setFieldValue('Upload_invoice',this.uploadHandler( e, 2))}> 
                    </Upload>
                  <FormikError touched={touched} errors={errors} name={'Upload_invoice'} /> 
                </Grid>

                <Grid container justify="space-between">
                  <Grid item xs={12}>
                    <Upload disabled={exercise.status2 === '2' || (exercise.save === '3' && (exercise.status_id !== 5 || (this.props.auth.roles[0].id !== 1 && this.props.auth.roles[0].id !== 3)) )}
                      uploadName="Upload_contracts_invoice"
                      mold="3"
                      uploads={uploads}
                      exercise={exercise}
                      name={form2.Upload_contracts_invoice} view={form2.view_case}
                      // uploadChange={e => setFieldValue('Upload_contracts_invoice', e.file)}>
                      uploadChange={e=>setFieldValue('Upload_contracts_invoice',this.uploadHandler( e, 3))}> 
                      </Upload>
                    <FormikError touched={touched} errors={errors} name={'Upload_contracts_invoice'} />
                  </Grid>

                </Grid>
                <Grid item xs={12}>
                  <Upload disabled={exercise.status2 === '2' || (exercise.save === '3' && (exercise.status_id !== 5 || (this.props.auth.roles[0].id !== 1 && this.props.auth.roles[0].id !== 3)) )}
                    uploadName="Upload_rest"
                    mold="3"
                    uploads={uploads}
                    exercise={exercise}
                    name={form2.Upload_rest} view={form2.view_case}
                    // uploadChange={e => setFieldValue('Upload_rest', e.file)}>
                    uploadChange={e=>setFieldValue('Upload_rest',this.uploadHandler( e, 4))}> 
                    </Upload>
                  <FormikError touched={touched} errors={errors} name={'Upload_rest'} />
                </Grid>
              </SimpleExpansionPanel>
              <FormikError touched={touched} errors={errors} name={'Upload_contracts'} />
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows='4'
                  label={form2.remarks}
                  value={values.remarks}
                  name='remarks'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'remarks'} />
              </Grid>
              {/* {roleId !== 11 && <div style={{ padding: '10px 0' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.sign_agreement === '1'}
                      disabled={exercise.save === '3'}
                      name='sign_agreement'
                      onChange={e => setFieldValue('sign_agreement', values.sign_agreement === '1' ? '' : '1')} />
                  }
                  label={form2.sign_agreement}
                />
                <span
                  style={{ color: '#f00', cursor: 'pointer' }}
                  onClick={this.viewAgreement}
                >{form2.sign_agreement2}</span>
                <FormikError touched={touched} errors={errors} name={'sign_agreement'} />
              </div>}
              {roleId === 11 && <div style={{ padding: '10px 0' }}>
                <Button
                  // color='primary'
                  variant='contained'
                  onClick={this.viewAgreement}
                  disabled={false}
                >
                  {form2.view_agreement}
                </Button>
              </div>} */}
              {
                status(handleSubmit, values)
              }

            </form>
          )}
      />
    )
  }
}

export default Form
