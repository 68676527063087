import React, { Component } from 'react';
import {
  Button,
  Typography,
  Grid,
} from '@material-ui/core'
import { fetching } from '../../config/utils';
import { C161Form } from './index'
import { r16, common } from '../formStatic'
import { loginInfos } from '../../components/numToText'
import { core_business_url } from '../core_business_url';
import { alertDialog as AlertDialog } from '../../components';

// copied from 05-.../DataView.js
class View extends Component {
  state = {
    columns: [{}],
    data: [],
    hasToken: sessionStorage.getItem("authToken"),
    dataUrl: { url: { query: '' } },
    id: 10000,
    roleList: [],
    form2: { ...common },
    checkExercise: {},
    exercise: {
      save: '1',
      DialogTitle: '新增',
      open: false,
      status2: '1'
    },
    alertInfo: {
      open: false,
      content: '',
    },

  };
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  }
  //获取数据
  componentWillMount() {
    const { table } = this.props;
    let auth = loginInfos();
    const dataUrl = core_business_url(auth, table).find(item => item.name === table);
    this.setState({ auth, dataUrl });
    this.onQuery(dataUrl);
  }

  //查询数据
  onQuery = (dataUrl) => {
    try {
      const { url } = dataUrl || this.state.dataUrl;
      let fetchingData = [fetching(url.query)]
      Promise.all(fetchingData).then(values => {
        let data = []
        values.forEach(item => {
          if (item instanceof Array) {
            data = data.concat(item);
          }
        })
        let formOpen = (data instanceof Array && data.length > 0) ? true : false;
        let data1 = formOpen ? data[0] : {}
        this.setState({ data, exercise: { ...data1, save: formOpen ? '3' : '1', formOpen, } })
      });
    } catch (e) { }
  }


  onRef = (ref) => {
    this.child = ref
  }

  FormSubmit2 = (newData) => {
    newData.formDate = new Date();
    this.onQuery();
  }
  // 对象查找
  findObject = (obj, data) => {
    let index = data.findIndex(item => item.id === obj.id);
    return index;
  }

  handleToggle1 = () => {
    const { exercise } = this.state;
    this.setState({
      checkExercise: { save: '2', },
      exercise: { ...exercise, save: '1', formOpen: true, }
    })
  }

  handleDelete = (e, row) => {
    this.setState({ alertInfo: { ...row, open: true, content: '确定删除该数据吗' } })
  };
  handleConfirm = (alertInfo) => {
    const { dataUrl } = this.state
    if (alertInfo.flag) {
      fetching(dataUrl.url.get + `${alertInfo._id}`, { method: 'GET' }).then(data => {
        this.setState({ alertInfo: { open: false, content: '' } })
        this.onQuery();
      })
    } else {
      this.setState({ alertInfo: { open: false, content: '' } })
    }
  }
  // handleDelete = () => {
  //   const { data, dataUrl } = this.state;
  //   if (data instanceof Array && data.length > 0) {
  //     let data1 = data[0]
  //     fetching(dataUrl.url.get + `${data1._id}`, { method: 'DELETE' }).then(resData => {
  //       try {
  //         if (resData) { this.onQuery() }
  //       } catch (error) { }
  //     })
  //   }
  // }
  handleUpdate = (e, data1) => {
    this.setState({ exercise: { ...data1, save: '2', formOpen: true } })
  }

  render() {
    const { data, dataUrl, exercise, auth, alertInfo } = this.state;
    const table = this.props.table || '';
    const C161 = () => {
      let data1 = (data instanceof Array && data.length > 0) ? data[0] : {};
      return <div >
        {exercise.formOpen ? (
          <div >
            {data1._id && <Grid container className="dovbox" style={{ justifyContent: 'flex-end' }}>
              <Button variant="contained" color="primary" onClick={e => { this.handleDelete(e, data1) }}>删除</Button>
              <Button style={{ margin: '0 10px' }} variant="contained" color="primary" onClick={e => { this.handleUpdate(e, data1) }}>编辑</Button>
            </Grid>
            }
            <C161Form auth={auth} formStatic={{ ...common, ...r16 }} exercise={exercise} table={table} dataUrl={dataUrl}
              onSubmit={this.FormSubmit2} />
          </div>
        ) : (
            <div>
              {(table === 'r16-1') && <Typography variant="body2" gutterBottom >{'暂未设置融资授权审批'}</Typography>}
              {(table === 'r16-2') && <Typography variant="body2" gutterBottom >{'暂未设置开立授权审批'}</Typography>}
              {(table === 'r16-3') && <Typography variant="body2" gutterBottom >{'暂未设置流转授权审批'}</Typography>}
              <Button variant="contained" color="primary" onClick={this.handleToggle1}>{'设置授权审批'}</Button>
            </div>
          )
        }
      </div>
    }

    return <div>
      {(table === 'r16-1' || table === 'r16-2' || table === 'r16-3') && <C161></C161>}
      <AlertDialog alertInfo={alertInfo} handleConfirm={this.handleConfirm}></AlertDialog>
    </div>
  }
}


const setView = Comp => (collection, name) => {
  return class extends Component {
    render() {
      return <Comp table={collection} tableName={name} {...this.props} />
    }
  }
}

export const SetView = setView(View);

