
import getSubRouters from './helper';

export const subRootAry3 = [
    '应收帐回款查询',
    '应收帐回款录入',
  ];


const PARENT_PATH = '/risk-management/应收账款管理';
  const PATH = '应收帐回款信息';
  export default getSubRouters(PARENT_PATH, PATH, subRootAry3);
