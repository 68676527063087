//短信提示信息维护
export default {
  enterprise_name: '客户名称',
  debt_number: "借据号",
  note_received_by: '短信接收人',
  note_received_number: "短信接收号码",
  note_send_date: "短信发送时间",
  note_end_date: "短信结束时间",
  note_send_interval : "短信发送间隔",
  note_send_type: "短信发送类型"

}
