import React, { Component } from 'react';
import MaterialTable from '../../vendors/material-table';
import { defer, capitalize, fetching } from '../../config/utils';
import { core_business_url } from './core_business_url';
import { common, r7_1_3 } from './formStatic'
import { loginInfos } from '../../components/numToText'
import { DownloadTimeForm, DialogForm } from '../../02-core_business/downloadTime';//时间选择弹窗--20201117
import {
  Button,
  ButtonGroup,
  Grid,
  Paper,
} from '@material-ui/core';//20201117
import  {
  C713RueryForm
} from './index'
class View extends Component {
  state = {
    columns: [{}],
    data: [],
    hasToken: sessionStorage.getItem("authToken"),
    dataUrl: { url: { query: '' } },
    id: 10000,
    query: '',
    form2: { ...common },
    exercise: {
      save: '1',
      DialogTitle: '新增',
      open: false,
      status2: '1'
    }
  };
  //获取数据
  componentDidMount() {
    const { table } = this.props;
    fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {
        if (data !== undefined && data !==null) {
          let auth = loginInfos();
          const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
          //根据不同的页面，获取不同的接口地址，渲染不同的数据
          const dataUrl = core_business_url(auth, table).find(item => item.name === table);
          const dataUrl2 = dataUrl || {};
          this.setState({ columns, dataUrl: dataUrl2 });

          if (dataUrl2.url) {
            dataUrl2.url.query !== '' && this.onQuery();
          }
        }

      })
  }

  //查询数据
  onQuery = () => {
    const { url } = this.state.dataUrl;
    fetching(url.query + '?status_id=5').then(data => {

      if (data !== undefined && data !==null) {
        let datas = data.map(item => {
          if (item.status) {
            item.status = item.status.name;
          }
          return item;
        })
        this.setState({ data: datas });
      }
    })

  }

  //  table新增
  onAdd = newData => {
    let data = this.state.data;
    data.unshift(newData);
    this.setState({ data })
    return defer(0).then(console.log('newData ---: ', newData));
  }

  // 对象查找
  findObject = (obj, data) => {
    let index = data.findIndex(item => item.id === obj.id);
    return index;
  }
  //表格编辑
  onUpdate = (newData, oldData) => {
    return defer(60).then(console.log(' newData, oldData ---: ', newData, oldData));
  }
  //表格删除
  onDelete = async oldData => {
    const { dataUrl } = this.state
    const returnedData = await fetching(dataUrl.url.query + oldData.id, { method: 'DELETE' })
    returnedData && this.onQuery();
    return returnedData;
  }


  //搜索框Change
  onSearchChange = (query) => {
  }
  //页面更改事件
  onChangePage = (query) => {
  }
  //处理每页的行更改（pageSize）
  onChangeRowsPerPage = (query) => {
  }
  //条件查询
  handleSubmit = () => {

  }
  //客户名称
  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ query: value })
  }

  //触发打开时间段选项弹窗--20201117
  handleToggleYWD = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open: false }
      })
    window.location.reload()
    } else {
      this.setState({
        exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
      })
    }
  }

  //触发打开时间段选项弹窗--20201117
  exportTableYWD = (e) => {
    const { exercise, form2, dataUrl } = this.state;
    //open: true--详情页；checkOpen: true--公共表单
    //type: 1-后端/全部；2-前端/指定公司
    this.setState({
      exercise: { ...exercise, save: '3', DialogTitle: form2.exportTable, open: false, tableName: dataUrl.name, status2: '1', checkFlag: true, type: 1 }
    })
  }

  FormSubmit = (newData, data) => {
    let datas = data || this.state.data || [];
    const { dataUrl, exercise, auth } = this.state;
    if (newData.submit_name === '提交') {
      if (newData.formDates) {
        datas = this.deleteSave(newData.formDates);
      } else {
        this.setState({
          exercise: { ...this.state.exercise, open: false }
        })
        setTimeout(() => {
          this.fetchings(dataUrl, exercise.table, auth)
        }, 500)
      }
    } else {
      this.setState({
        data: JSON.parse(JSON.stringify(datas)),
        exercise: { ...this.state.exercise, open: false }
      })
    }
  }

  render() {
    const { columns, exercise, data, dataUrl } = this.state;
    const { table } = this.props;
    const title = dataUrl.title || '';
    const path = capitalize(table);
    const actions = actions2(this, table);
    const editable = editable2(this, table);

    //导出按钮--20201117
    const C6 = () => {
      return <div style={{ textAlign: 'end', marginBottom: '10px' }}>
        <Button variant="contained" color="primary" onClick={this.exportTableYWD}>导出</Button>
      </div>
    }


    //时间段选择弹窗--20201117
    const DownloadTime = () => {
      let isBtn = false;
       return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
         <DialogForm handleToggle={this.handleToggleYWD} exercise={{ ...exercise, addBtnName: common.exportTable, isBtn }}>
           <DownloadTimeForm form2={{ ...common }} exercise={{ ...exercise }} onSubmit={this.FormSubmit} />
         </DialogForm>
      </div>
    }

    
    return <div>
      {table === 'r7-1-3' && <C713RueryForm formStatic={{...r7_1_3,...common}}></C713RueryForm>}
      {table === 'r7-7-1' && <DownloadTime></DownloadTime>}
      {table === 'r7-7-1' && <C6></C6>}
      <MaterialTable
        title={title}
        onSearchChange={this.onSearchChange}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        path={path}
        columns={columns}
        data={data}
        actions={actions}
        editable={editable}
        localization={localization}
        options={options}
      />
    </div>
  }
}
const actions2 = (that, name) => {
  let actions = [];
  switch (name) {

    default:
      actions = [];
  }
  return actions;
}
const editable2 = (that, name) => {
  let editable = {};
  switch (name) {
    case 'm1':
      editable = { onRowAdd: that.onAdd, };
      break;

    default:
    // editable = { onRowDelete: that.onDelete };
  }
  return editable;
}
const localization = {
  header: {
    actions: '操作'
  }
}
const options = {
  actionsCellStyle: { padding: '0 10px' },
  headerStyle: { //表格头部
    backgroundColor: '#1d4583',
    color: '#FFF',
    whiteSpace: 'nowrap'
  },
  rowStyle: { //表行
    backgroundColor: '#fff',
    whiteSpace: 'nowrap'
  },
  sorting: true,  //排序
  // selection: true //多选框
}
const setView = Comp => (collection, name) => {
  return class extends Component {
    render() {
      return <Comp table={collection} tableName={name} {...this.props} />
    }
  }
}

export const SetView = setView(View);
