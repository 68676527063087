import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';
import {showImg} from '../../config/reusable';

const info = {
  name: '陕西善美商业保理有限公司',
  address: '西安市锦业一路6号永利国际金融中心25楼',
  email: 'shanmeibaoli@shccig.com',
  phone: '029-81133289/029-81132605/029-81772305',
  hours: '8:00-12:00 14:00-18:00',
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1, 1),
  },
  paper: {
    padding: theme.spacing(1, 1),
    margin: `${theme.spacing(1)}px auto`,
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    }
  },
}));

export default function() {
  const classes = useStyles();

  const bigCard = showImg({
    image: '/imgs/logo2.png',
    title: '善美融单',
    style: {
      height: '65%',
      width: '65%',
    }
  });
  const leftCard = showImg({
    image: '/imgs/logo1.png',
    title: '善美融单logo',
    style: {
      width: '90%',
      height: '90%',
    }
  });
  const rightCard = showImg({
    image: '/imgs/公众号.jpeg',
    title: '善美融单公众号',
    style: {
      height: '60%',
      width: '60%',
    },
  });

  return (
    <Grid container className={`${classes.root} ${classes.paper}`}>
      <Grid item>
        {bigCard}
      </Grid>
      <Grid container>
        <Grid item>
          <Box ml={8}>
            {leftCard}
          </Box>
        </Grid>
        <Grid item xs>
          <Typography component="h5" variant="h5">
            {info.name}
          </Typography>
          <Typography component="h5" variant="h5">
            {info.address}
          </Typography>
          <Typography component="h5" variant="h5">
            {info.email}
          </Typography>
          <Typography component="h5" variant="h5">
            {info.phone}
          </Typography>
          <Typography component="h5" variant="h5">
            {info.hours}
          </Typography>
        </Grid>
        <Grid item>
          {rightCard}
        </Grid>
      </Grid>
    </Grid>
  );
};
