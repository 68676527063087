
import getSubRouters from './helper';

export const subRootAry3 = [
    '风险分类变动情况查询',
    '逾期欠息查询',
    '风险提示及反馈'
  ];


const PARENT_PATH = '/risk-management/贷后管理';
  const PATH = '贷后实时管理';
  export default getSubRouters(PARENT_PATH, PATH, subRootAry3);
