
import getSubRouters from './helper';

export const subRootAry5 = [
    '数链应收账款审核',
    '数链应收账款查看',
    '数链融资审核',
    '数链融资查看',
    '数链还款审核',
    '已完成数链应收账款',
    '已完成数链融资',
    '已完成数链还款',
    '已退回数链应收账款',
    '已退回数链融资',
    '已退回数链还款',
    '已作废数链还款',
  ];


const PARENT_PATH = '/risk-management/应收账款管理';
  const PATH = '数链管理';
  export default getSubRouters(PARENT_PATH, PATH, subRootAry5);
