
//表格公共接口列表
export const TabUrl = (auth = {}) => {
    if (!auth.id) return [];
    else {
        const { id, organization, roles } = auth;
        let rolesId = roles[0].id;
        let query = [];
        let organization_name = organization.name;
        if (rolesId === 11) {
            //操作员
            query = [
          
                //待签收
                `/msapi/issue/find?receive_credit_code=${organization.credit_no}&status_id=4`,
                `/msapi/circulation/find?receive_credit_code=${organization.credit_no}&status_id=4`,
                `/msapi/financing/find?open_credit_code=${organization.credit_no}&role_id=14&status_id=4`,
                `/msapi/receivable/find?companyCode=${organization.credit_no}&role_id=14&status_id=3&company_type=0`,
                `/msapi/receivable/find?receive=${organization.name}&role_id=14&status_id=3&company_type=1`,
               // 拒签收
                `/msapi/issue/find?organization_id=${organization.id}&user_id=${id}&status_id=6&role_id=11`,
                `/msapi/issue/find?organization_id=${organization.id}&user_id=${id}&status_id=6&role_id=12`,
                // `/api/issue/find?organization_id=${organization.id}&user_id=${id}&status_id=6&role_id=13`,
                `/msapi/circulation/find?organization_id=${organization.id}&user_id=${id}&status_id=6&role_id=11`,
                `/msapi/circulation/find?organization_id=${organization.id}&user_id=${id}&status_id=6&role_id=12`,
                // `/api/circulation/find?organization_id=${organization.id}&user_id=${id}&status_id=6&role_id=13`,
                //退回
                `/msapi/issue/find?organization_id=${organization.id}&user_id=${id}&status_id=7&role_id=12`,
                // `/api/issue/find?organization_id=${organization.id}&user_id=${id}&status_id=7&role_id=13`,
                `/msapi/circulation/find?organization_id=${organization.id}&user_id=${id}&status_id=7&role_id=12`,
                // `/api/circulation/find?organization_id=${organization.id}&user_id=${id}&status_id=7&role_id=13`,
                `/msapi/financing/find?organization_id=${organization.id}&user_id=${id}&status_id=7&role_id=12`,
                // `/api/financing/find?organization_id=${organization.id}&user_id=${id}&status_id=7&role_id=13`,

                //清算平台注册
                `/msapi/account/getPaymentRegistrationStatus?organization_id=${organization.id}`,
                // 已退回应收账款
                `/msapi/receivable/find?organization_id=${organization.id}&status_id=7&user_id=${id}&role_id=12`,

            ]
        } else if (rolesId === 12  ) {
            //复核员、管理员
            query = [
                //待审核
                `/msapi/issue/find?organization_id=${organization.id}&status_id=2`,
                `/msapi/circulation/find?organization_id=${organization.id}&status_id=2`,
                `/msapi/financing/find?organization_id=${organization.id}&status_id=2`,
                `/msapi/financing/find?open_credit_code=${organization.credit_no}&status_id=5`,
                `/msapi/receivable/find?organization_id=${organization.id}&status_id=2`,
                //已退回
                `/msapi/issue/find?organization_id=${organization.id}&status_id=7&role_id=1`,
                `/msapi/circulation/find?organization_id=${organization.id}&status_id=7&role_id=1`,
                `/msapi/receivable/find?organization_id=${organization.id}&status_id=7&role_id=1`,
                //`/msapi/financing/find?organization_id=${organization.id}&status_id=7&role_id=1`,
                `/msapi/financing/find?organization_id=${organization.id}&status_id=7&role_id=16`,
                //待签收
                `/msapi/issue/find?receive_credit_code=${organization.credit_no}&status_id=4`,
                `/msapi/circulation/find?receive_credit_code=${organization.credit_no}&status_id=4`,

                //清算平台注册
                `/msapi/account/getPaymentRegistrationStatus?organization_id=${organization.id}`,
            ]
        } else if ( rolesId === 13) {
            //管理员
            query = [
                //待签收
                `/msapi/issue/find?receive_credit_code=${organization.credit_no}&status_id=4`,
                `/msapi/circulation/find?receive_credit_code=${organization.credit_no}&status_id=4`,
                `/msapi/financing/find?open_credit_code=${organization.credit_no}&role_id=14&status_id=4`,
                `/msapi/receivable/find?companyCode=${organization.credit_no}&role_id=14&status_id=3&company_type=0`,
                `/msapi/receivable/find?receive=${organization.name}&role_id=14&status_id=3&company_type=1`,
            ]
        }
        //风险管理（后台）
        else if (rolesId === 1) {
            //项目经理（role_id：1）
            if (organization_name === "融单平台"){
                query = [
                    `/msapi/issue/find?status_id=3&role_id=12`,
                    // `/api/issue/find?status_id=3&role_id=13`,
                    //`/msapi/financing/find?status_id=3&role_id=2`,  
                    `/msapi/circulation/find?status_id=3&role_id=12`,
                    `/msapi/financing/find?status_id=3&role_id=16&organization_name=${organization_name}`,//融资分配完成
                    // `/api/financing/find?status_id=3&role_id=13`,
                    `/sl/slFindReceivable?&roleId=1`,
                    `/msapi/receivable/find?status_id=3&role_id=12`,
                    //已退回
                    `/msapi/circulation/find?status_id=7&role_id=3`,
                    `/msapi/credit1/find?status_id=7&role_id=3`,
                    `/msapi/issue/find?status_id=7&role_id=3`,
                    `/msapi/financing/find?status_id=7&role_id=3&organization_name=${organization_name}`,
                    `/smrd/business_management/find?status_id=7&role_id=3`,
                    `/smrd/quota_allocation/find_get?status_id=7&role_id=3`,
                    `/smrd/quota_recycle/find?status_id=7&role_id=3`,
                    `/msapi/receivable/find?status_id=7&role_id=3`,
                    `/amount/usedAmount/quotaFreezingToDo?amount_status=7&role_id=3`,
                    `/amount/usedAmount/quotaUnfreezeToDoList?thawing_status=7&role_id=3`,
                ]
            }else{
                query = [
                    `/msapi/financing/find?status_id=3&role_id=16&organization_name=${organization_name}`,//融资分配完成
                    `/msapi/financing/find?status_id=7&role_id=2&organization_name=${organization_name}`,
                ]
            }
        }
        else if (rolesId === 2) {
            //业务负责人
            if (organization_name === "融单平台"){
            query = [
                `/msapi/issue/find?status_id=3&role_id=1`,
                `/msapi/financing/find?status_id=3&role_id=1`,
                `/msapi/receivable/find?status_id=3&role_id=1`,
                `/msapi/credit1/find?status_id=3&role_id=1`,
                `/smrd/quota_allocation/find_get?status_id=2&role_id=1`,
                `/smrd/quota_recycle/find?status_id=2&role_id=1`,
                `/sl/slFindReceivable?&roleId=2`,
                `/msapi/credit1/find?status_id=7&role_id=3`,
                `/msapi/issue/find?status_id=7&role_id=3`,
                `/msapi/financing/find?status_id=7&role_id=3`,
                `/smrd/quota_allocation/find_get?status_id=7&role_id=3`,
                `/smrd/quota_recycle/find?status_id=7&role_id=3`,
                `/msapi/receivable/find?status_id=7&role_id=3`,
            ]
            }else{
                query = [
                    `/msapi/financing/find?status_id=3&role_id=1&organization_name=${organization_name}`,
                    `/msapi/financing/find?status_id=7&role_id=3&organization_name=${organization_name}`,
                ]
            }
        } else if (rolesId === 16) {
            //融资分配员
            query = [
                //`/api/issue?status_id=13&role_id=16`,
                `/msapi/financing/find?status_id=13&role_id=12`,
                //`/api/credit1?status_id=3&role_id=16`,
                //`/smrd/quota_allocation?status_id=2&role_id=166`,
                //`/smrd/quota_recycle?status_id=2&role_id=1`,

                //`/api/credit1?status_id=7&role_id=3`,
                //`/api/issue?status_id=7&role_id=3`,
                `/msapi/financing/find?status_id=7&role_id=1`,
                //`/smrd/quota_allocation?status_id=7&role_id=3`,
                //`/smrd/quota_recycle?status_id=7&role_id=3`,
            ]
        }
        else if (rolesId === 3) {
            //风险经理
            if (organization_name === "融单平台"){
                query = [
                    //审核中
                    `/msapi/issue/find?status_id=3&role_id=1`,
                    `/msapi/circulation/find?status_id=3&role_id=1`,
                    // `/api/circulation/find?status_id=3&role_id=13`,
                    `/sl/slFindReceivable?&roleId=3`,
                    `/msapi/financing/find?status_id=3&role_id=1`,
                    `/msapi/credit1/find?status_id=3&role_id=1`,
                    `/smrd/business_management/find?status_id=2&role_id=1`,
                    `/smrd/quota_allocation/find_get?status_id=2&role_id=1`,
                    `/smrd/quota_recycle/find?status_id=2&role_id=1`,
                    `/msapi/receivable/find?status_id=3&role_id=1`,
                    `/amount/usedAmount/quotaFreezingToDo?amount_status=3&role_id=1`,//额度冻结
                    `/amount/usedAmount/quotaUnfreezeToDoList?thawing_status=3&role_id=1`,//额度解冻
                    //已退回
                    `/smrd/quota_allocation/find_get?status_id=7&role_id=8`,
                    `/msapi/issue/find?status_id=7&role_id=8`,
                    `/msapi/circulation/find?status_id=7&role_id=8`,
                    `/msapi/financing/find?status_id=7&role_id=8`,
                    `/msapi/credit1/find?status_id=7&role_id=8`,
                    `/msapi/receivable/find?status_id=7&role_id=8`,
                    `/amount/usedAmount/quotaFreezingToDo?amount_status=7&role_id=8`,
                    `/amount/usedAmount/quotaUnfreezeToDoList?thawing_status=7&role_id=8`,
                ]
            }else{
                query = [
                    `/msapi/financing/find?status_id=3&role_id=2&organization_name=${organization_name}`,
                    `/msapi/financing/find?status_id=7&role_id=15&organization_name=${organization_name}`,
                ]
            }
        } else if (rolesId === 4) {
            //风险负责人
            query = [
                //审核中
                `/msapi/issue/find?status_id=3&role_id=3`,
                `/msapi/circulation/find?status_id=3&role_id=3`,
                `/msapi/financing/find?status_id=3&role_id=3`,
                `/msapi/receivable/find?status_id=3&role_id=3`,
                `/msapi/credit1/find?status_id=3&role_id=3`,
                `/sl/slFindReceivable?&roleId=4`,
                //已退回
                `/msapi/issue/find?status_id=7&role_id=15`,
                `/msapi/circulation/find?status_id=7&role_id=15`,
                `/msapi/receivable/find?status_id=7&role_id=15`,
                `/msapi/financing/find?status_id=7&role_id=15`,
                `/msapi/credit1/find?status_id=7&role_id=8`,

            ]
        } else if (rolesId === 8) {
            //总经理
            if (organization_name === "融单平台"){
                query = [
                    //审核中
                    `/msapi/issue/find?status_id=3&role_id=3`,
                    `/msapi/circulation/find?status_id=3&role_id=3`,
                    `/msapi/financing/find?status_id=3&role_id=3`,
                    `/msapi/receivable/find?status_id=3&role_id=3`,
                    `/msapi/credit1/find?status_id=3&role_id=3`,
                    `/smrd/interest_rate/find?status_id=3&role_id=6`,
                    `/sl/slFindReceivable?&roleId=8`,
                    `/smrd/quota_allocation/find_get?status_id=3&role_id=3`,
                    `/amount/usedAmount/quotaFreezingToDo?amount_status=3&role_id=3`,
                    `/amount/usedAmount/quotaUnfreezeToDoList?thawing_status=3&role_id=3`,
                    //已退回
                    //`/msapi/financing/find?status_id=7&role_id=14`
                    `/msapi/receivable/find?status_id=7&role_id=14`,
                    `/msapi/credit1/find?status_id=7&role_id=14`,
                    `/msapi/financing/find?status_id=7&role_id=14`,
                ]
            }else{
                query = [
                    `/msapi/financing/find?status_id=3&role_id=15&organization_name=${organization_name}`,
                    `/msapi/financing/find?status_id=7&role_id=14&organization_name=${organization_name}`,
                ]
            }
        } else if (rolesId === 14) {
            //董事长
            query = [
                //审核中
                `/msapi/financing/find?status_id=3&role_id=8`,
                `/msapi/receivable/find?status_id=3&role_id=8`,
                `/sl/slFindReceivable?&roleId=14`,
	        `/msapi/credit1/find?status_id=3&role_id=8`,

            ]
        } else if (rolesId === 9) {
            //综合部经办人
            if (organization_name === "融单平台"){
                query = [
                    //审核中
                    `/msapi/issue/find?status_id=3&role_id=8`,
                    `/msapi/financing/find?status_id=10&role_id=12&organization_name=${organization_name}`,
                    `/msapi/receivable/find?status_id=3&role_id=11`,
                    // `/api/financing/find?status_id=10&role_id=13`,

                ]
            }else{
                query = [
                    `/msapi/financing/find?status_id=10&role_id=12&organization_name=${organization_name}`,
                ]
            }
        } else if (rolesId === 5) {
            //财务经办人
            if (organization_name === "融单平台"){
                query = [
                    //审核中
                    `/msapi/financing/find?status_id=10&role_id=9&organization_name=${organization_name}`,
                    `/smrd/interest_rate/find?status_id=7&role_id=6`,
                    `/msapi/receivable/find?status_id=10&role_id=9`,
                ]
            }else{
                query = [
                    `/msapi/financing/find?status_id=10&role_id=9&organization_name=${organization_name}`,
                ]
            }
        } else if (rolesId === 6) {
            //财务负责人
            if (organization_name === "融单平台"){
                query = [
                    //审核中
                    `/msapi/financing/find?status_id=10&role_id=5&organization_name=${organization_name}`,
                    `/smrd/interest_rate/find?status_id=2&role_id=5`,
                    `/smrd/interest_rate/find?status_id=7&role_id=8`,
                    `/msapi/receivable/find?status_id=10&role_id=5`,
                ]
            }else{
                query = [
                    `/msapi/financing/find?status_id=10&role_id=5&organization_name=${organization_name}`,
                ]
            }
        }  else if (rolesId === 15) {
            //法务
            if (organization_name === "融单平台"){
                query = [
                    //审核中
                    `/msapi/issue/find?status_id=3&role_id=4`,
                    `/msapi/circulation/find?status_id=3&role_id=4`,
                    `/msapi/financing/find?status_id=3&role_id=4`,
                    `/msapi/receivable/find?status_id=3&role_id=4`,
                    //已退回
                    `/msapi/issue/find?status_id=7&role_id=8`,
                    `/msapi/circulation/find?status_id=7&role_id=8`,
                    `/msapi/financing/find?status_id=7&role_id=8`,
                    `/msapi/receivable/find?status_id=7&role_id=8`,
                ]
            }else{
                query = [
                    `/msapi/financing/find?status_id=3&role_id=3&organization_name=${organization_name}`,
                    `/msapi/financing/find?status_id=7&role_id=8&organization_name=${organization_name}`,
                ]
            }
        } else if (rolesId === 17) {
            //法务
            query = [
                //审核中
                //`/api/issue?status_id=3&role_id=4`,
                //`/api/circulation?status_id=3&role_id=4`,
                //`/api/financing?status_id=3&role_id=4`,

                //已退回
                //`/api/issue?status_id=7&role_id=8`,
                //`/api/circulation?status_id=7&role_id=8`,
                //`/api/financing?status_id=7&role_id=8`,
            ]
        }


        return query;
    }
}
