
import getSubRouters from './helper';

 export const subRootAry42 = [
    // '可启用额度',
    '新增额度启用',
    '额度启用审核',
    '额度启用查询',
    '已退回额度启用'
  ];
const PARENT_PATH = '/risk-management/额度审批';
const PATH = '额度启用';
export default getSubRouters(PARENT_PATH, PATH, subRootAry42);