import Accordion from './Accordion'
import Layout from './Layout'
import Drawer from './Drawer'
import BarMenu from './BarMenu'
import C404 from './404'
import bars from './appBars'
import dialogs from './dialogs'
import modals from './modals'
import footers from './footers'
import alertDialog from './alertDialog'
import Toast from './Toast'//还款弹窗--20201218


export {
  Accordion,
  Layout,
  Drawer,
  BarMenu,
  C404,
  bars,
  dialogs,
  modals,
  footers,
  alertDialog,
  Toast//还款弹窗--20201218
}