import React, { Component } from 'react';
import Select from 'react-select';
import {
    TextField,
    Button,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Select as Select2,
    InputLabel,
    MenuItem,
    CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { Upload } from '../../components/upload';
import { DEFAULT_EXPORT_URL } from '../../config/constants';
import { withStyles } from '@material-ui/core/styles';
import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Formik } from 'formik';
import { C116Formik } from './formikValidate';
import FormikError from './formikError';
import { fetching } from '../../config/utils';
import { formatDate } from '../../components/date'
import { numberfun, commafy, delcommafy } from '../../components/numToText';


const loadingStyle = {
    position: "absolute",
    color: green[500],
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -17,
}
const useStyles = (theme => ({
    formControl: {
        marginTop: '16px',
        marginBottom: '8px',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    width: '100%',
    label: {
        textAlign: 'left',
    },
    radio: {
        flexDirection: 'row',
    },
}));

const styles = {
    width: '100%',
    margin: '16px 0 8px 0'
}

class Form1 extends Component {
    state = {
        form: this.getInitState(),
        form2: this.props.form2 || {},//静态资源
        selectCustomerList: [],
        loading: false,
    }
    getInitState() {
        const { exercise = {} } = this.props;
        const form2 = {
            deposit_bank: null,
            bank_name: null,
            account_number: null,
            factoring_agreement_no: null,
        }
        return { ...form2, ...exercise }
    }

    uploadChange = (file) => {

    };

    handleChange = ({ target: { name, value } }) => {
        let { form } = this.state;
        let value2 = value
        this.setState({
          form: { ...form, [name]: value2, }
        })
        return value2;
      }

       //输入框失去焦点
  handleBlur = (value) => {

    return commafy(value);
  }

  //输入框空格
  handleBlur4 = (value) => {
    return  value.replace(/\s+/g,"");
  }

    conversion = (date) => {
        if (date instanceof Object && date.getTime()) {
            return formatDate(date, 'yyyy/MM/dd');
        }
        return date
    }
    //保存
    handleSave = (value2) => {
        this.setState({ loading: true, loadingDsb: true, })
        const body2 ={
            deposit_bank : value2.deposit_bank,
            bank_name : value2.bank_name,
            account_number : value2.account_number,
            factoring_agreement_no : value2.factoring_agreement_no ,
        }
        fetching(`/msapi/account/saveCebAccountInform`, {
            method:"post",
            body: JSON.stringify(body2),
        }).then((res) => {
                if(res.data){
                    if(res.msg){
                        alert( `新增失败，${res.msg}` )
                        return;
                    }
                    this.props.onSubmit({
                        ...this.state.form,
                    })
                     window.location.reload()
                }else {
                    alert(`新增失败` )
                    window.location.reload()
                }
        })
    };

    render() {
        const { exercise = {}, formStatic, classes } = this.props;
        const { form,form2} = this.state;
        return (
            <Formik
                //初始化数据
                initialValues={{
                    ...form
                }}
                //表单验证
                validate={(values) => {
                    // return C116Formik(values, formStatic);
                }}
                //验证提交
                onSubmit={(values) => {
                    this.handleSave(values);
                }}
                render={({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                }) => (
                        <form method="post" encType="multipart/form-data">
                               <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                        <TextField
                                            label={form2.deposit_bank}
                                            value={values.deposit_bank}
                                            name='deposit_bank'
                                            onChange={handleChange}
                                            onBlur={e => setFieldValue('deposit_bank', this.handleBlur4.bind(this, e.target.value)())}
                                            margin='normal'
                                            inputProps={{ maxLength: 50 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            label={form2.bank_name}
                                            value={values.bank_name}
                                            name='bank_name'
                                            onChange={handleChange}
                                            onBlur={e => setFieldValue('bank_name', this.handleBlur4.bind(this, e.target.value)())}
                                            margin='normal'
                                            inputProps={{ maxLength: 50 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            label={form2.factoring_agreement_no}
                                            value={values.factoring_agreement_no}
                                            name='factoring_agreement_no'
                                            onChange={handleChange}
                                            onBlur={e => setFieldValue('factoring_agreement_no', this.handleBlur4.bind(this, e.target.value)())}
                                            margin='normal'
                                            inputProps={{ maxLength: 50 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            label={form2.account_number}
                                            value={values.account_number}
                                            name='account_number'
                                            onChange={handleChange}
                                            onBlur={e => setFieldValue('account_number', this.handleBlur4.bind(this, e.target.value)())}
                                            margin='normal'
                                            inputProps={{ maxLength: 50 }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>                                                    
                            
                            <div style={{ margin: '20px 0', position: 'relative' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={this.state.loading}
                                    >
                                        {form2.save}
                                    </Button>
                            </div>
                        </form>
                    )}
            />
        );
    }
}

export default withStyles(useStyles)(Form1);