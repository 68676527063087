
import getSubRouters from './helper';


 export const subRootAry1 = [
    '客户评级查询',
    '评级流程查询'
  ];

const PARENT_PATH = '/risk-management/评级限额';
const PATH = '查询';
export default getSubRouters(PARENT_PATH, PATH, subRootAry1);