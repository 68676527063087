import React from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  CalendarViewDay,
  Description,
  AssignmentTurnedIn,
  OpenInBrowser,
} from '@material-ui/icons';
import Accordion from '../components/Accordion'
import subRouters, { SetView } from './common'
import C71, { subRootAry } from './containers/C7_1'
import { loginInfos} from '../components/numToText';

const PARENT_PATH = subRouters['应收账款'];


const Info1 = [
  {
    path: "我的应收账款",
    icon: CalendarViewDay,
    component: SetView('m7-1'),
  },
  {
    path: '应收账款开具',
    icon: Description,
    component: SetView('m7-2'),

  },
  {
    path: '应收账款复核',
    icon: AssignmentTurnedIn,
    component: SetView('m7-3'),
  },
  {
    path: '应收账款签收',
    icon: AssignmentTurnedIn,
    component: SetView('m7-5'),
  },
  {
    path: '应收账款',
    icon: OpenInBrowser,
  },
];


const subRootAry1 = subRootAry.map(item => {
  switch (item) {
    case '开具中应收账款':
      return { path: '开具中应收账款', component: SetView('m7-4-1') }
    case '已开应收账款':
      return { path: '已开应收账款', component: SetView('m7-4-2') }
    case '已退回应收账款':
      return { path: '已退回应收账款', component: SetView('m7-4-3') }
    case '待还款':
      return { path: '待还款', component: SetView('m7-4-4') }
    case '还款审批':
      return { path: '还款审批', component: SetView('m7-4-5') }
    default:
      return item;
  }
})

export const Menu7 = () => {


  let roleId = 0;
  let auth = loginInfos();
  try {
    if (auth) {
      roleId = auth.roles[0].id || 0;
    }
  } catch (e) {
    roleId = 0;
  }

  let Info = Info1;
  Info = Info.filter((item) => {
    if (roleId === 11) {
      return (item.path !== "应收账款复核") ? item : null
    } else if (roleId === 13) {
      return (item.path !== "应收账款开具" && item.path !== "应收账款复核") ? item : null
    } else {
      return (item.path !== "应收账款开具") ? item : null
    }

  })

  const list = Info.map((item, index) => {
    const CompIcon = item.icon;
    return index === (Info.length - 1) ? <C71 key="C71" /> : (
      <ListItem
        button
        component={Link}
        to={`${PARENT_PATH}/${item.path}`}
        key={item.path}>
        <ListItemIcon>
          <CompIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText primary={item.path} />
      </ListItem>
    )
  });

  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
};

export const Content7 = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${Info1[0].path}`}
      />
      {Info1.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${PARENT_PATH}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${PARENT_PATH}/${item.path}`}
              render={() => <h2>{item.path}</h2>}
              key={item.path}
            />
          )
        }
      })}
      {subRootAry1.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${PARENT_PATH}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${PARENT_PATH}/${item}`}
              render={() => <h2>{item}</h2>}
              key={item}
            />
          )
        }
      })}
    </Switch>
  )
};
