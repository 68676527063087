//额度申请第二个表单
export default {
    enterprise_no:"客户编号",
    enterprise_name:"客户名称",
    business_varieties:"业务品种",
    business_status:"业务状态",
    credit_start_date: "额度起始日期",
    credit_due_date: "额度到期日期",
    cash_deposit_ratio:"保证金比例",
    is_allow_equivalent_deposit:"是否允许等价物充当保证金",
    equivalent_info:"等价物信息",
    trading_currencies:"出账使用币种",
    remark:"备注", 
}