
import getSubRouters from './helper';

 export const subRootAry46 = [
    '大树风控额度准入查看推送',
    '化工交易信息查看推送',
    '大树风控额度查看推送',
    ];
const PARENT_PATH = '/risk-management/额度审批';
const PATH = '大树风控额度';
export default getSubRouters(PARENT_PATH, PATH, subRootAry46);