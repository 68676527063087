import React, { Component } from 'react'
// import { fetching } from '../../config/utils';
import {
  TextField,
  Button,
  Grid,
} from '@material-ui/core'
import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { r14_3_2 } from '../formStatic';
import { Formik } from 'formik';
import FormikError from './formikError';
import { C1432Formik } from './formikValidate';
import { formatDate } from '../../components/date';

const { object } = require('yup');
const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }
}));
class C141Form extends Component {
  state = {
    form: this.getInitState(),
    formStatic: { ...r14_3_2 },//静态资源
  }
  getInitState() {
    const time = new Date();
    const form = {
      customer_name: "",//客户名称
      refund_amount: "",//回款金额
      payer_name: "",//付款人名称
      corresponding_invoice_no: "",//对应发票号
      refund_date: time,//回款日期
      notes: ""//备注

    }
    return form
  }
  handleChange = (e) => {
    const { form } = this.state
    const { name, value } = e.target;
    form[name] = value;
    this.setState({ form: { ...form } })

  }
  //保存 提交
  handleSubmit = (values) => {
    const form = { ...values };
    if (form.contract_validity instanceof Object) {
      form.contract_validity = formatDate(form.contract_validity, 'yyyy/MM/dd');
    }
    if (form.signing_time instanceof Object) {
      form.signing_time = formatDate(form.signing_time, 'yyyy/MM/dd');
    }
    if (form.entry_time instanceof Object) {
      form.entry_time = formatDate(form.entry_time, 'yyyy/MM/dd');
    }


  }
  handleReset = () => {
    let form2 = this.getInitState();
    this.setState({ form: form2 })
  }

  render() {
    const { formStatic, form } = this.state;
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        validationSchema={object().shape(C1432Formik(formStatic))}
        //验证提交
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
            <form style={{ maxWidth: '800px', margin: '0 auto' }}>
              <h4>应收账回款录入</h4>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.customer_name}
                    value={values.customer_name}
                    name='customer_name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                  />
                  <FormikError touched={touched} errors={errors} name={'customer_name'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={formStatic.refund_amount}
                    value={values.refund_amount}
                    name='refund_amount'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                  />
                  <FormikError touched={touched} errors={errors} name={'refund_amount'} />
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.payer_name}
                    value={values.payer_name}
                    name='payer_name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                  />
                  <FormikError touched={touched} errors={errors} name={'payer_name'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.corresponding_invoice_no}
                    value={values.corresponding_invoice_no}
                    name='corresponding_invoice_no'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                  />
                  <FormikError touched={touched} errors={errors} name={'corresponding_invoice_no'} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                  <Grid container justify="space-between">
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        style={{ width: '100%' }}
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline"
                        label={formStatic.refund_date}
                        value={values.refund_date}
                        onChange={e => setFieldValue('refund_date', e)}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'refund_date'} />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        label={formStatic.notes}
                        value={values.notes}
                        name='notes'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin='normal'
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <div style={{ padding: '20px 0' }}>
                <Button
                  style={{ width: '100px', marginRight: '10px' }}
                  color='primary'
                  variant='contained'
                  onClick={handleSubmit}
                >
                  {'保存'}
                </Button>

              </div>
            </form>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(C141Form);



