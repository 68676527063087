//公用的名称
export default {
    RDTree: "融单流转树形图",//20200929
    fpyzResult:"发票验真结果列表",
    exportTable: "导出台账",//20201117
    exportInterestDerived:"利息摊销表导入",
    export: "导出",//20201117
    add: "新增",
    edit: "编辑",
    view: "查看",
    save: "保存",
    submit: "提交",
    signFor: "签收",
    refuse_signFor:'拒绝签收',
    revocation: "取消",
    check: "审核",
    check2: "审核通过",
    send_back: "退回上一级",
    check_opinion:'审核意见*',
    send_back2: "退回经办人",
    addBtnName1: "新增融单开具制单",
    addBtnName2: "新增融单流转",
    addBtnName3: "新增融资申请",
    addBtnName4: "创建用户",
    addBtnName5: "融单金额流转",
    addBtnName6: "新增应收账款开具",
    addBtnName9:"善美光大信息录入",
    Upload_contracts_invoice: "上传合同发票编号*", 
    Upload_invoice_number: "上传发票编号*", 
    Upload_rest:"其他文件",
    download:"下载",
    download2:"下载协议",
    mx_amount:'融单金额',
    status:'状态', // 已保存、待审核、已审核、已退回
    resetPwd: "重置密码",
    old_password: "原密码",
    add_days_number: "增加天数",
    new_password: "新密码",
    confirm_password: "再次输入密码",
    cancel:'取消',
    UploadFile:'上传',
    bfeMsgIn: '北金所信息录入',
    bfe_preProdNo:  '北金所产品预编号',
    delayFile: '文件上传',

}
