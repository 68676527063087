import React, { Component } from 'react'
import moment from 'moment'
import { Formik } from 'formik';
import Select from 'react-select';
import {
  TextField,
  Button,
  Grid,
  Select as Select2,
  InputLabel,
  MenuItem,
  FormControl,
  // FormControlLabel,
  // Checkbox,
  Typography,
  LinearProgress,
  Box
} from '@material-ui/core'

import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Upload } from '../../components/upload';

import { numberfun, commafy, delcommafy } from '../../components/numToText'
import { formatDate,getNowFormatDate } from '../../components/date'
import FormikError from './formikError';
import { financingFormik } from './formikValidate';
import SimpleExpansionPanel from './Panels';
import { fetching } from '../../config/utils';
import { numberParseChina, days } from '../../components/numToText'
import { DEFAULT_CONTRCT_URL } from '../../config/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
const { object } = require('yup');

global.count = 0;
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    selectedTrue: {},//下拉框选中的可用融单信息封装--20210204
    form: this.getInitState(),
    form2: this.props.form2 || {},
    open2: false,
    opinion_list: [],
    mxNumberList: [],
    interestList: [],
    uploads: {},
    loading: false,
    loading2: false,
    loadingDsb: false,
    interestPayList: ['是', '否']
  }

  decimal(num) {
    let list = num.toString().split(".")
    if(list.length <= 1){
      return num
    }
    let strNum = list[0]
    let strNum1= list[1]
    strNum1 = strNum1.substr(0,2)
    if(list.length===1)
      return num
    if(list[1].length < 3)
      return num
    let str = list[1]
    if(str[2] > 4)
      return Number(strNum+"."+strNum1)+0.01
    return Number(strNum+"."+strNum1)  
  }

  getInitState() {
    const { exercise, auth = {} } = this.props
    const open_day = new Date();

    const receivableNo = exercise.receivableNo ?
      { id: exercise.receivableNo, receivableNo: exercise.receivableNo, mxc_number: exercise.mxc_number } : '';
    let applicant = '';
    let applyAmount = exercise.applyAmount ? commafy(exercise.applyAmount) : 0;//应收审批金额
    let mx_amount = exercise.mx_amount ? commafy(exercise.mx_amount) : 0;//授信额度
    let usable_amount = exercise.usable_amount ? commafy(exercise.usable_amount) : 0;//可用额度
    let amount = exercise.amount ? commafy(exercise.amount) : '';
    let interest = exercise.interest ? commafy(exercise.interest) : 0;
    let net_financing_amount = exercise.net_financing_amount ? commafy(exercise.net_financing_amount) : 0;
    let financing_cost = exercise.financing_cost ? commafy(exercise.financing_cost) : 0;
    let mxc_number = exercise.mxc_number || '';
    //let synthesis_rate = exercise.synthesis_rate || 5.8;
    let synthesis_rate = 0.0;
    let payment_day = exercise.payment_day || open_day;
    let open_credit_code = '';
    var extraction = exercise.extraction,//开立
      issue_credit_code = exercise.open_credit_code || '',//开立信用代码
      circulation = exercise.mx_payer || '', //流转
      circulation_credit_code = exercise.payer_credit_code || '';//流转信用代码
    if (auth.organization) {
      applicant = auth.organization.name;
      open_credit_code = auth.organization.credit_no;
    }
    if (exercise.save === '4') {
      amount = '';
    }

    if ((exercise.statusId === 7 && auth.roles[0].id === 1) ||(exercise.StatusId === 7 && auth.roles[0].id === 1) || (exercise.StatusId === 7 && auth.roles[0].id === 11) || (exercise.statusId === 7 && auth.roles[0].id === 11)){
      exercise.financing_time = getNowFormatDate();
      exercise.financing_maturity = days(payment_day, open_day)//融资期限
      let synthesis_rate2 = (Number(synthesis_rate) / 100 / 360);
      let value = numberfun(amount, 12,2);
      exercise.interest = this.decimal(synthesis_rate2 * (exercise.financing_maturity + Number(exercise.add_days_number)) * Number(value));
      exercise.net_financing_amount = (Number(value) - Number(exercise.interest)).toFixed(2);
      exercise.financing_cost = (Number(exercise.interest) + Number(exercise.service_charge)).toString();
   }

    const ocid = exercise.id || '';
    const ocid_from = 'receivable';
    const form = {
      receivableNo,
      applyAmount,
      applicant,//融资方
      open_credit_code,//融资方信用代码
      extraction, //开立
      issue_credit_code,//开立信用代码
      circulation, //流转
      circulation_credit_code,//流转信用代码
      right_resource: "否",//追索权
      mxc_number,
      mx_amount,//授信额度
      usable_amount,//可用融单金额
      payment_day,//承诺付款日
      ocid,
      ocid_from,

      // interest_pay: '一次性支付',//利息支护方式
      net_financing_amount,//净融资额
      interest,//利息
      amount,//融资金额
      financing_amount_capital: '',//融资金额大写
      application_number: "",//融资编号
      service_tariffing: 0,//服务费率
      service_charge: 0,//服务费
      financing_cost,//融资成本
      financing_maturity: days(payment_day, open_day),//融资期限


      remarks: '',  //备注
      financing_time: open_day,//融资申请时间
      add_days_number: 0,//增加天数
      synthesis_rate,//预计综合融资息费率（%)
      
      
      
      DialogTitle: "",
      status2: '1', //判断是开具还是复核
      sign_agreement: '1', //签订协议
      save: "1", //判断是新增还是编辑
      imgUrl: '',
      table: exercise.table || '' ,
      
    }
    if (auth.roles[0].id === 1 && exercise.upload_contracts === 1) {
      form.Upload_contracts = exercise.upload_contracts;//上传合同
      form.Upload_invoice = exercise.upload_invoice; //上传发票
      form.Upload_contracts_invoice =  exercise.upload_contracts_invoice; //上传合同编号
      form.Upload_rest = exercise.upload_rest;//其他
    } else {
    form.Upload_contracts = "";//上传合同
    form.Upload_invoice = ""; //上传发票
    form.Upload_contracts_invoice =  ""; //上传合同编号
    form.Upload_rest = "";//其他
    }


    if ((exercise.status_id === 7 && auth.roles[0].id === 1) ||(exercise.StatusId === 7 && auth.roles[0].id === 1) ||
     (exercise.StatusId === 7 && auth.roles[0].id === 11) || (exercise.statusId === 7 && auth.roles[0].id === 11) ||
     (exercise.StatusId === 7 && auth.roles[0].id === 11) || (exercise.status_id === 7 && auth.roles[0].id === 11)){
      form.financing_cost = commafy(exercise.financing_cost);
      form.net_financing_amount = commafy(exercise.net_financing_amount);
      form.interest = commafy(exercise.interest);
      net_financing_amount = commafy(exercise.net_financing_amount);
      interest = commafy(exercise.interest);
      financing_cost = commafy(exercise.financing_cost);
    }
    
    return (exercise.save !== '1' && exercise.save !== '4') ? {
      ...form, ...exercise, receivableNo, amount,
      net_financing_amount, interest, financing_cost, mx_amount, usable_amount,
      financing_amount_capital: numberParseChina(exercise.amount)
    } : form
  }

  //组件初始化后
  componentDidMount() {
    try {
      const { auth = {}, exercise } = this.props;
      // const { form } = this.state;
      //获取利率
      fetching(`/smrd/interest_rate/find?status_id=9`).then(res => {
        if (res && res.length > 0) {
          this.setState({ interestList: res[0].rates })
        }
      })

      

      if (exercise.save === '3') {

        let department_id = ``;
        if (auth.department.id === 7) department_id = `&department_id=${auth.department.id}`
        fetching(`/msapi/fflow/find?mx_number=${exercise.mx_number}&mxf_number=${exercise.mxf_number}&${department_id}`).then(res => {
          if (res !== undefined && res !==null) {
            this.setState({ opinion_list: res })
          }
        })
        fetching('/msapi/upload/find?financing_id=' + exercise.id, { method: 'GET' }).then(data => {
          if (Array.isArray(data) && data.length > 0) {
            let uploads = {}
            data.forEach(item => {
              uploads[item.field] = item.fpath;
            })
            this.setState({ uploads })
          }
        })
      } else {
        let query = `/msapi/issue/find?receive_credit_code=${auth.organization.credit_no}&status_id=5`;
        let query2 = `/msapi/circulation/find?receive_credit_code=${auth.organization.credit_no}&status_id=5`;
        Promise.all([fetching(query), fetching(query2)]).then(values => {
          let data = []
          values.forEach(item => {
            if (item instanceof Array) {
              data = data.concat(item);
            }
          })

          this.setState({ mxNumberList: data });
        });
      }
    } catch (e) {
    }
  }

  //输入框失去焦点
  handleBlur2 = (value) => {
    return commafy(value);
  }
  //获取利率
  findSynthesis_rate = (amount) => {
    const { interestList } = this.state;
    if (interestList.length > 0) {
      let find = interestList.find(item => {
        if ((item.max_amount === null || item.max_amount === 'null') && item.min_amount <= amount) {
          item.synthesis_rate = 5.8  //融资利率写死
          return item
        }

        else if (item.min_amount <= amount && amount < item.max_amount) {
          item.synthesis_rate = 5.8  //融资利率写死
          return item
        }else {
          //return null
          item.synthesis_rate = 5.8  //融资利率写死
          return item
        }
      })
      return find.synthesis_rate ? find.synthesis_rate : 5.8
    } else {
      return 5.8;
    }
  }
  handleChange = ({ target: { value, name } }) => {
    let value2 = value;
    const {
      interest, net_financing_amount, service_charge, financing_maturity, save,
      financing_amount_capital, synthesis_rate, financing_cost, amount, add_days_number, payment_day
    } = this.state.form;
    this.state.form.ocid_from = 'receivable';
    let net_financing_amount2 = net_financing_amount; //净融资额
    let financing_amount_capital2 = financing_amount_capital;
    let interest2 = interest;
    let financing_cost2 = financing_cost;
    let financing_maturity2 = financing_maturity;
    let synthesis_rate3 = synthesis_rate
    let amount2 = save === '3' ? delcommafy(amount) : Number(amount)
    if (name === 'sign_agreement') {
      value2 = this.state.form.sign_agreement === '1' ? '1' : '1';
    } else if (
      name === 'usable_amount' ||
      name === 'net_financing_amount'
    ) {
      //value2 = numberfun(value2, 12);
      value2 = numberfun(value2, 12,2);
    } else if (name === 'amount') {
      //value2 = Number(numberfun(value2, 12, 2));
      value2 = numberfun(value2, 12, 2);
      synthesis_rate3 = this.findSynthesis_rate(value2);
      let synthesis_rate2 = (Number(synthesis_rate3) / 100 / 360);
      financing_amount_capital2 = numberParseChina(value2);
      //interest2 = (Math.round(synthesis_rate2 * (financing_maturity + Number(add_days_number)) * value2 * 100)/100).toFixed(2);
      interest2 = this.decimal(synthesis_rate2 * (financing_maturity + Number(add_days_number)) * value2);
      net_financing_amount2 = (value2 - Number(interest2)).toFixed(2);
      //financing_cost2 = (Number(interest2) + service_charge);
      financing_cost2 = (Number(interest2) + Number(service_charge));//处理融资成本成为10倍的错误--20200923
    } else if (name === 'add_days_number') {
      let synthesis_rate2 = (Number(synthesis_rate) / 100 / 360);
      value2 = Number(numberfun(value2, 5));
      //interest2 = (Math.round(synthesis_rate2 * (financing_maturity + value2) * amount2 * 100)/100).toFixed(2);
      interest2 = this.decimal(synthesis_rate2 * (financing_maturity + value2) * amount2 );
      net_financing_amount2 = (amount2 - Number(interest2)).toFixed(2);
      //financing_cost2 = (Number(interest2) + service_charge);
      financing_cost2 = (Number(interest2) + Number(service_charge));//处理融资成本成为10倍的错误--20200923
    } else if (name === 'synthesis_rate') {
      value2 = numberfun(value2, 3, 2, '100');
      if (value2 > 100) {
        value2 = String(value2).slice(0, 2)
      }
      let synthesis_rate2 = (Number(value2) / 100 / 360);
      //interest2 = (Math.round(synthesis_rate2 * (financing_maturity + Number(add_days_number)) * amount2 * 100)/100).toFixed(2);
      interest2 = this.decimal(synthesis_rate2 * (financing_maturity + Number(add_days_number)) * amount2);
      net_financing_amount2 = (amount2 - Number(interest2)).toFixed(2);
      //financing_cost2 = (Number(interest2) + service_charge);
      financing_cost2 = (Number(interest2) + Number(service_charge));//处理融资成本成为10倍的错误--20200923
    } else if (name === 'financing_time') {
      financing_maturity2 = days(payment_day, value)//融资期限
      let synthesis_rate2 = (Number(synthesis_rate) / 100 / 360);
      //interest2 = (Math.round(synthesis_rate2 * (financing_maturity2 + Number(add_days_number)) * amount2 * 100)/100).toFixed(2);
      interest2 = this.decimal(synthesis_rate2 * (financing_maturity2 + Number(add_days_number)) * amount2);
      net_financing_amount2 = (amount2 - Number(interest2)).toFixed(2);
      //financing_cost2 = (Number(interest2) + service_charge);
      financing_cost2 = (Number(interest2) + Number(service_charge));//处理融资成本成为10倍的错误--20200923
    }
    this.setState({
      form: {
        ...this.state.form,
        net_financing_amount: commafy(net_financing_amount2),
        interest: commafy(interest2),
        financing_cost: commafy(financing_cost2),
        financing_amount_capital: financing_amount_capital2,
        financing_maturity: financing_maturity2,
        synthesis_rate: synthesis_rate3,
        [name]: value2,
      }
    })
    return value2;

  }
  //申请时间
  onChangeFinancingTime = (e) => {
    if (e.getTime()) {
      this.handleChange({ target: { value: e, name: 'financing_time' } });
    }
    return e;
  }
  //失去焦点
  handleOnBlur = ({ target: { value, name } }) => {

    if (name === 'synthesis_rate') {

      if (Number(value) > 100) {
        alert('请输入1~100的数字最多保留2位小数');
      }

    }
  }
  //融资申请时间
  handleChangeStartTime = (data) => {
    let financing_time = new Date();
    if (data !== undefined && data !==null) {
      financing_time = data;
      this.setState({ form: { ...this.state.form, financing_time: data } })
    } else {
      this.setState({ form: { ...this.state.form, financing_time: new Date() } })
    }
    return financing_time;
  }
  //付款日
  handleChangeEndTime = (data) => {
    let payment_day = new Date();
    if (data !== undefined && data !==null) {
      payment_day = data;
      this.setState({ form: { ...this.state.form, payment_day: data } })
    } else {
      this.setState({ form: { ...this.state.form, payment_day: new Date() } })
    }
    return payment_day;
  }
  //保存 提交
  handleSubmit = (form2, name) => {
    this.setState({ loading: true, loadingDsb: true });
    const dataList = ['contractId','invoiceId','ciId','otherId','synthesis_rate', 'interest', 'usable_amount', 'financing_maturity',
      'payment_day', 'net_financing_amount', 'mx_amount', 'mxc_number', 'financing_cost',
      'add_days_number', 'extraction', 'issue_credit_code', 'circulation', 'circulation_credit_code'];
    const amountList = ['amount', 'usable_amount', 'mx_amount', 'interest',
      'net_financing_amount', 'financing_cost']; //增加千位符
    const { id } = this.state.form;
    const { auth } = this.props;
    form2.ocid = this.state.form.ocid;
    form2.ocid_from = this.state.form.ocid_from;
    let form = form2 ? { ...form2 } : this.state.form;
    

    const text = name || '取消';
    let ids = id ? id : '';
    
    let method =  'POST' //id ? 'PUT' : 'POST';
    dataList.forEach(item => form[item] = this.state.form[item])



    //提交表单
    if (text === '提交' || text === '保存') {
      this.setState({ loading: true, loadingDsb: true });
      if (moment(form.payment_day).isBefore(moment(new Date()))) {
        this.setState({ loading: false, loading2: false, loadingDsb: false });
        alert('承诺付款日已过期');
        return false;
      }
      if (form.financing_time instanceof Object) {
        form.financing_time = formatDate(form.financing_time, 'yyyy-MM-dd');
      }
      amountList.forEach(item => {
        form[item] = delcommafy(form[item]);
      })
      if (form.usable_amount < form.amount && auth.department.id === 7) {
        alert('融资金额不能大于可用融单金额');
        return;
      } else if (form.amount <= 0) {
        alert('融资金额必须大于0');
        return;
      } /*else if (Number(form.synthesis_rate) > 100 || Number(form.synthesis_rate) <= 0) {
        alert('预计费率请输入1~100的数字最多保留2位小数');
        return;
      }*/
      form.mx_number = form.receivableNo.receivableNo || '';
      form.auth = auth;//用户信息
      if (text === '提交') {
        form.status_id = form.statusId ? form.statusId : 2;
        form.type = 3;
        this.setState({ loading: true, loadingDsb: true })
      }
      else if (text === '保存') {
        form.status_id = 1;
        form.type = 2;
        this.setState({ loading2: true, loadingDsb: true })
      }

      const excludes = ['DialogTitle', 'status2', 'save', 'imgUrl', 'formDate',
        'table', 'mxNumberList', 'check_opinion', 'open',
        'checkOpen'];
      form.Upload_contracts=true;
      form.Upload_invoice=true;
      form.Upload_contracts_invoice=true;
      if(form.otherId){
        form.Upload_rest=true;
      }else{
        form.Upload_rest=false;
      }

      let roleId = auth.roles ? auth.roles[0].id : 0;
      if (roleId === 11) {//操作员
        const formCheck = {
          organization_id: auth.organization.id,
          amount: form.amount,//金额
          contractsInvoiceId: form.ciId , //excel上传文件id  1.28 zhao
        };

        // fetching('/smrd/fapiao_org/check_excel', {
        //   method:"post",
        //   body: JSON.stringify(formCheck),
        // }).then(res => {
        //   if(res !== null && res !== undefined){
        //   if (res.msg) {
        //     alert(`excel中数据检测不通过：,${res.msg}`)
        //     this.setState({ loading: false, loading2: false, loadingDsb: false, })
        //     return false;
        //   }
        // } else {
        //   alert("请检验excel表是否符合模板规范")
        //   return false;
        // }
      fetching('/msapi/financing/save/' + ids, {
        method:"post",
        body: JSON.stringify(form)
      }).then(res => {
        this.setState({ loading: false, loading2: false, loadingDsb: false, })
        try {
          if (res !== undefined && res !==null) {
            if (res.msg) {
              alert(`融单融资失败,${res.msg}` )
              return false;
            }
            this.props.onSubmit({
              ...form,
              submit_name: text
            });
            alert('融单融资成功')
          } else {
            alert('融单融资失败')
          }
        } catch (error) { console.log(error) }
      })

    //});
  }

  if (roleId === 1) {//rdyw1
    //method = id ? 'PUT' : 'POST';
      fetching('/msapi/financing/save/' + ids, {
        method:'post',
        body: JSON.stringify(form)
      }).then(res => {
        this.setState({ loading: false, loading2: false, loadingDsb: false, })
        try {
          if (res !== undefined && res !==null) {
            if (res.msg) {
              alert(`融单融资失败,${res.msg}` )
              return false;
            }
            this.props.onSubmit({
              ...form,
              submit_name: text
            });
            alert('融单融资成功')
          } else {
            alert('融单融资失败')
          }
        } catch (error) { console.log(error) }
      })
  
  }
    } else {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
    }
  }




  uploadFile(e){
    var fordata = new FormData();
    fordata.append('type',2);
    fordata.append('fileName',e.file);
    const options = {
      method: "POST",
      body: fordata
    }
    global.count = (global.count+1)
    return fetch(`/msapi/upload/upload`,options)
  }
  ///上传文件并更新上传id
  uploadHandler = async (e,type) => {
	this.setState({progressLength : 0})
    	this.setState({ progress: true })
    	const timer = setInterval(() => {
      		this.setState({progressLength : (this.state.progressLength >= 99 ? 99 : this.state.progressLength + 1) })
    	}, 30);
    let indexId = '';
    indexId = await this.uploadFile(e).then(res => {
      if (res) {
        if (!res.status){
          alert(res.msg)         
        }else{
        global.count = global.count - 1
          return res.json()
        }
      }else{
        return null
      }
    }).then(r=>{ 
		clearInterval(timer);
    		this.setState({progressLength : 100})
    		setTimeout(() => {
      		alert("文件上传成功");
      		this.setState({ progress: false })
    		}, 500)     
      return r.data
    }) 
    if(type === 1){
      this.state.form.contractId = indexId 
    }else if(type === 2){
      this.state.form.invoiceId = indexId 
    }else if(type === 3){
      this.state.form.ciId = indexId 
    }else if(type === 4){
      this.state.form.otherId = indexId 
    }
    return true
    
  }
  //查看协议
  viewAgreement = () => {
    const { fdd_file_path = '' } = this.state.form
    if (fdd_file_path && fdd_file_path !== 'null') {
      window.open(fdd_file_path)
    } else
      window.open(DEFAULT_CONTRCT_URL + 'contract/template/rz.pdf')
  }
  //下载协议
  ondownload = () => {
    const { fdd_file_name = '' } = this.state.form
    fetching(`/dzqz//downLoadContract?contract_id=${fdd_file_name}`, {
      method: 'GET'
    }).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(res.msg || '下载失败')
        }
      } else {
        alert('下载失败')
      }
    })
  }
  //盟雄编号下拉
  handleChange2 = (selectedOption) => {
    const { financing_time, amount } = this.state.form;
    let payment_day = formatDate(new Date(selectedOption.payment_day), 'yyyy-MM-dd');
    let financing_maturity = days(new Date(payment_day), financing_time);//融资期限

    this.state.selectedTrue = selectedOption;//下拉框选中的可用融单信息封装--20210204

      this.setState({
        form: {
          ...this.state.form,
          mx_amount: commafy(selectedOption.mx_amount),
          usable_amount: commafy(selectedOption.usable_amount),
          mxc_number: selectedOption.mxc_number || '',
          extraction: selectedOption.extraction,//开立
          issue_credit_code: selectedOption.open_credit_code || '',//开立信用代码
          circulation: selectedOption.mx_payer || '', //流转
          circulation_credit_code: selectedOption.payer_credit_code || '',//流转信用代码
          synthesis_rate: selectedOption.synthesis_rate || 10,//预计综合融资息费率（%)
          payment_day,
          financing_maturity,
          ocid: selectedOption.id
        }
      })
      setTimeout(() => {
        this.handleChange({ target: { value: amount, name: 'amount' } });
      }, 300)
      return selectedOption;
    
    
  }


  getOptionLabel = data => {
      return  data.receivableNo
  }
  getOptionValue = data => {
    return data.id
  }

  render() {
    const { net_financing_amount,  } = this.state.form;
    const { mxNumberList, interestPayList, opinion_list } = this.state;
    const { form2, form, uploads, loading, loadingDsb, progress, progressLength } = this.state;
    const { exercise, auth = {} } = this.props;
    const roleId = auth.roles ? auth.roles[0].id : '';
    const disableds = (roleId, exercise, table) => {
      //if (roleId === 1 && exercise.save === '3' && exercise.table === 'r6-3-1') return false
      if (roleId === 1 && exercise.save === '3' && (exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6')) return false
      else if (exercise.save === '3' || table) return true
      else return false
    }
    const status = (handleSubmit, values) => {
      if (exercise.status2 === '1') {
        return <Grid container justify="center" style={{ width: '100%', padding: '10px 0' }}>
          <div style={{ position: 'relative' }}>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSubmit}
              disabled={loadingDsb}
              name={form2.submit}
              style={{ marginRight: '10px' }}
            >
              {form2.submit}
            </Button>
            {loading && <CircularProgress size={24} style={loadingStyle} />}
          </div>

          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.revocation)}
            name={form2.revocation}
            disabled={loadingDsb}
            style={{ marginRight: '10px' }}
          >
            {form2.revocation}
          </Button>
        </Grid>
      }
      //else if (roleId === 1 && exercise.save === '3' && exercise.table === 'r6-3-1') {
      else if (roleId === 1 && exercise.save === '3' && (exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6' )) {
        return <Grid container justify="center" style={{ padding: '10px 0' }}>

          <div style={{ position: 'relative' }}>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSubmit}
              disabled={loading}
              name={form2.submit}
              style={{ marginRight: '10px' }}
            >
              {form2.submit}
            </Button>
            {loading && <CircularProgress size={24} style={loadingStyle} />}
          </div>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.revocation)}
            name={form2.revocation}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.revocation}
          </Button>
        </Grid>
      }
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        //表单验证
        //validationSchema={object().shape(financingFormik(form2))}
        onSubmit={(values) => {
          if(global.count === 0){
            this.handleSubmit(values, '提交');
        }else{
          //alert("文件正在上传请稍后!")
          this.handleSubmit(values, '提交');
        }
          //this.handleSubmit(values, '提交');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <form>
              <Grid item xs={12}>
                <div style={{ margin: '26px 0 8px 0', }}>
                  <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form2.mx_number}</span>
                  <Select
                    isSearchable
                    value={values.receivableNo}
                    onBlur={handleBlur}
                    getOptionLabel={this.getOptionLabel}
                    getOptionValue={this.getOptionValue}
                    onChange={e => setFieldValue('receivableNo', this.handleChange2(e))}
                    options={mxNumberList}
                    isDisabled={exercise.save === '3' || exercise.save === '4'}
                  />
                </div>
                <FormikError touched={touched} errors={errors} name={'mx_number'} />
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.application_number}
                    value={form.application_number}
                    name='application_number'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'application_number'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.applicant}
                    value={form.applicant}
                    name='applicant'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'applicant'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.mx_amount}
                    value={form.applyAmount}
                    name='mx_amount'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'mx_amount'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.usable_amount}
                    value={form.usable_amount}
                    name='usable_amount'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'usable_amount'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.amount}
                    value={values.amount}
                    name='amount'
                    onChange={e => setFieldValue('amount', this.handleChange.bind(this, e)())}
                    onBlur={e => setFieldValue('amount', this.handleBlur2.bind(this, e.target.value)())}
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'amount'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    title={form.financing_amount_capital}
                    label={form2.financing_amount_capital}
                    value={form.financing_amount_capital}
                    name='financing_amount_capital'
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'financing_amount_capital'} />
                </Grid>

              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                  <Grid container justify="space-between">
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline"
                        label={form2.financing_time}
                        value={values.financing_time}
                        onChange={e => setFieldValue('financing_time', this.onChangeFinancingTime.bind(this, e)())}
                        disabled={disableds(roleId, exercise, true)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'financing_time'} />
                    </Grid>
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline"
                        label={form2.payment_day}
                        value={form.payment_day}
                        onBlur={handleBlur}
                        disabled={true}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'payment_day'} />
                    </Grid>

                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits2"
                    label={form2.synthesis_rate}
                    value={form.synthesis_rate}
                    onChange={this.handleChange}
                    name='synthesis_rate'
                    margin='normal'
                    fullWidth
                    disabled={disableds(roleId, exercise, true)}
                  />
                  <FormikError touched={touched} errors={errors} name={'synthesis_rate'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.financing_maturity}
                    value={form.financing_maturity}
                    name='financing_maturity'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="TextFieldUnits3"
                  label={form2.add_days_number}
                  value={form.add_days_number}
                  onChange={this.handleChange}
                  name='add_days_number'
                  margin='normal'
                  fullWidth
                  disabled={disableds(roleId, exercise)}
                />
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.interest}
                    value={form.interest}
                    name='interest'
                    onChange={e => setFieldValue('interest', this.handleChange.bind(this, e)())}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'interest'} />
                </Grid>
                <Grid item xs={5}>
                  <FormControl style={{ width: '100%', margin: '16px 0 8px 0' }}>
                    <InputLabel id="demo-simple-select-label">{form2.right_resource}</InputLabel>
                    <Select2
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name='right_resource'
                      value={values.right_resource}
                      disabled={exercise.save === '3'}
                      onChange={e => setFieldValue('right_resource', e.target.value)}> 
                    {interestPayList.map(item => {
                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                      })}
                    </Select2>
                  </FormControl>
                  <FormikError touched={touched} errors={errors} name={'right_resource'} />
                </Grid>

              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.financing_cost}
                    value={form.financing_cost}
                    name='financing_cost'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.net_financing_amount}
                    value={net_financing_amount}
                    name='net_financing_amount'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                </Grid>
              </Grid>

              {exercise.save === '3' &&
                <SimpleExpansionPanel title="历史审核意见">
                  {
                    opinion_list.map((item, index) => {
                      return (
                        <div style={{ margin: '10px 0' }} key={index}>
                          <Typography component="p">
                            {item.name}
                          </Typography>
                          <Typography component="p">
                            {item.check_opinion}
                          </Typography>
                        </div>
                      )
                    })

                  }
                </SimpleExpansionPanel>
              }
              <SimpleExpansionPanel title="档案登记*">
		{/* 上传进度条 */}
                { progress &&
                  <div>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate"  value={progressLength}/>
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                      progressLength
                    )}%`}</Typography>
                  </Box>
                </Box>
                </div>
                }
                <Grid container justify="space-between">
                  <Grid item xs={12}>
                    <Upload disabled={exercise.status2 === '2' || exercise.save === '3'}
                      uploadName="Upload_contracts"
                      mold="2"
                      uploads={uploads}
                      
                      name={form2.Upload_contracts} view={form2.view_case}
                      exercise={exercise}
                      // uploadChange={e => setFieldValue('Upload_contracts', e.file)}>
                      uploadChange={e=>setFieldValue('Upload_contracts',this.uploadHandler( e, 1))}> 
                      </Upload>
                    <FormikError touched={touched} errors={errors} name={'Upload_contracts'} />
                  </Grid>

                </Grid>
                <Grid item xs={12}>
                  <Upload disabled={exercise.status2 === '2' || exercise.save === '3'}
                    uploadName="Upload_invoice"
                    mold="2"
                    uploads={uploads}
                    exercise={exercise}
                    name={form2.Upload_invoice} view={form2.view_case}
                    // uploadChange={e => setFieldValue('Upload_invoice', e.file)}>
                    uploadChange={e=>setFieldValue('Upload_invoice',this.uploadHandler( e, 2))}> 
                    </Upload>
                  <FormikError touched={touched} errors={errors} name={'Upload_invoice'} />
                </Grid>

                <Grid container justify="space-between">
                  <Grid item xs={12}>
                    <Upload disabled={exercise.status2 === '2' || exercise.save === '3'}
                      uploadName="Upload_contracts_invoice"
                      mold="2"
                      uploads={uploads}
                      exercise={exercise}
                      name={form2.Upload_contracts_invoice} view={form2.view_case}
                      // uploadChange={e => setFieldValue('Upload_contracts_invoice', e.file)}>
                      uploadChange={e=>setFieldValue('Upload_contracts_invoice',this.uploadHandler( e, 3))}>
                      </Upload>
                    <FormikError touched={touched} errors={errors} name={'Upload_contracts_invoice'} />
                  </Grid>

                </Grid>
                <Grid item xs={12}>
                  <Upload disabled={exercise.status2 === '2' || exercise.save === '3'}
                    uploadName="Upload_rest"
                    mold="2"
                    uploads={uploads}
                    exercise={exercise}
                    name={form2.Upload_rest} view={form2.view_case}
                    // uploadChange={e => setFieldValue('Upload_rest', e.file)}>
                    uploadChange={e=>setFieldValue('Upload_rest',this.uploadHandler( e, 4))}> 
                    </Upload>
                  <FormikError touched={touched} errors={errors} name={'Upload_rest'} />
                </Grid>
              </SimpleExpansionPanel>
              <FormikError touched={touched} errors={errors} name={'Upload_contracts'} />
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows='4'
                  label={form2.remarks}
                  value={values.remarks}
                  name='remarks'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'remarks'} />
              </Grid>
              {/* {roleId !== 11 && <div style={{ padding: '10px 0' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.sign_agreement === '1'}
                      disabled={exercise.save === '3'}
                      name='sign_agreement'
                      onChange={e => setFieldValue('sign_agreement', values.sign_agreement === '1' ? '' : '1')} />
                  }
                  label={form2.sign_agreement}
                />
                <span
                  style={{ color: '#f00', cursor: 'pointer' }}
                  onClick={this.viewAgreement}
                >{form2.sign_agreement2}</span>
                <FormikError touched={touched} errors={errors} name={'sign_agreement'} />
              </div>} */}
              <div style={{ padding: '10px 0' }}>
                <Button
                  // color='primary'
                  variant='contained'
                  onClick={this.viewAgreement}
                  disabled={false}
                >
                  {form2.view_agreement}
                </Button>
                {(exercise.table === 'm3-1-4' || exercise.table === 'r6-3-7') && <Button
                  style={{ margin: '0 10px' }}
                  variant='contained'
                  onClick={this.ondownload}
                  disabled={false}
                >
                  {form2.download2}
                </Button>}
              </div>
              {
                status(handleSubmit, values)
              }

            </form>
          )}
      />
    )
  }
}

export default Form
