import Layout from '../components/Layout'
import {
  Payment,
  LaptopWindows,
  // Message,
  // RateReview,
  AssignmentTurnedIn,
  FormatQuote,
  Business,
  LocalAtm,
  // LocalAtm,
  DirectionsRun,
  FilterVintage,
  // Layers,
  AccountBalance,
  Money,
  // MoneyOff,
  GroupAdd,
  RateReview,
  SettingsBackupRestore,
} from '@material-ui/icons'

import C1 from './C1'
// import C2 from './C2'
// import C3 from './C3'
import C4 from './C4'
import C5 from './C5'
import C6 from './C6'
import C7 from './C7'
import C8 from './C8'
import C9 from './C9'
import C10 from './C10'
// import C11 from './C11'
import C12 from './C12'
// import C13 from './C13'
import C14 from './C14'
import C15 from './C15'
import C16 from './C16'
import C17 from './C17'
import C18 from './C18'
import C19 from './C19'
import C20 from './C20'
import { subRouters } from './common';

const Routers = [
  {
    path: subRouters['系统管理'],
    title: '系统管理',
    icon: LaptopWindows,
    component: Layout(C1[0], C1[1]),
  },
  // {
  //   path: subRouters['客户信息'],
  //   title: '客户信息',
  //   icon: Message,
  //   component: Layout(C2[0], C2[1]),
  // },
  // {
  //   path: subRouters['评级限额'],
  //   title: '评级限额',
  //   icon: RateReview,
  //   component: Layout(C3[0], C3[1]),
  // },
  {
    path: subRouters['额度审批'],
    title: '额度审批',
    icon: AssignmentTurnedIn,
    component: Layout(C4[0], C4[1]),
  },
  {
    path: subRouters['额度管理'],
    title: '额度管理',
    icon: FormatQuote,
    component: Layout(C5[0], C5[1]),
  }, {
    path: subRouters['融单'],
    title: '融单',
    icon: Business,
    component: Layout(C6[0], C6[1]),
  }, {
    path: subRouters['外部融资'],
    title: '外部融资',
    icon: LocalAtm,
    component: Layout(C18[0], C18[1]),
  },
  /*{
    path: subRouters['银行预审'],
    title: '银行预审',
    icon: LocalAtm,
    component: Layout(C19[0], C19[1]),
  },*/
  // {
  //   path: subRouters['放款'],
  //   title: '放款',
  //   icon: MoneyOff,
  //   component: Layout(C13[0], C13[1]),
  // },
  {
    path: subRouters['保后管理'],
    title: '保后管理',
    icon: Money,
    component: Layout(C7[0], C7[1]),
  },
  {
    path: subRouters['应收账款管理'],
    title: '应收账款管理',
    icon: AccountBalance,
    component: Layout(C14[0], C14[1]),
  },
  {
    path: subRouters['还款'],
    title: '还款',
    icon: Payment,
    component: Layout(C8[0], C8[1]),
  }, {
    path: subRouters['运营管理'],
    title: '运营管理',
    icon: DirectionsRun,
    component: Layout(C9[0], C9[1]),
  }, 
 /*{
    path: subRouters['流程管理'],
    title: '流程管理',
    icon: FilterVintage,
    component: Layout(C10[0], C10[1]),
  },*/
  // {
  //   path: subRouters['参数配置'],
  //   title: '参数配置',
  //   icon: Layers,
  //   component: Layout(C11[0], C11[1])
  // },
  {
    path: subRouters['用户管理'],
    title: '用户管理',
    icon: GroupAdd,
    component: Layout(C12[0], C12[1])
  },
  {
    path: subRouters['利率管理'],
    title: '利率管理',
    icon: RateReview,
    component: Layout(C15[0], C15[1])
  },
  {
    path: subRouters['授权审批'],
    title: '授权审批',
    icon: RateReview,
    component: Layout(C16[0], C16[1])
  },
  {
    path: subRouters['合同管理'],
    title: '合同管理',
    icon: RateReview,
    component: Layout(C17[0], C17[1])
  },
  {
    path: subRouters['系统日志'],
    title: '系统日志',
    icon: SettingsBackupRestore,
    component: Layout(C20[0], C20[1]),
  },
];

export default Routers;
