
//审核  可以选多条数据一起审核
//历史审核意见参数 opinion_list:[{name:'复核员',content:'同意'}]
 const check = {
    account:'account_id',//账号id
    role: 'role_id',//角色id
    check_opinion:'审核意见*',
    mx_number:'融单编号', //可以字符串拼接
    audit_status:'审核状态',  //传状态id
    sign_agreement: "签订《融单开立协议》*",
    sign_agreement2: "《融单开立协议》",
    sign_flow: "签订《融单流转协议》*",
    sign_financing: "签订《融单融资协议》*",
    sign_cebFinancing: "签订《融单光大融资协议》*",
    sign_bobFinancing: "签订《融单北京银行融资协议》*",
    returnBy:'退回人员',
    distribute:'融资分配',
    //静态
    check2:'审核通过',
    iagree:'我同意',
    verification_code:'手机验证码*',
    verification_code2:'获取验证码',
    verification_code3:'秒后可以重新发送',

    return_reason:'退回原因*',
    signFor:'签收',
    remarks:'备注',
    submit:'提交',
    refuse_signFor:'拒绝签收',
    view_agreement: "查看协议",
    check:'审核',
    send_back:'退回',
    cancel:'取消',
    sendBank:'退回上一级',
    sendBank2:'退回经办人',
    distribute:'融资分配',


    mxf_number:'融资编号',  //20210609 新增弹框展示字段
    applicant : '融资企业', //20210609 新增弹框展示字段
    net_financing_amount:'融资净额', //20210609 新增弹框展示字段
    frozenAmount:'冻结金额', //20210609 新增弹框展示字段
    beFrozenAmount:'待冻结金额',
    thawAmount:'解冻金额',
    paymentAmount:'放款金额', //20210609 新增弹框展示字段
    frozen_ways : '冻结方式', //20210609 新增弹框展示字段
    payment_ways : '支付方式', //20210609 新增弹框展示字段

    enterprise_name:'企业名称',
    limitMark:'额度属性',
    mxAmount:'总额度',
    usedAmount:'已用额度',
    usableAmount:'可用额度',
    creditStartDate:'额度生效时间',
    creditDueDate:'额度失效时间',

}

export {
    check
}
