//额度分配表单
export default {
    select_subsidiary: "选择集团客户子公司",
    select_customer: "选择集团客户",
    enterprise_name: "集团客户名称",
    enterprise_no: "集团客户编号",
    subsidiary_name: "子公司名称",
    subsidiary_no: "子公司编号",
    mx_amount: "集团客户总额度",
    usable_amount: "集团客户可用额度",
    subsidiary_amount: "子公司待分配额度",
    subsidiary_date: "子公司待分配额度使用时间",
    sharing_protocol: "请上传共享协议",
    commitment_letter: "请上传承诺函",
}