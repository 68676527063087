import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import {
  FilterVintage,
  TrackChanges,
  Description,
  PersonPin,
  ExpandLess,
  ExpandMore,
  StarBorder,
  Notifications,
  RotateRight,
  Payment,
  Redo,
} from '@material-ui/icons';
import { loginInfos } from '../../components/numToText';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const getSubRouters = (ppath, path, ary) => () => {
  let roleId = 0;
  let auth = loginInfos();
  try {
    if (auth) {
      roleId = auth.roles[0].id || 0;
    }
  } catch (e) {
    roleId = 0;
  }
  let ary1 = ary.filter((item) => {
    if (roleId === 1) {
      return !(item === "额度申请审核" || item === "额度启用审核") ? item : null;
    } else if (roleId === 8) {
      return !(item === "新增额度申请" || item === "新增额度启用") ? item : null;
    } else if (roleId === 14) {
      return !(item === "新增额度申请" || item === "新增额度启用"||item === "融单开立审核"||item === "融单流转审核") ? item : null;
    } else {
      return !(item === "新增额度申请" || item === "新增额度启用") ? item : null;
    }
  })
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const icons = [
    FilterVintage,
    TrackChanges,
    Payment,
    PersonPin,
    Notifications,
    Description,
    Redo,
    RotateRight,
  ];

  const getSubItems = () => {
    return ary1.map((item, inx) => {
      const Icon = icons[inx] || Description;
      return (
        <ListItem
          button
          component={Link}
          to={`${ppath}/${item}`}
          className={classes.nested}
          key={item}
        >
          <ListItemIcon>
            <Icon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={item} />
        </ListItem>
      )
    });
  };

  return (
    <>
      <ListItem
        button
        key={path}
        onClick={handleClick}>
        <ListItemIcon>
          <StarBorder fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={path} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {getSubItems()}
        </List>
      </Collapse>
    </>
  );
}

export default getSubRouters;