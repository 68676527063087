import React, { Component } from 'react'
import moment from 'moment'
import { Formik } from 'formik';
import Select from 'react-select';
import {
  TextField,
  Button,
  Grid,
  Select as Select2,
  InputLabel,
  MenuItem,
  FormControl,
  // FormControlLabel,
  // Checkbox,
  Typography
} from '@material-ui/core'

import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Upload } from '../../components/upload';

import { numberfun, commafy, delcommafy } from '../../components/numToText'
import { formatDate,getNowFormatDate } from '../../components/date'
import FormikError from './formikError';
import { financingFormik} from './formikValidate';
import SimpleExpansionPanel from './Panels';
import { fetching } from '../../config/utils';
import { numberParseChina, days } from '../../components/numToText'
import { DEFAULT_CONTRCT_URL } from '../../config/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
const { object } = require('yup');

global.count = 0;
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    selectedTrue: {},//下拉框选中的可用融单信息封装--20210204
    form: this.getInitState(),
    form2: this.props.form2 || {},
    open2: false,
    opinion_list: [],
    mxNumberList: [],
    interestList: [],
    uploads: {},
    loading: false,
    loading2: false,
    loadingDsb: false,
    interestPayList: ['是', '否'],
    industrycodeList: [],
    corporationscaleList: [],
    provinceList: [],
    cityList: [],
  }

 
  getInitState() {
    const { exercise, auth = {} } = this.props
    const open_day = new Date();

    const mx_number = exercise.mx_number ?
      { id: exercise.mx_number, mx_number: exercise.mx_number, mxc_number: exercise.mxc_number } : '';
    let applicant = exercise.applicant ? exercise.applicant : exercise.data.companyName;  
    let mx_amount = exercise.mx_amount ? commafy(exercise.mx_amount) : 0;//授信额度
    let usable_amount = exercise.usable_amount ? commafy(exercise.usable_amount) : 0;//可用额度
    let amount = exercise.amount ? commafy(exercise.amount) : exercise.data.financingAmount; 
    //let aaa = numberfun(amount, 12,2);  
    let interest = exercise.interest ? commafy(exercise.interest) : 0;
    let net_financing_amount = exercise.net_financing_amount ? commafy(exercise.net_financing_amount) : 0;
    let financing_cost = exercise.financing_cost ? commafy(exercise.financing_cost) : 0;
    let mxc_number = exercise.mxc_number || '';
    let synthesis_rate = exercise.synthesis_rate || 5.8;
    let payment_day = exercise.payment_day ? exercise.payment_day : exercise.data.endDate; 
    let open_credit_code = exercise.open_credit_code ? exercise.open_credit_code : exercise.data.companyCode;

    let fdd_file_name = exercise.fdd_file_name ? exercise.fdd_file_name : exercise.data.factoringContractNo;   

    let timelimit = '';
    let title = '';
    let corporationname = exercise.legalName || '';
    let detailaddress = exercise.contactAddress || '';
    let collateraldescribe = '';

    let industryCode = '';
    let corporationscalecode = '';
    let provincecode = '';
    let citycode = '';


    var extraction = exercise.extraction,//开立
      issue_credit_code = exercise.open_credit_code || '',//开立信用代码
      circulation = exercise.mx_payer || '', //流转
      circulation_credit_code = exercise.payer_credit_code || '';//流转信用代码
    /*if (auth.organization) {
      applicant = auth.organization.name;
      open_credit_code = auth.organization.credit_no;
    }*/
    if (exercise.save === '4') {
      amount = '';
    }

    const ocid = exercise.id || '';
    const ocid_from = exercise.mxc_number ? 'circulation' : 'issue';
    const form = {
      mx_number,
      applicant,//融资方
      open_credit_code,//融资方信用代码
      extraction, //开立
      issue_credit_code,//开立信用代码
      circulation, //流转
      circulation_credit_code,//流转信用代码
      right_resource: "否",//追索权
      mxc_number,
      mx_amount,//授信额度
      usable_amount,//可用融单金额
      payment_day,//承诺付款日
      ocid,
      ocid_from,

      timelimit,
      title,
      corporationname,
      detailaddress,
      collateraldescribe,

      industryCode,
      corporationscalecode,
      provincecode,
      citycode,


      net_financing_amount,//净融资额
      interest,//利息
      amount,//融资金额
      financing_amount_capital: '',//融资金额大写
      application_number: "",//融资编号
      service_tariffing: 0,//服务费率
      service_charge: 0,//服务费
      financing_cost,//融资成本
      financing_maturity: days(payment_day, open_day),//融资期限


      remarks: '',  //备注
      financing_time: open_day,//融资申请时间
      add_days_number: 0,//增加天数
      synthesis_rate,//预计综合融资息费率（%)
      
      
      
      DialogTitle: "",
      status2: '1', //判断是开具还是复核
      sign_agreement: '1', //签订协议
      save: "1", //判断是新增还是编辑
      imgUrl: '',
      table: exercise.table || '' ,
      
    }
  
    
    return (exercise.save !== '1' && exercise.save !== '4') ? {
      ...form, ...exercise, mx_number, amount,
      net_financing_amount, interest, financing_cost, mx_amount, usable_amount, fdd_file_name,
      register_enddate: formatDate(new Date(), 'yyyy/MM/dd'),
    } : form
  }

  //组件初始化后
  componentDidMount() {
    const { auth = {}, exercise } = this.props;
    fetching('/sl/findProvince', {
      method: 'GET',
    }).then(res => {
      if (res) {
        let data1 = res.data.filter((item) => {
          //item.province = item.code;
          item.label = item.name;
          return (item.id !== 1) ? item : null;
      })
      this.setState({ provinceList: data1 })
      } else {
      }
    })
    fetching('/sl/findIndustrycode', {
      method: 'GET',
    }).then(res => {
      if (res) {
        let data2 = res.data.filter((item) => {
          //item.province = item.code;
          item.label = item.name;
          return item;
      })
      this.setState({ industrycodeList: data2 })
      } else {
      }
    })
    fetching('/sl/findCorporationscale', {
      method: 'GET',
    }).then(res => {
      if (res) {
        let data3 = res.data.filter((item) => {
          //item.province = item.code;
          item.label = item.name;
          return item;
      })
      this.setState({ corporationscaleList: data3 })
      } else {
      }
    })
    if(exercise.mxf_number) {
      fetching(`/sl/getCollateraldescribe?mxfNumber=${exercise.mxf_number}`).then(res => {    
        if (res) {
          this.setState({ 
            form: {
            ...this.state.form,
            collateraldescribe: res.data,
          }   
        })
        } else {
        }

      })
     } 
   }

  //输入框失去焦点
  handleBlur2 = (value) => {
    return commafy(value);
  }
  
  handleChange = ({ target: { value, name } }) => {
    const { payment_day } = this.state.form;
    let valuenew = "";
    if (name === "timelimit") {
      let valuenew = value;
      let now = new Date();
      let newDate = new Date(now.setMonth(now.getMonth()+parseInt(value)))
      newDate = new Date(newDate.setDate(newDate.getDate() - 1));
      newDate = formatDate(newDate, 'yyyy/MM/dd');
      this.setState({
        form: {
          ...this.state.form,
          register_enddate: newDate,
          timelimit: valuenew,
        }
      })
    }
    if (name === "title") {
      let valuenew = value
      this.setState({
        form: {
          ...this.state.form,
          title: valuenew,
        }
      })
    }
    if (name === "maincontractnumber") {
      let valuenew = value
      this.setState({
        form: {
          ...this.state.form,
          fdd_file_name: valuenew,
        }
      })
    }
    if (name === "debtorname") {
      let valuenew = value
      this.setState({
        form: {
          ...this.state.form,
          applicant: valuenew,
        }
      })
    }
    if (name === "organizationcode") {
      let valuenew = value
      this.setState({
        form: {
          ...this.state.form,
          open_credit_code: valuenew,
        }
      })
    }
    if (name === "corporationname") {
      let valuenew = value
      this.setState({
        form: {
          ...this.state.form,
          corporationname: valuenew,
        }
      })
    }
    if (name === "industryregistrationcode") {
      let valuenew = value
      this.setState({
        form: {
          ...this.state.form,
          open_credit_code: valuenew,
        }
      })
    }
    if (name === "detailaddress") {
      let valuenew = value
      this.setState({
        form: {
          ...this.state.form,
          detailaddress: valuenew,
        }
      })
    }
    if (name === "amount") {
      let valuenew = value
      this.setState({
        form: {
          ...this.state.form,
          amount: valuenew,
        }
      })
    }
    if (name === "collateraldescribe") {
      let valuenew = value
      this.setState({
        form: {
          ...this.state.form,
          collateraldescribe: valuenew,
        }
      })
    }
    return valuenew;

  }

  handleChange1(e)  {
    this.setState({
      form: {
        ...this.state.form,
        industryCode: e.code,
      }
    })
  }

  handleChange2(e)  {
    this.setState({
      form: {
        ...this.state.form,
        corporationscalecode: e.code,
      }
    })
  }

  handleChange3(e)  {
    this.setState({
      form: {
        ...this.state.form,
        provincecode: e.code,
      }
    })
    fetching(`/sl/findCityByProvinceId?spc_id=${e.spc_id}`).then(res => {    
      if (res) {
        let datas = res.data.filter((item) => {
          //item.province = item.code;
          item.label = item.name;
          return item;
      })
      this.setState({ cityList: datas })
      } else {
      }
    })
  }

  handleChange4(e)  {
    this.setState({
      form: {
        ...this.state.form,
        citycode: e.code,
      }
    })
  }
  
  //保存 提交
  handleSubmit = (form2, name) => {
    this.setState({ loading: true, loadingDsb: true });  
    const text = name || '取消';
    let body = {};
    body.requestno = this.state.form.mxf_number ? this.state.form.mxf_number : this.state.form.data.financingNo;
    body.timelimit = this.state.form.timelimit;
    body.title = this.state.form.title;
    body.debtorname = this.state.form.applicant;
    body.organizationcode = this.state.form.open_credit_code;
    body.industryregistrationcode = this.state.form.open_credit_code;
    body.corporationname = this.state.form.corporationname;
    body.industrycode = this.state.form.industryCode;
    body.corporationscale = this.state.form.corporationscalecode;
    body.nationality = "CHN";
    body.province = this.state.form.provincecode;
    body.city = this.state.form.citycode;
    body.detailaddress = this.state.form.detailaddress;
    body.maincontractnumber = this.state.form.fdd_file_name;
    body.maincontractcurrency = "CNY";
    body.maincontractsum = numberfun(this.state.form.amount, 12,2);
    body.pledgecontractcurrency = "CNY";
    body.pledgecontractsum = numberfun(this.state.form.amount, 12,2);
    body.collateraldescribe = this.state.form.collateraldescribe;

    
    //提交表单
    if (text === '提交' || text === '保存') {
      this.setState({ loading: true, loadingDsb: true });
      fetching('/zd/initRegister', {
        method: 'POST',
        body: JSON.stringify(body)
      }).then(res => {
        if(res){
          alert(res.msg);
        }
      })
   }
 }


  render() {
    const { form2, form, loading, loadingDsb, industrycodeList, corporationscaleList, provinceList, cityList } = this.state;
    const { exercise, auth = {} } = this.props;
    const roleId = auth.roles ? auth.roles[0].id : '';
    
    const status = (handleSubmit, values) => {
      if (exercise.status2 === '1') {
        return <Grid container justify="center" style={{ width: '100%', padding: '10px 0' }}>
          <div style={{ position: 'relative' }}>
            <Button
              color='primary'
              variant='contained'
              onClick={this.handleSubmit.bind(this, values, form2.submit)}
              disabled={loadingDsb}
              name={form2.submit}
              style={{ marginRight: '10px' }}
            >
              {form2.submit}
            </Button>
          </div>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.revocation)}
            name={form2.revocation}
            disabled={loadingDsb}
            style={{ marginRight: '10px' }}
          >
            {form2.revocation}
          </Button>
        </Grid>
      }
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
       
        //表单验证
        //validationSchema={object().shape(zdFormik(form2))}
 
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <form>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.timelimit}
                    value={form.timelimit}
                    name='timelimit'
                    onChange={e => setFieldValue('register_enddate', this.handleChange.bind(this, e)())}
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'timelimit'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    title={form.register_enddate}
                    label={form2.register_enddate}
                    value={form.register_enddate}
                    name='register_enddate'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'register_enddate'} />
                </Grid>

              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                  <Grid container justify="space-between">
                    <Grid item xs={5}>
                      <TextField
                        label={form2.title}
                        value={form.title}
                        onChange={this.handleChange}
                        name='title'
                        margin='normal'
                        fullWidth
                        disabled={exercise.save === '3'}
                      />
                      <FormikError touched={touched} errors={errors} name={'title'} />
                    </Grid>
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline"
                        label={form2.payment_day}
                        value={form.payment_day}
                        onBlur={handleBlur}
                        disabled={true}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'payment_day'} />
                    </Grid>

                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.maincontractnumber}
                    value={form.fdd_file_name}
                    onChange={this.handleChange}
                    name='maincontractnumber'
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'maincontractnumber'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.debtorname}
                    value={form.applicant}
                    onChange={this.handleChange}
                    name='debtorname'
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'debtorname'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.organizationcode}
                    value={form.open_credit_code}
                    onChange={this.handleChange}
                    name='organizationcode'
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'organizationcode'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.corporationname}
                    value={form.corporationname}
                    onChange={this.handleChange}
                    name='corporationname'
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'corporationname'} />
                  </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.industryregistrationcode}
                    value={form.open_credit_code}
                    onChange={this.handleChange}
                    name='industryregistrationcode'
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'industryregistrationcode'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form2.industrycode}</span>
                  <Select
                    isSearchable
                    value={form.industrycode}
                    onChange={e => setFieldValue('industrycode', this.handleChange1.bind(this, e)())}
                    options={industrycodeList}
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'industrycode'} />
                </Grid>
                <Grid item xs={5}>
                  <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form2.corporationscale}</span>
                  <Select
                    isSearchable
                    value={form.corporationscale}
                    onChange={e => setFieldValue('corporationscale', this.handleChange2.bind(this, e)())}
                    options={corporationscaleList}
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'corporationscale'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form2.province}</span>
                  <Select
                    isSearchable
                    value={form.province}
                    onChange={e => setFieldValue('province', this.handleChange3.bind(this, e)())}
                    options={provinceList}
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'province'} />
                </Grid>
                <Grid item xs={5}>
                  <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form2.city}</span>
                  <Select
                    isSearchable
                    value={form.city}
                    onChange={e => setFieldValue('city', this.handleChange4.bind(this, e)())}
                    options={cityList}
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'city'} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={form2.detailaddress}
                  value={form.detailaddress}
                  onChange={this.handleChange}
                  name='detailaddress'
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'detailaddress'} />
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.maincontractsum}
                    value={form.amount}
                    onChange={this.handleChange}
                    name='amount'
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'amount'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.maincontractcurrency}
                    value={"人民币"}
                    name='maincontractcurrency'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'maincontractcurrency'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.pledgecontractsum}
                    value={form.amount}
                    onChange={this.handleChange}
                    name='amount'
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'amount'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.pledgecontractcurrency}
                    value={"人民币"}
                    name='pledgecontractcurrency'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'pledgecontractcurrency'} />
                </Grid>
                <Grid item xs={12}>
                <TextField
                  label={form2.collateraldescribe}
                  value={form.collateraldescribe}
                  onChange={this.handleChange}
                  name='collateraldescribe'
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'collateraldescribe'} />
              </Grid>
              </Grid>
              {
                status(handleSubmit, values)
              }

            </form>
          )}
      />
    )
  }
}

export default Form
