//管理层信息
export default {
    enterprise_name:"客户名称",
    enterprise_no:"客户编号",
    management_name:"管理层姓名",
    position:"职务",
    associated_info:"关联信息",
    legal_other_duty:"法人其他任职信息",
    judicial_info:"司法查控信息",
    credit_attention_bad: "征信关注及不良"
}
