import React from 'react';
import { Switch, Route,Redirect, Link } from 'react-router-dom';

import {
  ChromeReaderMode,
  Warning,
  StarBorder
} from '@material-ui/icons';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { subRouters } from './common';
import Accordion from '../components/Accordion'
import { SetView } from './managementAfterLoan/commonTable'
import { setViewRealTime } from './managementAfterLoan/RealTimeManagement/commonTable'

import {
  C71,
  C72,
  C73,
  C74,
  C79,
} from './containers'
import { subRootAry1 } from './containers/C7_1'
import { subRootAry2 } from './containers/C7_2'
import { subRootAry3 } from './containers/C7_3'
import { subRootAry4 } from './containers/C7_4'
import { subRootAry9 } from './containers/C7_9'
import { C712Form } from './managementAfterLoan'


const PARENT_PATH = subRouters['保后管理'];

const subRootAry = [
  ...subRootAry1,
  ...subRootAry2,
  ...subRootAry3,
  ...subRootAry4,
  ...subRootAry9
]
const Info = [
{
    path: "保后汇总表",
    icon: StarBorder,
    component: SetView('r7-7-1')
  },
  /*{
    path: "贷后基础管理",
    icon: ChromeReaderMode,
    component: C71
  },
  {
    path: "贷后定期管理",
    icon: ChromeReaderMode,
    component: C72
  },
  {
    path: "贷后实时管理",
    icon: ChromeReaderMode,
    component: C73
  },
  {
    path: "台账管理",
    icon: ChromeReaderMode,
    component: C74
  },
  {
    path: "贷后实时风险分类",
    icon: StarBorder,
    component: SetView('r7-1-1')
  },
  {
    path: "贷后不定期检查流程",
    icon: StarBorder,
  },
  {
    path: "逾期欠息发生报告流程",
    icon: StarBorder,
  },
  {
    path: "贷后高风险定期检查流程",
    icon: StarBorder,
  },
  {
    path: "风险预警",
    icon: Warning,
    component: C79
  },*/
  
];

const view = {};
subRootAry.forEach((item, index) => {
  switch (item) {
   /* case '贷后基础条件管理查询':
      view[item] = SetView('r7-1-1');
      break;
    case '短信提示信息维护':
      view[item] = C712Form;
      break;
    case '短信相关信息查询':
      view[item] = SetView('r7-1-3');
      break;
    case '财务报表查询':
      view[item] = setViewRealTime('r7-2-1');
      break;
    case '定期检查情况查询':
      view[item] = setViewRealTime('r7-2-2');
      break;
    case '风险分类变动情况查询':
      view[item] = SetView('r7-1-1');
      break;
    case '逾期欠息查询':
      view[item] = SetView('r7-1-1');
      break;
    case '风险提示及反馈':
      view[item] = SetView('r7-1-1');
      break;
    case '融资款收回方式登记':
      view[item] = SetView('r7-1-1');
      break;
    case '授信发放和收回流水查询':
      view[item] = SetView('r7-1-1');
      break;
    case '未到期借据查询':
      view[item] = SetView('r7-1-1');
      break;
    case '风险预警解除查询':
      view[item] = SetView('r7-1-1');
      break;
    case '风险预警认定查询':
      view[item] = SetView('r7-1-1');
      break;
    case '未到期预警授信查询':
      view[item] = SetView('r7-1-1');
      break;
    case '预警级别调整查询':
      view[item] = SetView('r7-1-1');
      break;
    default:
      ;*/
  }
})


const getMenu = (parent_path, info) => () => {
  const list = info.map((item, index) => {
    const CompIcon = item.icon;
    switch (index) {
      //case 0:
        //return <C71 key="C71" />;
      case 1:
        return <C72 key="C72" />;
      case 2:
        return <C73 key="C73" />;
      case 3:
        return <C74 key="C74" />;
      case 8:
        return <C79 key="C79" />;
      default:
        return (
          <ListItem button component={Link} to={`${parent_path}/${item.path}`} key={item.path}>
            <ListItemIcon>
              <CompIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={item.path} />
          </ListItem>
        );
    }
  });
  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
};
const getContent = (parent_path, info) => () => {
  return (
    <Switch>
      <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${"融后汇总表"}`}
      />
      {info.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${parent_path}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${parent_path}/${item.path}`}
              render={() => <h2>{item.path}</h2>}
              key={item.path}
            />
          )
        }
      })}

      {subRootAry.map(item => {
        if (view[item]) {
          return (
            <Route
              path={`${parent_path}/${item}`}
              component={view[item]}
              key={item}
            />
          )
        }
        else {
          // return null;
          return (
            <Route
              path={`${parent_path}/${item}`}
              render={() => <h2>{item}</h2>}
              key={item}
            />
          )
        }
      })}
    </Switch>

  )
};
export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];