
import getSubRouters from './helper';

export const C2_1subRootAry = [
    '关联客户名单查询维护',
    '单一客户管理',
    '集团客户管理',
    '集团客户识别认定',
  ];

const PARENT_PATH = '/risk-management/customer-info';
const PATH = '查询';
 export default getSubRouters(PARENT_PATH, PATH, C2_1subRootAry);