//融单开立表单
export default {
    extraction: "供应商名称*",
    open_credit_code: "供应商组织机构代码*",
    extraction: "供应商名称*",
    extraction1: "债权人名称*",
    receive:'债务人*',
    receive1:'债务人名称*',
    usable_amount: "可用额度*",
    receivableAmount:'应收账款净额*',
    applyAmount: "融资申请金额*",
    sign_agreement: "我同意",
    open_day: "应收账款起始日*",
    payment_day: "应收账款到期日*",
    contractNo: "合同编号*",
    Upload_contracts: "上传合同*",
    Upload_invoice: "上传发票*",
    Upload_contracts_invoice: "上传明细表*",
    Upload_rest: "其他文件",
    Upload_dispatch: "上传发货单",
    Upload_shipping: "上传运输单",
    discount_rate: "折扣率*",
    synthesis_rate: "预计融资息费率*",
    financing_maturity:'融资期限（天）',
    right_resource: "追索权",
    interest_pay: "利息支付方式",
    add_days_number:'增加天数',
    interest: "预计融资息费金额*",
    financing_cost:'融资成本',
    net_financing_amount: "净融资额*",
    special_agreement:"特殊约定",
    remarks: "备注",
    receivable:"发票总金额*",
    //静态资源 不用在数据库里面加这些字段
    // usable_amount_capital: "大写额度*",
    sign_agreement2: "《应收账款协议》*",
    view_agreement: "查看应收账款协议",
    view_case: "查看案例",
    check_opinion: "审核意见",
    amount_capital: "大写金额*",
    guarantor:"开立担保人",
}