import React, { Component } from 'react'
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  Button,
  Grid,
  Typography,
  FormControl,
  InputAdornment,
  Input,
  CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import {
  AddCircle,
  RemoveCircle,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles';

import { numberfun, commafy, delcommafy } from '../../components/numToText';
// import FormikError from '../../components/commonForm/formikError';
import { C151Formik } from './formikValidate'
import CheckForm from '../../components/commonForm/CheckForm';


const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
const useStyles = (theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: '#fff',
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '20px 50px',

    },
  },
  root: {
    margin: 'auto',
  },
  textField: {
    width: '100%',
    marginBottom: '8px',
    marginTop: '16px',
  },
  textField1: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '24px',
  },
  center: {
    textAlign: 'center'
  }
}));
let rate = { min_amount: '', max_amount: '', synthesis_rate: '' }
class Form extends Component {
  state = {
    form: this.getInitState(),
    formStatic: this.props.formStatic || {},//静态资源
    loading: false,
  }
  getInitState() {
    const { exercise = {} } = this.props
    let form = {}
    if (exercise.save !== '1') {
      if (exercise.rates instanceof Array && exercise.rates.length > 0) {
        let rates = exercise.rates.map((item) => {
          if (item.min_amount) {
            item.min_amount = commafy(item.min_amount)
          }
          if (item.max_amount) {
            item.max_amount = commafy(item.max_amount)
          }
          return item;
        })
        form.rates = rates
      } else {
        form.rates = []
      }
    } else {
      form.rates = [{ ...rate }]
    }
    return exercise.save === '1' ? { ...form } : { ...form, ...exercise }
  }

  //输入框值改变
  handleChange = (e, index) => {
    e.preventDefault();
    let { name, value } = e.target;
    const { rates } = this.state.form
    if (name === 'min_amount' || name === 'max_amount') {
      rates[index][name] = numberfun(value)
    } else {
      rates[index][name] = value
    }
    this.setState({ form: { ...this.state.form, rates } })
  }
  //输入框失去焦点 金额 千分位
  handleBlur = (e, index) => {
    e.preventDefault();
    let { name, value } = e.target;
    const { rates } = this.state.form
    
    if (name === 'min_amount' || name === 'max_amount') {
      rates[index][name] = commafy(value) !== 0 ? commafy(value) : ''

      this.setState({ form: { ...this.state.form, rates } })
    }
  }


  addItems = () => {   
    const { rates } = this.state.form
    for(let i=0;i< rates.length;i++){
      if (rates[i].min_amount===null || rates[i].min_amount==='') {
	alert("最小金额不能为空")
        return
      }
      if (rates[i].max_amount===null || rates[i].max_amount==='') {
        alert("最大金额不能为空")
        return
      }
      if (rates[i].synthesis_rate===null || rates[i].synthesis_rate==='') {
        alert("利率不能为空")
        return
      }
    }
    rates.push({ ...rate });
    this.setState({ form: { ...this.state.form, rates } })
  }
  deleteItems = (index) => {
    const { rates } = this.state.form;
    rates.splice(index, 1);
    this.setState({ form: { ...this.state.form, rates } })
  }

  //保存 提交
  handleSubmit = (values, handle) => {
    const { auth } = this.props;
    let params = values || this.state.form;
    const text = handle || 'cancle';
    let ids = params._id || '';
    const { rates } = this.state.form
    if (text === 'submit') {
      this.setState({ loading: true })
    }
    if (rates.length < 1) {
      alert("利率提交表单不能为空")
      window.location.reload();
      return;
    } 
    let rates1 = rates.map((item) => {
      if (item.min_amount) {
        item.min_amount = delcommafy(item.min_amount)
      }
      if (item.max_amount) {
        item.max_amount = delcommafy(item.max_amount)
      }
      if (item.synthesis_rate) {
        item.synthesis_rate = delcommafy(item.synthesis_rate)
      }
      return item;
    })
    for(let i=0;i< rates1.length;i++){
      if (rates1[i].min_amount===null || rates1[i].min_amount==='') {
        alert("最小金额不能为空")
        this.setState({ loading: false })
        return
      }
      if (rates1[i].max_amount===null || rates1[i].max_amount==='') {
        alert("最大金额不能为空")
        this.setState({ loading: false })
        return
      }
      if (rates1[i].synthesis_rate===null || rates1[i].synthesis_rate==='') {
        alert("利率不能为空")
        this.setState({ loading: false })
        return
      }
    }
    let paramsDate = {
      _id:ids,
      rates: rates1,
      auth: auth,
    }
    //提交表单
    if (text !== 'cancle') {
      fetching(`/smrd/interest_rate/save/${ids}`, {
        method: ids ? 'POST' : 'POST',
        body: JSON.stringify(paramsDate)
      }).then((data) => {
        this.setState({ loading: false })
        try {
          if (data !== undefined && data !== null) {
            if (data.msg) {
              alert(data.msg)
              return;
            }
            this.props.onSubmit({
              ...data,
              submit_name: text
            })
          }else {
            alert('提交失败')
          }
        } catch (error) {
          console.log(error)
        }
      })
    } else {
      const { form } = this.state
      form.rates = []
      this.setState({ form: { ...form, save: '1' } })
      this.props.onSubmit({
        ...paramsDate,
        submit_name: text
      })
    }
  }

  //审核意见
  FormSubmit3 = (data) => {
    this.props.onSubmit({
      ...data,
      submit_name: 'submit'
    })
  }

  render() {
    const { form, formStatic, loading } = this.state
    const { exercise = {}, classes, auth, dataUrl, table } = this.props;
    const checkform = () => {      
      //debugger
      return <CheckForm auth={auth} exercise={exercise} dataUrl={dataUrl}
        onSubmit={this.FormSubmit3}
      />
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        validate={(values) => {
          return C151Formik(values, formStatic, form.rates);
        }}

        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, 'submit');
        }}
        render={({
          values,
          //errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
            <div className={classes.layout}>
              <form >
                <div>
                  <h4>利率表</h4>
                  <Grid container justify="space-between" alignItems='center' alignContent='center'>
                    <Grid item xs={8} className={classes.center}>
                      <Typography variant="body1" gutterBottom >
                        {formStatic.amount_interval}
                      </Typography>
                    </Grid>

                    <Grid item xs={2} className={classes.center}>
                      <Typography variant="body1" gutterBottom>
                        {formStatic.synthesis_rate}
                      </Typography>
                    </Grid>
                    {exercise.save !== '3' &&
                      <Grid item xs={1} className={classes.center}>
                        <AddCircle fontSize="small" color="primary" onClick={this.addItems} />
                      </Grid>
                    }
                  </Grid>
                  {
                    form.rates.map((items, index) => {
                      return (
                        <div key={index}>

                          <Grid container justify="space-between" >
                            <Grid item xs={8} >
                              <Grid container justify="space-between">
                                <Grid item xs={4}>
                                  <FormControl className={classes.textField}>
                                    {/* <InputLabel htmlFor="min_amount">{formStatic.min_amount}</InputLabel> */}
                                    <Input
                                      name="min_amount"
                                      value={items.min_amount || ''}
                                      onChange={e => { this.handleChange(e, index) }}
                                      onBlur={e => { this.handleBlur(e, index) }}
                                      endAdornment={<InputAdornment position="end">元</InputAdornment>}
                                      disabled={exercise.save === '3'}
                                      inputProps={{
                                        maxLength: 50,
                                      }}
                                    />
                                  </FormControl>
                                  {/* {(errors.rates instanceof Array && errors.rates[index].min_amount) &&
                                    <div className="errorMsg">{errors.rates[index].min_amount}</div>
                                  } */}
                                </Grid>
                                <Grid item xs={3} className={classes.center}>
                                  <Grid container justify="space-between" alignItems="center" className={classes.textField1}>
                                    <Typography >
                                      <span>&lt;=</span>融单金额<span>&lt;</span>
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid item xs={4}>

                                  <FormControl className={classes.textField}>
                                    {/* <InputLabel htmlFor="max_amount">{formStatic.max_amount}</InputLabel> */}
                                    <Input
                                      name="max_amount"
                                      value={items.max_amount || ''}
                                      onChange={e => { this.handleChange(e, index) }}
                                      onBlur={e => { this.handleBlur(e, index) }}
                                      endAdornment={<InputAdornment position="end">元</InputAdornment>}
                                      disabled={exercise.save === '3'}
                                      inputProps={{
                                        maxLength: 50,
                                      }}
                                    />
                                  </FormControl>
                                  {/* {(errors.rates instanceof Array && errors.rates[index].max_amount) &&
                                    <div className="errorMsg">{errors.rates[index].max_amount}</div>
                                  } */}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={2} >
                              <FormControl className={classes.textField}>
                                {/* <InputLabel htmlFor="synthesis_rate">{formStatic.synthesis_rate}</InputLabel> */}
                                <Input
                                  name="synthesis_rate"
                                  value={items.synthesis_rate || ''}
                                  onChange={e => { this.handleChange(e, index) }}
                                  onBlur={e => { this.handleBlur(e, index) }}
                                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                  disabled={exercise.save === '3'}
                                  type="number"
                                  inputProps={{
                                    maxLength: 10,
                                    shrink: "true",
                                  }}
                                />
                              </FormControl>
                              {/* {(errors.rates instanceof Array && errors.rates[index].synthesis_rate) &&
                                <div className="errorMsg">{errors.rates[index].synthesis_rate}</div>
                              } */}
                            </Grid>
                            {exercise.save !== '3' &&
                              <Grid item xs={1} className={classes.center}>
                                <RemoveCircle className={classes.textField} style={{ marginTop: '24px' }} fontSize="small" color="primary" onClick={this.deleteItems.bind(this, index)} />
                              </Grid>}
                          </Grid>

                        </div>
                      )
                    })
                  }
                  {exercise.save !== '3' &&
                    <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
                      <div style={{ position: 'relative' }}>
                        <Button
                          style={{ margin: '0 10px' }}
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          onClick={handleSubmit}
                        >
                          提交
                    </Button>
                        {loading && <CircularProgress size={24} style={loadingStyle} />}
                      </div>
                      <Button variant="contained" color="primary" onClick={this.handleSubmit.bind(this, values, 'cancle')}>取消</Button>
                    </div>
                  }
                </div>
              </form>
              {(exercise.save !== '1' && table !== 'm3-2-9') &&
                <div>
                  {checkform()}
                </div>}
            </div>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);



