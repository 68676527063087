import React from 'react';
import { subRouters } from './common';
import { SetView } from './commonTable';
import {
  OfflineBolt as OfflineBoltIcon,
  History as HistoryIcon,
} from '@material-ui/icons';
import { Switch, Route,Redirect, Link } from 'react-router-dom';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
  } from '@material-ui/core';
import { subRootAry1 } from './containers/C20_1'
import { subRootAry2 } from './containers/C20_2'
import { subRootAry3 } from './containers/C20_3'
import { C20_1 as C201  } from './containers'
import { C20_2 as C202  } from './containers'
import { C20_3 as C203  } from './containers'
import Accordion from '../components/Accordion'
const PARENT_PATH = subRouters['系统日志'];
const subRootAry = [
    ...subRootAry1,
    ...subRootAry2,
    ...subRootAry3,
  ];
const Info = [
  {
    path: "用户操作日志",
    icon: OfflineBoltIcon,
    component: C201,
  },
  {
    path: "业务操作日志",
    icon: OfflineBoltIcon,
    component: C202,
  },
  {
    path: "其他操作日志",
    icon: OfflineBoltIcon,
    component: C203,
  },
  
];
const view = {};
subRootAry.forEach(item => {
  switch (item) {
    case '用户登录日志':
      view[item] = SetView('r20-1-1');
      break;
    case '用户信息变更日志':
      view[item] = SetView('r20-1-2');
      break;
    case '开立日志':
      view[item] = SetView('r20-2-1');
      break;
    case '流转日志':
      view[item] = SetView('r20-2-2');
      break;
    case '融资日志':
      view[item] = SetView('r20-2-3');
      break;
    case '额度日志':
      view[item] = SetView('r20-2-4');
      break;
    case '企业关联日志':
      view[item] = SetView('r20-2-5');
      break;
    case '额度分配日志':
      view[item] = SetView('r20-2-6'); 
      break;
    case '额度回收日志':
      view[item] = SetView('r20-2-7');
      break;
    case '应收账款日志':
      view[item] = SetView('r20-2-8');
      break;
    case '其他日志':
      view[item] = SetView('r20-3-1');
      break;
    default: ;
  }
})
const getMenu = (parent_path, info) => () => {
  const list = info.map((item, index) => {
    const CompIcon = item.icon;
    switch (index) {
      case 0:
        return <C201 key="C201" />;
      case 1:
        return <C202 key="C202" />;
      case 2:
        return <C203 key="C203" />;
      default:
        return (
          <ListItem button component={Link} to={`${parent_path}/${item.path}`} key={item.path}>
            <ListItemIcon>
              <CompIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={item.path} />
          </ListItem>
        );
    }
  });
  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
};
const getContent = (parent_path, info) => () => {
    return (
      <Switch>
        <Redirect
          exact
          from={PARENT_PATH}
          to={`${PARENT_PATH}/${subRootAry1[0]}`}
        />
        {info.map(item => {
          if (item.component) {
            return (
              <Route
                path={`${parent_path}/${item.path}`}
                component={item.component}
                key={item.path}
              />
            )
          }
          else {
            return (
              <Route
                path={`${parent_path}/${item.path}`}
                render={() => <h2>{item.path}</h2>}
                key={item.path}
              />
            )
          }
        })}
  
        {subRootAry.map(item => {
          if (view[item]) {
            return (
              <Route
                path={`${parent_path}/${item}`}
                component={view[item]}
                key={item}
              />
            )
          }
          else {
            // return null;
            return (
              <Route
                path={`${parent_path}/${item}`}
                render={() => <h2>{item}</h2>}
                key={item}
              />
            )
          }
        })}
      </Switch>
  
    )
  };

export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];
