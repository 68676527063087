import React, { Component } from 'react';
import MaterialTable from '../vendors/material-table';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import {
  Button,
  ButtonGroup,
} from '@material-ui/core'
import { R96Form } from '../01-risk-management/containers';
import {
  MonetizationOn,//还款审批恢复额度--20201222
  ViewComfy,
  CloudUpload,
  Edit,
  Search,
  Input,
  InsertChart,
  ViewWeekOutlined,
} from '@material-ui/icons'
import { CommonDialog,CheckBoxForm ,Form1 ,CebCheckForm} from '../components/commonForm';
import { defer, capitalize, fetching } from '../config/utils';
import { core_business_url } from './core_business_url';
import { common } from './formStatic'
import { loginInfos } from '../components/numToText'
import { DEFAULT_EXPORT_URL } from '../config/constants'
import { DownloadTimeForm } from '../02-core_business/downloadTime';//时间选择弹窗--20200917
import DialogForm from '../components/commonForm/DialogForm';//时间选择弹窗--20200917
import { Dialog, C41Form, C61Form, C321Form,C12CEBForm} from '../02-core_business/containers2';//还款审批恢复额度--20201222
import { f4_1, f3_2_1, f6_7 } from '../02-core_business/formStatic';//还款审批恢复额度--20201222
import { BfeMsgInputForm, DialogbfeMsg,  } from '../02-core_business/bfeMsgIn';//北金所加参弹框

// copied from 05-.../DataView.js
class View extends Component {
  state = {
    columns: [{}],
	isLoading: true,
    pageData: {
      totalCount: 0,
      page: 1,
      pageSize: 5,
      orderBy: null
    },
    data: [],
    hasToken: sessionStorage.getItem("authToken"),
    dataUrl: { url: { query: '' } },
    id: 10000,
    form2: { ...common },
    params: {},
    exercise: {
      save: '1',
      DialogTitle: '新增',
      open: false,
      status2: '1'
    },
    auth: {},
    opinion_list: []
  };
  //获取数据
  componentDidMount() {
    const { table } = this.props;
    fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {
        if (data !== undefined && data !== null) {
          let auth = loginInfos();
          const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
          //根据不同的页面，获取不同的接口地址，渲染不同的数据
          const dataUrl = core_business_url(auth, table).find(item => item.name === table);
          const dataUrl2 = dataUrl || {};
          this.setState({ columns, dataUrl: dataUrl2, auth });

          if (dataUrl2.url) {
            dataUrl2.url.query !== '' && this.onQuery();
          }
        }

      })
  }

  //查询数据
  onQuery = () => {
    const { url } = this.state.dataUrl;
    const { table } = this.props;
     if (table === 'm6-6') {
      this.onQueryContract();
      return;
    }
    //年、月、半年、获取数据
    if(table === 'm6-2' || table ==='m6-4' || table === 'm6-3'|| table === 'm6-1' || table === 'r20-1-1' || table === 'r20-1-2'
    || table === 'r20-2-1' || table === 'r20-2-2' || table === 'r20-2-3' || table === 'r20-1-4' || table === 'r20-3-1'
    || table === 'r20-2-4' || table === 'r20-2-5' || table === 'r20-2-6' || table === 'r20-2-7' || table === 'r20-2-8' || table === 'r8-3' || table === 'r8-6') {
      this.QueryByPeriod(1)
      return;
    }
    //还款模块查询--20201222
    if (table === 'r8-1' || table === 'r8-2' /*|| table === 'r8-3' || table === 'r8-6' */|| table === 'r8-8' ) {//r8-1:待还款; r8-2:已还款; r8-3:额度已恢复; r8-6:额度不可恢复
      //is_westcol_done: 0: 待还款, 1: 审核中, 2. 还款中, 3. 已退回, 4. 已还款, 5. 未还款//defaultValue: 0  8:还款复核
      //type: 1-后端/全部；2-前端/指定公司
      this.onQueryRepayment();
      return;
    }
    //开立到融资时间统计--20210106
    if (table === 'r9-5') {//r9-5:开立到融资时间统计
      this.onQueryTimeAmount();
      return;
    }
    if (table === 'r9-2-1' || table === 'r9-2-2') {//r9-2-1 数据统计
      try {
        const { url } = this.state.dataUrl;
        fetching(`${url.query}`).then(res => {
          if (res !== undefined) {
            this.setState({ data: res.data });
          } else {
            alert('查询失败')
          }
        })
      } catch (e) {
      }
      return;
    }
	/* if(table === 'r8-4'){
      this.startTimer(); // 开始定时查询
      return;
    }*/
    // if (table === 'm6-4' || table === 'm6-5') {
      if ( table === 'm6-5') {
      const { url } = this.state.dataUrl;
      
      fetching(`${url.query}`).then(res => {
          if (res !== undefined && res !== null) {
          this.setState({ data: res});
        }
      })
      return;
    }
    if(table === 'm6-7'){
      const { url } = this.state.dataUrl;
      
      fetching(`${url.query}`).then(res => {
          if (res !== undefined && res !== null) {
          this.setState({ data: res});
        }
      })
      return;
    }
    if (table === 'r8-4') {
      const { url } = this.state.dataUrl;
      fetching(url.query).then(res => {
         if (res !== undefined && res.data !== null) {
          this.setState({ data: res.data});
       }
     })
     return;
   }

    if (table === 'r8-5') {
      try {
        const { url } = this.state.dataUrl;
        fetching(`${url.query}`).then(res => {
          if (res !== undefined) {
            if (res.code === 200) {
  
              this.setState({ data: res.data });
            } else {
              alert(res.msg || '查询失败')
            }
          } else {
            alert('查询失败')
          }

        })
      } catch (e) {
      }
      return;
    }
    
    if (table === 'r9-4') {
      try {
        const { url } = this.state.dataUrl;
        fetching(`${url.query}`).then(res => {
         if (res !== undefined) {
            if (res.code === 200) {
  
              this.setState({ data: res.data });
            } else {
              alert(res.msg || '查询失败')
            }
          } else {
            alert('查询失败')
          }
  
        })
      } catch (e) {
      }
      return;
    }
    if (table === 'r9-6') {
      try {
        const { url } = this.state.dataUrl;
        fetching(`${url.query}`).then(res => {
          if (res !== undefined) {
            //debugger
            this.setState({ data: res });
          } else {
            alert('查询失败')
          }
        })
      } catch (e) {
      }
      return;
    }
    if (table === 'r8-7' || table === 'r6-3-11' ) {
      try {
        const { url } = this.state.dataUrl;  
        //const enterprise_no = this.state.auth.organization.enterprise_no;
        const enterprise_no = "KU9202352625018";//保理
        fetching(`${url.query+enterprise_no}`).then(data => {
          if (data !== undefined && data !== null) {
            let datas = data.filter(item => {
              item.receive = (Number(item.accountPayee)/100) ? (Number(item.accountPayee)/100) : (Number(item.accountPayer)/100);
              item.pay = (Number(item.accountPayer)/100) ? (Number(item.accountPayer)/100) : (Number(item.accountPayee)/100);
              if (item.status === 1) {
                item.payStatus = "成功";
              } else {
                item.payStatus = "失败";
              }
              return item;
            })
            this.setState({ data : datas });
          } else {
            alert('查询失败')
          }
  
        })
      } catch (e) {
      }
      return;
    }

    if(table === 'r6-5-1' || table === 'r6-5-2' || table === 'r18-2-1' || table === 'r18-2-2'  || table === 'r18-7-1' || table === 'r18-7-2'
    || table === 'r19-1-1' || table === 'r19-1-2' 
    || table === 'r18-4-1' || table === 'r18-4-2' || table === 'r18-4-3' || table === 'r18-4-4'
    || table === 'r18-5-1' || table === 'r18-5-2' || table === 'r18-5-3' || table === 'r18-5-4' || table === 'r18-6-1'
    || table === 'r18-6-2'){
      fetching(url && url.query).then(res => {
        if (res !== undefined && res.data) {
          let datas = [];
          datas = res.data.filter(item => {
            item.completion_date = item.financingTime;
            return item;
          });
          this.setState({ data: datas })
        }
      })
      return;
    }
    if(table === 'r18-5-4' || table === 'r18-5-5'){
      fetching(url && url.query).then(res => {
        if (res.data) {
          let data = [];
          data = res.data.filter(item => {
            //item.Status = item.status;
            //item.status = item.status ? item.status.name : '';
			item.completion_date = item.financing_time
            if (item.ceb_financing_status === '04') {
              item.status = '融资放款成功';
            } else if (item.ceb_financing_status === '06') {
              item.status = '已发起还款，还款处理中';
            } else if (item.ceb_financing_status === '07') {
              item.status = '已还款';
            }
            return item;
          })
          this.setState({ data: data })
        }
      })
      return;
    }
    if(table === 'r21-1-1' || table === 'r19-2-1' || table === 'r19-3-1'){
      fetching(url && url.query).then(data => {
        if (data !== undefined && data !== null) {
          data = data.filter(item => {
            item.completion_date = item.financing_time;
            item.Status = item.status;
            item.status = item.status ? item.status.name : '';
            return item;
          })
          this.setState({ data: data })
        }
      })
      return;
    }

    // if (table === 'r20-1-1' || table === 'r20-1-2' ) {
    //   fetching(url && url.query).then(res => {
    //     if (res.data) {
    //       this.setState({ data: res.data })
    //     }
    //   })
    //   return;
    // }

    if(table === 'r18-9-1' || table === 'r18-9-2'){
      fetching(url.query).then(data => {
        if (data !== undefined && data !== null) {
          let datas = data.map(item => {
            if (item.status) {
              item.status = item.status.name;
            }
            return item;
          })
          this.setState({ data: datas });
        }
      })
      return;
    }

    fetching(url.query + '?status_id=5').then(data => {
      if (data !== undefined && data !== null) {
        let datas = data.map(item => {
          if (item.status) {
            item.status = item.status.name;
          }
          return item;
        })
        this.setState({ data: datas });
      }
    })

  }
  
    startTimer = () => {
    this.timer = setInterval(this.onQuery84, 500); // 5秒钟查询一次
  };
  clearTimer = () => {
    clearInterval(this.timer);
  };

  onQuery84 = () => {
    //debugger
    let fetchingData = [];
    let dataType = 0; //dataType = 0 默认 1 已到期融单
    const { table } = this.props;
    const { url } = this.state.dataUrl;
    //debugger
      fetching(url.query,{
      method: "post",
      body: JSON.stringify(this.state.pageData),
    }).then(res => {
      //debugger
      if (res.data !== undefined && res.data !== null) {
      
        // this.setState({ data: datas });
          this.setState(prevState => ({
            pageData: {
              ...prevState.pageData,
              totalCount: res.pageData.totalCount,
              page: prevState.pageData.page + 1,
            },
            data: [...prevState.data, ...res.data],
            isLoading: (this.state.data.length + 5) < res.pageData.totalCount,
          }));
          if (!this.state.isLoading) {
            this.clearTimer(); // 所有数据加载完成后清除定时器
          }
          console.log(this.state.pageData.page)
          return this.state.data;
     
      
      }
    })
  }
  //开立到融资时间统计--20210106
  onQueryTimeAmount = () => {
    try {
      const { url } = this.state.dataUrl;
      fetching(url.query).then(res => {
        if (res !== undefined) {
            if (res.code === 200) {
              this.setState({ data: res.data });
            } else {
              alert(res.msg || '查询失败')
            }
          } else {
            alert('查询失败')
          }

      })
    } catch (e) {
    }
  }

  //中信融资推送
  ccbRzVerify = () => {
    const formBfe = this.state.form;
    if (formBfe.distribute !== '中信银行') {
      alert('该笔订单非中信业务不需推送中信银行');
      return
    }
    fetching(`/msapi/ccb/CCBFinancing?MxfNumber=${formBfe.mxf_number}`).then(res => {
      if (res !== undefined && res !== null) {
          alert(res.msg);
          if (res.result === 'success'){
            this.state.form.ccb_rz_type = '1';
          }else {
            this.state.form.ccb_rz_type = '0';
          }
          }
        })

  }

  //还款查询--20201222
  onQueryRepayment = () => {
    try {
      const { url } = this.state.dataUrl;

      //还款查询
      var type = 1;//type: 1-后端/全部；2-前端/指定公司
      fetching(url.query + type).then(res => {
        if (res !== undefined) {
          if (res.code === 200) {
            let datas = res.data.filter(item => {
              if(item.payment_type === "还款日不固定"){
                item.amount = item.amount + item.interest
              }
              item.operator = item.user ? item.user.name : '';
              return item;
            })
            this.setState({ data: datas });
          } else {
            alert(res.msg || '查询失败')
          }
        } else {
          alert('查询失败')
        }
      })
    } catch (e) {
    }
  }
  //合同管理查询
  onQueryContract = () => {
    try {
      const { url } = this.state.dataUrl;
      fetching(`${url.query}`).then(res => {
        if (res !== undefined) {
          if (res.code === 200) {
            this.setState({ data: res.data });
          } else {
            alert(res.msg || '查询失败')
          }
        } else {
          alert('查询失败')
        }

      })
    } catch (e) {
    }
  }

  //编辑修改银行卡信息--2021.04.07
onUpdate2 = (e, row) => {
  const { form2,  exercise } = this.state
  let status2 = '1'
  fetching('/msapi/account/findById?id=' + row.id).then(data => {  
    this.setState({ exercise: { ...exercise, ...data, save: '2', DialogTitle: form2.edit, status2, checkStatus: '3',open: true, } });
  })
  }

  //查询当前企业清算注册信息--2022.01.25
  getCompanyStatus = (e, row) => {
    fetching('/msapi/account/getPaymentRegistrationStatusByOrgId?organization_id=' + row.id).then(res => {  
      if (res !== undefined && res !== null) {
        alert(res.msg) 
      }
    })
  }

  //查看合同
  onViewContract = (e, row) => {
    if (!row.fddFilePath || row.fddFilePath === 'null') {
      alert('暂无合同')
      return;
    }
    window.open(row.fddFilePath)
  }
  //下载合同
  onUploadContract = (e, row) => {
    if (!row.fddFileName || row.fddFileName === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fddFileName}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract2 = (e, row) => {
    if (!row.fdd_yssq_name || row.fdd_yssq_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_yssq_name}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract3 = (e, row) => {
    if (!row.fdd_ysqr_name || row.fdd_ysqr_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_ysqr_name}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract4 = (e, row) => {
    if (!row.fdd_ystz_name || row.fdd_ystz_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_ystz_name}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract5 = (e, row) => {
    if (!row.fdd_ysxxb_name || row.fdd_ysxxb_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_ysxxb_name}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract6 = (e, row) => {
    if (!row.fdd_yshz_name || row.fdd_yshz_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_yshz_name}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract7 = (e, row) => {
    if (!row.zr_file_path || row.zr_file_path === 'null') {
      alert('暂无合同')
      return;
    }
    window.open(row.zr_file_path)
  }


  onRef = (ref) => {
    this.child = ref
  }

  //  table新增
  onAdd = newData => {
    let data = this.state.data;
    data.unshift(newData);
    this.setState({ data })
    return defer(0).then(console.log('newData ---: ', newData));
  }

  // 对象查找
  findObject = (obj, data) => {
    let index = data.findIndex(item => item.id === obj.id);
    return index;
  }
  //表格编辑
  onUpdate = (newData, oldData) => {
    return defer(60).then(console.log(' newData, oldData ---: ', newData, oldData));
  }
  //表格删除
  onDelete = async oldData => {
    const { dataUrl } = this.state
    const returnedData = await fetching(dataUrl.url.query + oldData.id, { method: 'DELETE' })
    returnedData && this.onQuery();
    return returnedData;
  }
  export = () => {
    const table = this.props.table
    let url = ''
    if (table === 'm6-1') {
      url = 'contract/excel/zc'
    } else if (table === 'm6-2') {
      url = 'contract/excel/issues '
    } else if (table === 'm6-3') {
      url = 'contract/excel/financings '
    } else if (table === 'm6-4') {
      url = 'contract/excel/banks '
    } else if (table === 'r9-4') {
      url = 'contract/excel/IssuesCountAndFund '
    }
    if (!url) {
      alert('接口为空')
      return
    }
    // let creditNo = auth.organization.credit_no
    window.open(`${DEFAULT_EXPORT_URL}${url}`)
    // fetching(url,).then(res => {
    //   try {
    //     if (res.code === 200) {
    //       window.open(res.data)
    //     } else {
    //       alert(`导出失败,${res.msg}`)
    //     }
    //   } catch (error) {

    //   }

    // })
  }

  exportDa = (e,row) => {//exportSupplierData
    const table = this.props.table
    let creditNo = row.open_credit_code
    let url = ''
    if (table === 'r9-2-1') {
      url = `contract/downLoadExcel/getReceiverDetail?selectedStartDate=null&selectedEndDate=null&type=0&creditNo=${creditNo}`;
    } else if (table === 'r9-2-2') {
      creditNo = row.creditNo;
      url = `contract/downLoadExcel/getIssCirDetail?selectedStartDate=null&selectedEndDate=null&type=0&creditNo=${creditNo}`;
    }
    if (!url) {
      alert('接口为空')
      return
    }
    window.open(`${DEFAULT_EXPORT_URL}${url}`)
  }

  //触发打开时间段选项弹窗--20200918
  exportTable = (e) => {
    const { exercise, form2, dataUrl } = this.state;
    //type: 1-后端/全部；2-前端/指定公司
    this.setState({
      exercise: { ...exercise, save: '3', DialogTitle: form2.exportTable, open: true, tableName: dataUrl.name, checkFlag: true, type: 1 }
    })
  }

  //善美光大银行信息录入
  exportTableSMCEB = (e) => {
    const { exercise, form2, dataUrl } = this.state;
    this.setState({
      exercise: { ...exercise,
        save: '3',
        DialogTitle: form2.addBtnName9,
        open3: true, 
        tableName: dataUrl.name,
        status2: '2',
        checkOpen: true,
        type: 1 
      }
    })
    }

    onUpdateCeb = (e) =>{
      const { exercise, form2, dataUrl } = this.state;
      this.setState({
        exercise: { ...exercise,
          save: '2',
          DialogTitle: form2.addBtnName10,
          open3: true, 
          tableName: dataUrl.name,
          status2: '2',
          checkOpen: true,
          type: 1 
        }
      })
    }  

  //提交修改的银行卡信息2021.3.19
  FormSubmit2 = (newData) => {
    newData.formDate = new Date();
    if (newData.submit_name === '提交') {
      this.onQuery();
    }
    this.setState({
      exercise: { ...this.state.exercise, open: false }
    })
  }

  //北金所弹框按钮
   onBfeMsgIn = (e,row) => {
        const { exercise, form2, dataUrl } = this.state;
         this.setState({
          exercise: { ...exercise,
          ...row,
           save: '1',
           DialogTitle: form2.bfeMsgIn,
           openbfe: true, 
           tableName: dataUrl.name,
           status2: '1',
           checkFlags: true,
           type: 1 
           }
          })
        }

     //北金所弹框时间选择
  handleTogglebfeMsg = (open) => {
      const { form2 } = this.state
      if (open) {
        this.setState({
          exercise: { openbfe: false }
        })
      window.location.reload()
      } else {
        this.setState({
          exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '5', openbfe: true,isBtn:false }
        })
      }
    }

  FormSubmit4 = (newData) => {
        newData.formDate = new Date();
        setTimeout(() => {
          if (newData.submit_name !== 'cancle') {
            this.onQuery2();
          }
          this.setState({
            exercise: { ...this.state.exercise, open: false, checkOpen: false, applyOpen:false  }
          })
        }, 500)
      }

  //触发打开时间段选项弹窗--20200918
  handleToggle = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open: false }
      })
    } else {
      this.setState({
        exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
      })
    }
  }

    //下载还款回单
    handleUpload1 = (e, row) => {
      try { 
      let url = "";
      if (!row.orderNo || row.orderNo === 'null') {
        alert('暂无回单')
        return;
      }  
      if(!row.paId || row.paId === "null"){
       let type = 40;//中金/清算支付回单类型(订死的)
       url = `/loan/getrRepaymentReceipt?txSn=${row.serialNumber}&type=${type}&orderNo=${row.orderNo}`
      } else {
      let type = 40;//中金/清算分账回单类型(订死的)
       url = `/loan/getrRepaymentReceipt?txSn=${row.serialNumber}&type=${type}&paId=${row.paId}&orderNo=${row.orderNo}&id=${row.id}`
      } 
      fetching(url,{
        method: 'GET',
      }).then(res => {
        if (res !== undefined && res.data !== null && res.data !== undefined && res.data !== "") {
          if (res.data.data.length !== undefined) {
            let datas = res.data.data.filter(item => {
              if (item.data.status === '20') {
                alert(`下载失败,`+ item.data.responseMessage)
              } else {
                window.open(item.data.downloadUrl)
              }
              return null;
            })
          } else {
            if (res.data.data.status === '20') {
              alert(`下载失败,`+ res.data.data.responseMessage)
            } else {
              window.open(res.data.data.downloadUrl)
            }
          }
          
        } else {
          alert(`下载失败,请联系管理员`)
        }
      })
        } catch (error) { }
      
    }
   //下载月度风险数据
   downloadMonthlyRiskData = (e, row) => {
    let id = row.id
      try {
        // let url =`msapi/risk/computeMonthlyRiskGovernance?id=`+id
        fetching(`/msapi/risk/computeMonthlyRiskGovernance?id=${id}`,{method: 'GET'}).then(res =>{
          //debugger
          if (res){
            alert(res.message)
            window.location.reload()
            return
          }else{
            window.location.href=DEFAULT_EXPORT_URL+`msapi/risk/computeMonthlyRiskGovernance?id=`+id
              }
        })
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };
//下载该融单到期持有excel表格
handleDownloadHoldAtMaturity = (e, row) => {
  let mx_number = row.mx_number;
  let url  = `contract/downLoadExcel/repaymentHoldAtMaturity?mx_number=`
    window.open(`${DEFAULT_EXPORT_URL}${url}${mx_number}`)  
}

//查看收款方收款权限
 receiveRegistrationStatus = (e, row) => {
  const body5 ={
  applicant: row.receive 
}
  fetching('/loan/paymentRegistrationStatus', {
    method: 'POST',
    body: JSON.stringify(body5)
  }).then(res => {
    if (res) {
      try {
        if(res.code===200){ 
          alert(res.msg)
        } else { 
          alert(res.msg)
          return;
        }
      } catch (error) {
          alert('查询接收人收款权限失败')
          }
    }
    
  }) 
}


  //查询浙商直融结果  
  applyFinancingResult = (e, row) => {
    if (row.distribute === '浙商银行') {
      fetching(`/msapi/czb/applyFinancingResult?mxfNumber=${row.mxfNumber}`).then(res => {
        if (res !== undefined) {
          if (res.code === 200) {
            alert(`查询成功,${res.msg}`)
          } else {
            alert(`查询失败,${res.msg}`)
          }
        } else {
          alert(`查询失败`)
        }
      })
    }
  }

  //查询浦发融资结果  
  applySpdFinancingResult = (e, row) => {
    if (row.distribute === '浦发银行') {
      fetching(`/msapi/spd/applyFinancingResult?mxfNumber=${row.mxfNumber}`).then(res => {
        if (res !== undefined) {
          if (res.code === 200) {
            alert(`查询成功,${res.msg}`)
          } else {
            alert(`查询失败,${res.msg}`)
          }
        } else {
          alert(`查询失败`)
        }
      })
    }
  }

  //浙商直融兑付结果 
  applyFinancingPaymentResult = (e, row) => {
    if (row.distribute === '浙商银行') {
      fetching(`/msapi/czb/applyFinancingPaymentResult?mxfNumber=${row.mxfNumber}`).then(res => {
       if (res !== undefined) {
          if (res.code === 200) {
            alert(`查询成功,${res.msg}`)
          } else {
            alert(`查询失败,${res.msg}`)
          }
        } else {
          alert(`查询失败`)
        }
      })
    }
  }

   //浙商直融兑付通知 
   czbPaymentFileUploadNotify = (e, row) => {
    if (row.distribute === '浙商银行') {
      fetching(`/msapi/czb/czbPaymentFileUploadNotify?mxfNumber=${row.mxfNumber}`).then(res => {
        if (res !== undefined) {
          if (res.code === 200) {
            alert(`通知成功,${res.msg}`)
          } else {
            alert(`通知失败,${res.msg}`)
          }
        } else {
          alert(`通知失败`)
        }
      })
    }
  }

  //北金所保理融资推送
  bfeRzVerify = (e, row) => {
    fetching(`/msapi/bfe/getFactoringUnderlyingAssetMsg?MxfNumber=${row.mxfNumber}`).then(res => {
      if (res !== undefined && res !== null) {
          alert(res.msg);
          window.location.reload();
          }
        })
  }

  //北金所保理融资结果变更
  bfeRzResult = (e, row) => {
    fetching(`/msapi/bfe/updateFinancingResultStatus?MxfNumber=${row.mxfNumber}`).then(res => {
      if (res !== undefined && res !== null) {
          alert(res.msg);
          window.location.reload();
          }
        })
  }

  //北金所保理融资推送状态查询
  bfeRzstatsus = (e, row) => {
    fetching(`/msapi/bfe/getFinancingStatus?MxfNumber=${row.mxfNumber}`).then(res => {
      if (res !== undefined && res !== null) {
          alert(res.msg);
          window.location.reload();
          }
        })
  }

  //搜索框Change
  onSearchChange = (query) => {
  }
  //页面更改事件
  onChangePage = (query) => {
  }
  //处理每页的行更改（pageSize）
  onChangeRowsPerPage = (query) => {
  }
       //处理每页的行更改（pageSize）
  onChangeRowsPerPage2 = (query) => {
      this.setState(prevState => ({
        pageData: {
          ...prevState.pageData,
          pageSize: query,
        },
      }));
    }
  //按时间段进行数据查询
  QueryByPeriod = (e) => {
    let dataType = 0; //dataType = 0 默认 1 已到期融单
    const { table } = this.props;
    const { url } = this.state.dataUrl;
    let dateType = e; // e = 1 月度 2 季度 3半年 4 一年 5 所有
    if (table === 'm6-2') {
      if (e === 1) {
        this.state.dataUrl.title = "月度开立合同";
      } else if (e === 2) {
        this.state.dataUrl.title = "季度开立合同";
      } else if (e === 3) {
        this.state.dataUrl.title = "近半年开立合同";
      } else if (e === 4) {
        this.state.dataUrl.title = "近一年开立合同";
      } else if (e === 5) {
        this.state.dataUrl.title = "全部开立合同"; 
      } 
      fetching(url.query+`?dateType=${dateType}`).then(res => {
       if (res !== undefined) {
          if(res.code === 200) {
            this.setState({data:res.data})
          }else{
            alert(res.msg || '查询失败')
          }
        } else{
          alert('查询失败')
        }
      })
    }

    if (table === 'm6-3') {
      if (e === 1) {
        this.state.dataUrl.title = "月度融资合同管理";
      } else if (e === 2) {
        this.state.dataUrl.title = "季度融资合同管理";
      } else if (e === 3) {
        this.state.dataUrl.title = "近半年融资合同管理";
      } else if (e === 4) {
        this.state.dataUrl.title = "近一年融资合同管理";
      } else if (e === 5) {
        this.state.dataUrl.title = "全部融资合同管理"; 
      } 
      fetching(url.query+`?dateType=${dateType}`).then(res => {
       if (res !== undefined) {
          if(res.code === 200) {
            this.setState({data:res.data})
          }else{
            alert(res.msg || '查询失败')
          }
        } else{
          alert('查询失败')
        }
      })
    }
    if ( table === 'm6-4') {
      if (e === 1) {
        this.state.dataUrl.title = "月度银行账号信息";
      } else if (e === 2) {
        this.state.dataUrl.title = "季度银行账号信息";
      } else if (e === 3) {
        this.state.dataUrl.title = "近半年银行账号信息";
      } else if (e === 4) {
        this.state.dataUrl.title = "近一年银行账号信息";
      } else if (e === 5) {
        this.state.dataUrl.title = "全部银行账号信息"; 
      } 
      fetching(url.query+`?dateType=${dateType}`).then(res => {
       if (res !== undefined) {
          if(res.code === 200) {
            this.setState({data:res.data})
          }else{
            alert(res.msg || '查询失败')
          }
        } else{
          alert('查询失败')
        }
      })
    }
    if ( table === 'm6-1') {
      if (e === 1) {
        this.state.dataUrl.title = "月度注册合同管理";
      } else if (e === 2) {
        this.state.dataUrl.title = "季度注册合同管理";
      } else if (e === 3) {
        this.state.dataUrl.title = "近半年注册合同管理";
      } else if (e === 4) {
        this.state.dataUrl.title = "近一年注册合同管理";
      } else if (e === 5) {
        this.state.dataUrl.title = "全部注册合同管理"; 
      } 
      fetching(url.query+`?dateType=${dateType}`).then(res => {
       if (res !== undefined) {
          if(res.code === 200) {
            this.setState({data:res.data})
          }else{
            alert(res.msg || '查询失败')
          }
        } else{
          alert('查询失败')
        }
      })
    }
    if ( table === 'r20-1-1' || table === 'r20-1-2' || table === 'r20-2-1' || table === 'r20-2-2' || table === 'r20-2-3' || table === 'r20-2-4'
      || table === 'r20-2-5' || table === 'r20-2-6' || table === 'r20-2-7' || table === 'r20-2-8') {
      let businessName = "";
      let type = 0 ; //1 登录 2 开立  3 流转 4 融资 5 授信 6 额度分配 7 额度冻结  8 用户信息变更 9 其他 后续自定义
      if (table === 'r20-1-1' ) {
        businessName = "用户登录";
        type = 1;
      } else if ( table === 'r20-1-2') {
        businessName = "用户信息变更";
        type = 8;
      } else if ( table === 'r20-2-1') {
        businessName = "用户开立";
        type = 2;
      } else if ( table === 'r20-2-2') {
        businessName = "用户流转";
        type = 3;
      } else if ( table === 'r20-2-3') {
        businessName = "用户融资";
        type = 4;
      } else if ( table === 'r20-2-4') {
        businessName = "用户额度申请";
        type = 5;
      } else if ( table === 'r20-2-5') {
        businessName = "用户企业关联";
        type = 11;
      } else if ( table === 'r20-2-6') {
        businessName = "用户额度分配";
        type = 6;
      } else if ( table === 'r20-2-7') {
        businessName = "用户额度回收";
        type = 10;
      } else if ( table === 'r20-2-8') {
         businessName = "用户应收账款";
         type = 12;
       }
      if (e === 1) {
        this.state.dataUrl.title = "月度"+businessName+"日志";
      } else if (e === 2) {
        this.state.dataUrl.title = "季度"+businessName+"日志";
      } else if (e === 3) {
        this.state.dataUrl.title = "近半年"+businessName+"日志";
      } else if (e === 4) {
        this.state.dataUrl.title = "近一年"+businessName+"日志";
      } else if (e === 5) {
        this.state.dataUrl.title = "全部"+businessName+"日志"; 
      } 
      fetching(url.query+`?dateType=${dateType}&type=${type}`).then(res => {
       if (res !== undefined) {
          if(res.code === 200) {
            this.setState({data:res.data})
          }else{
            alert(res.msg || '查询失败')
          }
        } else{
          alert('查询失败')
        }
      })
    }
    if (table === 'r8-3' || table === 'r8-6') {
      let businessName = "";
      if (table === 'r8-3' ) {
        businessName = "额度已恢复";
      } else if ( table === 'r8-6') {
        businessName = "额度不可恢复";
      }
      if (e === 1) {
        this.state.dataUrl.title = "月度"+businessName;
      } else if (e === 2) {
        this.state.dataUrl.title = "季度"+businessName;
      } else if (e === 3) {
        this.state.dataUrl.title = "近半年"+businessName;
      } else if (e === 4) {
        this.state.dataUrl.title = "近一年"+businessName;
      } else if (e === 5) {
        this.state.dataUrl.title = "全部"+businessName; 
      } 

      try {
        //还款查询
        var type = 1;//type: 1-后端/全部；2-前端/指定公司
        fetching(url.query + type+`&dateType=${e}`).then(res => {
          if (res !== undefined) {
            if (res.code === 200) {
              let datas = res.data.filter(item => {
                if(item.payment_type === "还款日不固定"){
                  item.amount = item.amount + item.interest
                }
                item.operator = item.user ? item.user.name : '';
                return item;
              })
              this.setState({ data: datas });
            } else {
              alert(res.msg || '查询失败')
            }
          } else {
            alert('查询失败')
          }
        })
      } catch (e) {
      }

    }
  }
  //还款审批--20201222
  onRepayment2 = (e, rowData) => {
    this.setState({
      checkExercise: {
        ...rowData,
        DialogTitle: '',
        checkStatus: '3',
        save: '1',
      },
      exercise: { ...rowData, save: '1', status2: '2', DialogTitle: '', open: true },

    });
  }


  //核心企业3表单提交--20201222
  FormSubmit = (newData, data) => {
    // let datas = data || this.state.data || [];
    const { dataUrl, exercise, auth } = this.state;
    if (newData.submit_name === '提交') {
      if (newData.formDates) {
        // datas = this.deleteSave(newData.formDates);
      } else {
        setTimeout(() => {
          this.onQueryRepayment()//还款查询--20201222
        }, 1000)
      }
      this.setState({
        exercise: { ...this.state.exercise, open: false, open2: false }
      })
    } else {
      setTimeout(() => {
        //this.fetchings(dataUrl, exercise.table, auth)
        this.onQueryRepayment()//还款查询--20201222
      }, 1000)
      this.setState({
        exercise: { ...this.state.exercise, open: false, open2: false }
      })
    }

  }

  //公共表单
  handleCommon = (open) => {
    const { form2, exercise } = this.state;
    if (open) {
      this.setState({
        exercise: { ...exercise, checkOpen: false },
      })
      window.location.reload()
    } else {
      this.setState({
        exercise: { ...exercise, status2: '2', DialogTitle: form2.check, save: '3', checkOpen: true },
      })
    }
  }

  //开具复核-审核
  onCheck = (e, row) => {
    const { form2, dataUrl, exercise, auth } = this.state;
    const { roles = [{ id: 0 }] } = auth;
    let roleId = roles[0].id;
    let query = dataUrl.url.get || dataUrl.url.query;
    let DialogTitle = form2.check;
    if (roleId === 5 || roleId === 6) {
      DialogTitle = '批量复核';
    } 
    
    fetching(query+1).then(data => {
      this.setState({
        exercise: {
          ...exercise, ...data, save: '1', DialogTitle,
          rolseId: '2',
          status2: '2', checkOpen: true, checkStatus: '3',

        },
      });
    })

  }


    //开具复核-审核
    onCheckCeb = (e, row) => {
      const { form2, dataUrl, exercise, auth } = this.state
        this.setState({
          exercise: {
            ...exercise,
            ...row,
            save: '3',
            DialogTitle: form2.check,
            status2: '2',
            rolseId: '1',
            checkOpen: true,
            checkStatus: '3',
          },
        });
    }

 //查询光大融资状态 
  onfindCebStatus = (e, row) => {
    const mxf_number = row.mxf_number
    fetching(`/msapi/ceb/findCebFinancing?mxf_number=` + mxf_number, {
      method: 'get',
    }).then(res => {
      if (res) {
        alert(res.msg)
      }
    })
  }

  //查询光大融资状态 
  onfindCebRepaymentStatus = (e, row) => {
    const mxf_number = row.mxf_number
    fetching(`/msapi/ceb/findCebRepayment?mxf_number=${mxf_number}&tradeType=02`, {
      method: 'get',
    }).then(res => {
      if (res) {
        alert(res.msg)
      }
    })
  }

  //发起光大融资还款 
  onSavaBuyerRepayment = (e, row) => {
    const mxf_number = row.mxf_number
    fetching(`/msapi/ceb/savaBuyerRepayment?mxf_number=${mxf_number}`, {
      method: 'get',
    }).then(res => {
      if (res) {
        alert(res.msg)
      }
    })
  }

//查询北京银行融资状态 
  onfindBobRzStatus = (e, row) => {
    const mxf_number = row.mxf_number
    fetching(`/msapi/bob/getBobCustomerRecordOrFinancingResult?mxf_number=${mxf_number}&businessType=2`, {
      method: 'get',
    }).then(res => {
      if (res) {
        alert(res.msg)
      }
    })
  }

  onCebSkip = (e,row) =>{
    const mxf_number = row.mxf_number
    const UpGwMsg = "";
    fetching(`/msapi/ceb/gatewayCebAgainString?mxf_number=` + mxf_number,{
      method:'get' , 
    }).then(res =>{
      if(res.code === 200){
          const param ={
            "UpGwMsg":res.data
          }
          Post("https://ebank.cebbank.com:17443/cebent/preRELLogin.do?_locale=zh_CN", param);
          function  Post(URL, PARAMS) {
            var temp = document.createElement("form");
            temp.action = URL;
            temp.method = "post";
                  //如需新打开窗口 form 的target属性要设置为'_blank'
            temp.target = "_blank";
            temp.style.display = "none";
            for (var x in PARAMS) {
              var opt = document.createElement("textarea");
              opt.name = x;
              opt.value = PARAMS[x];
              temp.appendChild(opt);
            }
            document.body.appendChild(temp);
            temp.submit();
            return temp;
          }
      
      } else {
        alert(res.msg)
      }
    })
}

  //下载合同(光大再保)
  onUploadContractCEB = (e, row) => {
  if (!row.fdd_cebfile_name || row.fdd_cebfile_name === 'null') {
    alert('暂无合同')
    return;
  }
  fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_cebfile_name}`).then(res => {
    if (res !== undefined) {
      if (res.code === 200) {
        window.open(res.data)
      } else {
        alert(`下载失败,${res.msg}`)
      }
    } else {
      alert(`下载失败`)
    }
  })
}

   //公共表单提交
  FormSubmit3 = () => {
    const { dataUrl, exercise, auth } = this.state;
    this.setState({
      exercise: { ...this.state.exercise, checkOpen: false }
    })

    setTimeout(() => {
      this.onQuery(dataUrl, exercise.table, auth)
    }, 1000)
   
  }

  //光大融资审核
  onCheck2 = (e, row) => {
    const { form2, dataUrl, exercise, auth } = this.state;
    let query = dataUrl.url.get || dataUrl.url.query;
    let mxc_number = ``;
    let DialogTitle = form2.check;
    if (row.flow_date) mxc_number = `cid=${row.id}`
    else if (row.mxf_number) mxc_number = `mxf_number=${row.mxf_number}`;
    let department_cond = ``;
    if (parseInt(auth.department.id) === 7) department_cond = `department_id=7`;
    fetching(query + `?mx_number=${row.mx_number}&${department_cond}&${mxc_number}`).then(data => {
      this.setState({
        exercise: {
          ...exercise, ...row, save: '3', DialogTitle,
          rolseId: '2',
          status2: '2', checkOpen: true, checkStatus: '3',
          opinion_list: data || []
        },
      });
    })
  }

   //查看
   onview = (e, row) => {
    const { form2, dataUrl } = this.state
    const table = this.props.table || '';
    if (row.id && row.guaranty_way) { 
      this.getGuarantInfo(row.id, row.guaranty_way);
    }
    setTimeout(() => {
        fetching(dataUrl.url.get2 + `${row.id}`).then(data => {
          this.setState({
            checkExercise: {
              ...row,
              Status: row.status,
              DialogTitle: form2.check,
              status2: '2',
              rolseId: '1',
              checkStatus: '3',
              save: '3',
              opinion_list: data || []
            },
            exercise: { ...data, save: '3', status2: '', DialogTitle: form2.view, open: true, }
          });
        })
    }, 500)

  }
  render() {
    const { columns, data, dataUrl, exercise, auth, checkExercise = {} ,opinion_list ,params,pageData} = this.state;//还款审批恢复额度--20201222
    const title = dataUrl.title || '';
    const path = capitalize(this.props.table);
    // const actions = actions2(this, this.props.table);
    const editable = editable2(this, this.props.table);
    const table = this.props.table
    let roles = auth ? auth.roles : [{ id: 0 }];
    let rolesId = auth.roles ? auth.roles[0].id : 0;

    //还款申请--20201222
    const C41 = () => {
      let isBtn = false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, isBtn }}>
          <C41Form auth={auth} form2={{ ...common, ...f4_1 }} dataUrl={dataUrl} exercise={{ ...exercise, table }} checkExercise={{ ...checkExercise, table }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    const C21 = () => {
      return <CommonDialog handleToggle={this.handleCommon}
        exercise={{ ...exercise, addBtnName: '公共表单', isBtn: false, }}>
        <CheckBoxForm auth={auth} opinion_list={[]} exercise={{ ...exercise, table }}
          onSubmit={this.FormSubmit3} />
      </CommonDialog>
    }
    // 合同管理
    const C6 = () => {
      return <div style={{ textAlign: 'end', marginBottom: '10px' }}>
        <Button variant="contained" color="primary" onClick={this.exportTable}>导出</Button>
      </div>
    }

    // 导出开票信息
    const C64 = () => {
      return <div style={{ textAlign: 'end', marginBottom: '10px' }}>
        <Button variant="contained" color="primary" onClick={this.export}>导出</Button>
      </div>
    }
     //分时间段查询--开立合同管理+银行账户信息
     const T1 = () => {
      return <div style={{ textAlign: 'start', marginBottom: '10px' }}>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button style={{ marginRight: '10px',backgroundColor: 'rgb(29, 69, 131)'}} variant="contained" color="primary" onClick={() => this.QueryByPeriod(1)}>月度</Button>
        <Button style={{ marginRight: '10px',backgroundColor: 'rgb(29, 69, 131)'}} variant="contained" color="primary" onClick={() => this.QueryByPeriod(2)}>季度</Button>
        <Button style={{ marginRight: '10px',backgroundColor: 'rgb(29, 69, 131)'}} variant="contained" color="primary" onClick={() => this.QueryByPeriod(3)}>近半年</Button>
        <Button style={{ marginRight: '10px',backgroundColor: 'rgb(29, 69, 131)'}} variant="contained" color="primary" onClick={() => this.QueryByPeriod(4)}>近一年</Button>
        <Button style={{ backgroundColor: 'rgb(29, 69, 131)'}} variant="contained" color="primary" onClick={() => this.QueryByPeriod(5)}>所有</Button>
        </ButtonGroup>
      </div>
    }

    const C1 = () => {
      return <div style={{ textAlign: 'end', marginBottom: '10px' }}>
         <Button variant="contained" color="primary" onClick={this.exportTableSMCEB}>善美光大银行信息录入</Button>
       </div>
    }

    
    //时间段选择弹窗--20200917
    const DownloadTime = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <DialogForm handleToggle={this.handleToggle} exercise={{ ...exercise, addBtnName: common.exportTable, isBtn }}>
          <DownloadTimeForm form2={{ ...common }} exercise={{ ...exercise }} onSubmit={this.FormSubmit} />
        </DialogForm>
      </div>
    }

    //修改银行开信息弹出框--20210319 
    const R41 = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
      <Dialog handleToggle={this.handleToggle} exercise={{ ...exercise, isBtn }}>
          <C61Form  auth={auth} form2={{ ...common, ...f4_1 }} dataUrl={dataUrl} exercise={{ ...exercise, table }} checkExercise={{ ...checkExercise, table }}
           onSubmit={this.FormSubmit2} />
      </Dialog>
      </div>
      }

      const T4 = () => {
        return <div style={{ textAlign: 'start', marginBottom: '10px' }}>
        <ButtonGroup size="small" aria-label="small outlined button group">
          <Button style={{ marginRight: '10px',backgroundColor: 'rgb(29, 69, 131)'}} variant="contained" color="primary" onClick={() => this.onCheck()}>批量操作</Button>
          </ButtonGroup>
        </div>
      }

        //北金所
    const BfeMsgInput =() => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}> 
        <DialogbfeMsg handleToggle={this.handleTogglebfeMsg}
          exercise={{ ...exercise, addBtnName: common.addBtnName3, isBtn }}>
          <BfeMsgInputForm form2={{ ...common }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit4} />
        </DialogbfeMsg>
      </div>
      }
 
  //审核
  const Check = () => {
    return <CommonDialog handleToggle={this.handleCommon}
        exercise={{ ...exercise, addBtnName: '公共表单', isBtn: false, }}>
        <Form1 auth={auth} opinion_list={opinion_list} exercise={{ ...exercise, table }}
          onSubmit={this.FormSubmit3} />
      </CommonDialog>
  }

    //融单融资
    const C321 = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName3, isBtn }}>
          <C321Form auth={auth} form2={{ ...common, ...f3_2_1 }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    //光大再保融资发起
    const CebCheck = () => {
      return <div style={{ justifyContent: 'flex-end' }}>
        <CommonDialog handleToggle={this.handleCommon}
          exercise={{ ...exercise, addBtnName: '公共表单', isBtn: false, }}>
          <CebCheckForm auth={auth} opinion_list={[opinion_list]} exercise={{ ...exercise, table }}
            onSubmit={this.FormSubmit3} />
        </CommonDialog>
      </div>
    }

     //善美光大银行信息录入
     const C12 = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <CommonDialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName9, isBtn }}>
          <C12CEBForm auth={auth} form2={{ ...common, ...f6_7 }} exercise={{ ...exercise, ...params }}
            onSubmit={this.FormSubmit} />
        </CommonDialog>
      </div>
    }
//月度风险监控文件上传
    const R96 = () => {
      let isBtn = table === 'r9-6' ? true : false;
      //debugger
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.monthly_file_uploads, isBtn }}>
          <R96Form auth={auth} form2={{ ...common }} exercise={{ ...exercise, ...params }} />
        </Dialog>
      </div>
    }

    return <div>
      {(table === 'r8-2' ) && <T4></T4>}
      {(table === 'r8-2' ) && <C21></C21>}
      {(table === 'r18-5-1') && <CebCheck></CebCheck>}
      {(table === 'r8-2' || table === 'r8-8') && <C41></C41>}
      {(table === 'm6-4') && <R41></R41>}
      {(table === 'm6-4') && <C64></C64>}
      {(table ==='r18-4-4' && rolesId === 16 ) && <BfeMsgInput></BfeMsgInput>}
      {((table ==='r18-4-1'|| table ==='r18-4-2') && rolesId === 16 ) && <BfeMsgInput></BfeMsgInput>}
      {(table === 'm6-7' ) && <C1></C1>}
      {(table === 'm6-7' ) && <C12></C12>}
      {(table === 'm6-2' || table === 'm6-4' || table === 'm6-1' || table === 'm6-3' || table === 'r20-1-1' || table === 'r20-1-2' || table === 'r20-2-1' 
      || table === 'r20-2-2' || table === 'r20-2-3' || table === 'r20-3-1'|| table === 'r20-2-4' || table === 'r20-2-5' || table === 'r20-2-6' 
      || table === 'r20-2-8' || table === 'r20-2-7' || table === 'r8-6' || table === 'r8-3') && <T1></T1>}
      {(table === 'm6-1' || table === 'm6-2' || table === 'm6-3' || table === 'r9-4' || table === 'r9-5' || table === 'r8-5' || table === 'r8-3' || table === 'r8-4') && <C6></C6>}
      {(table === 'm6-1' || table === 'm6-2' || table === 'm6-3' || table === 'r9-4' || table === 'r9-5' || table === 'r8-5' || table === 'r8-3' || table === 'r8-4') && <DownloadTime></DownloadTime>}
      {(table === 'r21-1-1'  || table === 'r19-2-1' || table === 'r19-3-1' ) && <Check></Check>}
      {(table === 'r21-1-1'  || table === 'r19-2-1' || table === 'r19-3-1') && <C321></C321>}
      {(table === 'r9-6') && <R96></R96>}
	  
      <MaterialTable
        title={title}
        onSearchChange={this.onSearchChange}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        path={path}
        columns={columns}
        data={data}
        actions={actions2(this, table, auth)}
        editable={editable}
        localization={localization}
        options={options}
      />
    </div>
  }
}
const actions2 = (that, name,auth) => {
  const { roles = [{ id: 0 }] } = auth;
  let roleId = roles[0].id;

  let actions = [];
  switch (name) {
    case 'm6-1':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onViewContract },
        { icon: CloudUpload, tooltip: '下载', onClick: that.onUploadContract }
      ];
      break;
    case 'm6-2':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onViewContract },
        { icon: CloudUpload, tooltip: '下载', onClick: that.onUploadContract }
      ];
      break;
    case 'm6-3':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onViewContract },
        { icon: CloudUpload, tooltip: '下载保理协议', onClick: that.onUploadContract },
        { icon: CloudUpload, tooltip: '下载通知书', onClick: that.onUploadContract7 }
      ];
      break;
    case 'm6-6':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onViewContract },
      { icon: CloudUpload, tooltip: '下载保理合同', onClick: that.onUploadContract },
      { icon: CloudUpload, tooltip: '下载申请书', onClick: that.onUploadContract2 },
      { icon: CloudUpload, tooltip: '下载确认书', onClick: that.onUploadContract3 },
      { icon: CloudUpload, tooltip: '下载通知书', onClick: that.onUploadContract4 },
      { icon: CloudUpload, tooltip: '下载信息表', onClick: that.onUploadContract5 },
      { icon: CloudUpload, tooltip: '下载回执', onClick: that.onUploadContract6 }
      ];
      break;
    case 'r8-2'://额度恢复可用财务经办人/财务负责人(rdcw1/rdcw2)--20201222
      if(roleId === 5 || roleId === 6) {
        actions = [
         { icon: MonetizationOn, tooltip: '还款审批', onClick: that.onRepayment2 },
        ];

      }
      break;
    case 'r8-4'://还款提醒
        actions = [
          { icon: Search, tooltip: '持有人收款权限查询', onClick: that.receiveRegistrationStatus },
        ];
      break;
    case 'r8-7'://下载还款回单
      actions = [
        { icon: CloudUpload, tooltip: '下载', onClick: that.handleUpload1 }
      ];
      break;
    case 'r8-8'://还款复核
    if(roleId === 17) {
      actions = [
        { icon: MonetizationOn, tooltip: '还款审批', onClick: that.onRepayment2 },
        { icon: CloudUpload, tooltip: '融单到期持有明细', onClick: that.handleDownloadHoldAtMaturity }
      ];
    }
      break;
    case 'm6-4': //修改银行卡信息
      actions = [
        { icon: Edit, tooltip: '编辑', onClick: that.onUpdate2 } 
        
      ];
      break;
    case 'm6-5': //查询客户清算平台注册状态
      actions = [
        { icon: Search, tooltip: '查询', onClick: that.getCompanyStatus } 
        
      ];
      break;
      case 'm6-7': //善美光大银行信息修改
      actions = [
        { icon: Edit, tooltip: '编辑', onClick: that.onUpdateCeb } 
      ];
      break; 
     case 'r9-6': //月度风险监督
      actions = [
        { icon: VerticalAlignBottomIcon, tooltip: '计算并导出计算结果', onClick: that.downloadMonthlyRiskData }

      ];
      break;
      case 'r9-2-1': //核心企业明细
      actions = [
        { icon: CloudUpload, tooltip: '导出供应商交易明细', onClick: that.exportDa }

      ];
      break;
    case 'r9-2-2': //供应商交易统计
      actions = [
        { icon: CloudUpload, tooltip: '导出供应商融单接收明细', onClick: that.exportDa }

      ];
      break;
    case 'r18-2-1': //查询浙商直融结果
      actions = [
        { icon: Search, tooltip: '获取浙商直融状态', onClick: that.applyFinancingResult } 
        
      ];
      break;
    case 'r18-2-2': //查询浙商兑付状态
      actions = [
        { icon: ViewComfy, tooltip: '浙商直融兑付通知', onClick: that.czbPaymentFileUploadNotify } ,
        { icon: Search, tooltip: '浙商直融兑付结果查询', onClick: that.applyFinancingPaymentResult } ,
      ];
      break;
    case 'r18-4-1': //北金所非保理融资查看
    if(roleId === 1) {
      actions = [
        // { icon: Input, tooltip: '北金所信息录入', onClick: that.onBfeMsgIn } ,
        { icon: Search, tooltip: '北金所融资推送状态查询', onClick: that.bfeRzstatsus } ,
        { icon: InsertChart, tooltip: '北金所融资状态变更', onClick: that.bfeRzResult } ,
      ];}
      break;
    case 'r18-4-2': //北金所保理融资查看
    if(roleId === 1) {
      actions = [
        // { icon: Input, tooltip: '北金所信息录入', onClick: that.onBfeMsgIn } ,
        { icon: Search, tooltip: '北金所融资推送状态查询', onClick: that.bfeRzstatsus } ,
        { icon: InsertChart, tooltip: '北金所融资状态变更', onClick: that.bfeRzResult } ,
      ];}
      break;
    case 'r18-4-4': //北金所融资发起
    if(roleId === 16) {
      actions = [
        { icon: ViewComfy, tooltip: '北金所融资发起', onClick: that.bfeRzVerify } ,
        { icon: Input, tooltip: '北金所信息录入', onClick: that.onBfeMsgIn } ,
      ];
    }
    break;
    case 'r18-5-1':
    if(roleId === 16 || roleId === 6 || roleId === 5){
        actions = [
          { icon: Edit, tooltip: '光大再保融资发起', onClick: that.onCheckCeb }
        ];
    }  
    break;
case 'r18-5-2':
      if (roleId === 16) {
        actions = [
          { icon: Search, tooltip: '光大融资结果查询', onClick: that.onfindCebStatus },
          // { icon: ViewWeekOutlined, tooltip: '跳转光大进行数字签名', onClick: that.onCebSkip}
        ];
      }
      break;
    case 'r18-5-3':
      if (roleId === 16) {
        actions = [
          { icon: Search, tooltip: '光大融资结果查询', onClick: that.onfindCebStatus },
          // { icon: ViewWeekOutlined, tooltip: '跳转光大进行数字签名', onClick: that.onCebSkip}
        ];
      }
      break;
    case 'r18-5-4':
      if (roleId === 16) {//savaBuyerRepayment
        actions = [
          { icon: Input, tooltip: '光大还款发起', onClick: that.onSavaBuyerRepayment },
          { icon: Search, tooltip: '光大还款结果查询', onClick: that.onfindCebRepaymentStatus },
          // { icon: ViewWeekOutlined, tooltip: '跳转光大进行数字签名', onClick: that.onCebSkip}

        ];
      }
      break;
    case 'r18-5-5':
      if (roleId === 16) {//savaBuyerRepayment
        actions = [
          { icon: Input, tooltip: '光大还款发起', onClick: that.onSavaBuyerRepayment },
          { icon: Search, tooltip: '光大还款结果查询', onClick: that.onfindCebRepaymentStatus },
          // { icon: ViewWeekOutlined, tooltip: '跳转光大进行数字签名', onClick: that.onCebSkip}

        ];
      } else {
        actions = [
          { icon: CloudUpload, tooltip: '下载光大再保协议', onClick: that.onUploadContractCEB },
        ];
      }
      break;
   case 'r18-6-1':
        actions = [
          { icon: Search, tooltip: '北京银行融资结果查询', onClick: that.onfindBobRzStatus },
        ];
      break;
    case 'r18-6-2':
        actions = [
          { icon: Search, tooltip: '北京银行融资结果查询', onClick: that.onfindBobRzStatus },
        ];
      break;
    case 'r18-7-1': //查询浦发融资结果
    actions = [
      { icon: Search, tooltip: '获取浦发融资状态', onClick: that.applySpdFinancingResult } 
      
    ];
      break;
    case 'r18-7-2': //查询浦发兑付状态
      actions = [
        { icon: Search, tooltip: '浦发融资兑付结果查询', onClick: that.applySpdFinancingResult } ,
      ];
      break;
      case 'r21-1-1':
        if(roleId === 21){
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck2 },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        ]
      } else {
        actions = [
          //{ icon: 'save', tooltip: '审核', onClick: that.onCheck2 },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        ]
      }
        break;
    case 'r19-1-1': //北金所融资发起
      actions = [
        { icon: ViewComfy, tooltip: '中信融资发起', onClick: that.ccbRzVerify } ,
        { icon: Search, tooltip: '中信融资状态查询', onClick: that.bfeRzstatsus } ,
        //{ icon: Input, tooltip: '北金所信息录入', onClick: that.onBfeMsgIn } ,
      ];
      break;
    case 'r19-2-1':
      if(roleId === 21){
      actions = [
        { icon: 'save', tooltip: '审核', onClick: that.onCheck2 },
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
      ]
    } else {
      actions = [
        //{ icon: 'save', tooltip: '审核', onClick: that.onCheck2 },
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
      ]
    }
      break;
   case 'r19-3-1':
      if (roleId === 22) {
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck2 },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        ]
      } else {
        actions = [
          //{ icon: 'save', tooltip: '审核', onClick: that.onCheck2 },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        ]
      }
      break;
    default:
      actions = [];
  }
  return actions;
}
const editable2 = (that, name) => {
  let editable = {};
  switch (name) {
    case 'm1':
      editable = { onRowAdd: that.onAdd, };
      break;
    default:
    // editable = { onRowDelete: that.onDelete };
  }
  return editable;
}
const localization = {
  header: {
    actions: '操作' 
  }
}


const options = {
  actionsCellStyle: { padding: '0 10px' },
  headerStyle: { //表格头部
    backgroundColor: '#1d4583', //'#039be5',
    color: '#FFF',
    whiteSpace: 'nowrap'
  },
  rowStyle: { //表行
    backgroundColor: '#fff',
    whiteSpace: 'nowrap'
  },
  sorting: true,  //排序
  exportButton: true,
  //selection: true //多选框
}

   

const setView = Comp => (collection, name) => {
  return class extends Component {
    render() {
      return <Comp table={collection} tableName={name} {...this.props} />
    }
  }
}

export const SetView = setView(View);
