import { delcommafy } from "../../components/numToText";
const { string } = require('yup');
// const decimals = /^([1-9]|[1-9]\d|100)(\.\d{1,2})?$///数字1-100 保留两位小数
// const numbers = /^\d{1,12}$/ //数字
// const numbers = /^\+?[1-9]\d*$/;
const number1 = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;//数字保留两位小数
const numbers = /^\+?[1-9]\d*$/;



//利率维护
export const C151Formik = (values, formStatic, rates) => {
  let errors = {};
  let rates1 = []
  let flag = false;
  rates.forEach((item, index) => {
    let obj = {}
    let sr1 = item.synthesis_rate.replace(/'/g,'');
    if (index === 0) {
      if (item.min_amount) {
        if (!(delcommafy(item.min_amount) < delcommafy(item.max_amount))) {
          //obj.max_amount = '最大值需大于最小值';
          alert('最大值需大于最小值')//20210425
          flag = true
          window.location.reload()//20210425
        }
      }
      if (!item.max_amount) {
        obj.max_amount = formStatic.max_amount;
        flag = true
      } else if (!numbers.test(delcommafy(item.max_amount))) {
        //obj.max_amount = '请输入整数';
        alert('请输入整数')//20210425
        flag = true
      }
    } else if (index === (rates.length - 1)) {
      if (!item.min_amount) {
        obj.min_amount = formStatic.min_amount;
        flag = true
      } else if (!numbers.test(delcommafy(item.min_amount))) {
        //obj.min_amount = '请输入整数';
        alert('请输入整数')//20210425
        flag = true
      }
      if (item.max_amount) {
        if (!(delcommafy(item.min_amount) < delcommafy(item.max_amount))) {
          //obj.max_amount = '最大值需大于最小值';
          alert('最大值需大于最小值')//20210425
          window.location.reload()//20210425
          flag = true
        }

      }
      if (Number(sr1) >= Number(100)) {
        //obj.synthesis_rate = '请输入数值小于100';
        alert('利率输入数值应小于100')//20210425
        flag = true
      }
    } else {
      if (!item.min_amount) {
        obj.min_amount = formStatic.min_amount;
        flag = true
      } else if (!numbers.test(delcommafy(item.min_amount))) {
        //obj.min_amount = '请输入整数';
        alert('请输入整数')//20210425
        flag = true
      }
      if (!item.max_amount) {
        obj.max_amount = formStatic.max_amount;
        flag = true
      } else if (!numbers.test(delcommafy(item.max_amount))) {
        //obj.max_amount = '请输入整数';
        alert('请输入整数')//20210425
        flag = true
      } else if (!(delcommafy(item.min_amount) < delcommafy(item.max_amount))) {
        //obj.max_amount = '最大值需大于最小值';
        alert('最大值需大于最小值')//20210425
        flag = true
        window.location.reload()//20210425
      }
    }
    if (!item.synthesis_rate) {
      obj.synthesis_rate = formStatic.synthesis_rate;
      flag = true
    } else if (!number1.test(delcommafy(item.synthesis_rate))) {
      //obj.synthesis_rate = '请输入数值最多保留两位小数';
      alert('利率请输入数值最多保留两位小数且不能为负数')//20210425
      flag = true
      window.location.reload()//20210425
    } else if (Number(sr1) >= Number(100)) {
      //obj.synthesis_rate = '请输入数值小于100';
      alert('利率输入数值应小于100')//20210425
      flag = true
      window.location.reload()//20210425
    }
    rates1.push(obj)
    
  });
  
  if (flag) {
    errors.rates = rates1
  }
  return errors;
}

//融资期限维护
export const C152Formik = (values, formStatic, rates) => {
  let errors = {};
  let rates1 = []
  let flag = false;
  rates.forEach((item, index) => {
    let obj = {}
    let sr1 = item.synthesis_rate.replace(/'/g,'');
    if (index === 0) {
      if (item.min_day) {
        if (!(delcommafy(item.min_day) < delcommafy(item.max_day))) {
          //obj.max_amount = '最大值需大于最小值';
          alert('最大日期需大于最小日期')//20210425
          flag = true
          window.location.reload()//20210425
        }
      }
      if (!item.max_day) {
        obj.max_day = formStatic.max_day;
        flag = true
      } else if (!numbers.test(delcommafy(item.max_day))) {
        //obj.max_amount = '请输入整数';
        alert('请输入整数')//20210425
        flag = true
      }
    } else if (index === (rates.length - 1)) {
      if (!item.min_day) {
        obj.min_day = formStatic.min_day;
        flag = true
      } else if (!numbers.test(delcommafy(item.min_day))) {
        //obj.min_amount = '请输入整数';
        alert('请输入整数')//20210425
        flag = true
      }
      if (item.max_day) {
        if (!(delcommafy(item.min_day) < delcommafy(item.max_day))) {
          //obj.max_amount = '最大值需大于最小值';
          alert('最大值需大于最小值')//20210425
          window.location.reload()//20210425
          flag = true
        }

      }
      if (Number(sr1) >= Number(100)) {
        //obj.synthesis_rate = '请输入数值小于100';
        alert('利率输入数值应小于100')//20210425
        flag = true
      }
    } else {
      if (!item.min_day) {
        obj.min_day = formStatic.min_day;
        flag = true
      } else if (!numbers.test(delcommafy(item.min_day))) {
        //obj.min_amount = '请输入整数';
        alert('请输入整数')//20210425
        flag = true
      }
      if (!item.max_day) {
        obj.max_day = formStatic.max_day;
        flag = true
      } else if (!numbers.test(delcommafy(item.max_day))) {
        //obj.max_amount = '请输入整数';
        alert('请输入整数')//20210425
        flag = true
      } else if (!(delcommafy(item.min_day) < delcommafy(item.max_day))) {
        //obj.max_amount = '最大值需大于最小值';
        alert('最大值需大于最小值')//20210425
        flag = true
        window.location.reload()//20210425
      }
    }
    // if (!item.synthesis_rate) {
    //   obj.synthesis_rate = formStatic.synthesis_rate;
    //   flag = true
    // } else if (!number1.test(delcommafy(item.synthesis_rate))) {
    //   //obj.synthesis_rate = '请输入数值最多保留两位小数';
    //   alert('利率请输入数值最多保留两位小数且不能为负数')//20210425
    //   flag = true
    //   window.location.reload()//20210425
    // } else if (Number(sr1) >= Number(100)) {
    //   //obj.synthesis_rate = '请输入数值小于100';
    //   alert('利率输入数值应小于100')//20210425
    //   flag = true
    //   window.location.reload()//20210425
    // }
    rates1.push(obj)
    
  });
  
  if (flag) {
    errors.rates = rates1
  }
  return errors;
}


export const checkFormik = (form2, form) => {
  let check = '请输入';
  if (form.checkStatus === '3') {
    check = form2.check_opinion
    return {
      check_opinion:
        string()
          .trim().required(check),//审核意见
      sign_agreement:
        string()
          .trim().required(form2.sign_agreement),//签订协议 
    }
  }

  else if (form.checkStatus === '2' || form.checkStatus === '6') {
    check = form.checkStatus === '2' ? form2.return_reason : form2.remarks;
    return {
      check_opinion:
        string()
          .trim().required(check),//审核意见
    }
  }
}

