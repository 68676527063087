import React, { Component } from 'react'
// import { fetching } from '../../config/utils';
import {
  TextField,
  Button,
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { r14_3_2 } from '../formStatic';
import { C1442Formik } from './formikValidate';
import { Formik } from 'formik';
import FormikError from './formikError';
const { object } = require('yup');

const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }
}));
class C141Form extends Component {
  state = {
    form: this.getInitState(),
    formStatic: { ...r14_3_2 },//静态资源
  }
  getInitState() {

    const form = {
      impawn_rate: "",//质押率
      warning_line: "",//警戒线
      collect_amount: "",//有效应收账款金额


    }
    return form
  }
  handleChange = (e) => {
    const { form } = this.state
    const { name, value } = e.target;
    form[name] = value;
    this.setState({ form: { ...form } })

  }
  //保存 提交
  handleSubmit = () => {
    // const form = this.state;


  }
  handleReset = () => {
    let form2 = this.getInitState();
    this.setState({ form: form2 })
  }

  render() {
    const { formStatic, form } = this.state;
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        validationSchema={object().shape(C1442Formik(formStatic))}
        //验证提交
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
            <form style={{ maxWidth: '800px', margin: '0 auto' }}>
              <h4>应收账款质押预警</h4>
              <Grid container alignItems="flex-end">
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits2"
                    label={formStatic.impawn_rate}
                    value={values.impawn_rate}
                    name='impawn_rate'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                  />
                </Grid>
                <div style={{ padding: '10px 0 10px 30px' }}>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={handleSubmit}
                    name='query'
                    style={{ marginLeft: '10px' }}
                  >
                    {'确定'}
                  </Button>
                </div>
              </Grid>
              <FormikError touched={touched} errors={errors} name={'impawn_rate'} />

              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.warning_line}
                    value={values.warning_line}
                    name='warning_line'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={formStatic.collect_amount}
                    value={values.collect_amount}
                    name='collect_amount'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                </Grid>
              </Grid>


            </form>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(C141Form);



