import React, { Component } from 'react';
import {
  ViewComfy,
  EventNote,//发票验真伪--所有--20201126
  EventAvailable,//发票验真伪--通过--20201126
  EventBusy,//发票验真伪--未通过--20201126
  CloudDownload,//替换图表20200923
  AccountTree,//20200929
  CloudUpload,
  DomainSharp,
  Receipt,
  QueryBuilder,
  Input,
  InsertChart,
  InsertChartOutlined,
  InsertChartOutlinedOutlined,
  Edit,
  DeleteForever,
  Info
} from '@material-ui/icons'
import MaterialTable from '../../vendors/material-table';
import { defer, capitalize, fetching } from '../../config/utils';
import { core_business_url } from '../core_business_url';
import { Dialog, C23Form, C318Form, C321Form, ZDForm ,C42ViewList} from '../../02-core_business/containers2'
import { f2_3, f3_1_8, f3_2_1, zd, common } from '../../02-core_business/formStatic';
import { Form1, CommonDialog, CheckBoxDownForm } from '../../components/commonForm';
import { loginInfos } from '../../components/numToText';
import { DEFAULT_UPLOADS_URL, DEFAULT_EXPORT_URL } from '../../config/constants'
import {
  Button,
  ButtonGroup,
  Grid,
  Paper,
} from '@material-ui/core';//20201117
import { DownloadTimeForm, DialogForm } from '../../02-core_business/downloadTime';//时间选择弹窗--20201117
import { InterestDerivedForm, InterestDerivedDialogForm } from '../../02-core_business/interestDerived';//利息摊销弹框 
import { InvoiceListForm, InvoiceDialogForm } from '../../components/invoiceList';//发票验真伪结果list展示弹窗--20201126
import { BfeMsgInputForm, DialogbfeMsg } from '../../02-core_business/bfeMsgIn';//北金所加参弹框
import CircularProgress from '@material-ui/core/CircularProgress';//等待转圈展示弹窗--20201229
import { sum } from 'lodash';

// copied from 05-.../DataView.js
class View extends Component {
  state = {
    setLoading: false,//等待转圈--20201229
    columns: [{}],
    sum: {
      sumData:[],
      page:1,
      rowsPerPage:5,
    },
    sumAmount: 0,
    sumCount: 0,
    data: [],
    loading: false,//等待--20201210
    hasToken: sessionStorage.getItem("authToken"),
    dataUrl: {url:{query:''}},
    id: 10000,
    form2: { ...common },
    auth: {},
    params: {},
    exercise: {
      save: '1',
      DialogTitle: '新增',
      open: false,
      open3:false,
      status2: '1',
      checkOpen: false,
      table: this.props.table || ''
    },
    opinion_list: [],
    query: '',
    orderBy: '',
    orderDirection: ''

  };
  //获取数据
  componentDidMount() {
    const { table } = this.props;

    fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {
        if (data !== undefined && data !==null) {
          let auth = loginInfos();
          const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
          //根据不同的页面，获取不同的接口地址，渲染不同的数据
          const dataUrl = core_business_url(auth, table).find(item => item.name === table);
          const dataUrl2 = dataUrl || {};
          const localStorageData = this.localStorageData(table, auth.account) || [];
          this.setState({ columns, dataUrl: dataUrl2, data: localStorageData, auth });
          if (dataUrl2.url) {
            this.fetchings(dataUrl2, table, auth);
          }
        }

      })
  }
  //根据条件查询数据
  fetchings = (dataUrl2, table, auth) => {
    const rolesId = auth.roles ? auth.roles[0].id : ''
    table = dataUrl2.name
    if (dataUrl2.url.query) {
      if ((table === 'r6-2-1' && rolesId === 1) ||
        (table === 'r6-4-1' && rolesId === 1) ||
        (table === 'r6-3-1' && (rolesId === 1 || rolesId === 9 || rolesId ===16 )) /*|| 
        table === 'r6-4-7' || table === 'r6-4-2'*/) {
        this.onQuery2(table);
        //} else if ( (table === 'r6-3-1' || table === 'r6-4-1' || table === 'r6-2-1') && rolesId === 3) {//融资申请审核 && 风险经理
        //this.onQueryfpyzwJava();
      } else if (table === 'r6-1' || table === 'r6-2-3' || table === 'r6-3-7'
      || table === 'r6-4-2'  || table === 'r6-4-5' || table === 'r6-4-6'
      || table === 'r6-4-7') {
        this.QueryByPeriod(1)

    } else {
        this.onQuery();
      }
    }
  }
  //风险管理发票验真伪列表查询
  onQueryfpyzwJava = () => {
    const { table } = this.props;
    var url;
    const status_id = 3;
    const role_id = 2;

    if (table === 'r6-2-1') {//融单开立审核
      url = `/invoiceVerification/getIssuesInvoiceList?status_id=${status_id}&role_id=${role_id}`;
    } else if (table === 'r6-4-1') {//融单流转审核
      url = `/invoiceVerification/getCirculationsInvoiceList?status_id=${status_id}&role_id=${role_id}`;
    } else if (table === 'r6-3-1') {//融资申请审核
      url = `/invoiceVerification/getFinancingsInvoiceList?status_id=${status_id}&role_id=${role_id}`;
    }

    fetching(url).then(data => {
      if (data !== undefined) {
        let datas = data.filter(item => {
          item.status = item.Status ? item.Status.name : '';
          item.operator = item.User ? item.User.name : '';
          item.payer = item.mx_payer ? item.mx_payer : item.extraction;
          // item.payer = '融单平台';
          item.role_name = this.queryName(String(item.RoleId), String(item.statusId ? item.statusId:item.status_id), item)
          /*let Flows = item.Flows || item.FinancingFlows || item.CirculationFlows || [];
          if (Flows.length > 0) {
            item.reason = Flows[0].check_opinion*/
          let flows = item.flows || item.financingFlows || item.circulationFlows || [];
            if (flows.length > 0) {
            item.reason = flows[flows.length - 1].check_opinion
          }
          if (item.StatusId !== 2 && item.StatusId !== 1) {
            return item;
          } else{
            return null;
          }
        })
        if (table === 'r6-2-2' || table === 'r6-3-4' || table === 'r6-4-3') {
          datas = datas.filter(item => {
            if (item.StatusId !== 11 && item.StatusId !== 7 && item.StatusId !== 5 && item.StatusId !== 6) {
              return item;
            }else{
              return null;
            }
          })
        }
        this.setState({ data: datas });
      }
    })
  }

  //查询数据
  
  onQuery = () => {
    const { table } = this.props;
    const { url } = this.state.dataUrl;
    let enterprise_no = "";
    //const enterprise_no = this.state.auth.organization.enterprise_no;
    if(this.state.auth.organization.name === "融单平台"){
      enterprise_no = "KU9202352625018";//保理
    }else{
      enterprise_no = "KU9202442576565";//保理1
    }
    if( table === 'r6-3-10'){
      fetching(url.query + enterprise_no).then(data => {
        if (data !== undefined && data !==null) {
          let datas = data.filter(item => {
            item.receive = (Number(item.accountPayee)/100) ? (Number(item.accountPayee)/100) : (Number(item.accountPayer)/100);
            item.pay = (Number(item.accountPayer)/100) ? (Number(item.accountPayer)/100) : (Number(item.accountPayee)/100);
            if (item.status === 1) {
              item.payStatus = "成功";
            } else {
              item.payStatus = "失败";
            }
            return item;
          })
          this.setState({ data : datas });
      }
    })
    } else if (table === 'r8-7' || table === 'r6-3-11') {
      try {
        const { url } = this.state.dataUrl;  
        //const enterprise_no = this.state.auth.organization.enterprise_no;
        let enterprise_no = "";
        if(this.state.auth.organization.name === "融单平台"){
          enterprise_no = "KU9202352625018";//保理
        }else{
          enterprise_no = "KU9202442576565";//保理1
        }
        fetching(`${url.query+enterprise_no}`).then(data => {
          if (data !== undefined && data !==null) {
            let datas = data.filter(item => {
              item.receive = (Number(item.accountPayee)/100) ? (Number(item.accountPayee)/100) : (Number(item.accountPayer)/100);
              item.pay = (Number(item.accountPayer)/100) ? (Number(item.accountPayer)/100) : (Number(item.accountPayee)/100);
              if (item.status === 1) {
                item.payStatus = "成功";
              } else {
                item.payStatus = "失败";
              }
              return item;
            })
            this.setState({ data : datas });
          } else {
            alert('查询失败')
          }
  
        })
      } catch (e) {
      }
      return;
    }else if(table === 'r6-5-1' || table === 'r6-5-2'){
      fetching(url && url.query).then(res => {
        if (res !== undefined && res.data) {
          this.setState({ data: res.data })
        }
      })
    }else {
    fetching(url && url.query).then(data => {
      if (data !== undefined && data !==null) {
        let datas = data.filter(item => {
          //过滤到所有查询出来的列表的已删除信息--20201223
          /*if (item.StatusId === 12) {
            return null;
          }
          if (table === 'r6-2-2' || table === 'r6-3-4' || table === 'r6-4-3') {//r6-2-2:开立融单查看;r6-3-4:融资查看;r6-4-3:支付融单查看
            if (item.StatusId === 11 || item.StatusId === 7 || item.StatusId === 5 || item.StatusId === 6) {
              return null;
            }
          }*/
          item.Status = item.status;
          item.status = item.status ? item.status.name : '';
          item.operator = item.user ? item.user.name : '';
          if(table === 'r6-3-1' || table === 'r6-3-4'){
          item.payer = item.circulation ? item.circulation : item.extraction;
          }else{
          item.payer = item.mx_payer ? item.mx_payer : item.extraction;
          }
         //item.payer = item.mx_payer ? item.mx_payer : item.extraction;
          // item.payer = '融单平台';
          item.role_name = this.queryName(String(item.roleId), String(item.statusId ? item.statusId:item.status_id), item)
          // let Flows = item.Flows || item.FinancingFlows || item.CirculationFlows || [];
          // if (Flows.length > 0) {
          //   item.reason = Flows[0].check_opinion
          let flows = item.flows || item.financingFlows || item.circulationFlows || [];
          if (flows.length > 0) {
            item.reason = flows[flows.length - 1].check_opinion
          }
          return item;
          /*if (item.statusId !== 2 && item.statusId !== 1) {
            return item;
          } else{
            return null;
          }*/
        })
         /*if (table === 'r6-2-2' || table === 'r6-3-4' || table === 'r6-4-3') {//r6-2-2:开立融单查看;r6-3-4:融资查看;r6-4-3:支付融单查看
          datas = datas.filter(item => {
            //if (item.StatusId !== 11 && item.StatusId !== 7 && item.StatusId !== 5 && item.StatusId !== 6) {
            if (item.StatusId !== 12) {//status:12-已删除;11-已融资;7-已退回;6-拒签收;5-已签收
              return item;
            }else{
              return null;
            }
          })
        }*/
        this.setState({ data: datas });
      }
    })
  }

  }
  //查询多个
  onQuery2 = (table) => {
    const { url } = this.state.dataUrl;
    let fetchingData = [];
    let role_id = this.state.auth.roles[0].id;
    if (table === 'r6-2-1') fetchingData = [fetching(url.query), fetching(url.query1)]
    else if (table === 'r6-4-1') fetchingData = [fetching(url.query), fetching(url.query1)]
    else if (table === 'r6-3-1' && role_id !== 16 && role_id !== 9) fetchingData = [fetching(url.query)]
    else if (table === 'r6-3-1' && role_id === 16) fetchingData = [fetching(url.query1), fetching(url.query2)]
    else if (table === 'r6-3-1' && role_id === 9) fetchingData = [fetching(url.query)]
    else if (table === 'r6-1') fetchingData = [fetching(url.query), fetching(url.query2), fetching(url.query3)]
    else if (table === 'r6-4-7') fetchingData = [fetching(url.query), fetching(url.query2)]
    else if (table === 'r6-4-2') fetchingData = [fetching(url.query), fetching(url.query2)]


    Promise.all(fetchingData).then(values => {
      let data = []
      values.forEach(item => {
        if (item instanceof Array) {
          data = data.concat(item);
        }
      })
      data = data.filter(item => {
        //过滤到所有查询出来的列表的已删除信息--20201223
        if (item.StatusId === 12) {
          return null;
        }
        item.Status = item.status;
        item.status = item.status ? item.status.name : '';
        item.operator = item.user ? item.user.name : '';
	if(table === 'r6-3-1'){
          item.payer = item.circulation ? item.circulation : item.extraction;
        }else{
          item.payer = item.mx_payer ? item.mx_payer : item.extraction;
        }
        //item.payer = item.mx_payer ? item.mx_payer : item.extraction;
        // item.payer = '融单平台';
        item.role_name = this.queryName(String(item.roleId), String(item.statusId ? item.statusId:item.status_id), item)
        // let Flows = item.Flows || item.FinancingFlows || item.CirculationFlows || [];
        // if (Flows.length > 0) {
        //   item.reason = Flows[0].check_opinion
        let flows = item.flows || item.financingFlows || item.circulationFlows || [];
        if (flows.length > 0) {
          item.reason = flows[flows.length - 1].check_opinion
        }
        if (item.statusId !== 2 && item.statusId !== 1) {
          return item;
        } else{
          return null;
        }
        
      })
      this.setState({ data });
    });
  }
  //查询当前审核的岗位 role_name
  /*queryName = (role_id = '', status_id = '', item = {}) => {

    let role_name = '';
    switch (role_id) {
      case '11': //操作员
        if (status_id === '7' || status_id === '6') {
          role_name = "操作员";
        } else if (status_id === '5') {
          role_name = "已完成";
        } else {
          role_name = "复核岗";
        }
        break;
      case '12': //复核岗/管理员
        if (status_id === '7' || status_id === '6') {
          role_name = "操作员";
        } else if (status_id === '5' && item.mxf_number) {
          role_name = "待复核";
        } else if (status_id === '5' && item.mxf_number === "") {
          role_name = "已完成";
        } else if (status_id === '10') {
          role_name = "综合部经办人";
        } else {
          if (item.mxc_number) {
           //role_name = "风险经理";
           if (item.mxf_number) {//宋炜--20210107
            role_name = "业务经理";
          } else {
            role_name = "风险经理";
          }
          } else {
            role_name = "业务经理";
          }

        }
        break;
      case '13': //管理员
        if (status_id === '7' || status_id === '6') {
          role_name = "操作员";
        } else if (status_id === '5') {
          role_name = "已完成";
        } else if (status_id === '10') {
          role_name = "综合部经办人";
        } else {
          if (item.mxc_number) {
            role_name = "风险经理";
          } else {
            role_name = "业务经理";
          }
        }
        break;
      case '1': //业务经理
        if (status_id === '7') {
          role_name = "复核岗/管理员";
        } else {
          role_name = "业务负责人";
        }
        break;
      case '2': //业务负责人
        if (status_id === '7') {
          role_name = "业务经理";
        } else {
          role_name = "风险经理";
        }
        break;
      case '3': //风险经理
        if (status_id === '7') {
          if (item.mxc_number) role_name = "复核岗/管理员";
          else role_name = "业务负责人";
        } else {
          role_name = "风险负责人";
        }
        break;
      case '4': //风险负责人
        if (status_id === '7') {
          role_name = "风险经理";
        } else {
          role_name = "法务";
        }
        break;
      case '15': //法务
        if (status_id === '7') {
          role_name = "风险负责人";
        } else {
          role_name = "总经理";
        }
        break;
      case '8': //总经理
        if (status_id === '7') {
          role_name = "法务";
        } else {
          //if (item.mxc_number) role_name = "已完成";
          // if (item.mxf_number) role_name = "董事长";
          if (item.mxf_number) role_name = "董事长";//宋炜-20210107
          else if (item.mxc_number) role_name = "已完成";
          else role_name = "综合部经办人";
        }
        break;
      case '14': //董事长
        if (status_id === '7') {
          role_name = "总经理";
        } else {
          role_name = "操作员";
        }
        break;
      case '9': //综合部经办人
        if (status_id === '7') {
          role_name = "总经理";
        } else {
          if (item.mxf_number) role_name = "财务经办人";
          else role_name = "已完成";
        }
        break;
      case '5': //财务经办人
        if (status_id === '7') {
          role_name = "综合部经办人";
        } else {
          role_name = "财务负责人";
        }
        break;
      case '6': //财务负责人
        if (status_id === '7') {
          role_name = "财务经办人";
        } else {
          role_name = "已融资";
        }
        break;
      default: ;
    }
    return role_name;
  }*/

  //查询当前审核的岗位 role_name
  queryName = (role_id = '', status_id = '', item = {}) => {

    let role_name = '';
    switch (role_id) {
      case '11': //操作员
        if (status_id === '7' || status_id === '6') {
          role_name = "操作员";
       /* } else if (status_id === '5') {
          role_name = "已完成";*/
	} else if (status_id === '5' && item.mxf_number) {
          role_name = "待复核";
        } else if (status_id === '5' && item.mxf_number === "")  {
          role_name = "已完成";
        } else {
          role_name = "复核岗/管理员";
        }
        break;
      case '12': //复核岗/管理员
        if (status_id === '7' || status_id === '6') {
          role_name = "操作员";
        } else if (status_id === '5' && item.mxf_number) {
          role_name = "待复核";
        } else if (status_id === '5' && item.mxf_number === "") {
          role_name = "已完成";
        } else if (status_id === '10') {
          role_name = "综合部经办人";
        } else if (status_id === '13') {
          role_name = "融资分配负责人";
        } else {
          if (item.mxc_number) {
            if (item.mxf_number) {
              role_name = "业务经理";
            } else {
              role_name = "业务经理";
            }            
          } else {
            role_name = "业务经理";
          }

        }
        break;
      case '16': //融资分配
      if (status_id === '7' ) {
        role_name = "复核员";
      } else if (status_id === '3') {
        role_name = "业务经理";
      } else if (status_id === '14') {
        role_name = "其他业务";
      } else if (status_id === '13') {
        role_name = "融资分配负责人";
      } 
      break;
      case '13': //管理员
        if (status_id === '7' || status_id === '6') {
          role_name = "操作员";
        } else if (status_id === '5') {
          role_name = "已完成";
        } else if (status_id === '10') {
          role_name = "综合部经办人";
        } else {
          if (item.mxc_number) {
            role_name = "风险经理";
          } else {
            role_name = "业务经理";
          }
        }
        break;
      case '1': //业务经理
        if (status_id === '7') {
          role_name = "复核岗/管理员";
        } else if (item.distribute != null && item.distribute.includes("汇通")){ 
          role_name ="业务负表人";
        } else {
          role_name = "风险经理";
        }
        break;
      case '2': //业务负责人
        if (status_id === '7') {
          role_name = "业务经理";
        } else {
          role_name = "风险经理";
        }
        break;
      case '3': //风险经理
        if (status_id === '7') {
          if (item.mxc_number) role_name = "复核岗/管理员";
          else if (item.distribute != null && item.distribute.includes("汇通")) role_name = "业务负责人";
          else role_name = "业务经理";
        } else if(item.distribute != null && item.distribute.includes("汇通")){
          role_name = "法务";
        } else {
          role_name = "总经理";
        }
        break;
      case '4': //风险负责人
        if (status_id === '7') {
          role_name = "风险经理";
        } else {
          role_name = "法务";
        }
        break;
      case '15': //法务
        if (status_id === '7') {
          role_name = "风险经理";
        } else {
          role_name = "总经理";
        }
        break;
      case '8': //总经理
        if (status_id === '7') {
          role_name = "法务";
        } else {
          if (item.mxf_number) role_name = "董事长";
          else if (item.mxc_number) role_name = "已完成";
          else role_name = "综合部经办人";
        }
        break;
      case '14': //董事长
        if (status_id === '7') {
          role_name = "总经理";
        } else {
          role_name = "操作员";
        }
        break;
      case '9': //综合部经办人
        if (status_id === '7') {
          role_name = "总经理";
        } else {
          if (item.mxf_number) role_name = "财务经办人";
          else role_name = "已完成";
        }
        break;
      case '5': //财务经办人
        if (status_id === '7') {
          role_name = "综合部经办人";
        } else {
          role_name = "财务负责人";
        }
        break;
      case '6': //财务负责人
        if (status_id === '7') {
          role_name = "财务经办人";
        } else {
          role_name = "已融资";
        }
        break;
      default: ;
    }
    return role_name;
  }

  handleChild = (data)=> {
    this.$Child.childConsole(data);    // this上有了子组件方法
  }

  //获取本地保存的数据
  localStorageData = (table, name) => {
    let localStorageData = [];
    let issue = null;
    if (table === 'm2-3' || table === 'm3-1-8' || table === 'm3-2-1') {
      issue = localStorage.getItem('issue');
    }
    if (issue) {
      let issue2 = JSON.parse(issue);
      let issue3 = issue2.find(item => item.name === name);
      if (issue3) localStorageData = issue3[table];
    }
    return localStorageData;
  }
  onRef = (ref) => {
    this.child = ref
  }

  //核心企业3表单提交
  FormSubmit = (newData, data) => {
    let datas = data || this.state.data || [];
    const { dataUrl, exercise, auth } = this.state;
    if (newData.submit_name === '提交') {
      if (newData.formDates) {
        datas = this.deleteSave(newData.formDates);
      } else {
        this.setState({
          exercise: { ...this.state.exercise, open: false }
        })
        setTimeout(() => {
          this.fetchings(dataUrl, exercise.table, auth)
        }, 500)
      }
    } else {
      this.setState({
        data: JSON.parse(JSON.stringify(datas)),
        exercise: { ...this.state.exercise, open: false }
      })
    }
  }
  FormSubmit2 = (newData) => {
    newData.formDate = new Date();
    if (newData.submit_name === '提交') {
      this.onQuery();
    }
    this.setState({
      exercise: { ...this.state.exercise, open: false }
    })
  }
  // 对象查找
  findObject = (obj, data) => {
    let index = data.findIndex(item => item.id === obj.id);
    return index;
  }
  //新增开具表单
  handleToggle = (open) => {
    const { form2, exercise } = this.state
    if (open) {

      this.setState({
        exercise: { ...exercise, open: false }
      })
    } else {
      this.setState({
        exercise: { ...exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
      })
    }
  }

  isTwoDecimalPlaces(synthesis_rate) {
    return /^\d+(\.\d{2})?$/.test(synthesis_rate);
  }

  //利率新增
  onAdd = async newData => {
    //let data = this.state.data;
    //data.unshift(newData);
    //this.setState({ data })
    //return defer(0).then(console.log('newData ---: ', newData));
    const { dataUrl } = this.state
    if (isNaN(newData.synthesis_rate)){
      alert("利率必须为数字")
      return
    }
    if (!this.isTwoDecimalPlaces(newData.synthesis_rate)){
      alert("利率必须为2位小数")
      return
    }
    const returnedData = await fetching(`/msapi/financing/saveRate?company_name=${newData.company_name}&synthesis_rate=${newData.synthesis_rate}`, { method: 'POST' })
    if(returnedData.code === 400){
      alert(returnedData.msg)
      return
    }
    window.location.reload()
  }

  //利率编辑
  onUpdate = async (newData, oldData) => {
    //return defer(60).then(console.log(' newData, oldData ---: ', newData, oldData));
    const { dataUrl } = this.state
    if (isNaN(newData.synthesis_rate)){
      alert("利率必须为数字")
      return
    }
    if (!this.isTwoDecimalPlaces(newData.synthesis_rate)){
      alert("利率必须为2位小数")
      return
    }
    const returnedData = await fetching(`/msapi/financing/updateRate?id=${oldData.id}&company_name=${newData.company_name}&synthesis_rate=${newData.synthesis_rate}`, { method: 'POST' })
    if(returnedData.code === 400){
      alert(returnedData.msg)
      return
    }
    window.location.reload()
  }

  //利率删除
  onDelete = async oldData => {
    const { dataUrl } = this.state
    await fetching(dataUrl.url.delete + oldData.id, { method: 'GET' })
    window.location.reload()
  }

  //查看开具制单1
  onview = (e, row) => {
    const { form2, dataUrl, exercise } = this.state;
    let query = dataUrl.url.get2 || dataUrl.url.query;
    // let query2 = '/api/totalamount?organization_id=' + row.organizationId;
    if (dataUrl.name === 'r6-4-7') {
      query = exercise.mxc_number ? dataUrl.url.get2 : dataUrl.url.get;
    }
    fetching(query + row.id).then(res => {
      if (res !== undefined && res !==null) {
        this.setState({
          exercise: {
            ...exercise, ...res, table: this.props.table,
            save: '3', status2: '', DialogTitle: form2.view, open: true,
          }
        });
      }
    })

  }

  //公共表单
  handleCommon = (open) => {
    const { form2, exercise } = this.state;
    if (open) {
      this.setState({
        exercise: { ...exercise, checkOpen: false },
      })
      if(this.state.dataUrl.name !== "r6-2-3" && this.state.dataUrl.name !== "r6-3-7" && this.state.dataUrl.name !== "r6-4-5"){
        window.location.reload()
      }
    } else {
      this.setState({
        exercise: { ...exercise, status2: '2', DialogTitle: form2.check, save: '3', checkOpen: true },
      })
    }
  }

  //公共表单提交
  FormSubmit3 = () => {
    const { dataUrl, exercise, auth } = this.state;
    this.setState({
      exercise: { ...this.state.exercise, checkOpen: false }
    })
    setTimeout(() => {
      this.fetchings(dataUrl, exercise.table, auth)
    }, 1000)
    if (auth.roles[0].id === 6) {
      //alert('融资成功')
    }
  }
  export = (e, row) => {
    //获取审核意见
    fetching(`/msapi/flow/find?mx_number=${row.mx_number}`).then(res => {
      if (res !== undefined && res !==null) {
        console.log(res)
      }
    })
 }


  //融单流转树形图--20200929
  onTree = (e, row) => {
    const { table } = this.props;
    const { form2, exercise } = this.state;
    //type--1:开立；2：流转；3：融资
    var type = 0;
    var mxi_number = "";
    if (table === 'r6-2-3') {//已开融单
      type = 1;
      mxi_number = row.mx_number;
    } else if (table === 'r6-4-5') {//已支付融单
      type = 2;
      mxi_number = row.mxc_number;
    } else if (table === 'r6-3-7') {//已融资融单
      type = 3;
      mxi_number = row.mxf_number;
    }
    fetching(`/dzqz/getRDTree?mx_number=${row.mx_number}&mxi_number=${mxi_number}`).then(data => {
      if (data.code === 200) {
        this.setState({
          exercise: {
            save: '3', DialogTitle: form2.RDTree,
            status2: '2', checkOpen: true, checkStatus: '8',
            RDTree: data.data || {}
          },
        });
      } else {
        alert('数据获取失败！')
      }
      
    })

  }

  //开具复核-审核
  onCheck = (e, row) => {
    const { form2, dataUrl, exercise, auth } = this.state;
    const { roles = [{ id: 0 }] } = auth;
    let roleId = roles[0].id;
    let table = dataUrl.name;
    let status_id = row.status_id;
    if (roleId === 1 && table === "r6-2-2" && status_id !== 4) {
      alert("该操作只允许退回待签收融单")
      return;
    }
    if(roleId === 3){
      if(row.fdd_file_name === "" || row.fdd_file_path === ""){
        alert("融资合同填充失败,请退回上一级进行重新审核操作或联系管理员")
      }
    }
    let query = dataUrl.url.get || dataUrl.url.query;
    let mxc_number = ``;
    let DialogTitle = form2.check;
    if (roleId === 5 || roleId === 6) {
      DialogTitle = '放款';
    } else if (roleId === 9) {
      DialogTitle = '签章';
    } else if (roleId === 16) {
      DialogTitle = '分配';
    }
    if (row.flow_date) mxc_number = `cid=${row.id}`
    else if (row.mxf_number) mxc_number = `mxf_number=${row.mxf_number}`;

    let department_cond = ``;
    if (parseInt(auth.department.id) === 7) department_cond = `department_id=7`;

    fetching(query + `?mx_number=${row.mx_number}&${department_cond}&${mxc_number}`).then(data => {
      this.setState({
        exercise: {
          ...exercise, ...row, save: '3', DialogTitle,
          rolseId: '2',
          status2: '2', checkOpen: true, checkStatus: '3',
          opinion_list: data || []

        },
      });
    })

  }
  //开具复核-退回
  onCheck2 = (e, row) => {
    const { dataUrl, exercise, auth } = this.state
    let query = dataUrl.url.get || dataUrl.url.query;
    let mxc_number = ``;
    if (row.flow_date) mxc_number = `cid=${row.id}`
    else if (row.mxf_number) mxc_number = `mxf_number=${row.mxf_number}`

    let department_cond = ``;
    if (parseInt(auth.department.id) === 7) department_cond = `department_id=7`;

    fetching(query + `?mx_number=${row.mx_number}&${department_cond}&${mxc_number}`).then(data => {
      this.setState({
        exercise: {
          ...exercise,
          ...row,
          save: '3',
          DialogTitle: '退回',
          status2: '2',
          checkStatus: '2',
          checkOpen: true,
          opinion_list: data || []
        },
      });
    })
  }

  // 批量下载
  onCheck3 = (e, row) => {
    const { form2, dataUrl, exercise, auth } = this.state;
    const { roles = [{ id: 0 }] } = auth;
    const { url } = this.state.dataUrl;
    let fetchingData = [];
    let roleId = roles[0].id;
    let query = dataUrl.url.get || dataUrl.url.query;
    let DialogTitle = form2.check;
    if (roleId === 5 || roleId === 6) {
      DialogTitle = '批量下载';
    }
    fetchingData = [fetching(url.query4), fetching(url.query5)]
    Promise.all(fetchingData).then(values => {
          let data = []
          values.forEach(item => {
            if (item instanceof Array) {
              data = data.concat(item);
            }
          })
          data = data.filter(item => {
            item.start_day = item.open_day ? item.open_day : item.flow_date ;
            item.status = item.status ? item.status.name : '';
            item.operator = item.user ? item.user.name : '';
            item.payer = item.mx_payer ? item.mx_payer : item.extraction;
            item.role_name = this.queryName(String(item.roleId), String(item.status_id), item)
            return item;
          })
          this.setState({
            exercise: {
              ...exercise, data: data, save: '1', DialogTitle,
              rolseId: '2',
              status2: '2', checkOpen: true, checkStatus: '3',
    
            },
          });
        });

  }

  

//中登登记
onZDRegister = (e, row) => {
  const { form2, dataUrl, exercise } = this.state;
    let query = dataUrl.url.get2 || dataUrl.url.query;
    // let query2 = '/api/totalamount?organization_id=' + row.organizationId;
    if (dataUrl.name === 'r6-4-7') {
      query = exercise.mxc_number ? dataUrl.url.get2 : dataUrl.url.get;
    }
    fetching(query + row.id).then(res => {
      if (res !== undefined && res !==null) {
        this.setState({
          exercise: {
            ...exercise, ...res,
            save: '2', status2: '1', DialogTitle: "登记", open: true,
          }
        });
      }
    })
 }

 //中登查询
 onZDQuery = (e, row) => {
  fetching(`/zd/queryBySubject?nameorno=${row.applicant}`).then(res => {    
    if (res !== undefined && res !==null) {
      if (res.code !== 400) {
        if(res.data.length > 0){
          for (let i = 0; i < res.data.length; i++){
            let imgUrl2 = res.data[i].fpath ? DEFAULT_UPLOADS_URL + res.data[i].fpath : '';
            let typeIndex = imgUrl2.lastIndexOf('.');
            if (typeIndex !== -1) {
                console.log(imgUrl2)
                window.open(imgUrl2, '_blank' )
            }
            } 
          }else {
            alert("没有找到匹配的记录")
          }
        }else {
          alert(res.msg)
        }
    }  
    })
  }

sendCEBBasicInform =(e,row) =>{
    fetching(`/msapi/ceb/sendCEBBasicInform?mxf_number=${row.mxf_number}`).then(res =>{
      if(res !== undefined && res !==null){
        alert(res.msg)
      }
    })
  }

  sendBobBasicInform =(e,row) =>{
    fetching(`/msapi/bob/sendBobBasicInform?mxf_number=${row.mxf_number}`).then(res =>{
      if(res !== undefined && res !==null){
        alert(res.msg)
      }
    })
  }
    
  //搜索框Change
  onSearchChange = (query) => {
    console.log('onSearchChange', query);
  }
  // 对data数据进行排序
sort = (orderBy, orderDirection,data) => {
  var dsa = []
  data.filter(item => {
    if(item.remarks === null){
      dsa.push(item)
    }
  })
  //debugger
  // 对数据进行排序操作
    switch (orderBy) {
      // case -1:  
      //   this.state.orderBy = ''
      //   this.state.orderDirection = ''
      // this.handleSearchChange(this.state.query)
      //   break;
      case 0:
        data.sort((a, b) => {
            var aa = a.mx_number;
            var bb = b.mx_number
          if (orderDirection === 'desc') {
            return bb.localeCompare(aa);
          } else {
            return aa.localeCompare(bb);
          }
        });
       break;
      case 1:
        data.sort((a, b) => {
          var aa = a.extraction;
          var bb = b.extraction
        if (orderDirection === 'desc') {
          return bb.localeCompare(aa, 'en-US-u-kn-true');
        } else {
          return aa.localeCompare(bb, 'en-US-u-kn-true');
        }
      });
        break;
      case 2:
          data.sort((a, b) => {
            var aa = a.receive;
            var bb = b.receive
          if (orderDirection === 'desc') {
            return bb.localeCompare(aa, 'en-US-u-kn-true');
          } else {
            return aa.localeCompare(bb, 'en-US-u-kn-true');
          }
        });
        break;
      case 3:
        data.sort((a, b) => {
          var aa = a.amount;
          var bb = b.amount
        if (orderDirection === 'desc') {
          return bb - aa;
        } else {
          return aa - bb;
        }
      });
        break;
      case 4:
        data.sort((a, b) => {
          var aa = a.payment_day;
          var bb = b.payment_day
        if (orderDirection === 'desc') {
          return bb.localeCompare(aa, 'en-US-u-kn-true');
        } else {
          return aa.localeCompare(bb, 'en-US-u-kn-true');
        }
      });
        break;
      case 5:
        data.sort((a, b) => {
          var aa = a.open_day;
          var bb = b.open_day
        if (orderDirection === 'desc') {
          return bb.localeCompare(aa, 'en-US-u-kn-true');
        } else {
          return aa.localeCompare(bb, 'en-US-u-kn-true');
        }
      });
        break;
      case 6:
        data.sort((a, b) => {
          var aa = a.operator;
          var bb = b.operator
        if (orderDirection === 'desc') {
          return bb.localeCompare(aa, 'en-US-u-kn-true');
        } else {
          return aa.localeCompare(bb, 'en-US-u-kn-true');
        }
      });
        break;
      case 7:
        data.sort((a, b) => {
          var aa = a.limitMark;
          var bb = b.limitMark
        if (orderDirection === 'desc') {
          return bb.localeCompare(aa, 'en-US-u-kn-true');
        } else {
          return aa.localeCompare(bb, 'en-US-u-kn-true');
        }
      });
        break;
      case 8:
        data.sort((a, b) => {
          var aa = a.remarks;
          var bb = b.remarks
        if (orderDirection === 'desc') {
          return bb.localeCompare(aa, 'en-US-u-kn-true');
        } else {
          return aa.localeCompare(bb, 'en-US-u-kn-true');
        }
      });
        break;
      default:
    }
};

// 搜索框统计融单合计金额
handleSearchChange = (searchText) => {
  //debugger
  if(this.props.table === 'r6-2-3'){
    this.state.query = searchText;
    var oldData = this.state.data.slice();
    if (searchText === '') {
      if (this.state.orderDirection === '') {
        this.state.sum.sumData = oldData
        this.state.sum.page = 1
        this.handleChild(this.state.sum);
      } else {
        this.sort(this.state.orderBy,this.state.orderDirection,oldData)
        this.state.sum.sumData = oldData
        this.state.sum.page = 1
        this.handleChild(this.state.sum);
      }
      
    }else {
      if (this.state.orderDirection === '') {
        this.searchChange(searchText,oldData)
        this.state.sum.page = 1
        this.handleChild(this.state.sum);
      } else {
        this.sort(this.state.orderBy,this.state.orderDirection,oldData)
        this.searchChange(searchText,oldData)
        this.state.sum.page = 1
        this.handleChild(this.state.sum);
      }
    }
  }
}

// 排序统计融单合计金额
handleSort = (orderBy, orderDirection) => {
  //debugger
  if(this.props.table === 'r6-2-3'){
    this.state.orderBy = orderBy
    this.state.orderDirection = orderDirection
    var oldData = this.state.data.slice()
    if (this.state.query === '') {
      if (orderBy === -1) {
        this.state.sum.sumData = oldData
        this.state.sum.page = 1
        this.handleChild(this.state.sum);
      } else {
        this.sort(orderBy, orderDirection,oldData)
        this.state.sum.sumData = oldData
        this.state.sum.page = 1
        this.handleChild(this.state.sum);
      }
    } else {
      if (orderBy === -1) {
        this.searchChange(this.state.query,oldData)
        this.state.sum.page = 1
        this.handleChild(this.state.sum);
      } else {
        this.searchChange(this.state.query,oldData)
        this.sort(orderBy, orderDirection,this.state.sum.sumData)
        this.state.sum.page = 1
        this.handleChild(this.state.sum);
      }
    }
  }
}

// 搜索data中包含的字符
searchChange = (searchText,data) => {
  //debugger
  if (this.props.table === 'r6-2-3') {
      this.state.query = searchText
    if (searchText !== "" || this.state.orderBy !== '') {
      var newData = data.filter(item => {
        var amount = item.amount + ''
        switch (true) {
          case (item.limitMark.includes(searchText)):
            return item.limitMark.includes(searchText)
          case (item.mx_number.includes(searchText)):
            return item.mx_number.includes(searchText);
          case (item.extraction.includes(searchText)):
            return item.extraction.includes(searchText);
          case (item.receive.includes(searchText)):
            return item.receive.includes(searchText);
          case (amount.includes(searchText)):
            return amount.includes(searchText);
          case (item.payment_day.includes(searchText)):
            return item.payment_day.includes(searchText);
          case (item.open_day.includes(searchText)):
            return item.open_day.includes(searchText);
          case (item.operator.includes(searchText)):
            return item.operator.includes(searchText);
            default:
        }
      });
      this.state.sum.sumData = newData
      //debugger
    }
  }
};
  //页面更改事件
  onChangePage = (query) => {
    console.log('onChangePage', query);
    if(query!==0){
      this.state.sum.page=query+1
    }else{
      this.state.sum.page=1;
    }
    if(this.props.table === 'r6-2-3'){
    this.handleChild(this.state.sum);
    }
  }
  //处理每页的行更改（pageSize）
  onChangeRowsPerPage = (query) => {
    console.log('onChangeRowsPerPage', query);
    if(query){
      this.state.sum.rowsPerPage=query
      this.state.sum.page=1
    }
    if(this.props.table === 'r6-2-3'){
    this.handleChild(this.state.sum);
    }
  }

  //按时间段进行数据查询
  QueryByPeriod = (e) => {
    let fetchingData = [];
    let dataType = 0; //dataType = 0 默认 1 已到期融单
    const { table } = this.props;
    const { url } = this.state.dataUrl;
    let dateType = e; // e = 1 月度 2 季度 3半年 4 一年 5 所有
    if (table === 'r6-1' ) {
      if (e === 1) {
        this.state.dataUrl.title = "月度融单";
      } else if (e === 2) {
        this.state.dataUrl.title = "季度融单";
      } else if (e === 3) {
        this.state.dataUrl.title = "近半年融单";
      } else if (e === 4) {
        this.state.dataUrl.title = "近一年融单";
      } else if (e === 5) {
        this.state.dataUrl.title = "全部融单"; 
      } 
      fetching(url && url.query4).then(data => {
        if (data !== undefined && data !==null) {
	let datas = data.filter(item => { 
            item.status = item.status ? item.status.name : '';
            item.operator = item.user ? item.user.name : '';
            item.payer = item.circulation ? item.circulation : item.extraction;
            return item;
          
          })
          this.setState({ data: datas });
        }
      })
          
    } else if ( table === 'r6-2-3') {
      if (e === 1) {
        this.state.dataUrl.title = "月度已开融单";
      } else if (e === 2) {
        this.state.dataUrl.title = "季度已开融单";
      } else if (e === 3) {
        this.state.dataUrl.title = "近半年已开融单";
      } else if (e === 4) {
        this.state.dataUrl.title = "近一年已开融单";
      } else if (e === 5) {
        this.state.dataUrl.title = "全部已开融单";
      } 
    fetching(url && url.query4+`&dateType=${dateType}`).then(data => {
      if (data !== undefined && data !==null) {
        let datas = data.filter(item => {
          //item.status = item.status ? item.status.name : '';
	  if (item.remarks === null) {
            item.remarks = ''
          }
          item.operator = item.user ? item.user.name : '';
          item.payer = item.circulation ? item.circulation : item.extraction;
          // item.payer = '融单平台';
          //item.role_name = this.queryName(String(item.RoleId), String(item.StatusId), item)
          /*let Flows = item.Flows || item.FinancingFlows || item.CirculationFlows || [];
          if (Flows.length > 0) {
            item.reason = Flows[0].check_opinion
          }*/
          return item;
        })
        this.state.sum.sumData=data; 
        this.setState({ data: datas });
        if (this.state.orderBy === '') {
          this.handleChild(this.state.sum)
        } else {
          this.handleSort(this.state.orderBy,this.state.orderDirection)
        }
      }
    })
    } else if (table === 'r6-3-7') {
    if (e === 1) {
      this.state.dataUrl.title = "月度已融资融单";
    } else if (e === 2) {
      this.state.dataUrl.title = "季度已融资融单";
    } else if (e === 3) {
      this.state.dataUrl.title = "近半年已融资融单";
    } else if (e === 4) {
      this.state.dataUrl.title = "近一年已融资融单";
    } else if (e === 5) {
      this.state.dataUrl.title = "全部已融资融单";
    } 
    fetching(url && url.query4+`&dateType=${dateType}`).then(data => {
        if (data !== undefined && data !==null) {
          let datas = data.filter(item => {
            item.amortize= item.amortize == 0 ? "摊销" : "不进行摊销";
            //item.status = item.status ? item.status.name : '';
            //item.operator = item.user ? item.user.name : '';
            //item.payer = item.circulation ? item.circulation : item.extraction;
            // item.payer = '融单平台';
            //item.role_name = this.queryName(String(item.RoleId), String(item.StatusId), item)
            /*let Flows = item.Flows || item.FinancingFlows || item.CirculationFlows || [];
            if (Flows.length > 0) {
              item.reason = Flows[0].check_opinion
            }*/
            return item;
          })
          
          this.setState({ data: datas });
        }
      })
    } else if (table === 'r6-4-2') {
      if (e === 1) {
        this.state.dataUrl.title = "月度可用融单";
      } else if (e === 2) {
        this.state.dataUrl.title = "季度可用融单";
      } else if (e === 3) {
        this.state.dataUrl.title = "近半年可用融单";
      } else if (e === 4) {
        this.state.dataUrl.title = "近一年可用融单";
      } else if (e === 5) {
        this.state.dataUrl.title = "全部可用融单";
      } 
      fetchingData = [fetching(url.query4+`&dateType=${dateType}`), fetching(url.query5+`&dateType=${dateType}`)]

        Promise.all(fetchingData).then(values => {
          let data = []
          values.forEach(item => {
            if (item instanceof Array) {
              data = data.concat(item);
            }
          })
          data = data.filter(item => {
            
            
            // if (table === 'r6-4-7') {
            //   item.start_day = item.open_day ? item.open_day : item.flow_date ;
            // }
            item.status = item.status ? item.status.name : '';
            item.operator = item.user ? item.user.name : '';
            //item.payer = item.circulation ? item.circulation : item.extraction;
            item.payer = item.mx_payer ? item.mx_payer : item.extraction;
            // item.payer = '融单平台';
            item.role_name = this.queryName(String(item.roleId), String(item.status_id), item)
            // let Flows = item.Flows || item.FinancingFlows || item.CirculationFlows || [];
            // if (Flows.length > 0) {
            //   item.reason = Flows[0].check_opinion
            // }
            // if (item.StatusId !== 2 && item.StatusId !== 1) {
            //   return item;
            // } else{
            //   return null;
            // }
            return item;
          })
          this.setState({ data });
        });
    } else if ( table === 'r6-4-5') { 
      if (e === 1) {
        this.state.dataUrl.title = "月度已支付融单";
      } else if (e === 2) {
        this.state.dataUrl.title = "季度已支付融单";
      } else if (e === 3) {
        this.state.dataUrl.title = "近半年已支付融单";
      } else if (e === 4) {
        this.state.dataUrl.title = "近一年已支付融单";
      } else if (e === 5) {
        this.state.dataUrl.title = "全部已支付融单";
      } 
    fetching(url && url.query4+`&dateType=${dateType}`).then(data => {
      if (data !== undefined && data !==null) {
        let datas = data.filter(item => {
          //item.status = item.status ? item.status.name : '';
          item.operator = item.user ? item.user.name : '';
          item.payer = item.circulation ? item.circulation : item.extraction;
          // item.payer = '融单平台';
          //item.role_name = this.queryName(String(item.RoleId), String(item.StatusId), item)
          /*let Flows = item.Flows || item.FinancingFlows || item.CirculationFlows || [];
          if (Flows.length > 0) {
            item.reason = Flows[0].check_opinion
          }*/
          return item;
        })
        
        this.setState({ data: datas });
      }
    })
    } else if ( table === 'r6-4-6') { 
          if (e === 1) {
            this.state.dataUrl.title = "月度已到期融单";
          } else if (e === 2) {
            this.state.dataUrl.title = "季度已到期融单";
          } else if (e === 3) {
            this.state.dataUrl.title = "近半年已到期融单";
          } else if (e === 4) {
            this.state.dataUrl.title = "近一年已到期融单";
          } else if (e === 5) {
            this.state.dataUrl.title = "全部已到期融单";
          } 
        fetching(url && url.query4+`&dateType=${dateType}&dataType=1`).then(data => {
            if (data !== undefined && data !==null) {
                let datas = data.filter(item => {
                  //item.status = item.status ? item.status.name : '';
                  item.operator = item.user ? item.user.name : '';
                  item.payer = item.circulation ? item.circulation : item.extraction;
                  // item.payer = '融单平台';
                  //item.role_name = this.queryName(String(item.RoleId), String(item.StatusId), item)
                  /*let Flows = item.Flows || item.FinancingFlows || item.CirculationFlows || [];
                  if (Flows.length > 0) {
                    item.reason = Flows[0].check_opinion
                  }*/
                  return item;
                })
                
                this.setState({ data: datas });
              }
            })
    } else if ( table === 'r6-4-7') {
      if (e === 1) {
        this.state.dataUrl.title = "月度原始融单";
      } else if (e === 2) {
        this.state.dataUrl.title = "季度原始融单";
      } else if (e === 3) {
        this.state.dataUrl.title = "近半年原始融单";
      } else if (e === 4) {
        this.state.dataUrl.title = "近一年原始融单";
      } else if (e === 5) {
        this.state.dataUrl.title = "全部原始融单";
      } 
      fetchingData = [fetching(url.query4+`&dateType=${dateType}`), fetching(url.query5+`&dateType=${dateType}`)]

        Promise.all(fetchingData).then(values => {
          let data = []
          values.forEach(item => {
            if (item instanceof Array) {
              data = data.concat(item);
            }
          })
          data = data.filter(item => {
            // if (table === 'r6-4-7') {
            //   item.start_day = item.open_day ? item.open_day : item.flow_date ;
            // }
            item.start_day = item.open_day ? item.open_day : item.flow_date ;
            item.status = item.status ? item.status.name : '';
            item.operator = item.user ? item.user.name : '';
            //item.payer = item.circulation ? item.circulation : item.extraction;
            item.payer = item.mx_payer ? item.mx_payer : item.extraction;
            // item.payer = '融单平台';
            item.role_name = this.queryName(String(item.roleId), String(item.status_id), item)
            // let Flows = item.Flows || item.FinancingFlows || item.CirculationFlows || [];
            // if (Flows.length > 0) {
            //   item.reason = Flows[0].check_opinion
            // }
            // if (item.StatusId !== 2 && item.StatusId !== 1) {
            //   return item;
            // } else{
            //   return null;
            // }
            return item;
          })
          this.setState({ data });
        });
    }
  }

  InterestDerivedByFin = () =>{
    let url = `contract/downLoadExcel/getInterestDerivedByFin`
    if (!url) {
      alert('接口为空')
      return;
    }
    window.open(`${DEFAULT_EXPORT_URL}${url}`)
  }

  //查看信息表
  onViewInfo = (e, row) => {
    if (!row.fdd_xxb_path) {
      alert('暂无《善美融单信息单》')
      return;
    }
    window.open(row.fdd_xxb_path)
  }
  //下载信息表
  onUploadInfo = (e, row) => {
    if (!row.fdd_xxb_name) {
      alert('暂无《善美融单信息单》')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_xxb_name}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }
  //下载融资回单
  onUploadRZHD = (e, row) => {
    if (!row.fdd_rzhd_path) {
    //if (!row.fdd_rzhd_path) {
      alert('暂无《善美融资回单》')
      return;
    }else{
      window.open(row.fdd_rzhd_path)
    }
  }
  //下载增值税发票
  onDownloadZZSFP = (e, row) =>{
    const oid=row.id
    // row.type = -1;
    var type = 0;
    const { table } = this.props;
    if (table === 'r6-2-3') {//已开融单
      type = 1;
    } else if (table === 'r6-4-5') {//已支付融单
      type = 2;
    } else if (table === 'r6-3-7') {//已融资融单
      type = 3;
    }
    fetching(`/invoiceVerification/fpyzwDownload?type=${type}&oid=${oid}`,{
      method: 'GET',
    }).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          if(res.data.length>0){
          for(let i = 0; i < res.data.length; i++){
            let imgUrl2 = res.data[i].filePath ? DEFAULT_UPLOADS_URL + res.data[i].filePath : '';
          window.open(imgUrl2)
          }
        }else{
          alert('暂无《增值税发票》')
          return;
        }
        } else {
          alert(`下载失败,${res.msg}`);
        }
      } else {
        alert(`下载失败`);
      }
    })
  }
  //下载融资通知书
  onUploadZRTZS = (e, row) => {
    if (!row.zr_file_path) {
    //if (!row.fdd_rzhd_path) {
      alert('暂无《应收账款转让通知书》')
      return;
   }else{
      window.open(row.zr_file_path)
     }
   }
   
   //下载流转通知书
   onUploadZRTZS1 = (e, row) => {
     if (!row.fdd_file_path || row.fdd_file_path === 'null') {
     //if (!row.fdd_rzhd_path) {
       alert('暂无《应收账款转让通知书》')
       return;
     }else{
       window.open(row.fdd_file_path)
       }
   }
 

    //下载合同
    onUploadContract = (e, row) => {
  if((row.roleId ===9 || row.roleId ===5 || row.roleId ===6)
    && ( row.status_id === 9 || row.status_id === 10 || row.status_id === 11)){  
    if (!row.fdd_file_name || row.fdd_file_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_file_name}`).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
} else{
  alert('暂无合同')
  return;
}
}


   //下载放款回单
 handleUpload = (e, row) => {
   try { 
   let type = 40;//中金/清算分账回单类型(订死的)
   if (!row.serialNumber || row.serialNumber === 'null') {
     alert('暂无回单')
     return;
   }  
   fetching(`/loan/getReceipt?txSn=${row.serialNumber}&type=${type}`,{
     method: 'GET',
   }).then(res => {
//debugger
     if (res !== undefined && res.data !== null && res.data !== undefined && res.data !== "") {
       if(JSON.parse(res.data).data.status === null || JSON.parse(res.data).data.status === '20'){
         alert(`下载失败,`+ JSON.parse(res.data).data.responseMessage)
       } else {
       //window.open(res.data)
       window.open(JSON.parse(res.data).data.downloadUrl)
       }
     } else {
       alert(`下载失败,请联系管理员`)
     }
   })
     } catch (error) { }
   
 }

 //下载还款回单
 handleUpload1 = (e, row) => {
  try { 
  let url = "";
  if (!row.orderNo || row.orderNo === 'null') {
    alert('暂无回单')
    return;
  }  
  if(!row.paId || row.paId === "null"){
   let type = 40;//中金/清算支付回单类型(订死的)
   url = `/loan/getrRepaymentReceipt?txSn=${row.serialNumber}&type=${type}&orderNo=${row.orderNo}`
  } else {
  let type = 40;//中金/清算分账回单类型(订死的)
   url = `/loan/getrRepaymentReceipt?txSn=${row.serialNumber}&type=${type}&paId=${row.paId}&orderNo=${row.orderNo}&id=${row.id}`
  } 
  fetching(url,{
    method: 'GET',
  }).then(res => {
    if (res !== undefined && res.data !== null && res.data !== undefined && res.data !== "") {
      if (res.data.data.length !== undefined) {
        let datas = res.data.data.filter(item => {
          if (item.data.status === '20') {
            alert(`下载失败,`+ item.data.responseMessage)
          } else {
            window.open(item.data.downloadUrl)
          }
          return null;
        })
      } else {
        if (res.data.data.status === '20') {
          alert(`下载失败,`+ res.data.data.responseMessage)
        } else {
          window.open(res.data.data.downloadUrl)
        }
      }
      
    } else {
      alert(`下载失败,请联系管理员`)
    }
  })
    } catch (error) { }
  
}

  //触发打开时间段选项弹窗--20201117
  exportTableYWD = (e) => {
    const { exercise, form2, dataUrl } = this.state;
    //open: true--详情页；checkOpen: true--公共表单
    //type: 1-后端/全部；2-前端/指定公司
    this.setState({
      exercise: { ...exercise, save: '3', DialogTitle: form2.exportTable, open: false, tableName: dataUrl.name, status2: '1', checkFlag: true, type: 1 }
    })
  }

   //触发利息摊销文件上传弹窗--20201117
   exportTableLXTX = (e) => {
    const { exercise, form2, dataUrl } = this.state;
    this.setState({
      exercise: { ...exercise,
        save: '5',
        DialogTitle: form2.exportInterestDerived,
        open3: true, 
        tableName: dataUrl.name,
        status2: '1',
        checkFlags: true,
        type: 1 
      }
    })
    }

  handleTogglefpyz = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open1: false }
      })
    //window.location.reload()
    } else {
      this.setState({
        exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '1', open1: true }
      })
    }
  }

  //触发打开时间段选项弹窗--20201117
  handleToggleYWD = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open: false }
      })
    window.location.reload()
    } else {
      this.setState({
        exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
      })
    }
  }

   //触发打开时间段选项弹窗--20201117
   handleToggleLXTX  = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open3: false }
      })
    window.location.reload()
    } else {
      
      this.setState({
        exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '5', open3: true,isBtn:false }
      })
    }
  }


  

  //发票验真
  onCheckInvoice = (e, row) => {
    //等待转圈--20201229
    if (this.state.setLoading) {
      alert(`发票验真中，请勿重复点击！`)
      return;
    }
    this.setState({setLoading:true});//等待转圈--20201229

    //this.setState({ loading: true });//等待--20201210
    const { table } = this.props;
    const oid = row.id;
    //type--1:开立；2：流转；3：融资
    var type = 0;
    if (table === 'r6-2-1' || table === 'r6-2-3' || table === 'r6-2-4') {//融单开立审核
      type = 1;
    } else if (table === 'r6-4-1' || table === 'r6-4-5' || table === 'r6-4-8') {//融单流转审核
      type = 2;
    } else if (table === 'r6-3-1' || table === 'r6-3-6' || table === 'r6-3-7') {//融资申请审核
      type = 3;
    }
    /*fetching(`/invoiceVerification/fpyzw?type=${type}&oid=${oid}`).then(res => {
      if (res.code === 200) {
        if (res.data.length == 0) {
          alert(`发票验证信息全部通过`)
        } else if (res.data.length > 0) {
          alert(`发票验证不合格信息共计：[` + res.data.length + `]条,详情请见发票验真不合格列表！`)
        } else {
          alert(res.msg)
        }
      } else {
        alert(`失败,${res.msg}`)
      }
    })*/

    fetching(`/invoiceVerification/getInvoiceVerificationList?type=${type}&oid=${oid}`).then(res => {
      
      if (res !== undefined && null != res.invoice_all_list && res.invoice_all_list.length > 0) {
        //this.setState({ loading: false });//等待--20201210
        this.setState({setLoading:false});//等待转圈--20201229
        //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
        alert(`本单已经进行过发票验真，请直接查看验真结果！`)
      } else {
        fetching(`/invoiceVerification/fpyzw?type=${type}&oid=${oid}`).then(res => {
          //this.setState({ loading: false });//等待--20201210
          this.setState({setLoading:false});//等待转圈--20201229
          if(res !== undefined){
          if (res.code === 200) {
            if (res.data.length > 0) {
              alert(`发票验证不合格信息共计：[` + res.data.length + `]条,详情请见发票验真不合格列表！`)
            } else {
              alert(res.msg)
            }
          } else {
            alert(`失败,${res.msg}`)
          }
        } else {
          alert("发票验真网络连接超时,请稍后重试或请联系管理员处理")
        }
        })
      }
    })

  }


  //发票验真伪的结果list用弹窗展示
  //1.发票验真结果列表
  onShowListAll = (e, row) => {
    let DialogTitle = "发票验真结果列表";
    const { table } = this.props;
    if (null == row.invoice_all_list || row.invoice_all_list.length === 0) {//status_id=${status_id}&role_id=${role_id}
      const oid = row.id;
      //type--1:开立；2：流转；3：融资
      var type = 0;
      if (table === 'r6-2-1' || table === 'r6-2-3' || table === 'r6-2-4') {//融单开立审核||已开融单
        type = 1;
      } else if (table === 'r6-4-1' || table === 'r6-4-5' || table === 'r6-4-8') {//融单流转审核
        type = 2;
      } else if (table === 'r6-3-1' || table === 'r6-3-6' || table === 'r6-3-7') {//融资申请审核
        type = 3;
      }
      fetching(`/invoiceVerification/getInvoiceVerificationList?type=${type}&oid=${oid}`).then(res => {
        if (res !== undefined && null != res.invoice_all_list && res.invoice_all_list.length > 0) {
          console.log('调用发票验真结果查看方法！！！！')
          //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
          this.setState({
            exercise: { 
              save: '3', DialogTitle,
              open1: true,
              status2: '1',
               //checkFlag: true, 
               type: 1,
              InvoiceList: res.invoice_all_list || []
            }
          })
        } else {
          alert(`尚未进行发票验真伪功能！`)
        }
      })
    } else {
      this.setState({
        exercise: { 
          save: '3', DialogTitle,
          open1: true,
          status2: '1', checkFlag: true, type: 1,
          InvoiceList: row.invoice_all_list || []
        }
      })
    }
  }
  //2.发票验真不合格列表
  onShowListNo = (e, row) => {
    let DialogTitle = "发票验真不合格列表";
    const { table } = this.props;
    if (null == row.invoice_all_list || row.invoice_all_list.length == 0) {//status_id=${status_id}&role_id=${role_id}
      const oid = row.id;
      //type--1:开立；2：流转；3：融资
      var type = 0;
      if (table === 'r6-2-1' ||table === 'r6-2-3' || table === 'r6-2-4') {//融单开立审核
        type = 1;
      } else if (table === 'r6-4-1' || table === 'r6-4-5' || table === 'r6-4-8') {//融单流转审核
        type = 2;
      } else if (table === 'r6-3-1' || table === 'r6-3-6' || table === 'r6-3-7') {//融资申请审核
        type = 3;
      }
      fetching(`/invoiceVerification/getInvoiceVerificationList?type=${type}&oid=${oid}`).then(res => {
        if (res !== undefined && null != res.invoice_all_list && res.invoice_all_list.length > 0) {
          //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
          this.setState({
            exercise: { 
              save: '3', DialogTitle,
              open: false,
              status2: '1',
              checkFlag: false, 
              open1 :true,
               type: 9,
              InvoiceList: res.invoice_no_list || []
            }
          })
        } else {
          alert(`尚未进行发票验真伪功能！`)
        }
      })
    } else {
      this.setState({
        exercise: { 
          save: '3', DialogTitle,
          open1: false,
          status2: '1', checkFlag: true, type: 9,
          InvoiceList: row.invoice_no_list || []
        }
      })
    }
  }

  // onAmortize = (e,row) => {
  //   const id = row.id;
  //   var amortizeid =0;
  //   if(row.amortize ==='摊销'){
  //     amortizeid = 0;
  //   }else if(row.amortize === '不进行摊销'){
  //     amortizeid = 1;
  //   }
  //   fetching(`/msapi/financing/uploadOnAmortize?id=${id}&amortizeid=${amortizeid}`).then(res => {
  //     alert(res.data)
  //   })

  // }

    FormSubmit4 = (newData) => {
        newData.formDate = new Date();
        setTimeout(() => {
          if (newData.submit_name !== 'cancle') {
            this.onQuery2();
          }
          this.setState({
            exercise: { ...this.state.exercise, open: false, checkOpen: false, applyOpen:false  }
          })
        }, 500)
      }
//北金所弹框按钮
   onBfeMsgIn = (e,row) => {
        const { exercise, form2, dataUrl } = this.state;
         this.setState({
          exercise: { ...exercise,
          ...row,
           save: '1',
           DialogTitle: form2.bfeMsgIn,
           openbfe: true, 
           tableName: dataUrl.name,
           status2: '1',
           checkFlags: true,
           type: 1 
           }
          })
        }

     //北金所弹框时间选择
  handleTogglebfeMsg = (open) => {
      const { form2 } = this.state
      if (open) {
        this.setState({
          exercise: { openbfe: false }
        })
      window.location.reload()
      } else {
        this.setState({
          exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '5', openbfe: true,isBtn:false }
        })
      }
    }


  render() {
    const { columns, data, dataUrl, exercise, auth, opinion_list, params,setLoading, } = this.state;
    const title = dataUrl.title || '';
    const { table = '' } = this.props;
    const path = capitalize(table);
    const actions = actions2(this, table, auth);
    const editable = editable2(this, table, auth);

     //20201229
     let rolesId = auth.roles ? auth.roles[0].id : 0;

     //InvoiceListForm, InvoiceDialogForm
     //发票验真伪结果list展示弹窗--20201126
     const InvoiceList = () => {
       let isBtn = false;
       return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
         {/* <InvoiceDialogForm handleToggle={this.handleToggleYWD} */}
         <InvoiceDialogForm handleToggle={this.handleTogglefpyz}
         exercise={{ ...exercise, addBtnName: common.fpyzResult, isBtn }}>
           <InvoiceListForm exercise={{ ...exercise }} />
         </InvoiceDialogForm>
       </div>
     }
     //导出按钮--20201117
     const C6 = () => {
       return <div style={{ textAlign: 'end', marginBottom: '10px' }}>
         <Button variant="contained" color="primary" onClick={this.exportTableYWD}>导出</Button>
       </div>
     }
     const T2 = () => {
      return <div style={{ textAlign: 'end', marginBottom: '10px' }}>
         <Button variant="contained" color="primary" onClick={this.exportTableLXTX}>利息摊销导出</Button>
       </div>
    }

     //--20210812
    const T1 = () => {
      return <div style={{ textAlign: 'start', marginBottom: '10px' }}>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button style={{ marginRight: '10px',backgroundColor: 'rgb(29, 69, 131)'}} variant="contained" color="primary" onClick={() => this.QueryByPeriod(1)}>月度</Button>
        <Button style={{ marginRight: '10px',backgroundColor: 'rgb(29, 69, 131)'}} variant="contained" color="primary" onClick={() => this.QueryByPeriod(2)}>季度</Button>
        <Button style={{ marginRight: '10px',backgroundColor: 'rgb(29, 69, 131)'}} variant="contained" color="primary" onClick={() => this.QueryByPeriod(3)}>近半年</Button>
        <Button style={{ marginRight: '10px',backgroundColor: 'rgb(29, 69, 131)'}} variant="contained" color="primary" onClick={() => this.QueryByPeriod(4)}>近一年</Button>
        <Button style={{ backgroundColor: 'rgb(29, 69, 131)'}} variant="contained" color="primary" onClick={() => this.QueryByPeriod(5)}>所有</Button>
        </ButtonGroup>
      </div>
    }
 
     //中登弹窗
     const ZDRegister = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}> 
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName3, isBtn }}>
          <ZDForm auth={auth} form2={{ ...common, ...zd }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
     }

     //时间段选择弹窗--20201117
     const DownloadTime = () => {
       let isBtn = false;
        return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
          <DialogForm handleToggle={this.handleToggleYWD} exercise={{ ...exercise, addBtnName: common.exportTable, isBtn }}>
            <DownloadTimeForm form2={{ ...common }} exercise={{ ...exercise }} onSubmit={this.FormSubmit} />
          </DialogForm>
       </div>
     }
     //摊销利息导出台账
     const InterestDerived =() => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <InterestDerivedDialogForm handleToggle={this.handleToggleLXTX} exercise={{ ...exercise, addBtnName: common.exportTable, isBtn }}>
          <InterestDerivedForm form2={{ ...common }} exercise={{ ...exercise }} onSubmit={this.FormSubmit} />
        </InterestDerivedDialogForm>
     </div>
     }
       //北金所
    const BfeMsgInput =() => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}> 
        <DialogbfeMsg handleToggle={this.handleTogglebfeMsg}
          exercise={{ ...exercise, addBtnName: common.addBtnName3, isBtn }}>
          <BfeMsgInputForm form2={{ ...common }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit4} />
        </DialogbfeMsg>
      </div>
      }
 
    //公共表单
    const C21 = () => {
      return <CommonDialog handleToggle={this.handleCommon}
        exercise={{ ...exercise, addBtnName: '公共表单', isBtn: false, }}>
        <Form1 auth={auth} opinion_list={opinion_list} exercise={{ ...exercise, table }}
          onSubmit={this.FormSubmit3} />
      </CommonDialog>
    }

	const C22 = () => {
      return <CommonDialog handleToggle={this.handleCommon}
        exercise={{ ...exercise, addBtnName: '公共表单', isBtn: false, }}>
        <CheckBoxDownForm auth={auth} opinion_list={[]} exercise={{ ...exercise, table }}
          onSubmit={this.FormSubmit3} />
      </CommonDialog>
    }

   
    //融单开具
    const C23 = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName1, isBtn }}>
          <C23Form auth={auth} form2={{ ...common, ...f2_3 }} exercise={{ ...exercise, ...params }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }
        //开立合计
    const C24= () => {     
      return (
      <div>
          <C42ViewList state={this.state} onRef={(ref)=> {this.$Child=ref}}>
          </C42ViewList>
      </div>
      )
      }
    //融单流转
    const C318 = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName2, isBtn }}>
          <C318Form auth={auth} form2={{ ...common, ...f3_1_8 }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }
    //融单融资
    const C321 = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName3, isBtn }}>
          <C321Form auth={auth} form2={{ ...common, ...f3_2_1 }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

// 原始融单批量下载
    const T4 = () => {
      return <div style={{ textAlign: 'start', marginBottom: '10px' }}>
        <ButtonGroup size="small" aria-label="small outlined button group">
          <Button style={{ marginRight: '10px', backgroundColor: 'rgb(29, 69, 131)' }} variant="contained" color="primary" onClick={() => this.onCheck3()}>批量下载</Button>
        </ButtonGroup>
      </div>
    }

    return <div>
      {rolesId === 3 && setLoading && <CircularProgress />}
      {(table === 'r6-1'   || table === 'r6-2-3' || table === 'r6-3-7' || table === 'r6-4-2' || table === 'r6-4-5' || table === 'r6-4-6' || table === 'r6-4-7') && <T1></T1>}
      {(table === 'r6-3-1' || table === 'r6-4-1' || table === 'r6-2-1' || table === 'r6-2-3' || table === 'r6-2-4'  || table === 'r6-3-6' || table === 'r6-3-7' || table === 'r6-4-5' || table === 'r6-4-8') && <InvoiceList></InvoiceList>}
      {(table === 'r6-3-7' || table === 'r6-2-2' || table === 'r6-2-3' || table === 'r6-4-2' || table === 'r6-4-3' || table === 'r6-4-5' || table === 'r6-4-6' 
      || table === 'r6-4-7') && <C6></C6>}
      {(table === 'r6-3-7' || table === 'r6-2-2' || table === 'r6-2-3' || table === 'r6-4-2' || table === 'r6-4-3' || table === 'r6-4-5' || table === 'r6-4-6' 
      || table === 'r6-4-7') && <DownloadTime></DownloadTime>}
       {(table ==='r6-3-7' && rolesId === 6 && auth.organization.name === "融单平台") && <T2></T2>}
      {(table ==='r6-3-1' && rolesId === 16 ) && <BfeMsgInput></BfeMsgInput>}
      {(table ==='r6-3-7' && rolesId === 6 ) && <InterestDerived></InterestDerived>}
      {(table === 'r6-2-1' || table === 'r6-2-2' || table === 'r6-2-4' ||
        table === 'r6-3-1' || table === 'r6-4-1' ||
        table === 'r6-3-6' || table === 'r6-4-8'
        || table === 'r6-3-7' || table === 'r6-2-3' || table === 'r6-4-5' || table === 'r6-3-15'
        || table === 'r6-3-8' || table === 'r6-4-9' || table === 'r6-2-6'  || table === 'r6-4-12'
        || table === 'r6-3-9' || table === 'r6-4-10' || table === 'r6-2-7' || table === 'r6-2-9'
      ) && <C21></C21>}
      {(table === 'r6-2-6' || table === 'r6-2-7' ||
        table === 'r6-2-1' || table === 'r6-2-2' ||
        table === 'r6-2-3' || table === 'r6-2-4' || table === 'r6-2-9' ||
        table === 'r6-2-5' || table === 'r6-4-6') && <C23></C23>}
      {(table === 'r6-3-8' || table === 'r6-3-9' || table === 'r6-3-15' ||
        table === 'r6-3-6' || (table === 'r6-3-1' && rolesId !== 9) || table === 'r6-3-2' ||
        table === 'r6-3-3' || table === 'r6-3-4' || 
        table === 'r6-3-5' || table === 'r6-3-7') && <C321></C321>}
      {(table === 'r6-4-9' || table === 'r6-4-10' ||
        table === 'r6-4-1' || table === 'r6-4-2' || table === 'r6-4-12'||
        table === 'r6-4-3' || table === 'r6-4-4' || table === 'r6-4-8' ||
        table === 'r6-4-5' || table === 'r6-6-5'
      ) && <C318></C318>}
      {(table === 'r6-3-1' && rolesId === 9) && <ZDRegister></ZDRegister>}
      <MaterialTable
        title={title}
        onSearchChange={this.handleSearchChange}
        onOrderChange={this.handleSort}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        path={path}
        columns={columns}
        data={data}
        actions={actions}
        editable={editable}
        localization={localization}
        options={options(table)}
      />
       {(table === 'r6-2-3') && <C24></C24>}
    </div>
  }
}
const actions2 = (that, name, auth) => {
  let actions = [];
  const { roles = [{ id: 0 }] } = auth;
  let roleId = roles[0].id;
  switch (name) {
    case 'r6-2-1':
      if (roleId === 1) {
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          // { icon: Backup, tooltip: '退回', onClick: that.onCheck2 },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
          { icon: QueryBuilder, tooltip: '中登查询', onClick: that.onZDQuery },
          { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
          { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
          { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
        ];
      } else if(roleId === 3){
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          // { icon: Backup, tooltip: '退回', onClick: that.onCheck2 },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
          { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
          { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
          { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
        ];
      } else {
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        ];
      }
      /*actions = [
        { icon: 'save', tooltip: '审核', onClick: that.onCheck },
        // { icon: Backup, tooltip: '退回', onClick: that.onCheck2 },
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        rowData => ({
          icon: EventNote,
          tooltip: '发票验真结果列表',
          onClick: that.onShowListAll,
          hidden: ( roleId != 3 )
          //hidden: ( null == rowData.invoice_all_list || rowData.invoice_all_list.length == 0  || rowData.role_id !== 3 )
        }),
        rowData => ({
          icon: EventBusy,
          tooltip: '发票验真不合格列表',
          onClick: that.onShowListNo,
          hidden: ( roleId != 3 )
          //hidden: ( null == rowData.invoice_no_list || rowData.invoice_no_list.length == 0  || rowData.role_id !== 3 )
        }),
        rowData => ({
          icon: EventAvailable,
          tooltip: '发票验真',
          onClick: that.onCheckInvoice,
          hidden: ( roleId != 3 )
          //hidden: ( null == rowData.invoice_no_list || rowData.invoice_no_list.length == 0  || rowData.role_id !== 3 )
        }),
      ];*/
      if (roleId === 9) {
        actions.splice(1, 1);
        actions[0].tooltip = '签章'
      }

      break;
    case 'r6-2-2':
      if ( roleId === 1) {
      actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview },
      { icon: 'save', tooltip: '退回操作', onClick: that.onCheck },
    ];
  } else {
    actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
  }
      break;
    case 'r6-2-3':
      actions = [{ icon: ViewComfy, tooltip: '编辑/查看', onClick: that.onview },
      { icon: AccountTree, tooltip: '融单流转树形图', onClick: that.onTree },
      //{ icon: CloudDownload, tooltip: '增值税发票下载', onClick: that.onDownloadZZSFP },
      { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
      { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
      { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
      /*{ icon: CloudDownload, tooltip: '上传补充资料', onClick: that.onTree },*/
      //{ icon: Info, tooltip: '审批导出', onClick: that.export }
    ];//20200929
      break;
    case 'r6-2-4':
      if(roleId === 16){
        actions = [
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
          { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
          { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
          { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
        ];
      } else {
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          // { icon: Backup, tooltip: '退回', onClick: that.onCheck2 },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
          { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
          { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
          { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
        ];
      }
      break;
    case 'r6-2-9':
      if (roleId === 1) {
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        ]
      } else {
        actions = [
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        ]
      }
      break;
    case 'r6-3-1':
      if (roleId === 3 || roleId === 1 ) {
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
          { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
          { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
          { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
        ];
      } else if (roleId === 9) {
        if(auth.organization.name === "融单平台"){
          actions = [
            { icon: 'save', tooltip: '审核', onClick: that.onCheck },
            { icon: Receipt, tooltip: '中登登记', onClick: that.onZDRegister},
            { icon: QueryBuilder, tooltip: '中登查询', onClick: that.onZDQuery },
          ];
        }else {
          actions = [
            { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          ];
        }
      } else if (roleId === 16 && auth.organization.name === "融单平台") {
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
          { icon: QueryBuilder, tooltip: '中登查询', onClick: that.onZDQuery },
          { icon: Input, tooltip: '北金所信息录入', onClick: that.onBfeMsgIn },
          { icon: InsertChart, tooltip: '光大客户基础信息推送', onClick: that.sendCEBBasicInform },
          { icon: InsertChartOutlinedOutlined, tooltip: '北京银行客户基础信息推送', onClick: that.sendBobBasicInform },
        ];
      } else {
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        ];
      }
      /*actions = [
        { icon: 'save', tooltip: '审核', onClick: that.onCheck },
        // { icon: Backup, tooltip: '退回', onClick: that.onCheck2 },
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        rowData => ({
          icon: EventNote,
          tooltip: '发票验真结果列表',
          onClick: that.onShowListAll,
          hidden: ( roleId != 3 )
          //hidden: ( null == rowData.invoice_all_list || rowData.invoice_all_list.length == 0  || rowData.role_id !== 3 )
        }),
        rowData => ({
          icon: EventBusy,
          tooltip: '发票验真不合格列表',
          onClick: that.onShowListNo,
          hidden: ( roleId != 3 )
          //hidden: ( null == rowData.invoice_no_list || rowData.invoice_no_list.length == 0  || rowData.role_id !== 3 )
        }),
        rowData => ({
          icon: EventAvailable,
          tooltip: '发票验真',
          onClick: that.onCheckInvoice,
          hidden: ( roleId != 3 )
          //hidden: ( null == rowData.invoice_no_list || rowData.invoice_no_list.length == 0  || rowData.role_id !== 3 )
        }),
      ];*/
        if (roleId === 6 || roleId === 9) {
          //actions.splice(1, 1);
          actions[0].tooltip = roleId === 9 ? '签章' : '放款';
        } else if (roleId === 5) {//rdcw1增加查看功能--20200827
          actions[0].tooltip = '放款';
          actions[1].tooltip = '查看';
        } else if (roleId === 1) {
          actions[1].tooltip = '编辑/查看';
        } 
      break;
    case 'r6-3-2':
      actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'r6-3-3':
      actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'r6-3-4':
      actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview },
	{ icon: CloudUpload, tooltip: '下载保理协议', onClick: that.onUploadContract },];
      break;
    case 'r6-3-5':
      actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'r6-3-7':
      if(roleId !== 6){
      actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview },
      { icon: AccountTree, tooltip: '融单流转树形图', onClick: that.onTree },
      { icon: CloudUpload, tooltip: '下载', onClick: that.onUploadRZHD },
      { icon: CloudDownload, tooltip: '通知书下载', onClick: that.onUploadZRTZS },
      //{ icon: CloudDownload, tooltip: '增值税发票下载', onClick: that.onDownloadZZSFP},
      { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
      { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
      { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
    ];//20200929
  }else if(roleId === 6)  {
    actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview },
    { icon: AccountTree, tooltip: '融单流转树形图', onClick: that.onTree },
    { icon: CloudUpload, tooltip: '下载', onClick: that.onUploadRZHD },
    { icon: CloudDownload, tooltip: '通知书下载', onClick: that.onUploadZRTZS },
    //{ icon: CloudDownload, tooltip: '增值税发票下载', onClick: that.onDownloadZZSFP},
    { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
    { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
    { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
    // { icon: DomainSharp ,tooltip:'是否摊销',onClick: that.onAmortize},
  ];
  }
      break;
    case 'r6-3-6':
      actions = [
        { icon: 'save', tooltip: '审核', onClick: that.onCheck },
        // { icon: Backup, tooltip: '退回', onClick: that.onCheck2 },
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
        { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
        { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
        ];
        if (roleId === 1) {
          actions[1].tooltip = '编辑/查看';
        }
      break;

    case 'r6-3-8'://融资退回经办人的业务板块功能--20201030
        actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
        break;
    case 'r6-3-10'://业务端下载放款回单
        actions = [{ icon: CloudDownload, tooltip: '下载放款支付回单', onClick: that.handleUpload }];
        break;
    case 'r6-3-11'://业务端下载回款回单
        actions = [{ icon: CloudDownload, tooltip: '下载融资还款支付回单', onClick: that.handleUpload1 }];
        break;
    case 'r6-3-15':
      if (roleId === 1) {
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        ]
      } else {
        actions = [
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        ]
      }
      break;
    case 'r6-4-9'://流转退回经办人的业务板块功能--20201030
        actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
        break;
    case 'r6-2-6'://开立退回经办人的业务板块功能--20201030
        actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
        break;
    case 'r6-3-9'://融资拒签业务板块功能--20201120
        actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
        break;
    case 'r6-4-10'://流转拒签业务板块功能--20201120
        actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
        break;
    case 'r6-2-7'://开立拒签业务板块功能--20201120
        actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
        break;
    case 'r6-4-1':
      if (roleId === 3 || roleId === 1) {
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
          { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
          { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
          { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
        ];
      } else {
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        ];
      }
      /*actions = [
        { icon: 'save', tooltip: '审核', onClick: that.onCheck },
        // { icon: Backup, tooltip: '退回', onClick: that.onCheck2 },
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        rowData => ({
          icon: EventNote,
          tooltip: '发票验真结果列表',
          onClick: that.onShowListAll,
          hidden: ( roleId != 3 )
          //hidden: ( null == rowData.invoice_all_list || rowData.invoice_all_list.length == 0  || rowData.role_id !== 3 )
        }),
        rowData => ({
          icon: EventBusy,
          tooltip: '发票验真不合格列表',
          onClick: that.onShowListNo,
          hidden: ( roleId != 3 )
          //hidden: ( null == rowData.invoice_no_list || rowData.invoice_no_list.length == 0  || rowData.role_id !== 3 )
        }),
        rowData => ({
          icon: EventAvailable,
          tooltip: '发票验真',
          onClick: that.onCheckInvoice,
          hidden: ( roleId != 3 )
          //hidden: ( null == rowData.invoice_no_list || rowData.invoice_no_list.length == 0  || rowData.role_id !== 3 )
        }),
      ];*/
      break;
    case 'r6-4-2':
      // actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'r6-4-3':
      actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'r6-4-4':
      actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'r6-4-5':
      actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview },
      { icon: AccountTree, tooltip: '融单流转树形图', onClick: that.onTree },
      { icon: CloudDownload, tooltip: '通知书下载', onClick: that.onUploadZRTZS1 },
      //{ icon: CloudDownload, tooltip: '增值税发票下载', onClick: that.onDownloadZZSFP },
      { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
      { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
      { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
    ];//20200929
      break;
    case 'r6-4-6':
      actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'r6-4-7':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onViewInfo },
        //{ icon: CloudUpload, tooltip: '下载', onClick: that.onUploadInfo }
        { icon: CloudDownload, tooltip: '下载', onClick: that.onUploadInfo }//替换图表20200923
      ];
      break;

    case 'r6-4-8':
      actions = [
        { icon: 'save', tooltip: '审核', onClick: that.onCheck },
        // { icon: Backup, tooltip: '退回', onClick: that.onCheck2 },
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
        { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
        { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
    ];
      break;
    case 'r6-4-12':
      if (roleId === 1) {
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        ]
      } else {
        actions = [
          { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        ]
      }
      break;
    default:
      actions = [];
  }
  return actions;
}
const editable2 = (that, name, auth) => {
  let editable = {};
  const { roles = [{ id: 0 }] } = auth;
  let roleId = roles[0].id;
  switch (name) {
    case 'm1':
      editable = { onRowAdd: that.onAdd, };
      break;
    case 'r6-3-13':
      if(roleId === 1)
      editable = { onRowDelete: that.onDelete, onRowAdd: that.onAdd, onRowUpdate: that.onUpdate };
      break;
    default:
    // editable = { onRowDelete: that.onDelete };
  }
  return editable;
}
const localization = {
  header: {
    actions: '操作'
  }
}
const options = (table) => {
  const options = {
    actionsCellStyle: { padding: '0 10px' },
    headerStyle: { //表格头部
      backgroundColor: '#1d4583',
      color: '#FFF',
      whiteSpace: 'nowrap'
    },
    rowStyle: { //表行
      backgroundColor: '#fff',
      whiteSpace: 'nowrap'
    },
    sorting: true,  //排序
    // selection: true //多选框
  }
  /*if (table === 'r6-3-7') {
    options.exportButton = true //导出
  }*/
  return options;

}
const setView = Comp => (collection, name) => {
  return class extends Component {
    render() {
      return <Comp table={collection} tableName={name} {...this.props} />
    }
  }
}

export const SetView = setView(View);
