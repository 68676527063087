import Dialog from './Dialog'
import C23Form from './C2_3Form'
import C318Form from './C3_1_8Form'
import C321Form from './C3_2_1Form'
import C41Form from './C4_1Form'//还款弹窗--20201218
import C51Form from './C5_1Form'
import C52Form from './C5_2Form'
import C1451Form from './C14_5_1Form'
import C1452Form from './C14_5_2Form'
import accountForm from  './accountForm'
import C61Form from './C6_1Form' //修改银行信息的弹出框
import dataAnalysisForm from  './dataAnalysisForm'
import ZDForm from './ZDForm'
import C42ViewList from './C42View'
import C72Form from './C7_2Form'
import C751Form from './C7_5_1Form'
import C1471Form from './C14_7_1Form'
import C12CEBForm from './C1_2CEBForm'
import riskForm from  './riskForm'
import riskManagementForm from './riskManagementForm'



export {
  Dialog,
  C23Form,
  C318Form,
  C321Form,
  C41Form,//还款弹窗--20201218
  C51Form,
  C52Form,
  C1451Form,
  C1452Form,
  accountForm,
  C61Form, //修改银行信息的弹出框
  dataAnalysisForm,
  ZDForm,
  C42ViewList,
  C72Form,
  C751Form,
  C1471Form,
  C12CEBForm,
  riskForm,
  riskManagementForm,
}
