import React, { Component } from 'react';
import {
  Delete,
  ViewComfy,
} from '@material-ui/icons'
import MaterialTable from '../../vendors/material-table';
import { defer, capitalize, fetching } from '../../config/utils';
import { core_business_url } from './core_business_url';
import { C111Form, C112Form, C116Form, C117Form, C12Form, C13Form, C14Form, C15Form, Dialog } from './index'
import { common, c1_1_1, c1_1_2, c1_1_6, c1_1_7,c1_2, c1_3, c1_5 } from '../formStatic'
import { DownloadTimeForm, DialogForm } from '../../02-core_business/downloadTime';
import { loginInfos } from '../../components/numToText'
import { Button, ButtonGroup } from '@material-ui/core';
export const BASE = '/customer-info';

class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [{}],
      auth: {},//登录信息
      data: [],
      dataUrl: { url: { query: '' } },
      exercise: {},
      form2: { ...common },
      hasToken: sessionStorage.getItem("authToken")
    };
  }

  componentDidMount() {
    const { table } = this.props;
    let auth = loginInfos();
    this.setState({ auth });
    fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {
        const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
        //根据不同的页面，获取不同的接口地址，渲染不同的数据
        const dataUrl = core_business_url(auth, table).find(item => item.name === table);
        const dataUrl2 = dataUrl || {};
        this.setState({ columns, dataUrl: dataUrl2 });
        if (dataUrl2.url && auth.roles[0].id === 1) {
          dataUrl.url.query !== '' && this.onQuery();
        } else if (dataUrl2.url) {
          dataUrl.url.query !== '' && this.onQuery2();
        }
      });
  }

  //项目经理查询数据
  onQuery = () => {
    const { url ,name} = this.state.dataUrl;
    if (name ==='c1-1-1' || name === 'c1-1-8') {
      this.QueryByPeriod(1)
      return;
    }
    fetching(url.get).then(data => {
      try {
        if (name === "c1-5") { 
         let datas =  data.filter(item => { 
         if (item.status === "1") {
            item.status = "正常";
         } else {
            item.status = "已过期";
         }
         return item;
         })
          this.setState({ data: datas });
        } else if (name === "c1-1-7"){
          let datas =  data.filter(item => { 
            if (item.internal === 1) {
               item.internal = "内部用户";
            } else {
               item.internal = "外部用户";
            }
            return item;
            })
          this.setState({ data: datas });
        } else {
          this.setState({ data: data });
        }
        
      } catch (error) {
      }
    })
  }
  //其他角色查询数据
  onQuery2 = () => {
    const { url ,name} = this.state.dataUrl;
    fetching(url.get).then(data => {
      try {
        if (name === "c1-5") {
          let datas =  data.filter(item => { 
          if (item.status === "1") {
             item.status = "正常";
          } else {
             item.status = "已过期";
          }
          return item;
          })
           this.setState({ data: datas });
	  } else if (name === "c1-1-7"){
          let datas =  data.filter(item => { 
            if (item.internal === 1) {
               item.internal = "内部用户";
            } else {
               item.internal = "外部用户";
            }
            return item;
            })
          this.setState({ data: datas });
         } else {
           this.setState({ data: data });
         }
      } catch (error) {
      }
    })
  }
 //按时间段进行数据查询
  QueryByPeriod = (e) => {
    const { table } = this.props;
    const { url } = this.state.dataUrl;
    let dateType = e; // e = 1 月度 2 季度 3半年 4 一年 5 所有
    if (table === 'c1-1-8') {
      if (e === 1) {
        this.state.dataUrl.title = "月度企业管理员账号";
      } else if (e === 2) {
        this.state.dataUrl.title = "季度企业管理员账号";
      } else if (e === 3) {
        this.state.dataUrl.title = "近半年企业管理员账号";
      } else if (e === 4) {
        this.state.dataUrl.title = "近一年企业管理员账号";
      } else if (e === 5) {
        this.state.dataUrl.title = "全部企业管理员账号"; 
      } 
      fetching(url.get+`?dateType=${e}`).then(data => {
            this.setState({ data: data });
      })
    }else  if (table === 'c1-1-1' ) {
      if (e === 1) {
        this.state.dataUrl.title = "月度客户概况";
      } else if (e === 2) {
        this.state.dataUrl.title = "季度客户概况";
      } else if (e === 3) {
        this.state.dataUrl.title = "近半年客户概况";
      } else if (e === 4) {
        this.state.dataUrl.title = "近一年客户概况";
      } else if (e === 5) {
        this.state.dataUrl.title = "全部客户概况"; 
      } 
      fetching(url.get+`?dateType=${dateType}`).then(data => {
        if (data !== undefined && data !==null) {
          let datas = data.filter(item => { 
            item.status = item.status ? item.status.name : '';
            item.operator = item.user ? item.user.name : '';
            item.payer = item.circulation ? item.circulation : item.extraction;
            return item;
          
          })
          this.setState({ data: datas });
        }
      })
          
    }
  }
  //搜索框Change
  onSearchChange = (query) => {
    console.log('onSearchChange', query);
  }
  //页面更改事件
  onChangePage = (query) => {
    console.log('onChangePage', query);
  }
  //处理每页的行更改（pageSize）
  onChangeRowsPerPage = (query) => {
    console.log('onChangeRowsPerPage', query);
  }

  //新增
  handleToggle = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open: false }
      })
    } else {
      this.setState({
        exercise: { status2: '1', DialogTitle: form2.add, save: '1', open: true }
      })
    }
  }

  //表单新增后
  FormSubmit = (newData = {}) => {
    const { auth } = this.state
    this.setState({
      exercise: { ...this.state.exercise, open: false }
    })
    setTimeout(() => {
      if (auth.roles[0].id === 1) {
        this.onQuery();
      } else {
        this.onQuery2();
      }
    }, 500)
  }

  //表格删除
  onDelete1 = async (e, row) => {
    const { dataUrl, auth } = this.state
    const returnedData = await fetching(dataUrl.url.get2 + row.id, { method: 'DELETE' })
    if (returnedData) {
      if (auth.roles[0].id === 1) {
        this.onQuery();
      } else {
        this.onQuery();
      }
    }
    return returnedData;

  }

    //表格删除
    onDelete = async oldData => {
      const { dataUrl, auth } = this.state
      const returnedData = await fetching(dataUrl.url.get2 + oldData.id, { method: 'DELETE' })
      if (returnedData) {
        if (auth.roles[0].id === 1) {
          this.onQuery();
        } else {
          this.onQuery2();
        }
      }
      return returnedData;
  
    }

  //编辑
  onUpdate2 = (e, row) => {
    const { form2 } = this.state
    let status2 = '1'
    this.setState({ exercise: { ...row, save: '2', DialogTitle: form2.edit, status2, open: true, } });
  }

  //查看
  onview = (e, row) => {
    const { form2 } = this.state
    this.setState({ exercise: { ...row, save: '3', status2: '', DialogTitle: form2.view, open: true, } });



  }

  onAdd = newData => {
    return defer(0).then(console.log('--- newData ---: ', newData));
  }

  onUpdate = (newData, oldData) => {
    return defer(60).then(console.log('--- newData, oldData ---: ', newData, oldData));
  }

  onDelete = oldData => {
    return defer(60).then(console.log('--- oldData ---: ', oldData));
  }

  exportTableYWD = (e) => {
    const { exercise, form2, dataUrl } = this.state;
    //open: true--详情页；checkOpen: true--公共表单
    this.setState({
      exercise: { ...exercise, save: '3', DialogTitle: form2.exportTable, open: false, tableName: dataUrl.name, status2: '1', checkFlag: true, type: 1 }
    })
  }

  handleToggleYWD = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open: false }
      })
    } else {
      this.setState({
        exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
      })
    }
  }

  render() {
    const { columns, data, dataUrl, auth, exercise } = this.state;

    const title = dataUrl.title || '';
    const path = capitalize(this.props.table);
    const actions = actions2(this, this.props.table);
    const editable = editable2(this, this.props.table);
    // const { form2 } = this.state
    const { roles = [{ id: 0 }] } = auth;

    const C6 = () => {
      return <div style={{ textAlign: 'end', marginBottom: '10px' }}>
        <Button variant="contained" color="primary" onClick={this.exportTableYWD}>导出</Button>
      </div>
    }

    const DownloadTime = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <DialogForm handleToggle={this.handleToggleYWD} exercise={{ ...exercise, addBtnName: common.exportTable, isBtn }}>
          <DownloadTimeForm form2={{ ...common }} exercise={{ ...exercise }} />
        </DialogForm>
      </div>
    }

    //客户概况
    const C111 = () => {
      let isBtn = auth && this.props.table === 'c1-1-1' && roles[0].id === 1 ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
    <ButtonGroup size="small" aria-label="small outlined button group">
          <Button style={{ marginRight: '10px', backgroundColor: 'rgb(29, 69, 131)' }} variant="contained" color="primary" 				onClick={() => this.QueryByPeriod(1)}>月度</Button>
          <Button style={{ marginRight: '10px', backgroundColor: 'rgb(29, 69, 131)' }} variant="contained" color="primary"				 onClick={() => this.QueryByPeriod(2)}>季度</Button>
          <Button style={{ marginRight: '10px', backgroundColor: 'rgb(29, 69, 131)' }} variant="contained" color="primary" 				onClick={() => this.QueryByPeriod(3)}>近半年</Button>
          <Button style={{ marginRight: '10px', backgroundColor: 'rgb(29, 69, 131)' }} variant="contained" color="primary"				 onClick={() => this.QueryByPeriod(4)}>近一年</Button>
          <Button style={{ marginRight: '930px',backgroundColor: 'rgb(29, 69, 131)' }} variant="contained" color="primary" onClick={() => this.QueryByPeriod(5)}>		所有</Button>
        </ButtonGroup>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName1, isBtn }}
        >
          <C111Form auth={auth} formStatic={{ ...common, ...c1_1_1 }} exercise={exercise}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }
    //营业执照
    const C112 = () => {
      let isBtn = auth && this.props.table === 'c1-1-2' && roles[0].id === 1 ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName2, isBtn }}
        >
          <C112Form auth={auth} formStatic={{ ...common, ...c1_1_2 }} exercise={exercise}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }
    //非自然控制人
    const C12 = () => {
      let isBtn = this.props.table === 'c1-2' && roles[0].id === 1 ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName4, isBtn }}
        >
          <C12Form auth={auth} formStatic={{ ...common, ...c1_2 }} exercise={exercise}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }
    //非自然控制人
    const C13 = () => {
      let isBtn = this.props.table === 'c1-3' && roles[0].id === 1 ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName3, isBtn }}
        >
          <C13Form auth={auth} formStatic={{ ...common, ...c1_3 }} exercise={exercise}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }
    //自然控制人
    const C14 = () => {
      let isBtn = this.props.table === 'c1-4' && roles[0].id === 1 ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName3, isBtn }}
        >
          <C14Form auth={auth} formStatic={{ ...common, ...c1_3 }} exercise={exercise}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    //客户信息补录
    const C116 = () => {
      let isBtn = auth && this.props.table === 'c1-1-6' && (roles[0].id === 1 || roles[0].id === 16 ) ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName6, isBtn }}
        >
          <C116Form auth={auth} formStatic={{ ...common, ...c1_1_6 }} exercise={exercise}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    //客户授权信息
    const C15 = () => {
      let isBtn = auth && this.props.table === 'c1-5' && (roles[0].id === 1 || roles[0].id === 3 ) ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName7, isBtn }}
        >
          <C15Form auth={auth} formStatic={{ ...common, ...c1_5 }} exercise={exercise}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    // 内外部用户
    const C117 = () => {
      let isBtn = false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise,  addBtnName: common.addBtnName7, isBtn }}
        >
          <C117Form auth={auth} formStatic={{ ...common, ...c1_1_7 }} exercise={exercise}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    const table = this.props.table || '';
    return (
      <div>
        {(table === 'c1-1-1' || table === 'c1-1-8') && <C111></C111>}
        {(table === 'c1-1-2') && <C112></C112>}
        {(table === 'c1-1-6') && <C116></C116>}
        {(table === 'c1-1-7') && <C117></C117>}
        {(table === 'c1-1-7') && <C6></C6>}
        {(table === 'c1-1-7') && <DownloadTime></DownloadTime>}
        {(table === 'c1-2') && <C12></C12>}
        {(table === 'c1-3') && <C13></C13>}
        {(table === 'c1-4') && <C14></C14>}
        {(table === 'c1-5') && <C15></C15>}
        <MaterialTable
          title={title}
          path={path}
          columns={columns}
          data={data}
          editable={editable}
          localization={localization}
          options={options}
          actions={actions}
          onSearchChange={this.onSearchChange}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
        />
      </div>
    )
  }
}
const actions2 = (that, name) => {
  let actions = [];
  let auth = loginInfos();
  if (name === 'c1-1-7' && auth.roles[0].id !== 1) {
    return actions;
  }
  switch (name) {
    case 'c1-1-1':
      actions = [{ icon: 'save', tooltip: '编辑', onClick: that.onUpdate2 }, { icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'c1-1-2':
      actions = [{ icon: 'save', tooltip: '编辑', onClick: that.onUpdate2 }, { icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'c1-1-6':
      actions = [{ icon: 'save', tooltip: '编辑', onClick: that.onUpdate2 }, { icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'c1-1-7':
      actions = [{ icon: 'save', tooltip: '编辑', onClick: that.onUpdate2 }];
      break;
    case 'c1-2':
      actions = [{ icon: 'save', tooltip: '编辑', onClick: that.onUpdate2 }, { icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'c1-3':
      actions = [{ icon: 'save', tooltip: '编辑', onClick: that.onUpdate2 }, { icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'c1-4':
      actions = [{ icon: 'save', tooltip: '编辑', onClick: that.onUpdate2 }, { icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'c1-5':
      actions = [
        { icon: 'save', tooltip: '编辑', onClick: that.onUpdate2 }, 
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        { icon: Delete, tooltip: '删除', onClick: that.onDelete1 },];
      break;
    default:
      actions = [];
  }
  return actions;
}
const editable2 = (that, name) => {
  let editable = {};
  switch (name) {
    case 'r12-1':
      editable = { onRowDelete: that.onDelete };
      break;
    default:
  }
  return editable;
}
const localization = {
  header: {
    actions: '操作'
  }
}
const options = {
  actionsCellStyle: { padding: '0 10px' },
  headerStyle: { //表格头部
    backgroundColor: '#1d4583',
    color: '#FFF',
    whiteSpace: 'nowrap'
  },
  rowStyle: { //表行
    backgroundColor: '#fff',
    whiteSpace: 'nowrap'
  },
  sorting: true,  //排序
  // selection: true //多选框
}





let setView = Comp => collection => {
  return class extends Component {
    render() {
      return <Comp table={collection} {...this.props} />
    }
  }
}

export const SetView = setView(View);

