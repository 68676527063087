
import getSubRouters from './helper';

 export const subRootAry2 = [
    '融单开立审核',
    '开立融单查看',
    '已开融单',
    '已退回融单',
    // '批量审核结果',
    '已退回经办人融单',//开立退回经办人的业务板块功能--20201030
    '已拒签融单',//开立拒签业务板块功能--20201120
    '已删除融单',
    '已退回复核员融单',
  ];

const PARENT_PATH = '/risk-management/融单';
const PATH = '融单开立';
export default getSubRouters(PARENT_PATH, PATH, subRootAry2);