
import getSubRouters from './helper';

 export const subRootAry43 = [
    '新增额度停用',
    '额度停用审核',
    '额度停用查询',
    '已退回额度停用申请',
  ];
const PARENT_PATH = '/risk-management/额度审批';
const PATH = '额度冻结';
export default getSubRouters(PARENT_PATH, PATH, subRootAry43);