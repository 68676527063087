import React from 'react';
import { Switch, Route,Redirect, Link } from 'react-router-dom';

import {
  Alarm,//时间钟表--20210106
  PersonPin,
  AlarmOn,
  ChromeReaderMode,
  CallToAction,
  CalendarViewDay,
CalendarViewDayOutlined,
} from '@material-ui/icons';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { subRouters } from './common';
import C91, { subRootAry1 } from './containers/C9_1'
import { SetView } from './commonTable'
import { dataAnalysisForm} from '../02-core_business/containers2'
import { riskForm} from '../02-core_business/containers2'

const PARENT_PATH = subRouters['运营管理'];

const Info = [
  /*{
    path: "清算",
    icon: ChromeReaderMode
  },
  {
    path: "交易查询",
    icon: AlarmOn
  },
  {
    path: "账户信息",
    icon: PersonPin
  },*/
  {
    path: "核心企业签发对比",
    icon: CallToAction,
    component:SetView('r9-4')
  },
  {
    path: "开立到融资时间统计",
    icon: Alarm,
    component:SetView('r9-5')
  },
  {
    path: "数据分析",
    icon: CalendarViewDay,
    component: dataAnalysisForm,
  },
 {
    path: "风控管理",
    icon: CallToAction,
    component: riskForm,
  },
 {
    path: "月度风险监测指标测算",
    icon: CalendarViewDayOutlined,
    component:SetView('r9-6')
  },
  /*{
    path: "数据统计",
    icon: QueryBuilderIcon,
    component: C92
  },*/
  {
    path: "核心企业明细",
    icon: CalendarViewDayOutlined,
    component:SetView('r9-2-1')
  },
  {
    path: "供应商交易统计",
    icon: CalendarViewDayOutlined,
    component:SetView('r9-2-2')
  },
];

const view = {};
subRootAry1.forEach(item => {
  switch (item) {
    case '融单待清算':
      view[item] = SetView('r9-1-1');
      break;
    case '融单清算中':
      view[item] = SetView('r9-1-2');
      break;
    case '融单已清算':
      view[item] = SetView('r9-1-3');
      break;
    default: ;
  }
})

const getMenu = (parent_path, info) => () => {
  const list = info.map((item, index) => {
    const CompIcon = item.icon;
    switch (index) {
      //case 0:
        //return <C91 key="C91" />;
      default:
        return (
          <ListItem button component={Link} to={`${parent_path}/${item.path}`} key={item.path}>
            <ListItemIcon>
              <CompIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={item.path} />
          </ListItem>
        );
    }
  });
  return (
    <div>
      {list}
    </div>
  );
};
const getContent = (parent_path, info) => () => {
  return (
    <Switch>
      <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${"核心企业签发对比"}`}
      />
      {info.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${parent_path}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${parent_path}/${item.path}`}
              render={() => <h2>{item.path}</h2>}
              key={item.path}
            />
          )
        }
      })}
      {subRootAry1.map(item => {
        if (view[item]) {
          return (
            <Route
              path={`${parent_path}/${item}`}
              component={view[item]}
              key={item}
            />
          )
        }
        else {
          return (
            <Route
              path={`${parent_path}/${item}`}
              render={() => <h2>{item}</h2>}
              key={item}
            />
          )
        }
      })}
    </Switch>

  )
};
export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];