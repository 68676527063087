import React, { Component } from 'react'
import Select from 'react-select';
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  TextField,//20201228
  Button,
  Grid,
  Checkbox,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  CircularProgress,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import CheckForm from '../../components/commonForm/CheckForm';
import FormikError from '../../components/commonForm/formikError';
import { C51Formik } from '../../01-risk-management/CreditManager/formikValidate'

global.count = 0;
const useStyles = (theme => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 280,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    form: this.getInitState(),
    formStatic: this.props.formStatic || {},//静态资源
    selectCustomerList: [],
    checked: [],
    left: [],
    right: this.getInitRight(),
    leftChecked: [],
    rightChecked: [],
    leftSelect: [],//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
    rightSelect: [],//企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228
    leftName: '',//[备选企业]搜索关键字--20201228
    rightName: '',//[选中企业]搜索关键字--20201228
    loading: false,
  }
  async componentWillMount() {
    const { exercise = {} } = this.props;
    // const { left, right, checked } = this.state;
    if (exercise.save !== '3') {
      this.queryEnterpriseInfo(exercise.enterprise_no);
    } else {
      //企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228
      this.setState({ rightSelect: this.state.right });
    }
    // let leftChecked = this.intersection(checked, left);
    // let rightChecked = this.intersection(checked, right);
    // this.setState({ leftChecked, rightChecked })
  }

  getInitState() {
    const { exercise = {} } = this.props
    const form = {
      select_customer: '',
      enterprise_name: "",
      enterprise_no: "",
      data: [],
      //form_no: '2',
    }
    if (exercise.save !== '1') {
      form.select_customer = {
        value: exercise.enterprise_name,
        label: exercise.enterprise_name,
        enterprise_no: exercise.enterprise_no,
        enterprise_name: exercise.enterprise_name
      }
    }
    return exercise.save === '1' ? { ...form } : { ...form, ...exercise }
  }
  getInitRight() {
    const { exercise = {} } = this.props;
    if (exercise.save !== '1' && exercise.subsidiaries instanceof Array) {
      let subsidiaries = exercise.subsidiaries.map((item) => {
        let items = {
          id: item.sub_company_id,
          enterprise_name: item.sub_company_name,
          enterprise_no: item.sub_company_no
        }
        return items
      })
      return subsidiaries
    }
    return []
  }
  queryEnterpriseInfo = (enterprise_no) => {
    const { exercise = {} } = this.props;
    const { right = [] } = this.state;
      if (exercise.data) {
        try {
          let left = [];
          let datas = exercise.data.filter((item) => {
            item.value = item.mx_number  + item.extraction;
            item.label = item.mx_number + item.extraction ;
            item.enterprise_name = item.mx_number + item.extraction;
            if ( true ) {
              let obj = {};
              obj.id = item.id
              obj.enterprise_no = item.mx_number
	      obj.enterprise_name = item.mx_number +"-"+ item.extraction +"-"+ item.amount+ "(元)" +"-"+ item.limit_mark +"-"+ item.payment_day
              //obj.enterprise_name = item.mx_number + item.extraction
              left.push(obj)
            }
            return  item ;
            
          })
          let leftSelect = left;//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
          this.setState({ selectCustomerList: datas, left, leftSelect });
          //this.setState({ selectCustomerList: datas, left });
        } catch (error) {
        }
      } else {
      }
    
  }

  //企业管理模块关联企业时,[备选企业]/[选中企业]可以按照名字搜索分公司名字--20201228
  handleInputChange = (name, value) => {
    let leftSelect = this.state.left;//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
    let rightSelect = this.state.right;//企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228

    let leftName = "";//[备选企业]搜索关键字--20201228
    let rightName = "";//[选中企业]搜索关键字--20201228
    
    if (name === 'leftName') {//选择[备选企业]
      if (null !== value && value !== "") {
        leftName = value;//[备选企业]搜索关键字--20201228
        leftSelect = this.state.left.filter((item) => {
          if ( item.enterprise_name.includes(value) ) {
            return item;
          }
          return null;
        })
      }
      this.setState({ leftSelect, leftName });//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
    } else if (name === 'rightName') {//选择[选中企业]
      if (null !== value && value !== "") {
        rightName = value;//[选中企业]搜索关键字--20201228
        rightSelect = this.state.right.filter((item) => {
          if ( item.enterprise_name.includes(value) ) {
            return item;
          }
          return null;
        })
      }
      this.setState({ rightSelect, rightName });//企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228
    }
  }
    
  
  
  //[备选企业]/[选中企业]中值随着左右移动按钮变化后,企业管理模块关联企业时,[备选企业]/[选中企业]可以按照名字搜索分公司名字--20201228
  // companySelectChange = (listLeft, listRight) => {
  //   let leftSelect = listLeft;//企业管理模块关联企业时,[备选企业]可以按照名字搜索分公司名字--20201228
  //   let rightSelect = listRight;//企业管理模块关联企业时,[选中企业]可以按照名字搜索分公司名字--20201228

  //   const leftName = this.state.leftName;//[备选企业]搜索关键字--20201228
  //   const rightName = this.state.rightName;//[选中企业]搜索关键字--20201228

  //   if (null !== leftName && leftName !== "") {
  //     leftSelect = listLeft.filter((item) => {
  //       if ( item.enterprise_name.includes(leftName) ) {
  //         return item;
  //       }
  //       return null;
  //     })
  //   }

  //   if (null !== rightName && rightName !== "") {
  //     rightSelect = listRight.filter((item) => {
  //       if ( item.enterprise_name.includes(rightName) ) {
  //         return item;
  //       }
  //       return null;
  //     })
  //   }

  //   this.setState({ leftSelect, rightSelect });
  // }

  //输入框值改变
  handleChange3 = (name, value) => {
    let value2 = value;
    if (name === 'select_customer') {
      this.setState({ checked: [], leftChecked: [], rightChecked: [], right: [] })
      this.queryEnterpriseInfo(value.enterprise_no)
    }
    return value2;
  }

  // 对象查找
  // findObject = (obj, data) => {
  //   let index = data.findIndex(item => item.enterprise_no === obj.enterprise_no);
  //   return index;
  // }
  handleToggle = (value) => {
    const { checked, left, right } = this.state;
    const currentIndex =  -1;//this.findObject(value, checked);
    let newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    //this.state.form.data.push(value.enterprise_no);
    if (this.state.form.data.length === 0) {
      this.state.form.data.push(value.enterprise_no);
    } else {
      this.props.exercise.CreditSource = true;
      for (var i = 0; i < this.state.form.data.length; i++) { 
        if (this.state.form.data[i] === value.enterprise_no) {
          if ( i > -1) { 
            this.state.form.data.splice(i, 1); 
            this.props.exercise.CreditSource = false;
            }
        }
    }
    if (this.props.exercise.CreditSource) {
      this.state.form.data.push(value.enterprise_no);
    }
    }
    
    //let leftChecked = this.intersection(newChecked, left);
    //let rightChecked = this.intersection(newChecked, right);
    //this.setState({ checked: newChecked, leftChecked, rightChecked })
    this.setState({ values: this.state.form.data })
    // if (leftChecked.length>0) {
    //   let sub_company_no = leftChecked[0].enterprise_no;
    //   fetching(`/smrd/business_management/findc?sub_company_no=${sub_company_no}`).then(res => {
    //     if(res){
    //       if(res.length>0) {
    //         alert("该企业已经被关联过,不能再次进行关联操作")
    //       } else{
    //         fetching(`/smrd/business_management/findc?enterprise_no=${sub_company_no}`).then(res => {
    //           if(res){
    //             if(res.length>0){
    //               alert("该企业已经被作为总公司关联过,不能关联为子公司")
    //             } else {
    //               this.setState({ checked: newChecked, leftChecked, rightChecked })
    //             }
    //           }
    //         })
    //       }
    //     }
    //   })
    // } else if(rightChecked.length>0){    
    //         this.setState({ checked: newChecked,rightChecked, leftChecked })
    //       }
    
  };

  not = (a, b) => {
    let bIds = b.map(item => item.enterprise_no ? item.enterprise_no : null)
    let data = a.filter(value => value.enterprise_no ? !bIds.includes(value.enterprise_no) : null);
    return data;
  }
  intersection(a, b) {

    let bIds = b.map(item => item.enterprise_no ? item.enterprise_no : null)
    let data = a.filter(value => value.enterprise_no ? bIds.includes(value.enterprise_no) : null);
    return data;
  }
  //保存 提交
  handleSubmit = (values, name) => {
    const text = name || 'cancle';
    if (text === 'submit') {
      this.setState({ loading: true })
    }
    const { auth } = this.props;
    let mx_number = values.data;
    let form_no = this.props.exercise.form_no;
    let params = {
      mx_number,
      auth,
      form_no,
      //enterprise_name: select_customer.enterprise_name,
      //enterprise_no: select_customer.enterprise_no,
      //timestamp : new Date()
    }
    let roleId = auth.roles[0].id
    if (roleId === 11 && form_no === "2") {
      alert('当前角色不能进行退回操作')
      window.location.reload();
      return;
    }
    
    //提交表单
    if (text === 'submit') {
      fetching(`/repayment/savePaymentFlowBatchOperation`, {
        method: 'POST' ,
        body: JSON.stringify(params)
      }).then((data) => {
        this.setState({ loading: false })
        try {
          if (data.code === 200) {
            alert(data.msg)
            window.location.reload();
            // this.props.onSubmit({
            //   ...params,
            //   submit_name: text
            // })
          }else {
            alert('提交失败')
            return;
          }
        } catch (error) {
          console.log(error)
         
        }
      })
    } else {
      window.location.reload();
      // this.props.onSubmit({
      //   ...params,
      //   submit_name: text
      // })
    }
  }
  //审核意见
  FormSubmit3 = (data) => {
    this.props.onSubmit({
      ...data,
      submit_name: 'submit'
    })
  }

  handleChange3 = ({ target: { name, value } }) => {
    const { form } = this.state
    this.props.exercise.form_no=value
    this.setState({
      form: { ...form, [name]: value}
    })
    return value
  }

  render() {
    const { form, formStatic, selectCustomerList, checked, left, right, leftChecked, rightChecked, loading, leftSelect, rightSelect, leftName, rightName, } = this.state
    const { exercise, classes, auth, dataUrl } = this.props;
    // const checkform = () => {
    //   return <CheckForm auth={auth} exercise={exercise} dataUrl={dataUrl}
    //     onSubmit={this.FormSubmit3}
    //   />
    // }
    const customList = items => (
     
        <List dense component="div" role="list">
          {items.map(value => {
            const labelId = `transfer-list-item-${value.enterprise_no}-label`;
            return (
              <ListItem key={value.enterprise_no} role="listitem" button onClick={this.handleToggle.bind(this, value)}>
                {/* <ListItem key={value} role="listitem" button> */}
                <ListItemIcon>
                  <Checkbox
                    //checked={this.findObject(value, checked) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.enterprise_name} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
    );
    return (
      <Formik
        initialValues={{
          ...form
        }}
        // validate={(values) => {
        //   return values;
        // }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, 'submit');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
            <div>
              <form>
                <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                  <Grid item xs={12}>
                  <div style={{ margin: '10px' }}>
                    <Typography variant="body2" gutterBottom>待审批</Typography>
                    <TextField
                      id="outlined-margin-dense"
                      placeholder="select..."
                      defaultValue=""
                      className={classes.textField}
                      value={values.leftName}
                      onChange={e => setFieldValue('leftName', this.handleInputChange.bind(this, 'leftName', e.target.value)())}
                      disabled={exercise.save === '3'}
                      margin="dense"
                      variant="outlined"
                    />
                    {customList(leftSelect)}
                    </div>
                  </Grid>
                
                </Grid>
                {
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="gender" name="form_no" value={form.form_no} onChange={this.handleChange3}>
                  <FormControlLabel value="3" control={<Radio />} label="同意" />

                  <FormControlLabel
                    //disabled={rolesId === 9 || rolesId === 5 || rolesId === 6 || exercise.StatusId === 5}
                    value="2" control={<Radio />} label="退回" />
                </RadioGroup>}
                {exercise.save !== '3' &&
                  <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
                    <div style={{ position: 'relative' }}>
                      <Button
                        style={{ margin: '0 10px' }}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        提交
                    </Button>
                      {loading && <CircularProgress size={24} style={loadingStyle} />}
                    </div>

                    <Button variant="contained" color="primary" onClick={this.handleSubmit.bind(this, values, 'cancle')}>取消</Button>
                  </div>
                }
              </form>
              

            </div>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);



