import React, { Component } from 'react'
import { Formik } from 'formik';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { fetching } from '../../config/utils';
import { check } from './check'
import {
  TextField,
  Button,
  Grid,
  Select,
  FormControl,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputLabel
} from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import FormikError from './formikError';
import SimpleExpansionPanel from './Panels'
import { issueFormik } from './formikValidate';
import { DEFAULT_CONTRCT_URL } from '../../config/constants';
// import { numberParseChina } from '../numToText';

import TreeView from '@material-ui/lab/TreeView';//202009630
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';//202009630
import ChevronRightIcon from '@material-ui/icons/ChevronRight';//202009630
import TreeItem from '@material-ui/lab/TreeItem';//202009630

import {
  LocationOn,
  Attachment,
  MonetizationOn,
  AccountTree
} from '@material-ui/icons'//20200930
import { result } from 'lodash';

const { object } = require('yup');

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
class SlForm extends Component {
  state = {
    form: this.getInitState(),
    form2: this.getInitState2(),
    open2: false,
    imgUrl: '',
    times: null,
    signatureMsg: '',
    second: 60,//验证码倒计时
    secondNumber: '',//验证码is
    userList: [
      { id: 2, name: '退回数链' },
    ],
    auth: this.props.auth || {}
  }
  getInitState() {
    const { exercise, auth } = this.props

    // const sign_agreement = exercise.rolseId === '2' ? '1': '';
    let sign_agreement = '1';
    let phone = auth.phone ? auth.phone : '15719478614';
    let isverification_code = false //判断是否验证
    let KLStart = true;
    let rolesId = auth.roles ? auth.roles[0].id : 0;
    if (exercise.checkStatus === '3' && exercise.table === 'm2-4') {
      KLStart = false;
      isverification_code = true
    } else if (exercise.checkStatus === '5' && (exercise.table === 'm3-1-1')) {
      isverification_code = exercise.mxf_number ? false : true;
      KLStart = exercise.open_day ? false : true;
    } else if ((exercise.table === 'r6-3-1' || exercise.table === 'r6-2-1') && rolesId === 9) {
      KLStart = false;
    } else if (exercise.table === 'm3-2-2' && exercise.StatusId === 5) {
      KLStart = false;
      isverification_code = true
    }

    // if (exercise.checkStatus === '6') {
    //  isverification_code = true
    // }
    const form = {
      opinion_list: [],
      ...exercise,
      sign_agreement, //签订协议
      check_opinion: '',
      verification_code: '',
      isverification_code,
      form_no: exercise.checkStatus || '3',
      KLStart,
      phone,
      returnBy: '退回数链'
    }

    return form
  }
  getInitState2() {
    const { exercise } = this.props
    let sign_agreement = '';
    if (exercise.table === 'm2-4' || exercise.table === 'r6-2-1' || exercise.table === 'm3-1-1') {

      sign_agreement = exercise.mxc_number ? check.sign_flow : check.sign_agreement;
    }
    else if (exercise.table === 'm3-1-9' || exercise.table === 'r6-4-1') {
      sign_agreement = check.sign_flow
    }
    else if (exercise.table === 'm3-2-2' || exercise.table === 'r6-3-1') {
      sign_agreement = check.sign_financing
    }
    return { ...check, sign_agreement };
  }

  componentWillUnmount() {
    clearInterval(this.state.times);
  }

  
  handleFileChange = e => {
    this.setState({
      [e.target.name]: e.target.files[0],
    })
  };

  //查看协议
  viewAgreement = () => {
    // this.setState({ open2: !this.state.open2 })
    const { fdd_file_path = '', mxf_number } = this.state.form;
    if (fdd_file_path && fdd_file_path !== 'null') {
      window.open(fdd_file_path)
    } else {
      if (mxf_number) {
        window.open(DEFAULT_CONTRCT_URL + 'contract/template/rz.pdf')
      } else {
        window.open(DEFAULT_CONTRCT_URL + 'contract/template/kl.pdf')
      }
    }
  }
  //查看单据
  viewAgreement2 = () => {
    // this.setState({ open2: !this.state.open2 })
    const { mxc_number, fdd_kld_path = '', fdd_lzd_path = '' } = this.state.form;

    if (mxc_number) {
      window.open(fdd_lzd_path)
    } else {
      window.open(fdd_kld_path)
    }
  }
 

  // 提交
  handleSubmit = async (form2, name) => {
    const { auth = {}, secondNumber } = this.state;
    const stateForm = this.state.form;
    const { exercise } = this.props
    const form = form2 ? { ...form2 } : stateForm;
    const text = name || '取消';
    var rolesId = auth.roles ? auth.roles[0].id : '';
    const receivableNo = form.receivableNo;
    const financingNo = form.financingNo;
    const repayNo = form.repayNo;
    var result = '';
    if (stateForm.form_no === '2') {
      result = '02';
    }
    if (stateForm.form_no === '3') {
      result = '01';
    }
    if (stateForm.form_no === '3' && stateForm.table === "r14-5-3" && rolesId === 6) {
      result = '03';
    }
    const message = form.check_opinion;
    const amount = form.financingAmount;
    const rate = form.rate;
    //const beginDate = '2021-05-09';
    //const endDate = '2021-08-09';
    const loanAccountBank = form.loanAccountBank;
    const loanAccountBankName = form.loanAccountBankName;
    const loanAccountNo = form.loanAccountNo;
    
    //参数
    const body = {
      receivableNo: form.OrganizationId,//20200910
      result: result,//审核结果
      message: form.check_opinion,//审核意见

    }

    //提交表单
    if (text !== '取消') {
      if ((stateForm.form_no === '3' && rolesId === 6) || (stateForm.form_no === '3' && rolesId === 14 && stateForm.table === 'r14-5-1') ||
          (stateForm.form_no === '3' && rolesId === 8 && stateForm.applyAmount <= 10000000 && stateForm.table === 'r14-5-1')) {
        if (stateForm.table === 'r14-5-1') {
        fetching(`/sl/pushReceivableFlow?receivableNo=${receivableNo}&result=${result}&message=${message}`).then(res => {
          if (res) {
            if(res.code === "400") alert(res.msg)
            }
            })
          }else if (stateForm.table === 'r14-5-2') {
            fetching(`/sl/pushLoanFlow?financingNo=${financingNo}&receivableNo=${receivableNo}&result=${result}&amount=${amount}&rate=${rate}&loanAccountBank=${loanAccountBank}&loanAccountBankName=${loanAccountBankName}&loanAccountNo=${loanAccountNo}`).then(res => {
              if (res) console.log(res.code)
                })
          }else if (stateForm.table === 'r14-5-3') {
            fetching(`/sl/pushSlRepaymentFlow?repayNo=${repayNo}&result=${result}&reason=${message}`).then(res => {
              if (res) console.log(res.code)
                })
          }
        }else if (stateForm.form_no === '3' && rolesId !== 6) {
          var roleId = 0;
          if (rolesId === 1) {
            roleId = 3
          }else if (rolesId === 3) {
            roleId = 8
          // }else if (rolesId === 4) {
          //   roleId = 8
          }else if (rolesId === 8 && stateForm.applyAmount <= 10000000) {
            roleId = 5
          }else if(rolesId === 8){
            roleId = 14
          }else if (rolesId === 14 || rolesId === 9) {
            roleId = 5
          }else if (rolesId === 5) {
            roleId = 6
          }
          if (stateForm.table === 'r14-5-1') { 
            fetching(`/sl/slReceivableAuditing?receivableNo=${receivableNo}&roleId=${roleId}`).then(res => {
              if (res) console.log(res.code)
                })
            }else if (stateForm.table === 'r14-5-2') {
              fetching(`/sl/slFinancingAuditing?financingNo=${financingNo}&roleId=${roleId}`).then(res => {
                if (res) console.log(res.code)
                  })
            }
            else if (stateForm.table === 'r14-5-3') {
              fetching(`/sl/slRepaymentAuditing?repayNo=${repayNo}&roleId=${roleId}`).then(res => {
                if (res) {
                  fetching(`/sl/pushSlRepaymentFlow?repayNo=${repayNo}&result=${result}&reason=${message}`).then(res2 => {
                    if (res2) console.log(res2.code)
                      })
                    } 
                  })
            }
        }else if (stateForm.form_no === '2') {
          if (stateForm.table === 'r14-5-1') {
            fetching(`/sl/pushReceivableFlow?receivableNo=${receivableNo}&result=${result}&message=${message}`).then(res => {
              if (res) {
                if(res.code === "400") alert(res.msg)
                }
                })
            }else if (stateForm.table === 'r14-5-2') {
              fetching(`/sl/pushLoanFlow?financingNo=${financingNo}&receivableNo=${receivableNo}&result=${result}&amount=${amount}&rate=${rate}&loanAccountBank=${loanAccountBank}&loanAccountBankName=${loanAccountBankName}&loanAccountNo=${loanAccountNo}`).then(res => {
                if (res) console.log(res.code)
                  })
            }else if (stateForm.table === 'r14-5-3') {
              fetching(`/sl/pushSlRepaymentFlow?repayNo=${repayNo}&result=${result}&reason=${message}`).then(res => {
                if (res) console.log(res.code)
                  })
            }
        }
      this.props.onSubmit({
        ...form,
        submit_name: text
      })      
    } else {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
    }
    window.location.reload()
  }
  handleChange2 = ({ target: { name, value } }) => {
    const { form } = this.state
    let isverification_code = form.isverification_code
    if (value === '6') {
      isverification_code = true
    }
    this.setState({
      form: { ...form, [name]: value, isverification_code }
    })
  }
  render() {
    const { form2, form, imgUrl, userList, second, secondNumber, signatureMsg } = this.state;
    const { exercise, auth } = this.props;
    let rolesId = auth.roles ? auth.roles[0].id : 0;
    const isSign_agreement = () => {

      if (
        (form.form_no === '3' || form.form_no === '5') &&
        (exercise.table === 'm2-4' || exercise.table === 'm3-1-1') && exercise.RoleId !== 14) {
        if (exercise.flow_date) {
          return false
        } else return true
      }
      else if ((exercise.table === 'r6-3-1' || exercise.table === 'r6-2-1') && rolesId === 9) {
        return true
      }
      else if (exercise.table === 'm3-2-2' && exercise.StatusId === 5) {
        return true
      }
      else return false;
    }

    const check_opinion_label = () => {
      if (form.form_no === '3' && rolesId !== 5) return form2.check_opinion
      else if (form.form_no === '2') return form2.return_reason
      else return form2.remarks

    }

    const status = (handleSubmit, values) => {
      if (form.form_no === '3') {

        return <Grid container justify="center" style={{ padding: '20px 0' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      } else if (form.form_no === '2') {
        return <Grid container justify="center" style={{ padding: '20px 0' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      } else if (form.form_no === '5') {
        return <Grid container justify="center" style={{ padding: '20px 0' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      } else if (form.form_no === '6') {
        return <Grid container justify="center" style={{ padding: '20px 0' }}>

          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      }
    }
    //签订协议类型 exercise.table
    // const agreementTple = () => {}
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        validationSchema={object().shape(issueFormik(form2, form, rolesId))}
        //验证提交
        onSubmit={(values) => {
          this.handleSubmit(values, form2.check);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <form method="post" encType="multipart/form-data">
              <FormControl component="fieldset">

              </FormControl>
              { form.form_no != '8' && rolesId !== 9 && <Grid item xs={12}>
                <TextField
                  multiline
                  rows='4'
                  label={check_opinion_label()}
                  value={values.check_opinion}
                  name='check_opinion'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={false}
                />
                <FormikError touched={touched} errors={errors} name={'check_opinion'} />
              </Grid>}

              {(form.form_no === '3' || form.form_no === '2') &&
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="gender" name="form_no" value={form.form_no} onChange={this.handleChange2}>
                  <FormControlLabel value="3" control={<Radio />} label="同意" />

                  <FormControlLabel
                    disabled={(rolesId === 9 || rolesId === 5 || rolesId === 6 || exercise.StatusId === 5) && (exercise.table != "r14-5-3")}
                    value="2" control={<Radio />} label="退回" />
                </RadioGroup>}
              {(form.form_no === '5' || form.form_no === '6') &&
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="gender" name="form_no" value={form.form_no} onChange={this.handleChange2}>
                  <FormControlLabel value="5" control={<Radio />} label="签收" />
                  <FormControlLabel disabled={exercise.RoleId === 14} value="6" control={<Radio />} label="拒签收" />
                </RadioGroup>}
              {
                isSign_agreement() &&
                <div>
                  <Grid container>

                    {rolesId !== 9 && <Grid item xs={12}>
                      <Grid container alignItems="flex-end">
                        <TextField
                          label={form2.verification_code}
                          value={form.verification_code}
                          name='verification_code'
                          onChange={this.handleChange2}
                          margin='normal'
                          style={{ width: '167px' }}
                          disabled={false}
                        />
                        {second === 60 ?
                          <Button onClick={this.onVerificationCode} style={{ margin: ' 0  0 8px 10px' }}
                            variant='contained'>{form2.verification_code2}
                          </Button> :
                          <Button style={{ margin: ' 0  0 8px 10px' }}
                            variant='contained'>{second + form2.verification_code3}
                          </Button>}
                      </Grid>
                      {secondNumber !== '' && <div style={{ color: ' rgba(0, 0, 0, 0.42)', fontSize: '12px' }}>
                        {'验证码也发送到你的手机 ' + form.phone}
                      </div>}
                      <FormikError touched={touched} errors={errors} name={'verification_code'} />
                    </Grid>}
                    <div style={{ margin: '20px 0 10px 0' }}>
                      <Button
                        onClick={this.sign_agreements}
                        color="primary">{form2.sign_agreement}</Button>

                      <Button onClick={this.viewAgreement} style={{ marginLeft: '10px' }}
                        variant='contained' >{form2.view_agreement}</Button>
                    </div>

                  </Grid>
                  <div style={{ marginLeft: '10px' }}>{signatureMsg}</div>

                </div>
              }

              {
                (form.form_no === '5' && exercise.RoleId === 14) &&
                <Button onClick={this.viewAgreement} style={{ margin: '10px 0' }}
                  variant='contained' >{'查看协议'}</Button>
              }
              {
                (
                  form.form_no === '6' ||
                  (exercise.table === 'm3-1-1' && form.flow_date)) && <Grid item xs={12}>
                  <Grid container alignItems="flex-end">
                    <TextField
                      label={form2.verification_code}
                      value={form.verification_code}
                      name='verification_code'
                      onChange={this.handleChange2}
                      margin='normal'
                      disabled={false}
                    />
                    {second === 60 ?
                      <Button onClick={this.onVerificationCode} style={{ margin: ' 0  0 8px 10px' }}
                        variant='contained'>{form2.verification_code2}
                      </Button> :
                      <Button style={{ margin: ' 0  0 8px 10px' }}
                        variant='contained'>{second + form2.verification_code3}
                      </Button>}

                  </Grid>
                  {secondNumber !== '' && <div style={{ color: 'rgba(0, 0, 0, 0.42)', fontSize: '12px' }}>
                    {'验证码也发送到你的手机 ' + form.phone}
                  </div>}
                  <FormikError touched={touched} errors={errors} name={'verification_code'} />
                </Grid>
              }
              {
                form.form_no === '2' &&
                <FormControl style={{ width: '100%', margin: '10px 0' }}>
                  <InputLabel id="demo-simple-select-label">{form2.returnBy}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="returnBy"
                    value={values.returnBy}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {
                      userList.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              }
              {
                status(handleSubmit, values)
              }
              <Dialog maxWidth={'md'} onClose={this.viewAgreement} aria-labelledby="customized-dialog-title"
                open={this.state.open2}>
                <MuiDialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  id="customized-dialog-title">
                  <span>{form2.view_agreement}</span>
                </MuiDialogTitle>
                <MuiDialogContent style={{ minWidth: '600px', minHeight: '400px' }}>
                  <img src={imgUrl} alt='' />
                </MuiDialogContent>
                <Button onClick={this.viewAgreement} style={{ width: '100px', margin: '20px 0 20px 24px' }}
                  variant='contained'>返回</Button>
              </Dialog>
            </form >
          )
        }
      />

    )
  }
}

export default withStyles(useStyles)(SlForm)
