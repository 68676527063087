//客户概况
export default {
    enterprise_name: "客户名称",
    enterprise_no: "客户编号",
    organization_code: "组织机构代码证",
    issue_unit: "颁发单位",
    issue_date:"发证日期",
    validity_period:"有效期",
    yearly_check: "是否年检",
    organization_type: "机构类型",
    register_place: "注册地",
    country_register_place: "国别(注册地)",
    country_businessr_place: "国别(经营所在地)",
    register_address: "详细注册地",
    industry_strategy:"行业策略",
    industry_code:"行业代码",
    national_standard_industry:"国标行业",
    customer_relationship_type:"客户关系类型",
    customer_types:"客户大类",
    accounting_system:"适用会计制度",
    enterprise_type: "企业类型",
    enterprise_scale: "企业规模",
    enterprise_employees: "企业人数",
}
