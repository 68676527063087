import React from 'react';
import { Switch, Route,Redirect, Link } from 'react-router-dom';
import {
  QueryBuilder as QueryBuilderIcon,
  // LocalFlorist as LocalFloristIcon
} from '@material-ui/icons';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { subRouters } from './common';
import { SetView } from './CreditApproval/commonTable'
import { C4_1 as C41,C4_3 as C43,C4_4 as C44,C4_5 as C45,C4_6 as C46} from './containers'
import { subRootAry41 } from './containers/C4_1'
// import { subRootAry42 } from './containers/C4_2'
import { subRootAry43 } from './containers/C4_3'
import { subRootAry44 } from './containers/C4_4'
import { subRootAry45 } from './containers/C4_5'
import { subRootAry46 } from './containers/C4_6'
import Accordion from '../components/Accordion'
const PARENT_PATH = subRouters['额度审批'];
const subRootAry = [
  ...subRootAry41,
  // ...subRootAry42
  ...subRootAry43,
  ...subRootAry44,
  ...subRootAry45,
  ...subRootAry46,
];


const Info = [
  {
    path: "额度申请",
    icon: QueryBuilderIcon,
    component: C41
  },
  {
    path: "额度冻结",
    icon: QueryBuilderIcon,
    component: C43
  },
  {
    path: "额度解冻",
    icon: QueryBuilderIcon,
    component: C44
  },
  {
    path:"额度延时",
    icon: QueryBuilderIcon,
    component: C45
  },
  /*{
    path:"大树风控额度",
    icon: QueryBuilderIcon,
    component: C46
  },*/
];
const view = {};
subRootAry.forEach(item => {
  switch (item) {
    case '新增额度申请':
      view[item] = SetView('r4-1-1');
      break;
    case '额度申请审核':
      view[item] = SetView('r4-1-2');
      break;
    case '额度申请查询':
      view[item] = SetView('r4-1-3');
      break;
    case '已退回额度申请':
      view[item] = SetView('r4-1-4');
      break;
    case '新增额度停用':
      view[item] = SetView('r4-3-1');
      break;
    case '额度停用审核':
      view[item] = SetView('r4-3-2');
      break;
    case '额度停用查询':
      view[item] = SetView('r4-3-3');
      break;
    case '已退回额度停用申请':
      view[item] = SetView('r4-3-4');
      break;
    case '新增额度启用':
      view[item] = SetView('r4-4-1');
      break;
    case '额度启用审核':
      view[item] = SetView('r4-4-2');
      break;
    // case '额度启用查询':
    //   view[item] = SetView('r4-4-3');
    //   break;
    case '已退回额度启用申请':
      view[item] = SetView('r4-4-4');
      break;
    case '额度延时申请':
      view[item] = SetView('r4-5-4');  
      break;
    case '额度延时申请审核':
      view[item] = SetView('r4-5-1');  
      break;
    case '已退回额度延时申请':
      view[item] = SetView('r4-5-2');  
      break;
    case '额度延时申请查看':
      view[item] = SetView('r4-5-3');  
      break;
    case '大树风控额度准入查看推送':
      view[item] = SetView('r4-6-1');  
      break;
    case '化工交易信息查看推送':
      view[item] = SetView('r4-6-2');  
      break;
    case '大树风控额度查看推送':
      view[item] = SetView('r4-6-3');  
      break;
    default:
      ;
  }
})
const getMenu = (parent_path, info) => () => {
  const list = info.map((item, index) => {
    const CompIcon = item.icon;
    switch (index) {
      case 0:
        return <C41 key="C41" />;
      case 1:
        return <C43 key="C43" />;
      case 2:
        return <C44 key="C44" />;
      case 3:
        return <C45 key="C45" />;
      case 4:
        return <C46 key="C46" />;
      default:
        return (
          <ListItem button component={Link} to={`${parent_path}/${item.path}`} key={item.path}>
            <ListItemIcon>
              <CompIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={item.path} />
          </ListItem>
        );
    }
  });
  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
};
const getContent = (parent_path, info) => () => {
  return (
    <Switch>
        <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${subRootAry41[2]}`}
      />
      {info.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${parent_path}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${parent_path}/${item.path}`}
              render={() => <h2>{item.path}</h2>}
              key={item.path}
            />
          )
        }
      })}
      {subRootAry.map(item => {
        if (view[item]) {
          return (
            <Route
              path={`${parent_path}/${item}`}
              component={view[item]}
              key={item}
            />
          )
        }
        else {
          // return null;
          return (
            <Route
              path={`${parent_path}/${item}`}
              render={() => <h2>{item}</h2>}
              key={item}
            />
          )
        }
      })}
    </Switch>

  )
};
export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];