const { string, number, date } = require('yup');
const decimals = /^([1-9]|[1-9]\d|100)(\.\d{1,2})?$/ //数字1-100 保留两位小数
//贸易合同
export const C1412Formik = (form2) => {
  return {
    customer_name:
      string()
        .trim().required(form2.customer_name),
    contract_validity:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),
    signing_time:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),
    entry_time:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),

  }

}

//发票
export const C1422Formik = (form2) => {
  return {
    customer_name:
      string()
        .trim().required(form2.customer_name),
    billing_date:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),
    entry_time:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),
    amount:
      number()
        .required(form2.amount)
        .moreThan(0, '金额必须大于0')
        .integer('金额为整数')
  }

}

//应收款回款
export const C1432Formik = (form2) => {
  return {
    customer_name:
      string()
        .trim().required(form2.customer_name),
    refund_date:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),
    refund_amount:
      number()
        .required(form2.refund_amount)
        .moreThan(0, '金额必须大于0')
        .integer('金额为整数')

  }

}

//应收款池
export const C1442Formik = (form2) => {
  return {

    impawn_rate:
      number()
        .required(form2.impawn_rate)
        .max(100, '数字不能大于100')
        .test('impawn_rate', '请输入1~100的数字最多保留2位小数', value => decimals.test(value)),

  }

}




