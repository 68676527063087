// import React from 'react';
// import { subRouters } from './common';
// import { SetView } from './commonTable';
// import {
//   OfflineBolt as OfflineBoltIcon,
//   History as HistoryIcon,
// } from '@material-ui/icons';
// import { Switch, Route,Redirect, Link } from 'react-router-dom';
// import {
//     ListItem,
//     ListItemIcon,
//     ListItemText,
//   } from '@material-ui/core';
// import { subRootAry1 } from './containers/C19_1'
// import { subRootAry2 } from './containers/C19_2'
// import { subRootAry3 } from './containers/C19_3'
// import { C19_1 as C191  } from './containers'
// import { C19_2 as C192  } from './containers'
// import { C19_3 as C193  } from './containers'
// import Accordion from '../components/Accordion'
// const PARENT_PATH = subRouters['银行预审'];
// const subRootAry = [
//     ...subRootAry1,
//     ...subRootAry2,
//     ...subRootAry3,
//   ];
// const Info = [
//   {
//     path: "中信银行预审",
//     icon: OfflineBoltIcon,
//     component: C191,
//   },
//   {
//     path: "光大银行预审",
//     icon: OfflineBoltIcon,
//     component: C192,
//   },
//   {
//     path: "北京银行预审",
//     icon: OfflineBoltIcon,
//     component: C193,
//   },
  
// ];
// const view = {};
// subRootAry.forEach(item => {
//   switch (item) {
//     case '中信融资预审':
//       view[item] = SetView('r19-1-1');
//       break;
//     case '中信已完成融资':
//       view[item] = SetView('r19-1-2');
//       break;
//     case '光大融资预审':
//       view[item] = SetView('r19-2-1');
//       break;
//     case '光大已完成融资':
//       view[item] = SetView('r19-2-2');
//       break;
//     case '北京银行融资预审':
//       view[item] = SetView('r19-3-1');
//       break;
//     default: ;
//   }
// })
// const getMenu = (parent_path, info) => () => {
//   const list = info.map((item, index) => {
//     const CompIcon = item.icon;
//     switch (index) {
//       case 0:
//         return <C191 key="C191" />;
//       case 1:
//         return <C192 key="C192" />;
//       case 2:
//         return <C193 key="C193" />;
//       default:
//         return (
//           <ListItem button component={Link} to={`${parent_path}/${item.path}`} key={item.path}>
//             <ListItemIcon>
//               <CompIcon fontSize="small" />
//             </ListItemIcon>
//             <ListItemText primary={item.path} />
//           </ListItem>
//         );
//     }
//   });
//   return (
//     <div>
//       {list}
//       <ListItem><Accordion /></ListItem>
//     </div>
//   );
// };
// const getContent = (parent_path, info) => () => {
//     return (
//       <Switch>
//         <Redirect
//           exact
//           from={PARENT_PATH}
//           to={`${PARENT_PATH}/${subRootAry1[0]}`}
//         />
//         {info.map(item => {
//           if (item.component) {
//             return (
//               <Route
//                 path={`${parent_path}/${item.path}`}
//                 component={item.component}
//                 key={item.path}
//               />
//             )
//           }
//           else {
//             return (
//               <Route
//                 path={`${parent_path}/${item.path}`}
//                 render={() => <h2>{item.path}</h2>}
//                 key={item.path}
//               />
//             )
//           }
//         })}
  
//         {subRootAry.map(item => {
//           if (view[item]) {
//             return (
//               <Route
//                 path={`${parent_path}/${item}`}
//                 component={view[item]}
//                 key={item}
//               />
//             )
//           }
//           else {
//             // return null;
//             return (
//               <Route
//                 path={`${parent_path}/${item}`}
//                 render={() => <h2>{item}</h2>}
//                 key={item}
//               />
//             )
//           }
//         })}
//       </Switch>
  
//     )
//   };

// export default [
//   getMenu(PARENT_PATH, Info),
//   getContent(PARENT_PATH, Info)
// ];
