import React, { Component } from 'react'

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { DEFAULT_UPLOADS_URL,DEFAULT_EXPORT_URL } from '../../config/constants'
// import { fetching } from '../../config/utils'
const styles = (theme) =>
  createStyles({
    root: {
      maxWidth: '1000px'
    },
  });
class Upload extends Component {
  state = {
    value: '',
    name: '',
    open: false,
    open2: false,
    message: '',
    fileList: [],
    imgUrl: '',
    mold:'',
  }

  componentWillMount() {
    if (this.props.fileList instanceof Array) {
      this.setState({ fileList: this.props.fileList })
    }
  }

  handleChange = (e) => {
    const { uploadName = '',mold='' } = this.props;
    let files = e.currentTarget.files;
    let type2 = files[0].type;
    let fileList = Array.from(files);
    if (files[0].size / 1024 / 1024 > 10) {
      this.setState({ open: true, message: '上传文件大小不能超过10M', value: '' });
      return;
    }
    if (
      type2 === 'image/png' || type2 === 'image/jpeg' ||
      type2 === 'image/jpg' || type2 === 'application/msword' || type2 === 'application/pdf' ||
      type2 === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.props.uploadChange({ file: fileList[0], uploadName ,mold })
      this.setState({ fileList });
    } else {
      alert('上传文件格式只能是jpg/jpeg/png/pdf/doc/docx')
    }


  }
  //消息提示
  handleClose = () => {
    this.setState({ open: false });
  }
  //删除上传文件
  handleUplaodClose = ({ target: index }) => {
    let fileList = this.state.fileList;
    fileList.splice(index, 1);
    this.setState({ fileList, value: '' });
  }
  //图片模态框
  handleClose2 = () => {
    this.setState({ open2: false });
  }
  //查看实例
  /*viewFile = () => {
    const files = this.state.fileList;
    const { uploads = "" } = this.props;
    if (files[0] instanceof Object) {
      let imgUrl = URL.createObjectURL(files[0]) || '';
      if (files[0].type === 'image/jpeg' || files[0].type === 'image/png' || files[0].type === 'image/jpg') {
        this.setState({ open2: true, imgUrl });
      } else {
        window.open(imgUrl)
      }
    } else {
      let imgUrl2 = uploads ? DEFAULT_UPLOADS_URL + uploads : '';
      let typeIndex = imgUrl2.lastIndexOf('.');
      if (typeIndex !== -1) {
        let types = imgUrl2.slice(typeIndex);
        if (types === '.jpg' || types === '.png' || types === '.jpeg') {
          this.setState({ open2: true, imgUrl: imgUrl2 });
        } else {
          window.open(imgUrl2)
        }

      } else {
        alert('你还没有上传文件')
      }
    }
  }*/
  //查看实例
  viewFile = () => {
	//debugger
    const files = this.state.fileList;
    const { uploads = "" } = this.props;
    const exercise = this.props.exercise;
    if (files[0] instanceof Object) {
      let imgUrl = URL.createObjectURL(files[0]) || '';
      if (files[0].type === 'image/jpeg' || files[0].type === 'image/png' || files[0].type === 'image/jpg' || 
      files[0].type === 'application/pdf' || files[0].type === 'application/msword' ||
      files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.setState({ open2: true, imgUrl });
      } else {
        window.open(imgUrl)
      }
    } else {
      const { mold='', uploads = {}, uploadName = '' } = this.props;
      if (exercise.uploads !== undefined) {
      //const { mold='', uploads = {}, uploadName = '' } = this.props;
      let type = 1;
      let _id =  exercise._id;
      let imgUrl = "";
      if (uploadName === "sharing_protocol" && exercise.sharing_protocol === false) {
        alert('你还没有上传文件')
        return
      } else if (uploadName === "commitment_letter" && exercise.commitment_letter === false) {
        alert('你还没有上传文件')
        return
      } else if (uploadName === "sharing_protocol" && exercise.sharing_protocol === true) {
        type = 1;
        imgUrl = DEFAULT_EXPORT_URL + `smrd/upload/view?_id=${_id}&type=${type}`;
        window.open(imgUrl)
      } else if (uploadName === "commitment_letter" && exercise.commitment_letter === true) {
        type = 2;
        imgUrl = DEFAULT_EXPORT_URL + `smrd/upload/view?_id=${_id}&type=${type}`;
        window.open(imgUrl)
      }
    } else if (uploadName === "business_license_file" && exercise.business_license_file === true) {
      let imgUrl = DEFAULT_EXPORT_URL + `msapi/upload/view?id=`+this.props.exercise.id+"&type="+parseInt(mold)+"&field="+uploadName
      window.open(imgUrl)
    } else {
      alert('你还没有上传文件')
      return
    }
  }
   
  }

  render() {
    const { message, open, open2, value, imgUrl, fileList } = this.state;

    const { msgClose, uploadClose, disabled = false, exercise={},fileName = true, viewFile = true, view = '查看', name = '新增' } = this.props;
    const action = () => {
      if (msgClose) {
        return [
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]
      } else {
        return []
      }
    }
    return (
      <div className="uploadBox">
        <div style={{ margin: '15px 0', display: 'flex' }}>
          <Button disabled={disabled} variant='contained'
            style={{ position: 'relative', marginRight: '10px', overflow: 'hidden' }}>
            <input type="file" name={this.props.uploadName} value={value} className="uploadFile" onChange={this.handleChange}></input>
            {name}
          </Button>
          {viewFile && <Button variant='contained' onClick={this.viewFile}>{view}</Button>}
        </div>
        {
          fileName &&
          fileList.map((item, index) => {
            return (
              <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <span title={item.name} className="fileName" style={{ margin: '0 10px' }}>{item.name}</span>
                {uploadClose && <IconButton
                  key="close"
                  aria-label="close"
                  color="primary"
                  index={index}
                  size="small"
                  onClick={this.handleUplaodClose}
                >
                  <CloseIcon />
                </IconButton>}
              </div>
            )
          })
        }

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open}
          style={{ background: '#09f' }}
          autoHideDuration={4000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{message}</span>}
          action={action()}
        />
        <Dialog maxWidth={'lg'} onClose={this.handleClose2} aria-labelledby="customized-dialog-title" open={open2}>
          <MuiDialogTitle id="customized-dialog-title">
            <span>{view}</span>
            <IconButton aria-label="close" onClick={this.handleClose2}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <MuiDialogContent style={{ minWidth: '600px', maxWidth: '900px', minHeight: '400px' }}>
            <img style={{ maxWidth: '800px' }} src={imgUrl} alt='' />
          </MuiDialogContent>

        </Dialog>
      </div>
    )
  }
}
export default withStyles(styles)(Upload);
