//额度申请第三个表单
export default {
    credit_approval: "额度审批书*",
    licences_copies: "营业执照正副本复印件",
    account_permit_copies: "开户许可证复印件",
    other_business_license: "其他经营许可证复印件",
    constitution: "章程",
    capital_verification_report: "验资报告",
    legal_idcard_and_resume: "法人代表身份证复印件及简历",
    Introduction: "简介",
    audit_reports_and_statements: "审计报告及报表",
    tax_return: "纳税申报表",
    business_registration_info: "工商登记信息",
    enterprise_credit_report: "企业征信查询报告",
    contracts_and_invoices: "合同、发票",
    other_supplely_info: "其他补充资料",
    credit_approval_type: '原件',
    licences_copies_type: '原件',
    account_permit_copies_type: '原件',
    other_business_license_type: '原件',
    constitution_type: '原件',
    capital_verification_report_type: '原件',
    legal_idcard_and_resume_type: '原件',
    Introduction_type: '原件',
    audit_reports_and_statements_type: '原件',
    tax_return_type: '原件',
    business_registration_info_type: '原件',
    enterprise_credit_report_type: '原件',
    contracts_and_invoices_type: '原件',
    other_supplely_info_type: '原件',
}