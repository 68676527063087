import React, { Component } from 'react'
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  TextField,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';


import FormikError from './formikError';
import { pwdFormik } from './formikValidate'

const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }
}));
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    form: this.getInitState(),
    form2: this.props.form2 || {},//静态资源
    loading: false,
  }
  async componentDidMount() {

  }

  getInitState() {
    const { exercise } = this.props
    const form = {
      new_password: "",
      confirm_password: "",
    }
    return { ...form, ...exercise }
  }

  //保存 提交
  handleSubmit = (form2, name) => {
    let form = form2 || this.state.form;
    const text = name || '撤销';

    let ids = form.id;
    let { new_password } = form2


    //提交表单s
    if (text === '提交') {
      this.setState({ loading: true})
        fetching(`/msapi/user/save/${ids}`, {
          method: 'post',
          // token: this.state.hasToken,
          body: JSON.stringify({password:new_password})
        }).then((data) => {
          try {
            if(data){
              if(data.msg){
                alert(data.msg)
                return;
              }
              this.props.onSubmit({
                ...form,
                submit_name: text
              })
            }else {
              alert('提交失败')
            }
          } catch (error) {console.log(error)}
        })
    } else {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
    }
  }

  render() {
    const form2 = this.state.form2;
    const form = this.state.form;


    const { exercise } = this.props;

    const status = (handleSubmit, isSubmitting, values) => {
      return <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
      <div style={{ position: 'relative' }}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          disabled={this.state.loading}
          name={form2.submit}
          style={{ marginRight: '10px' }}
        >
          {form2.submit}
          {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
        </Button>
      </div>
        <Button
          color='primary'
          variant='contained'
          onClick={this.handleSubmit.bind(this, values, '撤销')}
          name={form2.revocation}
          disabled={isSubmitting}
          style={{ marginRight: '10px' }}
        >
          {form2.revocation}
        </Button>
      </div>
    }

    return (

      <Formik
        initialValues={{
          ...form
        }}
        validate={(values) => {
          return pwdFormik(values, form2);
        }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, '提交');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting
        }) => (
          <form>

          <Grid container justify="space-between">

            <TextField
              label={form2.account}
              value={exercise.account}
              name='account'
              margin='normal'
              fullWidth
              disabled={true}
            />

          </Grid>
          {exercise.DialogTitle === form2.updataPwd &&
            <Grid container>
              <TextField
                label={form2.old_password}
                value={values.old_password || ''}
                name='old_password'
                onChange={handleChange}
                onBlur={handleBlur}
                margin='normal'
                fullWidth
                type='password'
                disabled={exercise.save === '3'}
              />
              <FormikError touched={touched} errors={errors} name={'old_password'} />
            </Grid>
          }

          <Grid container>
            <TextField
              //label={form2.new_password}
              label={form2.new_password+"请输入新口令(最少8位,且必须包含字母、数字和特殊字符)"}
              value={values.new_password || ''}
              name='new_password'
              onChange={handleChange}
              onBlur={handleBlur}
              margin='normal'
              fullWidth
              type='password'
              disabled={exercise.save === '3'}
            />
            <FormikError touched={touched} errors={errors} name={'new_password'} />
          </Grid>
          <Grid container>
            <TextField
              //label={form2.confirm_password}
              label={form2.confirm_password+"请再次输入新口令(最少8位,且必须包含字母、数字和特殊字符)"}
              value={values.confirm_password || ''}
              name='confirm_password'
              type='password'
              onChange={handleChange}
              onBlur={handleBlur}
              margin='normal'
              fullWidth
              disabled={exercise.save === '3'}
            />
            <FormikError touched={touched} errors={errors} name={'confirm_password'} />
          </Grid>
          {
            status(handleSubmit, isSubmitting, values)
          }
        </form>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);