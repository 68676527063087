
import getSubRouters from './helper';

export const subRootAry2 = [
    '财务报表查询',
    '定期检查情况查询',
  ];


const PARENT_PATH = '/risk-management/保后管理';
  const PATH = '贷后定期管理';
  export default getSubRouters(PARENT_PATH, PATH, subRootAry2);
