import React, { Component } from 'react';
import { Directions,} from '@material-ui/icons'
import MaterialTable from '../vendors/material-table';
import { Redirect } from "react-router-dom";
import { loginInfos } from '../components/numToText';
import { DEFAULT_ACCOUNT_URL } from '../config/constants' //支付注册路径
export const BASE = '/business';

class View extends Component {
  state = {
    columns: [
      { title: '状态', field: 'status' },
      { title: '待办内容', field: 'content' },
    ],
    data: [],
    loginFlag: false,
    routers: '',
    hasToken: sessionStorage.getItem("authToken"),
    auth: {},//登录信息
  };

  onview = (e, data) => {
    let route = ""; 
    let loginInfo = loginInfos();
    if (loginInfo.roles[0].id === 13) {
      if (data.content === "清算平台注册 → 待处理" && data.status === '待处理') {  
        alert("在清算平台注册时,请务必保证企业名称与当前注册公司名称保持一致")
        window.open(`${DEFAULT_ACCOUNT_URL}`);   //20240417
        route = '/business/额度管理/我的额度'
      } else{//没有覆盖到的跳转业务系统页面
        alert("请先在用户管理里分配操作员和复核员账号，如已分配账号请切换操作员账号进行签收")
        route = '/business/用户管理/用户管理'
      }
      this.setState({
        loginFlag: true,
        routers:route
      })
    } else {
    if ((data.content === "融单开立 → 待签收" || data.content === "融单流转 → 待签收" || data.content === "融单融资 → 待签收") && data.status === '待签收') {
      route = '/business/融单流转/融单签收'
    } else if (data.content === "融单开立 → 待处理" && data.status === '拒签收') {
      route = '/business/融单管理/已拒签融单'
    } else if (data.content === "融单流转 → 待处理" && data.status === '拒签收') {
      route = '/business/融单流转/已拒签流转'
    } else if (data.content === "融单开立 → 待处理" && data.status === '已退回') {
      route = '/business/融单管理/已退回融单'
    } else if (data.content === "融单流转 → 待处理" && data.status === '已退回') {
      route = '/business/融单流转/已退回流转'
    } else if (data.content === "融单融资 → 待处理" && data.status === '已退回') {
      route = '/business/融单流转/已退回融资'
    } else if (data.content === "融单融资 → 待复核" && data.status === '已签收') {
      route = '/business/融单流转/融资复核'
    } else if (data.content === "融单开立 → 待复核" && data.status === '待复核') {
      route = '/business/融单管理/融单开具复核'
    } else if (data.content === "融单流转 → 待复核" && data.status === '待复核') {
      route = '/business/融单流转/融单流转复核'
    } else if (data.content === "融单融资 → 待复核" && data.status === '待复核') {
      route = '/business/融单流转/融资复核'
    } else if (data.content === "融单应收账款 → 待复核" && data.status === '待复核') {  
      route = '/business/应收账款/应收账款复核'
    } else if (data.content === "融单应收账款 → 待签收" && data.status === '审核中') {  
      route = '/business/应收账款/应收账款签收'
    } else if (data.content === "融单应收账款 → 已退回" && data.status === '待处理') {  
      route = '/business/应收账款/已退回应收账款'
    } else if (data.content === "清算平台注册 → 待处理" && data.status === '待处理') {  
      alert("在清算平台注册时,请务必保证企业名称与当前注册公司名称保持一致")
      window.open(`${DEFAULT_ACCOUNT_URL}`);   //20240417
      route = '/business/额度管理/我的额度'
    } else{//没有覆盖到的跳转业务系统页面
      route = '/business/额度管理/我的额度'  
    }
    this.setState({
      loginFlag: true,
      routers:route
    })
   }
  };

  onview2 = (e, data) => {
    let route = ""; 
    if ((data.content === "融单开立 → 待审核" || data.content === "融单开立 → 待签章") && data.status === '审核中') {
      route = '/risk-management/融单/融单开立审核'
    } else if (data.content === "融单流转 → 待审核" && data.status === '审核中') {
      route = '/risk-management/融单/融单流转审核'
    } else if (data.content === "融单融资 → 待审核" && data.status === '审核中') {
      route = '/risk-management/融单/融资申请审核'
    } else if (data.content === "融单融资 → 待分配" && data.status === '待分配') {
      route = '/risk-management/融单/融资申请审核'
    } else if (data.content === "数链应收账款 → 待复核" && data.status === '审核中') {
      route = '/risk-management/应收账款管理/数链应收账款审核'
    } else if (data.content === "融单开立 → 待处理" && data.status === '已退回') {
      route = 'risk-management/融单/已退回融单'
    } else if (data.content === "融单流转 → 待处理" && data.status === '已退回') {
      route = '/risk-management/融单/已退回流转'
    } else if (data.content === "融单融资 → 待处理" && data.status === '已退回') {
      route = '/risk-management/融单/已退回融资'
    } else if (data.content === "额度申请 → 待审核" && data.status === '审核中') {
      route = '/risk-management/额度审批/额度申请审核'
    } else if (data.content === "额度申请 → 待处理" && data.status === '已退回') {
      route = '/risk-management/额度审批/已退回额度申请'
    } else if (data.content === "企业管理 → 待审核" && data.status === '待审核') {
      route = '/risk-management/额度管理/企业管理'
    } else if (data.content === "企业管理 → 待处理" && data.status === '已退回') {
      route = '/risk-management/额度管理/企业管理'
    } else if (data.content === "额度分配 → 待审核" && data.status === '待审核') {
      route = '/risk-management/额度管理/额度分配'
    } else if (data.content === "额度分配 → 待审核" && data.status === '审核中') {
      route = '/risk-management/额度管理/额度分配'
    } else if (data.content === "额度分配 → 待处理" && data.status === '已退回') {
      route = '/risk-management/额度管理/额度分配'
    } else if (data.content === "额度回收 → 待审核" && data.status === '待审核') {
      route = '/risk-management/额度管理/额度回收'
    } else if (data.content === "额度回收 → 待处理" && data.status === '已退回') {
      route = '/risk-management/额度管理/额度回收'
    } else if (data.content === "利率维护 → 待审核" && data.status === '待审核') {
      route = '/risk-management/利率管理/利率维护'
    } else if (data.content === "利率维护 → 待处理" && data.status === '已退回') {
      route = '/risk-management/利率管理/利率维护'
    } else if ((data.content === "融单融资 → 待放款" || data.content === "融单融资 → 待签章") && data.status === '待放款') {
      route = '/risk-management/融单/融资申请审核'
    } else if (data.content === "融单应收账款 → 已退回" && data.status === '待处理') {  
      route = '/risk-management/应收账款管理/已退回应收账款'
    } else if (data.content === "融单应收账款 → 审核中" && data.status === '审核中') {  
      route = '/risk-management/应收账款管理/应收账款审核'
    } else if (data.content === "融单应收账款 → 待签章" && data.status === '待签章') {  
      route = '/risk-management/应收账款管理/应收账款审核'
    } else if (data.content === "融单应收账款 → 待放款" && data.status === '待放款') {  
      route = '/risk-management/应收账款管理/应收账款审核'
    } /*else if (data.content === "授信启用 → 待审核" && data.status === '待审核') {
      route = '/risk-management/授信审批/新增授信额度启用'
    } else if (data.content === "授信启用 → 待处理" && data.status === '已退回') {
      route = '/risk-management/授信审批/已退回授信额度启用申请'
    } */else if (data.content === "额度冻结 → 待复核" && data.status === '审核中') {
      route = '/risk-management/额度审批/额度停用审核'
    } else if (data.content === "额度冻结 → 待处理" && data.status === '已退回') {
      route = '/risk-management/额度审批/已退回额度停用申请'
    } else if (data.content === "额度解冻 → 待复核" && data.status === '审核中') {
      route = '/risk-management/额度审批/额度启用审核'
    } else if (data.content === "额度解冻 → 待处理" && data.status === '已退回') {
      route = '/risk-management/额度审批/已退回额度启用申请'
    } else {//没有覆盖到的跳转业务系统页面
      route = '/risk-management/融单'  
    }
    this.setState({
      loginFlag: true,
      routers:route
    })
    
  };

  onDelete = () => {};

  render() {
    const { columns, routers } = this.state;
    const { data = [] } = this.props;
    // const actions = [{ icon: ViewComfy, tooltip: '跳转', onClick: this.onview }]
    let loginInfo = loginInfos();
    let actions = [];
    if(loginInfo.organization.id === 1 || loginInfo.organization.name === "陕西长安汇通商业保理有限公司") { 
      actions = [
        { icon: Directions, tooltip: '跳转详情页面', onClick: this.onview2 }
      ];
    } else {
      actions = [
        { icon: Directions, tooltip: '跳转详情页面', onClick: this.onview }
      ];
    }
    if (this.state.loginFlag) {
      return <Redirect to={routers} />;
    }
    return <div>
      <MaterialTable
        title={'待办内容列表'}
        columns={columns}
        data={data}
        // actions={[]}
        actions={actions}
        localization={localization}
        options={options}
      />
    </div>
  }
}

const localization = {
  header: {
    actions: '操作'
  }
};

const options = {
  actionsCellStyle: { padding: '0 10px' },
  headerStyle: { //表格头部
    backgroundColor: '#1d4583',
    color: '#FFF',
    whiteSpace: 'nowrap'
  },
  rowStyle: { //表行
    backgroundColor: '#fff',
    whiteSpace: 'nowrap'
  },
  sorting: true,  //排序
  // selection: true //多选框
};

export default View;
