import React, { Component } from 'react'
import Select from 'react-select';
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  Button,
  Grid,
  TextField,
  CircularProgress
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { numberfun, commafy, delcommafy } from '../../components/numToText';
import FormikError from '../../components/commonForm/formikError';
import { C52Formik } from './formikValidate'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CheckForm from '../../components/commonForm/CheckForm';
import DateFnsUtils from '@date-io/date-fns';
import { Upload } from '../../components/upload2';
import { formatDate } from '../../components/date'
import cnLocale from "date-fns/locale/zh-CN";
import { green } from '@material-ui/core/colors';

const useStyles = (theme => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    form: this.getInitState(),
    formStatic: this.props.formStatic || {},//静态资源
    selectCustomerList: [],
    selectSubsidiaryList: [],
    loading: false,
    uploads: this.getUploads(),
  }
  async componentWillMount() {
    const { exercise } = this.props;
    if (exercise.save !== '3') {
      this.queryEnterpriseInfo();
      if (exercise.save === '2') {
        this.queryAmount(exercise.enterprise_id)
      }
    }

  }
  getUploads() {
    const { exercise = {} } = this.props;
    if (Array.isArray(exercise.uploads) && exercise.uploads.length > 0) {
      let uploads = {}
      exercise.uploads.forEach(item => {
        uploads[item.field] = item.path;
      })
      return uploads
    } else {
      return {}
    }
  }

  getInitState() {
    const { exercise } = this.props
    let mx_amount = exercise.mx_amount ? {limitMark:exercise.limit_mark, mxAmount:exercise.mx_amount} : '';
    const form = {
      select_customer: "",
      select_subsidiary: "",
      mx_amount: '',
      usable_amount: '',
      subsidiary_amount: '',
      subsidiary_date: new Date(),
      sharing_protocol: '',
      commitment_letter: '',
      credit_due_date: '',
    }
    if (exercise.save !== '1') {
      form.select_customer = {
        id: exercise.enterprise_id,
        value: exercise.enterprise_name,
        label: exercise.enterprise_name,
        enterprise_no: exercise.enterprise_no,
        enterprise_name: exercise.enterprise_name
      }
      form.select_subsidiary = {
        sub_company_id: exercise.sub_company_id,
        value: exercise.sub_company_name,
        label: exercise.sub_company_name,
        sub_company_no: exercise.sub_company_no,
        sub_company_name: exercise.sub_company_name
      }
    }
    return exercise.save === '1' ? { ...form } : { ...form, ...exercise , mx_amount}
  }
  uploadFile(e){
    let form = this.state.form;
    var fordata = new FormData();
    if (form.status_id === 9 ) {//补充上传文件
        fordata.append('type',6);
        fordata.append('fileName',e.file);
        fordata.append('fileSource',e.uploadName);
        fordata.append('id',form._id);
        const options = {
          method: "POST",
          body: fordata
        }
        global.count = (global.count+1)
        return fetch(`/smrd/upload/reUpload`,options) 
    } else {
      fordata.append('type',4);
      fordata.append('fileName',e.file);
      const options = {
        method: "POST",
        body: fordata
      }
      global.count = (global.count+1)
      return fetch(`/smrd/upload/upload`,options)
    }
      }
      uploadHandler = async (e,type) => {
        let indexId = '';
        indexId = await this.uploadFile(e).then(res => {
          if (res) {
            if (!res.status){
              alert(res.msg)         
            }else{
              return res.json()
            }
          }else{
            return null
          }
        }).then(r=>{      
          if (r.error.code === 200) {//无错
          alert("文件上传成功");
         /*clearInterval(timer);
         this.setState({progressLength : 100})
         setTimeout(() => {
           alert("文件上传成功");
           this.setState({ progress: false })
         }, 500)*/
         return r.data
       } else {//业务失败
         alert(r.error.message)
         //this.setState({ progress: false })
         return null;
       }
        }) 
        if(type === 1){
          this.state.form.sharing_protocol_id = indexId 
        }else if(type === 2){
          this.state.form.commitment_letter_id = indexId 
        }
        return true
      }
  queryEnterpriseInfo = () => {
    fetching('/msapi/organizations', {
      method: 'GET',
    }).then(res => {
      if (res !== undefined && res !== null) {
        try {
          let datas = res.filter((item) => {
            item.value = item.name;
            item.label = item.name;
            item.enterprise_name = item.name;
            return (item.id !== 1) ? item : null;
          })
          this.setState({ selectCustomerList: datas });
        } catch (error) {
        }
      } else {
        alert('提交失败')
      }
    })
  }
  queryAmount = (organization_id) => {
    if (!organization_id) {
      return;
    }
    /*fetching('/msapi/totalamount/find?organization_id=' + organization_id, {
      method: 'GET',
    }).then(res => {*/
    fetching(`/amount/usedAmount/issuesGetFilterAmount?organizationId=${organization_id}`).then(res => {
      if (res !== undefined && res !== null) {
        try {
          if (res.data.length > 0) {
            this.setState({ amountList: res.data });
            // const { mx_amount, usable_amount } = res[0]
            // this.setState({ form: { ...this.state.form, mx_amount: commafy(mx_amount), usable_amount: commafy(usable_amount) } });
          } else {
            alert('该集团客户暂无可用额度')
          }

        } catch (error) {
        }
      } else {
      }
    })
  }
  querySubsidiary = (enterprise_no) => {
    fetching(`/smrd/business_management/find?enterprise_no=${enterprise_no}&status_id=9`, {
      method: 'GET',
    }).then(res => {
      this.setState({ selectSubsidiaryList: [] });
      this.setState({ amountList: [] });
      try {
        if (res !== undefined && res.length > 0) {

          //const { subsidiaries = [] } = res[0]

          //企业关联不是一次完成的--20200916
          if (res.length === 1) {
            const { subsidiaries = [] } = res[0];

            let datas = subsidiaries.filter((item) => {
              item.value = item.sub_company_name;
              item.label = item.sub_company_name;
              return item;
            })
            this.setState({ selectSubsidiaryList: datas });

          } else {
            const subsidiaries = [];
            for (const su of res) {
              const sub1 = su.subsidiaries;
              if ( !Array.isArray(sub1) || sub1.length === 0) {
                continue;
              }
              for (const s of sub1) {
                subsidiaries.push(s);
              }
            }

            let datas = subsidiaries.filter((item) => {
              item.value = item.sub_company_name;
              item.label = item.sub_company_name;
              return item;
            })
            this.setState({ selectSubsidiaryList: datas });
          }
          

          /*let datas = subsidiaries.filter((item) => {
            item.value = item.sub_company_name;
            item.label = item.sub_company_name;
            return item;
          })
          this.setState({ selectSubsidiaryList: datas });*/
        } else {
          alert('该集团客户暂未关联子公司')
        }
      } catch (error) { }
    })
  }
  queryCreditDate = (organization_id) => {
    if (!organization_id) {
      return;
    }
    fetching('/msapi/amount/find?organization_id=' + organization_id, {
      method: 'GET',
    }).then(res => {
      if (res !== undefined && res !== null) {
        try {
          if (res.length > 0) {
            let date = ''
            res.forEach((items) => {
              if (items.credit_due_date) {
                if (date) {
                  var oDate1 = new Date(date);
                  var oDate2 = new Date(items.credit_due_date);
                  if (oDate1.getTime() > oDate2.getTime()) {
                  }else{
                    date = items.credit_due_date
                  }
                } else {
                  date = items.credit_due_date
                }
              }
            })
            this.setState({ form: { ...this.state.form, credit_due_date: date } });
          }
        } catch (error) {
        }
      } else {
      }
    })
  }
  //输入框值改变
  handleChange1 = (name, value) => {
    let value2 = value;
    let organizationId = value.sub_company_id
    if (name === 'select_customer') {
      this.querySubsidiary(value.enterprise_no)
      this.queryAmount(value.id)
      this.queryCreditDate(value.id)
    } else if (name === 'select_subsidiary') {
      fetching(`/amount/usedAmount/getTotalAmount?organizationId=${organizationId}`).then(res => {
        if (res !== undefined && res !== null) {
    if (res.data.length > 0) {
            if (res.data[0].amountStatus !== 1) {
              let ttid = res.data[0].id  
              fetching(`/amount/usedAmount/getAmountTpye?ttid=${ttid}`).then(res => {
                 if (res !== undefined && res !== null) {
                   if (res.data.length > 0) {
                     if (res.data[0].type === 1) {
                       this.props.exercise.CreditSource = true;//额度来源  20210518
                       alert("此公司额度来源为额度申请不能进行额度分配")
                       return
                     }
                   }
                 }
              })
            } else {
              this.props.exercise.amountFrozenStatus = true;//额度状态 20211013
                       alert("此子司额度被冻结,不能进行额度分配")
                       return
            }
              
      }
   }
       })
     let urlCredit = `/msapi/credit1/getCheckingCreditList?name=`;
       fetching(urlCredit + `${value.sub_company_name}`).then(data => {
         if (null !== data && data.data.length !== 0){
           alert("客户有审核中/已保存的额度申请,不能进行额度分配!!!")
           return
         }
       })
    } else if (name === 'subsidiary_amount') {
      value2 = numberfun(value2, 12,2);
    }
    return value2;
  }

  //输入框失去焦点 金额 千分位
  handleBlur1 = (name, value) => {
    return commafy(value);
  }


  //保存 提交
  handleSubmit = (values, name) => {
    const text = name || 'cancle';
    if (text === 'submit') {
      this.setState({ loading: true })
    }
    if(this.props.exercise.CreditSource){ //额度分配额度来源拦截2021518
      alert("子公司额度来源为额度申请,不能进行额度分配操作")
      window.location.reload()
      return
    }
    if(this.props.exercise.amountFrozenStatus){ //额度分配额度冻结拦截20211013
      alert("此子司额度被冻结,不能进行额度分配")
      window.location.reload()
      return
    }
    const { auth } = this.props;
    let paramsDate = { ...values } || { ...this.state.form };
    let ids = paramsDate._id || '';
    //日期、金额转换
    if (paramsDate.subsidiary_date instanceof Object && paramsDate.subsidiary_date.getTime()) {
      paramsDate.subsidiary_date = formatDate(paramsDate.subsidiary_date, 'yyyy-MM-dd');
    }

    let creditStartDate = values.mx_amount.creditStartDate ?new Date(Date.parse(values.mx_amount.creditStartDate)).toLocaleDateString() :new Date(Date.parse(values.credit_start_date)).toLocaleDateString();
    let creditDueDate = values.mx_amount.creditDueDate? new Date(Date.parse(values.mx_amount.creditDueDate)).toLocaleDateString(): new Date(Date.parse(values.credit_due_date)).toLocaleDateString();

    const amountList = ['subsidiary_amount', 'mx_amount', 'usable_amount'];
    amountList.forEach(item => {
      if (item === 'subsidiary_amount') {
        paramsDate[item] = delcommafy(paramsDate[item]);
      } else {
        paramsDate[item] = delcommafy(this.state.form[item]);
      }
    })
    let { select_customer, select_subsidiary } = paramsDate
    let params = {
      ttid: this.state.form.ttid,//20210224
      enterprise_id: select_customer.id,
      enterprise_name: select_customer.enterprise_name,
      enterprise_no: select_customer.enterprise_no,
      sub_company_id: select_subsidiary.sub_company_id || '',
      sub_company_name: select_subsidiary.sub_company_name || '',
      sub_company_no: select_subsidiary.sub_company_no || '',
      //sharing_protocol: paramsDate.sharing_protocol,
      //commitment_letter: paramsDate.commitment_letter, 2021.01.05 
      sharing_protocol: "",
      commitment_letter: "",
      subsidiary_date: paramsDate.subsidiary_date,
      subsidiary_amount: paramsDate.subsidiary_amount,
      //mx_amount: paramsDate.mx_amount,
      mx_amount: this.state.form.mx_amount.mxAmount ? this.state.form.mx_amount.mxAmount : paramsDate.mx_amount,//20210416
      usable_amount: paramsDate.usable_amount,
      limit_mark: values.mx_amount.limitMark,//2021/03/24
      credit_start_date: creditStartDate,
      credit_due_date: creditDueDate,
      auth: auth,
      sharing_protocol_id:this.state.form.sharing_protocol_id,
      commitment_letter_id:this.state.form.commitment_letter_id,
      id: ids || "",
      _id : values._id//补丁20201230
    
    }
    const formData = new FormData();
    for (let name in params) {
      formData.append(name, params[name]);
    }
    //提交表单
    if (text === 'submit') {
    //1.额度分配提交及扣除总公司的额度
    var qAC = params;
    var typeAC = 0;//1-额度分配;2-额度收回
    const ttid = qAC.ttid;
    const oid = qAC.enterprise_id;
    const subAmount = qAC.subsidiary_amount;
    fetching(`/amount/usedAmount/firstHandleQaAndQc?ttid=${ttid}&oid=${oid}&subAmount=${subAmount}&typeAC=${1}`).then(res => {
     if (res !== undefined) {
          if (res.code !== 200)  {
            alert(res.msg || '额度处理失败！')
            return;
          } else {
       fetching(`/smrd/quota_allocation/save/${ids}`, {
         //method: ids ? 'PUT' : 'POST',
         method: ids ? 'POST' : 'POST',
         //body: formData
         body: JSON.stringify(params) 
       }).then((data) => {
         this.setState({ loading: false })
         try {
           if (data !== undefined && data !== null) {
             //if (data.msg) {
             if (data.code !== 200) {
               alert(data.msg)
               return;
             }
             //alert(data.msg)
             this.props.onSubmit({
               ...params,
               submit_name: text
             })
           }
         } catch (error) {
           console.log(error)
           // alert(`${ids ? '额度分配编辑失败' : '额度分配提交失败'}`)
         }
       })
         }
         }
      })         
    } else {
      this.props.onSubmit({
        ...params,
        submit_name: text
      })
    }
  }
  //审核意见
  FormSubmit3 = (data) => {
    this.props.onSubmit({
      ...data,
      submit_name: 'submit'
    })
  }

  handleChange2 = (selectedOption) => {
    const { amount } = this.state.form;
    this.setState({
      form: {
        ...this.state.form,
        ttid: selectedOption.id,//20210223
        mx_amount: commafy(selectedOption.mxAmount),//20210223
        usable_amount: commafy(selectedOption.usableAmount),
      }
    })
    return selectedOption;
  }

  getOptionLabel = data => {
      return data.limitMark + data.mxAmount
  }
  getOptionValue = data => {
    return data.id
  }

  render() {
    const { form, formStatic, selectCustomerList, selectSubsidiaryList, amountList, loading, uploads } = this.state
    const { exercise = {}, auth, dataUrl } = this.props;
    const checkform = () => {
      return <CheckForm auth={auth} exercise={exercise} dataUrl={dataUrl}
        onSubmit={this.FormSubmit3}
      />
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        validate={(values) => {
          return C52Formik(values, formStatic, form.usable_amount, form.credit_due_date);
        }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, 'submit');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
            <div>
              <form>
                <Grid item xs={12}>
                  <div style={{ margin: '5px 0 20px' }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_customer}</span>
                    <Select
                      isSearchable
                      value={values.select_customer}
                      onChange={e => { setFieldValue('select_customer', this.handleChange1.bind(this, 'select_customer', e)());
                      setFieldValue('select_subsidiary','');
                      setFieldValue('mx_amount','');
                      setFieldValue('usable_amount',this.state.form.usable_amount = '')
                    }}
                      options={selectCustomerList}
		      isDisabled={exercise.save === '3' || exercise.DialogTitle === "编辑"}
                    />
                    <FormikError touched={touched} errors={errors} name={'select_customer'} />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ margin: '5px 0' }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_subsidiary}</span>
                    <Select
                      isSearchable
                      value={values.select_subsidiary}
                      onChange={e => {setFieldValue('select_subsidiary', this.handleChange1.bind(this, 'select_subsidiary', e)());
                      setFieldValue('mx_amount','');
                      setFieldValue('usable_amount',this.state.form.usable_amount = '')
                    }}
                      options={selectSubsidiaryList}
                      isDisabled={exercise.save === '3'}
                    />
                    <FormikError touched={touched} errors={errors} name={'select_subsidiary'} />
                  </div>
                </Grid>
                <Grid container justify="space-between">
                  <Grid item xs={5}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.mx_amount}</span>
                      <Select
                      isSearchable 
                      value={values.mx_amount}
                      onBlur={handleBlur}
                      getOptionLabel={this.getOptionLabel}
                      getOptionValue={this.getOptionValue}
                      onChange={e => setFieldValue('mx_amount', this.handleChange2(e))}
                      options={amountList}
                      isDisabled={exercise.save === '3' || exercise.save === '4'}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label={formStatic.usable_amount}
                      value={form.usable_amount}
                      name='usable_amount'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin='normal'
                      inputProps={{ maxLength: 50 }}
                      fullWidth
                      disabled={exercise.save === '3'}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="space-between">
                  <Grid item xs={5}>
                    <TextField
                      label={formStatic.subsidiary_amount}
                      value={values.subsidiary_amount}
                      name='subsidiary_amount'
                      onChange={e => setFieldValue('subsidiary_amount', this.handleChange1.bind(this, 'subsidiary_amount', e.target.value)())}
                      onBlur={e => setFieldValue('subsidiary_amount', this.handleBlur1.bind(this, 'subsidiary_amount', e.target.value)())}
                      margin='normal'
                      inputProps={{ maxLength: 50 }}
                      fullWidth
                      disabled={exercise.save === '3'}
                    />
                    <FormikError touched={touched} errors={errors} name={'subsidiary_amount'} />
                  </Grid>
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="date-picker-inline"
                        label={formStatic.subsidiary_date}
                        value={values.subsidiary_date}
                        disabled={exercise.save === '3'}
                        onChange={e => setFieldValue('subsidiary_date', this.handleChange1.bind(this, 'subsidiary_date', e)())}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'subsidiary_date'} />
                    </Grid>
                      </MuiPickersUtilsProvider>*/}
                </Grid>
                <Grid container justify="space-between">
                  <Grid item xs={5}>
                    <Upload disabled={exercise.save === '3' && (exercise.status_id !== 9 || (this.props.auth.roles[0].id !== 1 && this.props.auth.roles[0].id !== 3) )} type="file"
                      name={formStatic.sharing_protocol}
                      uploads={uploads.sharing_protocol}
                      exercise={exercise}
                      uploadName='sharing_protocol' view={formStatic.view}
                      //uploadChange={e => setFieldValue('sharing_protocol', e.file)}
                      mold = "6"
                      uploadChange={e=>setFieldValue('sharing_protocol',this.uploadHandler(e, 1))}
                      ></Upload>
                    <FormikError touched={touched} errors={errors} name={'sharing_protocol'} />
                  </Grid>
                  <Grid item xs={5}>
                    <Upload disabled={exercise.save === '3' && ( exercise.status_id !== 9 || (this.props.auth.roles[0].id !== 1 && this.props.auth.roles[0].id !== 3) ) } type="file"
                      name={formStatic.commitment_letter}
                      uploads={uploads.commitment_letter}
                      exercise={exercise}
                      uploadName='commitment_letter' view={formStatic.view}
                      //uploadChange={e => setFieldValue('commitment_letter', e.file)}
                      mold = "6"
                      uploadChange={e=>setFieldValue('commitment_letter',this.uploadHandler(e, 2))}
                      ></Upload>
                    <FormikError touched={touched} errors={errors} name={'commitment_letter'} />
                  </Grid>

                </Grid>
                {exercise.save !== '3' &&
                  <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
                    <div style={{ position: 'relative' }}>
                      <Button
                        style={{ margin: '0 10px' }}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={handleSubmit}>提交</Button>
                      {loading && <CircularProgress size={24} style={loadingStyle} />}
                    </div>

                    <Button variant="contained" color="primary" onClick={this.handleSubmit.bind(this, values, 'cancle')}>取消</Button>
                  </div>
                }
              </form>
              {(exercise.save !== '1') &&
                <div>
                  {checkform()}
                </div>}
            </div>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);






