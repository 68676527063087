import React from 'react';
import { Switch, Route,Redirect, Link } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
// import { C2_2subRootAry } from './containers/C2_2'
// import { C2_1subRootAry } from './containers/C2_1'
// import { C2_3subRootAry } from './containers/C2_3'
import Accordion from '../components/Accordion'
import { loginInfos } from '../components/numToText';
// const subRootAry =[
//   ...C2_1subRootAry,
//   ...C2_2subRootAry,
//   ...C2_3subRootAry,
// ]
export const BASE = '/risk-management';

export const subRouters = [
  '系统管理',
  '客户信息',
  '评级限额',
  '额度审批',
  '额度管理',
  '融单',
  '外部融资',
  '银行预审',
  '光大银行预审',
  '放款',
  '保后管理',
  '还款',
  '运营管理',
  '流程管理',
  '参数配置',
  '用户管理',
  '应收账款管理',
  '利率管理',
  '授权审批',
  '合同管理',
  '系统日志',
].reduce((obj, url) => {
  obj[url] = `${BASE}/${url}`;
  return obj;
}, {});

export const getMenu = (parent_path, info) => () => {
  let roleId = 0;
  let auth = loginInfos();
  try {
    if (auth) {
      roleId = auth.roles[0].id || 0;
    }
  } catch (e) {
    roleId = 0;
  }

  let Info = info;
  Info = Info.filter((item) => {
    if (roleId === 8) {
      return (item.path !== "融资授权") ? item : null
    } else if (roleId === 14) {
      return !(item.path === "开立授权" || item.path === "流转授权") ? item : null
    } else {
      return item;
    }

  })
  const list = Info.map(item => {
    const CompIcon = item.icon;
    return (
      <ListItem button component={Link} to={`${parent_path}/${item.path}`} key={item.path}>
        <ListItemIcon>
          <CompIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={item.path} />
      </ListItem>
    )
  })
  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
};

export const getContent = (parent_path, info) => () => {
  return (
    <Switch>
        <Redirect
        exact
        from={parent_path}
        to={`${parent_path}/${info[0].path}`}
      />
      {info.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${parent_path}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${parent_path}/${item.path}`}
              render={() => <h2>{item.path}</h2>}
              key={item.path}
            />
          )
        }
      })}
      {/* {subRootAry.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${parent_path}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${parent_path}/${item}`}
              render={() => <h2>{item}</h2>}
              key={item}
            />
          )
        }
      } */}
      {/* )} */}
    </Switch>

  )
};

export const table = [
  'r2-1',
  'r2-2',
  'r2-3',
  'r2-4',
  'r2-5-2',
  'r2-5',
  'r2-5-1',
  'r2-5-3',
  'r2-5-4',
  'r3-1-1',
  'r3-1-2',
  'r3-1-3',
  'r3-1-4',
  'r3-1-5',
  'r3-1-6',
  'r3-1-7',
  'r3-1-8',
  'r3-2-1',
  'r3-2-2',
  'r3-2-3',
  'r3-2-4',
  'r3-2-5',
  'r3-3-1',
  'r3-3-2',
  'r4-1',
  'r4-2',
  'r4-3',
  'r4-3',
];
