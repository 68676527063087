import React from 'react';
import {
  Container,
  CssBaseline,
  Typography,
  Grid,
} from '@material-ui/core';
import { Home as AppHome } from '@material-ui/icons';
import { bars, footers } from '../components'
import { BusinessRouters } from '../routers'
import { reduxLink } from '../config/reusable'
import DisabledTabs from './Tabs';
import AboutUs from './Info/AboutUs';
import ContactUs from "./Info/ContactUs";
import Marketing from "./Info/Marketing";
import { loginInfos } from '../components/numToText';

function showTopBar (list) {
  return (
    <bars.Bar2>
      <Typography color="inherit" variant="h6" style={{ flexGrow: 1 }}>
        {reduxLink({ icon: AppHome, path: '/home', title: '' })}
      </Typography>
      {list}
    </bars.Bar2>
  )
}

class Home extends React.Component {
  state = {
    BusinessRouters1: [],
    newValue: 1,
  };

  componentDidMount() {
    let BusinessRouters1 = BusinessRouters;
    try {
      let loginInfo = loginInfos();
      if (loginInfo.organization.name === "融单平台") {
        if ([1,2,4].includes(loginInfo.department.id)) {
          BusinessRouters1 = BusinessRouters.filter((item) => {
            return item.path !== '/business' ? item : null
          })
        } else {
          BusinessRouters1 = BusinessRouters.filter((item) => {
            return item.path === '/risk-management' ? item : null
          })
        }
      }else if(loginInfo.organization.name === "陕西长安汇通商业保理有限公司"){
        BusinessRouters1 = BusinessRouters.filter((item) => {
          return item.path === '/risk-management' ? item : null
        })
      } else {
        BusinessRouters1 = BusinessRouters.filter((item) => {
          return item.path === '/business' ? item : null
        })
      }
    } catch (error) { }
    this.setState({BusinessRouters1});
  }

  handlePage = (newValue) => {
    this.setState({newValue})
  };

  // 服务选项, 关于我们, 联系我们, 市场
  getDisplayed = (isVisible) => {
    return (
      <Grid container style={{height: '78vh'}}>
        <Grid item xs={12} style={{display: isVisible===1 ? 'block': 'none'}}>
          <DisabledTabs />
        </Grid>
        <Grid item style={{display: isVisible===2 ? 'block': 'none'}}>
          <AboutUs />
        </Grid>
        <Grid item style={{display: isVisible===3 ? 'block': 'none'}}>
          <ContactUs />
        </Grid>
        <Grid item style={{display: isVisible===4 ? 'block': 'none'}}>
          <Marketing />
        </Grid>
      </Grid>
    )
  };

  render() {
    const {BusinessRouters1, newValue} = this.state;
    const list = BusinessRouters1.map(router => reduxLink(router));
    const displayed = this.getDisplayed(newValue);
    return (
      <>
        <CssBaseline />
        <Container fixed>
          {showTopBar(list)}
          {displayed}
          <footers.Footer1 handlePage={this.handlePage} />
        </Container>
      </>
    );
  }
}

export default Home;