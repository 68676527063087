
import getSubRouters from './helper';

export const subRootAry1 = [
    '融单待清算',
    '融单清算中',
    '融单已清算',
    '扎帐日志',
    '清算日志',
  ];

const PARENT_PATH = '/risk-management/运营管理';
  const PATH = '清算';
  export default getSubRouters(PARENT_PATH, PATH, subRootAry1);