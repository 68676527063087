import React from 'react';
import TabPanels from './TabPanels'
import getSubRouters from './helper';

const PARENT_PATH = '/business/融单管理';
const PATH = '融单开立';

export const subRootAry = [
  "开立中融单",
  "已开融单",
  "已退回融单",
  "已拒签融单",
  "已开融单融资明细",
  // "批量审核"
];

// option 1:
export function tabPanels(ary = []) {
  return <TabPanels ary={ary}/>;
}

export default getSubRouters(PARENT_PATH, PATH, subRootAry);
