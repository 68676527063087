//公用的名称
export default {
    distribute: "分配",//20210414
    export: "导出",//20200917
    exportTable: "导出台账",//20200917
    add: "新增",
    applyDelay: "额度延时申请",
    edit: "编辑",
    updataPwd: "修改密码",
    view: "查看",
    sign: "签章",
    save: "保存",
    submit: "提交",
    revocation: "撤销",
    check: "审核",
    check2: "审核通过",
    creditApproval: '额度申请',
    send_back: "退回上一级",
    send_back2: "退回经办人",
    addBtnName: "新增用户",
    addBtnName1: "新增融单开具制单",
    addBtnName2: "新增融单支付",
    addBtnName3: "新增融资申请",
    addBtnName4: "新建企业关联",
    addBtnName5: "新建额度分配",
    addBtnName6: "新建额度回收",
    addBtnName7:"设置利率",
    addBtnName8: "登记",
    addBtnName9:"善美光大信息录入",
    addBtnName10:"善美光大信息编辑",
    addBtnName11: "新增IP白名单",
    addBtnName12: "新增限制规则",
    status: '状态', // 已保存、待审核、已审核、已退回
    resetPwd: "重置密码",
    old_password: "原密码",
    new_password: "新密码",
    confirm_password: "再次输入密码",
    upload: "上传",
    view_agreement: "查看合同",
    getCellCode: "获取验证码",
    cell_validate_code: "手机验证码",
    signCommon: "签署公章",
    signPerson: "签署法人章",
    signCommon1: "签署客户公章",
    signPerson1: "签署客户法人章",
    amount_unit: '人民币万元起',
    view_loan_terms: "放款前需要落实的条件",
    view_loan_matters: "贷后需要关注的事项",
    select_customer: '选择客户',
    apply_list_fileName1: '资料1',
    data_list: "资料列表",
    file_type: "附件类型",
    handle: "操作",
    report_name: "调查报告",
    customer_infos: "客户概况",
    // enterprise_feature: "客户特征",
    management_info: "管理层信息",
    business_license: "营业执照",
    shareholders_info: "股东情况",
    pass: "校验通过",
    no_pass: "校验未通过",
    yearly_check: "已年检",
    no_yearly_check: '未年检',
    flagText: '校验未通过，请前往客户信息核查客户资料',
    fdd_file_name: '内部合同号',
    file_name: '附件名称',
    bank_number:'联行号',
    bfeMsgIn: '北金所信息录入',
    bfe_preProdNo:  '北金所产品预编号',
    monthly_file_uploads:'文件上传',
    Upload_contracts_invoice: "上传合同发票编号*", 
    Upload_rest:"其他文件",
}