
// import {
//   ChromeReaderMode,
//   WrapText,
//   Textsms,
//   FilterVintage,
//   TrackChanges,
//   Description,
//   PersonPin,
//   Message,
// } from '@material-ui/icons';


import getSubRouters from './helper';

export const subRootAry1 = [
    //'贷后基础条件管理查询',
    '短信提示信息维护',
    '短信相关信息查询',
  ];

  const PARENT_PATH = '/risk-management/保后管理';
  const PATH = '贷后基础管理';
  export default getSubRouters(PARENT_PATH, PATH, subRootAry1);

  // const ary1 = [
  //   {
  //     path: "贷后基础管理",
  //     icon: WrapText
  //   },
  //   {
  //     path: "贷后定期管理",
  //     icon: Textsms
  //   },
  //   {
  //     path: "贷后实时管理",
  //     icon: FilterVintage
  //   },
  //   {
  //     path: "贷后流程管理",
  //     icon: ChromeReaderMode
  //   }
  // ];

  // const subAry1 = [
  //   {
  //     path: "贷后基础条件管理",
  //     icon: WrapText
  //   },
  //   {
  //     path: "短息提示信息维护",
  //     icon: Textsms
  //   },
  //   {
  //     path: "问题资产清处置信息",
  //     icon: FilterVintage
  //   },
  // ];
  // const subAry2 = [
  //   {
  //     path: "财务报表管理",
  //     icon: TrackChanges
  //   },
  //   {
  //     path: "定期检查",
  //     icon: Description
  //   },
  // ];
  // const subAry3 = [
  //   {
  //     path: "风险分类",
  //     icon: WrapText
  //   },
  //   {
  //     path: "风险提示及反馈",
  //     icon: Textsms
  //   },
  //   {
  //     path: "逾期欠息查询",
  //     icon: FilterVintage
  //   },
  //   {
  //     path: "合账管理",
  //     icon: ChromeReaderMode
  //   }
  // ];
  // const subAry4 = [
  //   {
  //     path: "贷后实时风险分类",
  //     icon: PersonPin
  //   },
  //   {
  //     path: "贷后实时预警管理",
  //     icon: Message
  //   },
  //   {
  //     path: "贷后定期管理",
  //     icon: ChromeReaderMode
  //   }
  // ];




// const Collapse1 = (ary) => {
//   const list = ary.map(item => {
//     const CompIcon = item.icon;
//     return (
//       <ListItem
//         button
//         className={classes.nested}
//         component={Link}
//         to={`${PARENT_PATH}/${item.path}`}
//         key={item.path}
//       >
//         <ListItemIcon>
//           <CompIcon />
//         </ListItemIcon>
//         <ListItemText primary={item.path}/>
//       </ListItem>
//     )
//   });
//
//   return (
//     <Collapse in={open} timeout="auto" unmountOnExit>
//       <List component="div" disablePadding>
//         {list}
//       </List>
//     </Collapse>
//   );
// }


// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     maxWidth: 360,
//     backgroundColor: theme.palette.background.paper,
//   },
//   nested: {
//     paddingLeft: theme.spacing(4),
//   },
// }));

// export default function NestedList() {
//   const classes = useStyles();
//   const [open, setOpen] = React.useState(true);
//
//   const handleClick = () => {
//     setOpen(!open);
//   };
//
//   return (
//     <List component="nav">
//       <ListItem button onClick={handleClick}>
//         <ListItemIcon>
//           <InboxIcon />
//         </ListItemIcon>
//         <ListItemText primary="Inbox"/>
//         {open ? <ExpandLess /> : <ExpandMore />}
//       </ListItem>
//
//     </List>
//   )
// }

