import React from 'react';
import { Switch, Route,Redirect, Link } from 'react-router-dom';

import {
  CallToAction,
  OpenInBrowser,
  MergeType,
  MoveToInbox
} from '@material-ui/icons';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { subRouters } from './common';
import { C6_2 as C62, C6_3 as C63, C6_4 as C64 ,C6_5 as C65} from './containers'
import { subRootAry2 } from './containers/C6_2'
import { subRootAry3 } from './containers/C6_3'
import { subRootAry4 } from './containers/C6_4'
import { subRootAry5 } from './containers/C6_5'
import { SetView } from './rongdanContainers/commonTable'
import Accordion from '../components/Accordion'
import { loginInfos } from '../components/numToText'

const PARENT_PATH = subRouters['融单'];
const subRootAry = [
  ...subRootAry2,
  ...subRootAry3,
  ...subRootAry4,
  ...subRootAry5
];
const Info = [
  {
    path: "全部融单",
    icon: CallToAction,
    component:SetView('r6-1')
  },
  {
    path: "融单开立",
    icon: OpenInBrowser,
    component: C62
  },
  {
    path: "融单融资",
    icon: MergeType,
    component: C63
  },
  {
    path: "融单流转",
    icon: MoveToInbox,
    component: C64
  },
//  {
//    path: "招行融资",
//    icon: MoveToInbox,
//    component: C65
//  },
];
const view = {};
subRootAry.forEach(item => {
  switch (item) {
    case '融单开立审核':
      view[item] = SetView('r6-2-1');
      break;
    case '开立融单查看':
      view[item] = SetView('r6-2-2');
      break;
    case '已开融单':
      view[item] = SetView('r6-2-3');
      break;
    case '已退回融单':
      view[item] = SetView('r6-2-4');
      break;
    case '批量审核结果':
      view[item] = SetView('r6-2-5');
      break;
    //开立退回经办人的业务板块功能--20201030
    case '已退回经办人融单':
      view[item] = SetView('r6-2-6');
      break;
    case '已拒签融单':
      view[item] = SetView('r6-2-7');
      break;
    case '已删除融单':
      view[item] = SetView('r6-2-8');
      break;
    case '已退回复核员融单':
      view[item] = SetView('r6-2-9');
      break;
    case '融资申请审核':
      view[item] = SetView('r6-3-1');
      break;
    case '询价记录':
      view[item] = SetView('r6-3-2');
      break;
    case '融资申请中':
      view[item] = SetView('r6-3-3');
      break;
    case '融资查看':
      view[item] = SetView('r6-3-4');
      break;
    case '放款异常':
      view[item] = SetView('r6-3-5');
      break;
    case '已退回融资':
      view[item] = SetView('r6-3-6');
      break;
    case '已融资融单':
      view[item] = SetView('r6-3-7');
      break;
    //融资退回经办人的业务板块功能--20201030
    case '已退回经办人融资':
      view[item] = SetView('r6-3-8');
      break;
      //融资拒签业务板块功能--20201120
    case '已拒签融资':
      view[item] = SetView('r6-3-9');
      break;
    case '已删除融资':
      view[item] = SetView('r6-3-14');
      break;
    case '已退回复核员融资':
      view[item] = SetView('r6-3-15');
      break;
    case '融资放款明细':
      view[item] = SetView('r6-3-10');
      break;
    case '融资回款明细':
      view[item] = SetView('r6-3-11');
      break;
    case '利息摊销计算结果':
      view[item] = SetView('r6-3-12');
      break;
    case '其他保理利率维护':
      view[item] = SetView('r6-3-13');
      break;
    case '融单流转审核':
      view[item] = SetView('r6-4-1');
      break;
    case '可用融单':
      view[item] = SetView('r6-4-2');
      break;
    case '支付融单查看':
      view[item] = SetView('r6-4-3');
      break;
    case '已支付融单':
      view[item] = SetView('r6-4-5');
      break;
    case '已到期融单':
      view[item] = SetView('r6-4-6');
      break;
    case '原始融单':
      view[item] = SetView('r6-4-7');
      break;
      case '已退回流转':
        view[item] = SetView('r6-4-8');
        break;
    //流转退回经办人的业务板块功能--20201030
    case '已退回经办人流转':
      view[item] = SetView('r6-4-9');
      break;
      //流转拒签业务板块功能--20201120
    case '已拒签流转':
      view[item] = SetView('r6-4-10');
      break;
    case '已删除流转':
      view[item] = SetView('r6-4-11');
      break;
    case '已退回复核员流转':
      view[item] = SetView('r6-4-12');
      break;
    case '招行融资查看':
      view[item] = SetView('r6-5-1');
      break;
    case '招行已完成融资':
      view[item] = SetView('r6-5-2');
      break;
    default: ;
  }
})
const getMenu = (parent_path, info) => () => {
  const list = info.map((item, index) => {
    const CompIcon = item.icon;
    switch (index) {
      case 1:
        return <C62 key="C62" />;
      case 2:
        return <C63 key="C63" />;
      case 3:
        return <C64 key="C64" />;
      case 4:
        return <C65 key="C65" />;
      default:
        return (
          <ListItem button component={Link} to={`${parent_path}/${item.path}`} key={item.path}>
            <ListItemIcon>
              <CompIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={item.path} />
          </ListItem>
        );
    }
  });
  let loginInfo = loginInfos();
  if (loginInfo.organization.name === "融单平台") {
    return (
      <div>
        {list}
        <ListItem><Accordion /></ListItem>
      </div>
    );
  }else{
    return (
      <div>
        {list[2]}
        <ListItem><Accordion /></ListItem>
      </div>
    );
  }
};
const getContent = (parent_path, info) => () => {
  let loginInfo = loginInfos();
  let url = "";
  if (loginInfo.organization.name === "融单平台") {
     url = Info[0].path;
  }else{
     url = "融资申请审核";
  }
  return (
    <Switch>
       <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${url}`}
      />
      {info.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${parent_path}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${parent_path}/${item.path}`}
              render={() => <h2>{item.path}</h2>}
              key={item.path}
            />
          )
        }
      })}
      {subRootAry.map(item => {
        if (view[item]) {
          return (
            <Route
              path={`${parent_path}/${item}`}
              component={view[item]}
              key={item}
            />
          )
        }
        else {
          return (
            <Route
              path={`${parent_path}/${item}`}
              render={() => <h2>{item}</h2>}
              key={item}
            />
          )
        }
      })}
    </Switch>

  )
};
export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];
