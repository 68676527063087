import React, { Component } from 'react';
import {
  ViewComfy, QueryBuilder, Edit, Receipt,EventNote,//发票验真伪--所有--20201126
  EventAvailable,//发票验真伪--通过--20201126
  EventBusy,
  DeleteForever,
  CloudUpload,
} from '@material-ui/icons'
import MaterialTable from '../../vendors/material-table';
import { defer, capitalize, fetching } from '../../config/utils';
import { core_business_url } from '../core_business_url';
import { common } from '../formStatic'
import { loginInfos } from '../../components/numToText'
import { SlForm, CommonDialog, YSForm, CheckBoxBatchForm } from '../../components/commonForm';
import { Dialog, C1451Form, C1452Form, ZDForm, C72Form, C1471Form} from '../../02-core_business/containers2'
import { f14_5_1, f14_5_2, zd, f7_2} from '../../02-core_business/formStatic';
import { DownloadTimeForm, DialogForm } from '../../02-core_business/downloadTime';
import { DEFAULT_UPLOADS_URL } from '../../config/constants'
import { InvoiceListForm, InvoiceDialogForm } from '../../components/invoiceList';//发票验真伪结果list展示弹窗--20201126
import {
  TextField,
  Button,
  Grid,
  ButtonGroup,
} from '@material-ui/core'
class View extends Component {
  state = {
    columns: [{}],
    data: [],
    auth: {},
    hasToken: sessionStorage.getItem("authToken"),
    dataUrl: { url: { query: '' } },
    id: 10000,
    query: '',
    form2: { ...common },
    exercise: {
      save: '1',
      DialogTitle: '新增',
      open: false,
      status2: '1'
    }
  };
  //获取数据
  componentDidMount() {
    const { table } = this.props;
    fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {
        if (data) {
          let auth = loginInfos();
          const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
          //根据不同的页面，获取不同的接口地址，渲染不同的数据
          const dataUrl = core_business_url(auth, table).find(item => item.name === table);
          const dataUrl2 = dataUrl || {};
          this.setState({ columns, dataUrl: dataUrl2, auth });

          if (dataUrl2.url) {
            dataUrl2.url.query !== '' && this.onQuery();
          }
        }

      })
  }

  queryName = (role_id = '', status_id = '', item = {}) => {

    let role_name = '';
    switch (role_id) {
      case '11': //操作员
        if (status_id === '7' || status_id === '6') {
          role_name = "操作员";
       /* } else if (status_id === '5') {
          role_name = "已完成";*/
	} else if (status_id === '5' && item.mxf_number) {
          role_name = "待复核";
        } else if (status_id === '3') {
          role_name = "综合部经办人";
        } else if (status_id === '5' && item.mxf_number === "")  {
          role_name = "已完成";
        } else {
          role_name = "复核岗/管理员";
        }
        break;
      case '12': //复核岗/管理员
        if (status_id === '7' || status_id === '6') {
          role_name = "操作员";
        } else if (status_id === '5' && item.mxf_number) {
          role_name = "待复核";
        } else if (status_id === '5' && item.mxf_number === "") {
          role_name = "已完成";
        } else if (status_id === '10') {
          role_name = "综合部经办人";
        } else if (status_id === '13') {
          role_name = "融资分配负责人";
        } else {
          if (item.mxc_number) {
            if (item.mxf_number) {
              role_name = "业务经理";
            } else {
              role_name = "风险经理";
            }            
          } else {
            role_name = "业务经理";
          }

        }
        break;
      case '16': //融资分配
      if (status_id === '7' ) {
        role_name = "复核员";
      } else if (status_id === '3') {
        role_name = "业务经理";
      } else if (status_id === '14') {
        role_name = "其他业务";
      } 
      break;
      case '13': //管理员
        if (status_id === '7' || status_id === '6') {
          role_name = "操作员";
        } else if (status_id === '5') {
          role_name = "已完成";
        } else if (status_id === '10') {
          role_name = "综合部经办人";
        } else {
          if (item.mxc_number) {
            role_name = "风险经理";
          } else {
            role_name = "业务经理";
          }
        }
        break;
      case '1': //业务经理
        if (status_id === '7') {
          role_name = "复核岗/管理员";
        } else {
          role_name = "风险经理";
        }
        break;
      case '2': //业务负责人
        if (status_id === '7') {
          role_name = "业务经理";
        } else {
          role_name = "风险经理";
        }
        break;
      case '3': //风险经理
        if (status_id === '7') {
          if (item.mxc_number) role_name = "复核岗/管理员";
          else role_name = "业务经理";
        } else {
          role_name = "总经理";
        }
        break;
      case '4': //风险负责人
        if (status_id === '7') {
          role_name = "风险经理";
        } else {
          role_name = "法务";
        }
        break;
      case '15': //法务
        if (status_id === '7') {
          role_name = "风险负责人";
        } else {
          role_name = "总经理";
        }
        break;
      case '8': //总经理
        if (status_id === '7') {
          role_name = "风险经理";
        } else {
           role_name = "董事长";
        }
        break;
      case '14': //董事长
        if (status_id === '7') {
          role_name = "总经理";
        } else {
          role_name = "操作员";
        }
        break;
      case '9': //综合部经办人
        if (status_id === '7') {
          role_name = "总经理";
        } else {
          if (item.receivableNo) role_name = "财务经办人";
          else role_name = "已完成";
        }
        break;
      case '5': //财务经办人
        if (status_id === '7') {
          role_name = "综合部经办人";
        } else {
          role_name = "财务负责人";
        }
        break;
      case '6': //财务负责人
        if (status_id === '7') {
          role_name = "财务经办人";
        } else {
          role_name = "已融资";
        }
        break;
      default: ;
    }
    return role_name;
  }

  //查询数据
  onQuery = () => {
    const { url } = this.state.dataUrl;
    let newquery = ""
    if (this.state.dataUrl.name === "r14-6-1" && this.state.auth.roles[0].id === 1){
      newquery = url.query1
    }else {
      newquery = url.query
    }
    fetching(newquery).then(res => {

      if (res) {
        if (this.state.dataUrl.name === "r14-5-1" || this.state.dataUrl.name === "r14-5-4" || this.state.dataUrl.name === "r14-5-7" ||
            this.state.dataUrl.name === "r14-6-1" || this.state.dataUrl.name === "r14-6-2" || this.state.dataUrl.name === "r14-6-3" ||
            this.state.dataUrl.name === "r14-6-4" || this.state.dataUrl.name === "r14-7-1") {
              let datas = res.map(item => {
                if (item.status) {
                  item.status = item.status.name;
                  item.role_name = this.queryName(String(item.roleId), String(item.statusId ? item.statusId:item.status_id), item)
                }
                return item;
              })
          this.setState({ data: datas });
        }else if (this.state.dataUrl.name === "r14-5-3"){
          let datas = res.data.map(item => {
            if (item.roleId === 5)
              item.role_name = "财务经办人"
            else if (item.roleId === 6){
              item.role_name = "财务负责人"
            }
            return item;
          })
          this.setState({ data: datas });
        }else {
          this.setState({ data: res.data });
        }
      }
    })

  }

  //  table新增
  onAdd = newData => {
    let data = this.state.data;
    data.unshift(newData);
    this.setState({ data })
    return defer(0).then(console.log('newData ---: ', newData));
  }

  // 对象查找
  findObject = (obj, data) => {
    let index = data.findIndex(item => item.id === obj.id);
    return index;
  }
  //表格编辑
  onUpdate = (newData, oldData) => {
    return defer(60).then(console.log(' newData, oldData ---: ', newData, oldData));
  }
  //表格删除
  onDelete = async oldData => {
    const { dataUrl } = this.state
    const returnedData = await fetching(dataUrl.url.query + oldData.id, { method: 'DELETE' })
    returnedData && this.onQuery();
    return returnedData;
  }


  //搜索框Change
  onSearchChange = (query) => {
  }
  //页面更改事件
  onChangePage = (query) => {
  }
  //处理每页的行更改（pageSize）
  onChangeRowsPerPage = (query) => {
  }
  //条件查询
  handleSubmit = () => {

  }
  //客户名称
  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ query: value })
  }

  handleCommon = (open) => {
    const { form2, exercise } = this.state;
    if (open) {
      this.setState({
        exercise: { ...exercise, checkOpen: false },
      })
    } else {
      this.setState({
        exercise: { ...exercise, status2: '2', DialogTitle: form2.check, save: '3', checkOpen: true },
      })
    }
  }

  handleToggle = (open) => {
    if (open) {
      window.location.reload()
    }
    const { form2, exercise } = this.state
    if (open) {
      this.setState({
        exercise: { ...exercise, open: false }
      })
    } else {
      this.setState({
        exercise: { ...exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
      })
    }
  }

  FormSubmit3 = () => {
    const { dataUrl, exercise, auth } = this.state;
    this.setState({
      exercise: { ...this.state.exercise, checkOpen: false }
    })
  }

  //核心企业3表单提交
  FormSubmit = (newData, data) => {
    let datas = data || this.state.data || [];
    const { dataUrl, exercise, auth } = this.state;
    if (newData.submit_name === '提交') {
      if (newData.formDates) {
        datas = this.deleteSave(newData.formDates);
      } else {
        this.setState({
          exercise: { ...this.state.exercise, open: false }
        })
         setTimeout(() => {
           this.fetchings(dataUrl, exercise.table, auth)
         }, 500)
      }
    } else {
      this.setState({
        data: JSON.parse(JSON.stringify(datas)),
        exercise: { ...this.state.exercise, open: false }
      })
    }
  }

  exportTableYWD = (e) => {
    const { exercise, form2, dataUrl } = this.state;
    //open: true--详情页；checkOpen: true--公共表单
    this.setState({
      exercise: { ...exercise, save: '3', DialogTitle: form2.exportTable, open: false, tableName: dataUrl.name, status2: '1', checkFlag: true, type: 1 }
    })
  }

  handleToggleYWD = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open: false }
      })
    } else {
      this.setState({
        exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
      })
    }
  }

  onview = (e, row) => {
    const { form2, dataUrl, exercise } = this.state;
    // let query2 = '/api/totalamount?organization_id=' + row.OrganizationId;
    let query = '';
    if (dataUrl.name === 'r14-5-1' || dataUrl.name === 'r14-5-4' || dataUrl.name === 'r14-5-11') {
      query = `/sl/slFindReceivableById?id=${row.id}`;
    }else if (dataUrl.name === 'r14-5-2' || dataUrl.name === 'r14-5-5' || dataUrl.name === 'r14-5-12') {
      query = `/sl/slFindFinancingById?id=${row.id}`;
    }
    fetching(query).then(res => {
      if (res) {
        this.setState({
          exercise: {
            ...exercise, ...res,
            save: '3', status2: '', DialogTitle: form2.view, open: true,
          }
        });
      }
    })

  }

  onview1 = (e, row) => {
    const { form2, dataUrl, exercise } = this.state;
    let query = dataUrl.url.get2 || dataUrl.url.query;
    fetching(query + row.id).then(res => {
      if (res) {
        this.setState({
          exercise: {
            ...exercise, ...res,
            save: '3', status2: '', DialogTitle: form2.view, open: true,
          }
        });
      }
    })

  }


  onCheck = (e, row) => {
    const { form2, dataUrl } = this.state
    setTimeout(() => {
      fetching(`/sl/slFindSlReceivableFlow?receivableNo=${row.receivableNo}`).then(data => {
        this.setState({
          checkExercise: {
            ...row,
            Status: row.Status,
            DialogTitle: form2.check,
            status2: '2',
            rolseId: '1',
            checkStatus: '3',
            save: '1',
            opinion_list: data.data || []
          },
          exercise: { ...row, save: '3', status2: '', DialogTitle: form2.check, checkOpen: true, checkStatus: '3', opinion_list: data.data || []}
        });
      })
    }, 500)

  }

  onCheckBatch = (e, row) => {
    const { form2, dataUrl, exercise, auth } = this.state;
    const { roles = [{ id: 0 }] } = auth;
    let roleId = roles[0].id;
    let query = dataUrl.url.get || dataUrl.url.query;
    let DialogTitle = form2.check;
    if (roleId === 5 || roleId === 6) {
      DialogTitle = '批量复核';
    } 
    fetching(query).then(data => {
      this.setState({
        exercise: {
          ...exercise, ...data, save: '1', DialogTitle,
          rolseId: '2',
          status2: '2', open: true, checkStatus: '3',
        },
      });
    })
  }
  

//中登登记
onZDRegister = (e, row) => {
    const { form2, dataUrl, exercise } = this.state;
    let query = '';
    if (dataUrl.name === 'r14-5-2' || dataUrl.name === 'r14-5-5' || dataUrl.name === 'r14-5-12') {
      query = `/sl/slFindFinancingById?id=${row.id}`;
    }
    fetching(query).then(res => {
      if (res) {
        this.setState({
          exercise: {
            ...exercise, ...res,
            save: '2', status2: '1', DialogTitle: "登记", open: true,
          }
        });
      }
    })
 }

 //中登查询
 onZDQuery = (e, row) => {
  var nameorno = "";
  if (row.companyName === undefined){
    nameorno = row.extraction
  }else {
    nameorno = row.companyName
  }
  fetching(`/zd/queryBySubject?nameorno=${nameorno}`).then(res => {    
    if (res !== undefined && res !==null) {
      if (res.code !== 400) {
        if(res.data.length > 0){
          for (let i = 0; i < res.data.length; i++){
            let imgUrl2 = res.data[i].fpath ? DEFAULT_UPLOADS_URL + res.data[i].fpath : '';
            let typeIndex = imgUrl2.lastIndexOf('.');
            if (typeIndex !== -1) {
                console.log(imgUrl2)
                window.open(imgUrl2, '_blank' )
            }
            } 
          }else {
            alert("没有找到匹配的记录")
          }
        }else {
          alert(res.msg)
        }
    }
    })
  }
  
//发票验真
  onCheckInvoice = (e, row) => {

    //等待转圈--20201229
    if (this.state.setLoading) {
      alert(`发票验真中，请勿重复点击！`)
      return;
    }
    this.setState({setLoading:true});//等待转圈--20201229

    //this.setState({ loading: true });//等待--20201210
    const { table
     } = this.props;
    const oid = row.id;
    if (this.state.dataUrl.name === "r14-7-1"){
      var type = 8; //非标
    }else {
      var type = 7; //应收
    }
    
    /*fetching(`/invoiceVerification/fpyzw?type=${type}&oid=${oid}`).then(res => {
      if (res.code === 200) {
        if (res.data.length == 0) {1
          alert(`发票验证信息全部通过`)
        } else if (res.data.length > 0) {
          alert(`发票验证不合格信息共计：[` + res.data.length + `]条,详情请见发票验真不合格列表！`)
        } else {
          alert(res.msg)
        }
      } else {
        alert(`失败,${res.msg}`)
      }
    })*/

    fetching(`/invoiceVerification/getInvoiceVerificationList?type=${type}&oid=${oid}`).then(res => {
      
      if (null != res.invoice_all_list && res.invoice_all_list.length > 0) {
        //this.setState({ loading: false });//等待--20201210
        this.setState({setLoading:false});//等待转圈--20201229
        //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
        alert(`本单已经进行过发票验真，请直接查看验真结果！`)
      } else {
        fetching(`/invoiceVerification/fpyzw?type=${type}&oid=${oid}`).then(res => {
          //this.setState({ loading: false });//等待--20201210
          this.setState({setLoading:false});//等待转圈--20201229
          if(res !== undefined){
          if (res.code === 200) {
            if (res.data.length > 0) {
              alert(`发票验证不合格信息共计：[` + res.data.length + `]条,详情请见发票验真不合格列表！`)
            } else {
              alert(res.msg)
            }
          } else {
            alert(`失败,${res.msg}`)
          }
        } else {
          alert("发票验真网络连接超时,请稍后重试或请联系管理员处理")
        }
        })
      }
    })

  }


  //发票验真伪的结果list用弹窗展示
  //1.发票验真结果列表
  onShowListAll = (e, row) => {
    let DialogTitle = "发票验真结果列表";
    const { table } = this.props;
    if (null == row.invoice_all_list || row.invoice_all_list.length === 0) {//status_id=${status_id}&role_id=${role_id}
      const oid = row.id;
      if (this.state.dataUrl.name === "r14-7-1"){
        var type = 8; //非标
      }else {
        var type = 7; //应收
      }
      fetching(`/invoiceVerification/getInvoiceVerificationList?type=${type}&oid=${oid}`).then(res => {
        if (null != res.invoice_all_list && res.invoice_all_list.length > 0) {
          //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
          this.setState({
            exercise: { 
              save: '3', DialogTitle,
              open1: true,
              status2: '1', checkFlag: false, type: 1,
              InvoiceList: res.invoice_all_list || []
            }
          })
        } else {
          alert(`尚未进行发票验真伪功能！`)
        }
      })
    } else {
      this.setState({
        exercise: { 
          save: '3', DialogTitle,
          open: false,
          status2: '1', checkFlag: true, type: 1,
          InvoiceList: row.invoice_all_list || []
        }
      })
    }
  }
  //2.发票验真不合格列表
  onShowListNo = (e, row) => {
    let DialogTitle = "发票验真不合格列表";
    const { table } = this.props;
    if (null == row.invoice_all_list || row.invoice_all_list.length == 0) {//status_id=${status_id}&role_id=${role_id}
      const oid = row.id;
      if (this.state.dataUrl.name === "r14-7-1"){
        var type = 8; //非标
      }else {
        var type = 7; //应收
      }
      fetching(`/invoiceVerification/getInvoiceVerificationList?type=${type}&oid=${oid}`).then(res => {
        if (null != res.invoice_all_list && res.invoice_all_list.length > 0) {
          //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
          this.setState({
            exercise: { 
              save: '3', DialogTitle,
              open1: true,
              status2: '1', checkFlag: false, type: 1,
              InvoiceList: res.invoice_no_list || []
            }
          })
        } else {
          alert(`尚未进行发票验真伪功能！`)
        }
      })
    } else {
      this.setState({
        exercise: { 
          save: '3', DialogTitle,
          open: false,
          status2: '1', checkFlag: true, type: 1,
          InvoiceList: row.invoice_no_list || []
        }
      })
    }
  }

  fetchings = (dataUrl2, table, auth) => {
    table  = this.props.table;
   if (dataUrl2.url) {
     if (dataUrl2.url.query) {
      fetching(dataUrl2.url.query).then(res => {
        this.setState({ data: res });
     })
    }
   }
  }

  //将数据置为已删除状态（12）
  onDeleteStatus = (e, row) => {
    const { dataUrl, exercise, auth } = this.state;
    const id = row.id;
    const { table } = this.props;
    var url = "";
    url = `/msapi/nsreceivable/del/${id}/put_status`;
    if(window.confirm("是否删除该条数据,数据删除后不可恢复")){
    fetching(url).then(res => {
      if (res) {
        //if (!res.status) alert(res.msg)
        //res && this.onQuery(); 
        alert("删除成功");
        this.fetchings(dataUrl, exercise.table, auth);//20201207
      } else {
        alert("删除失败" + res);
      }
    })
    return true;
  } else {
    return false;
  }
  }

 //下载合同
 onUploadContract = (e, row) => {
    if (!row.fdd_file_name || row.fdd_file_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_file_name}`).then(res => {
      if (res !== undefined && res !==null) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract2 = (e, row) => {
    if (!row.fdd_yssq_name || row.fdd_yssq_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_yssq_name}`).then(res => {
      if (res !== undefined && res !==null) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract3 = (e, row) => {
    if (!row.fdd_ysqr_name || row.fdd_ysqr_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_ysqr_name}`).then(res => {
      if (res !== undefined && res !==null) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract4 = (e, row) => {
    if (!row.fdd_ystz_name || row.fdd_ystz_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_ystz_name}`).then(res => {
     if (res !== undefined && res !==null) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract5 = (e, row) => {
    if (!row.fdd_ysxxb_name || row.fdd_ysxxb_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_ysxxb_name}`).then(res => {
      if (res !== undefined && res !==null) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }

  onUploadContract6 = (e, row) => {
    if (!row.fdd_yshz_name || row.fdd_yshz_name === 'null') {
      alert('暂无合同')
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${row.fdd_yshz_name}`).then(res => {
      if (res !== undefined && res !==null) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(`下载失败,${res.msg}`)
        }
      } else {
        alert(`下载失败`)
      }
    })
  }


  render() {
    const { columns, data, dataUrl, query, exercise, auth, opinion_list, params } = this.state;
    const title = dataUrl.title || '';
    const path = capitalize(this.props.table);
    const { table = '' } = this.props;
    exercise.table = this.props.table;
    const actions = actions2(this, this.props.table, auth);
    const editable = editable2(this, this.props.table);
    let rolesId = auth.roles ? auth.roles[0].id : 0;

    //InvoiceListForm, InvoiceDialogForm
    //发票验真伪结果list展示弹窗--20201126
    const InvoiceList = () => {
    let isBtn = false;
    return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
      <InvoiceDialogForm handleToggle={this.handleToggleYWD} exercise={{ ...exercise, addBtnName: common.exportTable, isBtn }}>
        <InvoiceListForm exercise={{ ...exercise }} />
      </InvoiceDialogForm>
    </div>
    }

    //中登弹窗
    const ZDRegister = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName3, isBtn }}>
          <ZDForm auth={auth} form2={{ ...common, ...zd }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
     }

    //公共表单
    const C14 = () => {
      return <CommonDialog handleToggle={this.handleCommon}
        exercise={{ ...exercise, addBtnName: '公共表单', isBtn: false, }}>
        <SlForm auth={auth} opinion_list={opinion_list} exercise={{ ...exercise, table }}
          onSubmit={this.FormSubmit3} />
      </CommonDialog>
    }

    //公共表单
    const C21 = () => {
      return <CommonDialog handleToggle={this.handleCommon}
        exercise={{ ...exercise, addBtnName: '公共表单', isBtn: false, }}>
        <YSForm auth={auth} opinion_list={opinion_list} exercise={{ ...exercise, table }}
          onSubmit={this.FormSubmit3} />
      </CommonDialog>
    }

    //应收账款开具
    const C72 = () => {
      let isBtn = table === 'm7-2' ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName6, isBtn }}>
          <C72Form auth={auth} form2={{ ...common, ...f7_2 }} exercise={{ ...exercise, ...params }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    const Batch = () => {
      return <Dialog handleToggle={this.handleToggle}
        exercise={{ ...exercise, addBtnName: '公共表单', isBtn: false, }}>
        <CheckBoxBatchForm auth={auth} opinion_list={[]} exercise={{ ...exercise, table }}
          onSubmit={this.FormSubmit3} />
      </Dialog>
    }

    //非标业务开具
    const C1471 = () => {
      let isBtn = (table === 'r14-7-1' && rolesId === 1) ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName8, isBtn }}>
          <C1471Form auth={auth} form2={{ ...common, ...f7_2 }} exercise={{ ...exercise, ...params }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    const C6 = () => {
      return <div style={{ textAlign: 'end', marginBottom: '10px' }}>
        <Button variant="contained" color="primary" onClick={this.exportTableYWD}>导出</Button>
      </div>
    }

    const DownloadTime = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <DialogForm handleToggle={this.handleToggleYWD} exercise={{ ...exercise, addBtnName: common.exportTable, isBtn }}>
          <DownloadTimeForm form2={{ ...common }} exercise={{ ...exercise }} />
        </DialogForm>
      </div>
    }

    const C1451 = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName2, isBtn }}>
          <C1451Form auth={auth} form2={{ ...common, ...f14_5_1 }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    const C1452 = () => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <Dialog handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName2, isBtn }}>
          <C1452Form auth={auth} form2={{ ...common, ...f14_5_2 }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit} />
        </Dialog>
      </div>
    }

    const T4 = () => {
      return <div style={{ textAlign: 'start', marginBottom: '10px' }}>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button style={{ marginRight: '10px',backgroundColor: 'rgb(29, 69, 131)'}} variant="contained" color="primary" onClick={() => this.onCheckBatch()}>批量操作</Button>
        </ButtonGroup>
      </div>
    }
    

    return <div>
      {(table === 'r14-5-1' || table === 'r14-5-2' || table === 'r14-5-3'|| table==='r14-6-1' || table === 'r14-6-3') && <C14></C14>}
      {(table === 'r14-6-1' || table === 'r14-6-3' || table === 'r14-6-4') && <C21></C21>}
      {(table === 'r14-5-1' || table === 'r14-5-4' || table === 'r14-5-11') && <C1451></C1451>}
      {((table === 'r14-5-2' && rolesId !== 9) || table === 'r14-5-5'|| table === 'r14-5-12' || table === 'm7-4-3') && <C1452></C1452>}
      {(table === 'r14-5-5' || table === 'r14-5-6' || table === 'r14-6-3'  || table === 'r14-7-1') && <C6></C6>}
      {(table === 'r14-5-5' || table === 'r14-5-6' || table === 'r14-6-3'  || table === 'r14-7-1') && <DownloadTime></DownloadTime>}
      {(table === 'r14-5-2' && rolesId === 9) && <ZDRegister></ZDRegister>}
      {(table === 'r14-6-1' || table === 'r14-6-2' || table === 'r14-6-3' || table === 'r14-6-4') && <C72></C72>}
      {(table === 'r14-6-1' || table === 'r14-7-1') && <InvoiceList></InvoiceList>}
      {(table === 'r14-7-1') && <C1471></C1471>}
      {(table === 'r14-5-3') && <T4></T4>}
      {(table === 'r14-5-3' && ( rolesId === 6 || rolesId === 5)) && <Batch></Batch>}
      <Grid container justify="center" alignItems="flex-end" style={{marginBottom:'20px'}}>
        {/*<Grid item xs={5}>
          <TextField
            label={'客户名称'}
            value={query}
            name='enterprise_name'
            onChange={this.handleChange}
            // onBlur={handleBlur}
            margin='normal'
            fullWidth
          />
        </Grid>
        <div style={{ padding: '10px 0' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit}
            name='query'
            style={{ marginLeft: '10px' }}
          >
            {'查询'}
          </Button>
        </div>*/}
      </Grid>

      <MaterialTable
        title={title}
        onSearchChange={this.onSearchChange}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        path={path}
        columns={columns}
        data={data}
        actions={actions}
        editable={editable}
        localization={localization}
        options={options}
      />
    </div>
  }
}
const actions2 = (that, name, auth) => {
  let actions = [];
  const { roles = [{ id: 0 }] } = auth;
  let roleId = roles[0].id;
  switch (name) {
    case 'r14-5-1':
      if (roleId === 1) {
        actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview },
                 { icon: Edit, tooltip: '审核', onClick: that.onCheck },
                 { icon: QueryBuilder, tooltip: '中登查询', onClick: that.onZDQuery },];
      } else {
        actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview },
                 { icon: Edit, tooltip: '审核', onClick: that.onCheck },];
      }
      break;
    case 'r14-5-11':
      actions = [
                { icon: ViewComfy, tooltip: '查看', onClick: that.onview },];  
        break;  
    case 'r14-5-2':
      if (roleId === 9) {
        actions = [{ icon: Receipt, tooltip: '中登登记', onClick: that.onZDRegister},
                  { icon: Edit, tooltip: '审核', onClick: that.onCheck },];  
      } else {
        actions = [{ icon: ViewComfy, tooltip: '查看', onClick: that.onview },
                  { icon: Edit, tooltip: '审核', onClick: that.onCheck },];  
      }
      break;
    case 'r14-5-12':
      actions = [
               { icon: ViewComfy, tooltip: '查看', onClick: that.onview },];  
      break;  
    case 'r14-5-3':
      if (roleId === 5 || roleId === 6 ) {
        actions = [
                  { icon: Edit, tooltip: '审核', onClick: that.onCheck },];  
      }          
      break;
    case 'r14-5-4':
      actions = [
                { icon: ViewComfy, tooltip: '查看', onClick: that.onview },];  
        break;
    case 'r14-5-5':
      actions = [
                { icon: ViewComfy, tooltip: '查看', onClick: that.onview },];  
        break;  
    case 'r14-6-1':
      if (roleId === 3 || roleId === 1 ) {
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          { icon: ViewComfy, tooltip: '编辑/查看', onClick: that.onview1 },
          { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
          { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
          { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
        ];
      }else{
        actions = [
          { icon: 'save', tooltip: '审核', onClick: that.onCheck },
          { icon: ViewComfy, tooltip: '编辑/查看', onClick: that.onview1 },
        ];
      }
        break;
    case 'r14-6-2':
      actions = [
        { icon: ViewComfy, tooltip: '编辑/查看', onClick: that.onview1 },
        { icon: CloudUpload, tooltip: '下载保理合同', onClick: that.onUploadContract },
      { icon: CloudUpload, tooltip: '下载申请书', onClick: that.onUploadContract2 },
      { icon: CloudUpload, tooltip: '下载确认书', onClick: that.onUploadContract3 },
      { icon: CloudUpload, tooltip: '下载通知书', onClick: that.onUploadContract4 },
      { icon: CloudUpload, tooltip: '下载信息表', onClick: that.onUploadContract5 },
      { icon: CloudUpload, tooltip: '下载回执', onClick: that.onUploadContract6 },
      ];
        break;
    case 'r14-6-3':
      actions = [
        { icon: ViewComfy, tooltip: '编辑/查看', onClick: that.onview1 },
      ];
        break;
    case 'r14-6-4':
      actions = [
        { icon: 'save', tooltip: '审核', onClick: that.onCheck },
        { icon: ViewComfy, tooltip: '编辑/查看', onClick: that.onview1 },
      ];
        break;
    case 'r14-7-1':
      if (roleId === 1 ) {
        actions = [
          { icon: ViewComfy, tooltip: '编辑/查看', onClick: that.onview1 },
          { icon: DeleteForever, tooltip: '删除', onClick: that.onDeleteStatus },
          { icon: EventNote, tooltip: '发票验真结果列表', onClick: that.onShowListAll},
          { icon: EventBusy, tooltip: '发票验真不合格列表', onClick: that.onShowListNo},
          { icon: EventAvailable, tooltip: '发票验真', onClick: that.onCheckInvoice},
          { icon: QueryBuilder, tooltip: '中登查询', onClick: that.onZDQuery },
        ];
      }else{
        actions = [
          { icon: ViewComfy, tooltip: '编辑/查看', onClick: that.onview1 },
        ];
      }
        break;    
    default:
      actions = [];
  }
  return actions;
}
const editable2 = (that, name) => {
  let editable = {};
  switch (name) {
    case 'm1':
      editable = { onRowAdd: that.onAdd, };
      break;

    default:
    // editable = { onRowDelete: that.onDelete };
  }
  return editable;
}
const localization = {
  header: {
    actions: '操作'
  }
}
const options = {
  actionsCellStyle: { padding: '0 10px' },
  headerStyle: { //表格头部
    backgroundColor: '#1d4583',
    color: '#FFF',
    whiteSpace: 'nowrap'
  },
  rowStyle: { //表行
    backgroundColor: '#fff',
    whiteSpace: 'nowrap'
  },
  sorting: true,  //排序
  // selection: true //多选框
}
const setView = Comp => (collection, name) => {
  return class extends Component {
    render() {
      return <Comp table={collection} tableName={name} {...this.props} />
    }
  }
}

export const SetView = setView(View);
