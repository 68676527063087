// import React from 'react';
// import TabPanels from './TabPanels'
import getSubRouters from './helper';

const PARENT_PATH = '/customer-info/风险信息';
const PATH = '预警信息';

export const subRootAry = [
  "风险预警信息",
  "逾期信息"
];
// option 1:
// export function tabPanels(ary = []) {
//   return <TabPanels ary={ary} />;
// }

export default getSubRouters(PARENT_PATH, PATH, subRootAry);