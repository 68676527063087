import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Warning,
  CreditCard
} from '@material-ui/icons';
import { subRouters, SetView } from './common';
// import {C4_1} from './containers'
import C41, { subRootAry } from './containers/C4_1'
import Accordion from '../components/Accordion'
const PARENT_PATH = subRouters['风险信息'];


const Info = [
  {
    path: "预警信息",
    icon: Warning,
    component: C41,
  },
  /*{
    path: "征信关注及不良",
    icon: CreditCard,
    // component: SetView('c4-2'),
  },*/
];
const view = {};
subRootAry.forEach((item, index) => {
  switch (item) {
    case '风险预警信息':
      view[item] = SetView('c4-1-1');
      break;
    case '逾期信息':
      view[item] = SetView('c4-1-2');
      break;
    default:
      throw new Error('Unknown step');
  }
})
// export default [
//   getMenu(PARENT_PATH, Info),
//   getContent(PARENT_PATH, Info)
// ]
export const Menu4 = () => {
  const list = Info.map((item, index) => {
    const CompIcon = item.icon;
    return index === 0 ? <C41 key="C41" /> : (
      <ListItem button component={Link} to={`${PARENT_PATH}/${item.path}`} key={item.path}>
        <ListItemIcon>
          <CompIcon />
        </ListItemIcon>
        <ListItemText primary={item.path} />
      </ListItem>
    )
  })
  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
}


export const Content4 = () => {
  return (
    <Switch>
      {Info.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${PARENT_PATH}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${PARENT_PATH}/${item.path}`}
              render={() => <h2>{item.path}</h2>}
              key={item.path}
            />
          )
        }
      })
      }

      {subRootAry.map(item => {
        if (view[item]) {
          return (
            <Route
              path={`${PARENT_PATH}/${item}`}
              component={view[item]}
              key={item}
            />
          )
        } else {
          return (
            <Route
              path={`${PARENT_PATH}/${item}`}
              render={() => <h2>{item}</h2>}
              key={item}
            />
          )
        }

      })}
    </Switch>
  )
};