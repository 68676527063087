//公用的名称
export default {
    add: "新增",
    edit: "编辑",
    view: "查看",
    save: "保存",
    submit: "提交",
    export: "导出",//20200917
    exportTable: "导出台账",//20200917
    revocation: "撤销",
    addBtnName1: "录入客户概况",
    addBtnName2: "录入营业执照",
    addBtnName3: "录入实际控制人",
    addBtnName4: "录入管理层信息",
    addBtnName5: "录入财务信息",
    addBtnName6: "客户信息补录",
    addBtnName7: "录入客户授权信息",
    status: '状态', // 已保存、待审核、已审核、已退回
    resetPwd: "重置密码",
    old_password: "原密码",
    new_password: "新密码",
    confirm_password: "再次输入密码",
    upload: "上传",
    select_customer: '选择客户'
}