import React, { Component } from 'react'
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
    TextField,
    Button,
    Grid,
    Select as Select2,
    MenuItem,
    FormControl,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    InputLabel
} from '@material-ui/core'
import {
    ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { fetching } from '../../../config/utils';
import { HEADERS } from '../../../state/ActionTypes';
import Typography from '@material-ui/core/Typography';
import FormikError from '../../../components/commonForm/formikError';
import { pledgeFormFormik } from './formikValidate';
import { numberfun, commafy, delcommafy } from '../../../components/numToText';
// const { object } = require('yup');

const useStyles = (theme => ({
    formControl: {
        marginTop: '16px',
        marginBottom: '8px',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    width: '100%',
    label: {
        textAlign: 'left',
    },
    radio: {
        flexDirection: 'row',
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const styles = {
    width: '100%',
    margin: '16px 0 8px 0'
}

const pledgeType = ['银票、商票', '汇票、支票、本票、债券、存款单、仓单、提单', '股份、股票', '商标专用权，专利权、著作权中的财产权', '其他权利']
class PledgeForm extends Component {
    state = {
        guarantyWay: this.props.guarantyWay,
        formStatic: this.props.formStatic,
        form: this.getInitState() || {},
        isExpanded: false,
    }

    getInitState() {
        const { guarantInfo } = this.props;
        let form3 = {
            pledge_type: "其他权利",
            pledge_name: "",
            pledge_value: "",
            pledge_position: "",
            pledge_ratio: "",
            guarantee_amount: "",
            guarantee_period: "",
            pledge_desc: "",
            unified_social_credit_code: "",
            warrant_no: '',
        };
        if (guarantInfo.pledge_id) {
            form3.id = guarantInfo.pledge_id
        }
        if (guarantInfo.id) {
            form3.pledge_id = guarantInfo.id
        }
        return (guarantInfo.id || guarantInfo.pledge_id) ? { ...form3, ...guarantInfo } : { ...form3 };
    }

    // 提交
    handleSubmit = (values) => {
        const { guarantInfo = {} } = this.props;
        const id = guarantInfo.id || guarantInfo.pledge_id || '';
        const { auth } = this.props
        let ids = id ? id : '';
        let method = id ? 'PUT' : 'POST';

        let params = values;

        params.pledge_ratio = this.state.form.pledge_ratio;
        const amountList = ['guarantee_amount', 'pledge_value'];
        amountList.forEach(item => {
            params[item] = delcommafy(params[item]);
        });
        params.auth = auth //JSON.stringify(auth)
        params.id = ids
        fetching(`/msapi/pledge/save/${ids}`, {
            method,
            // token: this.state.hasToken,
            headers: HEADERS,
            body: JSON.stringify(params),
        }).then((data) => {
            try {
                if (ids) {
                    this.props.FormSubmit({ guarantInfo2:{...params} })
                } else {
                    this.props.FormSubmit({guarantInfo2:{...params,...data}})
                }
                this.setState({ isExpanded: false })
            } catch (error) {
                this.props.FormSubmit()
            }
        })


    }
    paneChange = (event, expanded) => {
        this.setState({ isExpanded: expanded })
    }
    //输入框值改变
    handleChange3 = (name, value) => {
        let value2 = value;
        if (name === 'guarantee_amount' || name === 'pledge_value'|| name === 'guarantee_period') {
            value2 = numberfun(value2, 12);
        }
        return value2;
    }
    //输入框失去焦点 金额 千分位
    handleBlur2 = (name, value) => {
        if (name === 'guarantee_amount') {
            return commafy(value);
        } else if (name === 'pledge_value' && value) {

            const { guarantyWay } = this.props;
            const mx_amount = delcommafy(guarantyWay.mx_amount);
            if (!mx_amount) {
                alert('请输入额度金额')
                return '';
            } else {
                const value1 = delcommafy(value);
                const pledge_ratio = ((mx_amount / value1) * 100).toFixed(2);
                this.setState({ form: { ...this.state.form, pledge_ratio } })
                return commafy(value)
            }
        }
    }

    render() {
        const { isExpanded, form } = this.state;
        const { formStatic, guarantyWay, classes } = this.props;
        return (
            <Formik
                //初始化数据
                initialValues={{
                    ...form
                }}
                //表单验证
                validate={(values) => {
                    return pledgeFormFormik(values, formStatic);
                }}
                //验证提交
                onSubmit={(values) => {
                    this.handleSubmit(values);
                }}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                        <ExpansionPanel expanded={isExpanded} onChange={this.paneChange}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{guarantyWay.guaranty_way}信息</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div style={{ width: '100%' }}>
                                    <Grid container justify='space-between' alignItems='center'>
                                        <Grid item xs={12}>
                                            <FormControl style={styles} className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-label">{formStatic.pledge_type}</InputLabel>
                                                <Select2
                                                    id="demo-simple-select"
                                                    value={values.pledge_type}
                                                    disabled={guarantyWay.save === '3'}
                                                    onChange={e => setFieldValue('pledge_type', e.target.value)}>
                                    {pledgeType.map(item => {
                                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                                    })}
                                                </Select2>
                                            </FormControl>
                                            <FormikError touched={touched} errors={errors} name={'pledge_type'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container justify='space-between'>
                                        <Grid item xs={5}>
                                            <TextField
                                                label={formStatic.pledge_name}
                                                value={values.pledge_name}
                                                name='pledge_name'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                margin='normal'
                                                inputProps={{ maxLength: 50 }}
                                                fullWidth
                                                disabled={guarantyWay.save === '3'}
                                            />
                                            <FormikError touched={touched} errors={errors} name={'pledge_name'} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                label={formStatic.unified_social_credit_code}
                                                value={values.unified_social_credit_code}
                                                name='unified_social_credit_code'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                margin='normal'
                                                inputProps={{ maxLength: 50 }}
                                                fullWidth
                                                disabled={guarantyWay.save === '3'}
                                            />
                                            <FormikError touched={touched} errors={errors} name={'unified_social_credit_code'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container justify='space-between'>
                                        <Grid item xs={5}>
                                            <TextField
                                                label={formStatic.warrant_no}
                                                value={values.warrant_no}
                                                name='warrant_no'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                margin='normal'
                                                inputProps={{ maxLength: 50 }}
                                                fullWidth
                                                disabled={guarantyWay.save === '3'}
                                            />
                                            <FormikError touched={touched} errors={errors} name={'warrant_no'} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid container justify="space-between" alignItems='flex-end' >
                                                <Grid item xs={10}>
                                                    <TextField
                                                        label={formStatic.pledge_value}
                                                        value={values.pledge_value}
                                                        name='pledge_value'
                                                        onChange={e => setFieldValue('pledge_value', this.handleChange3.bind(this, 'pledge_value', e.target.value)())}
                                                        onBlur={e => setFieldValue('pledge_value', this.handleBlur2.bind(this, 'pledge_value', e.target.value)())}
                                                        margin='normal'
                                                        inputProps={{ maxLength: 50 }}
                                                        fullWidth
                                                        disabled={guarantyWay.save === '3'}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} style={{ marginBottom: '8px' }}>元</Grid>
                                            </Grid>
                                            <FormikError touched={touched} errors={errors} name={'pledge_value'} />
                                        </Grid>

                                    </Grid>
                                    <Grid container justify='space-between'>
                                        <Grid item xs={5}>
                                            <TextField
                                                label={formStatic.pledge_position}
                                                value={values.pledge_position}
                                                name='pledge_position'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                margin='normal'
                                                inputProps={{ maxLength: 50 }}
                                                fullWidth
                                                disabled={guarantyWay.save === '3'}
                                            />
                                            <FormikError touched={touched} errors={errors} name={'pledge_position'} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid container justify="space-between" alignItems='flex-end' >
                                                <Grid item xs={10}>
                                                    <TextField
                                                        label={formStatic.pledge_ratio}
                                                        value={form.pledge_ratio}
                                                        name='pledge_ratio'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        margin='normal'
                                                        inputProps={{ maxLength: 50 }}
                                                        fullWidth
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} style={{ marginBottom: '8px' }}>%</Grid>
                                            </Grid>
                                            {/* <FormikError touched={touched} errors={errors} name={'pledge_ratio'} /> */}
                                        </Grid>
                                    </Grid>
                                    <Grid container justify='space-between'>
                                        <Grid item xs={5}>
                                            <Grid container justify="space-between" alignItems='flex-end' >
                                                <Grid item xs={10}>
                                                    <TextField
                                                        label={formStatic.guarantee_amount}
                                                        value={values.guarantee_amount}
                                                        name='guarantee_amount'
                                                        onChange={e => setFieldValue('guarantee_amount', this.handleChange3.bind(this, 'guarantee_amount', e.target.value)())}
                                                        onBlur={e => setFieldValue('guarantee_amount', this.handleBlur2.bind(this, 'guarantee_amount', e.target.value)())}
                                                        margin='normal'
                                                        inputProps={{ maxLength: 50 }}
                                                        fullWidth
                                                        disabled={guarantyWay.save === '3'}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} style={{ marginBottom: '8px' }}>元</Grid>
                                            </Grid>
                                            <FormikError touched={touched} errors={errors} name={'guarantee_amount'} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid container justify="space-between" alignItems='flex-end' >
                                                <Grid item xs={10}>
                                                    <TextField
                                                        label={formStatic.guarantee_period}
                                                        value={values.guarantee_period}
                                                        name='guarantee_period'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        margin='normal'
                                                        type="number"
                                                        inputProps={{ maxLength: 50 }}
                                                        fullWidth
                                                        disabled={guarantyWay.save === '3'}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} style={{ marginBottom: '8px' }}>年</Grid>
                                            </Grid>
                                            <FormikError touched={touched} errors={errors} name={'guarantee_period'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container justify='space-between'>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                rows='4'
                                                label={formStatic.pledge_desc}
                                                value={values.pledge_desc || ''}
                                                name='pledge_desc'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                margin='normal'
                                                fullWidth
                                                disabled={guarantyWay.save === '3'}
                                            />
                                            <FormikError touched={touched} errors={errors} name={'pledge_desc'} />
                                        </Grid>
                                    </Grid>
                                    {guarantyWay.save !== '3' &&
                                        <div style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
                                            <Button variant="contained" color="primary" onClick={handleSubmit}>保存 </Button>
                                        </div>
                                    }

                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )}
            />

        )
    }
}

export default withStyles(useStyles)(PledgeForm)
