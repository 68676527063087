import React, { Component } from 'react'
import { Formik } from 'formik';
import {
  TextField,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import cnLocale from "date-fns/locale/zh-CN";
import { DEFAULT_EXPORT_URL } from '../../config/constants';
import { formatDate } from '../../components/date';//20200918
export const BASE = '/business';

const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }
}));
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    form: this.getInitState(),
    form2: this.props.form2 || {},//静态资源
    loading: false,
  }
  async componentDidMount() {

  }

  getInitState() {
    const { exercise } = this.props;
    const open_day = new Date();
    if (exercise.tableName === 'r9-4'){
      const form = {
        selectedStartDate: open_day,
        selectedEndDate: open_day,
        selectedStartDate1: open_day,
        selectedEndDate1: open_day,
      }
      return { ...form, ...exercise }
    } else { 
      const form = {
        selectedStartDate: open_day,
        selectedEndDate: open_day,
      }
      return { ...form, ...exercise }
    }    
  }


  //导出注册/开立/融资的excel表
  handleSubmit = (form, name) => {

    const text = name || '撤销';
    
    if (form.tableName === 'r9-4'){
      let { selectedStartDate, selectedEndDate, selectedStartDate1, selectedEndDate1} = this.state.form;
      
      if (selectedStartDate.getTime() > selectedEndDate.getTime() || selectedStartDate1.getTime() > selectedEndDate1.getTime()) {
    	alert('开始时间应小于结束时间')
        return
      }
     
      selectedStartDate = formatDate(selectedStartDate, 'yyyy-MM-dd');
      selectedEndDate = formatDate(selectedEndDate, 'yyyy-MM-dd');
      selectedStartDate1 = formatDate(selectedStartDate1, 'yyyy-MM-dd');
      selectedEndDate1 = formatDate(selectedEndDate1, 'yyyy-MM-dd');
      
      if (text === '提交') {
      const creditNo = this.props.exercise.creditNo;//20201118
      const type = this.props.exercise.type;//20201118
      const table = form.tableName;
      let url = ''
      if (table === 'r9-4') {//r9-4
        url = `contract/excel/IssuesCountAndFund?selectedStartDate1=${selectedStartDate}&selectedEndDate1=${selectedEndDate}&selectedStartDate2=${selectedStartDate1}&selectedEndDate2=${selectedEndDate1}`
      }
      if (!url) {
        alert('接口为空')
        return
      }
      window.open(`${DEFAULT_EXPORT_URL}${url}${creditNo}`)//20201118      
    }
    } else {
      let { selectedStartDate, selectedEndDate } = this.state.form;

      if (selectedStartDate.getTime() > selectedEndDate.getTime()) {
        alert('开始时间应小于结束时间')
        return
      }

      selectedStartDate = formatDate(selectedStartDate, 'yyyy-MM-dd');
      selectedEndDate = formatDate(selectedEndDate, 'yyyy-MM-dd');
    

    //提交表单s
    if (text === '提交') {
      const creditNo = this.props.exercise.creditNo;//20201118
      const type = this.props.exercise.type;//20201118
      const table = form.tableName;
      let url = ''
      if (table === 'm6-1') {//`/api/fflow?mx_number=${exercise.mx_number}&mxf_number=${exercise.mxf_number}&${department_id}
        url = `contract/excel/zc?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'm6-2') {
        url = `contract/excel/issues?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'm6-3') {
        url = `contract/excel/financings?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'm3-1-4' || table === 'r6-3-7') {//已融资台账导出--20201117
        url = `contract/downLoadExcel/financed?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'm2-5-2' || table === 'r6-2-3') {//m2-5-2:(客户端的待签收/已签收开立)已开融单; r6-2-3:(业务端的已签收开立)已支付融单--20210106
        url = `contract/downLoadExcel/kl?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'm3-1-5' || table === 'r6-4-5') {//m3-1-5:(客户端的已签收流转)已支付融单; r6-4-5：(业务端的已签收流转)已支付融单--20210106
        url = `contract/downLoadExcel/lz?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'm3-1-6' || table === 'r6-4-6') {//m3-1-6:(客户端的已签收开立)已到期融单; r6-4-6：(业务端的已签收开立)已到期融单--20210330
        url = `contract/downLoadExcel/kla?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'm3-1-12') {//m3-1-12:(客户端的已签收开立)未到期到期融单; --20210413
        url = `contract/downLoadExcel/PayMentDayNotExpired?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'r8-5') {//r8-5:开立,流转融资到期时间统计台账--20210331
        url = `contract/downLoadExcel/getHoldAtMaturity?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'r8-3') {//r8-3:开立,还款额度已恢复导出台账 按承诺付款日导出--20211216
        url = `contract/downLoadExcel/exportLimitRecovery?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'r8-4') {//r8-4 还款提醒导出台账 --20240117
        url = `contract/downLoadExcel/getDisPlay15New?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'r9-5') {//r9-5:开立到融资时间统计--20210106
        url = `contract/downLoadExcel/klTimeAmountWeight?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&creditNo=`
      } else if (table === 'r6-2-2') {//r6-2-2:开立融单查看(非删除)--20210106
        url = `contract/downLoadExcel/klAll?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'r6-4-2') {//r6-4-2 业务端可用融单--20210915
        url = `contract/downLoadExcel/usableKlAndLz?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'r6-4-3') {//r6-4-3:支付融单查看(非删除)--20210106
        url = `contract/downLoadExcel/lzAll?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'm3-1-7' || table === 'r6-4-7') {//m3-1-7:原始融单(客户端); r6-4-7:原始融单(业务端)
        url = `contract/downLoadExcel/klAndlZ?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'r14-5-5') {//r14-5-5:已完成数链融资(业务端)
        url = `contract/downLoadExcel/SLFinancings?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'r14-5-6') {//r14-5-6:已完成数链还款(业务端)
        url = `contract/downLoadExcel/SLFindRepayment?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'r7-7-1') {//r7-7-1:融后汇总表(业务端)
        url = `contract/downLoadExcel/FinancingGather?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'r14-7-1') {//r14-7-1:应收登记(业务端)
        url = `contract/downLoadExcel/NSReceivable?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'c1-1-7') {//c1-1-7:内外客户(业务端)
        url = `contract/downLoadExcel/AccountsInfos?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'r14-6-3') {//r14-6-3:已完成应收账款(业务端)
        url = `contract/downLoadExcel/Receivable?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      }  else if (table === 'r5-4') {//r5-4额度查询
        url = `contract/downLoadExcel/getLimitDetailExcel?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'r5-5') {//r5-5历史额度查询
        url = `contract/downLoadExcel/getHistoryLimitDetailExcel?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } else if (table === 'r4-1-3') {//r4-1-3授信申请查询
        url = `contract/downLoadExcel/getCreditSearchDetailExcel?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}&type=${type}&creditNo=`
      } 
      if (!url) {
        alert('接口为空')
        return
      }
      //window.open(`${DEFAULT_EXPORT_URL}${url}`)
      window.open(`${DEFAULT_EXPORT_URL}${url}${creditNo}`)//20201118
    }
  }	
  }

  handleChange3 = (name, value) => {
    if (name === 'selectedStartDate' || name === 'selectedEndDate' || name === 'selectedStartDate1' || name === 'selectedEndDate1') {
      if (!(value instanceof Object && value.getTime())) {//判断日期是否为有效值
        return;
      }
      if (name === 'selectedStartDate') {
        this.state.form.selectedStartDate = value;
      } else if (name === 'selectedEndDate') {
        this.state.form.selectedEndDate = value;
      } else if (name === 'selectedStartDate1') {
        this.state.form.selectedStartDate1 = value;
      } else if (name === 'selectedEndDate1') {
        this.state.form.selectedEndDate1 = value;
      }
      
      return value;
    }
  }

  render() {
    const form2 = this.state.form2;
    const form = this.state.form;

    const { exercise } = this.props;
    const tableName = exercise.tableName;

    const status = (handleSubmit, isSubmitting, values) => {
      return<div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          disabled={this.state.loading}
          name={form2.export}
          fullWidth
          style={{ marginRight: '10px' }}
        >
          {form2.export}
          {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
        </Button>
        
      </div>
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        //validate={(values) => {
          //return pwdFormik(values, form2);
        //}}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, '提交');
        }}
        render={({
          values,
          //errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setSelectedDate,
          isSubmitting
        }) => (
            <form>
              {(tableName !== 'r14-7-1' && tableName !== 'c1-1-7' ) &&
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                <Grid container justify="space-between">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    id="date-picker-inline"
                    label="开始时间"
                    fullWidth
                    value={values.selectedStartDate}
                    onChange={e => setFieldValue('selectedStartDate', this.handleChange3.bind(this, 'selectedStartDate', e)())}
                    onBlur={handleBlur}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                
                <Grid container justify="space-between">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    id="date-picker-inline"
                    label="结束时间"
                    fullWidth
                    value={values.selectedEndDate}
                    onChange={e => setFieldValue('selectedEndDate', this.handleChange3.bind(this, 'selectedEndDate', e)())}
                    onBlur={handleBlur}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                
                {tableName === 'r9-4'&& <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                <Grid container justify="space-between">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    id="date-picker-inline"
                    label="开始时间2"
                    fullWidth
                    value={values.selectedStartDate1}
                    onChange={e => setFieldValue('selectedStartDate1', this.handleChange3.bind(this, 'selectedStartDate1', e)())}
                    onBlur={handleBlur}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>

                <Grid container justify="space-between">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    id="date-picker-inline"
                    label="结束时间2"
                    fullWidth
                    value={values.selectedEndDate1}
                    onChange={e => setFieldValue('selectedEndDate1', this.handleChange3.bind(this, 'selectedEndDate1', e)())}
                    onBlur={handleBlur}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                </MuiPickersUtilsProvider>
                }             
              </MuiPickersUtilsProvider>

              }
              {
                status(handleSubmit, isSubmitting, values)
              }
            </form>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);



