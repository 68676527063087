
import React from 'react';
import TabPanels from './TabPanels'
import getSubRouters from './helper';

const PARENT_PATH = '/business/外部融资';
const PATH = '浙商融资';

export const subRootAry2 = [
    '浙商融资查看',
    '浙商已完成融资',
  ];

// option 1:
export function tabPanels(ary = []) {
  return <TabPanels ary={ary}/>;
}

export default getSubRouters(PARENT_PATH, PATH, subRootAry2);
