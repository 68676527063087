// const { string, number, date } = require('yup');
// const decimals = /^([1-9]|[1-9]\d|100)(\.\d{1,2})?$///数字1-100 保留两位小数
// const numbers = /^\d{1,12}$/ //数字
import { delcommafy } from "../../components/numToText";
const numbers = /^\+?[1-9]\d*$/;



//企业管理
export const C51Formik = (values, formStatic) => {
  let errors = {};
  if (!values.select_customer) {
    errors.select_customer = formStatic.select_customer;
  }
  return errors;
}

//额度分配
export const C52Formik = (values, formStatic, usable_amount, credit_due_date) => {
  // same as above, but feel free to move this into a class method now.
  let errors = {};
  if (!values.select_customer) {
    errors.select_customer = formStatic.select_customer;
  }
  if (!values.select_subsidiary) {
    errors.select_subsidiary = formStatic.select_subsidiary;
  }
  if (!values.subsidiary_amount) {
    errors.subsidiary_amount = formStatic.subsidiary_amount;
  } /*else if (!numbers.test(delcommafy(values.subsidiary_amount))) {
    errors.subsidiary_amount = '请输入大于0的整数';
  } */else if (delcommafy(values.subsidiary_amount) > delcommafy(usable_amount)) {

    errors.subsidiary_amount = "分配额度不能大于集团可用额度";
  }

  /*if (credit_due_date&&values.subsidiary_date) {
    var oDate1 = new Date(credit_due_date);
    var oDate2=values.subsidiary_date;
    if (!(values.subsidiary_date instanceof Object && values.subsidiary_date.getTime())) {
      oDate2 = new Date(values.subsidiary_date);
    }
    if (oDate1.getTime() < oDate2.getTime()) {
      errors.subsidiary_date = '不能大于授信到期日';
    }
  }*/
  // if (!values.sharing_protocol) {
  //   errors.sharing_protocol = formStatic.sharing_protocol;
  // }
  // if (!values.commitment_letter) {
  //   errors.commitment_letter = formStatic.commitment_letter;
  // }
  return errors;
}

//额度回收
export const C53Formik = (values, formStatic, usable_amount) => {
  // same as above, but feel free to move this into a class method now.
  let errors = {};
  if (!values.select_customer) {
    errors.select_customer = formStatic.select_customer;
  }
  if (!values.select_subsidiary) {
    errors.select_subsidiary = formStatic.select_subsidiary;
  }
  if (!values.subsidiary_amount) {
    errors.subsidiary_amount = formStatic.subsidiary_amount;
  }/* else if (!numbers.test(delcommafy(values.subsidiary_amount))) {
    errors.subsidiary_amount = '请输入大于0的整数';
  } */else if (delcommafy(values.subsidiary_amount) > delcommafy(usable_amount)) {

    errors.subsidiary_amount = "回收额度不能大于子公司可用额度";
  }
  return errors;
}
