import Home from './home';
import { SignIn, SignInSide, SignOut } from './signin'
import Signup from './signup/signup3'
import RiskManagement from './01-risk-management'
import CoreBusiness from './02-core_business'
import CustomerInfo from './05-customer-information'
import { C404 } from './components'
import {
  OfflineBolt,
  SupervisorAccount,
  Home as AppHome,
  VerifiedUser,
  LockOpen,
  AccountBox,
  ExitToApp,
  BarChart as BarChartIcon,

} from '@material-ui/icons'

export const BusinessRouters = [
  {
    path: '/business',
    title: '点此进入业务系统',
    //title: '核心企业及供应商',
    icon: BarChartIcon,
    component: CoreBusiness,
  },
  {
    path: '/risk-management',
    title: '风险管理',
    icon: OfflineBolt,
    component: RiskManagement,
  },
  {
    path: '/customer-info',
    title: '客户管理',
    icon: SupervisorAccount,
    component: CustomerInfo,
  }
];

export const GeneralRouters = [
  {
    path: '/',
    title: '首页',
    exact: true,
    icon: AppHome,
    component: Home,
  },
  {
    path: '/signin',
    title: '登录 1',
    icon: VerifiedUser,
    component: SignIn,
  }, {
    path: '/login',
    title: '登录 2',
    icon: LockOpen,
    component: SignInSide,
  }, {
    path: '/logout',
    title: '退出',
    icon: ExitToApp,
    component: SignOut,
  }, {
    path: '/signup',
    title: '注册',
    icon: AccountBox,
    component: Signup,
  },
];

export const BackdoorRouters = [
  {
    path: '/home',
    component: Home,
  },
  {
    path: "*",
    component: C404,
  }
];