exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./MaterialIcons-Regular.eot"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./MaterialIcons-Regular.woff2"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./MaterialIcons-Regular.woff"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./MaterialIcons-Regular.ttf"));

// Module
exports.push([module.id, "@font-face {\r\n  font-family: 'Material Icons';\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  src: url(" + ___CSS_LOADER_URL___0___ + "); /* For IE6-8 */\r\n  src: local('Material Icons'),\r\n       local('MaterialIcons-Regular'),\r\n       url(" + ___CSS_LOADER_URL___1___ + ") format('woff2'),\r\n       url(" + ___CSS_LOADER_URL___2___ + ") format('woff'),\r\n       url(" + ___CSS_LOADER_URL___3___ + ") format('truetype');\r\n}\r\n\r\n.material-icons {\r\n  font-family: 'Material Icons';\r\n  font-weight: normal;\r\n  font-style: normal;\r\n  font-size: 24px;  /* Preferred icon size */\r\n  display: inline-block;\r\n  line-height: 1;\r\n  text-transform: none;\r\n  letter-spacing: normal;\r\n  word-wrap: normal;\r\n  white-space: nowrap;\r\n  direction: ltr;\r\n\r\n  /* Support for all WebKit browsers. */\r\n  -webkit-font-smoothing: antialiased;\r\n  /* Support for Safari and Chrome. */\r\n  text-rendering: optimizeLegibility;\r\n\r\n  /* Support for Firefox. */\r\n  -moz-osx-font-smoothing: grayscale;\r\n\r\n  /* Support for IE. */\r\n  font-feature-settings: 'liga';\r\n}\r\n", ""]);

