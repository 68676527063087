
//表格公共接口列表
export const core_business_url = (auth = {}, table) => {
    if (!auth.id) return [];
    else {
        const { id, organization, roles } = auth;
        let rolesId = roles[0].id;
        let rolesId2 = roles[0].id;
        let usersId = rolesId === 11 ? `&user_id=${id}` : '';

        //退回
        if (table === 'm2-5-3' || table === 'm3-1-10' || table === 'm3-2-8' || table === 'm7-4-3') {
            switch (rolesId) {
                case 11:
                    rolesId = 12;
                    rolesId2 = 13;
                    break;
                case 12:
                    //if (table === 'm3-2-8' || table === 'm2-5-3') rolesId = 1;
                    if (table === 'm3-2-8' ) {
                        rolesId = 16;//2021-04-20 融资分配
                    } else if (table === 'm2-5-3' || table === 'm7-4-3'){
                        rolesId = 1; 
                    } else {
                        rolesId = 1;
                    } 
                    break;
                case 13:
                    //if (table === 'm3-2-8' || table === 'm2-5-3') rolesId = 1;
                    if (table === 'm3-2-8' ){
                        rolesId = 16;//2021-04-20 融资分配
                    } else if (table === 'm2-5-3'){
                        rolesId = 1; 
                    } else{
                        rolesId = 1;
                    } 
                    break;
                default:
                    ;
            }
        }
        return [
            {
                name: "m1",
                title: '额度查询',
                url: { query: '/msapi/amount/find?organization_id=' + organization.id }
            },
            {
                name: "md",
                title: '我的额度',
                url: { query: '/amount/usedAmount/GetMyAmount?organizationId=' + organization.id }
            },
            {
                name: "mh",
                title: '历史额度',
                url: { query: '/amount/usedAmount/GetHistoricalAmount?organizationId=' + organization.id }
            },
            {
                name: "m2-1",
                url: {
                    query: `/msapi/issue/find?organization_id=${organization.id}${usersId}`,
                    query2: `/msapi/issue/find?receive_credit_code=${organization.credit_no}&status_id=5`,
                    query3: `/msapi/circulation/find?receive_credit_code=${organization.credit_no}&status_id=5`,
                    get: '/msapi/issue/get/',
                },
                title: '我的融单'
            },
            {
                name: "m2-2",
                url: { query: '' },
                title: '申请列表'
            },
            {
                name: "m2-3",
                url: {
                    query: `/msapi/issue/find?organization_id=${organization.id}${usersId}&status_id=1`,
                    get: '/msapi/flow/find', get2: '/msapi/issue/get/'
                },
                title: '融单开具制单'
            },
            {
                name: "m2-4",
                url: {
                    query: `/msapi/issue/find?organization_id=${organization.id}&status_id=2`,
                    get: '/msapi/flow/find', get2: '/msapi/issue/get/'
                },
                title: '融单开具复核'
            },
            {
                name: "m2-5-1",
                url: {
                    query: `/msapi/issue/find?organization_id=${organization.id}&status_id=3${usersId}`,
                    query1: `/msapi/issue/find?organization_id=${organization.id}&status_id=7${usersId}`,
                    query2: `/msapi/issue/find?organization_id=${organization.id}&status_id=2${usersId}`,
                    get2: '/msapi/issue/get/'
                },
                title: '开立中融单'
            },
            {
                name: "m2-5-2",
                url: {
                    query: `/msapi/issue/find?organization_id=${organization.id}&status_id=4${usersId}`,
                    query2: `/msapi/issue/find?organization_id=${organization.id}&status_id=5${usersId}`,
                    get2: '/msapi/issue/get/'
                },
                title: '已开融单'
            },
            {
                name: "m2-5-3",
                url: {
                    query: `/msapi/issue/find?organization_id=${organization.id}&status_id=7${usersId}&role_id=${rolesId}`,
                    query2: `/msapi/issue/find?organization_id=${organization.id}&status_id=6${usersId}`,
                    query3: `/msapi/issue/find?organization_id=${organization.id}&status_id=7${usersId}&role_id=${rolesId2}`,
                    get2: '/msapi/issue/get/',
                    get: '/msapi/flow/find/'
                },
                title: '已退回融单'
            },
            {
                name: "m2-5-5",
                url: {
                    query: `/msapi/issue/find?organization_id=${organization.id}&status_id=6${usersId}&role_id=${rolesId}`,
                    query2: `/msapi/issue/find?organization_id=${organization.id}&status_id=6${usersId}`,
                    query3: `/msapi/issue/find?organization_id=${organization.id}&status_id=6${usersId}&role_id=${rolesId2}`,
                    get2: '/msapi/issue/get/',
                    get: '/msapi/flow/find/'
                },
                title: '已拒签融单'
            },
            {
                name: "m2-5-6",
                url: {
                    query: `/msapi/financing/getFinancingDetails?issue_credit_code=${organization.credit_no}`,
                },
                title: '已开融单融资明细'
            },
            {
                name: "m2-5-4",
                url: { query: `/msapi/issue/find?organization_id=${organization.id}&status_id=2`, get2: '/msapi/issue/get/' },
                title: '批量审核'
            },
            {
                name: "m3-1-1",
                url: {
                    query: `/msapi/issue/find?receive_credit_code=${organization.credit_no}&status_id=4`,
                    query2: `/msapi/circulation/find?receive_credit_code=${organization.credit_no}&status_id=4`,
                    query3: `/msapi/financing/find?open_credit_code=${organization.credit_no}&role_id=14&status_id=4`,
                    query4: `/msapi/financing/find?open_credit_code=${organization.credit_no}&role_id=21&status_id=4`,
                    query5: `/msapi/financing/find?open_credit_code=${organization.credit_no}&role_id=22&status_id=4`,
                    get: '/msapi/cflow/find',
                    get2: '/msapi/issue/get/',
                    get3: '/msapi/flow/find'
                },
                title: '融单签收'
            },
            {
                name: "m3-1-2",  // william jiang
                url: {
                    // query: `/api/circulation/find/${organization.credit_no}/5/sync2`,
                    query: `/msapi/issue/findUsable?receive_credit_code=${organization.credit_no}&status_id=5`,
                    query2: `/msapi/circulation/findUsable?receive_credit_code=${organization.credit_no}&status_id=5`,
                    get: '/msapi/cflow/find/',
                    get2: '/msapi/issue/get/',
                    get3: '/msapi/flow/find'
                },
                title: '可用融单'
            },
            {
                name: "m3-1-3",
                url: {
                    query: `/msapi/circulation/find?organization_id=${organization.id}${usersId}`,
                    // query2: `/api/circulation/find?organization_id=${organization.id}&status_id=3${usersId}`,
                    get2: '/msapi/circulation/get/'
                },
                title: '支付融单查看'
            },
            {
                name: "m3-1-4",
                url: {
                    query: `/msapi/financing/find?organization_id=${organization.id}&status_id=11${usersId}`,
                    get2: '/msapi/financing/get/'
                },
                title: '已融资融单'
            },
            {
                name: "m3-1-5",
                url: {
                    query: `/msapi/circulation/find?organization_id=${organization.id}&status_id=5${usersId}`,
                    get2: '/msapi/circulation/get/'
                },
                title: '已支付融单'
            },
            {
                name: "m3-1-6",
                url: { query: `/msapi/issue/query/${organization.credit_no}/payment_day_expired`, get2: '/msapi/issue/get/' },
                title: '已到期融单'
            },
            {
                name: "m3-1-7",
                url: {
                    //query: `/api/issue/find?receive_credit_code=${organization.credit_no}&status_id=5`,
                    //query2: `/api/circulation/find?receive_credit_code=${organization.credit_no}&status_id=5`,
                    query: `/msapi/issue/getIssueOriginalKH?open_credit_code=${organization.credit_no}&receive_credit_code=${organization.credit_no}&status_id=5`,
                    query2: `/msapi/circulation/getCirculationOriginalKH?payer_credit_code=${organization.credit_no}&receive_credit_code=${organization.credit_no}&status_id=5`,
                    //query3: `/api/issue/find?open_credit_code=${organization.credit_no}&status_id=5`,
                    //query4: `/api/circulation/find?payer_credit_code=${organization.credit_no}&status_id=5`,
                    get2: '/msapi/issue/get/'
                },
                title: '原始融单'
            },
            {
                name: "m3-1-8",
                url: {
                    query: `/msapi/circulation/find?organization_id=${organization.id}${usersId}&status_id=1`,
                    get2: '/msapi/circulation/get/'
                },
                title: '融单流转'
            },
            {
                name: "m3-1-9",
                url: {
                    query: `/msapi/circulation/find?organization_id=${organization.id}&status_id=2`,
                    get: '/msapi/cflow/find',
                    get2: '/msapi/circulation/get/'
                },
                title: '融单流转复核'
            },
            {
                name: "m3-1-10",
                url: {
                    query: `/msapi/circulation/find?organization_id=${organization.id}&status_id=7${usersId}&role_id=${rolesId}`,
                    query2: `/msapi/circulation/find?organization_id=${organization.id}&status_id=6${usersId}`,
                    query3: `/msapi/circulation/find?organization_id=${organization.id}&status_id=7${usersId}&role_id=${rolesId2}`,
                    // query4: `/api/issue/find?organization_id=${organization.id}&status_id=6${usersId}`,
                    get2: '/msapi/circulation/get/',
                    get: '/msapi/cflow/find/'
                },
                title: '已退回流转'
            },
            {
                name: "m3-1-11",
                url: {
                    query: `/msapi/circulation/find?organization_id=${organization.id}&status_id=6${usersId}&role_id=${rolesId}`,
                    query2: `/msapi/circulation/find?organization_id=${organization.id}&status_id=6${usersId}`,
                    query3: `/msapi/circulation/find?organization_id=${organization.id}&status_id=6${usersId}&role_id=${rolesId2}`,
                    // query4: `/api/issue?organization_id=${organization.id}&status_id=6${usersId}`,
                    get2: '/msapi/circulation/get/',
                    get: '/msapi/cflow/find/'
                },
                title: '已拒签流转'
            },
            {
                name: "m3-1-12",
                url: { query: `/msapi/issue/${organization.credit_no}/getPayMentDayNotExpired`, get2: '/api/issue/' },
                title: '未到期融单'
            },
            {
                name: "m3-2-1",
                url: {
                    query: `/msapi/financing/find?organization_id=${organization.id}${usersId}&status_id=1`,
                    get2: '/msapi/financing/get/'
                },
                title: '融资申请'
            },
            {
                name: "m3-2-2",
                url: {
                    query: `/msapi/financing/find?organization_id=${organization.id}&status_id=2`,
                    query2: `/msapi/financing/find?open_credit_code=${organization.credit_no}&status_id=5`,
                    get: '/msapi/fflow/find',
                    get2: '/msapi/financing/get/'
                },
                title: '融资复核'
            },
            {
                name: "m3-2-3",
                url: {
                    query: `/msapi/financing/find?organization_id=${organization.id}`,
                    get2: '/msapi/financing/get/'
                },
                title: '融资查看'
            },
            {
                name: "m3-2-4",
                url: { query: '' },
                title: '询价记录'
            },
            {
                name: "m3-2-5",
                url: {
                    query: `/msapi/financing/find?organization_id=${organization.id}${usersId}`,
                    get2: '/msapi/financing/get/'
                },
                title: '融资申请中'
            },  
            {
                name: "m3-2-6",
                url: { query: `/msapi/financing/find?organization_id=${organization.id}${usersId}`, get2: '/msapi/financing/get/' },
                title: '融资查看'
            },
            {
                name: "m3-2-7",
                url: { query: '' },
                title: '放款异常'
            },
            {
                name: "m3-2-8",
                url: {
                    query: `/msapi/financing/find?organization_id=${organization.id}&status_id=7${usersId}&role_id=${rolesId}`,
                    query2: `/msapi/financing/find?organization_id=${organization.id}&status_id=7${usersId}&role_id=${rolesId2}`,
                    get2: '/msapi/financing/get/',
                    get: '/msapi/fflow/find/'
                },
                title: '已退回融资'
            },
            {
                name: "m3-2-9",
                url: {
                    query: `/smrd/interest_rate/find?&status_id=9`,
                    get: `/smrd/interest_rate/`,
                    get2: `/smrd/rflow/find?irid=`,
                    get3: `/smrd/rflow`
                },
                title: '查看利率表'
            },
            {
                name: "m3-2-10",
                url: {
                    query: `/loan/getLoanKHDetails?enterpriseNo=`,
                },
                title: '融资放款明细'
            },
            {
                name: "m3-2-11",
                url: {
                    query: `/loan/getFinancingRate?creditNo=`,
                },
                title: '融资时间及费率'
            },
            {
                name: "m3-2-12",
                url: {
                    query: `/msapi/financing/find?issue_credit_code=${organization.credit_no}&status_id=11`,
                },
                title: '下属供应商已融资查看'
            },
            {
                name: "m3-3-1",
                url: { query: '' },
                title: '待处理'
            },
            {
                name: "m3-3-2",
                url: { query: '' },
                title: '已处理'
            },
            {
                name: "m4-1",//is_westcol_done: 0: 待还款, 1: 审核中, 2. 还款中, 3. 已退回, 4. 已还款, 5. 未还款//defaultValue: 0
                //query: `/api/issue?open_credit_code=${organization.credit_no}&is_westcol_done=0,3`,//20201010
                //query: `/msapi/circulation/find?open_credit_code=${organization.credit_no}` },  //20210108
                url: { 
                    query: `/repayment/getRepaymentList?open_credit_code=${organization.credit_no}&is_westcol_done=0,3&type=`,//20201218
                    get3: '/msapi/payment_flow?issue_id='
                },
                title: '待还款'
            },
            {
                name: "m4-2",
                //query: `/msapi/circulation/find?open_credit_code=${organization.credit_no}`//20210108
                url: {
                    query: `/repayment/getRepaymentList?open_credit_code=${organization.credit_no}&is_westcol_done=1,2&type=`,//20201218
                    get: '/msapi/issue',
                    get2: '/repayment/savePaymentFlow',
                    get3: '/repayment/getRepaymentList?issue_id='
                    },
                title: '还款审批'
            },
            {
                name: "m4-3",
                //query: `/msapi/circulation/find?open_credit_code=${organization.credit_no}` //20201218
                url: { //已还款、额度已恢复、额度不可恢复
                    query:  `/repayment/getRepaymentList?open_credit_code=${organization.credit_no}&is_westcol_done=4,6,7&type=`,//20201222
                },
                title: '已还款'
            },
            {
                name: "m4-4",
                url: {
                    query: `/repayment/getRepaymentList?open_credit_code=${organization.credit_no}&is_westcol_done=5&type=`,//20201017
                    get3: '/repayment/getRepaymentList?issue_id='
                },
                title: '未还款'
            },
            {
                name: "m4-5",
                url: {
                    query: `/repayment/getReturnInformationList?organization_id=${organization.id}`,//20201229
                    // get3: '/api/getReturnInformationList?issue_id='
                },
                title: '还款信息'
            },
            {
                name: "m4-6",
                url: {
                    query: `/loan/getRepaymentDetails?enterpriseNo=`,//20210508
                },
                title: '还款明细'
            },
            {
                name: "m5-1",
                url: { query: `/msapi/user/find?organization_id=${organization.id}&active=1`, get: '/msapi/user/get/', get2: '/msapi/user/del/', },
                title: '用户管理'
            },
            {
                name: "m5-2",
                url: { query: `/msapi/account/findCompany`, 
                //query: `/account/findCompany?creditNo=${organization.credit_no}`,
             },
                title: '银行账号信息'
            },
            {
                name: "m6-1",
                url: { query: `/contract/zc?creditNo=` },
                title: '注册合同管理'
            },
            {
                name: "m6-2",
                url: { query: `/contract/issues?creditNo=` },
                title: '开立合同管理'
            },

            {
                name: "m6-3",
                url: { query: `/contract/financings?creditNo=` },
                title: '融资合同管理'
            },
            {
                name: "m6-6",
                url: { query: `/contract/receivables?creditNo=` },
                title: '应收合同管理'
            },
            {
                name: "m7-1",
                url: {
                    query: `/msapi/receivable/find?organization_id=${organization.id}${usersId}`,
                    query2: `/msapi/receivable/find?companyCode=${organization.credit_no}&status_id=5`,
                    get: '/msapi/receivable/get/',
                },
                title: '我的应收账款'
            },
            {
                name: "m7-2",
                url: {
                    query: `/msapi/receivable/find?organization_id=${organization.id}${usersId}&status_id=1`,
                    get: '/msapi/flow/find', get2: '/msapi/receivable/get/'
                },
                title: '应收账款开具'
            },
            {
                name: "m7-5",
                url: {
                    query: `/msapi/receivable/find?companyCode=${organization.credit_no}&status_id=3&role_id=14&company_type=0`,
                    query1: `/msapi/receivable/find?receive=${organization.name}&status_id=3&role_id=14&company_type=1`,              
                    get2: '/msapi/receivable/get/'
                },
                title: '应收账款签收'
            },
            {
                name: "m7-3",
                url: {
                    query: `/msapi/receivable/find?organization_id=${organization.id}&status_id=2`,
                    get: '/msapi/flow/find', get2: '/msapi/receivable/get/'
                },
                title: '应收账款复核'
            },
            {
                name: "m7-4-1",
                url: {
                    query: `/msapi/receivable/find?organization_id=${organization.id}&status_id=3${usersId}`,
                    query2: `/msapi/receivable/find?organization_id=${organization.id}&status_id=2${usersId}`,
                    get2: '/msapi/receivable/get/'
                },
                title: '开具中应收账款'
            },
            {
                name: "m7-4-2",
                url: {
                    query: `/msapi/receivable/find?companyCode=${organization.credit_no}&status_id=11`,
                    query2: `/msapi/receivable/find?organization_id=${organization.id}&status_id=5${usersId}`,
                    get2: '/msapi/receivable/get/'
                },
                title: '已开应收账款'
            },
            {
                name: "m7-4-3",
                url: {
                    query: `/msapi/receivable/find?organization_id=${organization.id}&status_id=7${usersId}&role_id=${rolesId}`,
                    query2: `/msapi/receivable/find?organization_id=${organization.id}&status_id=6${usersId}`,
                    query3: `/msapi/receivable/find?organization_id=${organization.id}&status_id=7${usersId}&role_id=${rolesId2}`,
                    get2: '/msapi/receivable/get/',
                    get: '/msapi/flow/find/'
                },
                title: '已退回应收账款'
            },
            {
                name: "m7-4-4",
                url: {
                    query: `/repayment/getRepaymentYSList?open_credit_code=${organization.credit_no}&is_westcol_done=0,3&type=`,
                    get2: '/msapi/receivable/get/',
                    get3: '/msapi/cflow/find'
                },
                title: '待还款'
            },
            {
                name: "m7-4-5",
                url: {
                    query: `/repayment/getRepaymentYSList?open_credit_code=${organization.credit_no}&is_westcol_done=1,2&type=`,
                    get2: '/msapi/receivable/get/',
                    get3: '/msapi/cflow/find'
                },
                title: '还款审批'
            },
            {
                name: "m8-1-1",
                url: {
                    query: `/cmb/cmbFinancingListKH?credit_no=${organization.credit_no}`,
                },
                title: '招行融资查看'
            },
            {
                name: "m8-1-2",
                url: {
                    query: `/cmb/cmbFinishFinancingListKH?credit_no=${organization.credit_no}`},
                title: '招行已完成融资'
            },
            {
                name: "m8-2-1",
                url: {
                    query: `/msapi/czb/czbFinancingList?credit_no=${organization.credit_no}`, //?credit_no=${organization.credit_no}
                },
                title: '浙商融资查看'
            },
            {
                name: "m8-2-2",
                url: {
                    query: `/msapi/czb/czbFinishFinancingList?credit_no=${organization.credit_no}`},
                title: '浙商已完成融资'
            },
            {
                name: "m8-3-1",
                url: {
                    query: `/msapi/spd/spdFinancingList?credit_no=${organization.credit_no}`, //?credit_no=${organization.credit_no}
                },
                title: '浦发融资查看'
            },
            {
                name: "m8-3-2",
                url: {
                    query: `/msapi/spd/spdFinishFinancingList?credit_no=${organization.credit_no}`},
                title: '浦发已完成融资'
            },
        ]
    }
}
