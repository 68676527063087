import {
  Lock as LockIcon,
  AssignmentInd as AssignmentIndcon,
  FindInPage as FindInPageIcon,
  Sms as SmsIcon,
  LockOpenRounded,
} from '@material-ui/icons';
import { subRouters, getMenu, getContent } from './common';
import { SetView } from './UserManager/commonTable'

const PARENT_PATH = subRouters['系统管理'];

const Info = [
  {
    path: "口令更改",
    icon: LockIcon,
    component: SetView('r1-1')
  },
  {
    path: "用户信息权限变更",
    icon: AssignmentIndcon,
    component: SetView('r1-2')
  },
  {
    path: "角色查询",
    icon: FindInPageIcon,
    component: SetView('r1-3')
  },
  {
    path: "短信查询",
    icon: SmsIcon,
    component: SetView('r1-4')
  },
  /*{
    path: "IP白名单管理",
    icon: LockIcon,
    component: SetView('r1-5')
  },
  {
    path: "访问限制管理",
    icon: LockOpenRounded,
    component: SetView('r1-6')
  },*/
];

export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
]
