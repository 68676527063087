
//表格公共接口列表
export const core_business_url = (auth, table) => {
    if (!auth.id) return [];
    else {
        return [
            {
                name: "c2-1",
                title: '主要财务资料',
                url: { query: ``, get: '',get2: '' }
                // url: { query: `/api/customer_info`, get: '/api/customer_info',get2: '/api/customer_info' }
            },

        ]
    }

}