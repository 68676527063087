//融单融资界面表单
export default {
    timelimit:'登记期限（月）',
    register_enddate:'登记到期日',
    title: "填表人归档号",
    payment_day: "承诺付款日",
    maincontractnumber: "融资合同号码",
    maincontractcurrency:'融资合同币种',
    maincontractsum: "融资合同金额",
    pledgecontractsum: "转让财产价值",
    pledgecontractcurrency:'转让财产币种',
    collateraldescribe: "转让财产描述",
    debtorname: "出让人名称",
    organizationcode: "组织机构代码",
    industryregistrationcode: "统一社会信用代码",
    corporationname: "出让人法人",
    industrycode: "所属行业",
    corporationscale: "规模",
    province: "省",
    city: "市",
    detailaddress: "详细地址"
}