
import getSubRouters from './helper';

 export const subRootAry44 = [
    '新增额度启用',
    '额度启用审核',
    // '额度启用查询',
    '已退回额度启用申请',
  ];
const PARENT_PATH = '/risk-management/额度审批';
const PATH = '额度解冻';
export default getSubRouters(PARENT_PATH, PATH, subRootAry44);