import React from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  OfflineBolt as OfflineBoltIcon,
  History as HistoryIcon,
  VerifiedUser as VerifiedUserIcon,
} from '@material-ui/icons';
import Accordion from '../components/Accordion'
import subRouters, { SetView } from './common'

const PARENT_PATH = subRouters['合同管理'];

const Info = [
  {
    path: "注册合同管理",
    icon: OfflineBoltIcon,
    component: SetView('m6-1'),
  },
  {
    path: "开立合同管理",
    icon: HistoryIcon,
    component: SetView('m6-2'),
  },
  {
    path: "融资合同管理",
    icon: VerifiedUserIcon,
    component: SetView('m6-3'),
  },
  {
    path: "应收合同管理",
    icon: VerifiedUserIcon,
    component: SetView('m6-6'),
  },
];

export const Menu6 = () => {
  const list = Info.map(item => {
    const CompIcon = item.icon;
    return (
      <ListItem
        button
        component={Link}
        to={`${PARENT_PATH}/${item.path}`}
        key={item.path}>
        <ListItemIcon>
          <CompIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={item.path} />
      </ListItem>
    )
  });

  return (
    <div>
      {list}
      <ListItem>
        <Accordion />
      </ListItem>
    </div>
  );
}

export const Content6 = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${Info[0].path}`}
      />
      {Info.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${PARENT_PATH}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${PARENT_PATH}/${item.path}`}
              render={() => <h2>{item.path}</h2>}
              key={item.path}
            />
          )
        }
      })
      }
    </Switch>
  )
};
