import {
  AccountCircle as AccountCircleIcon,
} from '@material-ui/icons';
import { subRouters, getMenu, getContent } from './common';

import { SetView } from './UserManager/commonTable'



const PARENT_PATH = subRouters['用户管理'];

const Info = [
  {
    path: "用户管理",
    icon: AccountCircleIcon,
    component: SetView('r12-1'),
  },
];



export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];

