import React from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  AssignmentReturned as AssignmentReturnedIcon,
  Payment as PaymentIcon,
  MergeType as MergeTypeIcon
} from '@material-ui/icons';
import Accordion from '../components/Accordion'
import subRouters, { SetView } from './common'
import C81, { subRootAry1 } from './containers/C8_1';
import C82, { subRootAry2 } from './containers/C8_2';
import C83, { subRootAry3 } from './containers/C8_3';
const PARENT_PATH = subRouters['外部融资'];

const Info = [
  {
    path: "招行融资",
    icon: PaymentIcon,
     component: C81,
  },
  {
    path: "浙商融资",
    icon: PaymentIcon,
     component: C82,
  },
  {
    path: "浦发融资",
    icon: PaymentIcon,
     component: C83,
  },
  
];

const view = {};

[...subRootAry1, ...subRootAry2, ...subRootAry3].forEach((item, index) => {
  switch (item) {
    case '招行融资查看':
      view[item] = SetView('m8-1-1');
      break;
    case '招行已完成融资':
      view[item] = SetView('m8-1-2');
      break;
    case '浙商融资查看':
      view[item] = SetView('m8-2-1');
      break;
    case '浙商已完成融资':
      view[item] = SetView('m8-2-2');
      break;
    case '浦发融资查看':
      view[item] = SetView('m8-3-1');
      break;
    case '浦发已完成融资':
      view[item] = SetView('m8-3-2');
      break;
    default: ;
  }
})



export const Menu8 = () => {


  const list = Info.map((item, index) => {
    const CompIcon = item.icon;
    switch (index) {
      case 0:
        return <C81 key="C81" />;
      case 1:
        return <C82 key="C82" />;
      case 2:
        return <C83 key="C83" />;
      default:
        return (
          <ListItem button component={Link} to={`${PARENT_PATH}/${item.path}`} key={item.path}>
            <ListItemIcon>
              <CompIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={item.path} />
          </ListItem>
        );
    }
  });
  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
};

export const Content8 = () => {
  let list = [...subRootAry1,...subRootAry2,,...subRootAry3,];
  return (
    <Switch>
      <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${subRootAry1[0]}`}
      />
      {Info.map(item => (
        <Route
          path={`${PARENT_PATH}/${item.path}`}
          component={item.component}
          key={item.path}
        />
      ))}
      {list.map(item => {
        if (view[item]) {
          return (
            <Route
              path={`${PARENT_PATH}/${item}`}
              component={view[item]}
              key={item}
            />

          )
        } else {
          return (
            <Route
              path={`${PARENT_PATH}/${item}`}
              render={() => <h2>{item}</h2>}
              key={item}
            />
          )
        }

      })}
    </Switch>
  )
}
