

export const core_business_url = (auth, table) => {
    if (!auth.id) return [];
    else {
        return [
           // {
            //    name: "r7-1-1",
             //   url: { query: '' },
             //   title: '贷后基础条件管理查询'
           // },
            {
                name: "r7-1-2",
                url: { query: '' },
                title: '短信相关信息查询'
            },
            {
                name: "r7-2-1",
                url: { query: '' },
                title: '财务报表查询'
            },
            {
                name: "r7-2-2",
                url: { query: '' },
                title: '定期检查情况查询'
            },
            {
                name: "r7-3-1",
                url: { query: '' },
                title: '风险分类变动情况查询'
            },
            {
                name: "r7-3-2",
                url: { query: '' },
                title: '逾期欠息查询'
            },
            {
                name: "r7-3-3",
                url: { query: '' },
                title: '风险提示及反馈'
            },
            {
                name: "r7-4-1",
                url: { query: '' },
                title: '融资款收回方式登记'
            },
            {
                name: "r7-4-2",
                url: { query: '' },
                title: '额度发放和收回流水查询'
            },
            {
                name: "r7-4-3",
                url: { query: '' },
                title: '未到期借据查询'
            },
            {
                name: "r7-5-1",
                url: { query: '' },
                title: '贷后实时风险分类'
            },
            {
                name: "r7-6-1",
                url: { query: '' },
                title: '风险预警解除查询'
            },
            {
                name: "r7-6-2",
                url: { query: '' },
                title: '风险预警认定查询'
            },
            {
                name: "r7-6-3",
                url: { query: '' },
                title: '未到期预警额度查询'
            },
            {
                name: "r7-6-4",
                url: { query: '' },
                title: '预警级别调整查询'
            },
            {
                name: "r7-7-1",
                url: { query: '' },
                title: '融后汇总表'
            },
        ]
    }

}
