import React from 'react';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { loginInfos} from '../../components/numToText';
import {
  FilterVintage,
  TrackChanges,
  Description,
  PersonPin,
  ExpandLess,
  ExpandMore,
  StarBorder,
  Notifications,
  RotateRight,
  Payment,
  Redo,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const getSubRouters = (ppath, path, ary) => () => {
  let roleId = 0;
  let auth = loginInfos();
  try {
    if (auth) {
      roleId = auth.roles[0].id || 0;
    }
  } catch (e) {
    roleId = 0;
  }
  let ary1 = ary.filter((item) => {
    if (roleId === 11) {
      return !(item==="融单流转复核"||item==="融资复核") ? item : null;
    } else if(roleId === 13) {
      return !(item==="融单流转复核"||item==="融资复核" || item==='融单签收' || item === "融单流转" || item === "融资申请") ? item: null;
    } else {
      return !(item === "融单流转" || item === "融资申请") ? item : null;
    }
  })
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
//收支
  const icons = [
    FilterVintage,
    TrackChanges,
    Payment,
    PersonPin,
    Notifications,
    Description,
    Redo,
    RotateRight,
  ];
  //融资
  // const icons2 = [
  //   Widgets,
  //   ViewModule,
  //   CheckCircle,
  //   Warning,
  //   RotateRight,
  // ];
  // //转让回执
  // const icons3 = [
  //   DragHandle,
  //   Beenhere,
  // ];

  const getSubItems = () => {
    return ary1.map((item, inx) => {
      const Icon = icons[inx] || Description;
      return (
        <ListItem
          button
          component={Link}
          to={`${ppath}/${item}`}
          className={classes.nested}
          key={item}
        >
          <ListItemIcon>
            <Icon fontSize="small"/>
          </ListItemIcon>
          <ListItemText primary={item}/>
        </ListItem>
      )
    });
  };

  return (
    <>
    <ListItem
      button
      key={path}
      onClick={handleClick}>
      <ListItemIcon>
        <StarBorder fontSize="small"/>
      </ListItemIcon>
      <ListItemText primary={path}/>
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {getSubItems()}
      </List>
    </Collapse>
    </>
  );
}

export default getSubRouters;