
import React from 'react';
import TabPanels from './TabPanels'
import getSubRouters from './helper';

const PARENT_PATH = '/business/外部融资';
const PATH = '浦发融资';

export const subRootAry3 = [
    '浦发融资查看',
    '浦发已完成融资',
  ];

// option 1:
export function tabPanels(ary = []) {
  return <TabPanels ary={ary}/>;
}

export default getSubRouters(PARENT_PATH, PATH, subRootAry3);
