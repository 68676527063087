import React from 'react';
import {Route, Switch, Redirect, Link} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {
  ListItem, ListItemText, Typography, Link as ALink,
  Grid, Card, CardMedia,
} from '@material-ui/core';
import {loginInfos} from '../components/numToText';
import {version} from "../../package.json";

export const NavList = ({navs}) => {
  const navList = navs.map(router => {
    const CompIcon = router.icon;
    return (
      <ListItem
        style={{width: 'auto', paddingTop: '4px', paddingBottom: '4px'}}
        button
        component={Link}
        to={router.path}
        key={router.path}
      >
        <CompIcon fontSize="small"/>
        <ListItemText style={{paddingLeft: '8px'}} primary={router.title}/>
      </ListItem>
    )
  });
  return (
    <div className="listNavigator">
      <ul style={{display: 'flex', displayDirection: 'row', flexFlow: 'row wrap'}}>
        {navList}
      </ul>
    </div>
  )
};

export const RouteList = ({routes, redirect = {}}) => {
  return (
    <Switch>
      {!isEmpty(redirect) ?
        <Redirect
          exact
          from={redirect.from}
          to={redirect.to}
        /> : null};
      {routes.map(route => <Route
        key={route.path}
        path={route.path}
        component={route.component}
      />)}
    </Switch>
  )
};

export const reduxLink = (router = {}) => {
  let companyName = '';
  try {
    companyName = loginInfos().organization.name
  } catch (error) {
  }
  const {icon, path, title} = router;
  const CompIcon = icon;
  return (
    <ListItem
      button
      component={Link}
      to={path}
      key={path}
    >
      <CompIcon fontSize="small"/>
      <span>{title === '核心企业及供应商' ? companyName : title}</span>
    </ListItem>
  )
};

// +Date.now(),  date '+%F %T': 2020-03-28 18:59:05
// 发布于 ${moment(__DATE_TIME__).format('YYYY-MM-DD, HH:mm:ss')}。
export const Copyright = ({opt}) => {
  opt = opt || 'subtitle1';
  return (
    <Typography component="span" variant={opt} style={{ color: '#e7e7e7' }} align="center">
      版权所有 Copyright ©
      <ALink color="inherit" href="https://www.erongdan.com/">
        陕西善美商业保理有限公司
      </ALink>
      {` 当前版本 ${version}，${new Date().getFullYear()}年。`}
    </Typography>
  );
};

export const ICP = ({opt}) => {
  opt = opt || 'subtitle1';
  return (
    <Typography component="span" variant={opt} style={{ color: '#e7e7e7' }} align="center">
      陝ICP备<ALink color="inherit" href="http://beian.miit.gov.cn/" underline="always">20002724</ALink>号
    </Typography>
  );
}

// material-ui是通过CardMedia来封装显示image的。
export const showImg = (options) => {
  return (
    <Card style={{border: 0, borderRadius: 0, boxShadow: "none"}}>
      <CardMedia component={"img"} {...options} />
    </Card>
  )
};

// 关于我们
export const showLogo = (cname) => {
  cname = cname || '善美保理';
  const logo = showImg({
    image: '/imgs/logo1.png',
    title: cname,
    style: {
      width: '70%',
      height: '70%',
    }
  });
  return (
    <Grid container spacing={3}>
      <Grid item>
        {logo}
      </Grid>
      <Grid item>
        <Typography variant={"h4"} component={"h4"} color={"inherit"} style={{opacity: 0.6}}>
          {cname}
        </Typography>
      </Grid>
    </Grid>
  )
};