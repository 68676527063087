import getSubRouters from './helper';

 export const subRootAry5 = [
    '光大保理融资发起',
    '光大保理融资查看',
    '光大保理已完成融资',
    '光大非保理融资查看',
    '光大非保理已完成融资',
    

  ];

const PARENT_PATH = '/risk-management/外部融资';
const PATH = '光大融资';
export default getSubRouters(PARENT_PATH, PATH, subRootAry5);