import React, { Component } from 'react';
import {
  Edit
} from '@material-ui/icons'
import MaterialTable from '../../vendors/material-table';
import { capitalize, fetching } from '../../config/utils';
import { core_business_url } from '../core_business_url';
import { C11Form, C12Form, C13Form , C15Form , C16Form} from './index'
// import Dialog from './Dialog';
import DialogForm from '../../components/commonForm/DialogForm';
import { f5_1, common, r1_3,r1_5 } from '../formStatic'
import { loginInfos } from '../../components/numToText'
import { formatDate } from '../../components/date'


// copied from 05-.../DataView.js
class View extends Component {
  state = {
    columns: [{}],
    data: [],
    hasToken: sessionStorage.getItem("authToken"),
    dataUrl: { url: { query: '' } },
    id: 10000,
    form2: { ...common },
    exercise: {
      save: '1',
      DialogTitle: '新增',
      open: false,
      status2: '1'
    }

  };
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  }
  //获取数据
  componentDidMount() {
    const { table } = this.props;
    fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {
        if (data !== undefined && data !==null) {
          let auth = loginInfos();
          const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
          //根据不同的页面，获取不同的接口地址，渲染不同的数据
          const dataUrl = core_business_url(auth, table).find(item => item.name === table);
          const dataUrl2 = dataUrl || {};
          this.setState({ columns, dataUrl: dataUrl });
          if (dataUrl2.url) {
            if (dataUrl.name === 'r12-1') {
              dataUrl2.url.query !== '' && this.onQuery();
            } else {
              if (dataUrl2.url.query !== '') {
                this.onQuery1();
              }
            }
          }
        }
      })
  }

  //查询角色数据
  onQuery = () => {
    try {
      const { url } = this.state.dataUrl;
      fetching(`${url.query}`).then(data => {
        if (data !== undefined && data !==null) {
          let datas = data.map(item => {
            item.createdAt = formatDate(new Date(item.createdAt),"yyyy-MM-dd")
            if (item.status) {
              item.status = item.status.name;
            }
            if (item.roles && item.roles.length > 0) {
              item.roleName = item.roles[0].name;
            }
            if (item.department) {
              item.departmentName = item.department.name;
            }
            return item;
          })
          this.setState({ data: datas });
        }

      })
    } catch (e) {
    }
  }

  //查询角色数据
  onQuery1 = () => {
    const { url, name } = this.state.dataUrl;
    fetching(`${url.query}`).then(data => {
      try {
        if (data !== undefined && data !==null) {
          let datas = [];
          if (name === 'r1-4' || name === 'r1-5' || name === 'r1-6') {
            if (data.code === 200) {
              if (name === 'r1-4' || name === 'r1-6') {
                datas = data.data;
              } else if (name === 'r1-5') {
                datas = data.data.map(item => {
                  if (item.active === 1) {
                    item.active = "生效中"
                  }
                  return item;
                });
              }
            }
          } else {
            datas = data.map(item => {
              item.createdAt =formatDate(new Date(item.createdAt),"yyyy-MM-dd")
              if (item.status) {
                item.status = item.status.name;
              }
              if (item.roles && item.roles.length > 0) {
                item.roleName = item.roles[0].name;
              }
              if (item.department) {
                item.departmentName = item.department.name;
              }
              return item;
            })
          }

          this.setState({ data: datas });
        }
      } catch (e) {
      }
    })
  }

  onRef = (ref) => {
    this.child = ref
  }

  FormSubmit2 = (newData) => {
    const { dataUrl } = this.state;
    newData.formDate = new Date();
    if (newData.submit_name === '提交') {
      if (dataUrl.name === 'r12-1') {
        dataUrl.url.query !== '' && this.onQuery();
      } else if (dataUrl.url.query !== '') {
        this.onQuery1();
      }
    }
    this.setState({
      exercise: { ...this.state.exercise, open: false }
    })
  }

  // 对象查找
  findObject = (obj, data) => {
    let index = data.findIndex(item => item.id === obj.id);
    return index;
  }
  //新增开具表单
  handleToggle = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open: false }
      })
    } else {
      this.setState({
        exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
      })
    }
  }

  //表格删除
  onDelete = async oldData => {
    const { dataUrl } = this.state
    const returnedData = await fetching(dataUrl.url.delete + oldData.id, { method: 'GET' })
    returnedData && this.onQuery();
    return returnedData;

  }

  //表格编辑
  onUpdate2 = (e, row) => {
    const { form2, dataUrl, exercise } = this.state
    let status2 = '1'
    fetching(dataUrl.url.get + row.id).then(data => {
      this.setState({ exercise: { ...exercise, ...data, save: '2', DialogTitle: form2.edit, status2, open: true, } });
    })
  }

  //ip表格编辑
  onUpdateIPWhite = (e, row) => {
    const { form2, dataUrl, exercise } = this.state
    //let status2 = '1'
    let url = dataUrl.url.query;
    fetching(url+`?id=${row.id}`).then(data => {
      this.setState({ exercise: { ...exercise, ...data.data, save: '2', DialogTitle: form2.edit, status2: '', open: true, } });
    })
  }

  //限制规则表格编辑
  onUpdateIPWhiteLimit = (e, row) => {
    const { form2, dataUrl, exercise } = this.state
    //let status2 = '1'
    let url = dataUrl.url.query;
    fetching(url+`?id=${row.id}`).then(data => {
        this.setState({ exercise: { ...exercise, ...data.data, save: '2', DialogTitle: form2.edit, status2: '', open: true, } });
      
    })
  }


  //查看开具制单1
  onview = (e, row) => {
    const { form2, dataUrl } = this.state
    fetching(dataUrl.url.get + row.id).then(data => {
      this.setState({ exercise: { ...data, save: '3', status2: '', DialogTitle: form2.view, open: true, } });
    })

  }


  //口令更改-修改密码
  onUpdataPwd = (e, row) => {
    const { form2 } = this.state
    this.setState({ exercise: { id: row.id, account: row.account, DialogTitle: form2.updataPwd, open: true, } });


  }

  //重置密码
  onResetPwd = (e, row) => {
    const { form2 } = this.state
    this.setState({ exercise: { id: row.id, account: row.account, DialogTitle: form2.resetPwd, open: true, } });
  }

  //搜索框Change
  onSearchChange = (query) => {
    console.log('onSearchChange', query);
  }
  //页面更改事件
  onChangePage = (query) => {
    console.log('onChangePage', query);
  }
  //处理每页的行更改（pageSize）
  onChangeRowsPerPage = (query) => {
    console.log('onChangeRowsPerPage', query);
  }

  render() {
    const { columns, data, dataUrl, exercise } = this.state;
    const title = dataUrl.title || '';
    const path = capitalize(this.props.table);
    const actions = actions2(this, this.props.table);
    const editable = editable2(this, this.props.table);
    const { form2 } = this.state

    //系统管理-口令更改
    const C11 = () => {
      let isBtn = false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <DialogForm handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName4, isBtn }}
        >
          <C11Form form2={{ ...common, ...f5_1 }} exercise={exercise}
            onSubmit={this.FormSubmit2} />
        </DialogForm>
      </div>
    }
    //用户信息权限变更
    const C12 = () => {
      let isBtn = false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <DialogForm handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName4, isBtn }}
        >
          <C12Form form2={{ ...common, ...f5_1 }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit2} />
        </DialogForm>
      </div>
    }
    //角色查询
    const C13 = () => {
      let isBtn = false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <DialogForm handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName5, isBtn }}
        >
          <C13Form form2={{ ...common, ...r1_3 }} exercise={{ ...exercise }}
            onSubmit={this.FormSubmit2} />
        </DialogForm>
      </div>
    }

    //ip白名单查询
    const C15 = () => {
      let isBtn = this.props.table === 'r1-5' ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <DialogForm handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName11, isBtn }}>
          { exercise.DialogTitle === form2.add &&
            <C15Form form2={{ ...common, ...r1_5 }} exercise={{ ...exercise }}
              onSubmit={this.FormSubmit2} />
          } 
          {exercise.DialogTitle === form2.edit &&
            <C15Form form2={{ ...common, ...r1_5 }} exercise={{ ...exercise }}
              onSubmit={this.FormSubmit2} />
          }
        </DialogForm>
      </div>
    }

    //ip白名单查询
    const C16 = () => {
      let isBtn = this.props.table === 'r1-6' ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <DialogForm handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName12, isBtn }}>
          { exercise.DialogTitle === form2.add &&
            <C16Form form2={{ ...common, ...r1_5 }} exercise={{ ...exercise }}
              onSubmit={this.FormSubmit2} />
          } 
          {exercise.DialogTitle === form2.edit &&
            <C16Form form2={{ ...common, ...r1_5 }} exercise={{ ...exercise }}
              onSubmit={this.FormSubmit2} />
          }
        </DialogForm>
      </div>
    }


    //用户管理
    const C51 = () => {
      let isBtn = this.props.table === 'r12-1' ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <DialogForm handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName, isBtn }}>
          {(exercise.DialogTitle === form2.edit || exercise.DialogTitle === form2.add) &&
            <C12Form form2={{ ...common, ...f5_1 }} exercise={{ ...exercise }}
              onSubmit={this.FormSubmit2} />
          }
          {exercise.DialogTitle === form2.resetPwd &&
            <C11Form form2={{ ...common, ...f5_1 }} exercise={{ ...exercise }}
              onSubmit={this.FormSubmit2} />
          }
        </DialogForm>
      </div>
    }
    const table = this.props.table || '';
    return <div>
      {(table === 'r1-1') && <C11></C11>}
      {(table === 'r1-2') && <C12></C12>}
      {(table === 'r1-3') && <C13></C13>}
      {(table === 'r1-5') && <C15></C15>}
      {(table === 'r1-6') && <C16></C16>}
      {(table === 'r12-1') && <C51></C51>}

      <MaterialTable
        title={title}
        onSearchChange={this.onSearchChange}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        path={path}
        columns={columns}
        data={data}
        actions={actions}
        editable={editable}
        localization={localization}
        options={options}
      />
    </div>
  }
}
const actions2 = (that, name) => {
  let actions = [];
  switch (name) {
    case 'r1-1':
      actions = [{ icon: 'save', tooltip: '修改密码', onClick: that.onUpdataPwd }];
      break;
    case 'r1-2':
      actions = [{ icon: Edit, tooltip: '编辑', onClick: that.onUpdate2 }];
      break;
    // case 'r1-3': //William Jiang commit: 没有角色编辑的功能
    //   actions = [
    //     { icon: Edit, tooltip: '编辑', onClick: that.onUpdate2 },
    //     { icon: ViewComfy, tooltip: '查看', onClick: that.onview }
    //   ];
    //   break;
    case 'r1-5':
      actions = [{ icon: Edit, tooltip: '编辑', onClick: that.onUpdateIPWhite }];
      break;
    case 'r1-6':
      actions = [{ icon: Edit, tooltip: '编辑', onClick: that.onUpdateIPWhiteLimit }];
      break;
    case 'r12-1':
      actions = [{ icon: Edit, tooltip: '编辑', onClick: that.onUpdate2 }, {
        icon: 'save',
        tooltip: '重置密码',
        onClick: that.onResetPwd
      }];
      break;
    default:
      actions = [];
  }
  return actions;
}
const editable2 = (that, name) => {
  let editable = {};
  switch (name) {
    // case 'm1':
    //   editable = { onRowAdd: that.onAdd, };
    //   break;
    case 'r12-1':
      editable = { onRowDelete: that.onDelete };
      break;
    default:
    // editable = { onRowDelete: that.onDelete };
  }
  return editable;
}
const localization = {
  header: {
    actions: '操作'
  }
}
const options = {
  actionsCellStyle: { padding: '0 10px' },
  headerStyle: { //表格头部
    backgroundColor: '#1d4583',
    color: '#FFF',
    whiteSpace: 'nowrap'
  },
  rowStyle: { //表行
    backgroundColor: '#fff',
    whiteSpace: 'nowrap'
  },
  sorting: true,  //排序
  // selection: true //多选框
}
const setView = Comp => (collection, name) => {
  return class extends Component {
    render() {
      return <Comp table={collection} tableName={name} {...this.props} />
    }
  }
}

export const SetView = setView(View);

