//融单流转表单
export default {
    receivableNo: "应收账款编号*",
    contractNo: "合同编号*",
    coreCompanyName: "核心企业名称*",
    coreCompanyCode: "核心企业组织机构代码*",
    companyName: "供应商名称*",
    companyCode: "供应商组织机构代码*",
    receivableAmount:'应收账款金额*',
    applyAmount: "融资申请金额*",
    sign_agreement: "我同意",
    beginDate: "应收账款起始日*",
    endDate: "应收账款到期日*",
    Upload_contracts: "上传合同*",
    Upload_invoice: "上传发票*",
    Upload_dispatch: "上传发货单",
    Upload_shipping: "上传运输单",
    synthesis_rate: "预计融资息费率*",
    remarks: "备注",

     //静态资源 不用在数据库里面加这些字段
     sign_agreement2: "《融单流转协议》*",
     view_agreement: "查看融单流转协议",
     view_case: "查看案例",
     check_opinion: "审核意见",

}