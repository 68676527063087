import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import {
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import FormikError from '../formik/formikError';
import { enterpriseFormik } from '../formik/formikValidate';
import { fetching } from '../../config/utils';

const useStyles = (theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  label: {
    textAlign: 'left',
  },
  margin: {
    marginRight: '0',
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  },
}));
let siv = '';
class EnterpriseForm extends Component {
  state = {
    form1: this.getInitForm1(),
    isExisted: false,
    cellCode: '',
    btnText: this.props.form_signup3.getCellCode,
    discodeBtn: false,
    timer: 60,
    clearInterval: false,
  };

  componentWillUnmount() {
    clearInterval(siv);
  };

  getInitForm1() {
    const form1 = {
      enterprise_name: '',
      cell: '',
      password: '',
      validate_code: '',
      graph_validate_code: '',
      cell_validate_code: '',
      invite_cell_code: '',
      invite_people: '1',

    };
    return localStorage.getItem("registerForm1") ? JSON.parse(localStorage.getItem("registerForm1")) : form1
  };


  checkInfo = (name, value) => {
    //const queryString = `${name}=${value}`;+
    const formData = {
      [name]: value,

    };
    return fetching('/msapi/account/search', {
      method: 'post',
      body: JSON.stringify(formData)
    },2).then((data) => {
      if (data.existed === false && data) {
        return value
      }
      else {
        if (name === "cell") {
          alert('手机号已存在')
        } else {
          alert('企业名称已存在')
        }
        return null;
      }
    });
  };

  getGraphCode = () => {

  };
//z设置手机验证码获取间隔
  count = () => {
    let { timer } = this.state;
    siv = setInterval(() => {
      this.setState({ timer: (timer--), btnText: timer + 's获取', discodeBtn: true }, () => {
        if (timer === 0) {
          clearInterval(siv);
          this.setState({ btnText: this.props.form_signup3.getCellCode, timer: 60, discodeBtn: false })
        }
      });
    }, 1000);
  };
  //z判断cell框的值
  getCellCode = (value) => {
    if (!value.cell) {
      alert('请输入手机号');
      return;
    }

    //z获取手机验证码url调用接口
    fetch('/api/sms/ZC?phone=' + value.cell, {
      method: 'get',
    })
        .then(res => res.json())
        .then(data => {
          if (data.code === 200) {
            const cellCode = data.data
            this.setState({ cellCode: cellCode, discodeBtn: false })
            this.count();

          } else {
            alert(data.msg)
          }
        });
  };
//z对手机验证码进行判断
  handleCellBlur = (value) => {
    if (value && value === this.state.cellCode) {
      return value;
    } else if (value) {
      alert('验证码错误')
      return '';
    }
  };

  handleChange = (name, checked) => {
    if (checked) {
      return '1'
    } else {
      return 0
    }
  };

  //下一步
  handleNext = (values) => {
    this.checkInfo('enterprise_name', values.enterprise_name)
        .then((data) => {
          if (!data) return null;
          else {
            this.checkInfo('cell', values.cell)
                .then(data1 => {
                  if (!data1) return null;
                  else this.submit(values);
                })
          }
        })
  };

  submit = (values) => {
    //z手机验证码框的状态
    values.cell_validate_code = '';
    values.graph_validate_code = '';
    localStorage.setItem("registerForm1", JSON.stringify(values));
    const formData = {
      open_id: values.cell,
      account_type: 2,
    };
    fetching('/dzqz/register', {
      method: 'post',
      body: JSON.stringify(formData)
    }, 2).then(data => {
      try {
        if (data.code === 200) {
          this.props.handleNext(values);
        } else {
          alert(data.msg)
        }
      } catch (error) {
        alert('error')
      }
    });
  };

  render() {
    const { form_signup3 = {} } = this.props;

    const { form1, cellCode, discodeBtn, btnText } = this.state;
    const classes = this.props.classes;
    return (
        <Formik
            //初始化数据
            initialValues={{
              ...form1
            }}
            // //表单验证
            // validationSchema={object().shape(enterpriseFormik(form_signup3))}
            //表单验证
            validate={(values) => {
              return enterpriseFormik(values, form_signup3);
            }}
            //验证提交
            onSubmit={(values) => {

              this.handleNext(values);
            }}
            render={({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit,
                       setFieldValue,
                       isSubmitting,
                     }) => (
                <form>
                  <Grid container spacing={3}>
                    {/* <Grid container justify="space-between"> */}
                    <Grid item xs={12}>
                      <TextField
                          required
                          id="enterprise_name"
                          name="enterprise_name"
                          value={values.enterprise_name}
                          onChange={handleChange}
                          // onBlur={e => setFieldValue('enterprise_name', this.checkInfo1.bind(this, 'enterprise_name', e.target.value)())}
                          onBlur={handleBlur}
                          label={form_signup3.enterprise_name}
                          fullWidth
                          autoComplete="on"
                      />
                      <FormikError touched={touched} errors={errors} name={'enterprise_name'} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                          required
                          id="standard-helperText"
                          name="cell"
                          value={values.cell}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={form_signup3.cell}
                          fullWidth
                          autoComplete="off"
                          helperText="该手机号可用来登录善美系统"
                      />
                      <FormikError touched={touched} errors={errors} name={'cell'} />
                    </Grid>
                    {/* </Grid> */}

                    <Grid item xs={12}>
                      <TextField
                          required
                          id="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          label={form_signup3.password}
                          fullWidth
                          type="password"
                          autoComplete="new-password"
                      />
                      <FormikError touched={touched} errors={errors} name={'password'} />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                          required
                          id="validate_code"
                          name="validate_code"
                          type="password"
                          value={values.validate_code}
                          onChange={handleChange}
                          label={form_signup3.validate_code}
                          fullWidth
                          autoComplete="off"
                      />
                      <FormikError touched={touched} errors={errors} name={'validate_code'} />
                    </Grid>

                    {/* <Grid item xs={9}>
                <TextField
                  required
                  id="graph_validate_code"
                  name="graph_validate_code"
                  value={values.graph_validate_code}
                  onChange={handleChange}
                  label={form_signup3.graph_validate_code}
                  fullWidth
                  autoComplete="off"
                />
                <FormikError touched={touched} errors={errors} name={'graph_validate_code'}/>
              </Grid>
              <Grid item xs={3}>
                <Button onClick={this.getGraphCode} variant="contained" className={classes.button}>
                  {form_signup3.graph_validate_code}
                </Button>
              </Grid> */}

                    {/* <Grid container justify="space-between"> */}
                    <Grid item xs={9}>
                      <TextField
                          required
                          id="cell_validate_code"
                          name="cell_validate_code"
                          value={values.cell_validate_code}
                          onChange={handleChange}
                          onBlur={e => setFieldValue('cell_validate_code', this.handleCellBlur.bind(this, e.target.value)())}
                          label={form_signup3.cell_validate_code}
                          fullWidth
                          autoComplete="off"
                          disabled={cellCode === ''}
                      />
                      <FormikError touched={touched} errors={errors} name={'cell_validate_code'} />
                    </Grid>

                    <Grid item xs={3}>
                      <Button
                          onClick={this.getCellCode.bind(this, values)}
                          variant="contained"
                          disabled={discodeBtn}
                          className={classes.button}>
                        {btnText}
                      </Button>
                    </Grid>
                    {/* </Grid> */}
                    <Grid item xs={9}>
                      <TextField

                          id="invite_cell_code"
                          name="invite_cell_code"
                          value={values.invite_cell_code}
                          onChange={handleChange}
                          label={form_signup3.invite_cell_code}
                          fullWidth
                          autoComplete="off"
                      />
                      <FormikError touched={touched} errors={errors} name={'invite_cell_code'} />
                    </Grid>

                    <Grid item xs={3} className={classes.margin}>
                      <FormControlLabel
                          style={{ marginRight: 0 }}
                          justify-content="flex-start"
                          control={
                            <Checkbox
                                checked={values.invite_people === '1'} name='invite_people'
                                onChange={e => setFieldValue('invite_people', this.handleChange.bind(this, 'invite_people', e.target.checked)())}
                            />
                          }
                          label={form_signup3.invite_people}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ margin: '20px 0' }}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                      下一步
                    </Button>
                  </div>
                </form>
            )}
        />
    );
  }
}


export default withStyles(useStyles)(EnterpriseForm);