import { delcommafy } from '../../components/numToText';
const { string } = require('yup');
// const numbers = /^\+?[1-9]\d*$/; //大于0的正整数
// const creditNo = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;; //社会信用代码
const numbers1 = /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/ //大于0的数字包含小数


export const C111Formik = (values, form2) => {
  let errors = {};
  if (!values.select_customer) {
    errors.select_customer = form2.select_customer;
  }
  if (!values.lglNm) {
    errors.lglNm = form2.lglNm;
  }
  if (!values.lgCardFileFront) {
    errors.lgCardFileFront = form2.lgCardFileFront;
  }
  if (!values.lgCardFileBack) {
    errors.lgCardFileBack = form2.lgCardFileBack;
  }
  if (!values.business_license_file) {
    errors.business_license_file = form2.business_license_file;
  }
  return errors;
}
export const C112Formik = (values, form2) => {
  let errors = {};
  if (!values.select_customer) {
    errors.select_customer = form2.select_customer;
  }
  if (!values.business_scope) {
    errors.business_scope = form2.business_scope;
  }
  if (!values.registered_assets) {
    errors.registered_assets = form2.registered_assets;
  } else if (!numbers1.test(delcommafy(values.registered_assets))) {
    errors.registered_assets = '请输入10位以内并大于0的数字';
  }
  return errors;
}

export const C116Formik = (values, form2) => {
  let errors = {};
  if (!values.select_customer) {
    errors.select_customer = form2.select_customer;
  }
  if (!values.lglNm) {
    errors.lglNm = form2.lglNm;
  }
  if (!values.lglIdCard) {
    errors.lglIdCard = form2.lglIdCard;
  }
  return errors;
}

export const C12Formik = (form2 = {}) => {
  return {
    select_customer:
      string()
        .trim().required(form2.select_customer),
    management_name:
      string()
        .trim().required(form2.management_name),
    position:
      string()
        .trim().required(form2.position),
    associated_info:
      string()
        .trim().required(form2.associated_info),
    legal_other_duty:
      string()
        .trim().required(form2.legal_other_duty),
    judicial_info:
      string()
        .trim().required(form2.judicial_info),
  }

}

export const C13Formik = (form2 = {}) => {
  return {
    select_customer:
      string()
        .trim().required(form2.select_customer),
    actual_controller:
      string()
        .trim().required(form2.actual_controller),
    associated_info:
      string()
        .trim().required(form2.associated_info),
    legal_other_duty:
      string()
        .trim().required(form2.legal_other_duty),
    judicial_info:
      string()
        .trim().required(form2.judicial_info),
    credit_attention_bad:
      string()
        .trim().required(form2.credit_attention_bad),
  }

}
export const C14Formik = (values, form2) => {
  let errors = {};
  if (!values.select_customer) {
    errors.select_customer = form2.select_customer;
  }
  if (!values.actual_controller) {
    errors.actual_controller = form2.actual_controller;
  }

  if (!values.associated_info) {
    errors.associated_info = form2.associated_info;
  }
  if (!values.legal_other_duty) {
    errors.legal_other_duty = form2.legal_other_duty;
  }
  if (!values.judicial_info) {
    errors.judicial_info = form2.judicial_info;
  }
  if (!values.net_assets) {
    errors.net_assets = form2.net_assets;
  } else if (!numbers1.test(delcommafy(values.net_assets))) {
    errors.net_assets = '请输入大于0的整数';
  }
  if (!values.paid_in_capital) {
    errors.paid_in_capital = form2.paid_in_capital;
  } else if (!numbers1.test(delcommafy(values.paid_in_capital))) {
    errors.paid_in_capital = '请输入大于0的整数';
  }
  if (!values.risk_warning_info) {
    errors.risk_warning_info = form2.risk_warning_info;
  }
  if (!values.overdue_info) {
    errors.overdue_info = form2.overdue_info;
  }
  if (!values.credit_attention_bad) {
    errors.credit_attention_bad = form2.credit_attention_bad;
  }
  return errors;
}




