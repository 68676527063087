import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid
} from '@material-ui/core';
import {
  AccountCircle,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Login from '../../signin/SigninSide';
import { form_bar2, common } from './formStatic'
import { Formik } from 'formik';
import FormikError from '../formik/formikError';
import { pwdFormik } from '../formik/formikValidate'
import { DEFAULT_LOGIN_PAGE } from '../../config/constants'
import { fetching } from '../../config/utils';
import { loginInfos} from '../numToText';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    width: 300
  },
  field: {
    width: 260,
  },
  fields: {
    marginLeft: 20,
  },
  right: {
    textAlign: 'right'
  },
  top: {
    marginTop: 16,
  }
}));

function Bar2(props) {

  const { children, auth } = props;

  let account, id;

  // can't read property 'account' of null.
  try {
    const obj = isEmpty(auth.loginInfo) ? loginInfos() : auth.loginInfo;
    account = obj.account;
    id = obj.id;
  } catch (e) {
    account = '';
    id = '';
  }

  const form = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  };

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [logout, setLogout] = useState(false);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpen1 = () => {
    setOpen1(true);

  };
  //取消
  const handleClose1 = () => {
    setOpen1(false);

  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen2 = () => (setOpen2(true));

  const handleClose2 = () => (setOpen2(false));

  const handleOk = () => {
    // TODO: logout flow.
    handleClose();
    setLogout(true);
    sessionStorage.removeItem("authToken")
  };


  //修改密码提交
  const handleSubmit = (value) => {
    let { confirm_password, ...params } = value
    setOpen1(false);
    fetching(`/msapi/user/update/${id}/password`, {
      method: 'post',
      // token: this.state.hasToken,
      body: JSON.stringify(params)
    }).then((data) => {
     try {
        if(data){
          if(data.msg){
            alert(data.msg)
            return;
          }
          //this.handleOk();
          //return <Redirect to={DEFAULT_LOGIN_PAGE} />
        }else {
          alert('提交失败')
        }
      } catch (error) {console.log(error)}
    })
  }

  if (logout) {
    return <Redirect to={DEFAULT_LOGIN_PAGE} />
  }
  const menuId = 'primary-search-account-menu';

  const updataPwd = (
    <Formik
      initialValues={{
        ...form
      }}
      validate={(values) => {
        return pwdFormik(values, form_bar2);
      }}
      onSubmit={(values, ) => {
        handleSubmit(values);
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (

          <Dialog open={open1} onClose={handleClose1}>
            <DialogTitle id="form-dialog-title">{common.updata_password}</DialogTitle>
            <form className={classes.form}>
              <Grid item xs={12} className={classes.fields}>
                <TextField
                  autoFocus
                  id="account"
                  label={common.account}
                  fullWidth
                  margin="dense"
                  value={account}
                  className={classes.field}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} className={classes.fields}>
                <TextField
                  id="old_password"
                  label={form_bar2.old_password}
                  margin="normal"
                  type="password"
                  className={classes.field}
                  value={values.old_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormikError touched={touched} errors={errors} name={'old_password'} />
              </Grid>
              <Grid item xs={12} className={classes.fields}>
                <TextField
                  id="new_password"
                  //label={form_bar2.new_password}
                  label={"请输入新口令(最少8位,且必须包含字母、数字和特殊字符)"}
                  margin="normal"
                  type="password"
                  className={classes.field}
                  value={values.new_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <br />
                <FormikError touched={touched} errors={errors} name={'new_password'} />
              </Grid>
              <Grid item xs={12} className={classes.fields}>
                <TextField
                  id="confirm_password"
                   //label={form_bar2.confirm_password}
                   label={"请再次输入新口令(最少8位,且必须包含字母、数字和特殊字符)"}
                  margin="normal"
                  type="password"
                  className={classes.field}
                  value={values.confirm_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <br />
                <FormikError touched={touched} errors={errors} name={'confirm_password'} />
              </Grid>
            </form>
            <DialogActions>
              <Button onClick={handleClose1} color="primary">
                {common.cancle}
              </Button>
              <Button onClick={handleSubmit} color="primary">
                {common.submit}
              </Button>
            </DialogActions>
          </Dialog>

        )}
    />
  )

  const renderMenu = (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {account && (<MenuItem>{common.user_account}{account}</MenuItem>)}
        <MenuItem onClick={handleOpen2} style={{color:'#3f51b5'}}>{common.change_user}</MenuItem>
        <MenuItem onClick={handleOpen1} style={{color:'#3f51b5'}}>{common.updata_password}</MenuItem>
        <MenuItem onClick={handleOpen} style={{color:'#3f51b5'}}>{common.logout}</MenuItem>
      </Menu>
      <Dialog open={open2} onClose={handleClose2}>
        <Login handleClose2={handleClose2} />
      </Dialog>
      {open1 === true && updataPwd}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">{common.confirm_logout_title}</h2>
            <p id="transition-modal-description">{common.confirm_logout_content}</p>
            <Button onClick={handleClose} color="primary">
              {common.no}
            </Button>
            <Button onClick={handleOk} color="primary">
              {common.yes}
            </Button>
          </div>
        </Fade>
      </Modal>
    </>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar style={{backgroundColor: '#1d4583'}}>
          {children}
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Bar2)
