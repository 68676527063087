import React, { Component } from 'react';
import {
  TextField,
  Button,
  Grid,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  Select as Select2,
  InputLabel,
  MenuItem,
  InputAdornment,
  Input,
  CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { form2Formik } from './formikValidate';
import FormikError from '../../../components/commonForm/formikError';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import cnLocale from "date-fns/locale/zh-CN";
import { formatDate } from '../../../components/date'
import { fetching } from '../../../config/utils';
const styles = {
  width: '100%',
  margin: '16px 0 8px 0'
}
const useStyles = (theme => ({
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  flex: {
    display: 'flex',
    flexDirection: 'Rowing',
  },
  button: {
    margin: theme.spacing(1),
  },
  width: '100%',
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  textField: {
    width: '100%',
    marginBottom: '8px',
    marginTop: '16px',
  },
}));
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
//业务品种
const businessVarietiesList = ['保理', '其他'];
//业务状态
const businessStatusList = ['正常', '其他'];
const currenciesList = ["人民币", '美元', '欧元', '日元']
const fixedRateList = ["是", "否"]

class Form2 extends Component {
  state = {
    form: this.getInitState(),
  }

  getInitState() {
    const { form = {} } = this.props;
    return { ...form }
  }
  //下一步
  handleNext = (values) => {
    if(formatDate(new Date(values.credit_start_date),"yyyy-MM-dd") > formatDate(new Date(values.credit_due_date),"yyyy-MM-dd")){
      alert("额度到期日期不能小于额度起始日期");
      return;
    }
    if((formatDate(new Date(values.credit_start_date),"yyyy-MM-dd") < formatDate((new Date()), "yyyy-MM-dd")) && values.DialogTitle !== "查看"){
      alert("额度开始日期不能小于当前日期");
      return;
    }
    const { exercise = {} } = this.props;
    ['credit_start_date', 'credit_due_date'].forEach(item => {
      if (values[item] instanceof Object && values[item].getTime()) {
        values[item] = formatDate(values[item], 'yyyy-MM-dd');
      }
    });
    if (  values.save === 'undefined' || values.save !== "3") {
      let { enterprise_name, credit_start_date, credit_due_date ,limit_mark ,createdAt} = values
      fetching(`/msapi/credit1/query/${enterprise_name}/${limit_mark}/${credit_start_date}/${credit_due_date}/${createdAt}/compare_dates`, {
        method: 'GET',
      }).then(res => {
        if (res) {
          try {
            if (res.code === 1) {
              this.props.handleNext(values);
            } else {
              alert(res.msg)
            }
          } catch (error) { }
        }
        // if (exercise.save !== '1') {
        //   this.props.handleNext(values);
        //   return;
        // }
      })
    } else {
      if (exercise.save !== '1') {
        this.props.handleNext(values);
        return;
      }
    }

  };
  //上一级
  handleBack = () => {
    this.props.handleBack();
  };
  //保存
  handleSave = (values) => {
    this.props.handleLoading(true)
    this.props.handleSave(values);
  };
  handleChange3 = (name, value) => {
    const { exercise = {} } = this.props;
    if (name === 'credit_start_date' || name === 'credit_due_date') {
      if (!(value instanceof Object && value.getTime())) {//判断日期是否为有效值
        return;
      }
      if (formatDate(value, 'yyyy-MM-dd') <  formatDate((new Date()), 'yyyy-MM-dd')) {
        alert("额度开始日期不能小于当前日期");
        return;
      }
      fetching(`/msapi/credit1/find?status_id=9&&enterprise_no=${exercise.enterprise_no}`, {
        method: 'GET',
      }).then(res => {
        if (res !== undefined) {
          try {
            if (res.length != 0) {
               if (formatDate(value, 'yyyy-MM-dd') < res[res.length - 1].credit_start_date) {
                 alert("额度到期日期不能小于之前额度开始日期");
                return;
               }
            } 
          } catch (error) { }
        }
      }) 
      return value
    }
  }

  render() {
    const { activeStep, exercise = {}, formStatic, classes } = this.props;
    const { form } = this.state;
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        // validationSchema={object().shape(form2Formik(formStatic))}
        validate={(values) => {
          return form2Formik(values, formStatic);
        }}
        //验证提交
        onSubmit={(values) => {
          this.handleNext(values);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <form>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.apply_credit_no}
                    value={values.apply_credit_no}
                    name='apply_credit_no'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'apply_credit_no'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.enterprise_no}
                    value={values.enterprise_no}
                    name='enterprise_no'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'enterprise_no'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.enterprise_name}
                    value={values.enterprise_name}
                    name='enterprise_name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'enterprise_name'} />
                </Grid>
                <Grid item xs={5}>
                  <FormControl className={classes.textField}>
                    <InputLabel htmlFor="mx_amount">{formStatic.mx_amount}</InputLabel>
                    <Input
                      name="mx_amount"
                      value={values.mx_amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      endAdornment={<InputAdornment position="end">元</InputAdornment>}
                      disabled={true}
                      inputProps={{
                        maxLength: 50,
                      }}
                    />
                  </FormControl>
                  <FormikError touched={touched} errors={errors} name={'mx_amount'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy/MM/dd"
                      margin="normal"
                      disabled={exercise.save === '3'}
                      id="date-picker-inline"
                      label={formStatic.credit_start_date}
                      value={values.credit_start_date}
                      onChange={e => setFieldValue('credit_start_date', this.handleChange3.bind(this, 'credit_start_date', e)())}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={5}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy/MM/dd"
                      margin="normal"
                      disabled={exercise.save === '3'}
                      id="date-picker-inline"
                      label={formStatic.credit_due_date}
                      value={values.credit_due_date}
                      onChange={e => setFieldValue('credit_due_date', this.handleChange3.bind(this, 'credit_due_date', e)())}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <FormControl style={styles} className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">{formStatic.business_varieties}</InputLabel>
                    <Select2
                      id="demo-simple-select"
                      value={values.business_varieties}
                      disabled={exercise.save === '3'}
                      onChange={e => setFieldValue('business_varieties', e.target.value)}>
                      >
                      {businessVarietiesList.map(item => {
                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                      })}
                    </Select2>
                  </FormControl>
                  <FormikError touched={touched} errors={errors} name={'business_varieties'} />
                </Grid>
                <Grid item xs={5}>
                  <FormControl style={styles} className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">{formStatic.business_status}</InputLabel>
                    <Select2
                      id="demo-simple-select"
                      value={values.business_status}
                      disabled={exercise.save === '3'}
                      onChange={e => setFieldValue('business_status', e.target.value)}>
                      >
                      {businessStatusList.map(item => {
                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                      })}
                    </Select2>
                  </FormControl>
                  <FormikError touched={touched} errors={errors} name={'business_status'} />
                </Grid>

              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <FormControl style={styles} className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">{formStatic.trading_currencies}</InputLabel>
                    <Select2
                      id="demo-simple-select"
                      value={values.trading_currencies}
                      disabled={exercise.save === '3'}
                      onChange={e => setFieldValue('trading_currencies', e.target.value)}>
                      >
                      {currenciesList.map(item => {
                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                      })}
                    </Select2>
                  </FormControl>
                  <FormikError touched={touched} errors={errors} name={'trading_currencies'} />
                </Grid>
                <Grid item xs={5}>
                  <FormControl className={classes.textField}>
                    <InputLabel htmlFor="cash_deposit_ratio">{formStatic.cash_deposit_ratio}</InputLabel>
                    <Input
                      name="cash_deposit_ratio"
                      value={values.cash_deposit_ratio}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      disabled={exercise.save === '3' || values.guaranty_way.split(",").includes('信用')}
                      type="number"
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </FormControl>
                  <FormikError touched={touched} errors={errors} name={'cash_deposit_ratio'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5} className={classes.flex} >
                  <FormControl component="fieldset" style={styles}>
                    <FormLabel component="legend" style={{ fontSize: '12px' }}>{formStatic.is_allow_equivalent_deposit}</FormLabel>
                    <RadioGroup style={{ flexDirection: 'row' }} aria-label="gender" name='is_allow_equivalent_deposit' value={values.is_allow_equivalent_deposit}
                      onChange={e => setFieldValue('is_allow_equivalent_deposit', e.target.value)}>
                      {fixedRateList.map(item => {
                        return (
                          <FormControlLabel
                            key={item}
                            name={item}
                            value={item}
                            disabled={exercise.save === '3'}
                            control={<Radio />}
                            label={item} />
                        )
                      })}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {values.is_allow_equivalent_deposit === '是' && <Grid item xs={5}  >
                  <TextField
                    label={formStatic.equivalent_info}
                    value={values.equivalent_info}
                    name='equivalent_info'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'equivalent_info'} />
                </Grid>
                }
              </Grid>

              <Grid container justify="space-between">

                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows='4'
                    label={formStatic.remark}
                    value={values.remark || ''}
                    name='remark'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={exercise.save === '3'}
                  />
                  <FormikError touched={touched} errors={errors} name={'remark'} />
                </Grid>
              </Grid>
              <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                >
                  上一步
                </Button>
                <Button style={{ margin: '0 10px' }} variant="contained" color="primary" onClick={handleSubmit}>
                  下一步
                            </Button>
                {exercise.save !== '3' &&
                  <div style={{ position: 'relative' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.props.loading}
                      onClick={this.handleSave.bind(this, values)}
                    >
                      保存</Button>
                    {this.props.loading && <CircularProgress size={24} style={loadingStyle} />}
                  </div>
                }
              </div>
            </form>
          )}
      />
    );
  }
}


export default withStyles(useStyles)(Form2);