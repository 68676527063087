import React from 'react';
import { Link } from 'react-router-dom'
import { Button, Menu, MenuItem, Fade } from '@material-ui/core';
import { BusinessRouters } from '../routers'
import { Menu as MenuIcon } from '@material-ui/icons'
import { ListItem } from '@material-ui/core';
import { loginInfos } from './numToText';

export default function () {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [BusinessRouters1, setBusinessRouters1] = React.useState(BusinessRouters);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  let loginInfo = loginInfos();
  try {
    let BusinessRouters11 = BusinessRouters;
    if (loginInfo.organization.id === 1) {
      if (loginInfo.department.id === 1 || loginInfo.department.id === 2 || loginInfo.department.id === 4) {
        BusinessRouters11 = BusinessRouters.filter((item) => {
          return item.title !== '核心企业及供应商' ? item : null
        })
      } else {
        BusinessRouters11 = BusinessRouters.filter((item) => {
          return item.title === '风险管理' ? item : null
        })
      }

    } else {

      BusinessRouters11 = BusinessRouters.filter((item) => {
        return item.title === '核心企业及供应商' ? item : null
      })
    }

    setBusinessRouters1(BusinessRouters11)
  } catch (error) { }

  return (
    <>
      {loginInfo.organization.id === 1 &&
        <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick} color="inherit">
          <MenuIcon />
        </Button>
      }
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {BusinessRouters1.map(router => {
          const CompIcon = router.icon;
          return (
            <ListItem
              button
              component={Link}
              to={router.path}
              key={router.path}
            >
              <MenuItem onClick={handleClose}>
                <CompIcon fontSize="small" />
                {router.title}
              </MenuItem>
            </ListItem>
          )
        })}
      </Menu>
    </>
  );
}
