import Layout from '../components/Layout'
import { Menu1, Content1 } from './C1'
import { Menu2, Content2 } from './C2'
import { Menu3, Content3 } from './C3'
import { Menu4, Content4 } from './C4'
import { Menu5, Content5 } from './C5'
import { Menu6, Content6 } from './C6'
import { Menu7, Content7 } from './C7'
import { Menu8, Content8 } from './C8'
import { AssignmentReturn, TouchApp, MonetizationOn, Payment, GroupAdd, Assignment,LocalAtm } from '@material-ui/icons'
import subRouters from './common';

const C1 = Layout(Menu1, Content1);
const C2 = Layout(Menu2, Content2);
const C3 = Layout(Menu3, Content3);
const C4 = Layout(Menu4, Content4);
const C5 = Layout(Menu5, Content5);
const C6 = Layout(Menu6, Content6);
const C7 = Layout(Menu7, Content7);
const C8 = Layout(Menu8, Content8);

const Routers = [
  {
    path: subRouters['额度管理'],
    title: '额度管理',
    icon: MonetizationOn,
    component: C1,
  },
  {
    path: subRouters['融单管理'],
    title: '融单管理',
    icon: TouchApp,
    component: C2,
  },
  {
    path: subRouters['融单流转'],
    title: '融单流转',
    icon: Payment,
    component: C3
  },
  {
    path: subRouters['应收账款'],
    title: '应收账款',
    icon: Payment,
    component: C7
  },
  {
    path: subRouters['还款管理'],
    title: '还款管理',
    icon: AssignmentReturn,
    component: C4,
  },
  {
    path: subRouters['合同管理'],
    title: '合同管理',
    icon: Assignment,
    component: C6,
  },
  {
    path: subRouters['用户管理'],
    title: '用户管理',
    icon: GroupAdd,
    component: C5,
  },
  {
    path: subRouters['外部融资'],
    title: '外部融资',
    icon: LocalAtm,
    component: C8,
  },
];




export default Routers;
