import React, { Component } from 'react';
import {
  Container,
  CssBaseline,
  Typography,
  // Link,
} from '@material-ui/core';
import { Home as AppHome, BarChart as BarChartIcon, } from '@material-ui/icons';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Routers1 from './sub-routers'
import { BASE } from './common'
import { bars } from '../components'
import { NavList, RouteList, reduxLink } from '../config/reusable'
import { getMenu1Action, getMenu2Action } from '../state/actions'
// import { fetching } from '../config/utils'
import { Redirect } from 'react-router-dom'
import { DEFAULT_LOGIN_PAGE } from '../config/constants'
import { loginInfos } from '../components/numToText'

class CoreBusinessSupplier extends Component {

  constructor(props) {
    super(props);
    props.getMenu1Action();
    this.state = {
      Routers: Routers1,
      logout: false,
      loginInfo: {},
    };
    this.title = '';
  }

  async componentWillMount() {
    const loginInfo = loginInfos()
    if (loginInfo.id) {
      const Routers = [...Routers1];
      if (!loginInfo.user.isAdmin) {
        const index2 = Routers.findIndex(item => item.title === '用户管理');
        Routers.splice(index2, 1)
      }
      try {
        this.title = loginInfo.organization.name || '核心企业及供应商';
      } catch (error) {
      }
      this.setState({
        Routers,
        loginInfo
      })
    } else {
      this.setState({ logout: true })
    }
  }
  render() {
    const { Routers } = this.state
    if (this.state.logout) {
      return <Redirect to={DEFAULT_LOGIN_PAGE} />
    }
    return (
      <>
        <CssBaseline />
        <Container fixed style={{ maxWidth: '100%' }}>
          <bars.Bar2>
            <Typography>
              {/*<Link href={BASE} color="inherit" variant="h6"><Business/>{this.title}</Link>*/}
              {reduxLink({ icon: BarChartIcon, path: BASE, title: this.title })}
            </Typography>
            <Typography>
              {reduxLink({ icon: AppHome, path: '/home', title: '' })}
            </Typography>
            {/* <BarMenu /> */}
            <NavList navs={Routers} />
          </bars.Bar2>
          <div style={{ width: '100%' }}>
            <RouteList
              routes={Routers}
              redirect={{ from: BASE, to: `${BASE}/额度管理/我的额度` }}
            />
          </div>
        </Container>
      </>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  menu1: state.menu1,
  menu2: state.menu2,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  { getMenu1Action, getMenu2Action }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoreBusinessSupplier);
