import React, { Component } from 'react'
import moment from 'moment'
import { Formik } from 'formik';
import {
  TextField,
  Button,
  Grid,
  Typography
} from '@material-ui/core'

import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Upload } from '../../components/upload';
import Select from 'react-select';
import { numberfun, numberParseChina, commafy, delcommafy } from '../../components/numToText'
import { formatDate } from '../../components/date'
import FormikError from './formikError';
import { circulationFormik } from './formikValidate';
import SimpleExpansionPanel from './Panels';
import { fetching } from '../../config/utils';
import { DEFAULT_CONTRCT_URL } from '../../config/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { DEFAULT_UPLOADS_URL } from '../../config/constants'

const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
const { object } = require('yup');
class Form extends Component {
  state = {
    form: this.getInitState(),
    form2: this.props.form2 || {},
    open2: false,
    opinion_list: [],
    uploads: [],
    loading: false,
    loading2: false,
    loadingDsb: false,
    mxNumberList: [] //可用融单编号
  }

  getInitState() {
    const { exercise, auth = {} } = this.props
    let financingNo = exercise.data.financingNo;
    let financingAmount = exercise.data.financingAmount;
    let receivableNo = exercise.data.receivableNo;
    let contractNo = exercise.data.contractNo;
    let rate = exercise.data.rate;
    let overdueRate = exercise.data.overdueRate;
    let financingRatio = exercise.data.financingRatio;
    let loanAccountBank = exercise.data.loanAccountBank;
    let loanAccountBankName = exercise.data.loanAccountBankName;
    let loanAccountNo = exercise.data.loanAccountNo;
    let acctAccountBank = exercise.data.acctAccountBank;
    let acctAccountBankName = exercise.data.acctAccountBankName;
    let acctAccountNo = exercise.data.acctAccountNo;
    const form = {
      financingNo,
      financingAmount,
      receivableNo,
      contractNo,
      rate,
      overdueRate,
      financingRatio,
      loanAccountBank,
      loanAccountBankName,
      loanAccountNo,
      acctAccountBank,
      acctAccountBankName,
      acctAccountNo,
      Upload_contracts: "", //上传合同
      Upload_invoice: "", //上传发票
      Upload_contracts_invoice: "", //上传合同编号
      Upload_rest: "",//其他
      sign_agreement: '1', //签订协议
      DialogTitle: "",
      status2: '1', //判断是开具还是复核
      save: "1", //判断是新增还是编辑
      imgUrl: '',
      table: exercise.table || ''
    }
    const form2 = (exercise.save !== '1' && exercise.save !== '4') ? {
      ...form, ...exercise, 
    } : form;




    return form2;
  }

  componentDidMount() {
    const { exercise, auth } = this.props;
    let financingNo = exercise.data.financingNo;
    fetching(`/sl/slFindFinancingFile?financingNo=${financingNo}`).then(res => {
      if (res) {
        let uploads = []
        uploads = res.data
        this.setState({ uploads })
      }
    })
  }

  //查看协议
  viewAgreement = () => {
    const { fdd_file_path = '' } = this.state.form
    if (fdd_file_path && fdd_file_path !== 'null') {
      window.open(fdd_file_path)
    } else
      window.open(DEFAULT_CONTRCT_URL + 'contract/template/lz.pdf')
  }

  //查看实例
  viewFile = (type) => {
    const { uploads = [] } = this.state;
    for (let i = 0; i < uploads.length; i++){
      let imgUrl2 = uploads[i].fpath ? DEFAULT_UPLOADS_URL + uploads[i].fpath : '';
      let typeIndex = imgUrl2.lastIndexOf('.');
      if (typeIndex !== -1) {
        if (uploads[i].type === type){
          console.log(imgUrl2)
          window.open(imgUrl2, '_blank' )
        }
      } 
    }
  }

  //下载放款审批表
  loanApproval = () =>{
    const mxf_number=this.state.form.receivableNo
    fetching(`/msapi/fflow/loanApprovalFilDownload?mxf_number=${mxf_number}`,{
      method: 'GET',
    }).then(res => {
      if (res.code === 200) {
        let imgUrl2 = res.data.filePath ? DEFAULT_UPLOADS_URL + res.data.filePath : '';
        window.open(imgUrl2)
      } else {
        alert(`下载失败,${res.msg}`);
      }
    })
  }

  render() {
    const { amount_capital } = this.state.form;
    const { mxNumberList, opinion_list } = this.state;
    const { form2, form, uploads, loading, loadingDsb } = this.state;
    const { exercise, auth = {}} = this.props;
    const roleId = auth.roles ? auth.roles[0].id : '';
    // const disableds = table === 'm3-1-8' ? loading : true

    const status = (handleSubmit, values) => {
      if (exercise.status2 === '1') {
        return <Grid container justify="center" style={{ width: '100%', padding: '10px 0' }}>

          <div style={{ position: 'relative' }}>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSubmit}
              disabled={loadingDsb}
              name={form2.submit}
              style={{ marginRight: '10px' }}
            >
              {form2.submit}
            </Button>
            {loading && <CircularProgress size={24} style={loadingStyle} />}
          </div>

          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.revocation)}
            name={form2.revocation}
            disabled={loadingDsb}
            style={{ marginRight: '10px' }}
          >
            {form2.revocation}
          </Button>
        </Grid>
      }
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        //表单验证
        validationSchema={object().shape(circulationFormik(form2))}
        onSubmit={(
          values
        ) => {
          this.handleSubmit(values, '提交');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          // isSubmitting,
        }) => (
            <form>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.financingNo}
                    value={form.financingNo}
                    name='mx_payer'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'financingNo'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className="TextFieldUnits"
                    label={form2.financingAmount}
                    value={form.financingAmount}
                    name='mx_amount'
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'financingAmount'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.receivableNo}
                    value={form.receivableNo}
                    name='mx_payer'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'receivableNo'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.contractNo}
                    value={form.contractNo}
                    name='payer_credit_code'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'contractNo'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                  className="TextFieldUnits2"
                    label={form2.rate}
                    value={form.rate}
                    name='rate'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'rate'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                  className="TextFieldUnits2"
                    label={form2.financingRatio}
                    value={form.financingRatio}
                    name='financingRatio'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'financingRatio'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.loanAccountBank}
                    value={form.loanAccountBank}
                    name='loanAccountBank'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'loanAccountBank'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.loanAccountBankName}
                    value={form.loanAccountBankName}
                    name='loanAccountBankName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'loanAccountBankName'} />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.loanAccountNo}
                    value={form.loanAccountNo}
                    name='loanAccountNo'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'loanAccountNo'} />
                </Grid>
              </Grid>   
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.acctAccountBank}
                    value={form.acctAccountBank}
                    name='acctAccountBank'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'acctAccountBank'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={form2.acctAccountBankName}
                    value={form.acctAccountBankName}
                    name='acctAccountBankName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'acctAccountBankName'} />
                </Grid>
              </Grid> 
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={form2.acctAccountNo}
                    value={form.acctAccountNo}
                    name='acctAccountNo'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                    disabled={true}
                  />
                  <FormikError touched={touched} errors={errors} name={'acctAccountNo'} />
                </Grid>
              </Grid>         
              <SimpleExpansionPanel title="附件*">
                <Grid container justify="space-between">
                  <Grid item xs={12}>
                  <Button
                   onClick={() => this.viewFile("1")}
                   >
                   下载保理业务申请书
                   </Button>
                   <Button
                   onClick={() => this.viewFile("2")}
                   >
                   下载保理合同
                   </Button>
                   <Button
                   onClick={() => this.viewFile("3")}
                   >
                   下载保理业务信息表
                   </Button>
                   <Button
                   onClick={() => this.viewFile("4")}
                   >
                   下载应收账款转让确认书
                   </Button>
                   <Button
                   onClick={() => this.viewFile("5")}
                   >
                   下载应收账款转让通知书
                   </Button>
                   <Button
                   onClick={() => this.viewFile("6")}
                   >
                   下载质押合同
                </Button>
                { roleId !== 11 && roleId !== 12 && 
                <Button
                  onClick={this.loanApproval}
                  disabled={false}
                >
                  {'下载放款审批表'}
                </Button>
                }
                  </Grid>
                </Grid>
              </SimpleExpansionPanel>
            </form>
          )}
      />
    )
  }
}

export default Form
