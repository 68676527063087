import React, { Component } from 'react';
// import {Switch, Route, Link} from 'react-router-dom';
// import {
//   // ViewComfy,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
// } from '@material-ui/core';
import MaterialTable from '../vendors/material-table';
import { defer, capitalize, fetching } from '../config/utils';
import { core_business_url } from './core_business_url';
// import Accordion from '../components/Accordion'

// import {subRootAry as C1_1subRootAry } from './containers/C1_1'
// import {subRootAry as C4_1subRootAry } from './containers/C4_1'
// const subRootAry =[
//   ...C1_1subRootAry,
//   ...C4_1subRootAry,
// ]
export const BASE = '/customer-info';

export const subRouters = [
  '客户基本信息',
  '财务信息',
  '评级限额信息',
  '风险信息',
  '关联信息',
  '现有数据',
  '帮助'
].reduce((obj, url) => {
  obj[url] = `${BASE}/${url}`;
  return obj;
}, {});



// export const getMenu = (parent_path, info) => () => {
//   const list = info.map(item => {
//     const CompIcon = item.icon;
//     return (
//       <ListItem button component={Link} to={`${parent_path}/${item.path}`} key={item.path}>
//         <ListItemIcon>
//           <CompIcon />
//         </ListItemIcon>
//         <ListItemText primary={item.path}/>
//       </ListItem>
//     )
//   })
//   return (
//     <div>
//       {list}
//       <ListItem><Accordion /></ListItem>
//     </div>
//   );
// };

// export const getContent = (parent_path, info,subRootAry) => () => {
//   return (
//     <Switch>
//       {info.map(item => {
//         if (item.component) {
//           return (
//             <Route
//               path={`${parent_path}/${item.path}`}
//               component={item.component}
//               key={item.path}
//             />
//           )
//         }
//         else {
//           return (
//             <Route
//               path={`${parent_path}/${item.path}`}
//               render={() => <h2>{item.path}</h2>}
//               key={item.path}
//             />
//           )
//         }
//       })}
//          {subRootAry.map(item => {
//         if (item.component) {
//           return (
//             <Route
//               path={`${parent_path}/${item.path}`}
//               component={item.component}
//               key={item.path}
//             />
//           )
//         }
//         else {
//           return (
//             <Route
//               path={`${parent_path}/${item}`}
//               render={() => <h2>{item}</h2>}
//               key={item}
//             />
//           )
//         }
//       })}
//     </Switch>

//   )
// };


// copied from 05-.../DataView.js
class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [{}],
      data: [],
      dataUrl: {},
      hasToken: sessionStorage.getItem("authToken")
    };
  }

  componentDidMount() {
    fetching('/table/' + this.props.table, { token: this.state.hasToken })
      .then(data => {
        const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
        //根据不同的页面，获取不同的接口地址，渲染不同的数据
        const dataUrl = core_business_url.find(item => item.name === this.props.table);
        const dataUrl2 = dataUrl || {};
        // if( dataUrl2.url){
        //   dataUrl.url.query !=='' && this.onQuery();
        // }
        this.setState({ columns, dataUrl: dataUrl2 });
      })
  }

  //查询数据
  onQuery = () => {
    const { url } = this.state.dataUrl;
    fetching(url.query).then(data => {
      this.setState({ data: data });
    })

  }
  //搜索框Change
  onSearchChange = (query) => {
    console.log('onSearchChange', query);
  }
  //页面更改事件
  onChangePage = (query) => {
    console.log('onChangePage', query);
  }
  //处理每页的行更改（pageSize）
  onChangeRowsPerPage = (query) => {
    console.log('onChangeRowsPerPage', query);
  }

  onAdd = newData => {
    return defer(0).then(console.log('--- newData ---: ', newData));
  }

  onUpdate = (newData, oldData) => {
    return defer(60).then(console.log('--- newData, oldData ---: ', newData, oldData));
  }

  onDelete = oldData => {
    return defer(60).then(console.log('--- oldData ---: ', oldData));
  }
  
  exportTableYWD = (e) => {
    const { exercise, form2, dataUrl } = this.state;
    //open: true--详情页；checkOpen: true--公共表单
    this.setState({
      exercise: { ...exercise, save: '3', DialogTitle: form2.exportTable, open: false, tableName: dataUrl.name, status2: '1', checkFlag: true, type: 1 }
    })
  }

  handleToggleYWD = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open: false }
      })
    } else {
      this.setState({
        exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
      })
    }
  }

  render() {
    const { columns, data, dataUrl } = this.state;
    const path = capitalize(this.props.table);
    const title = dataUrl.title || '';
    const actions = actions2(this,this.props.table);
 
    return(
      <MaterialTable
        title={title}
        path={path}
        columns={columns}
        data={data}
        editable={{
          onRowAdd: this.onAdd,
          onRowUpdate: this.onUpdate,
          onRowDelete: this.onDelete,
        }}
        localization={localization}
        options={options}
        actions={actions}
        onSearchChange={this.onSearchChange}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
      />
    )
  }
}

const localization = {
  header: {
    actions: '操作'
  }
}

const actions2 = (that,name)=>{
  let actions = [];
  switch(name) {
    case 'm2-3':
        // actions = [{icon: 'save',tooltip: '编辑',onClick:that.onUpdate2},{icon: ViewComfy,tooltip:'查看',onClick:that.onview}];
        break;
    default :
      actions = [];
  }
  return actions;
}

const options = {
  actionsCellStyle: { padding: '0 10px' },
  headerStyle: { //表格头部
    backgroundColor: '#1d4583',
    color: '#FFF',
    whiteSpace: 'nowrap'
  },
  rowStyle: { //表行
    backgroundColor: '#fff',
    whiteSpace: 'nowrap'
  },
  sorting: true,  //排序
  // selection: true //多选框
}

let setView = Comp => collection => {
  return class extends Component {
    render() {
      return <Comp table={collection} {...this.props} />
    }
  }
}

export const SetView = setView(View);
