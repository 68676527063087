import React, { Component } from 'react';
import Select from 'react-select';
import MaterialTable from '../../vendors/material-table';
import {
    TextField,
    Button,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Select as Select2,
    InputLabel,
    MenuItem,
    CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { Upload } from '../../components/upload';
import { DEFAULT_EXPORT_URL } from '../../config/constants';
import { withStyles } from '@material-ui/core/styles';
import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Formik } from 'formik';
import { C116Formik } from './formikValidate';
import FormikError from './formikError';
import { fetching } from '../../config/utils';
import { formatDate } from '../../components/date'
import { values } from 'lodash';

// const yesornoList = ['是', '否'];
// const registerPlaceList = ['境内', '境外'];
// const enterpriseScaleList = ['大型', '中型', '小型', '微型'];
// const enterpriseTypeList = ['合资', '独资', '国有', '私营', '全民所有制', '集体所有制', '股份制', '有限责任公司']
// const countryPlaceList = ['中国大陆', '中国香港'];
// const relationshipList = ['其他']
// const customerTypesList = ['一般公司']
// const accountingSystemList = ['新会计准则', '旧会计准则']

const loadingStyle = {
    position: "absolute",
    color: green[500],
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -17,
}
const useStyles = (theme => ({
    formControl: {
        marginTop: '16px',
        marginBottom: '8px',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    width: '100%',
    label: {
        textAlign: 'left',
    },
    radio: {
        flexDirection: 'row',
    },
}));

const styles = {
    width: '100%',
    margin: '16px 0 8px 0'
}

class Form1 extends Component {
    state = {
        form: this.getInitState(),
        form2: this.props.form2 || {},//静态资源
        selectCustomerList: [],
        selectOrderDetailList: [],
        loading: false,
        columns:[],
    }
    getInitState() {
        const { exercise = {} } = this.props;
        const form2 = {
            select_customer: '',
            enterprise_name: "",
            enterprise_no: "",
            organization_code: "",
            lglNm: "",
            lglIdCard: "",
            lgCardFileFront: false,
            lgCardFileBack: false,
            lglctfRgstDt: formatDate(new Date(), 'yyyy-MM-dd'),
            lglctfExpDt: formatDate(new Date(), 'yyyy-MM-dd'),
            business_license_file: false,
            ctfRgstDt: formatDate(new Date(), 'yyyy-MM-dd'),
            ctfExpDt: formatDate(new Date(), 'yyyy-MM-dd'),

            productName: "",
            count: "",
            unitPrice: "",
            amount: "",
            orderDate: "",

            sellerName:"",
            sellerOrgCode:"",
            buyerName:"",
            buyerOrgCode:"",
            orderStatus:"",
            // issue_date: new Date(),
            // validity_period: "",
            // yearly_check: "是",
            // organization_type: "企业法人",
            // register_place: "境内",
            // country_register_place: "中国大陆",
            // country_businessr_place: "中国大陆",
            // register_address: "",
            // industry_strategy: "",
            // industry_code: "",
            // national_standard_industry: "",
            // customer_relationship_type: "其他",
            // customer_types: "一般公司",
            // accounting_system: "新会计准则",
            // enterprise_type: "有限责任公司",
            // enterprise_scale: "中型",
            // enterprise_employees: "",
            uploads: {},
        }
        if (exercise.save === '1') {
            form2.select_customer = ''
        } else {
            form2.select_customer = { value: exercise.enterprise_name, label: exercise.enterprise_name, enterprise_no: exercise.enterprise_no }
        }
        return { ...form2, ...exercise }
    }
    async componentDidMount() {
        if (!this.state.form.enterprise_no) {
            this.queryEnterpriseInfo();
        }
        const table = "order_detail_list";
        fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {
        if (data !== undefined && data !== null) {
          //let auth = loginInfos();
          const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
          this.setState({ columns });
        }
      })
    }

    queryEnterpriseInfo = () => {
        let transId = this.props.exercise.id;
        fetching(`/msapi/bigTree/getBtTransOrderList?transId=${transId}`, {
            method: 'GET',
        }).then(res => {
            if (res !== undefined && res !== null) {
                try {
                    let datas = res.filter((item) => {
                        item.value = item.orderId+"-"+item.productName;
                        item.label = item.orderId+"-"+item.productName;
                        return (item.id !== 1) ? item : null;
                    })
                    this.setState({ selectCustomerList: datas });
                } catch (error) {
                }
            } else {
            }
        })
    }
    //选择客户
    handleChange2 = (name, selectedOption) => {
        let form = { ...this.state.form }
        let form2 = { ...this.state.form }
        // form.enterprise_no = selectedOption.enterprise_no
        // form.organization_code = selectedOption.credit_no
        form.productName = selectedOption.productName
        form.count = selectedOption.count
        form.unitPrice = selectedOption.unitPrice
        form.amount = selectedOption.amount
        form.sellerName = selectedOption.sellerName
        form.sellerOrgCode = selectedOption.sellerOrgCode
        form.buyerName = selectedOption.buyerName
        form.buyerOrgCode = selectedOption.buyerOrgCode
        form.orderDate = selectedOption.orderDate
        form.orderStatus = selectedOption.orderStatus

        
        let orderId = selectedOption.id
        fetching(`/msapi/bigTree/getBtTransOrderDetailList?orderId=${orderId}`, {
            method: 'GET',
        }).then(res => {
            if (res !== undefined && res !== null) {
                try {
                    let datas = res.filter((item) => {
                        item.value = "商品:"+item.productName+"-数量:"+item.count+"-金额:"+item.amount;
                        item.label = "商品:"+item.productName+"-数量:"+item.count+"-金额:"+item.amount;
                        return   item ;
                    })
                    this.setState({ selectOrderDetailList: datas });
                    form.selectOrderDetailList = this.state.selectOrderDetailList
                    this.setState({ form: { ...form } })
                    // let column = [];
                    // column = datas.map(c => ({ title: c.productName, field: c }));
                    // this.setState({ columns: column });
                } catch (error) {
                }
            } else {
            }
        })
        this.setState({ form: { ...form } })
        return selectedOption;
    }
    dateChange = (e) => {
        if (e !== null) {
            let open_day = formatDate(e, 'yyyy-MM-dd');
        return open_day;
      } else {
        alert("请选择日期");
      }
 }
    uploadChange = (file) => {

    };

    conversion = (date) => {
        if (date instanceof Object && date.getTime()) {
            return formatDate(date, 'yyyy/MM/dd');
        }
        return date
    }
    
    render() {
        const { exercise = {}, formStatic, classes } = this.props;
        const { table = '' } = this.props;
        const title = '';
        const { form, selectCustomerList ,columns,form2} = this.state;
        return (
            <Formik
                //初始化数据
                initialValues={{
                    ...form
                }}
                //表单验证
                validate={(values) => {
                    return C116Formik(values, formStatic);
                }}
                //验证提交
                onSubmit={(values) => {
                    this.handleSave(values);
                }}
                render={({
                    values,
                    errors,
                    touched,
                    uploads,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                        <form method="post" encType="multipart/form-data">
                            <Grid item>
                                <div style={{ margin: '5px 0' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_customer}</span>
                                    <Select
                                        isSearchable
                                        value={values.select_customer}
                                        onChange={e => setFieldValue('select_customer', this.handleChange2.bind(this, 'select_customer', e)())}
                                        options={selectCustomerList}
                                        isDisabled={exercise.save === '1'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'select_customer'} />
                                </div>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.orgName}
                                        value={form.orgName}
                                        name='orgName'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.orgCode}
                                        value={form.orgCode}
                                        name='orgCode'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    {/* <FormikError touched={touched} errors={errors} name={'organization_code'} /> */}
                                </Grid>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={2}>
                                    <TextField
                                        label={formStatic.productName}
                                        value={form.productName}
                                        name='productName'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'productName'} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        label={formStatic.count}
                                        value={form.count}
                                        name='count'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'count'} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        label={formStatic.unitPrice}
                                        value={form.unitPrice}
                                        name='unitPrice'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'unitPrice'} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        label={formStatic.amount}
                                        value={form.amount}
                                        name='amount'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'amount'} />
                                </Grid>
                              </Grid>
                              <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.sellerName}
                                        value={form.sellerName}
                                        name='sellerName'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'sellerName'} />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.sellerOrgCode}
                                        value={form.sellerOrgCode}
                                        name='sellerOrgCode'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'sellerOrgCode'} />
                                </Grid>
                              </Grid>
                              <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.buyerName}
                                        value={form.buyerName}
                                        name='buyerName'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'buyerName'} />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.buyerOrgCode}
                                        value={form.buyerOrgCode}
                                        name='buyerOrgCode'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'buyerOrgCode'} />
                                </Grid>
                              </Grid>
                              <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.orderDate}
                                        value={form.orderDate}
                                        name='orderDate'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orderDate'} />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.orderStatus}
                                        value={form.orderStatus}
                                        name='orderStatus'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'orderStatus'} />
                                </Grid>
                              </Grid>
                              {/* <Grid item>
                                <div style={{ margin: '5px 0' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_order_detail}</span>
                                    <Select
                                        isSearchable
                                        value={values.select_order_detail}
                                        //onChange={e => setFieldValue('select_order_detail', this.handleChange2.bind(this, 'select_order_detail', e)())}
                                        options={selectOrderDetailList}
                                        isDisabled={exercise.save === '1'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'select_order_detail'} />
                                </div>
                            </Grid> */}
                            <Grid>
                                <div>
                                <MaterialTable
                                    title={"子订单详情"}
                                    //onSearchChange={this.onSearchChange}
                                    //onChangePage={this.onChangePage}
                                    //onChangeRowsPerPage={this.onChangeRowsPerPage}
                                    //path={path}
                                    columns={columns}
                                    data={form.selectOrderDetailList}
                                    //actions={actions}
                                    //editable={editable}
                                    //localization={localization}
                                    options={options(table)}
                                />
                                </div>
                            </Grid>
                            <div style={{ margin: '20px 0', position: 'relative' }}>
                                {exercise.save !== '3' &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={this.state.loading}
                                    >
                                        {formStatic.save}
                                        {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
                                    </Button>
                                }
                            </div>
                        </form>
                    )}
            />
        );
    }
}

const options = (table) => {
    const options = {
      actionsCellStyle: { padding: '0 10px' },
      headerStyle: { //表格头部
        backgroundColor: '#1d4583',
        color: '#FFF',
        whiteSpace: 'nowrap'
      },
      rowStyle: { //表行
        backgroundColor: '#fff',
        whiteSpace: 'nowrap'
      },
      sorting: true,  //排序
      // selection: true //多选框
    }
    /*if (table === 'r6-3-7') {
      options.exportButton = true //导出
    }*/
    return options;
  
  }

export default withStyles(useStyles)(Form1);
