import React, { Component } from 'react'
import { Formik } from 'formik';
import {
  TextField,
  Button,
  Grid,
  CircularProgress,
  Input
} from '@material-ui/core'
import { formatDate } from '../../components/date';//20200918
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import 'date-fns';
import cnLocale from "date-fns/locale/zh-CN";
import { Upload } from '../../components/upload';
import FormikError from '../../components/commonForm/formikError';
import { fetching } from '../../config/utils';
import { DEFAULT_EXPORT_URL } from '../../config/constants';
import { DEFAULT_UPLOADS_URL } from '../../config/constants'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }
}));
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    form: this.getInitState(),
    form2: this.props.form2 || {},//静态资源
    loading: false,
    uploads: {},
    opinion_list: [],
    fordata : new FormData(),

  }

  async componentDidMount() {

  }
  getInitState() {
    const { exercise } = this.props;
    const open_day = new Date();
    const form = {
      expirationDate: open_day
        }
    return { ...form, ...exercise }
  }

  uploadFile(e){    
    let form = this.state.form;
    this.fordata.append('fileName',e.file);
    // const options = {
    //   method: "POST",
    //   body: fordata
    // }
    
    //   return fetch(`/msapi/upload/downLoadExcel/getInterestDerivedByFin`,options)
  }

  uploadFile1(e){
    
    let form = this.state.form;
    var fordata = new FormData();
    fordata.append('fileName',e.file);
    const options = {
      method: "POST",
      body: fordata
    }
    
      return fetch(`/msapi/upload/downLoadExcel/getCWVo`,options)
  }

  handleChange3 = (name, value) => {
    
    if (name === 'expirationDate') {
      if (!(value instanceof Object && value.getTime())) {//判断日期是否为有效值
        return;
      }
      if (name === 'expirationDate') {
      this.state.form.expirationDate = value;
      }
      return value;
    }
  }

  uploadHandler= async (e,type) => {
    let form = this.state.form;
    this.state.fordata.append('fileName',e.file);
    //     await this.uploadFile(e).then(res => {
    //     if (res) {
    //     if  (!res.status){
    //         alert(res.msg)
    //       }else{
    //           return res.json()
    //       }
    //     }else{
    //       return null
    //     }
    //     }).then(r=>{      
    //           if (r.error !== null) {
    //             alert(r.error.message)
    //             return null;
    //           } else {
    //             alert("摊销文件导入成功，查看结果请点击导出！！！");
    //             return r.data
    //           }
    //         }) 
        
    // return true
}

  uploadHandler1 = async (e,type) => {
    await this.uploadFile1(e).then(res => {
    if (res) {
    if  (!res.status){
        alert(res.msg)
      }else{
          return res.json()
      }
    }else{
      return null
    }
    }).then(r=>{  
    
          if (r.error !== null) {
            alert(r.error.message)
            return null;
          } else {
            alert("财务文件导入成功，查看测算结果请点击导出！！！");
            return r.data
          }
        }) 
    
  return true
  }


handleSubmit = () =>{
  this.setState({ loading: true})
  let {expirationDate, tableName} = this.state.form;
  expirationDate = formatDate(expirationDate, 'yyyy-MM-dd');
  this.state.fordata.append('expirationDate',expirationDate);
  //接口校验注解
  let url1 = `/contract/downLoadExcel/getInterestDerivedByFin?type=1`
  let url = `contract/downLoadExcel/getInterestDerivedByFin?expirationDate=${expirationDate}`
  if (tableName === "c2-1"){
    fetching( `/contract/downLoadExcel/getCWCalculation?type=1`, { 
      method: 'get',
    }).then(data => {
      if(data.code === 300){
        alert(data.msg)
        return
      }else if(data.code === 200){
        window.open(`${DEFAULT_EXPORT_URL}${`contract/downLoadExcel/getCWCalculation`}`)
      }
      })
  }else{
    if(!this.state.fordata.get("fileName")){
      alert("未导入文件")
      return;
    }
    const options = {
      method: "POST",
      body: this.state.fordata
    }
    fetch('/msapi/upload/downLoadExcel/getInterestDerivedByFin',options).then(res =>{
        window.location.reload();
    })   
   }
  }

  //下载模板
  downloadTemplate =()=>{
    window.open(DEFAULT_UPLOADS_URL+ '财务数据上传表.xlsx')
  }


  render() {
    const form2 = this.state.form2;
    const form = this.state.form;
    const { exercise } = this.props;
    const tableName = exercise.tableName;
    const status = (handleSubmit) => {
      return<div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          disabled={this.state.loading}
          name={form2.export}
          fullWidth
          style={{ marginRight: '10px' }}
        >
          {form2.export}
          {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
        </Button>
        
      </div>
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}

        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, '提交');
        }}
        render={({
          values,
          errors,
          touched,
          uploads,
          setFieldValue,
          handleBlur,
          handleSubmit,
        }) => (
            <form>
              <Grid container justify="space-between">
                  <Grid item xs={12}>
                    {tableName !== "c2-1" && <Upload 
                      uploadName="Upload_contracts"
                      mold="3"
                      uploads={uploads}
                      name={`摊销计算`} 
                      view={form2.view_case}
                      exercise={exercise}
                      uploadChange={e=>setFieldValue('Upload_contracts',this.uploadHandler( e, 1))
                      }
                      > 
                      </Upload>
                      }
                      {tableName === "c2-1" && <Upload 
                      uploadName="Upload_contracts"
                      mold="3"
                      uploads={uploads}
                      name={`文件导入`} 
                      view={form2.view_case}
                      exercise={exercise}
                      uploadChange={e=>setFieldValue('Upload_contracts',this.uploadHandler1( e, 1))}> 
                      </Upload>
                      }
                    <FormikError touched={touched} errors={errors} name={'Upload_contracts'} />
                  </Grid>
                  {tableName === "c2-1" && <Grid item xs={12}>
                    <Button 
                      variant='contained' 
                      onClick={this.downloadTemplate}>{'下载模板'}
                    </Button>
                  </Grid>
                  }
                  <Grid item xs={12}>
                  {tableName !== "c2-1" && <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      label="截止日期"
                      format="yyyy/MM/dd"
                      margin="normal"
                      id="date-picker-inline"
                      value={values.expirationDate}
                      onChange={e => setFieldValue('expirationDate', this.handleChange3.bind(this, 'expirationDate', e)())}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  }
                </Grid>
                </Grid>
                {/* <div style={{ textAlign: 'end', marginBottom: '10px' }}>
                  <Button variant="contained" color="primary" onClick={this.InterestDerivedByFin}>导出</Button>
                </div> */}
               {
                status(handleSubmit,  values)
              }
            </form>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);



