import React, { Component } from 'react';
import {
  ViewComfy,
} from '@material-ui/icons'
import MaterialTable from '../../vendors/material-table';
import { defer, capitalize, fetching } from '../../config/utils';
import { core_business_url } from './core_business_url';
// import { C111Form, Dialog } from './index'
import { common, } from '../formStatic'
import { loginInfos} from '../../components/numToText'
import {
  Button,
  ButtonGroup,
} from '@material-ui/core'
import { InterestDerivedForm, InterestDerivedDialogForm } from '../../02-core_business/interestDerived';//利息摊销弹框 
export const BASE = '/customer-info';

class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [{}],
      auth: {},//登录信息
      data: [],
      dataUrl: { url: { query: '' } },
      exercise: {},
      form2: { ...common },
      hasToken: sessionStorage.getItem("authToken")
    };
  }

  componentDidMount() {
    const { table } = this.props;
    let auth = loginInfos();
    this.setState({ auth });
    fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {

        const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
        //根据不同的页面，获取不同的接口地址，渲染不同的数据
        const dataUrl = core_business_url(auth, table).find(item => item.name === table);
        const dataUrl2 = dataUrl || {};
        this.setState({ columns, dataUrl: dataUrl2 });
        if (dataUrl2.url && auth.roles[0].id === 1) {
          dataUrl.url.query !== '' && this.onQuery();
        } else if (dataUrl2.url) {
          dataUrl.url.query !== '' && this.onQuery2();
        }
      });
  }

  //项目经理查询数据
  onQuery = () => {
    const { url } = this.state.dataUrl;
    fetching(url.query).then(data => {
      try {
        this.setState({ data: data });
      } catch (error) {
      }
    })
  }
  //其他角色查询数据
  onQuery2 = () => {
    const { url } = this.state.dataUrl;
    fetching(url.get).then(data => {
      try {
        this.setState({ data: data });
      } catch (error) {
      }
    })
  }
  //搜索框Change
  onSearchChange = (query) => {
    console.log('onSearchChange', query);
  }
  //页面更改事件
  onChangePage = (query) => {
    console.log('onChangePage', query);
  }
  //处理每页的行更改（pageSize）
  onChangeRowsPerPage = (query) => {
    console.log('onChangeRowsPerPage', query);
  }

  //新增
  handleToggle = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open: false }
      })
    } else {
      this.setState({
        exercise: { status2: '1', DialogTitle: form2.add, save: '1', open: true }
      })
    }
  }

  //表单新增后
  FormSubmit = (newData = {}) => {
    const { auth } = this.state
    this.setState({
      exercise: { ...this.state.exercise, open: false }
    })
    setTimeout(() => {
      if (auth.roles[0].id === 1) {
        this.onQuery();
      } else {
        this.onQuery2();
      }
    }, 500)
  }

  //表格删除
  onDelete = async oldData => {
    const { dataUrl, auth } = this.state
    const returnedData = await fetching(dataUrl.url.get2 + oldData.id, { method: 'DELETE' })
    if (returnedData) {
      if (auth.roles[0].id === 1) {
        this.onQuery();
      } else {
        this.onQuery2();
      }
    }
    return returnedData;

  }

  //编辑
  onUpdate2 = (e, row) => {
    const { form2 } = this.state
    let status2 = '1'
    this.setState({ exercise: { ...row, save: '2', DialogTitle: form2.edit, status2, open: true, } });
  }

  //查看
  onview = (e, row) => {
    const { form2 } = this.state
    this.setState({ exercise: { ...row, save: '3', status2: '', DialogTitle: form2.view, open: true, } });



  }

  onAdd = newData => {
    return defer(0).then(console.log('--- newData ---: ', newData));
  }

  onUpdate = (newData, oldData) => {
    return defer(60).then(console.log('--- newData, oldData ---: ', newData, oldData));
  }

  onDelete = oldData => {
    return defer(60).then(console.log('--- oldData ---: ', oldData));
  }


  //触发利息摊销文件上传弹窗--20201117
  exportTableLXTX = (e) => {
    const { exercise, form2, dataUrl } = this.state;
    this.setState({
      exercise: { ...exercise,
        save: '5',
        DialogTitle: form2.exportInterestDerived,
        open3: true, 
        tableName: dataUrl.name,
        status2: '1',
        checkFlags: true,
        type: 1 
      }
    })
  }

  //触发打开时间段选项弹窗--20201117
  handleToggleLXTX  = (open) => {
    const { form2 } = this.state
    if (open) {
      this.setState({
        exercise: { open3: false }
      })
    window.location.reload()
    } else {
      
      this.setState({
        exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '5', open3: true,isBtn:false }
      })
    }
  }


  render() {
    const { columns, data, dataUrl, exercise} = this.state;
    const title = dataUrl.title || '';
    const { table = '' } = this.props;
    const path = capitalize(this.props.table);
    const actions = actions2(this, this.props.table);
    const editable = editable2(this, this.props.table);
    const { form2 } = this.state

    //财务信息
    // const C111 = () => {
    //   let isBtn = auth&&this.props.table === 'c2-1' && auth.roles[0].id === 1 ? true : false;
    //   return <div style={{ justifyContent: 'flex-end' }}>
    //     <Dialog handleToggle={this.handleToggle}
    //       exercise={{ ...exercise, addBtnName: common.addBtnName5, isBtn }}
    //     >
    //       <C111Form auth={auth} formStatic={{ ...common, ...c2_1 }} exercise={exercise}
    //         onSubmit={this.FormSubmit} />
    //     </Dialog>
    //   </div>
    // }

    // const table = this.props.table || '';

    //摊销利息导出台账
     const InterestDerived =() => {
      let isBtn = false;
      return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        <InterestDerivedDialogForm handleToggle={this.handleToggleLXTX} exercise={{ ...exercise, addBtnName: common.exportTable, isBtn }}>
          <InterestDerivedForm form2={{ ...common }} exercise={{ ...exercise }} onSubmit={this.FormSubmit} />
        </InterestDerivedDialogForm>
     </div>
     }

     const T2 = () => {
      return <div style={{ textAlign: 'end', marginBottom: '10px' }}>
         <Button variant="contained" color="primary" onClick={this.exportTableLXTX}>财务测算导出</Button>
       </div>
    }


    return (
      <div>
        {(table === 'c2-1') && <InterestDerived></InterestDerived>}
        {(table === 'c2-1') && <T2></T2>}
        {/* {(table === 'c2-1') && <C111></C111>} */}
        <MaterialTable
          title={title}
          path={path}
          columns={columns}
          data={data}
          editable={editable}
          localization={localization}
          options={options}
          actions={actions}
          onSearchChange={this.onSearchChange}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
        />
      </div>
    )
  }
}
const actions2 = (that, name) => {
  let actions = [];
  switch (name) {
    case 'c1-1-1':
      actions = [{ icon: 'save', tooltip: '编辑', onClick: that.onUpdate2 }, { icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'c1-1-2':
      actions = [{ icon: 'save', tooltip: '编辑', onClick: that.onUpdate2 }, { icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'c1-2':
      actions = [{ icon: 'save', tooltip: '编辑', onClick: that.onUpdate2 }, { icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'c1-3':
      actions = [{ icon: 'save', tooltip: '编辑', onClick: that.onUpdate2 }, { icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    case 'c1-4':
      actions = [{ icon: 'save', tooltip: '编辑', onClick: that.onUpdate2 }, { icon: ViewComfy, tooltip: '查看', onClick: that.onview }];
      break;
    default:
      actions = [];
  }
  return actions;
}
const editable2 = (that, name) => {
  let editable = {};
  switch (name) {
    case 'r12-1':
      editable = { onRowDelete: that.onDelete };
      break;
    default:
  }
  return editable;
}
const localization = {
  header: {
    actions: '操作'
  }
}
const options = {
  actionsCellStyle: { padding: '0 10px' },
  headerStyle: { //表格头部
    backgroundColor: '#1d4583',
    color: '#FFF',
    whiteSpace: 'nowrap'
  },
  rowStyle: { //表行
    backgroundColor: '#fff',
    whiteSpace: 'nowrap'
  },
  sorting: true,  //排序
  // selection: true //多选框
}





let setView = Comp => collection => {
  return class extends Component {
    render() {
      return <Comp table={collection} {...this.props} />
    }
  }
}

export const SetView = setView(View);
