//融单融资界面表单
export default {
    mx_number: "可用应收账款编号*",
    mx_amount:'应收账款金额*',
    usable_amount: "可用应收金额*",
    amount: "融资金额*",
    application_number:'申请编号',
    applicant:'申请方',
    service_tariffing :'服务费率（%）',
    service_charge:'服务费',
    financing_cost:'融资成本',
    financing_maturity:'融资期限（天）',
    financing_time: "融资申请时间*",
    payment_day: "承诺付款日*",
    interest: "预计融资息费金额*",
    interest_pay: "利息支付方式*",
    net_financing_amount: "净融资额*",
    sign_agreement: "我同意",
    Upload_contracts: "上传合同*",
    Upload_invoice: "上传发票*",
    Upload_dispatch: "上传发货单",
    Upload_shipping: "上传运输单",
    remarks: "备注",
    synthesis_rate: "预计融资息费率*",
    right_resource: "追索权",
    add_days_number:'增加天数',

    //静态资源 不用在数据库里面加这些字段
    view_case: "查看案例",
    financing_amount_capital:'融资金额大写',
    sign_agreement2: "《融单融资协议》*",
    view_agreement: "查看融单融资协议",
    check_opinion: "审核意见"
}