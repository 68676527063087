const { string, number, date } = require('yup');
const decimals = /^([1-9]|[1-9]\d|100)(\.\d{1,2})?$/ //数字1-100 保留两位小数
const phone = /^1[3456789]\d{9}$/ //手机号码
//融单开具
export const issueFormik = (form2) => {

  return {
    extraction:
      string()
        .trim().required(form2.extraction),//开立
    open_credit_code:
      string()
        .trim().required(form2.open_credit_code),//开立信用码
    receive:
      string()
        .trim().required(form2.receive),//接收
    amount://金额
      string()
        .trim().required(form2.amount),
    // receivable://发票总金额
    //   string()
    //     .trim().required(form2.receivable),
    open_day:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),//开立日
    payment_day:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),//承诺付款日
    Upload_contracts:
      string()
        .required(form2.Upload_contracts),//上传合同
    Upload_invoice:
      string()
        .required(form2.Upload_invoice),//上传发票
    Upload_contracts_invoice:
      string()
        .required(form2.Upload_contracts_invoice),//上传合同发票编号

    // synthesis_rate://预计费率, william jiang
    //   number()
    //     .required(form2.synthesis_rate)
    //     .max(100, '数字不能大于100')
    //     .test('synthesis_rate', '请输入1~100的数字最多保留2位小数', value => decimals.test(value)),
  }

}

//应收开具
export const receivableFormik = (form2) => {

  return {
    extraction:
      string()
        .trim().required(form2.extraction),//供应商
    open_credit_code:
      string()
        .trim().required(form2.open_credit_code),//供应商信用码
    amount://应收账款金额
      string()
        .trim().required(form2.amount),
    discount_rate:
      string()
        .trim().required(form2.discount_rate),//折扣率
    open_day:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),//开立日
    payment_day:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),//承诺付款日
    applyAmount://融资申请金额
      string()
        .trim().required(form2.applyAmount),
    receive:
      string()
        .trim().required(form2.receive),//债务人    
    Upload_contracts:
      string()
        .required(form2.Upload_contracts),//上传合同
    Upload_invoice:
      string()
        .required(form2.Upload_invoice),//上传发票
    Upload_contracts_invoice:
      string()
        .required(form2.Upload_contracts_invoice),//上传合同发票编号

    // synthesis_rate://预计费率, william jiang
    //   number()
    //     .required(form2.synthesis_rate)
    //     .max(100, '数字不能大于100')
    //     .test('synthesis_rate', '请输入1~100的数字最多保留2位小数', value => decimals.test(value)),
  }

}

//融单流转
export const circulationFormik = (form2) => {
  return {
    mx_number:
      string()
        .trim().required(form2.mx_number),//融单编号
    receive:
      string().trim().required(form2.receive),//接收方
    amount://金额
      string()
        .trim().required(form2.amount),
    flow_date:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),//流转日期
    payment_day:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),//承诺付款日
    Upload_contracts:
      string()
        .required(form2.Upload_contracts),//上传合同
    Upload_invoice:
      string()
        .required(form2.Upload_invoice),//上传发票
    Upload_contracts_invoice:
      string()
        .required(form2.Upload_contracts_invoice),//上传合同发票编号

  }
}

//融单融资
export const financingFormik = (form2) => {
  return {
    mx_number:
      string()
        .trim().required(form2.mx_number),//融单编号
    amount:
      string()
        .trim().required(form2.amount),//融资金额
    financing_time:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),//融资申请时间
    payment_day:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),//承诺付款日
    Upload_contracts:
      string()
        .required(form2.Upload_contracts),//上传合同
    Upload_invoice:
      string()
        .required(form2.Upload_invoice),//上传发票
    Upload_contracts_invoice:
      string()
        .required(form2.Upload_contracts_invoice),//上传合同发票编号
    //synthesis_rate://预计费率, william jiang
      //number()
        //.required(form2.synthesis_rate)
        //.max(100, '数字不能大于100')
        //.test('synthesis_rate', '请输入1~100的数字最多保留2位小数', value => decimals.test(value)),
  }
}

//中登登记
export const zdFormik = (form2) => {
  return {
    detailaddress:
      string()
        .trim().required(form2.detailaddress),//详细地址
    collateraldescribe:
      string()
        .trim().required(form2.collateraldescribe),//财产描述
  }
}

//创建用户
export const userFormik = (values, form2) => {
  const pwd1 = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,18}$/
  let errors = {};
  if (!values.name) {//姓名
    errors.name = form2.name;
  } else if (!values.account) {//用户名
    errors.account = form2.account;
  }
  else if (values.hasOwnProperty('password') && !values.password) {
    errors.password = form2.password;
  } else if (values.password !== undefined && !pwd1.test(values.password)) {
    errors.password = '密码不符合规范(最少8位,且必须包含字母、数字和特殊字符)';
  } else if (values.hasOwnProperty('confirm_password') && !values.confirm_password) {
    errors.confirm_password = form2.confirm_password;
  } else if (values.confirm_password !== undefined && !pwd1.test(values.confirm_password)) {
    errors.confirm_password = '密码不符合规范(最少8位,且必须包含字母、数字和特殊字符)';
  } else if (values.hasOwnProperty('password') && values.hasOwnProperty('confirm_password') && values.confirm_password !== values.password) {
    errors.confirm_password = "两次输入密码不一致";
  } else if (!values.phone) {//用户名
    errors.phone = form2.phone;
  } else if (values.phone && !phone.test(values.phone)) {
    errors.phone = '手机号码格式不正确';
  } else if (!values.role_id) {//角色
    errors.role_id = form2.role_id;
  }
  return errors;
}

//重置密码
export const pwdFormik = (values, form2) => {
  const pwd1 = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,18}$/
  // same as above, but feel free to move this into a class method now.
  let errors = {};
  if (!values.new_password) {
    errors.new_password = form2.new_password;
  } else if (values.hasOwnProperty('old_password') && values.old_password === values.new_password) {
    errors.new_password = '新密码不能与原密码一致';
  } else if (!values.confirm_password) {
    errors.confirm_password = form2.confirm_password;
  } else if (values.confirm_password !== values.new_password) {
    errors.confirm_password = '两次输入密码不一致';
  } else if (!pwd1.test(values.new_password)) {
    errors.new_password = '密码不符合规范(最少8位,且必须包含字母、数字和特殊字符)';
  } else if (!pwd1.test(values.confirm_password)) {
    errors.confirm_password = '密码不符合规范(最少8位,且必须包含字母、数字和特殊字符)';
  } 
  return errors;
}

//重置密码
export const C41Formik = (values, form2, rolesId) => {
  // same as above, but feel free to move this into a class method now.
  let errors = {};
  if (rolesId === 12 && !values.check_opinion) {
    errors.check_opinion = form2.check_opinion;
  }

  return errors;
}

//修改客户银行卡信息校验
export const accountFormik = (values, form2) => {
  let errors = {};
  if (values.bankAccount === '') {
      errors.bankAccount = '银行卡号不为空';
  }
  if (values.bank_number === '') {
    errors.bank_number = '银行联行号不为空';
  }
   if(values.bank ===''){
    errors.bank='开户行不能为空';
  }
  return errors;
}

