import React, { Component } from 'react'
// import { fetching } from '../../config/utils';
import {
    TextField,
    Button,
    Grid,
    Select as Select2,
    InputLabel,
    MenuItem,
    FormControl,
} from '@material-ui/core'
import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import SimpleExpansionPanel from '../Panels'
const useStyles = (theme => ({
    field: {
        width: '100%',
    },
    top: {
        marginTop: 16,
    },
    button: {
        margin: theme.spacing(1),

    },
    bottom: {
        display: 'flex',
        alignItems: 'flex-end',
        textAlign: 'right',
    }
}));
class Form extends Component {
    state = {
        form: this.getInitState(),
        formStatic: this.props.formStatic || {},//静态资源
        interest_list: ['一次性','按月支付'],//结息
        overdue_query:['是', '否'],//逾期客户查询
        distance_expire_list:[
            '30天',
            '7天',
            '3天',
            '1天',
        ],//距离到期客户
    }
    async componentDidMount() { }
    getInitState() {
        const form = {
            organization_name: "",//机构名称
            enterprise_no: "",//客户编号
            enterprise_name: "",//客户名称
            debt_number: "",//借据号
            grant_date: null,//..发放日期
            expire_date: null,//..到期日期
            interest_settlement: "",//结息方式
            repayment_account: "",//还款账号
            debit_interest_query: "",//欠息客户查询
            overdue_query: "",//逾期客户查询
            distance_expire_clientele : "",//距离到期30天的客户

        }
        return form
    }
    handleChange = (e) => {
        const { form } = this.state
        const { name, value } = e.target;
        form[name] = value;
        this.setState({ form: { ...form } })

    }
    //时间
    handleChange2 (e,name) {
        const { form } = this.state
        this.setState({ form: { ...form, [name]:e } })

    }
    //保存 提交
    handleSubmit = () => {
        // let form = this.state.form;

    }
    handleReset = () => {
        let forms = this.getInitState();
        this.setState({ form: forms })
    }

    render() {
        const { formStatic, interest_list, distance_expire_list, overdue_query } = this.state;
        const form = this.state.form;
        return (
            <form>
                <SimpleExpansionPanel title="查询条件">
                    <Grid container justify="space-between">
                        <Grid item xs={5}>
                            <TextField
                                label={formStatic.organization_name}
                                value={form.organization_name}
                                name='organization_name'
                                onChange={this.handleChange}
                                margin='normal'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label={formStatic.enterprise_name}
                                value={form.enterprise_name}
                                name='enterprise_name'
                                onChange={this.handleChange}
                                margin='normal'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="space-between">
                        <Grid item xs={5}>
                            <TextField
                                label={formStatic.enterprise_no}
                                value={form.enterprise_no}
                                name='enterprise_no'
                                onChange={this.handleChange}
                                margin='normal'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label={formStatic.debt_number}
                                value={form.debt_number}
                                name='debt_number'
                                onChange={this.handleChange}
                                margin='normal'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%' }}
                                        disableToolbar
                                        variant="inline"
                                        format="yyyy/MM/dd"
                                        margin="normal"
                                        name='grant_date'
                                        id="date-picker-inline"
                                        label={formStatic.grant_date}
                                        value={form.grant_date}
                                        onChange={e => this.handleChange2.bind(this,e,'grant_date')()}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%' }}
                                        disableToolbar
                                        variant="inline"
                                        format="yyyy/MM/dd"
                                        margin="normal"
                                        name='expire_date'
                                        id="date-picker-inline"
                                        label={formStatic.expire_date}
                                        value={form.expire_date}
                                        onChange={e => this.handleChange2.bind(this,e,'expire_date')()}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>


                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid container justify="space-between">
                        <Grid item xs={5}>
                            <FormControl style={{ width: '100%', margin: '16px 0 8px 0' }} >
                                <InputLabel id="demo-simple-select-label">{formStatic.interest_settlement}</InputLabel>
                                <Select2
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='interest_settlement'
                                    value={form.interest_settlement}
                                    onChange={this.handleChange}>
                                    >
                                {interest_list.map(item => {
                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                    })}
                                </Select2>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label={formStatic.repayment_account}
                                value={form.repayment_account}
                                name='repayment_account'
                                onChange={this.handleChange}
                                margin='normal'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="space-between">
                        <Grid item xs={5}>
                            <FormControl style={{ width: '100%', margin: '16px 0 8px 0' }} >
                                <InputLabel id="demo-simple-select-label">{formStatic.debit_interest_query}</InputLabel>
                                <Select2
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='debit_interest_query'
                                    value={form.debit_interest_query}
                                    onChange={this.handleChange}>
                                    >
                                {overdue_query.map(item => {
                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                    })}
                                </Select2>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl style={{ width: '100%', margin: '16px 0 8px 0' }} >
                                <InputLabel id="demo-simple-select-label">{formStatic.overdue_query}</InputLabel>
                                <Select2
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='overdue_query'
                                    value={form.overdue_query}
                                    onChange={this.handleChange}>
                                    >
                                {overdue_query.map(item => {
                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                    })}
                                </Select2>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container justify="space-between">
                        <Grid item xs={5}>
                            <FormControl style={{ width: '100%', margin: '16px 0 8px 0' }} >
                                <InputLabel id="demo-simple-select-label">{formStatic.distance_expire_clientele}</InputLabel>
                                <Select2
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='distance_expire_clientele'
                                    value={form.distance_expire_clientele}
                                    onChange={this.handleChange}>
                                    >
                                {distance_expire_list.map(item => {
                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                    })}
                                </Select2>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div style={{ padding: '10px 0' }}>
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={this.handleSubmit}
                            name={formStatic.query}
                            style={{ marginRight: '10px' }}
                        >
                            {formStatic.query}
                        </Button>
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={this.handleReset}
                            name={formStatic.reset}
                            style={{ marginRight: '10px' }}
                        >
                            {formStatic.reset}
                        </Button>
                    </div>
                </SimpleExpansionPanel>
            </form>
        )
    }
}

export default withStyles(useStyles)(Form);



