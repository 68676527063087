import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { Form1, Form2, Form3, Form4, Form5 } from './applyForm';
import { r4 } from '../formStatic';
import { commafy, delcommafy } from '../../components/numToText';
import CheckForm from './CheckForm';
import { fetching } from '../../config/utils';
import { formatDate } from '../../components/date'
import { fromPairs } from 'lodash';
const useStyles = (theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


function getSteps() {
  return ['额度发起', '核定建议', '资料登记', '合规性检查', '调查报告'];
}

const form1 = {
  guaranty_way: '信用',//担保方式
  credit_way: "综合额度",//额度方式
  limit_type: '单一客户',//额度类型
  select_customer: '',//选择客户
  apply_credit_no: '',//申请书号
  limit_mark: '可循环额度',//额度标准
  enterprise_name: "",//客户名称
  mx_amount: '',//总额度
  is_appoint_customer: "不指定使用客户",//是否指定使用客户
  entrusted_payment: "是",//受托支付
  exposure: "敞口",
  quota:"保理额度",
}
const form2 = {
  enterprise_no: "",//客户编号
  business_varieties: "保理",//业务品种
  business_status: "正常",//业务状态
  credit_start_date: new Date(),
  credit_due_date: new Date(),
  mx_amount: "",//额度金额
  cash_deposit_ratio: "",//保证金比例
  is_allow_equivalent_deposit: "否",//是否允许等价物充当保证金
  equivalent_info: "",//等价物信息
  trading_currencies: "人民币",//出账使用币种
  remark: "",
}

class ApplyIndex extends React.Component {
  state = {
    activeStep: 0,
    steps: getSteps(),
    form: this.getInitState(),
    formStatic: { ...r4 },
    exercise: this.props.exercise || {},
    checkExercise: this.props.checkExercise || {},
    auth: {},//登录信息
    uploads: this.getInitUploads() || {},
    guarantInfo1: this.props.guarantInfo1 || {},
    guarantInfo2: this.props.guarantInfo2 || {},
    guarantInfo3: this.props.guarantInfo3 || {},
    submitHint: '',
    loading: false,
  }
  //如果有异步设置state值的情况，在组件销毁时清除所有的state状态
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }

  getInitState() {
    const { exercise = {} } = this.props;
    if (exercise.enterprise_no) {
      exercise.select_customer = { value: exercise.enterprise_name, label: exercise.enterprise_name, enterprise_no: exercise.enterprise_no }
      exercise.mx_amount = exercise.mx_amount !== '0' ? commafy(exercise.mx_amount) : '';
    }
    return exercise.save === '1' ? { ...form1, ...form2, } : { ...form1, ...form2, ...exercise };
  }

  getInitUploads() {
    let uploads = {}
    const Uploads = this.props.exercise.uploads;
    if (Uploads && Uploads.length > 0)
      Uploads.forEach((item) => {
        uploads[item.field] = item.fpath
      })
    return uploads;
  }

  //下一步
  handleNext = (data) => {
    const { form } = this.state;
    if (data !== undefined && data !== null) {
      this.setState({ activeStep: this.state.activeStep + 1, form: { ...form, ...data } })
    } else {
      this.setState({ activeStep: this.state.activeStep + 1 })
    }
  };
  //上一步
  handleBack = (data) => {
    if (data !== undefined && data !== null) {
      const { form } = this.state;
      this.setState({ activeStep: this.state.activeStep - 1, form: { ...form, ...data } })
    } else {
      this.setState({ activeStep: this.state.activeStep - 1 })

    }
  };
  //保存
  handleSave = (data1) => {
    let data = data1 ? data1 : {};
    data.status_id = 1
    this.onSubmit(data);
  }
  //提交
  
  handleSubmit1 = (data) => {
    data.status_id = 3
    const { form } = this.state;
    if (data !== undefined && data !== null) {
      this.setState({ activeStep: this.state.activeStep + 1, form: { ...form, ...data } })

    } else {
      this.setState({ activeStep: this.state.activeStep + 1 })

    }
    this.onSubmit(data);
  }
  
  onSubmit = (data) => {
    this.setState({ submitHint: '正在提交...' })
    const { auth } = this.props;
    const { save, id } = this.state.form;
    let ids = (save === '2' || save === '3') ? id : '';
    let method = (save === '2' || save === '3') ? 'POST' : 'POST';
    const { form } = this.state;
    const form1 = { ...form, ...data }
    form1.auth = auth;//用户信息
    const amountList = ['mx_amount'];
    amountList.forEach(item => {
      form1[item] = delcommafy(form1[item]);
    });
    const dateList = ['credit_start_date', 'credit_due_date'];
    dateList.forEach(item => {
      if (form1[item] instanceof Object && form1[item].getTime()) {
        form1[item] = formatDate(form1[item], 'yyyy-MM-dd');
      }
    });
   
    form1.mortgage_id = form1.mortgage_id ? form1.mortgage_id : ''
    form1.pledge_id = form1.pledge_id ? form1.pledge_id : ''
    form1.guarantor_id = form1.guarantor_id ? form1.guarantor_id : ''
    const excludes = ['Credit1Flows', 'cash_deposit_amount', 'createdAt', 'updatedAt', 'checkOpen', 'role_name', 'status', 'id', 'Uploads', 'select_customer', 'tableData', 'save', 'DialogTitle', 'status2', 'open', 'User', 'Role', 'Status', 'Organization', 'UserId', 'OrganizationId', 'StatusId', 'RoleId'];
    const formData = new FormData();
    for (let name in form1) {
      if ((!excludes.includes(name)) && form1[name] && form1[name] !== 'null') formData.append(name, form1[name]);
    }
    // POST
    form.auth = form1.auth
    form.status_id = data.status_id
    form.file1_obj_id = form1.file1_obj_id
    form.mx_amount = form1.mx_amount//.replaceAll(",","")
    form.guaranty_way = form1.guaranty_way
    form.mortgage_id = form1.mortgage_id
    form.pledge_id = form1.pledge_id
    form.guarantor_id = form1.guarantor_id
    form.enterprise_name = form1.enterprise_name
    form.enterprise_no = form1.enterprise_no
    form.select_customer = form1.select_customer
    form.credit_due_date = form1.credit_due_date
    form.credit_approval_id = form1.credit_approval_id//form3上传文件id
    form.licences_copies_id = form1.licences_copies_id
    form.account_permit_copies_id = form1.account_permit_copies_id
    form.other_business_license_id = form1.other_business_license_id
    form.constitution_id = form1.constitution_id
    form.capital_verification_report_id = form1.capital_verification_report_id
    form.legal_idcard_and_resume_id = form1.legal_idcard_and_resume_id
    form.Introduction_id = form1.Introduction_id
    form.audit_reports_and_statements_id = form1.audit_reports_and_statements_id
    form.tax_return_id = form1.tax_return_id
    form.business_registration_info_id = form1.business_registration_info_id
    form.enterprise_credit_report_id = form1.enterprise_credit_report_id
    form.contracts_and_invoices_id = form1.contracts_and_invoices_id
    form.other_supplely_info_id = form1.other_supplely_info_id
    form.file_obj_id = form1.file_obj_id
    form.file1_obj_id = form1.file1_obj_id //from5 文件上传
    form.quota = form1.quota
    fetching(`/msapi/credit1/save/${ids}`, {
      method,
      body: JSON.stringify(form)// formData, //
    }, 2).then((data) => {
      this.handleLoading(false)
      try {
        if (data !== undefined && data !== null) {
          if (data.msg) {
            alert(`${ data.status_id === 3?'额度申请提交失败':'额度申请保存失败'},data.msg`)
            return;
          }
          this.setState({ submitHint: '提交成功' })
          alert('提交成功')
          this.props.onSubmit({
            ...form,
          })
        }else {
          alert('提交失败')
        }
      } catch (error) {
        console.log('error')
        // alert(`${ data.status_id === 3?'额度申请提交失败':'额度申请保存失败'}`)
      }

    })
  }

  //审核意见提交
  FormSubmit3 = (data) => {
    this.props.onSubmit3({
      ...data,
    })
  }
  //担保信息
  FormSubmit1 = (data) => {
    const { guarantInfo1, guarantInfo2, guarantInfo3 } = this.state;
    if (data.guarantInfo1) {
      this.setState({ guarantInfo1: { ...guarantInfo1, ...data.guarantInfo1, } })
    } else if (data.guarantInfo2) {
      this.setState({ guarantInfo2: { ...guarantInfo2, ...data.guarantInfo2, } })
    } else if (data.guarantInfo3) {
      this.setState({ guarantInfo3: { ...guarantInfo3, ...data.guarantInfo3, } })
    }

  }

  handleLoading = (val) => {
    this.setState({ loading: val })
  }

  render() {
    const { classes, auth, table, dataUrl } = this.props;
    let { loading, activeStep, steps, form, formStatic, exercise = {}, checkExercise, uploads, guarantInfo1, guarantInfo2, guarantInfo3, submitHint } = this.state;
    const getStepContent = (stepIndex) => {
      switch (stepIndex) {
        case 0:
          return <Form1
            activeStep={activeStep}
            steps={steps}
            form={form}
            auth={auth}
            guarantInfo1={guarantInfo1}
            guarantInfo2={guarantInfo2}
            guarantInfo3={guarantInfo3}
            exercise={exercise}
            formStatic={formStatic}
            loading={loading}
            handleLoading={this.handleLoading}
            FormSubmit1={this.FormSubmit1}
            handleSave={this.handleSave}
            handleNext={this.handleNext}
            handleBack={this.handleBack}>
          </Form1>;
        case 1:
          return <Form2
            form={form}
            exercise={exercise}
            formStatic={formStatic}
            activeStep={activeStep}
            steps={steps}
            loading={loading}
            handleLoading={this.handleLoading}
            handleSave={this.handleSave}
            handleNext={this.handleNext}
            handleBack={this.handleBack}>
          </Form2>;
        case 2:
          return <Form3
            form={form}
            auth={auth}
            exercise={exercise}
            formStatic={formStatic}
            activeStep={activeStep}
            steps={steps}
            uploads={uploads}
            loading={loading}
            handleLoading={this.handleLoading}
            handleSave={this.handleSave}
            handleNext={this.handleNext}
            handleBack={this.handleBack}>
          </Form3>;
        case 3:
          return <Form4
            form={form}
            exercise={exercise}
            formStatic={formStatic}
            activeStep={activeStep}
            steps={steps}
            loading={loading}
            handleLoading={this.handleLoading}
            handleSave={this.handleSave}
            handleNext={this.handleNext}
            handleBack={this.handleBack}>
          </Form4>;
        case 4:
          return <Form5
            form={form}
            auth={auth}
            exercise={exercise}
            formStatic={formStatic}
            activeStep={activeStep}
            steps={steps}
            uploads={uploads}
            loading={loading}
            handleLoading={this.handleLoading}
            handleSubmit1={this.handleSubmit1}
            handleSave={this.handleSave}
            handleNext={this.handleNext}
            handleBack={this.handleBack}>
          </Form5>;
        default:
          return '';
      }
    }
    const checkform = () => {
      return <CheckForm dataUrl={dataUrl} auth={auth} exercise={{ ...checkExercise, table }}
        onSubmit={this.FormSubmit3}
      />
    }
    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions} component="h6" variant="h6" align="center">{submitHint}</Typography>
            </div>
          ) : (
              <div>
                {getStepContent(activeStep)}
              </div>
            )}
        </div>
        {(checkExercise.save !== '2') &&
          <div>
            {checkform()}
          </div>}
      </div>
    );
  }

}
export default withStyles(useStyles)(ApplyIndex)
