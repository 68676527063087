const roleName = (role_id, roleList) => {
    let roleName1 = roleList.filter((item) => {
        return (item.id === Number(role_id)) ? item : null;
    })
    return roleName1.length > 0 ? roleName1[0].name : '';
}
export const getProcess = (role_id, table, roleList) => {
    let role_name = '';
    let return_role_name = '';
    if (table === 'r5-2') {
        switch (role_id) {
            case 1:
                role_name = roleName(3, roleList);
                break;
            case 3:
                role_name = roleName(8, roleList);
                return_role_name = roleName(1, roleList);
                break;
            case 8:
                role_name = '已完成';
                return_role_name = roleName(3, roleList);
                break;
            default: role_name = '已完成';
        }
        return { role_name, return_role_name }
    }
    if ( table === 'r5-3') {
        switch (role_id) {
            case 1:
                role_name = roleName(3, roleList);
                break;
            case 3:
                role_name = '已完成';
                return_role_name = roleName(1, roleList);
                break;
            default: role_name = '已完成';
        }
        return { role_name, return_role_name }
    }
}
//表格公共接口列表
export const core_business_url = (auth, table) => {
    if (!auth.id) return [];
    else {
        const { roles, organization } = auth;

        let role_id = roles[0].id || '';
        let organization_name = organization.name;
        let status_id = '3';
        //审核
        if (table === 'r6-3-1' ) {
            switch (role_id) {
                case 1://项目经理 2021-04-20
                if (table === 'r6-3-1'){
                    role_id = 16;
                    } else role_id = 0;
                    break;
                case 2://业务负责人
                     role_id = 1;
                     break;
                case 3://风险经理
                    if(organization_name.includes("汇通")){
                        role_id = 2;
                    }else{
                        role_id = 1;
                    }
                    break;
                case 15://法务
                     role_id = 3;
                     break;
                //case 3://风险经理
                //    role_id = 1;
                //    break;
                case 8://总经理
                    if(organization_name.includes("汇通")){
                        role_id = 15;
                    }else{
                        role_id = 3;
                    }
                    break;
                case 14: //董事长
                    if (table === 'r6-3-1' || table === 'r14-6-1') role_id = 8;
                    else role_id = 0;
                    break;
                case 9://综合部
                    if (table === 'r6-3-1') {
                        status_id = '10';
                        role_id = 12;
                    }else if (table === 'r14-6-1') {
                        status_id = '3';
                        role_id = 11;
                    }else role_id = 8;
                    break;
                case 5://财务经办人
                    role_id = 9;
                    status_id = '10';
                    break;
                case 6://财务负责人
                    role_id = 5;
                    status_id = '10';
                    break;
                case 16: //融资经办人 2021-04-20
                    if (table === 'r6-3-1'){
                        role_id = 12;
                        status_id = '13';
                        } else role_id = 0;
                    break;
                default: ;
            }
        }else if(table === 'r14-6-1' ){//应收账款审核
            switch (role_id) {
                case 1://项目经理 2021-04-20
                if (table === 'r6-3-1'){
                    role_id = 12;
                    } else role_id = 0;
                    break;
                case 3://风险经理
                    role_id = 1;
                    break;
                case 8://总经理
                    role_id = 3;
                    break;
                case 14: //董事长
                     role_id = 8;
                    break;
                case 9://综合部
                    if (table === 'r6-3-1') {
                        status_id = '10';
                        role_id = 12;
                    }else if (table === 'r14-6-1') {
                        status_id = '3';
                        role_id = 11;
                    }else role_id = 8;
                    break;
                case 5://财务经办人
                    role_id = 9;
                    status_id = '10';
                    break;
                case 6://财务负责人
                    role_id = 5;
                    status_id = '10';
                    break;
                case 16: //融资经办人 2021-04-20
                    if (table === 'r6-3-1'){
                        role_id = 12;
                        status_id = '13';
                        } else role_id = 0;
                    break;
                default: ;
            }
        }else if( table === 'r6-4-1' ){
            switch (role_id) {
                case 1://项目经理 2021-04-20
                 role_id = 2;
                    break;
                case 3://风险经理
                    role_id = 1;
                    break;
                // case 4://风险负责人
                //     role_id = 3;
                //     break;
                // case 15://法务
                //     role_id = 4;
                //     break;
                case 8://总经理
                    role_id = 3;
                    break;
                case 14: //董事长
                    if (table === 'r6-3-1' || table === 'r14-6-1') role_id = 8;
                    else role_id = 0;
                    break;
                case 9://综合部
                    if (table === 'r6-3-1') {
                        status_id = '10';
                        role_id = 12;
                    }else if (table === 'r14-6-1') {
                        status_id = '3';
                        role_id = 11;
                    }else role_id = 8;
                    break;
                case 5://财务经办人
                    role_id = 9;
                    status_id = '10';
                    break;
                case 6://财务负责人
                    role_id = 5;
                    status_id = '10';
                    break;
                case 16: //融资经办人 2021-04-20
                    if (table === 'r6-3-1'){
                        role_id = 12;
                        status_id = '13';
                        } else role_id = 0;
                    break;
                default: ;
            }
        }else if (table === 'r6-2-1') { //融单开立审核
            switch (role_id) {
                case 1://项目经理 2021-04-20
                if (table === 'r6-3-1'){
                    role_id = 16;
                    } else role_id = 0;
                    break;
                // case 2://业务负责人
                //     role_id = 1;
                //     break;
                case 3://风险经理
                    role_id = 1;
                    break;
                // case 4://风险负责人
                //     role_id = 3;
                //     break;
                // case 15://法务
                //     role_id = 4;
                //     break;
                case 8://总经理
                    role_id = 3;
                    break;
                case 14: //董事长
                    if (table === 'r6-3-1' || table === 'r14-6-1') role_id = 8;
                    else role_id = 0;
                    break;
                case 9://综合部
                    if (table === 'r6-3-1') {
                        status_id = '10';
                        role_id = 12;
                    }else if (table === 'r14-6-1') {
                        status_id = '3';
                        role_id = 11;
                    }else role_id = 8;
                    break;
                case 5://财务经办人
                    role_id = 9;
                    status_id = '10';
                    break;
                case 6://财务负责人
                    role_id = 5;
                    status_id = '10';
                    break;
                case 16: //融资经办人 2021-04-20
                    if (table === 'r6-3-1'){
                        role_id = 12;
                        status_id = '13';
                        } else role_id = 0;
                    break;
                default: ;
            }
        }
        //退回
        else if ( table === 'r6-3-6' ) {
            switch (role_id) {
                case 1: //业务经理
                    if(organization_name.includes("汇通")){
                        role_id = 2;
                    }else{
                        role_id = 3;
                    }
                    break;
                case 2://业务负责人
                    role_id = 3;
                    break;
                case 3://风险经理
                    if(organization_name.includes("汇通")){
                        role_id = 15;
                    }else{
                        role_id = 8;
                    }
                    break;
                // case 4://风险负责人
                //     role_id = 15;
                //     break;
                case 15://法务
                    role_id = 8;
                    break;
                case 16: //融资经办人 2021-04-20
                    role_id = 1;
                    break;
                case 8://总经理
                    if (table === 'r6-3-6') role_id = 14
                    else role_id = 9
                    break;
               case 14://董事长
                    if (table === 'r6-3-6') role_id = 11
                    break;
                default: ;
            }
        }
        else if ( table === 'r6-4-8') {
            switch (role_id) {
                case 1: //业务经理
                    role_id = 3;
                    break;
                // case 2://业务负责人
                //     role_id = 3;
                //     break;
                case 3://风险经理
                    role_id = 8;
                    break;
                case 4://风险负责人
                    role_id = 15;
                    break;
                case 15://法务
                    role_id = 8;
                    break;
                case 16: //融资经办人 2021-04-20
                    role_id = 1;
                    break;
                case 8://总经理
                    if (table === 'r6-3-6') role_id = 14
                    else role_id = 9
                    break;
                default: ;
            }
        }
        else if (table === 'r6-2-4' ) {//已退回融单
            switch (role_id) {
                case 1: //业务经理
                    role_id = 3;
                    break;
                // case 2://业务负责人
                //     role_id = 3;
                //     break;
                case 3://风险经理
                    role_id = 8;
                    break;
                // case 4://风险负责人
                //     role_id = 15;
                //     break;
                // case 15://法务
                //     role_id = 8;
                //     break;
                case 16: //融资经办人 2021-04-20
                    role_id = 1;
                    break;
                case 8://总经理
                    if (table === 'r6-3-6') role_id = 14
                    else role_id = 9
                    break;
                default: ;
            }
        }
        else if (table === 'r14-6-4') {
            switch (role_id) {
                case 1: //业务经理
                    role_id = 3;
                    break;
                // case 2://业务负责人
                //     role_id = 3;
                //     break;
                case 3://风险经理
                    role_id = 8;
                    break;
                // case 4://风险负责人
                //     role_id = 15;
                //     break;
                // case 15://法务
                //     role_id = 8;
                //     break;
                case 8://总经理
                    if (table === 'r14-6-4') role_id = 14
                    else role_id = 9
                    break;
	case 14://董事长
                    if (table === 'r14-6-4') role_id = 11
                    break;
                default: ;
            }
        }

        return [
            {
                name: "r1-1",
                title: '口令更改',
                url: { query: '/msapi/user/find?active=1', get: '/msapi/user/get/' ,delete:'/msapi/user/del/'}
            },
            {
                name: "r1-2",
                title: '用户信息',
                url: { query: '/msapi/user/find?active=1', get: '/msapi/user/get/',delete:'/msapi/user/del/' }
            },
            {
                name: "r1-3",
                title: '角色查询',
                url: { query: '/msapi/roles/find' }
            },
            {
                name: "r1-4",
                title: '短信查询',
                url: { query: '/api/sms/content' }
            },
            {
                name: "r1-5",
                title: 'IP白名单管理',
                url: { query: '/msapi/user/getIpWhiteList' }
            },
            {
                name: "r1-6",
                title: '访问限制管理',
                url: { query: '/msapi/user/getIpWhiteRule' }
            },
            {
                name: "r2-1",
                title: '查询',
                url: { query: '' }
            },
            {
                name: "r2-3-1",
                url: { query: '' },
                title: '客户类型变更'
            },
            {
                name: "r2-3-2",
                url: { query: '' },
                title: '财务报表识别'
            },
            {
                name: "r3-1",
                url: { query: '' },
                title: '查询'
            },
            {
                name: "r5-1",
                url: {
                    //query: '/smrd/business_management/getBMList',
                    query: '/smrd/business_management/find',
                    query1: `/smrd/business_management/find?status_id=2&role_id=1`,
                    query3: `/smrd/business_management/find?status_id=9`,
                    query4: '/smrd/business_management/find/',
                    get: '/smrd/business_management/del/',
                    get2: '/smrd/bm_flow/find?bm_id=',
                    get3: '/smrd/bm_flow/save'
                },
                title: '企业管理'
            },
            {
                name: "r5-2",
                url: {
                    query: '/smrd/quota_allocation/find',
                    query1: `/smrd/quota_allocation/find_get?status_id=2&role_id=1`,
                    query2: `/smrd/quota_allocation/find_get?status_id=3&role_id=3`,
                    query3: `/smrd/quota_allocation/find_get?status_id=9`,

                    query5: `/smrd/quota_allocation/find_get?status_id=7&role_id=8`,
                    get: '/smrd/quota_allocation/del/',
                    get2: '/smrd/qa_flow/find?qa_id=',
                    get3: '/smrd/qa_flow/save'
                },
                title: '额度分配'
            },
            {
                name: "r5-3",
                url: {
                    query: '/smrd/quota_recycle/find',
                    query1: `/smrd/quota_recycle/find?status_id=2&role_id=1`,
                    query2: `/smrd/quota_recycle/find?status_id=3&role_id=3`,
                    query3: `/smrd/quota_recycle/find?status_id=9`,
                    query4: `/smrd/quota_recycle/del/`,

                    query5: `/smrd/quota_recycle/find?status_id=7&role_id=8`,
                    get: '/smrd/quota_recycle/find',
                    get2: '/smrd/qr_flow/find?qr_id=',
                    get3: '/smrd/qr_flow/save'
                },
                title: '额度回收'
            },
            {
                name: "r5-4",
                url: { query: '/amount/usedAmount/GetTotalAmount/', get: '', get2: '', get3: '' },
                title: '额度查询'
            },
            {
                name: "r5-5",
                url: { query: '/amount/usedAmount/GetHisTotalAmount/', get: '', get2: '', get3: '' },
                title: '历史额度查询'
            },
	    {
                name: "r5-6",
                url: { query: '/amount/usedAmount/getAmounts/' },
                title: '操作明细'
            },
            {
                name: "r6-1",
                url: {
                    query: '/msapi/issue/find/',
                    query2: '/msapi/financing/get/',
                    query3: '/msapi/circulation/get/',
                    query4: '/msapi/issue/getIssues',

                },
                title: '全部融单'
            },
            {
                name: "r6-2-1",
                url: {
                    query: `/msapi/issue/getIssueCheckYW?status_id=3&role_id=${role_id}`,
                    query1: `/msapi/issue/getIssueCheckYW?status_id=3&role_id=12`,
                    // query2: `/api/issue/find?status_id=3&role_id=13`,
                    get: '/msapi/flow/find',
                    get2: '/msapi/issue/get/'
                },
                title: '融单开立审核'
            },
            {
                name: "r6-2-2",
                url: { 
                    query: `/msapi/issue/getIssueView?status_id=3,4,8`,
                    get: '/msapi/flow/find',
                    get2: '/msapi/issue/get/' },
                title: '开立融单查看'
            },
            {
                name: "r6-2-3",
                url: { 
                    query: `/msapi/issue/find?status_id=5`, 
                    get2: '/msapi/issue/get/' ,
                    query4: `/msapi/issue/getIssues?status_id=5`, },
                title: '已开融单'
            },
            {
                name: "r6-2-4",
                url: {
                    query: `/msapi/issue/find?status_id=7&role_id=${role_id}`,
                    get: '/msapi/flow/find/',
                    get2: '/msapi/issue/get/'
                },
                title: '已退回融单'
            },
            {
                name: "r6-2-5",
                url: { query: '' },
                title: '批量审核结果'
            },
            {//开立拒签业务板块功能--20201120
                name: "r6-2-7",
                url: {
                    query: `/msapi/issue/find/?status_id=6`,
                    get: '/msapi/flow/find/',
                    get2: '/msapi/issue/get/'
                },
                title: '已拒签融单'
            },
            {//开立删除业务板块功能--20240715
                name: "r6-2-8",
                url: {
                    query: `/msapi/issue/find/?status_id=12`,
                    get: '/msapi/flow/find/',
                    get2: '/msapi/issue/get/'
                },
                title: '已删除融单'
            },
            {
                name: "r6-2-9",
                url: { 
                    query: `/msapi/issue/find?status_id=7&role_id=1`,
                    get: '/msapi/flow/find',
                    get2: '/msapi/issue/get/' },
                title: '已退回复核员融单'
            },
            {
                name: "r6-3-1",
                url: {
                    //query: `/msapi/issue/getFinancingsCheck?status_id=${status_id}&role_id=${role_id}`,
                    query: `/msapi/financing/getFinancingCheckYW?status_id=${status_id}&role_id=${role_id}&organization_name=${organization_name}`,
                    query1: `/msapi/financing/getFinancingCheckYW?status_id=${status_id}&role_id=12&organization_name=${organization_name}`,
                    // query2: `/api/financing/find?status_id=${status_id}&role_id=13`,
                    query2: `/msapi/financing/getFinancingCheckYW?status_id=13&role_id=16&organization_name=${organization_name}`,
                    get: '/msapi/fflow/find',
                    get2: '/msapi/financing/get/'
                },
                title: '融资申请审核'
            },
            {
                name: "r6-3-2",
                url: { query: '' },
                title: '询价记录'
            },
            {
                name: "r6-3-3",
                url: {
                    query: '/msapi/financing/find?status_id=3',
                    get2: '/msapi/financing/get/'
                },
                title: '融资申请中'
            },
            {
                name: "r6-3-4",
                url: {
                    query: `/msapi/financing/getFinancingView?status_id=3,4,5,8,9,10,13,14&organization_name=${organization_name}`,
                    get2: '/msapi/financing/get/',
                },
                title: '融资查看'
            },
            {
                name: "r6-3-5",
                url: { query: '' },
                title: '放款异常'
            },
            {
                name: "r6-3-6",
                url: {
                    query: `/msapi/financing/find?status_id=7&role_id=${role_id}&organization_name=${organization_name}`,
                    get: '/msapi/fflow/find/',
                    get2: '/msapi/financing/get/'
                },
                title: '已退回融资'
            },
            {
                name: "r6-3-7",
                url: { 
                    query: '/msapi/financing/display', 
                    get2: '/msapi/financing/get/',
                    query4: `/msapi/issue/getFinancings?organization_name=${organization_name}`,
                },
                title: '已融资融单'
            },
            {//融资退回经办人的业务板块功能--20201030
                name: "r6-3-8",
                url: {
                    query: `/msapi/financing/find?status_id=7&role_id=12&organization_name=${organization_name}`,
                    get: '/msapi/fflow/find/',
                    get2: '/msapi/financing/get/'
                },
                title: '已退回经办人融资'
            },
            {//融资拒签业务板块功能--20201120
                name: "r6-3-9",
                url: {
                    query: `/msapi/financing/find?status_id=6&organization_name=${organization_name}`,
                    get: '/msapi/fflow/find/',
                    get2: '/msapi/financing/get/'
                },
                title: '已拒签融资'
            },
            {//融资已删除业务板块功能--20240628
                name: "r6-3-14",
                url: {
                    query: `/msapi/financing/find?status_id=12&organization_name=${organization_name}`,
                    get: '/msapi/fflow/find/',
                    get2: '/msapi/financing/get/'
                },
                title: '已删除融资'
            },
            {
                name: "r6-3-15",
                url: { 
                    query: `/msapi/financing/find?status_id=7&role_id=16&organization_name=${organization_name}`,
                    get: '/msapi/fflow/find/',
                    get2: '/msapi/financing/get/'
                 },
                title: '已退回复核员融资'
            },
            {
                name: "r6-3-10",
                url: { query: `/loan/getLoanYWDetails?enterpriseNo=`},
                title: '融资放款明细'
            },
            {
                name: "r6-3-11",
                url: { query: `/loan/getRepaymentDetails?enterpriseNo=`},
                title: '融资回款明细'
            },
	        {
                name: "r6-3-12",
                url: { query: `/msapi/financing/getSumAmortization`},
                title: '利息摊销计算结果'
            },
            {
                name: "r6-3-13",
                url: { query: `/msapi/financing/getRateList`, 
                       query1:'/msapi/financing/getRate/', 
                       delete:'/msapi/financing/del/'
                },
                title: '其他保理利率维护'
            },
            {//流转退回经办人的业务板块功能--20201030
                name: "r6-4-9",
                url: {
                    query: `/msapi/circulation/find?status_id=7&role_id=12`,
                    get: '/msapi/cflow/find/',
                    get2: '/msapi/circulation/get/'
                },
                title: '已退回经办人流转'
            },
            {//开立退回经办人的业务板块功能--20201030
                name: "r6-2-6",
                url: {
                    query: `/msapi/issue/find?status_id=7&role_id=12`,
                    get: '/msapi/flow/find/',
                    get2: '/msapi/issue/get/'
                },
                title: '已退回经办人融单'
            },
            {
                name: "r6-4-1",
                url: {
                    query: `/msapi/circulation/getCirculationCheckYW?status_id=3&role_id=${role_id}`,
                    query1: `/msapi/circulation/getCirculationCheckYW?status_id=3&role_id=12`,
                    // query2: `/api/circulation/find?status_id=3&role_id=13`,
                    get: '/msapi/cflow/find',
                    get2: '/msapi/circulation/get/'
                },
                title: '融单流转审核'
            },

            {
                name: "r6-4-2",
                url: {
                    query: `/msapi/issue/find?receive_credit_code=all&status_id=5`,
                    query2: `/msapi/circulation/find?receive_credit_code=all&status_id=5`,
                    get: '/msapi/issue/get/',
                    get2: '/msapi/circulation/get/',
                    query4: `/msapi/issue/getIssues?status_id=5`,
                    query5: `/msapi/issue/getCirculations?status_id=5`,
                },
                title: '可用融单'
            },
            {
                name: "r6-4-3",
                url: { 
                    query: '/msapi/circulation/getCirculationPaying?status_id=3,4', 
                    get2: '/msapi/circulation/get/' },
                title: '支付融单查看'
            },
            {
                name: "r6-4-5",
                url: { 
                    query: '/msapi/circulation/find?status_id=5', 
                    get2: '/msapi/circulation/get/' ,
                    query4: `/msapi/issue/getCirculations?status_id=5`,
                },
                title: '已支付融单'
            },
            {
                name: "r6-4-6",
                url: { 
                    query: `/msapi/issue/findall/payment_day_expired`, 
                    get2: '/msapi/issue/get/',
                    query4: `/msapi/issue/getIssues?status_id=5`, 
                 },
                title: '已到期融单'
            },
            {
                name: "r6-4-7",
                url: {
                    query: `/msapi/issue/find?status_id=5`,
                    query2: `/msapi/circulation/find?status_id=5`,
                    get: '/msapi/issue/get/',
                    get2: '/msapi/circulation/get/',
                    query4: `/msapi/issue/getIssues?status_id=5`,
                    query5: `/msapi/issue/getCirculations?status_id=5`,
                },
                title: '原始融单'
            },
            {
                name: "r6-4-8",
                url: {
                    query: `/msapi/circulation/find?status_id=7&role_id=${role_id}`,
                    get: '/msapi/cflow/find/',
                    get2: '/msapi/circulation/get/'
                },
                title: '已退回流转'
            },
            {//流转拒签业务板块功能--20201120
                name: "r6-4-10",
                url: {
                    query: `/msapi/circulation/find?status_id=6`,
                    get: '/msapi/cflow/find/',
                    get2: '/msapi/circulation/get/'
                },
                title: '已拒签流转'
            },
            {//流转已删除业务板块功能--20240628
                name: "r6-4-11",
                url: {
                    query: `/msapi/circulation/find?status_id=12`,
                    get: '/msapi/cflow/find/',
                    get2: '/msapi/circulation/get/'
                },
                title: '已删除流转'
            },
                        {
                name: "r6-4-12",
                url: {
                    query: `/msapi/circulation/find?status_id=7&role_id=1`,
                    get: '/msapi/cflow/find/',
                    get2: '/msapi/circulation/get/'
                },
                title: '已退回复核员流转'
            },
            {
                name: "r6-5-1",
                url: {
                    query: `/cmb/cmbFinancingList`},
                title: '招行融资查看'
            },
            {
                name: "r6-5-2",
                url: {
                    query: `/cmb/cmbFinishFinancingList`},
                title: '招行已完成融资'
            },
            {
                name: "r8-1",
                url: { 
                    query: `/repayment/getRepaymentList?is_westcol_done=0,3&type=`,//20201218
                },
                title: '待还款'
            },
            {
                name: "r8-2",
                url: { 
                    query: `/repayment/getRepaymentList?is_westcol_done=4&type=`,//20201222 
                },
                title: '已还款'
            },
            {
                name: "r8-3",
                url: {
                    //query: `/repayment/getRepaymentList?is_westcol_done=6&type=`,//20201222
                    query: `/repayment/getRepaymentListByDateType?is_westcol_done=6&type=`,//20201222
                },
                title: '额度已恢复'
            },
            {
                name: "r8-4",
                //url: { query: '/api/issue/display15' }, //2021.1.11
                url: { query: '/repayment/display15New' },

                title: '还款提醒'
            },
            {
                name: "r8-5",
                url: { query: '/contract/getHoldAtMaturity' },
                title: '融单到期持有'
            },
            {
                name: "r8-6",
                url: {
                    //query: `/repayment/getRepaymentList?is_westcol_done=7&type=`,//20201222
                    query: `/repayment/getRepaymentListByDateType?is_westcol_done=7&type=`,//20201222
                },
                title: '额度不可恢复'
            },
            {
                name: "r8-7",
                url: { query: '/loan/getRepaymentDetailsAll?enterpriseNo=' },
                title: '还款明细'
            },
            {
                name: "r8-8",
                url: { query: '/repayment/getRepaymentList?is_westcol_done=8&type=' },
                title: '还款复核'
            },
            {
                name: "r9-1-1",
                url: { query: '' },
                title: '融单待清算'
            },
            {
                name: "r9-1-2",
                url: { query: '' },
                title: '融单清算中'
            },
            {
                name: "r9-1-3",
                url: { query: '' },
                title: '融单已清算'
            },
            {
                name: "r9-2-1",
                url: { query: '/da/getIssExtraction' },
                title: '核心企业明细'
            },
            {
                name: "r9-2-2",
                url: { query: '/da/getIssCirReceiver' },
                title: '供应商交易统计'
            },
            {
                name: "r9-5",
                url: { query: '/contract/findTAW' },///contract/findTAW?selectedStartDate=${selectedStartDate}&selectedEndDate=${selectedEndDate}
                title: '开立到融资时间统计'
            },
           {
              name: "r9-6",
              url: { query: '/msapi/risk/getMonthlyRiskGovernance' },
              title: '月度风险监测指标测算'
          },
            {
                name: "r9-4",
                url: { 
                    query: '/contract/getIssuesCountAndFund',
                },
                title: '核心企业签发对比'
            },
            {
                name: "r10-1",
                url: { query: '' },
                title: '代办任务列表'
            },
            {
                name: "r10-2",
                url: { query: '' },
                title: '本人完成列表'
            },
            {
                name: "r10-3",
                url: { query: '' },
                title: '全部结束任务'
            },
            {
                name: "r11-2",
                url: { query: '/mxbl/contract/' },
                title: '权限'
            },
            {
                name: "r12-1",
                url: { query: `/msapi/user/find?active=1&organization_id=${auth.organization.id}`, get: '/msapi/user/get/',delete:'/msapi/user/del/' },
                title: '用户管理'
            },
            {
                name: "r14-1-1",
                url: { query: ``, },
                title: '贸易合同信息查询'
            },
            {
                name: "r14-2-1",
                url: { query: ``, },
                title: '发票查询'
            },
            {
                name: "r14-3-1",
                url: { query: ``, },
                title: '应收帐回款查询'
            },
            {
                name: "r14-4-1",
                url: { query: ``, },
                title: '应收账款池查询'
            },
            {
                name: "r14-5-1",
                url: { query: `/sl/slFindReceivable?&roleId=${role_id}`, 
                        get2: `/sl/slFindReceivableById`, 
                     },
                title: '数链应收账款审核'
            },
            {
                name: "r14-5-11",
                url: { query: `/sl/slFindAuditingReceivable`, },
                title: '数链应收账款查看'
            },
            {
                name: "r14-5-2",
                url: { query: `/sl/slFindFinancing?&roleId=${role_id}`, },
                title: '数链融资审核'
            },
            {
                name: "r14-5-12",
                url: { query: `/sl/slFindAuditingFinancing`, },
                title: '数链融资查看'
            },
            {
                name: "r14-5-3",
                url: { query: `/sl/slFindRepayment?&roleId=${role_id}`, },
                title: '数链还款审核'
            },
            {
                name: "r14-5-4",
                url: { query: `/sl/slFindReceivable?&roleId=99`, },
                title: '已完成数链应收账款'
            },
            {
                name: "r14-5-5",
                url: { query: `/sl/slFindFinancing?&roleId=99`, },
                title: '已完成数链融资'
            },
            {
                name: "r14-5-6",
                url: { query: `/sl/slFindRepayment?&roleId=99`, },
                title: '已完成数链还款'
            },
            {
                name: "r14-5-7",
                url: { query: `/sl/slFindReceivable?&roleId=100`, },
                title: '已退回数链应收账款'
            },
            {
                name: "r14-5-8",
                url: { query: `/sl/slFindFinancing?&roleId=100`, },
                title: '已退回数链融资'
            },
            {
                name: "r14-5-9",
                url: { query: `/sl/slFindRepayment?&roleId=100`, },
                title: '已退回数链还款'
            },
            {
                name: "r14-5-10",
                url: { query: `/sl/slFindRepayment?&roleId=101`, },
                title: '已作废数链还款'
            },
            {
                name: "r14-6-1",
                url: {
                    query: `/msapi/receivable/find?status_id=${status_id}&role_id=${role_id}`,
                    query1: `/msapi/receivable/find?status_id=3&role_id=12`,
                    // query2: `/api/receivable/find?status_id=3&role_id=13`,
                    get: '/msapi/rflow/find',
                    get2: '/msapi/receivable/get/'
                },
                title: '应收账款审核'
            },
            {
                name: "r14-6-2",
                url: {
                    query: `/msapi/receivable/getReceivableView?status_id=3,4,5,8,9,10,13`,
                    query1: `/msapi/receivable/find?status_id=3&role_id=12`,
                    // query2: `/api/receivable/find?status_id=3&role_id=13`,
                    get: '/msapi/rflow/find',
                    get2: '/msapi/receivable/get/'
                },
                title: '应收账款查看'
            },
            {
                name: "r14-6-3",
                url: {
                    query: `/msapi/receivable/find?status_id=11`,
                    query1: `/msapi/receivable/find?status_id=3&role_id=12`,
                    // query2: `/api/receivable/find?status_id=3&role_id=13`,
                    get: '/msapi/rflow/find',
                    get2: '/msapi/receivable/get/'
                },
                title: '已完成应收账款'
            },
            {
                name: "r14-6-4",
                url: {
                    query: `/msapi/receivable/find?status_id=7&role_id=${role_id}`,
                    query1: `/msapi/receivable/find?status_id=3&role_id=12`,
                    // query2: `/api/receivable/find?status_id=3&role_id=13`,
                    get: '/msapi/rflow/find',
                    get2: '/msapi/receivable/get/'
                },
                title: '已退回应收账款'
            },
            {
                name: "r14-7-1",
                url: {
                    query: `/msapi/nsreceivable/find?status_id=9`,
                    get2: `/msapi/nsreceivable/get/`,
                },
                title: '应收账款业务登记'
            },
            {
                name: "r15-1",
                url: {
                    query: `/smrd/interest_rate/find`,
                    query1: `/smrd/interest_rate/find?status_id=2&role_id=5`,
                    query2: `/smrd/interest_rate/find?status_id=3&role_id=6`,
                    query3: `/smrd/interest_rate/find?status_id=9`,

                    query5: `/smrd/interest_rate/find?status_id=7&role_id=8`,
                    get: `/smrd/interest_rate/del/`,
                    get2: `/smrd/rflow/find?irid=`,
                    get3: `/smrd/rflow/save`
                },
                title: '利率维护'
            },
	        {
                name: "r15-2",
                url: {
                    query: `/smrd/repayment_rate/find`,
                    query1: `/smrd/repayment_rate/find?status_id=2&role_id=5`,
                    query2: `/smrd/repayment_rate/find?status_id=3&role_id=1`,
                    query3: `/smrd/repayment_rate/find?status_id=9`,
                    query5: `/smrd/repayment_rate/find?status_id=7&role_id=8`,
                    get: `/smrd/repayment_rate/del/`,
                    get2: `/smrd/pflow/find?irid=`,
                    get3: `/smrd/pflow/save`
                },
                title: '利息'
            },
            {
                name: "r16-1",
                url: {
                    query: `/smrd/sqsp/find?category=融资`,
                    get: `/smrd/sqsp/del/`,
                },
                title: '融资授权'
            },
            {
                name: "r16-2",
                url: {
                    query: `/smrd/sqsp/find?category=开立`,
                    get: `/smrd/sqsp/del/`,
                },
                title: '开立授权'
            },
            {
                name: "r16-3",
                url: {
                    query: `/smrd/sqsp/find?category=流转`,
                    get: `/smrd/sqsp/del/`,
                },
                title: '流转授权'
            },
            {
                name: "m6-1",
                url: { query: `/contract/zc` },
                title: '注册合同管理'
            },
            {
                name: "m6-2",
                url: { query: `/contract/issues` },
                title: '开立合同管理'
            },

            {
                name: "m6-3",
                url: { query: `/contract/financings` },
                title: '融资合同管理'
            },
            {
                name: "m6-6",
                url: { query: `/contract/receivables` },
                title: '应收合同管理'
            },
            {
                name: "m6-4",
                url: { query: `/msapi/account/find` },
                title: '银行账号信息'
            },
            {
                name: "m6-5",
                url: { query: `/msapi/account/getAllOrgs` },
                title: '注册客户信息'
            },
            {
                name: "m6-7",
                url: { query: `/msapi/account/getCebAccountInformDto` },
                title: '善美光大银行信息管理'
            },
	        {
                name: "r18-2-1",
                url: {
                    query: `/msapi/czb/czbFinancingList`,
                },
                title: '浙商融资查看'
            },
            {
                name: "r18-2-2",
                url: {
                    query: `/msapi/czb/czbFinishFinancingList`},
                title: '浙商已完成融资'
            },
            {
                name: "r18-3-1",
                url: {
                    query: `/msapi/ccb/czbFinancingList`,
                },
                title: '中信融资查看'
            },
            {
                name: "r18-3-2",
                url: {
                    query: `/msapi/ccb/czbFinishFinancingList`},
                title: '中信已完成融资'
            },
            {
                name: "r18-4-1",
                url: {
                    query: `/msapi/bfe/getNonFactoringList?type=2`,
                },
                title: '北金所非保理融资查看'
            },
            {
                name: "r18-4-2",
                url: {
                    query: `/msapi/bfe/getNonFactoringList?type=1`},
                title: '北金所保理融资查看'
            },
            {
                name: "r18-4-3",
                url: {
                    query: `/msapi/bfe/getFinancedList?status_id=11`,
                },
                title: '北金所已完成融资'
            },
            {
                name: "r18-4-4",
                url: {
                    query: `/msapi/bfe/getFactoringStartList?status_id=11`},
                title: '北金所保理融资发起'
            },
            {
                name: "r18-5-1",
                url: {
                    query: `/msapi/ceb/getFactoringStartList?status_id=11`},
                    get: '/msapi/fflow/find',
                title: '光大保理融资发起'
            },
            {
                name: "r18-5-2",
                url: {
                    query: `/msapi/ceb/getNonFactoringList?type=1`},
                title: '光大保理融资查看'
            },
            {
                name: "r18-5-3",
                url: {
                    query: `/msapi/ceb/getNonFactoringList?type=2`},
                title: '光大非保理融资查看'
            },
            {
                name: "r18-5-4",
                url: {
                    query: `/msapi/ceb/getFinancedList?status_id=11`},
                title: '光大已完成融资'
            },
            {
                name: "r18-5-5",
                url: {
                    query: `/msapi/ceb/getFinancedList?status_id=11&type=1`},
                title: '光大保理已完成融资'
            },
           {
                name: "r18-6-1",
                url: {
                    query: `/msapi/bob/getBobFactoringList`},
                title: '北京银行直融融资查看'
            },
            {
                name: "r18-6-2",
                url: {
                    query: `/msapi/bob/getBobFinancedList?status_id=11`},
                title: '北京银行直融已完成融资'
            },
            {
                name: "r18-7-1",
                url: {
                    query: `/msapi/spd/spdFinancingList`,
                },
                title: '浦发融资查看'
            },
            {
                name: "r18-7-2",
                url: {
                    query: `/msapi/spd/spdFinishFinancingList`},
                title: '浦发已完成融资'
            },
            {
                name: "r18-9-1",
                url: {
                    query: `/msapi/financing/getFinancingView?status_id=3,4,5,8,9,10,13,14&organization_name='汇通商业保理'`},
                title: '汇通融资查看'
            },
            {
                name: "r18-9-2",
                url: {
                    query: `/msapi/issue/getFinancings?organization_name='汇通商业保理'`},
                title: '汇通已完成融资'
            },
            {
                name: "r19-1-1",
                url: {
                    query: `/msapi/financing/getFinancingCheckYW?status_id=15&role_id=16`,
                },
                title: '中信融资预审'
            },
            {
                name: "r19-1-2",
                url: {
                    query: `/msapi/ccb/czbFinishFinancingList`},
                title: '中信已完成融资'
            },
            {
                name: "r19-2-1",
                url: {
                    query: `/msapi/ceb/getFinancingCheckCEB?status_id=15&role_id=16`,
                    get2: '/msapi/financing/get/',
                    get: '/msapi/fflow/find',
                    
                },
                title: '光大融资预审'
            },
            {
                name: "r19-3-1",
                url: {
                    query: `/msapi/bob/getFinancingCheckBOB?status_id=15&role_id=16`,
                    get2: '/msapi/financing/get/',
                    get: '/msapi/fflow/find',
                    
                },
                title: '北京银行融资预审'
            },
            {
                name: "r20-1-1",
                url: {
                    query: `/msapi/system_logs/getSystemLogs`,
                },
                title: '用户登录日志'
            },
            {
                name: "r20-1-2",
                url: {
                    query: `/msapi/system_logs/getSystemLogs`},
                title: '用户信息变更日志'
            },
            {
                name: "r20-2-1",
                url: {
                    query: `/msapi/system_logs/getSystemLogs`,
                },
                title: '用户开立日志'
            },
            {
                name: "r20-2-2",
                url: {
                    query: `/msapi/system_logs/getSystemLogs`},
                title: '用户流转日志'
            },
            {
                name: "r20-2-3",
                url: {
                    query: `/msapi/system_logs/getSystemLogs`},
                title: '用户融资日志'
            },
            {
                name: "r20-2-4",
                url: {
                    query: `/msapi/system_logs/getSystemLogs`},
                title: '用户额度申请日志'
            },
            {
                name: "r20-2-5",
                url: {
                    query: `/msapi/system_logs/getSystemLogs`},
                title: '用户企业关联日志'
            },
            {
                name: "r20-2-6",
                url: {
                    query: `/msapi/system_logs/getSystemLogs`},
                title: '用户额度分配日志'
            },
            {
                name: "r20-2-7",
                url: {
                    query: `/msapi/system_logs/getSystemLogs`},
                title: '用户额度回收日志'
            },
            {
                name: "r20-2-8",
                url: {
                    query: `/msapi/system_logs/getSystemLogs`},
                title: '用户应收账款日志'
            },
            {
                name: "r20-3-1",
                url: {
                    query: `/msapi/system_logs/getSystemLogs`},
                title: '其他日志'
            },
            {
                name: "r21-1-1",
                url: {
                    query: `/msapi/ceb/getFinancingCheckCEB?status_id=15&role_id=16`,
                    get2: '/msapi/financing/get/',
                    get: '/msapi/fflow/find',
                    
                },
                title: '光大融资审核'
            },
        ]
    }

}
