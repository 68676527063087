import getSubRouters from './helper';

export const subRootAry2 = [
  "融资申请",
  "查看利率表",
  "融资复核",
  "融资查看",
  // "询价记录",
  // "融资申请中",
  // "融资查看",
  "已融资融单",
  // "放款异常",
  "已退回融资",
  "融资放款明细",
  "融资时间及费率",
  "下属供应商已融资查看",
];


const PARENT_PATH = '/business/融单流转';
const PATH = '融单融资';

export default getSubRouters(PARENT_PATH, PATH, subRootAry2);

