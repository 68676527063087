import React from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Search as SearchIcon,
} from '@material-ui/icons';
import Accordion from '../components/Accordion'
import subRouters, { SetView } from './common';

const PARENT_PATH = subRouters['额度管理'];

const Info = [
  {
    path: "我的额度",
    icon: SearchIcon,
    component: SetView('md'),
  },
  {
    path: "历史额度",
    icon: SearchIcon,
    component: SetView('mh'),
  },
  // {
  //   path: "额度查询",
  //   icon: SearchIcon,
  //   component: SetView('m1'),
  // },

];

export const Menu1 = () => {
  const list = Info.map(item => {
    const CompIcon = item.icon;
    return (
      <ListItem button component={Link} to={`${PARENT_PATH}/${item.path}`} key={item.path}>
        <ListItemIcon>
          <CompIcon />
        </ListItemIcon>
        <ListItemText primary={item.path} />
      </ListItem>
    )
  })
  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
};

// render={() => <h2>{item.path}</h2>}
export const Content1 = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${Info[0].path}`}
      />
      {Info.map(item => (
        <Route
          path={`${PARENT_PATH}/${item.path}`}
          component={item.component}
          key={item.path} />
      ))}
    </Switch>
  )
}
