// const APP_IP = 'http(s)://www.erongdan.com:8080';
const APP_IP = '125.76.225.115:8080';
//const APP_IP = 'www.erongdan.com:8080';
//const APP_IP1 = '125.76.225.115:4000';//20201014
const APP_IP1 = 'www.erongdan.com';
//const APP_IP1 = '125.76.225.115:8989';//20201105
//const APP_IP1 = 'localhost:4000';//20201105-2
const APP_IP2 = `https://shanmeisettlement.com:8081`; //跳转至清算注册的ip地址20210415
//const APP_IP2 = `http://125.76.225.115:8088`; //跳转至清算注册的ip地址20210415

export const Email = {
  admin: 'admin@erongdan.com',
  support: 'service@erongdan.com',
};

export const ContactNo = {
  office: '130-6041-2448',
  business: '130-6041-2448'
};

export const DEFAULT_HOME_PAGE = '/';
export const DEFAULT_LOGIN_PAGE = '/signin';
export const DEFAULT_SIGNUP_PAGE = '/signup';

export const DEFAULT_UPLOADS_URL = `http://${APP_IP}/uploads/`; //上传文件地址
export const DEFAULT_CONTRCT_URL = `http://${APP_IP}/`;  //合同地址
export const DEFAULT_EXPORT_URL = `http://${APP_IP1}/`;  //合同管理导出地址
export const DEFAULT_ACCOUNT_URL = `https://shanmeisettlement.com/dist/#/registerUser`;  //跳转至清算地址20210415
//export const DEFAULT_ACCOUNT_URL = `http://117.34.72.43:8080/dist/#/registerUser`;  //跳转至清算地址20210415

export const DEFAULT_PAYMENT_URL = `https://shanmeisettlement.com:8081/`;  //跳转至清算支付地址20210512
//export const DEFAULT_PAYMENT_URL = `http://117.34.72.43:8080/`;  //跳转至清算支付地址20210512
