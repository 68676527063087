
import getSubRouters from './helper';
import { loginInfos } from '../../components/numToText'

let subRootAry= []
let loginInfo = loginInfos() || {};
if (loginInfo.organization !== undefined && loginInfo.organization.name === "融单平台" ) {
  subRootAry = [
    '融资申请审核',
    // '询价记录',
    // '融资申请中',
    '融资查看',
    // '放款异常',
    '已退回融资',
    '已融资融单',
    '已退回经办人融资',//融资退回经办人的业务板块功能--20201030
    '已拒签融资',//融资拒签业务板块功能--20201120
    '已删除融资',
    '已退回复核员融资',
    '融资放款明细',
    '融资回款明细',
    '利息摊销计算结果',
    '其他保理利率维护',
  ];
}else{
  subRootAry = [
    '融资申请审核',
    // '询价记录',
    // '融资申请中',
    '融资查看',
    // '放款异常',
    '已退回融资',
    '已融资融单',
    '已退回经办人融资',//融资退回经办人的业务板块功能--20201030
    '已拒签融资',//融资拒签业务板块功能--20201120
    '融资放款明细',
    '融资回款明细',
  ];
}
 export const subRootAry3 = subRootAry

const PARENT_PATH = '/risk-management/融单';
const PATH = '融单融资';
export default getSubRouters(PARENT_PATH, PATH, subRootAry3);
