
import getSubRouters from './helper';

export const subRootAry4 = [
    '融单流转审核',
    '可用融单',
    '支付融单查看',
    '已支付融单',
    '已到期融单',
    '原始融单',
    '已退回流转',
    '已退回经办人流转',//流转退回经办人的业务板块功能--20201030
    '已拒签流转',//流转拒签业务板块功能--20201120
    '已删除流转',
    '已退回复核员流转',
  ];

const PARENT_PATH = '/risk-management/融单';
const PATH = '融单流转';
export default getSubRouters(PARENT_PATH, PATH, subRootAry4);