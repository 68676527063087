import {
  QueryBuilder as QueryBuilderIcon,
} from '@material-ui/icons';
import {subRouters, getMenu, getContent} from './common';
import { SetView } from './InterestRate/commonTable'
const PARENT_PATH = subRouters['利率管理'];

const Info = [
  {
    path: "利率维护",
    icon: QueryBuilderIcon,
    component: SetView('r15-1')
  },
  {
    path: "利息",
    icon: QueryBuilderIcon,
    component: SetView('r15-2')
  },
];

export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];