import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  FormControl,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {DEFAULT_HOME_PAGE} from '../config/constants'
import {cutLoginAction} from '../state/actions'
import {common, form_signin} from './formStatic'
import {Formik} from 'formik';
import FormikError from '../components/formik/formikError';
import {loginFormik} from '../components/formik/formikValidate';
import { fetching } from '../config/utils';

class Login extends Component {

  state = {
    login: {
      user: '',
      password: '',
      captcha: '',
      uid: ''  ,
    },
    done: false
  }

  componentDidMount() {
     fetching(`/amount/usedAmount/captcha?width=250&height=50`, {}, 99).then(res => {
      if (res) {
        var aaa = 'data:image/png;base64,' + res.data.data
        this.setState({ captcha: aaa,  uid: res.data.uid })
      }
    })
   }

  validateForm = () => {
    return this.state.login.user.length > 0 && this.state.login.password.length > 0;
  }

  handleChange = e => {
    this.setState({login: {...this.state.login, [e.target.id]: e.target.value}})
  }


  handleSubmit = (value) => {
    // ev.preventDefault();
    // TODO validates, post a form-body
    if (null !== value.user) {
        fetching(`/msapi/account/findEnAndPersonCer?account=${value.user}`, {}, 99).then(res => {
        try {
          if (res !== undefined && null !== res.data) {
            if (!(res.data.is_authorization ===1) && res.data.is_authorization !== null) {
              alert("企业未完成实名认证,请联系管理员进行实名认证")
              return
            } else if (!(res.data.is_personage_authorization ===1) && res.data.is_personage_authorization !== null) {
              alert("企业未完成法人实名认证,请联系管理员进行实名认证")
              return
            } else if (res.data.status === 0) {
              alert("用户名或密码错误")
              return
            }
          }  else {
            alert("获取登录信息失败,请稍后重试")
            return
          }
      } catch {
        alert("获取登录信息失败,请稍后重试")
        return
      }
       //base64编码加密
      value.password = window.btoa(window.encodeURIComponent(value.password));
      value.uid=this.state.uid;
      this.props.cutLoginAction(value)
        .then(data => {

        const {loggedIn} = this.props.auth;

          if (loggedIn) {

            this.setState({done: true});
            this.props.handleClose2();
            // window.location.reload()

          }
          else {
            this.setState({done: false, title: this.props.auth.msg});
            alert(this.props.auth.msg)
          }
        })
      });
    }
  }

  handleCaptcha = () => {
    fetching(`/amount/usedAmount/captcha?width=250&height=50`, {}, 99).then(res => {
       if (res) {
         var aaa = 'data:image/png;base64,' + res.data.data
         this.setState({ captcha: aaa,  uid: res.data.uid })
       }
     })
  };

  render() {
    const classes = {};
    const {done,captcha,} = this.state;
    //if (this.props.auth.isAuthenticated())
    if (done) {
      return <Redirect to={DEFAULT_HOME_PAGE}/>
    }
    const login = this.state.login;
    return (
      <Formik
        initialValues={{
          ...login
        }}
        validate={(values) => {
          return loginFormik(values, form_signin);
        }}
        onSubmit={(values,) => {
          this.handleSubmit(values);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <div className={classes.paper} style={{margin: "20px"}}>
              <Avatar className={classes.avatar} style={{margin: "8px", backgroundColor: "#f50057"}}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {common.system_name}
              </Typography>
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="user"
                  label={form_signin.user}
                  name="user"
                  autoComplete="user"
                  autoFocus
                  value={values.user}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormikError touched={touched} errors={errors} name={'user'}/>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={form_signin.password}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormikError touched={touched} errors={errors} name={'password'}/>

                <Grid container justify="space-between">
                  <Grid item xs={4}> <TextField className={classes.textfield}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="captcha"
                    label={form_signin.captcha}
                    name="captcha"
                    autoComplete="captcha"
                    autoFocus
                    value={values.captcha}
                    onChange={handleChange}
                  />
                  </Grid>
                  <Grid item xs={7}> 
                  
                   <div 
                  onClick={ () => this.handleCaptcha()}
                  >
                  <FormControl style={{ width: '100%', margin: '16px 0 8px 0' }}>
                  <img
                    src={captcha}
                  />
                 </FormControl>
                  </div>
        
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={!this.validateForm}
                  onClick={handleSubmit}
                >
                  {common.submit}
                </Button>
              </form>
            </div>
          </Grid>
        )}
      />
    )
  }
}

export default connect(state => ({auth: state.auth}), {cutLoginAction})(Login);
