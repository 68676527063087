import React, { Component } from 'react';
import Select from 'react-select';
import {
    TextField,
    Button,
    Grid,
    CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { Formik } from 'formik';
import { C13Formik } from './formikValidate';
import FormikError from './formikError';
import { fetching } from '../../config/utils';

const { object } = require('yup');
const loadingStyle = {
    position: "absolute",
    color: green[500],
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -17,
}
class Form1 extends Component {
    state = {
        form: this.getInitState(),
        selectCustomerList: [],
        loading: false,
    }
    getInitState() {
        const { exercise = {} } = this.props;
        const form2 = {
            select_customer: '',
            enterprise_name: "",
            enterprise_no: "",
            actual_controller: "",
            associated_info: "",
            legal_other_duty: "",
            judicial_info: "",
            credit_attention_bad:"",
        }
        if (exercise.save === '1') {
            form2.select_customer = ''
        } else {
            form2.select_customer = { value: exercise.enterprise_name, label: exercise.enterprise_name, enterprise_no: exercise.enterprise_no }
        }
        return { ...form2, ...exercise }
    }
    async componentDidMount() {
        if (!this.state.form.enterprise_no) {
            this.queryEnterpriseInfo();
        }
    }
    queryEnterpriseInfo = () => {
        fetching('/msapi/customer_info/find', {
            method: 'GET',
        }).then(res => {
            if (res) {
                try {
                    let datas = res.filter((item) => {
                        item.value = item.enterprise_name;
                        item.label = item.enterprise_name;
                        return item; //(item.id !== 1) ? item : null;
                    })
                    this.setState({ selectCustomerList: datas });
                } catch (error) {
                }
            } else {
            }
        })
    }
    //选择客户
    handleChange2 = (name, selectedOption) => {
        let form = { ...this.state.form }
        form.enterprise_no = selectedOption.enterprise_no
        this.setState({ form: { ...form } })
        return selectedOption;
    }


    //保存
    handleSave = (values) => {
        this.setState({ loading: true })
        const { auth } = this.props;
        const { save, id } = this.state.form;
        let ids = (save === '2' || save === '3') ? id : '';
        let method = (save === '2' || save === '3') ? 'POST' : 'POST';
        values.enterprise_no = this.state.form.enterprise_no || ''
        values.enterprise_name = values.select_customer.value || ''
        values.auth = auth;//用户信息
        values.natural = 1
        /*const formData = {};
        const excludes = ['natural', 'auth', 'enterprise_no', 'enterprise_name', 'actual_controller', 'associated_info', 'legal_other_duty', 'judicial_info','credit_attention_bad']
        for (let name in values) {
            if (excludes.includes(name)) {
                formData[name] = values[name]
            }
        }
        formData.id = values.id*/
        // POST
        fetching(`/msapi/people/save/${ids}`, {
            method,
            body: JSON.stringify(values),
        }).then((data) => {
            this.setState({ loading: false })
            try {
                if(data){
                    if(data.msg){
                        alert(ids ? `编辑失败，${data.msg}` : `新增失败，${data.msg}` )
                        return;
                    }
                    this.props.onSubmit({
                        ...this.state.form,
                    })
                }else {
                    alert(ids ? `编辑失败` : `新增失败` )
                }

            } catch (error) {}
        })
    };

    render() {
        const { exercise = {}, formStatic } = this.props;
        const { form, selectCustomerList } = this.state;
        return (
            <Formik
                //初始化数据
                initialValues={{
                    ...form
                }}
                //表单验证
                validationSchema={object().shape(C13Formik(formStatic))}
                //验证提交
                onSubmit={(values) => {
                    this.handleSave(values);
                }}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                        <form method="post" encType="multipart/form-data">
                            <Grid item>
                                <div style={{ margin: '5px 0' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_customer}</span>
                                    <Select
                                        isSearchable
                                        value={values.select_customer}
                                        onChange={e => setFieldValue('select_customer', this.handleChange2.bind(this, 'select_customer', e)())}
                                        options={selectCustomerList}
                                        isDisabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'select_customer'} />
                                </div>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.enterprise_no}
                                        value={form.enterprise_no}
                                        name='enterprise_no'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.actual_controller}
                                        value={values.actual_controller}
                                        name='actual_controller'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'actual_controller'} />
                                </Grid>
                            </Grid>

                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        multiline
                                        rows='4'
                                        label={formStatic.associated_info}
                                        value={values.associated_info}
                                        name='associated_info'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        margin='normal'
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'associated_info'} />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        multiline
                                        rows='4'
                                        label={formStatic.legal_other_duty}
                                        value={values.legal_other_duty}
                                        name='legal_other_duty'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        margin='normal'
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'legal_other_duty'} />
                                </Grid>
                            </Grid>
                            <Grid container justify="space-between">
                                <TextField
                                    multiline
                                    rows='4'
                                    label={formStatic.judicial_info}
                                    value={values.judicial_info}
                                    name='judicial_info'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    margin='normal'
                                    fullWidth
                                    disabled={exercise.save === '3'}
                                />
                                <FormikError touched={touched} errors={errors} name={'judicial_info'} />
                            </Grid>
                            <Grid container justify="space-between">
                                <TextField
                                    multiline
                                    rows='4'
                                    label={formStatic.credit_attention_bad}
                                    value={values.credit_attention_bad}
                                    name='credit_attention_bad'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    margin='normal'
                                    fullWidth
                                    disabled={exercise.save === '3'}
                                />
                                <FormikError touched={touched} errors={errors} name={'credit_attention_bad'} />
                            </Grid>

                            <div style={{ margin: '20px 0', position: 'relative' }}>
                                {exercise.save !== '3' &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={this.state.loading}
                                    >
                                        {formStatic.save}
                                        {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
                                    </Button>
                                }
                            </div>
                        </form>
                    )}
            />
        );
    }
}

export default Form1;