import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import {
  Typography,
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { fetching } from '../../config/utils';
import { form_signup3 } from '../formStatic'
// import { DEFAULT_UPLOADS_URL } from '../../config/constants'

const useStyles = (theme => ({
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    marginRight: '0',
    display: 'flex',
    alignItems: 'flex-end',
  },
  field: {
    width: '100%',
  },
  errorMsg: {
    color: '#f00',
    textAlign: 'center',
    height: '20px',
    cursor: 'pointer',
  },
  left: {
    textAlign: 'left',
  }
}));
const styles = {
  textAlign: 'left',
  margin: '0 10px',
  // lineHeight: '30px'
}

class CertificationForm extends Component {
  state = {
    form1: {
      credit_no: '',
    },
    enterpriseCertificationStatus: '',//企业实名认证状态
    personCertificationStatus: '',//个人实名认证状态
    person: {
      personName: '',
      type: '',
      certType: '',
      idCard: '',
      mobile: '',
      auditFailReason: '',
      idCardPhotoPath: '',
      fork: '',
      birthday: '',
      sex: '',
      idCardDate: '',
      issueAuthority: '',
      address: '',
      status: ''
    },
    listCers: [form_signup3.certification_type1],
    open: false,
    imgUrl: "",
    open_id: localStorage.getItem("registerForm1") ? JSON.parse(localStorage.getItem("registerForm1")).cell : '',
    seal_person_url: '',//法人章
    message: '',
    value: '',
    company: {
      relatedTransactionNo: "",
      organizationPath: "",
      companyName: "",
      auditFailReason: "",
      hasagent: "",
      legalName: "",
      organizationType: "",
      auditorTime: "",
      regFormPath: "",
      companyEmail: "",
      organization: "",
      legal: "",
      verifyType: "",
      status: "",
    },
    bankCard: {
      branchBankCode: "",
      bankCardNo: "",
      bankDetailName: "",
      bankName: "",
    },
    manager: {
      birthday: "",
      address: "",
      idCard: "",
      sex: "",
      mobile: "",
      expiresDate: "",
      auditFailReason: "",
      issueAuthority: "",
      type: "",
      backgroundIdCardPath: "",
      personName: "",
      auditorTime: "",
      fork: "",
      photoUuid: "",
      headPhotoPath: "",
      startDate: "",
      verifyType: "",
      status: "",
    },
    authorization: { is_authorization: 1, is_personage_authorization: 1, },//授权权限
  }

  async componentWillMount() {
    this.queryEnterpriseInfo();
    this.queryPersonInfo();
    this.queryAuthorization();
  }

  //如果有异步设置state值的情况，在组件销毁时清除所有的state状态
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }

  viewAgreement = () => {
    this.setState({ open: false });
  }
  getCheckStatus = (val) => {
    let msg1;
    let msg2;
    switch (val) {
      case '0':
        msg1 = '请进行企业实名认证'
        msg2 = '请进行法人实名认证'
        break;
      case '1':
        msg1 = '请进行企业实名认证'
        msg2 = '请进行法人实名认证'
        break;
      case '2':
        msg1 = '请进行企业实名认证'
        msg2 = '法人实名认证已完成'
        break;
      case '4':
        msg1 = '企业实名认证完成'
        msg2 = '法人实名认证未通过'
        break;
      case '5':
        msg1 = '企业实名认证未通过'
        msg2 = ''
        break;
      case '6':
        msg1 = '企业实名认证待确认打款金额'
        msg2 = ''
        break;
      default:
        msg1 = '正在实名认证中'
        msg2 = '正在实名认证中'
    }
    return { msg1: msg1, msg2: msg2, }
  }
  getCertType = (val) => {
    let msg1;
    switch (val) {
      case '0':
        msg1 = '身份证'
        break;
      case '1':
        msg1 = '护照'
        break;
      case 'B':
        msg1 = '香港居民来往内地通行证'
        break;
      case 'C':
        msg1 = '台湾居民来往大陆通行证'
        break;
      default:
        msg1 = ''
    }
    return msg1
  }
  //通过uuid下载文件 该接口返回一个下载链接，可保存对应的图片信息
  handleClick = (e) => {
    let imgUrl = e.currentTarget.getAttribute('data-value')
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.setState({ open: true, imgUrl: imgUrl });
  }
  //企业实名认证
  handleCertification = () => {
    fetching('/dzqz/companyVerifyUrl', {
      method: 'post',
      body: JSON.stringify({ open_id: this.state.open_id })
    }, 2).then(res => {
     if (res) {
        if (res.data) {
          window.open(res.data.url)
        } else {
          alert(res.msg)
        }
      }
    });
  };

  //获取企业实名认证信息
  queryEnterpriseInfo = () => {
    fetching('/dzqz/findCompanyCertInfo', {
      method: 'POST',
      body: JSON.stringify({
        open_id: this.state.open_id,
      })
    }, 2).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          try {
            const { bankCard, manager, company } = res.data;
            this.setState({ bankCard: bankCard, manager: manager, company: company })
            let msg = this.getCheckStatus(company.status).msg1
            this.setState({ enterpriseCertificationStatus: msg })
          } catch (error) {}
        } else if (res.code === 400) {
          alert(res.msg)
        } else {
          this.setState({ enterpriseCertificationStatus: '请进行企业实名认证' })
        }
      }

    })
  }
  //个人实名认证
  handlePersonCertification = () => {
    fetching('/dzqz/getPersonVerifyUrl', {
      method: 'post',
      body: JSON.stringify({ open_id: this.state.open_id })
    }, 2).then(res => {
      if (res) {
        if (res.data) {
          window.open(res.data.url)
        } else {
          alert(res.msg)
        }
      }
    });
  };
  //查询自动授权签署
  queryAuthorization = () => {
    fetching(`/dzqz/company?open_id=${this.state.open_id}`, {
      method: 'get',
    }, 2).then(res => {
      if (res) {
        if (res.data) {
          const { is_authorization, is_personage_authorization } = res.data
          this.setState({ authorization: { is_authorization, is_personage_authorization } })
        } else {
          alert(res.msg)
        }
      }
    });
  };

  //获取个人实名认证信息
  queryPersonInfo = () => {
    fetching('/dzqz/findPersonCertInfo', {
      method: 'POST',
      body: JSON.stringify({
        open_id: this.state.open_id,
      })
    }, 2).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          try {
            const { person } = res.data;
            let msg = this.getCheckStatus(person.status).msg2
            let certType = this.getCertType(person.certType)
            this.setState({ person: { ...person, certType }, personCertificationStatus: msg })
          } catch (error) { }
        } else {
          this.setState({ personCertificationStatus: '请进行法人实名认证' })
        }
      }
    })
  }

  authorization = (e, value) => {
    let url = ''
    if (value === 1) {
      url = `/dzqz/authorization?open_id=${this.state.open_id}&differentiate=personage`
    } else {
      url = `/dzqz/authorization?open_id=${this.state.open_id}`
    }
    fetching(url, {
      method: 'GET',
    }, 2).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          try {
            window.open(res.data)
          } catch (error) { }
        } else {
          alert(res.msg)
        }
      }

    })
  }

  //上一步
  handleBack = () => {
    this.props.handleBack();
  };

  //下一步
  handleNext = () => {
    if ((this.state.company.status === '4') && (this.state.person.status === '2')) {
      fetching(`/dzqz/company?open_id=${this.state.open_id}`, {
        method: 'get',
      }, 2).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            for (let key in this.state.authorization) {
              if (parseInt(res.data[key]) !== 1) {
                let data1 = key === 'is_authorization' ? '请开启企业自动授权签署' : '请开启法人自动授权签署'
                alert(data1)
                return
              }
            }
            let values = {
              credit_no: this.state.company.organization,
              enterprise_name: this.state.company.companyName,
            }
            this.props.handleNext(values);
          }
        } else {
          alert('查询企业及法人自动授权签署权限失败')
        }
      })
    } else if (this.state.company.status !== '4') {
      alert('尚未完成企业实名认证')
    } else if (this.state.person.status !== '2') {
      alert('尚未完成法人实名认证')
    }
  };
  // loadInfo = () => {
  //   window.open(DEFAULT_UPLOADS_URL + '资料清单.docx')
  // }

  render() {

    const { activeStep, classes } = this.props;
    const { bankCard, manager, company, open, imgUrl, enterpriseCertificationStatus, personCertificationStatus, person, authorization } = this.state;
    return (
      <form >
        {/* <React.Fragment> */}
        {/* <Button variant="contained" className={classes.button} onClick={this.loadInfo}>
          {form_signup3.load_info}
        </Button> */}
        <Typography variant="body1" gutterBottom>
          {form_signup3.desc}
        </Typography>
        {enterpriseCertificationStatus &&
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h6" gutterBottom  >{enterpriseCertificationStatus}</Typography>
            <span style={{ paddingLeft: '10px', color: '#f00', cursor: 'pointer', textDecoration: 'underline' }} onClick={this.handleCertification}>{company.status === '4' ? '点击查看>>' : '点击前往>>'}</span>
          </div>
        }
        {personCertificationStatus &&
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h6" gutterBottom  >{personCertificationStatus}</Typography>
            <span style={{ paddingLeft: '10px', color: '#f00', cursor: 'pointer', textDecoration: 'underline' }} onClick={this.handlePersonCertification}>{person.status === '2' ? '点击查看>>' : '点击前往>>'}</span>
          </div>
        }
        {(company.status && company.status !== '0') &&
          <div style={{ margin: '0 20px' }}>
            <Typography variant="body1" gutterBottom style={{ textAlign: 'left', fontWeight: 'bolder', paddingleft: '10px' }} >企业信息</Typography>
            <Grid container justify='space-around'>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.enterprise_name}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {company.companyName}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.regFormPath}:
                </Typography>
                <span style={{ paddingLeft: '10px', color: '#409EFF', cursor: 'pointer', textDecoration: 'underline' }}
                  data-value={company.regFormPath}
                  onClick={this.handleClick}>
                  {form_signup3.regFormPath}
                </span>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.unified_social_credit_code}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {company.organization}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.license_file1}:
                </Typography>
                <span style={{ paddingLeft: '10px', color: '#409EFF', cursor: 'pointer', textDecoration: 'underline' }}
                  data-value={company.organizationPath}
                  onClick={this.handleClick}>
                  {form_signup3.license_file1}
                </span>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.legal_representative_name}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {company.legalName}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.legal_representative_certification_type}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {form_signup3.certification_type1}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.legal_representative_certificate_no}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {company.legal}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.bank}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {bankCard.BankName}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.bank_detail_name}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {bankCard.bankDetailName}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.bank_account}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {bankCard.bankCardNo}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.operator_name}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {manager.personName}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.certification_type1}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {form_signup3.certification_type1}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.operator_certificate_no}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {manager.idCard}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.operator_certificate_file1}:
                </Typography>
                <span style={{ paddingLeft: '10px', color: '#409EFF', cursor: 'pointer', textDecoration: 'underline' }}
                  data-value={manager.headPhotoPath}
                  onClick={this.handleClick}>
                  {form_signup3.operator_certificate_file1}
                </span>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.cell}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {manager.mobile}
                </Typography>
              </Grid>
            </Grid>
          </div>
        }
        {(person.status && person.status !== '0') &&
          <div style={{ margin: '0 20px' }}>
            <Typography variant="body1" gutterBottom style={{ textAlign: 'left', fontWeight: 'bolder', paddingleft: '10px' }} >法人信息</Typography>
            <Grid container justify='space-around'>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.personName}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {person.personName}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.certType}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {person.certType}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.idCard}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {person.idCard}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.mobile}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {person.mobile}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.idCardPhotoPath}:
                </Typography>
                <span style={{ paddingLeft: '10px', color: '#409EFF', cursor: 'pointer', textDecoration: 'underline' }}
                  data-value={person.headPhotoPath}
                  onClick={this.handleClick}>
                  {form_signup3.headPhotoPath}
                </span>
                <span style={{ paddingLeft: '10px', color: '#409EFF', cursor: 'pointer', textDecoration: 'underline' }}
                  data-value={person.backgroundIdCardPath}
                  onClick={this.handleClick}>
                  {form_signup3.backgroundIdCardPath}
                </span>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.fork}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {person.fork}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.birthday}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {person.birthday}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.sex}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {person.sex}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.idCardDate}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {person.startDate}-{person.expiresDate}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.issueAuthority}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {person.issueAuthority}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.personAddress}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {person.address}
                </Typography>
              </Grid>
              {person.auditFailReason && <Grid item xs={12} className={classes.margin}>
                <Typography variant="body1" gutterBottom display='inline' style={styles} >
                  {form_signup3.auditFailReason}:
                </Typography>
                <Typography variant="body1" gutterBottom display='inline' style={styles}>
                  {person.auditFailReason}
                </Typography>
              </Grid>
              }

            </Grid>
          </div>
        }
        <Grid container justify='space-around' alignItems='flex-start' style={{ paddingTop: '10px' }}>
          {((company.status === '4') && parseInt(authorization.is_authorization) === 0) &&
            <Grid item xs={6} >
              <Button variant='contained' color="primary" onClick={e => { this.authorization(e, 2) }} >开启企业自动授权签署</Button>
            </Grid>
          }
          {(person.status === '2' && parseInt(authorization.is_personage_authorization) === 0) &&
            <Grid item xs={6}>
              <Button variant='contained' color="primary" onClick={e => { this.authorization(e, 1) }}>开启法人自动授权签署</Button>
            </Grid>
          }
        </Grid>
        <div style={{ margin: '20px 0' }}>
          <Button
            disabled={activeStep === 0}
            onClick={this.handleBack}
          >
            上一步
                      </Button>
          <Button variant="contained" color="primary" onClick={this.handleNext}>
            下一步
                      </Button>
        </div>
        <Dialog maxWidth={'md'} onClose={this.viewAgreement} aria-labelledby="customized-dialog-title"
          open={open}>
          {/* <MuiDialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          id="customized-dialog-title">
          <span>{form_signup3.view_agreement}</span>
        </MuiDialogTitle> */}
          <MuiDialogContent style={{ minWidth: '600px', minHeight: '400px' }}>
            <img src={imgUrl} alt='' />
          </MuiDialogContent>
        </Dialog>
        {/* </React.Fragment> */}
      </form>
    );
  }
}


export default withStyles(useStyles)(CertificationForm);
