import React from 'react';
import { Switch, Route,Redirect, Link } from 'react-router-dom';

import {
  ChromeReaderMode
} from '@material-ui/icons';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { subRouters } from './common';
import {
  C141,
  C142,
  C143,
  C144,
  C145,
  C146,
} from './containers'
import { SetView } from './receivablesManage/commonTable'
import { C1412Form, C1422Form, C1432Form, C1442Form } from './receivablesManage'

import Accordion from '../components/Accordion'
import { subRootAry1 } from './containers/C14_1'
import { subRootAry2 } from './containers/C14_2'
import { subRootAry3 } from './containers/C14_3'
import { subRootAry4 } from './containers/C14_4'
import { subRootAry5 } from './containers/C14_5'
import { subRootAry6 } from './containers/C14_6'
const PARENT_PATH = subRouters['应收账款管理'];

const subRootAry = [
  ...subRootAry1,
  ...subRootAry2,
  ...subRootAry3,
  ...subRootAry4,
  ...subRootAry5,
  ...subRootAry6,
]
const Info = [
  {
    path: "贸易合同信息",
    icon: ChromeReaderMode,
    component: C141
  },
  {
    path: "发票信息",
    icon: ChromeReaderMode,
    component: C142
  },
  {
    path: "应收账回款信息",
    icon: ChromeReaderMode,
    component: C143
  },
  {
    path: "应收帐款池",
    icon: ChromeReaderMode,
    component: C144
  },
  {
    path: "数链管理",
    icon: ChromeReaderMode,
    component: C145
  },
  {
    path: "应收账款",
    icon: ChromeReaderMode,
    component: C146
  },
  {
    path: "应收账款业务登记",
    icon: ChromeReaderMode,
    component: SetView('r14-7-1'),
  },

];

const view = {};
subRootAry.forEach((item, index) => {
  switch (item) {
    case '贸易合同查询':
      view[item] = SetView('r14-1-1');
      break;
    case '贸易合同录入':
      view[item] = C1412Form;
      break;
    case '发票查询':
      view[item] = SetView('r14-2-1');
      break;
    case '发票录入':
      view[item] = C1422Form;
      break;
    case '应收帐回款查询':
      view[item] = SetView('r14-3-1');
      break;
    case '应收帐回款录入':
      view[item] = C1432Form;
      break;
    case '应收账款池查询':
      view[item] = SetView('r14-4-1');
      break;
    case '应收账款质押预警':
      view[item] = C1442Form;
      break;
    case '数链应收账款审核':
      view[item] = SetView('r14-5-1');
      break;
    case '数链应收账款查看':
      view[item] = SetView('r14-5-11');
      break;
    case '数链融资审核':
      view[item] = SetView('r14-5-2');
      break;  
    case '数链融资查看':
      view[item] = SetView('r14-5-12');
      break;
    case '数链还款审核':
      view[item] = SetView('r14-5-3');
      break;
    case '已完成数链应收账款':
      view[item] = SetView('r14-5-4');
      break;
    case '已完成数链融资':
      view[item] = SetView('r14-5-5');
      break;  
    case '已完成数链还款':
      view[item] = SetView('r14-5-6');
      break;
    case '已退回数链应收账款':
      view[item] = SetView('r14-5-7');
      break;
    case '已退回数链融资':
      view[item] = SetView('r14-5-8');
      break;  
    case '已退回数链还款':
      view[item] = SetView('r14-5-9');
      break;
    case '已作废数链还款':
      view[item] = SetView('r14-5-10');
      break;
    case '应收账款审核':
      view[item] = SetView('r14-6-1');
      break;
    case '应收账款查看':
      view[item] = SetView('r14-6-2');
      break;
    case '已完成应收账款':
      view[item] = SetView('r14-6-3');
      break; 
    case '已退回应收账款':
      view[item] = SetView('r14-6-4');
      break; 
    case '待还款':
      view[item] = SetView('r14-6-5');
      break;  
    case '还款审批':
      view[item] = SetView('r14-6-6');
      break;   
    default:
      ;
  }
})

const getMenu = (parent_path, info) => () => {
  const list = info.map((item, index) => {
    const CompIcon = item.icon;
    switch (index) {
      case 0:
        return <C141 key="C141" />;
      case 1:
        return <C142 key="C142" />;
      case 2:
        return <C143 key="C143" />;
      case 3:
        return <C144 key="C144" />;
      case 4:
        return <C145 key="C145" />;  
      case 5:
        return <C146 key="C146" />;  
      default:
        return (
          <ListItem button component={Link} to={`${parent_path}/${item.path}`} key={item.path}>
            <ListItemIcon>
              <CompIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={item.path} />
          </ListItem>
        );
    }
  });
  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
};
const getContent = (parent_path, info) => () => {
  return (
    <Switch>
       <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${subRootAry1[0]}`}
      />
      {info.map(item => {
        if (item.component) {
          return (
            <Route
              path={`${parent_path}/${item.path}`}
              component={item.component}
              key={item.path}
            />
          )
        }
        else {
          return (
            <Route
              path={`${parent_path}/${item.path}`}
              render={() => <h2>{item.path}</h2>}
              key={item.path}
            />
          )
        }
      })}
      {subRootAry.map(item => {
        if (view[item]) {
          return (
            <Route
              path={`${parent_path}/${item}`}
              component={view[item]}
              key={item}
            />
          )
        }
        else {
          return (
            <Route
              path={`${parent_path}/${item}`}
              render={() => <h2>{item}</h2>}
              key={item}
            />
          )
        }
      })}
    </Switch>

  )
};
export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];