import React, { Component } from 'react'
import { Formik } from 'formik';
import {
  TextField,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import 'date-fns';
import FormikError from '../containers2/formikError';
import { fetching } from '../../config/utils';
import Typography from '@material-ui/core/Typography';

const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }
}));

const styles = {
  textAlign: 'left',
  margin: '0 10px',
}
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    form: this.getInitState(),
    form2: this.props.form2 || {},//静态资源
    loading: false,
    uploads: {},
    opinion_list: [],
  }

  async componentDidMount() {

  }
  getInitState() {
    //debugger
    const { exercise } = this.props;
    const form = {
      bfe_preProdNo: ''
        }
    return { ...form, ...exercise }
  }

  handleChange = ({ target: { value, name } }) => {
    let value2 = value;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value2,
      }
    })
    return value2;

  }
  

  //保存 提交
  handleSubmit = (form2, name) => {
    let form = form2 || this.state.form;
    let mxfNumber = form.mxf_number ? form.mxf_number : form.mxfNumber;
    const text = name || '撤销';
    this.setState({ loading: true })
   // 提交表单
    if (text === '提交') {
      if(form.bfe_preProdNo === null || form.bfe_preProdNo === ""){
        alert("产品预编号不可为空")
	window.location.reload(); 
        return
      }else{
      fetching(`/msapi/bfe/inputBfePreProdNo?mxfNumber=${mxfNumber}&bfe_preProdNo=${form.bfe_preProdNo}&bfeRzStatus=0`,{
        method: 'GET',
      }).then(res => {
        if (res !== undefined && res.code === 200) {
          alert(res.msg) 
          window.location.reload();  
          // this.props.onSubmit({
          //   ...res,
          //   submit_name: text
          // })
        } else {
          alert(`下载失败,请联系管理员`)
        }
      })
	}
    } else {
      window.location.reload();  
    }
  }


  render() {
    const form2 = this.state.form2;
    const form = this.state.form;
    const { exercise , classes } = this.props;
   
    const status = (handleSubmit, isSubmitting, values) => {
      return<div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
      <div style={{ position: 'relative' }}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          disabled={this.state.loading}
          name={form2.submit}
          style={{ marginRight: '10px' }}
        >
          {form2.submit}
          {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
        </Button>
      </div>
        <Button
          color='primary'
          variant='contained'
          onClick={this.handleSubmit.bind(this, values, '撤销')}
          name={form2.revocation}
          disabled={isSubmitting}
          style={{ marginRight: '10px' }}
        >
          {form2.revocation}
        </Button>
      </div>
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, '提交');
        }}
        render={({
          values,
          errors,
          touched,
          uploads,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
            <form>
              
              <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {"融资单号"}:</Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.mxf_number?values.mxf_number:values.mxfNumber}
                  </Typography>
                </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body2" gutterBottom display='inline' style={styles} >
                  {"融资人"}:</Typography>
                <Typography variant="body2" gutterBottom display='inline' style={styles}>
                  {values.applicant}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.margin}>
                <Typography variant="body2" gutterBottom display='inline' style={styles} >
                  {"融资金额"}:</Typography>
                <Typography variant="body2" gutterBottom display='inline' style={styles}>
                  {values.amount}
                </Typography>
              </Grid>
            
              <Grid container>
                <TextField
                  label={form2.bfe_preProdNo}
                  value={values.bfe_preProdNo || ''}
                  name='bfe_preProdNo'
                  //onChange={handleChange}
                  onChange={e => setFieldValue('bfe_preProdNo', this.handleChange.bind(this, e)())}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'bfe_preProdNo'} />
              </Grid>
              
                
               {
                status(handleSubmit,isSubmitting,  values)
              }
            </form>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);



