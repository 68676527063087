//应收帐管理-应收帐回款
export default {
  customer_name: "客户名称",
  refund_amount: "回款金额",
  payer_name: "付款人名称",
  corresponding_invoice_no: "对应发票号",
  refund_date: "回款日期",
  notes: "备注",

  //应收帐款池
  impawn_rate: "质押率",
  warning_line: "警戒线",
  collect_amount: "有效应收账款金额"
}
