import React, { Component } from 'react'
import { Formik } from 'formik';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { fetching } from '../../config/utils';
import { check } from './check'
import {
  TextField,
  Button,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel
} from '@material-ui/core'


import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import FormikError from './formikError';
import SimpleExpansionPanel from './Panels'
import { issueFormik } from './formikValidate';
const { object } = require('yup');

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
class PaymentForm extends Component {
  state = {
    form: this.getInitState(),
    form2: this.getInitState2(),
    open2: false,
    imgUrl: '',
    times: null,
    signatureMsg: '',
    second: 60,//验证码倒计时
    secondNumber: 'yzm',//验证码
    userList: [
      { id: 1, name: '退回上一级' },
      { id: 2, name: '退回经办人' },
    ],
    auth: this.props.auth || {}
  }
  getInitState() {
    const { exercise, auth } = this.props
    // const sign_agreement = exercise.rolseId === '2' ? '1': '';
    let sign_agreement = '1';
    let phone = auth.phone ? auth.phone : '15719478614';
    let verification_code = 'yzm';
    let KLStart = true;
    let rolesId = auth.roles ? auth.roles[0].id : 0;
    if (
      (exercise.checkStatus === '3' || exercise.checkStatus === '5') &&
      (exercise.table === 'm2-4' || exercise.table === 'm3-1-9' || exercise.table === 'm3-2-2' ||
        exercise.table === 'm3-1-1')) {
      KLStart = false;
      verification_code = '';
    } else if(exercise.table === 'r6-3-1' && rolesId === 9){
      KLStart = false;
      verification_code = '';
    }
    if (exercise.checkStatus === '6') {
      verification_code = '';
    }
    const form = {
      opinion_list: [],
      ...exercise,
      sign_agreement, //签订协议
      check_opinion: '',
      verification_code,
      KLStart,
      phone,
      returnBy: '退回上一级'
    }

    return form
  }
  getInitState2() {
    const { exercise } = this.props
    let sign_agreement = '';
    if (exercise.table === 'm2-4' || exercise.table === 'r6-2-1' || exercise.table === 'm3-1-1') {

      sign_agreement = exercise.mxc_number ? check.sign_flow : check.sign_agreement;
    }
    else if (exercise.table === 'm3-1-9' || exercise.table === 'r6-3-1') {
      sign_agreement = check.sign_flow
    }
    else if (exercise.table === 'm3-2-2' || exercise.table === 'r6-4-1') {
      sign_agreement = check.sign_financing
    }
    return { ...check, sign_agreement };
  }

  componentWillUnmount() {
    clearInterval(this.state.times);
  }
  //  //签章
  sign_agreements = () => {

    const { mx_number, fdd_file_name, KLStart, checkStatus, table, mxc_number, mxf_number } = this.state.form;
    const { auth, form, signatureMsg } = this.state;
    const credit_no = auth.organization ? auth.organization.credit_no : '';

    let Url = '';
    let mx_number2 = mx_number;
    if (table === 'm2-4') {
      Url = '/dzqz/extsignAuto/KLStart';
    } else if (table === 'm3-1-1' && !mxc_number) {
      Url = '/dzqz/extsignAuto/KLEnd';
    } else if (table === 'm3-1-1' && mxc_number) {
      Url = '/dzqz/extsignAuto/LZEnd';
      mx_number2 = mxc_number;
    } else if (table === 'm3-1-9') {
      Url = '/dzqz/extsignAuto/LZStart';
      mx_number2 = mxc_number;
    } else if (table === 'm3-2-2') {
      Url = '/dzqz/extsignAuto/RZStart';
      mx_number2 = mxf_number;
    } else if (table === 'r6-3-1') {
      Url = '/dzqz/extsignAuto/RZEnd';
      mx_number2 = mxf_number;
    }
    const body = {
      credit_no,
      mx_number: mx_number2,
      fdd_file_name
    }
    if (!KLStart && signatureMsg !== '正在签订中...') {
      if (Url === '') {
        this.setState({
          form: { ...form, KLStart: true },
          signatureMsg: '签章还在更新中...'
        })
        return;
      }
      //签章
      fetching(Url, {
        method: 'POST',
        body: JSON.stringify(body)
      }, 2).then(res => {
        if (res !== undefined) {
          if (res.code === 200) {
            let fdd_file_path = checkStatus === '5' ? res.data.viewpdf_url1 : res.data.viewpdf_url;
            window.open(fdd_file_path);
            this.setState({
              form: { ...form, fdd_file_path, KLStart: true },
              signatureMsg: '签订成功'
            })
          } else {
            this.setState({
              signatureMsg: res.msg
            })
          }
        }

      })
      this.setState({
        signatureMsg: '正在签订中...',

      })
    }

  }

  handleFileChange = e => {
    this.setState({
      [e.target.name]: e.target.files[0],
    })
  };

  //查看协议
  viewAgreement = () => {
    // this.setState({ open2: !this.state.open2 })
    const { fdd_file_path = '' } = this.state.form;
    window.open(fdd_file_path)
  }
  //发送验证码
  onVerificationCode = () => {
    const { table, mxc_number, phone } = this.state.form;
    const times = setInterval(() => {
      const { second } = this.state;
      if (second === 0) {
        clearInterval(times);
        this.setState({
          second: 60
        })
      } else {
        this.setState({
          second: second - 1
        })
      }
    }, 1000)
    this.setState({
      times
    })

    let url = '';
    if (table === 'm2-4') {
      url = `/api/sms/KL?phone=${phone}`;
    } else if (table === 'm3-1-1' && !mxc_number) {
      url = `/api/sms/KL?phone=${phone}`;
    } else if (table === 'm3-1-1' && mxc_number) {
      url = `/api/sms/LZ?phone=${phone}`;
    } else if (table === 'm3-1-9') {
      url = `/api/sms/LZ?phone=${phone}`;
    } else if (table === 'm3-2-2') {
      url = `/api/sms/RZ?phone=${phone}`;
    } else if (table === 'r6-3-1') {
      url = `/api/sms/RZ?phone=${phone}`;
    }


    if(url === ''){
      alert('短信接口为空')
      return;
    }
    fetching(url, {
      method: 'GET'
    }).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          this.setState({
            secondNumber: res.data
          })
        } else {
          alert(res.msg || '验证码发送失败')
        }
      } else {
        alert('验证码发送失败')
      }

    })

  }
  // 提交
  handleSubmit = (form2, name) => {
    const { auth } = this.state;
    const stateForm = this.state.form;
    const { exercise } = this.props
    const form = form2 ? { ...form2 } : stateForm;
    const text = name || '取消';
    let typePath = '/msapi/flow/finds';//审核接口
    if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3'
      || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4') typePath = '/api/flow/finds' //开立
    else if (
      exercise.table === 'm3-1-9' ||
      exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' ||
      exercise.table === 'r6-4-8') typePath = '/msapi/cflow/find' //流转
    else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' ||
      exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') typePath = '/api/fflow/find' //融资
    //签收verification_code
    else if (exercise.table === 'm3-1-1') {
      if (form.mxc_number) {
        typePath = '/msapi/cflow/finds';
      } else
        typePath = '/msapi/flow/finds';
    }

    //参数
    const body = {
      auth,//用户信息
      check_opinion: form.check_opinion,//审核意见
      mx_number: form.mx_number || '', //可以字符串拼接
      audit_status: form.status,  //传状态id name form.status
      sign_agreement: form.sign_agreement,//签订融单协议
      form_no: form.checkStatus,
      returnBy: form.returnBy,
      mxc_number: form.mxc_number || '',
      mxf_number: form.mxf_number || '',
    }
    //提交表单

    if (text !== '取消') {
      fetching(typePath, {
        method: 'POST',
        body: JSON.stringify(body)
      })
      this.props.onSubmit({
        ...form,
        submit_name: text
      })

    } else {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
    }
  }
  render() {
    const { form2, form, imgUrl, userList, second, secondNumber, signatureMsg } = this.state;
    const { exercise, auth } = this.props;
    let rolesId = auth.roles ? auth.roles[0].id : 0;
    const isSign_agreement = () => {
      return false;
    }
    const checkOption = () => {
      if (form.checkStatus === '5' || form.checkStatus === '6') {
        return false;
      } else return true;
    }
    const check_opinion_label = () => {
      if (exercise.checkStatus === '3' && rolesId !== 5) return form2.check_opinion
      else if (exercise.checkStatus === '2') return form2.return_reason
      else return form2.remarks

    }
    const status = (handleSubmit, values) => {
      if (exercise.checkStatus === '3') {

        return <Grid container justify="center" style={{ padding: '20px 0' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      } else if (exercise.checkStatus === '2') {
        return <Grid container justify="center" style={{ padding: '20px 0' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      } else if (exercise.checkStatus === '5') {
        return <Grid container justify="center" style={{ padding: '20px 0' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      } else if (exercise.checkStatus === '6') {
        return <Grid container justify="center" style={{ padding: '20px 0' }}>

          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      }
    }
    //签订协议类型 exercise.table
    // const agreementTple = () => {}
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        validationSchema={object().shape(issueFormik(form2, form, rolesId))}
        //验证提交
        onSubmit={(values) => {
          this.handleSubmit(values, form2.check);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <form method="post" encType="multipart/form-data">

              {checkOption() &&
                <SimpleExpansionPanel title="历史审核意见">
                  {
                    form.opinion_list.map((item, index) => {
                      return (
                        <div style={{ margin: '10px 0' }} key={index}>
                          <Typography component="p">
                            {item.name}
                          </Typography>
                          <Typography component="p">
                            {item.check_opinion}
                          </Typography>
                        </div>
                      )
                    })

                  }
                </SimpleExpansionPanel>
              }

              <Grid item xs={12}>
                <TextField
                  multiline
                  rows='4'
                  label={check_opinion_label()}
                  value={values.check_opinion}
                  name='check_opinion'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin='normal'
                  fullWidth
                  disabled={false}
                />
                <FormikError touched={touched} errors={errors} name={'check_opinion'} />
              </Grid>
              {
                isSign_agreement() &&
                <Grid container>

                  <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                      <TextField
                        label={form2.verification_code}
                        value={values.verification_code}
                        name='verification_code'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin='normal'
                        style={{ width: '167px' }}
                        disabled={false}
                      />
                      {second === 60 ?
                        <Button onClick={this.onVerificationCode} style={{ margin: ' 0  0 8px 10px' }}
                          variant='contained'>{form2.verification_code2}
                        </Button> :
                        <Button style={{ margin: ' 0  0 8px 10px' }}
                          variant='contained'>{second + form2.verification_code3}
                        </Button>}
                    </Grid>
                    {secondNumber !== 'yzm' && <div style={{ color: ' rgba(0, 0, 0, 0.42)', fontSize: '12px' }}>
                      {'验证码也发送到你的手机 '  + form.phone}
                    </div>}
                    <FormikError touched={touched} errors={errors} name={'verification_code'} />
                  </Grid>
                  <div style={{ margin: '20px 0 10px 0' }}>
                    <Button onClick={this.sign_agreements}
                      color="primary">{form2.sign_agreement}</Button>
                    <Button onClick={this.viewAgreement} style={{ marginLeft: '10px' }}
                      variant='contained' >{form2.view_agreement}</Button>
                    {<div style={{ margin: '10px 0 0 10px' }}>{signatureMsg}</div>}
                  </div>
                </Grid>
              }
              {
                exercise.checkStatus === '6' && <Grid item xs={12}>
                  <Grid container alignItems="flex-end">
                    <TextField
                      label={form2.verification_code}
                      value={values.verification_code}
                      name='verification_code'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin='normal'
                      disabled={false}
                    />
                    {second === 60 ?
                      <Button onClick={this.onVerificationCode} style={{ margin: ' 0  0 8px 10px' }}
                        variant='contained'>{form2.verification_code2}
                      </Button> :
                      <Button style={{ margin: ' 0  0 8px 10px' }}
                        variant='contained'>{second + form2.verification_code3}
                      </Button>}
                  </Grid>
                  {secondNumber !== 'yzm' && <div style={{ color: 'rgba(0, 0, 0, 0.42)', fontSize: '12px' }}>
                    {'验证码也发送到你的手机 ' + form.phone}
                  </div>}
                  <FormikError touched={touched} errors={errors} name={'verification_code'} />
                </Grid>
              }
              {
                exercise.checkStatus === '2' &&
                <FormControl style={{ width: '100%', margin: '10px 0' }}>
                  <InputLabel id="demo-simple-select-label">{form2.returnBy}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="returnBy"
                    value={values.returnBy}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {
                      userList.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              }
              {
                status(handleSubmit, values)
              }
              <Dialog maxWidth={'md'} onClose={this.viewAgreement} aria-labelledby="customized-dialog-title"
                open={this.state.open2}>
                <MuiDialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  id="customized-dialog-title">
                  <span>{form2.view_agreement}</span>
                </MuiDialogTitle>
                <MuiDialogContent style={{ minWidth: '600px', minHeight: '400px' }}>
                  <img src={imgUrl} alt='' />
                </MuiDialogContent>
                <Button onClick={this.viewAgreement} style={{ width: '100px', margin: '20px 0 20px 24px' }}
                  variant='contained'>返回</Button>
              </Dialog>
            </form >
          )
        }
      />

    )
  }
}

export default withStyles(useStyles)(PaymentForm)
