
import getSubRouters from './helper';

export const subRootAry5 = [
    '招行融资查看',
    '招行已完成融资',
  ];

const PARENT_PATH = '/risk-management/融单';
const PATH = '招行融资';
export default getSubRouters(PARENT_PATH, PATH, subRootAry5);
