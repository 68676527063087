import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

class Notification extends Component {

  constructor() {
    super();
    //设置回调函数的执行
    this.transitionTime = 300;
    this.state = {
      notices: []
    }
  }
  // 使用时间和数组长度以保证唯一性
  getNoticeKey = () => {
    return `notice:${new Date().getTime()}:${this.state.notices.length}`;
  }

  addNotice = (notice) => {
    // 创造一个不重复的key
    const { notices } = this.state;
    const key = notice.key ? notice.key : notice.key = this.getNoticeKey();
    const temp = notices.filter((item) => item.key === key).length;
    notice.open = true
    if (!temp) {
      // 不存在重复的 添加
      notices.push(notice);
      this.setState({
        notices,
      })
    }
    if (notice.duration > 0) {
      setTimeout(() => {
        this.removeNotice(notice.key)
      }, notice.duration);
    }
  }

  removeNotice = (key) => {
    let { notices } = this.state;
    notices = notices.filter((item) => {
      if (item.key === key) {
        if (item.onClose) {
          setTimeout(item.onClose(), this.transitionTime)
        }
        item.open = false
        return false
      }
      return true
    })
    this.setState({
      notices
    })
  }
  handleClose = (event, reason, key) => {
    if (reason === 'clickaway') {
      return;
    }
    this.removeNotice(key)
  };

  render() {
    const { notices } = this.state
    return (
      // 当长度为0时不渲染容器
      notices.length > 0 ?
        <div className="toast">
          {notices.map((item) => (
            <div key={item.key}>
              <Snackbar open={item.open} onClose={(e, reason) => { this.handleClose(e, reason, item.key) }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <MuiAlert onClose={(e, reason) => { this.handleClose(e, reason, item.key) }} severity={item.type}
                  elevation={6} variant="filled"
                >
                  {item.text}
                </MuiAlert>
              </Snackbar>
            </div>
          ))}
        </div> : ''
    )
  }
}
// 生成一个容器 DOM 并返回创建和销毁方法。
function createNotification() {
  const div = document.createElement('div');
  document.body.appendChild(div)
  const ref = React.createRef()
  ReactDOM.render(<Notification ref={ref} />, div)

  return {
    add(notice) { return ref.current.addNotice(notice) },
    destroy() {
      ReactDOM.unmountComponentAtNode(div)
      document.body.removeChild(div)
    }
  }
}

export default createNotification()