import { subRouters, getMenu, getContent } from './common';
import { SetView } from './commonTable';
import {
  OfflineBolt as OfflineBoltIcon,
  History as HistoryIcon,
  VerifiedUser as VerifiedUserIcon,
  BookTwoTone as BookTwoTone,
  BorderAll
} from '@material-ui/icons';

const PARENT_PATH = subRouters['合同管理'];

const Info = [
  {
    path: "注册合同管理",
    icon: OfflineBoltIcon,
    component: SetView('m6-1'),
  },
  {
    path: "开立合同管理",
    icon: HistoryIcon,
    component: SetView('m6-2'),
  },
  {
    path: "融资合同管理",
    icon: VerifiedUserIcon,
    component: SetView('m6-3'),
  },
  {
    path: "应收合同管理",
    icon: VerifiedUserIcon,
    component: SetView('m6-6'),
  },
  {
    path: "银行账户信息",
    icon: VerifiedUserIcon,
    component: SetView('m6-4'),
  },
  {
    path: "清算注册明细表",
    icon: BookTwoTone,
    component: SetView('m6-5'),
  },
  {
    path: "善美光大银行信息管理",
    icon: BorderAll,
    component: SetView('m6-7'),
  },
];

export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];
