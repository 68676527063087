
import {
  Sync as SyncIcon,//循环-额度可恢复--20210125
  SyncDisabled as SyncDisabledIcon,//不可循环-额度不可恢复--20210125
  Pinterest as PinterestIcon,
  MoneyOff as MoneyOffIcon,
  Money as MoneyIcon
} from '@material-ui/icons';
import {subRouters, getMenu, getContent} from './common';
import{SetView} from './commonTable'

const PARENT_PATH = subRouters['还款'];

const Info = [
  {
    path: '还款复核',
    icon: PinterestIcon,
    component: SetView('r8-8')
  },
  {
    path: '待还款',
    icon: MoneyOffIcon,
    component: SetView('r8-1')
  },
  // {
  //   path: '还款审批',
  //   icon: VerifiedUser
  // },
  {
    path: '已还款',
    icon: MoneyIcon,
    component: SetView('r8-2')
  },
  {
    path: '额度已恢复',
    icon: SyncIcon,
    component: SetView('r8-3')
  },
  {
    path: '额度不可恢复',
    icon: SyncDisabledIcon,
    component: SetView('r8-6')
  },
  {
    path: '还款提醒',
    icon: PinterestIcon,
    component: SetView('r8-4')
  },
  {
    path: '融单到期持有',
    icon: PinterestIcon,
    component: SetView('r8-5')
  },
  {
    path: '还款明细',
    icon: MoneyOffIcon,
    component: SetView('r8-7')
  },
  
  // {
  //   path: '罚息管理',
  //   icon: PinterestIcon
  // },
];

export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];
