import React from 'react';
import { Switch, Route, Link,Redirect } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  ListAlt as ListAltIcon,
} from '@material-ui/icons';
import { subRouters } from './common';
import { SetView } from './FinancialInfo/commonTable'
import Accordion from '../components/Accordion'

const PARENT_PATH = subRouters['财务信息'];

const Info = [
  {
    path: "主要财务资料",
    icon: ListAltIcon,
    component: SetView('c2-1'),
  },
];

// export default [
//   getMenu(PARENT_PATH, Info),
//   getContent(PARENT_PATH, Info)
// ]

export const Menu2 = () => {
  const list = Info.map(item => {
    const CompIcon = item.icon;
    return (
      <ListItem button component={Link} to={`${PARENT_PATH}/${item.path}`} key={item.path}>
        <ListItemIcon>
          <CompIcon />
        </ListItemIcon>
        <ListItemText primary={item.path} />
      </ListItem>
    )
  })
  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
}

export const Content2 = () => {
  return (
    <Switch>
        <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${Info[0].path}`}
      />
      {Info.map(item => (
        <Route
          path={`${PARENT_PATH}/${item.path}`}
          component={item.component}
          key={item.path}
        />
      ))}
    </Switch>
  )
};