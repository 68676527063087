// 风险管理模块用到的弹窗表单——dxm
import React, { Component } from 'react'
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle2 = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});


class Dialog extends Component {

  state = {
    open: false,
    save: '1',
    DialogTitle: '新增',
    isBtn: true,
    ...this.props.exercise
  }
  //如果有异步设置state值的情况，在组件销毁时清除所有的state状态
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }
  getInitState() {
    const { exercise } = this.props
    return exercise || {
      open: false,
    }
  }
  handleToggle = () => {

    this.props.handleToggle(true);
  }
  handleToggle2 = () => {

    this.props.handleToggle();

  }

  handleFormSubmit = exercise => {
    this.props.FormSubmit(exercise);
  }

  render() {
    const { open } = this.state
    const exercise = { ...this.state }
    return (
      <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
        {exercise.isBtn && <Button style={{ marginBottom: '10px' }} onClick={this.handleToggle2} variant="contained" color="primary">{exercise.addBtnName}</Button>}
        <MuiDialog
          open={open}
          onClose={this.handleToggle}
          fullWidth
          maxWidth='sm'
        >
          <DialogTitle2 id="customized-dialog-title" onClose={this.handleToggle}>
            {exercise.DialogTitle}
          </DialogTitle2>
          <DialogContent>
            {this.props.children}
          </DialogContent>
        </MuiDialog>
      </div>
    )
  }
}

export default Dialog