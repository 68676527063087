import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {compose} from 'recompose';
import { fetching } from '../config/utils'
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {withStyles} from '@material-ui/core/styles';
import {DEFAULT_HOME_PAGE} from '../config/constants'
import {loginAction} from '../state/actions'
import {common, form_signin} from './formStatic'
import {Formik} from 'formik';
import FormikError from '../components/formik/formikError';
import {loginFormik} from '../components/formik/formikValidate'
import {Copyright, ICP, showImg} from '../config/reusable';
import ResetPassword from './resetPasswd';



const useStyles = theme => ({
  '@global': {
    body: {
      backgroundImage: 'url(/imgs/background.jpg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      //backgroundPosition: 'center',
    },
  },
  root: {
    height: '100vh',
  },
  bottom: {
    marginTop: '84vh',
  },
  top: {
    marginTop :'20vh',
    marginLeft :'11vh',
  },
  img: {
    marginLeft: theme.spacing(3),
  },
  img5:{
    marginLeft: '3px',
    width: '480px',
    height:'340px',
    marginBottom: '-100px',
    marginTop: '-100px',
    margin: theme.spacing(0,0,0,-4),
	backdropFilter: 'blur(0px)',
  },
  paper: {
    marginTop: theme.spacing(8, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'transparent', //theme.palette.common.white,
	backdropFilter: 'blur(0px)',
  },
  grid: {
    padding: theme.spacing(8),
    margin: theme.spacing('3%', '10%'),
    maxHeight: '100%',
    /*overflow: 'auto',*/
    backgroundColor: 'transparent',
  },
  form: {
      width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
	  backgroundColor: '#ffffff5e', //theme.palette.common.white,
    height: "500px",
    borderRadius: "3.5%",
    boxShadow: '5px 5px 10px #696c72',
    marginBottom: '10px',
    zIndex: '0',
  },
   userText: {
    // backgroundColor :'gray',
     borderRadius:'6%',
     color:'white',
     width: '251px',
     marginTop: '40px',
    },
  textfield: {
   // backgroundColor :'gray',
    borderRadius:'6%',
    color:'white',
	width: '251px',
    // "& .MuiInputLabel-outlined": {
    //   color: "white"
    // },
    // "& .MuiOutlinedInput-input": {
    //   color: "white"
    // },
    // "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "white"
    // },
   },
  //  checkbox:{
  //    color:"white",
  //  },
  //  formcontrollabel:{
  //   color:"white",
  //  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  dsadsa:{
    backdropFilter: 'blur(0px)',
    width: '40%',
    height: '97.5%',
    top: '0px',
    right: '1px',
    position: 'absolute',
  }
});

let siv = '';

class SignIn extends Component {
  state = {
    login: {
      user: '',
      password: '',
    },
    //loading: false,
    //open: false,
    //done: false,
    isCheck: false,
    isCheckCaptcha: false,
    isCheckPhotoCaptcha: true,
    captcha: '',
    uid: ''  ,
    isCaptcha: true,
    phoneCaptcha: '',
    btnText: common.getPhoneCode,
    timer: 60,
    clearInterval: false,
    
  };

  componentDidMount() {
    clearInterval(siv);
    // Number("62868.16") -  Number("45758.54")
     //this.state.login
     fetching(`/amount/usedAmount/captcha?width=250&height=50`, {}, 99).then(res => {
      if (res) {
        var aaa = 'data:image/png;base64,' + res.data.data
        this.setState({ captcha: aaa,  uid: res.data.uid })
      }
    })
   }
 
   handleCaptcha = () => {
     fetching(`/amount/usedAmount/captcha?width=250&height=50`, {}, 99).then(res => {
        if (res) {
          var aaa = 'data:image/png;base64,' + res.data.data
          this.setState({ captcha: aaa,  uid: res.data.uid })
        }
      })
   };

  validateForm = () => {
    return this.state.login.user.length > 0 && this.state.login.password.length > 0;
  };

  handleChange = e => {
    this.setState({login: {...this.state.login, [e.target.id]: e.target.value}, captcha:{...this.state.captcha, [e.target.id]: e.target.value}})
  };

  //z设置手机验证码获取间隔
  count = () => {
    let { timer } = this.state;
    siv = setInterval(() => {
      this.setState({ timer: (timer--), btnText: timer + '后可再次获取', discodeBtn: true }, () => {
        if (timer === 0) {
          clearInterval(siv);
          this.setState({ btnText: common.getPhoneCode, timer: 60, discodeBtn: false })
        }
      });
    }, 1000);
  };
  //z判断cell框的值
  getCellCode = (value) => {
    if (!value.user) {
      alert('请输入用户名');
      return;
    } else {
      this.setState({  discodeBtn: true })
    }

    
    //z获取手机验证码url调用接口
    fetch('/api/sms/LoginCheck?phone=' + value.user, {
      method: 'get',
    })
        .then(res => res.json())
        .then(data => {
          if (data.code === 200) {
            alert("短信验证码已发送至您的手机")
            //const cellCode = data.data
            this.setState({ /*cellCode: cellCode,*/ isCaptcha:false })
            this.count();
          } else {
            alert(data.msg)
          }
        });

      
  };

  /*handleSubmit = (value) => {
    this.setState({loading: true});
    this.props.loginAction(value)
      .then(data => {
        const {loggedIn} = this.props.auth;
        if (loggedIn) {
          this.setState({done: true, loading: false});
        } else {
          this.setState({done: false, loading: false, title: this.props.auth.msg});
          sessionStorage.removeItem("authToken");
          alert(this.props.auth.msg)
        }
      })
  };*/
  handleSubmit = (value) => {
    if (null !== value.user) {
      fetching(`/msapi/account/findEnAndPersonCer?account=${value.user}`, {}, 99).then(res => {
        try {
          if (res !== undefined && null !== res.data) {
            if (!(res.data.is_authorization ===1) && res.data.is_authorization !== null) {
              alert("企业未完成实名认证,请联系管理员进行实名认证")
              return
            } else if (!(res.data.is_personage_authorization ===1) && res.data.is_personage_authorization !== null) {
              alert("企业未完成法人实名认证,请联系管理员进行实名认证")
              return
            } else if (res.data.status === 0) {
              alert("用户名或密码错误")
              return
            } else if (res.data.status === 2) {
              alert("企业法人授权已过期,暂无法登录系统,请联系客户经理处理")
              return
            } else if (res.data.status === 3) {
              alert("距离上次登录时间太久,您需要进行手机验证码登录校验")
              //this.state.btnText = common.getPhoneCode;
              this.setState({  isCheckCaptcha: true,isCheck: true ,isCheckPhotoCaptcha: false})
              return
            }
          }  else {
            alert("获取登录信息失败,请稍后重试")
            return
          }
      } catch {
        alert("获取登录信息失败,请稍后重试")
        return
      }
    if ( this.state.isCheckCaptcha && !value.phoneCaptcha) {
      alert("请输入验证码")
      } else {
      this.setState({loading: true});
      value.uid=this.state.uid;
      value.isCheckCaptcha = this.state.isCheckCaptcha;
      //value.phoneCaptcha = this.state.phoneCaptcha;
      //base64编码加密
      value.password = window.btoa(window.encodeURIComponent(value.password))
      this.props.loginAction(value)
        .then(data => {
          const {loggedIn} = this.props.auth;
          if (loggedIn) {
            this.setState({done: true, loading: false});
          } else {
            value.password = "";
            this.setState({done: false, loading: false, title: this.props.auth.msg});
            sessionStorage.removeItem("authToken");
            alert(this.props.auth.msg)
            this.handleCaptcha()
          }
        })
      }
    });
      
   }
  };

  render() {
    const {classes} = this.props;
    const {done, login, loading , captcha, uid,discodeBtn,isCaptcha,isCheck,phoneCaptcha,btnText,isCheckPhotoCaptcha} = this.state;
    if (done) {
      return <Redirect to={DEFAULT_HOME_PAGE}/>
    }

    const img2 = showImg({
      image: '/imgs/erongdan-logo.png',
      title: '善美融单',
    });
    const img3 = showImg({
      image: '/imgs/公众号.jpeg',
      title: '善美融单公众号',
    });

    return (

      <Formik
        initialValues={{
          ...login
        }}
        validate={(values) => {
          return loginFormik(values, form_signin);
        }}
        onSubmit={(values,) => {
          this.handleSubmit(values);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Grid container component="main" className={classes.root} spacing={6}>
            <CssBaseline/>
            <Grid item xs className={classes.bottom}>
              {/* <img src="/imgs/公众号.jpeg" title="善美融单公众号" alt='善美融单公众号'width={150} height={150}></img> */}
              <Copyright/>
              <ICP/>
              <img src="/imgs/fdd.png" title="法大大" alt='法大大' width={100} height={60} className={classes.img}/>
            </Grid>
            <Grid item xs={12} sm={4} md={3}  elevation={6} square className={classes.grid}>
            <Grid item >
            </Grid>
			 <div className={classes.dsadsa}/>
              <div className={classes.paper}>
                {/* {img2} */}
                <img src="/imgs/erongdan-logo.png" title="融单e融" alt='融单e融' text-align='center' width={378} height={185} className={classes.img5}/>
                <form className={classes.form} noValidate>
                  <TextField className={classes.userText}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="user"
                    label={form_signin.user}
                    name="user"
                    autoComplete="user"
                    autoFocus
                    value={values.user}
                    onChange={handleChange}
                    
                  />
                  <FormikError touched={touched} errors={errors} name={'user'}/>
                  <TextField className={classes.textfield}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={form_signin.password}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormikError touched={touched} errors={errors} name={'password'}/>

                  {isCheck && <TextField className={classes.textfield}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="phoneCaptcha"
                    label={form_signin.phoneCaptcha}
                    name="phoneCaptcha"
                    disabled={isCaptcha}
                    autoComplete="phoneCaptcha"
                    autoFocus
                    value={values.phoneCaptcha}
                    onChange={handleChange}
                  />}
                  {isCheck && <Button
                    required
                    fullWidth
                    variant="outlined"
                    color="primary"
                    disabled={discodeBtn}
                    autoComplete="off"
                    onClick={this.getCellCode.bind(this, values)}
                  >
                    {btnText}
                    {/* {common.getPhoneCode} */}

                    {/* <Grid item xs={3}>
                      <Button
                          onClick={this.getCellCode.bind(this, values)}
                          variant="contained"
                          disabled={discodeBtn}
                          className={classes.button}>
                        {btnText}
                      </Button>
                    </Grid> */}
                    {/* {loading && <CircularProgress style={{color: "inherit"}} size={0}/>} */}
                  </Button>}
                  {isCheckPhotoCaptcha && <TextField className={classes.textfield}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="captcha"
                    label={form_signin.captcha}
                    name="captcha"
                    autoComplete="captcha"
                    autoFocus
                    value={values.captcha}
                    onChange={handleChange}
                  />}
                  {isCheckPhotoCaptcha && <div 
                  onClick={ () => this.handleCaptcha()}
                  >
                  <img
                    src={captcha}
                  />
                  </div>}
                  {/* <FormControlLabel className={classes.formcontrollabel}
                    control={<Checkbox className={classes.checkbox} value="remember" color="white"/>} */}

                  <FormControlLabel
                    control={<Checkbox  value="remember" color="primary"/>}
                    label={common.remember_me}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={!this.validateForm}
                    onClick={handleSubmit}
                    endIcon={<LockOpenIcon/>}
					style={{marginTop: '0px',width:'80%'}}
                  >
                    {common.submit}
                    {loading && <CircularProgress style={{color: "inherit"}} size={0}/>}
                  </Button>

                  <Grid container>
                    <Grid item xs>
                      <Link href="/signup" variant="body2" style={{ color: '#e7e7e7' }}>
                        {common.enterprise_registration}🤠
                      </Link>
                      <ResetPassword/>
                    </Grid>
                  </Grid>
                </form>

                <Grid container justify="center" alignItems="center">
                  <Grid item>
                    {/* {img3} */}
                   <img src="/imgs/公众号.jpeg" title="善美融单公众号" alt='善美融单公众号'width={150} height={150} style={{backdropFilter: 'blur(0px)',marginTop: '12px'}}></img> 
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}
      />
    );
  }
}

export default compose(
  withStyles(useStyles, {name: 'login1'}),
  connect(state => ({auth: state.auth}), {loginAction})
)(SignIn);
