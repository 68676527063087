const { string, date } = require('yup');
// const decimals = /^([1-9]|[1-9]\d|100)(\.\d{1,2})?$/ //数字1-100 保留两位小数
//短信信息维护
export const C712Formik = (form2) => {
  return {
    enterprise_name:
      string()
        .trim().required(form2.enterprise_name),
    debt_number:
      string()
        .trim().required(form2.debt_number),
    note_received_by:
      string()
        .trim().required(form2.note_received_by),
    note_received_number:
      string()
        .trim().required(form2.note_received_number),
    note_send_date:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),
    note_end_date:
      date()
        .min(new Date(1900, 0, 1), '时间不能小于1900年1月1日'),

  }

}



//应收款池
// export const C1442Formik = (form2) => {
//   return {

//     impawn_rate:
//       number()
//         .required(form2.impawn_rate)
//         .max(100, '数字不能大于100')
//         .test('impawn_rate', '请输入1~100的数字最多保留2位小数', value => decimals.test(value)),

//   }

// }




