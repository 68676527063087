
import getSubRouters from './helper';

export const subRootAry4 = [
    '应收账款池查询',
    '应收账款质押预警',
  ];


const PARENT_PATH = '/risk-management/应收账款管理';
  const PATH = '应收账款池';
  export default getSubRouters(PARENT_PATH, PATH, subRootAry4);
