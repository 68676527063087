import React from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  AssignmentReturned as AssignmentReturnedIcon,
  Payment as PaymentIcon,
  MergeType as MergeTypeIcon
} from '@material-ui/icons';
import Accordion from '../components/Accordion'
import subRouters, { SetView } from './common'
import C31, { subRootAry1 } from './containers/C3_1';
import C32, { subRootAry2 } from './containers/C3_2';
import C33, { subRootAry3 } from './containers/C3_3';
const PARENT_PATH = subRouters['融单流转'];

const Info = [
  {
    path: "融单收支",
    icon: PaymentIcon,
    // component: C31,
  },
  {
    path: "融单融资",
    icon: MergeTypeIcon,
    // component: C32
  },
 /* {
    path: "转让回执",
    icon: AssignmentReturnedIcon,
    // component: C33
  },*/
];

const view = {};

[...subRootAry1, ...subRootAry2, ...subRootAry3].forEach((item, index) => {
  switch (item) {
    case '融单签收':
      view[item] = SetView('m3-1-1');
      break;
    case '可用融单':
      view[item] = SetView('m3-1-2');
      break;
    case '支付融单查看':
      view[item] = SetView('m3-1-3');
      break;
    case '已融资融单':
      view[item] = SetView('m3-1-4');
      break;
    case '已支付融单':
      view[item] = SetView('m3-1-5');
      break;
    case '已到期融单':
      view[item] = SetView('m3-1-6');
      break;
    case '未到期融单':
      view[item] = SetView('m3-1-12');
      break;
    case '原始融单':
      view[item] = SetView('m3-1-7');
      break;
    case '融单流转':
      view[item] = SetView('m3-1-8');
      break;
    case '融单流转复核':
      view[item] = SetView('m3-1-9');
      break;
    case '已退回流转':
      view[item] = SetView('m3-1-10');
      break;
    case '已拒签流转':
      view[item] = SetView('m3-1-11');
      break;
    case '融资申请':
      view[item] = SetView('m3-2-1');
      break;
    case '融资复核':
      view[item] = SetView('m3-2-2');
      break;
    case '融资查看':
      view[item] = SetView('m3-2-3');
      break;
    case '询价记录':
      view[item] = SetView('m3-2-4');
      break;
    case '融资申请中':
      view[item] = SetView('m3-2-5');
      break;
    // case '融资查看':
    //   view[item] = SetView('m3-2-6');
    //   break;
    case '放款异常':
      view[item] = SetView('m3-2-7');
      break;
    case '已退回融资':
      view[item] = SetView('m3-2-8');
      break;
    case '查看利率表':
      view[item] = SetView('m3-2-9');
      break;
    case '融资放款明细':
      view[item] = SetView('m3-2-10');
      break;
    case '融资时间及费率':
      view[item] = SetView('m3-2-11');
      break;
    case '下属供应商已融资查看':
      view[item] = SetView('m3-2-12');
      break;
    case '待处理':
      view[item] = SetView('m3-3-1');
      break;
    case '已处理':
      view[item] = SetView('m3-3-2');
      break;
    default: ;
  }
})



export const Menu3 = () => {


  const list = Info.map((item, index) => {
    const CompIcon = item.icon;
    switch (index) {
      case 0:
        return <C31 key="C31" />;
      case 1:
        return <C32 key="C32" />;
      case 2:
        return <C33 key="C33" />;
      default:
        return (
          <ListItem button component={Link} to={`${PARENT_PATH}/${item.path}`} key={item.path}>
            <ListItemIcon>
              <CompIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={item.path} />
          </ListItem>
        );
    }
  });
  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
};

export const Content3 = () => {
  let list = [...subRootAry1, ...subRootAry2, ...subRootAry3];
  return (
    <Switch>
      <Redirect
        exact
        from={PARENT_PATH}
        to={`${PARENT_PATH}/${subRootAry1[0]}`}
      />
      {Info.map(item => (
        <Route
          path={`${PARENT_PATH}/${item.path}`}
          component={item.component}
          key={item.path}
        />
      ))}
      {list.map(item => {
        if (view[item]) {
          return (
            <Route
              path={`${PARENT_PATH}/${item}`}
              component={view[item]}
              key={item}
            />

          )
        } else {
          return (
            <Route
              path={`${PARENT_PATH}/${item}`}
              render={() => <h2>{item}</h2>}
              key={item}
            />
          )
        }

      })}
    </Switch>
  )
}
