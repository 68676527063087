import React, {Fragment, Component} from 'react'
// import {Redirect} from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import {HEADERS} from '../state/ActionTypes'
import { loginInfos } from '../components/numToText';
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min';

// import CustomizedSnackbars from '../components/message'

// redux-form (react example)
export const defer = ms => {
  const promise = new Promise((resolve, reject) => {
    ms = ms || 2000;
    setTimeout(() => resolve(), ms);
  });
  return promise;
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const isEmpty = prop => {
  return prop === null || prop === undefined ||
    (prop.hasOwnProperty("length") && prop.length === 0) ||
    (prop.constructor === Object && Object.keys(prop).length === 0)
};

export const Loading = loadingProp => Comp => {
  return class extends Component {
    render() {
      return isEmpty(this.props[loadingProp])
        ? <Fragment>
          <div className="loader"/>
        </Fragment>
        : <Comp {...this.props} />;
    }
  }
};
//加密方法
const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCKDX42QZqvmHjeABI3Iaeq6VZHB0yuv2tjyuXZklRKpsY9Bp3G6vJU3BfcuZoYa2FTmYEzGbd9okfCpNX7UvB0RVHIujkwE70wJTkgiLAkfv81OhUSuXF11UUDSCVEnYEzxTef90FPA31ex3+xyiUgwdfrIH1/IMhvGM6lTehvkwIDAQAB";

// 公钥加密
function encrypt(data) {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const encryptData = encrypt.encrypt(data);
  return encryptData;
  }

/**
 * 1. local 加token，有content-type和accept
 * 2. dzqz 不加，但有content-type和accept
 * 3. 上传文件，加token，但没有content-type
 *  in case encType="multipart/form-data", remove "Content-Type": "application/json; charset=UTF-8",
 */
export const fetching = (url, opts = {}, isFileOrProxy) => {
  let body;
  let headers = {};
  var timestamp = new Date().getTime().toString()
  let customTime = timestamp + '-' + ((Math.random().toString()).substring(2, 8))
  const customSign = encrypt(timestamp + '-' + (Math.random().toString()).substring(2, 12));
  const token = sessionStorage.getItem('authToken');
  const sid = sessionStorage.getItem("sid")
  // 电子签章
  if (isFileOrProxy === 2) {
    headers = {
      ...HEADERS,
      'sid':sid,
      'customTime':customTime,
      'customSign':customSign,
      ...opts.headers
    };
  }else {
    if (isFileOrProxy !== 99) {
      var xhr;
      if (window.XMLHttpRequest) {
        //大多数浏览器
        xhr = new XMLHttpRequest();
      } else {
      //     古董级浏览器
          // eslint-disable-next-line no-undef
          xhr = new ActiveXObject("Microsoft.XMLHTTP");
      }
        if (!token) {
          console.error('权限认证失败，请先注册');
          return null; //TODO: Redirect
        } 
        
        // 文件上传, 不要content-type
        if (isFileOrProxy === 1) {
          headers = {
            'Accept': HEADERS.Accept,
            'x-access-token': token,
            'sid':sid,
            'customTime':customTime,
            'customSign':customSign,
            ...opts.headers
          }
        } else {
          headers = {
            ...HEADERS,
            'x-access-token': token,
            'sid':sid,
            'customTime':customTime,
            'customSign':customSign,
            ...opts.headers
          }
        }
    }

  }

  const method = opts.method || 'GET';
  if (opts.body) body = opts.body;

  return fetch(url, {method, headers, body})
    .then(res => {
      return res.json()
     })
    .then(res => {
       if (null !== res.interceptMsg && res.interceptMsg !== undefined) {
        if (res.interceptCode === '401' || res.interceptCode === '400' || res.interceptCode === '402') {
          alert(res.interceptMsg);
          const path = `http://www.erongdan.com/signin`;
          window.location.replace(path);
          xhr.send();
          return null;
        } 
       } else {
        return res;
       }
     })
    .catch(e => console.error('操作失败: ', e.message));
};

// const fetching_old = (url, opts = {}) => {
//   let body;
//   const headers = {...HEADERS, ...opts.headers}
//   const method = opts.method || 'GET';
//   if (opts.body) body = opts.body;

//   return fetch(url, {method, headers, body}).then(res => {
//     return res.json();
//   }).catch(e => {
//     // alert('操作失败 ' + e);
//   });
// };

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const getLoginInfo = (token) => {
  let login_info;

  if (token) login_info = jwt_decode(token);
  else {
    const token = sessionStorage.getItem('authToken');
    login_info = jwt_decode(token);
  }
  return login_info;
};
