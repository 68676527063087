//融单融资界面表单
export default {
    mx_amount: "融单金额*",
    amount: "融资金额*",
    financing_time: "融资申请时间*",
    payment_day: "承诺付款日*",
    interest: "利息*",
    interest_pay: "利息支付方式*",
    net_financing_amount: "净融资额*",
    sign_agreement: "签订《融单开立协议》*",
    view_agreement: "查看协议",
    loan_approval: "查看放款审批表",
    // Upload_material: "上传贸易资料*",
    Upload_contracts: "上传合同*",
    Upload_invoice: "上传发票*",
    Upload_dispatch: "上传发货单*",
    Upload_shipping: "上传运输单*",

    view_case: "查看案例",
    synthesis_rate: "预计综合融资息费率（%)*",
    remarks: "备注",
    check_opinion: "审核意见"
}
