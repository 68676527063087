import React from 'react';
import { subRouters } from './common';
import { SetView } from './commonTable';
import {
  OfflineBolt as OfflineBoltIcon,
  History as HistoryIcon,
} from '@material-ui/icons';
import { Switch, Route,Redirect, Link } from 'react-router-dom';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
  } from '@material-ui/core';
import { subRootAry1 } from './containers/C18_1'
import { subRootAry2 } from './containers/C18_2'
import { subRootAry3 } from './containers/C18_3'
import { subRootAry4 } from './containers/C18_4'
import { subRootAry5 } from './containers/C18_5'
import { subRootAry6 } from './containers/C18_6'
import { subRootAry8 } from './containers/C18_8'
import { subRootAry9 } from './containers/C18_9'

import { subRootAry91 } from './containers/C19_1'
import { subRootAry92 } from './containers/C19_2'
import { subRootAry93 } from './containers/C19_3'
import { C19_1 as C191  } from './containers'
import { C19_2 as C192  } from './containers'
import { C19_3 as C193  } from './containers'

import { C18_1 as C181  } from './containers'
import { C18_2 as C182  } from './containers'
import { C18_3 as C183  } from './containers'
import { C18_4 as C184  } from './containers'
import { C18_5 as C185  } from './containers'
import { C18_6 as C186  } from './containers'
import { C18_8 as C188  } from './containers'
import { C18_9 as C189  } from './containers'

import Accordion from '../components/Accordion'
const PARENT_PATH = subRouters['外部融资'];
const subRootAry = [
    ...subRootAry1,
    ...subRootAry2,
    ...subRootAry3,
    ...subRootAry4,
    ...subRootAry5,
    ...subRootAry6,
    ...subRootAry8,
    ...subRootAry9,
    ...subRootAry91,
    ...subRootAry92,
    ...subRootAry93,
  ];
const Info = [
  {
    path: "招行融资",
    icon: OfflineBoltIcon,
    component: C181,
  },
  {
    path: "浙商融资",
    icon: OfflineBoltIcon,
    component: C182,
  },
  {
    path: "中信融资",
    icon: OfflineBoltIcon,
    component: C183,
  },
  {
    path: "北金所融资",
    icon: OfflineBoltIcon,
    component: C184,
  },
  {
    path: "光大融资",
    icon: OfflineBoltIcon,
    component: C185,
  },
  {
    path: "北京银行融资",
    icon: OfflineBoltIcon,
    component: C186,
  },
  {
    path: "浦发银行融资",
    icon: OfflineBoltIcon,
    component: C188,
  },
  {
    path: "汇通融资",
    icon: OfflineBoltIcon,
    component: C189,
  },
 /* {
    path: "中信银行预审",
    icon: OfflineBoltIcon,
    component: C191,
  },*/
  {
    path: "光大银行预审",
    icon: OfflineBoltIcon,
    component: C192,
  },
  {
    path: "北京银行预审",
    icon: OfflineBoltIcon,
    component: C193,
  },
];
const view = {};
subRootAry.forEach(item => {
  switch (item) {
    case '招行融资查看':
      view[item] = SetView('r6-5-1');
      break;
    case '招行已完成融资':
      view[item] = SetView('r6-5-2');
      break;
    case '浙商融资查看':
      view[item] = SetView('r18-2-1');
      break;
    case '浙商已完成融资':
      view[item] = SetView('r18-2-2');
      break;
    case '中信融资查看':
      view[item] = SetView('r18-3-1');
      break;
    case '中信已完成融资':
      view[item] = SetView('r18-3-2');
      break;  
    case '北金所保理融资发起':
      view[item] = SetView('r18-4-4');
      break;
    case '北金所非保理融资查看':
      view[item] = SetView('r18-4-1');
      break;
    case '北金所保理融资查看':
      view[item] = SetView('r18-4-2');
      break;
    case '北金所已完成融资':
      view[item] = SetView('r18-4-3');
      break;
    case '光大保理融资发起':
      view[item] = SetView('r18-5-1');
      break;
    case '光大保理融资查看':
      view[item] = SetView('r18-5-2');
      break;
    case '光大非保理融资查看':
      view[item] = SetView('r18-5-3');
      break;  
    case '光大非保理已完成融资':
      view[item] = SetView('r18-5-4');
      break; 
    case '光大保理已完成融资':
      view[item] = SetView('r18-5-5');
      break; 
    case '北京银行直融融资查看':
      view[item] = SetView('r18-6-1');
      break;
    case '北京银行直融已完成融资':
      view[item] = SetView('r18-6-2');
      break;
    case '浦发融资查看':
      view[item] = SetView('r18-7-1');
      break;
    case '浦发已完成融资':
      view[item] = SetView('r18-7-2');
      break;
    case '汇通融资查看':
      view[item] = SetView('r18-9-1');
      break;
    case '汇通已完成融资':
      view[item] = SetView('r18-9-2');
      break;
    case '中信融资预审':
      view[item] = SetView('r19-1-1');
      break;
    // case '中信已完成融资':
    //   view[item] = SetView('r19-1-2');
    //   break;
    case '光大融资预审':
      view[item] = SetView('r19-2-1');
      break;
    case '光大已完成融资':
      view[item] = SetView('r19-2-2');
      break;
    case '北京银行融资预审':
      view[item] = SetView('r19-3-1');
      break;
    default: ;
  }
})
const getMenu = (parent_path, info) => () => {
  const list = info.map((item, index) => {
    const CompIcon = item.icon;
    switch (index) {
      case 0:
        return <C181 key="C181" />;
      case 1:
        return <C182 key="C182" />;
      case 2:
        return <C183 key="C183" />;
      case 3:
        return <C184 key="C184" />;
      case 4:
        return <C185 key="C185" />;  
      case 5:
        return <C186 key="C186" />;  
      /*case 6:
        return <C191 key="C191" />;*/
      case 6:
        return <C192 key="C192" />;
      case 7:
        return <C193 key="C193" />;
      case 8:
        return <C188 key="C188" />;
      case 9:
        return <C189 key="C189" />;
      default:
        return (
          <ListItem button component={Link} to={`${parent_path}/${item.path}`} key={item.path}>
            <ListItemIcon>
              <CompIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={item.path} />
          </ListItem>
        );
    }
  });
  return (
    <div>
      {list}
      <ListItem><Accordion /></ListItem>
    </div>
  );
};
const getContent = (parent_path, info) => () => {
    return (
      <Switch>
        <Redirect
          exact
          from={PARENT_PATH}
          to={`${PARENT_PATH}/${subRootAry1[0]}`}
        />
        {info.map(item => {
          if (item.component) {
            return (
              <Route
                path={`${parent_path}/${item.path}`}
                component={item.component}
                key={item.path}
              />
            )
          }
          else {
            return (
              <Route
                path={`${parent_path}/${item.path}`}
                render={() => <h2>{item.path}</h2>}
                key={item.path}
              />
            )
          }
        })}
  
        {subRootAry.map(item => {
          if (view[item]) {
            return (
              <Route
                path={`${parent_path}/${item}`}
                component={view[item]}
                key={item}
              />
            )
          }
          else {
            // return null;
            return (
              <Route
                path={`${parent_path}/${item}`}
                render={() => <h2>{item}</h2>}
                key={item}
              />
            )
          }
        })}
      </Switch>
  
    )
  };

export default [
  getMenu(PARENT_PATH, Info),
  getContent(PARENT_PATH, Info)
];
