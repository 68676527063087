// import React, { Component } from 'react'
// const chinese=/^[\u4e00-\u9fa5]*$/ //中文
// const decimals=/^([1-9]|[1-9]\d|100)(\.\d{1,2})?$///数字1-100 保留两位小数
// const number = /^\d{1,12}$/ //数字
// const phone=/^1[3456789]\d{9}$/ //手机号码
// const certificate = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)///身份证

//登录
export const loginFormik = (values, form2) => {


  // same as above, but feel free to move this into a class method now.
  let errors = {};
  if (!values.user) {//姓名
    errors.user = form2.user;
  } else if (values.password === '') {//密码
    errors.password = form2.password;
  }
  return errors;
}

//修改密码
export const pwdFormik = (values, form2) => {
  
  const pwd1 = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,18}$/
  // same as above, but feel free to move this into a class method now.
  let errors = {};
  if (!values.old_password) {
    errors.old_password = form2.old_password;
  } else if (!values.new_password) {
    errors.new_password = form2.new_password;
  } else if (values.old_password === values.new_password) {
    errors.new_password = '新密码不能与原密码一致';
  } else if (!values.confirm_password) {
    errors.confirm_password = form2.confirm_password;
  } else if (values.confirm_password !== values.new_password) {
    errors.confirm_password = '两次输入密码不一致';
  } else if (!pwd1.test(values.new_password)) {
    errors.new_password = '密码不符合规范(最少8位,且必须包含字母、数字和特殊字符)';
  } else if (!pwd1.test(values.confirm_password)) {
    errors.confirm_password = '密码不符合规范(最少8位,且必须包含字母、数字和特殊字符)';
  }
  return errors;
}

//注册
export const enterpriseFormik = (values, form2) => {


  // same as above, but feel free to move this into a class method now.
  let errors = {};
  if (!values.enterprise_name) {
    errors.enterprise_name = form2.enterprise_name;
  } else if (values.new_password === '') {
    errors.new_password = form2.new_password;
  } else if (values.confirm_password === '') {
    errors.confirm_password = form2.confirm_password;
  } else if (values.confirm_password !== values.new_password) {
    errors.confirm_password = '两次输入密码不一致';
  }
  return errors;
}
