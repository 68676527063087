import React, { Component } from 'react'
import { Formik } from 'formik';
import { fetching } from '../../config/utils';
import {
  TextField,
  Button,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import FormikError from '../../components/commonForm/formikError';
import { roleFormik } from './formikValidate'

const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }
}));
class Form extends Component {
  state = {
    form: this.getInitState(),
    form2: this.props.form2 || {},//静态资源
    organizations: [],
  }
  async componentDidMount() {
    this.loadResources('organizations');
    // this.handleQueryRole();
    // this.loadResources('departments', 'departments');


  }
  loadResources = async (resource, url = resource) => {
    fetching(`/api/${url}`).then(data => this.setState({ [resource]: data }))
  };

  getInitState() {
    const { exercise } = this.props;
    if (exercise.organizations) {
      exercise.organization_id = exercise.organizations.length > 0 ? exercise.organizations[0].id : "";
    }

    const form = {
      organization_id: "",
      departmentName: "",
      roleName: "",
      permissions:"",
      desc: "",
      DialogTitle: "创建用户",
      save: "1", //判断是新增（1）还是编辑（2）
    }
    return exercise.save !== '1' ? exercise : form
  }

  // 查询全部角色
  handleQueryRole() {
    fetching('/msapi/roles/find', {
      method: 'GET'
    }).then(data => {
      this.setState({ roles: data })
    })
  }


  //保存 提交
  handleSubmit = (form2, name) => {
    

    let form = form2 || this.state.form;
    const text = name || '撤销';
    // let method = form.save === '2' ? 'PUT' : 'POST';
    // let ids = form.save === '2' ? form.id : '';
    // let params;
    // let params1 = {
    //   organization_id: form.organization_id,
    //   departmentName: form.organization_id === 2 ? form.departmentName : null,
    //   roleName: form.roleName,
    //   permissions: form.permissions,
    //   desc: form.desc,
    // }
    // params = params1
    //提交表单
    if (text === '提交') {
      // fetching(`/api/user/find/${ids}`, {
      //   method,
      //   // token: this.state.hasToken,
      //   body: JSON.stringify(params)
      // }).then((data) => {
      //   this.props.onSubmit({
      //     ...form,
      //     submit_name: text
      //   })
      // })
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
    } else {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
    }
  }

  render() {
    const form2 = this.state.form2;
    const form = this.state.form;

    const { exercise } = this.props;

    const status = (handleSubmit, isSubmitting, values) => {
      return <div style={{ padding: '10px 0' }}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          disabled={isSubmitting}
          name={form2.submit}
          style={{ marginRight: '10px' }}
        >
          {form2.submit}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={this.handleSubmit.bind(this, values, '撤销')}
          name={form2.revocation}
          disabled={isSubmitting}
          style={{ marginRight: '10px' }}
        >
          {form2.revocation}
        </Button>
      </div>
    }
    return (
      <Formik
        initialValues={{
          ...form
        }}
        validate={(values) => {
          return roleFormik(values, form2);
        }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, '提交');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
            <form>
              <Grid container>
                <FormControl className={`${this.props.classes.field} ${this.props.classes.top}`}>
                  <InputLabel htmlFor="organization_id">{form2.organization_id}</InputLabel>
                  <Select
                    value={values.organization_id || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      name: "organization_id",
                      id: "organization_id",
                    }}
                    disabled={exercise.save === '3'}
                  >
                    {this.state.organizations.map(r => <MenuItem value={r.id} key={r.id}>{r.name}</MenuItem>)}
                  </Select>
                </FormControl>
                <FormikError touched={touched} errors={errors} name={'organization_id'} />
              </Grid>

              <Grid container justify="space-between">
                <TextField
                  label={form2.departmentName}
                  value={values.departmentName}
                  name='departmentName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3' || values.organization_id !== '2'}
                />
                <FormikError touched={touched} errors={errors} name={'departmentName'} />
              </Grid>

              <Grid container justify="space-between">
                <TextField
                  label={form2.roleName}
                  value={values.roleName}
                  name='roleName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'roleName'} />
              </Grid>
              <Grid container>
                <TextField
                  multiline
                  rows='4'
                  label={form2.permissions}
                  value={values.permissions || ''}
                  name='permissions'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'permissions'} />
              </Grid>

              <Grid container>
                <TextField
                  multiline
                  rows='4'
                  label={form2.desc}
                  value={values.desc || ''}
                  name='desc'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={exercise.save === '3'}
                />
                <FormikError touched={touched} errors={errors} name={'desc'} />
              </Grid>
              {
                status(handleSubmit, isSubmitting, values)
              }
            </form>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);



