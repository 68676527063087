import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Link,
  Typography,
} from '@material-ui/core';

import EnterpriseForm from './EnterpriseForm';
import CertificationForm from './CertificationForm';
// import CustomSealForm from './CustomSealForm';
import MoreInfoForm from './MoreInfoForm';
import { form_signup3 } from '../formStatic'
import { HEADERS } from '../../state/ActionTypes';
import { Copyright } from '../../config/reusable'

const useStyles = (theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    "justify-content": 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  errorMsg: {
    color: '#f00',
    textAlign: 'center',
    height: '20px',
  }
}));

function getSteps() {//页面步骤跳转1,2,3
  return [form_signup3.first_step, form_signup3.second_step, form_signup3.last_step];
}

class Signup3 extends Component {
  state = {
    activeStep: 0,
    steps: getSteps(),
    form: {},//企业注册信息
  }
  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 })
  };
  handleNext = (data) => {
    const { form } = this.state;
    if (data !== undefined && data !==null) {
      this.setState({ activeStep: this.state.activeStep + 1, form: { ...form, ...data } })
    } else {
      this.setState({ activeStep: this.state.activeStep + 1 })
    }
  };
  handleSubmit = (data) => {
    const { form } = this.state;
    const formData = {
      ...form,
      ...data
    }
    fetch('/msapi/account/save', {
      method: 'post',
      headers: HEADERS,
      body: JSON.stringify(formData),
    }).then(res => res.json()).then(res => {
      if (res !== undefined && res !==null) {
        if (res.msg) {
          alert(`注册失败，${res.msg}`)
          return;
        }
        this.setState({ activeStep: this.state.activeStep + 1, form: { ...form, ...data } })
        localStorage.removeItem("registerForm1");
        localStorage.removeItem("registerForm3");
      } else {
        alert(`注册失败`)
      }

    });
  };
  render() {
    const { classes } = this.props;
    const { activeStep, steps } = this.state;
    const { form } = this.state;
    const getStepContent = (stepIndex) => {
      switch (stepIndex) {
          //注册第一步
        case 0:
          return <EnterpriseForm
              form1={form}
              form_signup3={form_signup3}
              activeStep={activeStep}
              steps={steps}
              handleNext={this.handleNext}
          >
          </EnterpriseForm>;
          //第二步实名认证
        case 1:
          return <CertificationForm
              form={form}
              form_signup3={form_signup3}
              activeStep={activeStep}
              steps={steps}
              handleNext={this.handleNext}
              handleBack={this.handleBack}>
          </CertificationForm>;
          //第三步补充信息
        case 2:
          return <MoreInfoForm
              form={form}
              form_signup3={form_signup3}
              activeStep={activeStep}
              steps={steps}
              handleSubmit={this.handleSubmit}
              handleBack={this.handleBack}>
          </MoreInfoForm>;
        default:
          return 'no';
      }
    }
    return (
        <div className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              {form_signup3.process}
            </Typography>

            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
                <div>
                  <Typography variant="h5" gutterBottom>
                    {form_signup3.success_text}
                  </Typography>
                  <Link href="/signin" variant="body2">
                    {form_signup3.to_login}
                  </Link>
                </div>
            ) : (
                <div>
                  {getStepContent(activeStep)}
                </div>
            )}
          </Paper>
          <Copyright />
        </div>
    );
  }
}
export default withStyles(useStyles)(Signup3)
