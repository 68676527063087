
import getSubRouters from './helper';

 export const subRootAry41 = [
    '新增额度申请',
    '额度申请审核',
    '额度申请查询',
    '已退回额度申请'
  ];
const PARENT_PATH = '/risk-management/额度审批';
const PATH = '额度申请';
export default getSubRouters(PARENT_PATH, PATH, subRootAry41);