// const { string, number, date } = require('yup');
// const decimals = /^([1-9]|[1-9]\d|100)(\.\d{1,2})?$///数字1-100 保留两位小数

// const numbers = /^\d{1,12}$/ //数字
const phone = /^1[3456789]\d{9}$/ //手机号码
// let length = 50;
// let lenMsg = '长度不能超过50'

//创建用户
export const userFormik = (values, form2) => {
  const pwd1 = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,18}$/
  let errors = {};
  if (!values.name) {//姓名
    errors.name = form2.name;
  }
  if (!values.account) {//用户名
    errors.account = form2.account;
  }
  if (!values.phone) {
    errors.phone = form2.phone;
  } else if (!phone.test(values.phone)) {
    errors.phone = '手机号码格式不正确';
  }
  if (values.password === '') {//密码
    errors.password = form2.password;
  }
  if (values.confirm_password === '') {//确认密码
    errors.confirm_password = form2.confirm_password;
  } else if (values.confirm_password !== values.password) {//两次密码不一致
    errors.confirm_password = "两次输入密码不一致";
  } else if (values.password !== undefined && !pwd1.test(values.password)) {
    errors.password = '密码不符合规范(最少8位,且必须包含字母、数字和特殊字符)';
  } else if (values.confirm_password !== undefined && !pwd1.test(values.confirm_password)) {
    errors.confirm_password = '密码不符合规范(最少8位,且必须包含字母、数字和特殊字符)';
  }
  if (values.hasOwnProperty('department_id') && !values.department_id) {//部门
    errors.department_id = form2.department_id;
  }
  if (!values.role_id) {//角色
    errors.role_id = form2.role_id;
  }

  return errors;
}

//口令更改

export const pwdFormik = (values, form2) => {
  const pwd1 = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,18}$/
  // same as above, but feel free to move this into a class method now.
  let errors = {};
  if (values.hasOwnProperty('old_password') && !values.old_password) {
    errors.old_password = form2.old_password;
  }
  if (!values.new_password) {
    errors.new_password = form2.new_password;
  }
  if (values.hasOwnProperty('old_password') && values.old_password === values.new_password) {
    errors.new_password = '新密码不能与原密码一致';
  }
  if (!values.confirm_password) {
    errors.confirm_password = form2.confirm_password;
  } else if (values.confirm_password !== values.new_password) {
    errors.confirm_password = '两次输入密码不一致';
  } else if (!pwd1.test(values.new_password)) {
    errors.new_password = '密码不符合规范(最少8位,且必须包含字母、数字和特殊字符)';
  } else if (!pwd1.test(values.confirm_password)) {
    errors.confirm_password = '密码不符合规范(最少8位,且必须包含字母、数字和特殊字符)';
  }
  return errors;
}

//角色管理维护
export const roleFormik = (values, form2) => {
  // same as above, but feel free to move this into a class method now.
  let errors = {};
  if (!values.organization_id) {
    errors.organization_id = form2.organization_id;
  }
  if (values.organization_id === 2 && !values.departmentName) {
    errors.departmentName = form2.departmentName;
  }
  if (!values.roleName) {
    errors.roleName = form2.roleName;
  }
  if (!values.permissions) {
    errors.permissions = form2.permissions;
  }
  return errors;
}

export const IpFormik = (values, form2) => {
  //const pwd1 = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,18}$/
  // same as above, but feel free to move this into a class method now.
  let errors = {};
  
  return errors;
}
