import React, { Component } from 'react';
import Select from 'react-select';
import {
    TextField,
    Button,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Select as Select2,
    CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import FormikError from './formikError';
import { fetching } from '../../config/utils';


const yesornoList = ['内部用户', '外部用户'];


const loadingStyle = {
    position: "absolute",
    color: green[500],
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -17,
}
const useStyles = (theme => ({
    formControl: {
        marginTop: '16px',
        marginBottom: '8px',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    width: '100%',
    label: {
        textAlign: 'left',
    },
    radio: {
        flexDirection: 'row',
    },
}));

const styles = {
    width: '100%',
    margin: '16px 0 8px 0'
}

class Form1 extends Component {
    state = {
        form: this.getInitState(),
        selectCustomerList: [],
        loading: false,
    }
    getInitState() {
        const { exercise = {} } = this.props;
        const form2 = {
            select_customer: '',
            enterprise_name: "",
            enterprise_no: "",
            organization_code: "",
        }
        if (exercise.save === '1') {
            form2.select_customer = ''
        } else {
            form2.select_customer = { value: exercise.enterprise_name, label: exercise.enterprise_name, enterprise_no: exercise.enterprise_no }
        }
        return { ...form2, ...exercise }
    }
    async componentDidMount() {
        if (!this.state.form.enterprise_no) {
            this.queryEnterpriseInfo();
        }
    }

    //保存
    handleSave = (values) => {
        if (values.internal === "内部用户") {
            values.internal = 1
        } else {
            values.internal = 0
        }

        // POST
        fetching(`/msapi/account/updateAccountById`, {
            method:"post",
            body: JSON.stringify({
                id: values.id,
                internal: values.internal
            })
        }).then((data) => {
            if (data && data === 1) {
                this.props.onSubmit({
                    ...this.state.form,
                })
            } else {
                alert("网络异常")
            }
        })
    };

    render() {
        const { exercise = {}, formStatic, classes } = this.props;
        const { form, selectCustomerList } = this.state;
        //debugger
        return (
            <Formik
                //初始化数据
                initialValues={{
                    ...form
                }}

                onSubmit={(values) => {
                    this.handleSave(values);
                }}
                
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                        <form method="post" encType="multipart/form-data">
                            <Grid item>
                                <div style={{ margin: '5px 0' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{formStatic.select_customer}</span>
                                    <Select
                                        isSearchable
                                        value={values.select_customer}
                                        isDisabled={exercise.save === '3'}
                                    />
                                    <FormikError touched={touched} errors={errors} name={'select_customer'} />
                                </div>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.enterprise_no}
                                        value={form.enterprise_no}
                                        name='enterprise_no'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label={formStatic.credit_no}
                                        value={form.credit_no}
                                        name='credit_no'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin='normal'
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        disabled={exercise.save === '3'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item xs={3}>
                                    <FormControl component="fieldset" style={styles} className={classes.radio}>
                                        <FormLabel component="legend">{formStatic.enterprise_type}</FormLabel>
                                        <RadioGroup className={classes.radio} style={{ flexDirection: 'row' }} aria-label="gender" name='internal' value={values.internal}
                                            onChange={e => setFieldValue('internal', e.target.value)}>
                                            {yesornoList.map(item => {
                                                return (
                                                    <FormControlLabel
                                                        key={item}
                                                        name={item}
                                                        value={item}
                                                        disabled={exercise.save === '3'}
                                                        control={<Radio />}
                                                        label={item} />
                                                        
                                                )
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                    <FormikError touched={touched} errors={errors} name={'internal'} />
                                </Grid>

                            </Grid>

                            <div style={{ margin: '20px 0', position: 'relative' }}>
                                {exercise.save !== '3' &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={this.state.loading}
                                    >
                                        {formStatic.save}
                                    </Button>
                                }
                            </div>
                        </form>
                    )}
            />
        );
    }
}

export default withStyles(useStyles)(Form1);