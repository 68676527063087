import React, { Component } from 'react';
import {
  Button,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { fetching } from '../../../config/utils';

const useStyles = (theme => ({
  error: {
    color: '#f00',
  },
  pass: {
    color: '#67C23A',
  }
}));

const styles = {
  textAlign: 'center',
  lineHeight: '30px'
}
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form4 extends Component {
  state = {
    flag: true,
    form: this.init(),

  }
  init() {
    const { form } = this.props;
    let queryUrl = [
      { id: 1, name: 'customer_infos', query: '/msapi/customer_info/find?enterprise_no=' + form.enterprise_no, check: false },
      { id: 2, name: 'business_license', query: '/msapi/license/find?enterprise_no=' + form.enterprise_no, check: false, yearly_check: false },
      { id: 3, name: 'management_info', query: '/msapi/customer_admin/find?enterprise_no=' + form.enterprise_no, check: false },
      { id: 4, name: 'shareholders_info', query: '/msapi/people/findc?enterprise_no=' + form.enterprise_no, check: false },
    ]
    return queryUrl

  }
  componentWillMount() {
    const { form } = this.state;
    let form2 = [...form];
    let flag = true;

    form.forEach((item, index) => {
      fetching(item.query, { method: 'GET' }).then(resData => {
        if (Array.isArray(resData) && resData.length > 0) {
          if (item.name === 'business_license' && resData[0].yearly_check === "是") {
            form2[index].check = true;
            form2[index].yearly_check = true;
          } else if (item.name === 'business_license' && resData[0].yearly_check === "否") {
            form2[index].check = true;
            form2[index].yearly_check = false;
            flag = false;
          } else {
            form2[index].check = true;
          }
        } else {
          form2[index].check = false;
          flag = false;
        }
        this.setState({ form: form2, flag })
      })
    })


  }

  //下一步
  handleNext = () => {
    const { flag } = this.state;
    if (flag) {
      this.props.handleNext();
    }
  };
  //上一步
  handleBack = () => {
    this.props.handleBack();
  };
  //保存
  handleSave = () => {
    this.props.handleLoading(true)
    this.props.handleSave();
  };

  render() {
    const { activeStep, exercise = {}, formStatic, classes } = this.props;
    const { form = [], flag } = this.state;
    return (
      <form method="post" encType="multipart/form-data">
        {form.map((item) => {
          if (item.name === 'business_license') {
            return (
              <div key={item.id}>
                <Grid container justify="space-around" >
                  <Grid item xs={4} style={styles}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formStatic[item.name]}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={styles}>
                    <Typography variant="subtitle1" gutterBottom className={item.check ? classes.pass : classes.error}>
                      {/* {item.check} */}
                      {item.check ? formStatic.pass : formStatic.no_pass}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justify="space-around">
                  <Grid item xs={4} style={styles}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formStatic[item.name]}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={styles}>
                    <Typography variant="subtitle1" gutterBottom className={item.yearly_check ? classes.pass : classes.error}>
                      {item.yearly_check ? formStatic.yearly_check : formStatic.no_yearly_check}
                      {/* {item.yearly_check} */}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )
          } else {
            return (
              <Grid container justify="space-around" key={item.id}>
                <Grid item xs={4} style={styles}>
                  <Typography variant="subtitle1" gutterBottom>
                    {formStatic[item.name]}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={styles}>
                  <Typography variant="subtitle1" gutterBottom className={item.check ? classes.pass : classes.error}>
                    {item.check ? formStatic.pass : formStatic.no_pass}
                    {/* {item.check} */}
                  </Typography>
                </Grid>
              </Grid>
            )
          }
        })}
        {!flag && <Grid item xs={12} style={styles} className={classes.error}>
          <Typography variant="body2" gutterBottom >注：请前往客户管理完善客户信息。</Typography>
        </Grid>
        }
        <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
          <Button
            disabled={activeStep === 0}
            onClick={this.handleBack}
          >
            上一步
                            </Button>
          <Button style={{ margin: '0 10px' }} variant="contained" color="primary" onClick={this.handleNext}>
            下一步
                            </Button>
          {exercise.save !== '3' &&
            <div style={{ position: 'relative' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.props.loading}
                      onClick={this.handleSave}
                    >
                      保存</Button>
                    {this.props.loading && <CircularProgress size={24} style={loadingStyle} />}
                  </div>
          }
        </div>
      </form>
    );
  }
}

export default withStyles(useStyles)(Form4);