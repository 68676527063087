import React, { Component } from 'react'
// import { fetching } from '../../config/utils';
import {
  TextField,
  Button,
  Grid,
  Select as Select2,
  InputLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core'
import cnLocale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { r7_1_2 } from '../formStatic';
import { Formik } from 'formik';
import FormikError from './formikError';
import { C712Formik } from './formikValidate';
import { formatDate } from '../../../components/date';

const { object } = require('yup');
const useStyles = (theme => ({
  field: {
    width: '100%',
  },
  top: {
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),

  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  }
}));
class Form extends Component {
  state = {
    form: this.getInitState(),
    formStatic: { ...r7_1_2 },//静态资源
    note_send_interval_list: ['年', '月', '日'],
    note_send_type_list: [' 收息提醒', '还款提醒', '应收账款补质押提醒', '逾期提醒']
  }
  getInitState() {
    const time = new Date();
    const form = {
      enterprise_name: '',//客户名称
      debt_number: "",//借据号
      note_received_by: '',//短信接收人
      note_received_number: "",//短信接收号码
      note_send_date: time,//短信发送时间
      note_end_date: time,//短信结束时间
      note_send_interval: "年",//短信发送间隔
      note_send_type: "逾期提醒"  //短信发送类型

    }
    return form
  }
  handleChange = (e) => {
    const { form } = this.state
    const { name, value } = e.target;
    form[name] = value;
    this.setState({ form: { ...form } })

  }
  //保存 提交
  handleSubmit = (values) => {
    const form = { ...values };
    if (form.note_send_date instanceof Object) {
      form.note_send_date = formatDate(form.note_send_date, 'yyyy/MM/dd');
    }
    if (form.note_end_date instanceof Object) {
      form.note_end_date = formatDate(form.note_end_date, 'yyyy/MM/dd');
    }

  }
  handleReset = () => {
    let form2 = this.getInitState();
    this.setState({ form: form2 })
  }

  render() {
    const { formStatic, form, note_send_type_list, note_send_interval_list } = this.state;
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        validationSchema={object().shape(C712Formik(formStatic))}
        //验证提交
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
            <form style={{ maxWidth: '800px', margin: '0 auto' }}>
              <h4>短信提示信息维护</h4>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.enterprise_name}
                    value={values.enterprise_name}
                    name='enterprise_name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                  />
                  <FormikError touched={touched} errors={errors} name={'enterprise_name'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.debt_number}
                    value={values.debt_number}
                    name='debt_number'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                  />
                  <FormikError touched={touched} errors={errors} name={'debt_number'} />
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.note_received_by}
                    value={values.note_received_by}
                    name='note_received_by'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                  />
                  <FormikError touched={touched} errors={errors} name={'note_received_by'} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={formStatic.note_received_number}
                    value={values.note_received_number}
                    name='note_received_number'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin='normal'
                    fullWidth
                  />
                  <FormikError touched={touched} errors={errors} name={'note_received_number'} />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cnLocale}>
                  <Grid container justify="space-between">
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        style={{ width: '100%' }}
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline"
                        label={formStatic.note_send_date}
                        value={values.note_send_date}
                        onChange={e => setFieldValue('note_send_date', e)}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'note_send_date'} />
                    </Grid>
                    <Grid item xs={5}>
                      <KeyboardDatePicker
                        style={{ width: '100%' }}
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline"
                        label={formStatic.note_end_date}
                        value={values.note_end_date}
                        onChange={e => setFieldValue('note_end_date', e)}
                        onBlur={handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <FormikError touched={touched} errors={errors} name={'note_end_date'} />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <FormControl style={{ width: '100%', margin: '16px 0 8px 0' }} >
                    <InputLabel id="demo-simple-select-label">{formStatic.note_send_interval}</InputLabel>
                    <Select2
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name='note_send_interval'
                      value={form.note_send_interval}
                      onChange={this.handleChange}>
                      >
                                {note_send_interval_list.map(item => {
                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                      })}
                    </Select2>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <FormControl style={{ width: '100%', margin: '16px 0 8px 0' }} >
                    <InputLabel id="demo-simple-select-label">{formStatic.note_send_type}</InputLabel>
                    <Select2
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name='note_send_type'
                      value={form.note_send_type}
                      onChange={this.handleChange}>
                      >
                                {note_send_type_list.map(item => {
                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                      })}
                    </Select2>
                  </FormControl>
                </Grid>
              </Grid>
              <div style={{ padding: '20px 0' }}>
                <Button
                  style={{ width: '100px', marginRight: '10px' }}
                  color='primary'
                  variant='contained'
                  onClick={handleSubmit}
                >
                  {'保存'}
                </Button>
                {/* <Button
                  style={{ width: '100px' }}
                  color='primary'
                  variant='contained'
                  onClick={this.handleReset}
                >
                  {'重置'}
                </Button> */}
              </div>
            </form>
          )}
      />
    )
  }
}

export default withStyles(useStyles)(Form);



