import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { fetching } from '../config/utils';
import View from './common';
import { TabUrl } from './TabUrl'
import { loginInfos } from '../components/numToText';

class DisabledTabs extends Component {
  state = {
    value: 0,
    auth: {},
    data: [],
  };

  componentDidMount() {
    let auth = loginInfos() || {};
    this.setState({ auth });
    const urlList = TabUrl(auth);
    this.onQuery(urlList, auth);

  }
  //查询多个状态
  onQuery = (urlList, auth = {}) => {
    try {
      const rolesId = auth.roles ? auth.roles[0].id : 1
      let fetchingData = urlList.map(item => {
        return fetching(item);
      });
      Promise.all(fetchingData).then(values => {
        let data = []
        values.forEach(item => {
          if (item instanceof Array) {
            data = data.concat(item);
          }
        })
        data.map(item => {
          if (item.status) {
            item.status = item.status ? item.status.name : '';
          } else if (item.auth && item.auth.status) {
            item.status = item.auth.status.name;
          }

          if ('coreCompanyName' in item) {
              item.status = "审核中";
              item.content = '数链应收账款 → ' + '待复核'
          } else if ('financingAmount' in item) {
              if (item.roleId === 5) {
                item.status = "审核中";
              }else if (item.roleId === 6) {
                item.status = "待放款";
              }
              item.content = '数链融资 → ' + item.status
          } else if (item.repayNo !== undefined && item.repayNo !== null) {
              item.status = "审核中";
              item.content = '数链融资 → ' + '待复核'
          }

          if (item.companyStatus !== undefined) {
            if (item.companyStatus === 2) {
              item.status = "已完成";
            } else {
              item.status = "待处理";
            }
            item.content = '清算平台注册 → ' + "待处理"
          }

          if ('discount_rate' in item) {
            if (item.status_id === 3 && item.roleId === 14 ) {
              item.status = "审核中";
              item.content = '融单应收账款 → ' + '待签收'
            } else if (item.status_id === 2 && item.roleId === 11) {
              item.status = "待复核";
              item.content = '融单应收账款 → ' + '待复核'
            } else if (item.status_id === 7 && (item.roleId === 1 || item.roleId === 3 || item.roleId === 8 || item.roleId === 14)) {
              item.status = "待处理";
              item.content = '融单应收账款 → ' + '已退回'
            } else if (item.status_id === 3 && (item.roleId === 12 || item.roleId === 1 || item.roleId === 3)) {
              item.status = "审核中";
              item.content = '融单应收账款 → ' + '审核中'
            } else if (item.status_id === 3 && item.roleId === 11) {
              item.status = "待签章";
              item.content = '融单应收账款 → ' + '待签章'
            } else if (item.status_id === 10 && (item.roleId === 9 || item.roleId === 5)) {
              item.status = "待放款";
              item.content = '融单应收账款 → ' + '待放款'
            } 
           }
	
         if (item.amountStatus !== undefined && item.amountStatus !== 0 ) {
            let statuName = "";
            if (item.amountStatus === 3) {
              statuName = "待复核";
              item.status = "审核中"
            } else if (item.amountStatus === 7) {
              statuName = "待处理";
              item.status = "已退回"
            }
            item.content = '额度冻结 → ' + statuName;
          } 

          if (item.thawingStatus !== undefined && item.thawingStatus !== 0) {
            let statuName = "";
            if (item.thawingStatus === 3) {
              statuName = "待复核";
              item.status = "审核中"
            } else if (item.thawingStatus === 7) {
              statuName = "待处理";
              item.status = "已退回"
            }
            item.content = '额度解冻 → ' + statuName;
          }

          let statuName = rolesId === 9 ? '待签章' : '待审核';
          if (item.status === '待审核') {
            if ('open_day' in item && item.discount_rate === undefined) {
              item.content = '融单开立 → ' + statuName
            } else if ('flow_date' in item) {
              item.content = '融单流转 → ' + statuName
            } else if ('sharing_protocol' in item) {
              item.content = '额度分配 → ' + statuName
            } else if ('subsidiary_amount' in item) {
              item.content = '额度回收 → ' + statuName
            } else if ('subsidiaries' in item) {
              item.content = '企业管理 → ' + statuName
            } else if ('rates' in item) {
              item.content = '利率维护 → ' + statuName
            } else {
              item.content = '融单融资 → ' + statuName
            }
          } else if (item.status === '审核中') {
            if ('open_day' in item && item.discount_rate === undefined) {
              item.content = '融单开立 → ' + statuName
            } else if ('flow_date' in item) {
              item.content = '融单流转 → ' + statuName
            } else if ('mxf_number' in item) {
              item.content = '融单融资 → ' + statuName
            } else if ('available_amount' in item) {
              item.content = '额度申请 → ' + statuName
            } else if ('start_use_amount' in item) {
              item.content = '额度启用 → ' + statuName
            } else if ('sharing_protocol' in item) {
              item.content = '额度分配 → ' + statuName
            } else if ('subsidiary_amount' in item) {
              item.content = '额度回收 → ' + statuName
            } else if ('subsidiaries' in item) {
              item.content = '企业管理 → ' + statuName
            } else if ('rates' in item) {
              item.content = '利率维护 → ' + statuName
            } 
          } else if (item.status === '待复核') {
            statuName = '待复核';
            if ('open_day' in item && item.discount_rate === undefined) {
              item.content = '融单开立 → ' + statuName
            } else if ('flow_date' in item) {
              item.content = '融单流转 → ' + statuName
            } else if ('mxf_number' in item) {
              item.content = '融单融资 → ' + statuName
            } 
          } else if (item.status === '待签收') {
            statuName = '待签收';
            if ('open_day' in item) {
              item.content = '融单开立 → ' + statuName
            } else if ('flow_date' in item) {
              item.content = '融单流转 → ' + statuName
            } else if ('financing_time' in item) {
              item.content = '融单融资 → ' + statuName
            }
          } else if (item.status === '已签收') {
            statuName = '待复核';
            if ('financing_time' in item) {
              item.content = '融单融资 → ' + statuName
            } 
          } else if (item.status === '已退回') {
            statuName = '待处理';
            if ('open_day' in item && item.discount_rate === undefined) {
              item.content = '融单开立 → ' + statuName
            } else if ('flow_date' in item) {
              item.content = '融单流转 → ' + statuName
            } else if ('mxf_number' in item) {
              item.content = '融单融资 → ' + statuName
            } else if ('available_amount' in item) {
              item.content = '额度申请 → ' + statuName
            } else if ('start_use_amount' in item) {
              item.content = '额度启用 → ' + statuName
            } else if ('sharing_protocol' in item) {
              item.content = '额度分配 → ' + statuName
            } else if ('subsidiary_amount' in item) {
              item.content = '额度回收 → ' + statuName
            } else if ('subsidiaries' in item) {
              item.content = '企业管理 → ' + statuName
            } else if ('rates' in item) {
              item.content = '利率维护 → ' + statuName
            } 
          } else if (item.status === '拒签收') {
            statuName = '待处理';
            if ('open_day' in item) {
              item.content = '融单开立 → ' + statuName
            } else if ('flow_date' in item) {
              item.content = '融单流转 → ' + statuName
            }
          } else if (item.status === '待放款') {
            statuName = rolesId === 9 ? '待签章' : '待放款';
            if ('mxf_number' in item) {
              item.content = '融单融资 → ' + statuName
            } 
          } else if (item.status === '待分配') {
            statuName = '待分配';
              item.content = '融单融资 → ' + statuName 
          } else if (item.status === '已分配') {
            statuName = '待复核';
              item.content = '融单融资 → ' + statuName 
          }
        });
        this.setState({ data });
      });
    } catch (e) {}
  };

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue
    })
  };

  render() {
    const { value, data } = this.state;
    return (
      <div style={{ marginBottom: '40px' }}>
        <Paper square style={{ margin: '20px 0' }}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="待办任务" />
            {/*<Tab label="数链待办任务" />*/}
          </Tabs>
        </Paper>
        <View value={value} data={data}></View>
      </div>
    );
  }
}

export default DisabledTabs;
