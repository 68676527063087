const { string, } = require('yup');

//融单开具
export const issueFormik = (form2, form, rolesId) => {
  let check = '请输入';
  if (form.checkStatus === '3' && rolesId !== 5 && rolesId !== 9 && rolesId !== 16) {
    check = form2.check_opinion
    return {
      check_opinion:
        string()
          .trim().required(check),//审核意见
      // sign_agreement:
      //   string()
      //     .trim().required(form2.sign_agreement),//签订协议
      // verification_code:
      //   string()
      //     .trim().required(form2.verification_code),//验证码

    }
  }
  else if (form.checkStatus === '5') {
    check = form2.check_opinion
    return {
      // sign_agreement:
      //   string()
      //     .trim().required(form2.sign_agreement)//签订协议
    }
  }
  else if (form.checkStatus === '2' || form.checkStatus === '6') {
    check = form.checkStatus === '2' ? form2.return_reason : form2.remarks;
    return {
      check_opinion:
        string()
          .trim().required(check),//审核意见
    }

  }


}

//审核意见
export const checkFormikDelay = (values, form2) => {
  let errors = {};
  let check  = values.form_no === '2' ? form2.return_reason : form2.check_opinion;
  if(!values.check_opinion){
    errors.check_opinion = check;
  }
  return errors;
}

//文件上传
export const checkFormikDelayFile =(form2) => {
  let errors = {};
  if(form2.delayFile === null || !form2.delayFile){
      errors.delayFile = "文件上传*"
    }
    return errors;
}

//审核意见
export const checkFormik = (values, form2) => {
  let errors = {};
  let check  = values.form_no === '2' ? form2.return_reason : form2.check_opinion;
  if(!values.check_opinion){
    errors.check_opinion = check;
  }
  return errors;
}

//审核意见
export const checkFormikbefa = (values, form2) => {
  let errors = {};
  let check  = form2.beFrozenAmount;
  let check1 = form2.thawAmount; 
  if((!values.beFrozenAmount || values.beFrozenAmount ==="0") && (!values.thawAmount || values.thawAmount === "0")){
    errors.beFrozenAmount = check;
    errors.thawAmount = check1;
  }
  return errors;
}
