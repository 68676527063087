import React from 'react';
import { makeStyles,  } from '@material-ui/core/styles';
import {Grid, Paper, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // padding: theme.spacing(1, 1),
    height: '70vh',
    width: '100vh',
  },
  paper: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    }
  }
}));

export  default function() {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant={"h1"} component={"h1"}>
          市场
        </Typography>
      </Paper>
    </Grid>
  )
};
