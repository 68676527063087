import React , { Component } from "react"
import {

    Grid,
  } from '@material-ui/core';//

class C42View extends Component {
    state = {
        sum: {
            sumData:[],
            page:1,
            rowsPerPage:5,
          },
        sumData:[],
        page:0,
        rowsPerPage:5
    }
    componentDidMount() {
        this.props.onRef(this);    // 调用父组件传入的函数，把自身赋给父组件
    }
    childConsole = (sum)=> {
        //debugger
        this.state.page = sum.page
        this.state.rowsPerPage=sum.rowsPerPage  
        this.state.sumData=sum.sumData  
        this.IssueSummations();
        this.setState(this.state)
    }
    IssueSummations=()=>{
        let sumData=this.state.sumData;
        let start=this.state.rowsPerPage*(this.state.page-1);
        let end=start+this.state.rowsPerPage;
        sumData=sumData.slice(start,end)
        this.state.sumAmount=0;//初始化金额合计
        sumData.filter(item => {
          this.state.sumAmount +=Number(item.amount)
        })
        this.state.sumCount=sumData.length;
        this.state.page=1;//初始化页面页数
        this.state.sumAmount=this.state.sumAmount.toFixed(2);//通过toFixed解决计算后小数点多位的bug
        return this.state.sumAmount;
      }
    render() {
        return (                  
            <div style={{ textAlign: 'start', marginBottom: '100px' , background :'rgb(29, 69, 131)' , color : '#fff' ,height:'44px' ,weight:'',fontsize : '0.875rem' }}>
            <Grid container spacing={2}>
            <Grid item xs={6} md={5}>融单金额合计:{this.state.sumAmount}（元）</Grid>
            <Grid item xs={6} md={5}>当前页条数:{this.state.sumCount}</Grid>
            </Grid>
            </div>     
        )
    }
}

export default C42View;
