import React, { Component } from 'react'
import { Formik } from 'formik';
import moment from 'moment';
import { fetching, sleep } from '../../config/utils';
import {
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Fade,
  Paper,
  Popper,
  IconButton
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import FormikError from '../../components/commonForm/formikError';
import { C41Formik } from './formikValidate'
import { commafy, delcommafy } from '../../components/numToText';
import setSignKey from '../../components/payment/config';
import SimpleExpansionPanel from '../../components/commonForm/Panels'
import { fromPairs } from 'lodash';
import { DEFAULT_PAYMENT_URL } from '../../config/constants' //支付接口路径
// import CheckForm from '../../components/commonForm/CheckForm';

//import { Toast } from '../../components';

const List = [{ id: '3', text: '同意' }, { id: '2', text: '退回' },]
const useStyles = (theme => ({
  margin: {
    marginRight: '0',
    display: 'flex',
    alignItems: 'flex-end',
  },
  typography: {
    lineHeight: '20px',
    fontSize: '12px'
  },
}));
const styles = {
  textAlign: 'left',
  margin: '0 10px',
}
const loadingStyle = {
  position: "absolute",
  color: green[500],
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -17,
}
class Form extends Component {
  state = {
    form: this.getInitState(),
    form2: this.props.form2 || {},//静态资源
    loading: false,
    errorMsg: '',
    paymentInfo: {},
    anchorEl: null,
    open: false,
    form1: {},
    placement: 'right-start',

  }
  //如果有异步设置state值的情况，在组件销毁时清除所有的state状态
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }
 
  // getInitState1() {
  //   const { exercise } = this.props
  //   const form1 = {
  //     type: 'submit'
  //   }
  //   return { ...form1, ...exercise }
  // }

  getInitState() {
    const { exercise } = this.props

    const form = {
      mx_number: '',
      mx_amount: '',
      amount: '',
      receive: '',
      payment_day: '',
      remarks: '',
      repayment_ways: "中金支付",
      check_opinion: '',
      opinion_list: [],
      form_no: '3',
    }

    if(exercise.receivableNo !== undefined){
      exercise.mx_number = exercise.receivableNo;
      exercise.amount = exercise.applyAmount;

    }

    if (exercise.amount) {
      form.repayment_amount = exercise.amount
    }
    exercise.mx_amount = exercise.mx_amount ? commafy(exercise.mx_amount) : 0;
    exercise.amount = exercise.amount ? commafy(exercise.amount) : 0;

    return { ...form, ...exercise }
  }

  componentWillMount() {
    const { url } = this.props.dataUrl
    const { form } = this.state
    const { table } = this.props.exercise
    //if ((table === 'm4-1' || table === 'm4-2' || table === 'm4-3' || table === 'm4-4') && data.code == 200) {
    //m4-1:待还款; m4-2:还款审批; m4-3:已还款; m4-4:未还款; r8-1:待还款; r8-2:已还款
    if (table === 'm4-1' || table === 'm4-2' || table === 'm4-3' || table === 'm4-4' || table === 'r8-1' || table === 'r8-2' || table === 'r8-8') {
      //var opinion_data = [];
      //opinion_data = data.data.length > 0 ? data.data : [];
      this.setState({
        //form: { ...this.state.form, opinion_list: opinion_data }
        form: { ...this.state.form, opinion_list: form.paymentFlows }
      })
    }
    /*fetching(url.get3 + `${form.id}`).then(data => {
      this.setState({
        form: { ...this.state.form, opinion_list: data }
      })
    })*/
    if (table === 'm4-3') {
      fetching(`/api/payment_notify?amount=${delcommafy(form.amount) * 100}`).then(data => {
        if (data.length > 0) {
          this.setState({
            paymentInfo: data[data.length - 1]
          })
        }

      })

    }
  }
  getStatus = (val) => {
    let msg1, msg2;
    switch (Number(val)) {
      case 0:
        msg1 = '未付款';
        msg2 = '付款订单'
        break;
      case 1:
        msg1 = '系统处理中'
        msg2 = '退款订单'
        break;
      case 2:
        msg1 = '交易成功'
        msg2 = '手续费订单'
        break;
      case 3:
        msg1 = '交易失败'
        msg2 = ''
        break;
      case 7:
        msg1 = '系统处理中,请隔日查询'
        msg2 = ''
        break;
      default:
        msg1 = ''
        msg2 = ''
    }
    return { msg1, msg2 }
  };
  handleClick = (newPlacement, event) => {
    const { paymentInfo } = this.state;
    const { table } = this.props.exercise
    if (table !== 'm4-3') {
      return;
    }
    if (!paymentInfo.amount) {
      //Toast.warning('未查询到相关西煤支付交易记录')
      alert(`未查询到相关西煤支付交易记录`)
      return;
    }
    this.setState({ anchorEl: event.currentTarget, placement: newPlacement, open: !this.state.open })

  };

  //保存 提交
  handleSubmit = (form2, name) => {
    const { table } = this.props.exercise
    if((form2.payment_type !== "还款日不固定" && form2.mx_number.indexOf("YS") === 0) || (form2.mx_number.indexOf("RD") === 0 && form2.repaymentType === "0")){
      this.setState({ loading: true, errorMsg: table === 'm4-2' ? '正在发起还款，请稍后...' : '' })
    }
    const text = name || '撤销';
    //提交表单s
    if (text === '提交') {

      //增加额度是否是[可循环额度]校验--20210120
      const { exercise, auth } = this.props;
      let roleId = auth.roles[0].id;
      /*if (table === 'r8-2' && (roleId === 5 || roleId === 6)) {//r8-2:已还款(业务端)
        const enterpriseName = exercise.extraction;//20210111
        //增加额度是否是[可循环额度]校验
        //如果是[不可循环额度]，则is_westcol_done = 7
        //额度来源方式：1.额度来自单独授信；2.额度来自集团分配
        var limitMark = "可循环额度";//是[可循环额度]还是[不可循环额度]
        fetching('/api/Credit1s?status_id=9&enterprise_name=' + enterpriseName).then(dataList => {
          //1.额度来自单独授信
          if (Array.isArray(dataList) && dataList.length > 0) {//1.单独授信
            //1.1 处理所有授信记录
            for (let i = 0; i < dataList.length; i++) {
              var credit1s = dataList[i];
              if (credit1s.limit_mark == "不可循环额度") {
                limitMark = "不可循环额度";//是[可循环额度]还是[不可循环额度]
                break;
              }
            }

            this.setState({ limitMark: limitMark });
            this.onFormSubmit(form2, name);
          } else {//2.额度来自集团分配
          //2.额度来自集团分配
            //2.1查询所有总公司分配额度记录(已完成状态)
            fetching('/smrd/quota_allocation?status_id=9&sub_company_name=' + enterpriseName).then(dataqa => {
              if (Array.isArray(dataqa) && dataqa.length > 0) {
                var enterpriseNameJT = dataqa[0].enterprise_name;//集团总公司名称
                //2.2查询总公司授信记录
                fetching('/api/Credit1s?status_id=9&enterprise_name=' + enterpriseNameJT).then(dataJTList => {
                  if (Array.isArray(dataJTList) && dataJTList.length > 0) {//总公司有授信记录
                    limitMark = "可循环额度";//是[可循环额度]还是[不可循环额度]
                    //2.3 处理所有授信记录
                    for (let i = 0; i < dataJTList.length; i++) {
                      var credit1s2 = dataJTList[i];
                      if (credit1s2.limit_mark == "不可循环额度") {
                        limitMark = "不可循环额度";//是[可循环额度]还是[不可循环额度]
                        break;
                      }
                    }
        
                    this.setState({ limitMark: limitMark });
                    this.onFormSubmit(form2, name);
                  } else {//总公司无授信记录
                    alert(`总公司无授信记录,请找后台工作人员确认！`);
                  }
                })
              } else {//无额度分配记录
                alert(`无额度分配记录,请找后台工作人员确认！`);
              }
            })
            
          }
        })
        
      } else {
        this.onFormSubmit(form2, name);
      }*/

      //简易版还款流程--20201221
      this.onFormSubmit(form2, name);


      /*if (table === 'm4-1') {
        this.onFormSubmit(form2, name);
      } else if (table === 'm4-2') {

        if (form2.form_no === '2') {
          this.onFormSubmit(form2, name)
        } else {
          this.onRepay(form2, name)
        }
      }*/
    } else {
      // return this.onRepay(form2, name);
      this.props.onSubmit({
        ...form2,
        submit_name: text
      })
    }
  }

  onRepay = async (form, text) => {
    const issuer = await fetching(`/api/westcol_account/${form.open_credit_code}/issuer`);
    if (!issuer) {
      //Toast.warning('还没有西煤支付账户，请注册。');
      alert(`还没有西煤支付账户，请注册。`);
      this.setState({ loading: false, errorMsg: '' });
      return;
    }

    //从java调取需要还款的账户信息列表
    const receiver = await fetching(`/dzqz/getRepaymentList?mx_number=${form.mx_number}`).then(data => {
      if (data.code == 200) {
        if (data.data.length == 0) {
          //Toast.warning("获取数据为空");
          alert(`获取数据为空`);
          return;
        }
        this.onRepayList(form, text, issuer, data.data);
      } else {
        //Toast.warning(data.msg);
        alert(data.msg);
        return;
      }
      
    })

  };


  
  //批量记录已经转款的记录，一次传入后端处理，而非多次调用后端处理
  onRepayList = async (form, text, issuer, list) => {

    //西煤支付转账操作
    const paidList = await this.onRepayAll(issuer, list);

    const resData = {
      paidList,
      issue_id: form.id,
      open_credit_code: form.open_credit_code,
      extraction: form.extraction,
      amount: form.amount,
    };

    //method默认的是post方法
    const { method } = issuer;

    //批量记录已经转款的记录，一次传入后端处理，而非多次调用后端处理
    fetching('/api/payment_notify/paidListPost', { method, body: JSON.stringify(resData) }, 2).then(res => {
      if (res) {
        if (res.msg) {
          this.setState({ loading: false, errorMsg: res.errorMessage });
          //Toast.error(res.msg || '还款失败');
          alert(res.msg || '还款失败');
          return;
        }
        form.Payments = res.data;
        this.onFormSubmit(form, text)
      } else {
        this.setState({ loading: false, errorMsg: res.errorMessage })
        //Toast.error(res.errorMessage || '还款失败')
        alert(res.errorMessage || '还款失败');
      }
    })
  };

  //西煤支付转账操作
  onRepayAll = async (issuer, list) => {

    //批量记录已经转款的记录，一次传入后端处理，而非多次调用后端处理
    var paidList = [];

    //method默认的是post方法
    const { sign_key, url, method, merchantCode, notifyUrl, version, paymentType, businessType, payeeBankAccountType } = issuer;
    const date_time = new Date();

    for (let i = 0; i < list.length; i++) {
      const receiver = list[i].fddCompanyInfoVo;//正式环境
      /*const receiver = {
        "payeeBankAccountNo": "6216261000000000019",
        "payeeName": "全渠道",
        "payeeBankName": "模拟银行",
      };//模拟环境
      */

      // 如下代码work，可以参照
      const body = {
        ...receiver,
        "amount": list[i].amount,
        "merchantCode": merchantCode,
        "notifyUrl": notifyUrl,
        "version": version,
        "paymentType": paymentType,
        "businessType": businessType,
        "payeeBankAccountType": payeeBankAccountType,
        "orderNo": 'Payment' + date_time.getTime(),
        // remark 最多25个字符，不然失败。
        // "remark": `西煤用户：${name}, ${loginName}, 商业信用码：${receiver.credit_no} - 联系号码：${receiver.mobile}`,
        "remark": "盟信" + moment(date_time).format('YYYY-MM-DD HH:mm'),
        "timestamp": date_time.getTime()
      };
      body.sign = setSignKey(body, sign_key);

      await fetching(url, { method, body: JSON.stringify(body) }, 2).then(res => {
        if (res) {
          try {
            if (res.status === 0) {
              paidList.push(res.data);//将已经转款成功的信息放入即将容易处理的list中
              
            } else {
              this.setState({ loading: false, errorMsg: res.errorMessage })
              //Toast.error(res.errorMessage || '还款失败')
              alert(res.errorMessage || '还款失败');
            }
    
          } catch (error) {
            this.setState({ loading: false, errorMsg: res.errorMessage })
            //Toast.error('还款失败')
            alert('还款失败');
          }
        }
      })
    }

    return paidList;

  };

  postExcelFile(params, url) { // params是post请求需要的参数，url是请求url地址
    var form = document.createElement('form')
    form.style.display = 'none'
    form.action = url
    form.method = 'post'
    //重新打开新的窗口设置form target属性
    form.target = '_blank'
    document.body.appendChild(form)
    var i =0
    for (var key in params) {
     
      var list1=params[key]
      var j =params["splitItemPos"].length
      // if(j === i){
      //   break;
      // }
     
      if(key !=='splitItemPos'){
       var input = document.createElement('input')
       input.type = 'hidden'
       input.name = key
       input.value = params[key]
       form.appendChild(input)
      }
      
      if(key ==='splitItemPos'){
       
   
       for(var key in list1 ){

        
        var option1 = document.createElement('input')
        option1.name= "splitTxsn["+i+"]"
        option1.type = 'hidden'  
        option1.value= list1[key].splitTxsn  //分账流水号
     
        var option2 = document.createElement('input')
        option2.name= "splitUserId["+i+"]"
        option2.type = 'hidden'
        option2.value=list1[key].splitUserId  //分账用户id

        var option3 = document.createElement('input')
        option3.name= "splitAmount["+i+"]"
        option3.type = 'hidden'
        option3.value=list1[key].splitAmount  //分账结算金额

        var option4 = document.createElement('input')
        option4.name= "splitFrozenAmount["+i+"]"
        option4.type = 'hidden'
        option4.value=list1[key].splitFrozenAmount  //分账冻结金额
       
        var option5 = document.createElement('input')
        option5.name= "note["+i+"]"
        option5.type = 'hidden'
        option5.value=list1[key].note //分账备注

        var option6 = document.createElement('input')
        option1.name= "cid["+i+"]"
        option1.type = 'hidden'  
        option1.value= list1[key].cid  //流转id

        var option7 = document.createElement('input')
        option1.name= "payIds["+i+"]"
        option1.type = 'hidden'  
        option1.value= list1[key].payIds  //支付id

        form.appendChild(option1)
        form.appendChild(option2)
        form.appendChild(option3)
        form.appendChild(option4)
        form.appendChild(option5)
        form.appendChild(option6)
        form.appendChild(option7)

        i++
     
       }
      }  
    }
    
   
    form.submit()
    form.remove()
  }

//还款支付
releasePaymentModel = () => {
  let mx_number = this.state.form.mx_number;
  //线上还款功能----20210511
  //const formFB = this.state.form;
     //let body2 = {
     //mx_number:formFB.mxf_number,
     //open_credit_code:formFB.open_credit_code,
    //url: window.location.href,
    //}
    let url2 = "";
    if(this.props.exercise.mx_number.indexOf("YS") === -1){
       url2 = `/repayment/payment?mx_number=${mx_number}`;
    }else{
       url2 = `/repayment/paymentys?mx_number=${mx_number}`; 
    }
     fetching(url2, { //第一次调用 还款查询操作
        method: 'GET',
        //body: JSON.stringify(body2)
       }).then(res2 => {
          try {
           if (res2.code === 200) { //放款失败调用放款接口
            let url = DEFAULT_PAYMENT_URL + `gateway/shanmei/payment_more`;
            //if(window.confirm("进入还款页面,是否要支付这笔给"+formFB.applicant+",融资金额为"+formFB.amount+"元,融资净额为"+formFB.net_financing_amount+"元的这笔款项?")){
            if(window.confirm("进入还款页面,是否要支付这笔款项?")){
           //this. postExcelFile(res2.data,'http://192.168.1.11:8088/gateway/new/transaction_transfer')
             //this. postExcelFile(res2.data,'http://192.168.1.5:8089/gateway/shanmei/payment_more')
             this. postExcelFile(res2.data,url)
             return true;  //打开还款页面                
              }else{
             return false; //不打开还款页面                  
            }
              
           } else if (res2.code === 300) { //放款成功
                  alert("这笔融资资金已经还款成功,请进行审核操作")
           } else {
            alert(res2.msg)
            return;
           }
          } catch (error) {
            alert('支付发起失败')
          }
   })
      }

  onFormSubmit = (form2, name) => {
    const text = name || '撤销';
    const { table } = this.props.exercise
    let form = form2 || this.state.form;
    const body = {
      auth: this.props.auth,//用户信息
    }
    let mx_number = this.state.form.mx_number;
    let url1 = "";
    if(this.props.exercise.mx_number.indexOf("YS") === -1){
       url1 = `/repayment/payment?mx_number=${mx_number}`;
    }else{
       url1 = `/repayment/paymentys?mx_number=${mx_number}`; 
    }
    //还款审批提交拦截检测是否还款
    if(table === 'm4-2' && this.props.auth.roles[0].id === 12 && form.form_no !== "2" && ((form.payment_type !== "还款日不固定" && form.mx_number.indexOf("YS") === 0) || (form.mx_number.indexOf("RD") === 0 && form.repaymentType === "0"))){
      fetching(url1, { 
        method: 'GET',
        //body: JSON.stringify(body2)
       }).then(res2 => {
          try {
           if (res2.code === 200) { //放款失败调用放款接口
            //if(window.confirm("进入还款页面,是否要支付这笔给"+formFB.applicant+",融资金额为"+formFB.amount+"元,融资净额为"+formFB.net_financing_amount+"元的这笔款项?")){
            //if(window.confirm("进入还款页面,是否要支付这笔款项?")){
           //this. postExcelFile(res2.data,'http://192.168.1.11:8088/gateway/new/transaction_transfer')
             //this. postExcelFile(res2.data,'http://192.168.1.5:8089/gateway/shanmei/payment_more')
             //return true;  //打开还款页面                
              //}else{
             //return false; //不打开还款页面                  
            //}
           alert("这笔融单未进行还款,请进行还款操作")
           window.location.reload();
           } else if (res2.code === 300) { //放款成功
        //校准额度恢复中是否是【可循环额度】--20210120
    const { exercise } = this.props;
    body.open_credit_code = exercise.open_credit_code;//开立发起的信用代码 == organizations.credit_no
    body.amount = delcommafy(exercise.amount);//开具金额

    if (table === 'm4-2') {
      body.check_opinion = form.check_opinion;//复核员审核意见
      body.is_westcol_done = form.is_westcol_done || form.isWestcolDone;  //传Status对象
      body.form_no = form.form_no;
      body.issue_id = form.id;
      if (this.props.exercise.mx_number.indexOf("YS") === 0){
        body.name = "应收";
      }
      if (form.form_no === '3') {
        body.Payments = form.Payments
      }
    } else if (table === 'm4-1') {
      body.check_opinion = '提交还款申请';//审核意见
      body.is_westcol_done = form.is_westcol_done || form.isWestcolDone;
      body.form_no = '3';
      body.issue_id = form.id;
      if (this.props.exercise.mx_number.indexOf("YS") === 0){
        body.name = "应收";
      }
    } else if (table === 'r8-2') {
      body.check_opinion = form.check_opinion || '已还款额度恢复';//审核意见
      body.is_westcol_done = form.is_westcol_done || form.isWestcolDone;

      //校准额度恢复中是否是【可循环额度】--20210120
      //const { auth } = this.props;
      //let roleId = auth.roles[0].id;
      //if ( (roleId === 5 || roleId === 6) && this.state.limitMark == "不可循环额度" && (form.is_westcol_done || form.isWestcolDone) == 4) {//r8-2:已还款(业务端)
        //body.is_westcol_done = 7;//7:额度不可恢复
     // }
      body.form_no = form.form_no;
      //body.form_no = '3';
      body.issue_id = form.id;
      body.open_credit_code = form.open_credit_code;//开立发起的信用代码 == organizations.credit_no
      body.amount = form.repayment_amount;//开具金额
    }
    // return;
    //let url = table === 'm4-2' ? '/api/payment_flow' : '/api/payment_flow'
    let url = table === 'm4-2' ? '/repayment/savePaymentFlow' : '/repayment/savePaymentFlow'
    fetching(url, {
      method: 'POST',
      body: JSON.stringify(body)
    }).then(res => {
	if (res) {
      this.setState({ loading: false, errorMsg: '' })
      this.props.onSubmit({
        ...res,
        submit_name: text
      })

        if (res.code !== 200 && res.msg) {
          //Toast.error(`提交失败,${res.msg}`)
          alert(res.msg);
          return;
        }
        //if(table === 'm4-2' && this.props.auth.roles[0].id === 12){
        //模仿form 表单提交
        //this.postExcelFile(res.data,'http://192.168.1.5:8089/gateway/shanmei/payment_more')
      //}
        alert('提交成功!');
      } else {
        //Toast.error(`提交失败`)
        alert('提交失败');
      }
    })
                  //alert("这笔融资资金已经还款款成功,请进行审核操作")
           } else {
            alert(res2.msg)
            return;
           }
          } catch (error) {
            alert('支付发起失败')
          }
   })
      
    
    
  } else {
        //校准额度恢复中是否是【可循环额度】--20210120
        const { exercise } = this.props;
        body.open_credit_code = exercise.open_credit_code;//开立发起的信用代码 == organizations.credit_no
        body.amount = delcommafy(exercise.amount);//开具金额
    
        if (table === 'm4-2') {
          body.check_opinion = form.check_opinion;//复核员审核意见
          body.is_westcol_done = form.is_westcol_done || form.isWestcolDone;  //传Status对象
          body.form_no = form.form_no;
          body.issue_id = form.id;
          if (this.props.exercise.mx_number.indexOf("YS") === 0){
            body.name = "应收";
          }
          if (form.form_no === '3') {
            body.Payments = form.Payments
          }
        } else if (table === 'm4-1' || table === 'm7-4-4') {
          body.check_opinion = '提交还款申请';//审核意见
          body.is_westcol_done = form.is_westcol_done || form.isWestcolDone;
          body.form_no = '3';
          body.issue_id = form.id;
          if (this.props.exercise.mx_number.indexOf("YS") === 0){
            body.name = "应收";
          }
        } else if (table === 'r8-2') {
          body.check_opinion = form.check_opinion || '已还款额度恢复';//审核意见
          body.is_westcol_done = form.is_westcol_done || form.isWestcolDone;
          if (this.props.exercise.mx_number.indexOf("YS") === 0){
            body.name = "应收";
          }
    
          //校准额度恢复中是否是【可循环额度】--20210120
          /*const { auth } = this.props;
          let roleId = auth.roles[0].id;
          if ( (roleId === 5 || roleId === 6) && this.state.limitMark == "不可循环额度" && (form.is_westcol_done || form.isWestcolDone) == 4) {//r8-2:已还款(业务端)
            body.is_westcol_done = 7;//7:额度不可恢复
          }*/
          body.form_no = form.form_no;
          //body.form_no = '3';
          body.issue_id = form.id;
          body.open_credit_code = form.open_credit_code;//开立发起的信用代码 == organizations.credit_no
          if(form.payment_type !== "还款日不固定"){
            body.amount = form.repayment_amount;//开具金额
          }else{
            body.amount = delcommafy(form.mx_amount);//开具金额
          }
        } else if (table === 'r8-8') { //还款复核
          body.check_opinion = form.check_opinion || '还款复核';//审核意见
          body.is_westcol_done = form.is_westcol_done || form.isWestcolDone;
          body.form_no = form.form_no;
          body.issue_id = form.id;
          body.open_credit_code = form.open_credit_code;//开立发起的信用代码 == organizations.credit_no
          if(form.payment_type !== "还款日不固定"){
            body.amount = form.repayment_amount;//开具金额
          }else{
            body.amount = delcommafy(form.mx_amount);//开具金额
          }
          if (this.props.exercise.mx_number.indexOf("YS") === 0){
            body.name = "应收";
          }
        }
        // return;
        //let url = table === 'm4-2' ? '/api/payment_flow' : '/api/payment_flow'
        let url = table === 'm4-2' ? '/repayment/savePaymentFlow' : '/repayment/savePaymentFlow'
        fetching(url, {
          method: 'POST',
          body: JSON.stringify(body)
        }).then(res => {
          if (res) {
          this.setState({ loading: false, errorMsg: '' })
          this.props.onSubmit({
            ...res,
            submit_name: text
          })
            if (res.code !== 200 && res.msg) {
              //Toast.error(`提交失败,${res.msg}`)
              alert(res.msg);
              return;
            }
            /*if(table === 'm4-2' && this.props.auth.roles[0].id === 12){
            //模仿form 表单提交
            this.postExcelFile(res.data,'http://192.168.1.5:8089/gateway/shanmei/payment_more')
          }*/
            alert('提交成功!');
          } else {
            //Toast.error(`提交失败`)
            alert('提交失败');
          }
        })

  }
  }

  //线下支付
  OfflineRepayment = () => {
  const formFB = this.state.form;
  if(window.confirm("是否要修改当前融单为线下/线上还款?确认修改请点击'确认',页面会自动刷新重新获取数据")){
    fetching(`/msapi/issue/updateRepayementType?mx_number=${formFB.mx_number}`).then(res => {
      if (res) {
        //this.setState({ opinion_list: res })
        alert(res.msg)
        window.location.reload();//TODO 后续修改
        //this.setState()
      }
    })
    return true; 
  }else{
    return false;        
  }
  }

  render() {
    const { anchorEl, open, placement, form2, form, paymentInfo } = this.state;
    const { exercise, classes, auth } = this.props;
    let repaymentType = exercise.repaymentType ? exercise.repaymentType : "0";
    let rolesId = auth.roles ? auth.roles[0].id : 0;
    const status = (handleSubmit, isSubmitting, values) => {  
      return <div style={{ margin: '20px 0 10px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ position: 'relative' }}>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSubmit}
              disabled={this.state.loading}
              name={form2.submit}
              style={{ marginRight: '10px' }}
            >
              {form2.initiate}
              {this.state.loading && <CircularProgress style={loadingStyle} size={20} />}
            </Button>
          </div>
          { (rolesId !== 5 && rolesId !== 6) && <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, '撤销')}
            name={form2.revocation}
            // disabled={isSubmitting}
            style={{ marginRight: '10px' }}
          >
            {form2.revocation}
          </Button>}
        </div>

      </div>
    }
    return (

      <Formik
        initialValues={{
          ...form
        }}
        validate={(values) => {
          return C41Formik(values, form2, rolesId);
        }}
        onSubmit={(
          values,
        ) => {
          this.handleSubmit(values, '提交');
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting
        }) => (
            <form style={{ padding: '0 60px' }}>
              <Popper style={{ zIndex: '10000' }} open={open} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper style={{ padding: '16px' }}>
                      <IconButton color='secondary' style={{ padding: 0 }}>
                        <InfoIcon />
                      </IconButton>
                      <Typography variant="subtitle2" className={classes.typography}>商户订单号：{paymentInfo.orderNo}</Typography>
                      <Typography variant="subtitle2" className={classes.typography}>交易流水号：{paymentInfo.transactionNo}</Typography>
                      <Typography variant="subtitle2" className={classes.typography}>交易状态：{this.getStatus(paymentInfo.transactionStatus).msg1}</Typography>
                      <Typography variant="subtitle2" className={classes.typography}>交易类型：{this.getStatus(paymentInfo.transactionType).msg2}</Typography>
                      <Typography variant="subtitle2" className={classes.typography}>交易金额：{paymentInfo.amount}</Typography>
                      <Typography variant="subtitle2" className={classes.typography}>交易手续费：{paymentInfo.feeAmount}</Typography>
                      <Typography variant="subtitle2" className={classes.typography}>通用余额：{paymentInfo.balance}</Typography>

                    </Paper>
                  </Fade>
                )}
              </Popper>
              <Grid container justify='space-around' alignItems='center'>
                {(exercise.table === 'm4-1' || exercise.table === 'm4-4') && <h3>还款申请</h3>}
                {exercise.table === 'm4-2' && <h3>还款审批</h3>}
                {exercise.table === 'm4-3' && <h3>还款信息</h3>}
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.mx_number}:</Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.mx_number}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.receive}:</Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.extraction}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.payment_day}:</Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.payment_day}
                  </Typography>
                </Grid>
                {((form.payment_type !== "还款日不固定" && form.mx_number.indexOf("YS") === 0) || (form.mx_number.indexOf("RD") === 0 && (form.mx_number.indexOf("RD") === 0 && repaymentType === "0")))  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.repayment_ways}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.repayment_ways}
                  </Typography>
                </Grid>}
                {((form.mx_number.indexOf("RD") === 0 && repaymentType === "1") || (form.payment_type === "还款日不固定" && form.mx_number.indexOf("YS") === 0))  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.repayment_ways}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {"线下支付"}
                  </Typography>
                </Grid>}
                {(form.payment_type !== "还款日不固定")  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.amount}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.amount}元</span>
                  </Typography>
                </Grid>}
                {(form.payment_type === "还款日不固定")  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {"还款本金"}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.mx_amount}元</span>
                  </Typography>
                </Grid>}
                {(form.payment_type === "还款日不固定" && form.mx_number.indexOf("YS") === 0)  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {"结算利息"}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {commafy(values.interest)}元</span>
                  </Typography>
                </Grid>}
                {(form.payment_type === "还款日不固定" && form.mx_number.indexOf("RD") === 0)  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {"结算利息"}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {commafy(values.interest)}元</span>
                  </Typography>
                </Grid>}
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.remarks}:</Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {form.remarks}
                  </Typography>
                </Grid>
                {
                (form.form_no === '3' &&  rolesId === 11 && form.mx_number.indexOf("RD") === 0) &&
                <Button onClick={this.OfflineRepayment} style={{ margin: '10px 0' }}
                  variant='contained' >{'线下/线上切换还款流程'}</Button>
                }
                {/* {<Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom style={styles}>注:发起还款申请前请确保还款金额已存入西煤支付账户上。</Typography>
                </Grid>
                } */}
              </Grid>

              {(exercise.table !== 'm4-1' && exercise.table !== 'm4-4') &&
                <div>

                  <SimpleExpansionPanel title="历史审核意见">
                    {/* <Typography style={{ textAlign: 'left' }} variant="inherit" gutterBottom>经办人：{form.Organization.name}-{form.Role.name || ''}-{form.User.name}</Typography> */}
                    <Typography style={{ textAlign: 'left' }} variant="inherit" gutterBottom>经办人：{form.organization.name}-{form.role.name || ''}-{form.user.name}</Typography>

                    {
                      form.opinion_list.map((item, index) => {
                        return (
                          <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 0', textAlign: 'left' }} key={index}>
                            <Typography variant="inherit" gutterBottom>
                              {/* item.Organization.name}-{item.Role.name}-{item.User.name */}
                              {/* item.organization.name}-{item.role.name}-{item.user.name */}
                              {item.name}
                            </Typography>
                            <Typography variant="inherit" gutterBottom >
                              {item.check_opinion || item.checkOpinion}
                            </Typography>
                          </div>
                        )
                      })

                    }
                  </SimpleExpansionPanel>
                  {
                (form.form_no === '3' &&  rolesId === 12 && 
                ((form.payment_type !== "还款日不固定" && form.mx_number.indexOf("YS") === 0) || (form.mx_number.indexOf("RD") === 0 && repaymentType === "0"))) &&
                <Button onClick={this.releasePaymentModel} style={{ margin: '10px 0' }}
                  variant='contained' >{'还款支付'}</Button>   
                  }

                  {exercise.save !== '3' && <div>
                    <Grid item xs={12} >
                      <FormControl component="fieldset" style={styles} className={classes.radio}>
                        {/* <FormLabel component="legend" style={{ fontSize: '12px' }}></FormLabel> */}
                        <RadioGroup className={classes.radio} style={{ flexDirection: 'row' }} aria-label="gender" name='entrusted_payment' value={values.form_no}
                          onChange={e => setFieldValue('form_no', e.target.value)}>
                          {List.map(item => {
                            return (
                              <FormControlLabel
                                key={item.id}
                                name={item.id}
                                value={item.id}
                                control={<Radio />}
                                label={item.text} />
                            )
                          })}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        rows='4'
                        label={form2.check_opinion}
                        value={values.check_opinion}
                        name='check_opinion'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin='normal'
                        fullWidth
                        disabled={exercise.save === '3'}
                      />
                      <FormikError touched={touched} errors={errors} name={'check_opinion'} />
                    </Grid>
                  </div>
                  }

                </div>
              }
              {(exercise.table !== 'm4-3') &&
                status(handleSubmit, isSubmitting, values)
              }
              {this.state.errorMsg &&
                <Grid item xs={12} className="errorMsg">
                  <Typography variant="body2" gutterBottom style={{ textAlign: 'center' }}>{this.state.errorMsg}</Typography>
                </Grid>}

            </form>
          )}
      />

    )


  }

}

export default withStyles(useStyles)(Form);

