import getSubRouters from './helper';

 export const subRootAry6 = [
    '北京银行直融融资查看',
    '北京银行直融已完成融资',
    

  ];

const PARENT_PATH = '/risk-management/外部融资';
const PATH = '北京银行融资';
export default getSubRouters(PARENT_PATH, PATH, subRootAry6);