export default {
    enterprise_name:"客户名称",
    enterprise_no:"客户编号",
    report_year:"报表年份",
    report_month:"报表月份",
    report_properties:"报表性质",
    is_audit:"是否审计",
    currency: "币种",
    balance_sheet:'资产负债表',
    cash_flow_statement:'现金流量表',
    profit_statement:'利润表',
}