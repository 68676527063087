import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import {
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
  InputLabel
  // MenuItem,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import FormikError from '../formik/formikError';
import { moreInfoFormik } from '../formik/formikValidate';
import { fetching } from '../../config/utils';
import { DEFAULT_CONTRCT_URL } from '../../config/constants'

const { object } = require('yup');


const useStyles = (theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  label: {
    textAlign: 'left',
  },
  margin: {
    marginRight: '0',
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'right',
  },
}));

class MoreInfoForm extends Component {
  
  state = {
    form1: this.getInitForm1(),
    open_id: localStorage.getItem("registerForm1") ? JSON.parse(localStorage.getItem("registerForm1")).cell : '',
    viewpdf_url: '',
    agreement: 0,//签订协议
    contract_id: '',
    form2: this.props.form2 || {},
    // 注册地址
    registerProvinceList: [],//省份
    registerCityList: [],//省份下包含的市
    registerCountyList: [],//市包含的县
    // 通讯地址
    communicateProvinceList: [],//省份
    communicateCityList: [],//省份下包含的市
    communicateCountyList: [],//市包含的县
    // 开票地址
    InvoicingProvinceList: [],//省份
    InvoicingCityList: [],//省份下包含的市
    InvoicingCountyList: [],//市包含的县
    //收信人地址
    RecipientProvinceList: [],//省份
    RecipientCityList: [],//省份下包含的市
    RecipientCountyList: [],//市包含的县


    enterprise_size_list: [],//企业规模
    industry_division_list: [],//行业划分
    enterprise_nature_list: ["国有企业","集体所有制企业","联营企业","三资企业","私营企业"],//企业性质
  }

  getInitForm1() {
    const form1 = {
      operator_email: '',
      registry_address: '',
      contact_address: '',
      issue_invoice_enterprise_full_name: '',//开具发票 企业全称
      tin: '',
      address: '',
      enterprise_cell: '',
      bank: '',
      bank_account: '',
      send_invoice_enterprise_full_name: '',//寄送发票 企业全称
      receiver_name: '',
      receiver_cell: '',
      receiver_address: '',
      agreement: 0,//签订协议
      province: '',
      city: '',
      district: '',
      // 注册地址
      registry_address_range: '',
      registerProvince: '',
      registerCity: '',
      registerCounty: '',
      // 通讯地址
      address_range: '',
      communicateProvince: '',
      communicateCity: '',
      communicateCounty: '',
      // 发票地址
      invoicingProvinceCityCounty: '',
      InvoicingProvince: '',
      InvoicingCity: '',
      InvoicingCounty: '',
      // 收信人地址
      receiver_address_range: '',
      RecipientProvince: '',
      RecipientCity: '',
      RecipientCounty: '',
      enterprise_size: '',// 企业规模
      industry_division: '',// 行业划分
      enterprise_nature: '',// 企业性质

    };
    return localStorage.getItem("registerForm3") ? JSON.parse(localStorage.getItem("registerForm3")) : form1
  };


  componentDidMount(){
    // 获取省份
    fetching('/dzqz/findProvince', { method: 'GET'} ,99).then(res => { 
      if (res) {
        let data = res.data.map((item) => {
          return {spc_id: item.spc_id, value: item.name, label: item.name }
        })
      this.setState({ registerProvinceList: data })
      this.setState({ communicateProvinceList: data})
      this.setState({ InvoicingProvinceList: data})
      this.setState({ RecipientProvinceList: data})
      } else {
      }
    })
    // 获取企业规模
    fetching('/dzqz/findCorporationscale', { method: 'GET'} ,99).then(res => { 
      if (res) {
        let data = res.data.map((item) => {
          return {spc_id: item.spc_id, value: item.name, label: item.name }
        })
      this.setState({ enterprise_size_list: data })
      } else {
      }
    })
    // 获取行业列表
    fetching('/dzqz/findIndustrycode', { method: 'GET'} ,99).then(res => { 
      if (res) {
        let data = res.data.map((item) => {
          return {spc_id: item.spc_id, value: item.name, label: item.name }
        })
      this.setState({ industry_division_list: data })
      } else {
      }
    })
    // 获取企业性质
    let data = this.state.enterprise_nature_list.map((item) => {
      return { value: item, label: item }
    })
    this.setState({ enterprise_nature_list: data })
  }

  handleChange = ({ target: { name, value } }) => {

    return value;
  }

  // 根据省获取市
  handleChange3 = (e,num) => {

    this.setState({ cityList: [] })
    fetching(`/dzqz/findCityByProvinceId?spc_id=${e.spc_id}`,{ method: 'GET'} ,99).then(res => {    
      if (res) {
        let data = res.data.map((item) => {
          return {spc_id: item.spc_id, value: item.name, label: item.name }
        })
        switch(num){
          case 1: 
            this.setState({
              form1: {
                ...this.state.form1,
                registerProvince: e.value,
              }
            })
            this.setState({ registerCityList: data });
            break;
          case 2:
            this.setState({
              form1: {
                ...this.state.form1,
                communicateProvince: e.value,
              }
            })
            this.setState({ communicateCityList: data });
            break;
          case 3:
            this.setState({
              form1: {
                ...this.state.form1,
                InvoicingProvince: e.value,
              }
            })
            this.setState({ InvoicingCityList: data });
            break;
          case 4:
            this.setState({
              form1: {
                ...this.state.form1,
                RecipientProvince: e.value,
              }
            })
            this.setState({ RecipientCityList: data });
            break;
          default:{}
        }
      } else {
      }
    })
    return e.name
  }
  // 根据市获取县
  handleChange4 = (e,num) => {
    this.setState({ countyList: [] })
    fetching(`/dzqz/findCityByProvinceId?spc_id=${e.spc_id}`,{ method: 'GET'} ,99).then(res => {    
      if (res) {
        let data = res.data.map((item) => {
          return {spc_id: item.spc_id, value: item.name, label: item.name }
        })
        switch(num){
          case 1: 
            this.setState({
              form1: {
                ...this.state.form1,
                registerCity: e.value,
              }
            })
            this.setState({ registerCountyList: data });
            break;
          case 2:
            this.setState({
              form1: {
                ...this.state.form1,
                communicateCity: e.value,
              }
            })
            this.setState({ communicateCountyList: data });
            break;
          case 3:
            this.setState({
              form1: {
                ...this.state.form1,
                InvoicingCity: e.value,
              }
            })
            this.setState({ InvoicingCountyList: data });
            break;
          case 4:
            this.setState({
              form1: {
                ...this.state.form1,
                RecipientCity: e.value,
              }
            })
            this.setState({ RecipientCountyList: data });
            break;
          default:{}
        }
      } else {
      }
    })
  }


  handleChange5 = (e,num) => {
        switch(num){
          case 1: 
            this.setState({
              form1: {
                ...this.state.form1,
                registerCounty: e.value,
              }
            })
            break;
          case 2:
            this.setState({
              form1: {
                ...this.state.form1,
                communicateCounty: e.value,
              }
            })
            break;
          case 3:
            this.setState({
              form1: {
                ...this.state.form1,
                InvoicingCounty: e.value,
              }
            })
            break;
          case 4:
            this.setState({
              form1: {
                ...this.state.form1,
                RecipientCounty: e.value,
              }
            })
            break;
          default:{}
        }
    }

  handleChange6 = (e,num) => {
    switch(num){
      case 1: 
        this.setState({
          form1: {
            ...this.state.form1,
            enterprise_size: e.value,
          }
        })
        break;
      case 2:
        this.setState({
          form1: {
            ...this.state.form1,
            industry_division: e.value,
          }
        })
        break;
      case 3:
        this.setState({
          form1: {
            ...this.state.form1,
            enterprise_nature: e.value,
          }
        })
        break;
        default:{}
        }
  }



  //如果有异步设置state值的情况，在组件销毁时清除所有的state状态
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }
  //查看协议
  handleView = () => {
    window.open(DEFAULT_CONTRCT_URL + '/contract/template/zc.pdf')
  }
  //自动签署
  handleChange1 = (e, values) => {
    const { checked } = e.target;
    if (!checked) { return; }
    let paramData = {
      open_id: this.state.open_id,
      mail: values.operator_email,
      phone: values.receiver_cell,
      address: values.contact_address,
      registerAddress:values.registry_address,
      contactPerson: values.receiver_name,
    }
    for (let name in paramData) {
      if (!paramData[name]) {
        alert('请先完善注册信息')
        return;
      }
    }
    this.setState({ value: '正在签署协议，请稍后...' })
    fetching('/dzqz/extsignAuto/ZC', {
      method: 'POST',
      body: JSON.stringify(paramData)
    }, 2).then(res => {
      this.setState({ value: '' })
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data.viewpdf_url)
          this.setState({ agreement: '1', contract_id: res.data.contract_id })
        } else if (parseInt(res.code) === 2002) {//企业未授权
          alert(res.msg)
          this.setState({ agreement: 0 })
          this.handleChange2(2);
        } else if (parseInt(res.code) === 2001) {//法人未授权
          alert(res.msg)
          this.setState({ agreement: 0 })
          this.handleChange2(1);
        } else {
          alert(res.msg)
          this.setState({ agreement: 0 })
        }
      }
      
    })
  }
  //签署授权协议
  handleChange2 = (value) => {
    let url = '';
    if (value === 1) {
      url = `/dzqz/authorization?open_id=${this.state.open_id}&differentiate=personage`
    } else {
      url = `/dzqz/authorization?open_id=${this.state.open_id}`
    }
    fetching(url, {
      method: 'GET',
    }, 2).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data)
        } else {
          alert(res.msg)
        }
      }
      
    })
  }
  //下载注册协议
  handleUpload = () => {
    const { contract_id } = this.state;
    if (!contract_id) {
      return;
    }
    fetching(`/dzqz/downLoadContract?contract_id=${contract_id}`, {
      method: 'GET',
    }, 2).then(res => {
      if (res !== undefined) {
        try {
          if (res.code === 200) {
            window.open(res.data)
          } else {
            alert(res.msg)
          }
        } catch (error) { }
      } 
      
    })
  }

  //上一步
  handleBack = () => {
    this.props.handleBack();
  };

  //提交
  handleSubmit = (values) => {
    let message = this.isEmpty()
    if (message) {
      alert(message);
      return
    }
    const { agreement} = this.state;
    values = this.merge(values)
    localStorage.setItem("registerForm3", JSON.stringify(values));
    if (agreement === '1') {
      this.props.handleSubmit(values);
    } else {
      this.setState({ value: '请签署协议' })
    }
  };
  // 下拉框选择地址进行判空
  isEmpty = () => {
    const { form1} = this.state;
    if (form1.registerCounty === '') {
      return '注册地址未选择'
    }
    // if (form1.communicateCounty === '') {
    //   return '通讯地址未选择'
    // }
    // if (form1.InvoicingCounty === '') {
    //   return '发票地址未选择'
    // }
    // if (form1.RecipientCounty === '') {
    //   return '收信人地址未选择'
    // }
    if (form1.enterprise_size === '' || form1.industry_division === '' || form1.enterprise_nature === '' ) {
      return '企业信息未选择'
    }
    return false
  }

  merge = (values) => {
    const { form1} = this.state;
    values.province = form1.registerProvince
    values.city = form1.registerCity
    values.district = form1.registerCounty
    values.receiver_address_range = form1.registerProvince + form1.registerCity + form1.registerCounty
    values.enterprise_size = form1.enterprise_size
    values.industry_division = form1.industry_division
    values.enterprise_nature = form1.enterprise_nature
    return values;
  }

  getOptionValue = data => {
    return data.id
  }
  getOptionLabel = data => {
    return data.name
  }


  render() {
    const { activeStep, form_signup3 = {} } = this.props;
    const { registerProvinceList, registerCityList,  registerCountyList, communicateProvinceList, communicateCityList, communicateCountyList,
      InvoicingProvinceList, InvoicingCityList, InvoicingCountyList, RecipientProvinceList, RecipientCityList, RecipientCountyList} = this.state;

    const { form1, agreement, value, contract_id ,enterprise_size_list, industry_division_list,enterprise_nature_list ,form2 ,form} = this.state;
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form1
        }}
        //表单验证
        validationSchema={object().shape(moreInfoFormik(form_signup3))}
        //验证提交
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <div >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="standard-helperText"
                    name="operator_email"
                    value={values.operator_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={form_signup3.operator_email}
                    fullWidth
                    autoComplete="on"
                    helperText="该邮箱是用来接收签章的链接并进行签章"
                  />
                  <FormikError touched={touched} errors={errors} name={'operator_email'} />
                </Grid>

                {/* 注册地址 */}
                <Grid container justify="space-between">
                <Grid item xs={3}>
                  <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{"省份"}</span> 
                  <Select
                    onChange={e => this.handleChange3(e, 1)}
                    options={registerProvinceList}
                  />
                  <FormikError touched={touched} errors={errors} name={'registerProvince'} />
                </Grid>
                <Grid item xs={3}>
                 <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form_signup3.market}</span>
                  <Select
                    onChange={e => this.handleChange4(e, 1)}
                    options={registerCityList}              
                  />
                  <FormikError touched={touched} errors={errors} name={'registerCity'} />
                </Grid>
                <Grid item xs={3}>
                 <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form_signup3.county}</span>
                 <Select
                    onChange={e => this.handleChange5( e, 1)}
                    options={registerCountyList}              
                  />
                  <FormikError touched={touched} errors={errors} name={'registerCounty'} />
                </Grid>
              </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    id="registry_address"
                    name="registry_address"
                    value={values.registry_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={form_signup3.registry_address}
                    fullWidth
                    autoComplete="off"
                    helperText="营业执照上住所"
                  />
                  <FormikError touched={touched} errors={errors} name={'registry_address'} />
                </Grid>

                {/* 通讯地址 */}
                {/* <Grid container justify="space-between">
                 <Grid item xs={3}>
                  <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form_signup3.province}</span>
                   <Select
                     onChange={e => this.handleChange3( e, 2)}
                     options={communicateProvinceList}
                  />
                  <FormikError touched={touched} errors={errors} name={'communicateProvince'} />
                </Grid>
                <Grid item xs={3}>
                 <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form_signup3.market}</span>
                  <Select
                    onChange={e => this.handleChange4( e, 2)}
                    options={communicateCityList}              
                  />
                  <FormikError touched={touched} errors={errors} name={'communicateCity'} />
                </Grid>
                <Grid item xs={3}>
                 <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form_signup3.county}</span>
                 <Select
                    onChange={e => this.handleChange5(e, 2)}
                    options={communicateCountyList}              
                  />
                  <FormikError touched={touched} errors={errors} name={'communicateCounty'} />
                </Grid>
              </Grid> */}

                <Grid item xs={12}>
                  <TextField
                    required
                    id="contact_address"
                    name="contact_address"
                    value={values.contact_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={form_signup3.contact_address}
                    fullWidth
                    autoComplete="off"
                  />
                  <FormikError touched={touched} errors={errors} name={'contact_address'} />
                </Grid>
                <Typography variant="h6" gutterBottom>
                  {form_signup3.issue_invoice_desc}
                </Typography>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="issue_invoice_enterprise_full_name"
                    name="issue_invoice_enterprise_full_name"
                    value={values.issue_invoice_enterprise_full_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={form_signup3.issue_invoice_enterprise_full_name}
                    fullWidth
                    autoComplete="off"
                  />
                  <FormikError touched={touched} errors={errors} name={'issue_invoice_enterprise_full_name'} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="tin"
                    name="tin"
                    value={values.tin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={form_signup3.tin}
                    fullWidth
                    autoComplete="off"
                  />
                  <FormikError touched={touched} errors={errors} name={'tin'} />
                </Grid>

                {/* 发票地址 */}
                {/* <Grid container justify="space-between">
                 <Grid item xs={3}>
                  <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form_signup3.province}</span>
                   <Select
                     onChange={e => this.handleChange3(e, 3)}
                     options={InvoicingProvinceList}
                  />
                  <FormikError touched={touched} errors={errors} name={'InvoicingProvince'} />
                </Grid>
                <Grid item xs={3}>
                 <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form_signup3.market}</span>
                  <Select
                    onChange={e => this.handleChange4(e, 3)}
                    options={InvoicingCityList}              
                  />
                  <FormikError touched={touched} errors={errors} name={'InvoicingCity'} />
                </Grid>
                <Grid item xs={3}>
                 <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>{form_signup3.county}</span>
                 <Select
                    onChange={e => this.handleChange5( e, 3)}
                    options={InvoicingCountyList}              
                  />
                  <FormikError touched={touched} errors={errors} name={'InvoicingCounty'} />
                </Grid>
              </Grid> */}

                <Grid item xs={12}>
                  <TextField
                    required
                    id="address"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={form_signup3.address}
                    fullWidth
                    autoComplete="off"
                  />
                  <FormikError touched={touched} errors={errors} name={'address'} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="enterprise_cell"
                    name="enterprise_cell"
                    value={values.enterprise_cell}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={form_signup3.enterprise_cell}
                    fullWidth
                    autoComplete="off"
                  />
                  <FormikError touched={touched} errors={errors} name={'enterprise_cell'} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="bank"
                    name="bank"
                    value={values.bank}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={form_signup3.bank}
                    fullWidth
                    autoComplete="off"
                  />
                  <FormikError touched={touched} errors={errors} name={'bank'} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="bank_account"
                    name="bank_account"
                    value={values.bank_account}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={form_signup3.bank_account}
                    fullWidth
                    autoComplete="off"
                  />
                  <FormikError touched={touched} errors={errors} name={'bank_account'} />
                </Grid>
                <Typography variant="h6" gutterBottom>
                  {form_signup3.send_invoice_desc}
                </Typography>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="send_invoice_enterprise_full_name"
                    name="send_invoice_enterprise_full_name"
                    value={values.send_invoice_enterprise_full_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={form_signup3.send_invoice_enterprise_full_name}
                    fullWidth
                    autoComplete="off"
                  />
                  <FormikError touched={touched} errors={errors} name={'send_invoice_enterprise_full_name'} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="receiver_name"
                    name="receiver_name"
                    value={values.receiver_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={form_signup3.receiver_name}
                    fullWidth
                    autoComplete="off"
                  />
                  <FormikError touched={touched} errors={errors} name={'receiver_name'} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="receiver_cell"
                    name="receiver_cell"
                    value={values.receiver_cell}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={form_signup3.receiver_cell}
                    fullWidth
                    autoComplete="off"
                  />
                  <FormikError touched={touched} errors={errors} name={'receiver_cell'} />
                </Grid>

                {/* 收信人地址 */}
                {/* <Grid container justify="space-between">
                 <Grid item xs={3}>
                 <InputLabel id="demo-simple-select-label">{form_signup3.province}</InputLabel>
                   <Select
                     onChange={e => this.handleChange3( e, 4)}
                     options={RecipientProvinceList}
                  />
                  <FormikError touched={touched} errors={errors} name={'RecipientProvince'} />
                </Grid>
                <Grid item xs={3}>
                <InputLabel id="demo-simple-select-label">{form_signup3.market}</InputLabel>
                  <Select
                    onChange={e => this.handleChange4( e, 4)}
                    options={RecipientCityList}              
                  />
                  <FormikError touched={touched} errors={errors} name={'RecipientCity'} />
                </Grid>
                <Grid item xs={3}>
                <InputLabel id="demo-simple-select-label">{form_signup3.county}</InputLabel>
                 <Select
                    onChange={e => this.handleChange5( e, 4)}
                    options={RecipientCountyList}              
                  />
                  <FormikError touched={touched} errors={errors} name={'RecipientCounty'} />
                </Grid>
              </Grid> */}

                <Grid item xs={12}>
                  <TextField
                    required
                    id="receiver_address"
                    name="receiver_address"
                    value={values.receiver_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={form_signup3.receiver_address}
                    fullWidth
                    autoComplete="off"
                  />
                  <FormikError touched={touched} errors={errors} name={'receiver_address'} />
                </Grid>
                <Typography variant="h6" gutterBottom>
                  {form_signup3.information}
                </Typography>

                {/* 企业信息 */}
                <Grid container justify="space-between">
                  {/* 企业规模 */}
                 <Grid item xs={3}>
                 <InputLabel id="demo-simple-select-label">{form_signup3.enterprise_size}</InputLabel>
                   <Select
                     onChange={e => this.handleChange6( e, 1)}
                     options={enterprise_size_list}
                  />
                  <FormikError touched={touched} errors={errors} name={'enterprise_size'} />
                </Grid>
                {/* 行业划分 */}
                <Grid item xs={3}>
                <InputLabel id="demo-simple-select-label">{form_signup3.industry_division}</InputLabel>
                  <Select
                    onChange={e => this.handleChange6( e, 2)}
                    options={industry_division_list}              
                  />
                  <FormikError touched={touched} errors={errors} name={'industry_division'} />
                </Grid>
                {/* 企业性质 */}
                <Grid item xs={3}>
                <InputLabel id="demo-simple-select-label">{form_signup3.enterprise_nature}</InputLabel>
                 <Select
                    onChange={e => this.handleChange6( e, 3)}
                    options={enterprise_nature_list}              
                  />
                  <FormikError touched={touched} errors={errors} name={'enterprise_nature'} />
                </Grid>
              </Grid>
              
                <div style={{ padding: '10px 0' }}>
                  <FormControlLabel
                    style={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={agreement === '1'}
                        name='agreement'
                        onChange={e => { this.handleChange1(e, values) }} />
                    }
                    label={form_signup3.agreement1}
                  />
                  <span
                    style={{ color: '#f00', cursor: 'pointer' }}
                    onClick={this.handleView}
                  >{form_signup3.agreement2}</span>
                  {contract_id &&
                    <span
                      style={{ color: '#2196f3', cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={this.handleUpload}
                    >下载注册协议</span>
                  }
                  <Typography style={{ textAlign: 'left', paddingLeft: '20px', width: '100%', lineHeight: '30px' }} className="errorMsg" variant="body2" gutterBottom >{agreement !== '1' ? value : ''}</Typography>
                </div>
              </Grid>
              <div style={{ margin: '20px 0' }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                >
                  上一步
                      </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  提交
                      </Button>
              </div>
            </div>
          )}
      />

    );
  }
}

export default withStyles(useStyles)(MoreInfoForm);
