import React, { Component } from 'react'
import { Formik } from 'formik';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { fetching } from '../../config/utils';
import { check } from './check'
import {
  TextField,
  Button,
  Grid,
  Select,
  FormControl,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputLabel
} from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import FormikError from './formikError';
import SimpleExpansionPanel from './Panels'
import { issueFormik } from './formikValidate';
import { DEFAULT_CONTRCT_URL , DEFAULT_PAYMENT_URL} from '../../config/constants';
// import { numberParseChina } from '../numToText';

import TreeView from '@material-ui/lab/TreeView';//202009630
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';//202009630
import ChevronRightIcon from '@material-ui/icons/ChevronRight';//202009630
import TreeItem from '@material-ui/lab/TreeItem';//202009630

import {
  LocationOn,
  Attachment,
  MonetizationOn,
  AccountTree
} from '@material-ui/icons'//20200930

const { object } = require('yup');

const styles = {
  textAlign: 'left',
  margin: '0 10px',
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
class Form1 extends Component {
  state = {
    form: this.getInitState(),
    form2: this.getInitState2(),
    open2: false,
    imgUrl: '',
    times: null,
    signatureMsg: '',
    second: 60,//验证码倒计时
    secondNumber: '',//验证码is
    userList: [
      { id: 1, name: '退回上一级' },
      { id: 2, name: '退回经办人' },
    ],
    distributeList: [        //对接的银行列表
      { id: 1, name: '善美保理' },
      { id: 2, name: '招商银行' },
      { id: 3, name: '浙商银行' },
      //{ id: 4, name: '中信银行' },
      { id: 5, name: '北金所' },
      { id: 6, name: '光大银行'},
      { id: 7, name: '北京银行'},
      { id: 8, name: '浦发银行'},
      { id: 9, name: '汇通商业保理'},
    ],
    auth: this.props.auth || {}
  }
  getInitState() {
    const { exercise, auth } = this.props
    exercise.StatusId = exercise.status_id || exercise.statusId

    // const sign_agreement = exercise.rolseId === '2' ? '1': '';
    let sign_agreement = '1';
    let phone = auth.phone ? auth.phone : '15719478614';
    let isverification_code = false //判断是否验证
    let KLStart = true;
    let rolesId = auth.roles ? auth.roles[0].id : 0;
    //let paymentType = exercise.paymentType;
    if (exercise.checkStatus === '3' && exercise.table === 'm2-4') {
      KLStart = false;
      isverification_code = true
    } else if (exercise.checkStatus === '5' && (exercise.table === 'm3-1-1')) {
      isverification_code = exercise.mxf_number ? false : true;
      KLStart = exercise.open_day ? false : true;
    } else if ((exercise.table === 'r6-3-1' || exercise.table === 'r6-2-1') && rolesId === 9) {
      KLStart = false;
    } else if (exercise.table === 'm3-2-2' && exercise.StatusId === 5) {
      KLStart = false;
      isverification_code = true
    } else if ((exercise.table === 'm3-2-2') && rolesId === 12 && exercise.distribute === '光大银行') {
      KLStart = false;
      isverification_code = true
    } else if ((exercise.table === 'm3-2-2') && rolesId === 12 && exercise.distribute === '北京银行') {
      KLStart = false;
      isverification_code = true
    }

    // if (exercise.checkStatus === '6') {
    //  isverification_code = true
    // }
    const form = {
      opinion_list: [],
      ...exercise,
      sign_agreement, //签订协议
      check_opinion: '',
      verification_code: '',
      isverification_code,
      form_no: exercise.checkStatus || '3',
      KLStart,
      phone,
      returnBy: '退回上一级',
      // distribute: '善美保理',
      repayment_ways: "中金支付", //20210609 新增弹框展示字段
      cmb_rz_type: '',
      bobzxsqs: false,

    }

    return form
  }
  getInitState2() {
    const { exercise } = this.props
    let sign_agreement = '';
    if (exercise.table === 'm2-4' || exercise.table === 'r6-2-1' || exercise.table === 'm3-1-1') {

      sign_agreement = exercise.mxc_number ? check.sign_flow : check.sign_agreement;
      if (null !== exercise.mxf_number && exercise.mxf_number !== undefined) {
        sign_agreement = '';
      }
    }
    else if (exercise.table === 'm3-1-9' || exercise.table === 'r6-4-1') {
      sign_agreement = check.sign_flow
    }
    else if ((exercise.table === 'm3-2-2' && exercise.distribute !== '光大银行' && exercise.distribute !== '北京银行') || exercise.table === 'r6-3-1') {
      sign_agreement = check.sign_financing
    }
    else if(exercise.table === 'm3-2-2' && exercise.distribute === '光大银行'){
      sign_agreement = check.sign_cebFinancing
    }else if(exercise.table === 'm3-2-2' && exercise.distribute === '北京银行'){
      sign_agreement = check.sign_bobFinancing
    }
    return { ...check, sign_agreement };
  }

  componentWillUnmount() {
    clearInterval(this.state.times);
  }
  //  //签章
  sign_agreements = () => {
    const { fdd_file_name, KLStart, isverification_code,
      table, open_day, StatusId = '', fdd_file_path, open_credit_code, verification_code, distribute } = this.state.form;
    const { auth, form, signatureMsg, secondNumber } = this.state;
    const rolesId = auth.roles ? auth.roles[0].id : '';
    let Url = '';
    let body = {};
    if (table === 'm2-4') {//融单开具复核
      Url = '/dzqz/extsignAuto/KLA';//开立合同-开立方签章
      body = {
        credit_no: form.open_credit_code,//开立方社会信用代码
        fdd_file_name: fdd_file_name,//开立合同编号
        //签章重复问题处理--20201102
        type: 1,//1-开立；2-流转；3-融资
        oid: this.state.form.id,//开立/流转/融资id
      }
    } else if (table === 'r6-2-1' && rolesId === 9) {//融单开立审核
      Url = '/dzqz/extsignAuto/KLB';//开立合同-平台签章
      body = {
        fdd_file_name: fdd_file_name,//开立合同编号
        //签章重复问题处理--20201102
        type: 1,//1-开立；2-流转；3-融资
        oid: this.state.form.id,//开立/流转/融资id
      }
    } else if (table === 'm3-1-1' && open_day) {//融单签收
      Url = '/dzqz/extsignAuto/KLC';//开立合同-接收方签章
      body = {
        credit_no: form.receive_credit_code,//接收方社会信用代码
        fdd_file_name: fdd_file_name,//开立合同编号
        //签章重复问题处理--20201102
        type: 1,//1-开立；2-流转；3-融资
        oid: this.state.form.id,//开立/流转/融资id
      }
    } else if (table === 'm3-2-2' && StatusId === 5 && distribute !== '光大银行' && distribute !== '北京银行') {//融资复核
      if(distribute === null){
        Url = '/dzqz/extsignAuto/RZA';//融资合同-融资方签章
      }else{
        Url = '/dzqz/extsignAuto/RZAOther';//融资合同-融资方签章
      }
      body = {
        credit_no: open_credit_code ? open_credit_code : form.organization.credit_no,//融资方社会信用代码
        fdd_file_name: fdd_file_name,//融资合同编号
        //签章重复问题处理--20201102
        type: 3,//1-开立；2-流转；3-融资
        oid: this.state.form.id,//开立/流转/融资id
      }

    } else if (table === 'm3-2-2' && StatusId === 5 && distribute === '光大银行'){
      Url = '/dzqz/extsignAuto/RZACEB';//光大融资合同-融资方签章
      body = {  
        credit_no: open_credit_code ? open_credit_code : form.organization.credit_no,//融资方社会信用代码
        fdd_file_name: fdd_file_name,//融资合同编号
        //签章重复问题处理--20201102
        type: 3,//1-开立；2-流转；3-融资
        oid: this.state.form.id,//开立/流转/融资id
      }
    } else if (table === 'm3-2-2' && StatusId === 5 && distribute === '北京银行'){
      Url = '/dzqz/extsignAuto/RZABOB';//光大融资合同-融资方签章
      body = {  
      credit_no: open_credit_code ? open_credit_code : form.organization.credit_no,//融资方社会信用代码
      fdd_file_name: fdd_file_name,//融资合同编号
      //签章重复问题处理--20201102
      type: 3,//1-开立；2-流转；3-融资
      oid: this.state.form.id,//开立/流转/融资id
      }
    } else if (table === 'r6-3-1' && rolesId === 9) {//融资申请审核
      if(auth.organization.name === "融单平台"){
        Url = '/dzqz/extsignAuto/RZB';//融资合同-平台签章
      }else{
        Url = '/dzqz/extsignAuto/RZBOther';//融资合同-平台签章
      }
      body = {
        fdd_file_name: fdd_file_name,//融资合同编号
        //签章重复问题处理--20201102
        type: 3,//1-开立；2-流转；3-融资
        oid: this.state.form.id,//开立/流转/融资id
      }
    }
    if (!KLStart && signatureMsg !== '正在签订中...') {
      if (Url === '') {
        this.setState({
          form: { ...form, KLStart: true },
          signatureMsg: '签章还在更新中...'
        })
      }
      else if (!isverification_code || rolesId === 9) {
        this.setState({
          signatureMsg: '正在签订中...',

        })
        //签章
        fetching(Url, {
          method: 'POST',
          body: JSON.stringify(body)
        }, 2).then(res => {
          if (res !== undefined) {
            if (res.code === 200) {
              window.open(res.data.viewpdf_url || fdd_file_path);
              this.setState({
                form: { ...form, KLStart: true },
                signatureMsg: '签订成功'
              })
            } else {
              this.setState({
                signatureMsg: res.msg || '签章失败'
              })
            }
          }
          

        })
      }
      else if (isverification_code && secondNumber !== '' && verification_code === secondNumber) {
        this.setState({
          signatureMsg: '正在签订中...',

        })
        //签章
        fetching(Url, {
          method: 'POST',
          body: JSON.stringify(body)
        }, 2).then(res => {
          if (res !== undefined) {
            if (res.code === 200) {
              window.open(res.data.viewpdf_url || fdd_file_path);
              this.setState({
                form: { ...form, KLStart: true },
                signatureMsg: '签订成功'
              })
            } else {
              this.setState({
                signatureMsg: res.msg || '签章失败'
              })
            }
          }
          

        })
      } else if (isverification_code && StatusId === 5 && distribute === '光大银行' && table === 'm3-2-2' ) {
        this.setState({
          signatureMsg: '正在签订中...',

        })
        //签章
        fetching(Url, {
          method: 'POST',
          body: JSON.stringify(body)
        }, 2).then(res => {
          if (res !== undefined) {
            if (res.code === 200) {
              window.open(res.data.viewpdf_url || fdd_file_path);
              this.setState({
                form: { ...form, KLStart: true },
                signatureMsg: '签订成功'
              })
            } else {
              this.setState({
                signatureMsg: res.msg || '签章失败'
              })
            }
          }

        })
      } else if (isverification_code && StatusId === 5 && distribute === '北京银行' && table === 'm3-2-2' ) {
        this.setState({
          signatureMsg: '正在签订中...',

        })
        //签章
        fetching(Url, {
          method: 'POST',
          body: JSON.stringify(body)
        }, 2).then(res => {
          if (res !== undefined) {
            if (res.code === 200) {
              window.open(res.data.viewpdf_url || fdd_file_path);
              this.setState({
                form: { ...form, KLStart: true },
                signatureMsg: '签订成功'
              })
            } else {
              this.setState({
                signatureMsg: res.msg || '签章失败'
              })
            }
          }

        })
      } else if (isverification_code) {
        alert('验证码输入不正确');
      }


    }

  }

  handleFileChange = e => {
    this.setState({
      [e.target.name]: e.target.files[0],
    })
  };

  //查看协议
  viewAgreement = () => {
    // this.setState({ open2: !this.state.open2 })
    const { fdd_file_path = '', mxf_number ,distribute} = this.state.form;
    if (fdd_file_path && fdd_file_path !== 'null') {
      window.open(fdd_file_path)
    } else {
      if(mxf_number && distribute === '光大银行'){
        window.open(DEFAULT_CONTRCT_URL + 'contract/template/ceb.pdf')
      }else if (mxf_number && distribute !== '光大银行' && distribute !== '北京银行') {
        window.open(DEFAULT_CONTRCT_URL + 'contract/template/rz.pdf')
      }else if (mxf_number && distribute === '北京银行') {
        window.open(DEFAULT_CONTRCT_URL + 'contract/template/bob.pdf')
      } else {
        window.open(DEFAULT_CONTRCT_URL + 'contract/template/kl.pdf')
      }
    }
  }

  //查看征信授权协议
  viewAgreementZX = () => {
    const { bobzxsq_file_path = '', mxf_number ,distribute} = this.state.form;
    if (bobzxsq_file_path && bobzxsq_file_path !== 'null') {
      window.open(bobzxsq_file_path)
    } else {
      if(mxf_number && distribute === '北京银行'){
        window.open(DEFAULT_CONTRCT_URL + 'contract/template/bobzxsq.pdf')
      }
    }
  }

  onCebSkip = () =>{
    const { fdd_file_path = '', mxf_number ,distribute} = this.state.form;
    if (distribute === '光大银行'){
      //const mxf_number = row.mxf_number
      const UpGwMsg = "";
      fetching(`/msapi/ceb/gatewayCebString?mxf_number=` + mxf_number,{
        method:'get' , 
      }).then(res =>{
        if (res !== undefined) {
          if(res.code === 200){
            const param ={
              "UpGwMsg":res.data
            }
          //https://111.205.51.120:17443/cebent/preRELLogin.do?_locale=zh_CN
          //https://ebank.cebbank.com:17443/cebent/preRELLogin.do?_locale=zh_CN
          Post("https://ebank.cebbank.com/cebent/preRELLogin.do?_locale=zh_CN", param);
            // return false;
            function  Post(URL, PARAMS) {
              var temp = document.createElement("form");
              temp.action = URL;
              temp.method = "post";
                    //如需新打开窗口 form 的target属性要设置为'_blank'
              temp.target = "_blank";
              temp.style.display = "none";
              for (var x in PARAMS) {
                var opt = document.createElement("textarea");
                opt.name = x;
                opt.value = PARAMS[x];
                temp.appendChild(opt);
              }
              document.body.appendChild(temp);
              temp.submit();
              return temp;
            }
        } else {
          alert(res.msg)
        }
        }
        
      })
    } else {
      alert("非光大直融不需要进行光大数字签名")
    }
  }

  //光大直融文件上送
  uploadSubmit = () =>{
    //let mxf_number = this.props.exercise.mxf_number;
    const { fdd_file_path = '', mxf_number ,distribute} = this.state.form;
    //type === 1  为直融文件上送
    fetching(`/msapi/ceb/cebFileUpload?mxf_number=${mxf_number}&type=1`,{
      method:'get' ,
    }).then((res) => {
        alert(res.msg)
    })

  }

  //光大直融发起
  cebFinancingSubmit = () =>{
    const {  mxf_number } = this.state.form;
    fetching(`/msapi/ceb/applyCebFinancing?mxf_number=${mxf_number}`,{
      method:'get' ,
    }).then((res) => {
        alert(res.msg)
    })

  }


  //放款金额冻结
  frozenBalance = () => {
    //放款查找余额冻结余额 20210420
    const formFB = this.state.form;
    if(formFB.payment_type === "还款日不固定"){
      formFB.net_financing_amount = formFB.amount
    }
    const body5 ={
    id: formFB.id,
    extraction:formFB.extraction,
    applicant: formFB.applicant,
    amount: formFB.amount,
    netFinancingAmount: formFB.net_financing_amount,
    mxNumber: formFB.mx_number,
    mxfNumber: formFB.mxf_number,
    url: window.location.href,
  }
    fetching('/loan/frozenBalance', {
      method: 'POST',
      body: JSON.stringify(body5)
    }).then(res => {
      if (res) {
        try {
          if(res.code===200){ //未冻结
              //表单提交调用
            let url = DEFAULT_PAYMENT_URL+"gateway/new/bidding_frozen";
            if(window.confirm("进入冻结页面,是否要冻结这笔给"+formFB.applicant+",融资金额为"+formFB.amount+"元,冻结融资净额为"+formFB.net_financing_amount+"元的这笔款项?")){
              //this. postExcelFile(res.data,'http:192.168.1.5:8088/gateway/new/bidding_frozen')
              this.postExcelFile(res.data,url)
              return true;  //打开冻结页面                
            }else{
              return false; //不打开冻结页面                  
            }
          //查找是否冻结成功,如果冻结成功,走向rdcw2 发送url
          } else if ( res.code === 300 ) { //冻结成功  继续往下走
            alert("这笔融资金额已经冻结成功,请进行审核操作")
          } else { 
            alert(res.msg)
            return;//冻结失败原因提示,不走后面逻辑
          }
        } catch (error) {
            alert('冻结发起失败')
          }
      }
      
    }) 
  }

    //线下支付
    OfflinePayment = () => {
      //放款查找余额冻结余额 20210420
      const formFB = this.state.form;
      // if(formFB.payment_type === "还款日不固定"){
      //   formFB.net_financing_amount = formFB.amount
      // }
      
    if(window.confirm("是否要修改当前融资为线下/线上支付?线上支付有冻结按钮,确认修改请点击'确认',页面会自动刷新重新获取数据")){
      fetching(`/msapi/financing/updatePayementType?mxf_number=${formFB.mxf_number}`).then(res => {
        if (res) {
          //this.setState({ opinion_list: res })
          alert(res.msg)
          window.location.reload();//TODO 后续修改
          //this.setState()
        }
      })
      return true; 
    }else{
      return false;        
    }
      
  }

  //融资分配查看收款方首款权限
  paymentRegistrationStatus = () => {
    const formFB = this.state.form;
    const body5 ={
    id: formFB.id,
    extraction:formFB.extraction,
    applicant: formFB.applicant,
    amount: formFB.amount,
    netFinancingAmount: formFB.net_financing_amount,
    mxNumber: formFB.mx_number,
    mxfNumber: formFB.mxf_number,
  }
    fetching('/loan/paymentRegistrationStatus', {
      method: 'POST',
      body: JSON.stringify(body5)
    }).then(res => {
      if (res) {
        try {
          if(res.code===200){ 
            alert(res.msg)
          } else { 
            alert(res.msg)
            return;//冻结失败原因提示,不走后面逻辑
          }
        } catch (error) {
            alert('查询融资人收款权限失败')
            }
      }
      
    }) 
  }

  //查看收款方收款权限
  receiveRegistrationStatus = () => {
    const formFB = this.state.form;
    const body5 ={
    id: formFB.id,
    extraction:formFB.extraction,
    applicant: formFB.receive || formFB.applicant,
    amount: formFB.amount,
    netFinancingAmount: formFB.net_financing_amount,
    mxNumber: formFB.mx_number,
    mxfNumber: formFB.mxf_number,
  }
    fetching('/loan/paymentRegistrationStatus', {
      method: 'POST',
      body: JSON.stringify(body5)
    }).then(res => {
      if (res) {
        try {
          if(res.code===200){ 
            alert(res.msg)
          } else { 
            alert(res.msg)
            return;//冻结失败原因提示,不走后面逻辑
          }
        } catch (error) {
            alert('查询接收人收款权限失败')
            }
      }
      
    }) 
  }
  //光大银行融资方授权信息查询
  
  financingAuthorizationQuery = () => {
    const mxf_number = this.state.form.mxf_number;
    fetching(`/msapi/ceb/findCEBFinaningAuthorization?mxf_number=${mxf_number}`).then(res =>{
      if (res !== undefined) {
        if(res.code === 200){
          alert(res.msg)
        }else if(res.code === 201){
          alert(res.msg)
        }else if(res.code === 202){
          alert(res.msg)
        }
      }
      
      return;
    })
  }
 



  cmbRzVerify = () => {
    const formCmb = this.state.form;
    if (formCmb.distribute !== '招商银行') {
      alert('善美分配不需推送招行');
      return
    }
    fetching(`/cmb/applyFinancing?mxfNumber=${formCmb.mxf_number}`).then(res => {
      if (res) {
          alert(res.msg);
          if (res.result === 'success'){
            this.state.form.cmb_rz_type = '1';
          }else {
            this.state.form.cmb_rz_type = '0';
          }
          }
        })

  }

  spdRzVerify = () => {
    const formCmb = this.state.form;
    if (formCmb.distribute !== '浦发银行') {
      alert('善美分配不需推送浦发银行');
      return
    }
    fetching(`/msapi/spd/spdApplyFinancing?mxfNumber=${formCmb.mxf_number}`).then(res => {
      if (res) {
          alert(res.msg);
          if (res.result === 'success'){
            this.state.form.cmb_rz_type = '1';
          }else {
            this.state.form.cmb_rz_type = '0';
          }
          }
        })

  }

  czbRzVerify = () => {
    const formCzb = this.state.form;
    if (formCzb.distribute !== '浙商银行') {
      alert('善美分配不需推送浙商银行');
      return
    }
    fetching(`/msapi/czb/czbApplyFinancing?mxfNumber=${formCzb.mxf_number}`).then(res => {
      if (res) {
          alert(res.msg);
          if (res.result === 'success'){
            this.state.form.cmb_rz_type = '1';
          }else {
            this.state.form.cmb_rz_type = '0';
          }
          }
        })

  }

  bobRzVerify = () => {
    const formBob = this.state.form;
    if (formBob.distribute !== '北京银行') {
      alert('善美其他分配不需推送北京银行');
      return
    }
    if (!formBob.KLStart) {
      alert('融单北京银行融资协议未签订');
      return
    }
    if (!formBob.bobzxsqs) {
      alert('北京银行征信授权书未签订');
      return
    }
    fetching(`/msapi/bob/applyBobFinancing?mxf_number=${formBob.mxf_number}`).then(res => {
      if (res) {
          alert(res.msg);
          }
        })

  }
  

 bobZXSQ = () => {
    const form = this.state.form;
    if (form.distribute !== '北京银行') {
      alert('其他业务不需签订征信授权书');
      return
    }
    let Url = '/dzqz/extsignAuto/BOBZX';//北京银行融资合同-融资方签章
      let body = {  
      credit_no: form.open_credit_code ? form.open_credit_code : form.organization.credit_no,//融资方社会信用代码
      fdd_file_name: form.fdd_file_name,//融资合同编号
      //签章重复问题处理--20201102
      type: 3,//1-开立；2-流转；3-融资
      oid: this.state.form.id,//开立/流转/融资id
      }
     //签章
     fetching(Url, {
      method: 'POST',
      body: JSON.stringify(body)
    }, 2).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          window.open(res.data.viewpdf_url );
          this.setState({
            form: { ...form, bobzxsqs: true },
          })
        } else {
	    alert("北京银行征信授权书签署失败，请重试")
        }
      }
      

    })

  }

  //中信额度查询
  ccbGetAmount = () => {
    const formBfe = this.state.form;
    if (formBfe.distribute !== '中信银行') {
      alert('非中信银行不需要查询额度');
      return
    }
    fetching(`/msapi/ccb/ccbGetAmount?MxfNumber=${formBfe.mxf_number}`).then(res => {
      if (res) {
          alert(res.msg);
          //组装页面弹框
          // if (res.result === 'success'){
          //   this.state.form.cmb_rz_type = '1';
          // }else {
          //   this.state.form.cmb_rz_type = '0';
          // }
          }
        })

  }

  //中信客户建档推送
  CCBCustomerFiling = () => {
    const formBfe = this.state.form;
    if (formBfe.distribute !== '中信银行') {
      alert('非中信银行不需要进行建档');
      return
    }
    fetching(`/msapi/ccb/CCBCustomerFiling?MxfNumber=${formBfe.mxf_number}`).then(res => {
      if (res) {
          alert(res.msg);
          //组装页面弹框
          // if (res.result === 'success'){
          //   this.state.form.cmb_rz_type = '1';
          // }else {
          //   this.state.form.cmb_rz_type = '0';
          // }
          }
        })

  }

  //中信客户建档状态查询
  getCCBCustomerFilingResults = () => {
    const formBfe = this.state.form;
    if (formBfe.distribute !== '中信银行') {
      alert('非中信银行不需要进行建档');
      return
    }
    fetching(`/msapi/ccb/getCCBCustomerFilingResults?MxfNumber=${formBfe.mxf_number}`).then(res => {
      if (res) {
          alert(res.msg);
          //组装页面弹框
          // if (res.result === 'success'){
          //   this.state.form.cmb_rz_type = '1';
          // }else {
          //   this.state.form.cmb_rz_type = '0';
          // }
          }
        })

  }

  //中信融资推送
  ccbRzVerify = () => {
    const formBfe = this.state.form;
    if (formBfe.distribute !== '中信银行') {
      alert('善美分配不需推送中信银行');
      return
    }
    fetching(`/msapi/ccb/CCBFinancing?MxfNumber=${formBfe.mxf_number}`).then(res => {
      if (res) {
          alert(res.msg);
          if (res.result === 'success'){
            this.state.form.ccb_rz_type = '1';
          }else {
            this.state.form.ccb_rz_type = '0';
          }
          }
        })

  }


  //放款支付
  releasePaymentModel = () => {
    //线上打款功能----20210417
    const formFB = this.state.form;
    if(formFB.payment_type === "还款日不固定"){
      formFB.net_financing_amount = formFB.amount
    }
       let body2 = {
       mxf_number:formFB.mxf_number,
       open_credit_code:formFB.open_credit_code,
      url: window.location.href,
      }
       fetching('/loan/thaw', { //第一次调用 放款查询操作
          method: 'POST',
          body: JSON.stringify(body2)
         }).then(res2 => {
            try {
             if (res2.code === 200) { //放款失败调用放款接口
              let url = DEFAULT_PAYMENT_URL+"gateway/shanmei/release_payment_model";
              if(window.confirm("进入放款页面,是否要支付这笔给"+formFB.applicant+",融资金额为"+formFB.amount+"元,融资净额为"+formFB.net_financing_amount+"元的这笔款项?")){
               //this. postExcelFile(res2.data,'http://192.168.1.11:8088/gateway/new/transaction_transfer')
               //this. postExcelFile(res2.data,'http://192.168.1.11:8089/gateway/shanmei/release_payment_model')
               this.postExcelFile(res2.data,url)
               return true;  //打开冻结页面                
                }else{
               return false; //不打开冻结页面                  
              }
                
             } else if (res2.code === 300) { //放款成功
                    alert("这笔融资资金已经放款成功,请进行审核操作")
             } else {
              alert(res2.msg)
              return;
             }
            } catch (error) {
              alert('支付发起失败')
            }
     })
        }
    
//冻结释放
  freezeRelease  = () => {
    const formFR = this.state.form;
      let mxf_number = formFR.mxf_number ;
     
        }

  //查看单据
  viewAgreement2 = () => {
    // this.setState({ open2: !this.state.open2 })
    const { mxc_number, fdd_kld_path = '', fdd_lzd_path = '' } = this.state.form;

    if (mxc_number) {
      window.open(fdd_lzd_path)
    } else {
      window.open(fdd_kld_path)
    }
  }
  //发送验证码
  onVerificationCode = () => {
    const { table, mxc_number, phone, flow_date, mxf_number } = this.state.form;

    let url = '';
    if (table === 'm2-4') {
      url = `/api/sms/KL?phone=${phone}`;
    } else if (table === 'm3-1-1' && !mxc_number) {
      url = `/api/sms/KL?phone=${phone}`;
    } else if (table === 'm3-1-1' && flow_date) {
      url = `/api/sms/LZ?phone=${phone}`;
    } else if (table === 'm3-1-1' && mxf_number) {
      url = `/api/sms/RZ?phone=${phone}`;
    } else if (table === 'm3-1-9') {
      url = `/api/sms/LZ?phone=${phone}`;
    } else if (table === 'm3-2-2') {
      url = `/api/sms/RZ?phone=${phone}`;
    } else if (table === 'r6-3-1') {
      url = `/api/sms/RZ?phone=${phone}`;
    }


    if (url === '') {
      alert('短信接口为空')
      return;
    }
    fetching(url, {
      method: 'GET'
    }).then(res => {
      if (res !== undefined) {
        if (res.code === 200) {
          const times = setInterval(() => {
            const { second } = this.state;
            if (second === 0) {
              clearInterval(times);
              this.setState({
                second: 60
              })
            } else {
              this.setState({
                second: second - 1
              })
            }
          }, 1000)
          this.setState({
            secondNumber: res.data,
            times
          })
        } else {
          this.setState({
            second: 60
          })
          alert(res.msg || '验证码发送失败')
        }
      } else {
        alert('验证码发送失败')
      }
      

    })

  }

  //生成form 表单
  postExcelFile(params, url) { // params是post请求需要的参数，url是请求url地址
    var form = document.createElement('form')
    form.style.display = 'none'
    form.action = url
    form.method = 'post'
    //重新打开新的窗口设置form target属性
    form.target = '_blank'
    document.body.appendChild(form)
    for (var key in params) {
      var input = document.createElement('input')
      input.type = 'hidden'
      input.name = key
      input.value = params[key]
      form.appendChild(input)
    }
    form.submit()
    form.remove()
  }

//下载放款回单
handleUpload = () => {
  let type = 40;//中金/清算支付回单类型(订死的)
  const { mxf_number } = this.state.form;
  if (!mxf_number) {
    return;
  }
  fetching(`/loan/thaw?mxf_number=${mxf_number}`, {
    method: 'POST',
  }).then(res => {
    try {
      if (res === null) {
        alert("该单未进行中金放款操作,暂无放款回单")
      } else if (res !== null ){
        if (res.status === 1) { //已完成放款操作
         //let url = `http://192.168.1.11:8089/gateway/payment/downladReceipt?txSn=${res.serialNumber}&type=${type}`; 
        let url = {DEFAULT_PAYMENT_URL} + `/gateway/payment/downladReceipt?txSn=${res.serialNumber}&type=${type}`;
          window.open(url)
        } else {
          alert("该单完成放款操作,暂无放款回单")
        } 
      }
    } catch (error) { }
  })
}
  // 提交
  handleSubmit = async (form2, name) => {
    const { auth = {}, secondNumber } = this.state;
    let typePath1 = "";
    let typePath2 = "";
    let typePath3 = "";
    const stateForm = this.state.form;
    const { exercise } = this.props
    const form = form2 ? { ...form2 } : stateForm;
    const text = name || '取消';
    let typePath = '/msapi/flow/save';//审核接口
    if(auth.organization.name === "融单平台"){
        typePath1 = '/dzqz/extsignAuto/RZC';
        typePath2 = '/dzqz/generateContract/RZ';//善美单据填充
        typePath3 = '/dzqz/ffilling'
    }else{
        typePath1 = '/dzqz/extsignAuto/RZCOther';
        typePath2 = '/dzqz/generateContract/RZOhter';//其他保理单据填充
        typePath3 = '/dzqz/ffillingOther'
    }
    let fillingBody = {};
    const rolesId = auth.roles ? auth.roles[0].id : '';
    let flowId = 'issue_id'

    //融资合同填充
    const body4 = {

      application_number: stateForm.open_credit_code ? stateForm.open_credit_code : form.organization.credit_no,//	融资方 社会信用代码1
      amount: form.amount,//	融资金额
      right_resource: form.right_resource || '是',//	追索权1
      financing_maturity: form.financing_maturity,//	融资期限（天）
      payment_day: form.payment_day,//	承诺付款日
      financing_time: form.financing_time,//	融资申请时间
      synthesis_rate: form.synthesis_rate,//	预计融资息费率
      interest: form.interest,//	预计融资息费金额
      id: form.id,//	融资 id
    }
    const body5 ={
      id: form.id,
      extraction:form.extraction,
      applicant: form.applicant,
      amount: form.amount,
      netFinancingAmount: form.net_financing_amount,
      mxNumber: form.mx_number,
      mxfNumber: form.mxf_number,
      url: window.location.href,
    }
    
    //开立
    if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3'
      || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4' || exercise.table === 'r6-2-9') {
      typePath = '/msapi/flow/save';
      flowId = 'issue_id'
    }
    //流转
    else if (
      exercise.table === 'm3-1-9' ||
      exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' ||
      exercise.table === 'r6-4-8' ||exercise.table === 'r6-4-12') {
      typePath = '/msapi/cflow/save';
      flowId = 'circulation_id'
    }
    //融资
    else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' ||
      exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6' || exercise.table === 'r6-3-15' ) {
      typePath = '/msapi/fflow/save';
      //typePath2 = '/dzqz/generateContract/RZ';
      flowId = 'financing_id'
      fillingBody = { ...body4 };
    }
    //光大融资
    else if (exercise.table === 'r21-1-1'|| exercise.table === 'r19-2-1'){
      typePath = '/msapi/fflow/save';
      flowId = 'financing_id'
    }
    //签收verification_code
    else if (exercise.table === 'm3-1-1' || exercise.table === 'r19-3-1') {
      if (form.flow_date) {
        typePath = '/msapi/cflow/save';
        flowId = 'circulation_id'
      } else if (form.mxf_number) {
        typePath = '/msapi/fflow/save';
        flowId = 'financing_id'
      } else
        typePath = '/msapi/flow/save';
    }
    // 分配北京银行添加提示
    if (exercise.table === 'r6-3-1' &&  form.distribute === "北京银行") {
      if (window.confirm('请确认基础合同及发票登记表信息完整')) {

      }else {
        return
      }
    }

    //参数
    const body = {
      distribute:form.distribute,//2021-04-20
      ttid: form.ttid,//20210225
      organization_id: form.organizationId,//20200910
      auth,//用户信息
      check_opinion: form.check_opinion,//审核意见
      mx_number: form.mx_number || '', //可以字符串拼接
      audit_status: form.status,  //传状态id name form.status
      sign_agreement: form.sign_agreement,//签订融单协议
      form_no: stateForm.form_no,
      returnBy: form.returnBy,
      mxc_number: form.mxc_number || '',
      mxf_number: form.mxf_number || '',
      [flowId]: form.id,
      statusId:form.status_id,
    }
    if (form.flow_date) body.cid = form.id

    let body2 = {
        mxf_number:form.mxf_number,
        open_credit_code: form.open_credit_code,
        url: window.location.href,
       }
    if (form.flow_date) body.cid = form.id
  
    //提交表单

    if (text !== '取消') {
      if(form.StatusId === 4 && stateForm.verification_code === "" && body.mxf_number === "" && rolesId !== 1){
        alert('请您进行验证码校验');
      } else if (form.StatusId === 4  && rolesId === 1 && (form.returnBy !== '退回经办人' || stateForm.form_no === '3')) {
        alert('当前页面只能进行退回经办人操作');
      } else if (stateForm.isverification_code && stateForm.verification_code !== secondNumber && stateForm.form_no !== '2') {
        alert('验证码输入不正确');
      } else if (flowId !== 'circulation_id' && !stateForm.KLStart  && stateForm.form_no !== '2' && stateForm.form_no !== '6') {
        alert('你还没有签订协议');
      } else if (form.distribute === '招商银行') {
        fetching(`/cmb/cmbFindFinancingById?id=${form.id}`).then(res => {
        if (res === undefined && res.data.cmbRzStatus === "") {
          alert("招行融资推送失败无法提交，请重新提交！")
          return
         }else {
          if (stateForm.form_no === "2") {
            const oid = form.id;
            //type--1:开立；2：流转；3：融资
            var type = 0;
            if (form.returnBy.indexOf('经办人') !== -1 || (form.returnBy.indexOf('上一级') !== -1 && auth.roles[0].id === 12)) {
              //开立： m2-4:融单开具复核; m2-5-3:已退回融单; r6-2-1:融单开立审核; r6-2-4:已退回融单
              if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3' || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4') {
                type = 1;
              } else if (exercise.table === 'm3-1-9' || exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' || exercise.table === 'r6-4-8') {
                //流转： m3-1-9:融单流转复核; m3-1-10:已退回流转; r6-4-1:融单流转审核; r6-4-8:已退回流转
                type = 2;
              } else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' || exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') {
                //融资: m3-2-2:融资复核; m3-2-8:已退回融资; r6-3-1:融资申请审核; r6-3-6:已退回融资
                type = 3;
              }
              fetching(`/invoiceVerification/fpyzwDelete?type=${type}&oid=${oid}`).then(res => {
                if (res !== undefined && res.code === 200) {
                  //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
                  alert(res.msg);
                } else {
                  alert(`发票验真原有记录标记清除失败！`);
                }
              })
            }
          }
          fetching(typePath, {
            method: 'POST',
            body: JSON.stringify(body)
          }).then(res => {
            if (res && exercise.table === 'r6-3-1' && rolesId === 3) {
              fetching(typePath2, {
                method: 'POST',
                body: JSON.stringify(fillingBody)
              }).then(res2 => {
                if (res2.code !== 200) {
                  alert('合同填充失败')
                }
              })
            } else if (res && form.flow_date && res.statusId === 5) {//流转待签收
              fetching('/dzqz/extsignAuto/LZ', {
                method: 'POST',
                body: JSON.stringify({
                  id: form.id
                })
              }).then(res2 => {
                try {
                  if (res2.code === 200) {
                    alert('已给开立人邮箱发送《应收账款转让通知(善美融单转让)》')
                  } else {
                    alert('发送《应收账款转让通知(善美融单转让)》失败')
                  }
                } catch (error) {
                  alert('发送《应收账款转让通知(善美融单转让)》失败')
                }
              })
              let body1 = {
                c_id: form.id,
                mx_number:form.mx_number,
              }
              fetching('/dzqz/filling', {
                method: 'POST',
                body: JSON.stringify(body1)
              }).then(res2 => {
                try {
                  if (res2.code === 200) {
                    if (res2.error) {
                      alert(res2.error)
                      return;
                    }
                    alert('善美融单信息单填充成功')
                  } else {
                    alert(res.msg)
                  }
                } catch (error) {
                  alert('《善美融单信息单》填充失败')
                }
              })
              
              if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
                let ocId =  form.id;
                let type = 2;
                let result = "400";
                if (res.statusId === 5) {
                  result = "200";
                } 
                let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
                fetching(url, {
                  method: 'GET',
                }).then(res3 => {
                  try {
                    if (res3.code === 200) {
                      if (res3.error) {
                        alert(res3.error)
                        return;
                      }
                      alert('审核结果推送化工成功')
                    } else {
                      alert(res3.msg)
                    }
                  } catch (error) {
                    alert('审核结果推送失败,请联系管理员')
                  }
                })
              }
            } else if (res && form.mxf_number && res.statusId === 11) {//融资放款 20210417
              fetching(typePath1, {
                method: 'POST',
                body: JSON.stringify({
                  id: form.id
                })
              }).then(res2 => {
                if (res2.code === 200) {
                  alert('已给开立人邮箱发送《应收账款转让通知（善美融单保理）》')
                } else {
                  alert('发送邮件失败')
                }
              })
              let body1 = {
                c_id: form.ocId,
                mx_number:form.mx_number,
                mxf_number:form.mxf_number,
              }
              fetching(typePath3, {
                method: 'POST',
                body: JSON.stringify(body1)
              }).then(res2 => {
                try {
                  if (res2.code === 200) {
                    if (res2.error) {
                      alert(res2.error)
                      return;
                    }
                    alert('善美融单回单填充成功')
                  } else {
                    //alert(res.msg)
                  }
                } catch (error) {
                  alert('《善美融单回单》填充失败')
                }
              })
                     
        
  
            } else if (res && (stateForm.form_no === '2' && (rolesId === 12 || rolesId === 13 || form.returnBy === '退回经办人'))) {
              fetching('/fdd/withdraw', {
                method: 'POST',
                body: JSON.stringify({
                  contract_id: form.fdd_file_name
                })
              })
              if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
                let type = 0;
                if (form.mxc_number !== undefined && form.mxc_number !== null && form.mxc_number !== "") {
                  type = 2; 
                } else {
                  type = 1; 
                }
                let ocId =  form.id;
                let result =  "400";
                let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
                fetching(url, {
                  method: 'GET',
                }).then(res3 => {
                  try {
                    if (res3.code === 200) {
                      if (res3.error) {
                        alert(res3.error)
                        return;
                      }
                      alert('审核结果推送化工成功')
                    } else {
                      alert(res3.msg)
                    }
                  } catch (error) {
                    alert('审核结果推送失败,请联系管理员')
                  }
                })
              }
            } else if (res && res.issue_id && res.statusId === 5 && !res.mxc_number && !res.mxf_number) {//开立签收
              let body1 = { mx_number: form.mx_number }
              fetching('/dzqz/filling', {
                method: 'POST',
                body: JSON.stringify(body1)
              }).then(res2 => {
                try {
                  if (res2.code === 200) {
                    if (res2.error) {
                      alert(res2.error)
                      return;
                    }
                    alert('善美融单信息单填充成功')
                  } else {
                    alert(res.msg)
                  }
                } catch (error) {
                  alert('《善美融单信息单》填充失败')
                }
  
              })      
              if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
                let ocId =  form.id;
                let type = 1;
                let result =  400;
                if (res.statusId === 5) {
                  result = 200;
                } 
                let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
                fetching(url, {
                  method: 'GET',
                }).then(res3 => {
                  try {
                    if (res3.code === 200) {
                      if (res3.error) {
                        alert(res3.error)
                        return;
                      }
                      alert('审核结果推送化工成功')
                    } else {
                      alert(res3.msg)
                    }
                  } catch (error) {
                    alert('审核结果推送失败,请联系管理员')
                  }
                })
              }
            }else if (res.msg) {
              alert(res.msg)
            } else if (!res) {
              //alert('操作失败');
            }
           // window.location.reload()
          })
          this.props.onSubmit({
            ...form,
            submit_name: text
          })
          //window.location.reload()
        }
        })
    } else if ( form.distribute === "浙商银行") {
      fetching(`/msapi/czb/czbFinancingStatus?id=${form.id}`).then(res => {
        if (res) {
          if (res == undefined && res.data.czbRzStatus === null && stateForm.form_no !== "2") {
            alert("浙商融资推送失败无法提交，请重新进行浙商融资推送！")
            return
           }else if ((res.data.czbRzStatus === null || res.data.czbRzStatus === null) && stateForm.form_no === "2"){
            alert("浙商融资推送失败无法进行退回操作，请切换至善美保理进行退回！")
            return
           }else {
            if (stateForm.form_no === "2") {
              const oid = form.id;
              var type = 0;//type--1:开立；2：流转；3：融资
              if (form.returnBy.indexOf('经办人') !== -1 || (form.returnBy.indexOf('上一级') !== -1 && auth.roles[0].id === 12)) {
               
                if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3' || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4') {  //开立： m2-4:融单开具复核; m2-5-3:已退回融单; r6-2-1:融单开立审核; r6-2-4:已退回融单
                  type = 1;
                } else if (exercise.table === 'm3-1-9' || exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' || exercise.table === 'r6-4-8') {//流转： m3-1-9:融单流转复核; m3-1-10:已退回流转; r6-4-1:融单流转审核; r6-4-8:已退回流转
                  
                  type = 2;
                } else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' || exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') {//融资: m3-2-2:融资复核; m3-2-8:已退回融资; r6-3-1:融资申请审核; r6-3-6:已退回融资
                  
                  type = 3;
                }
                fetching(`/invoiceVerification/fpyzwDelete?type=${type}&oid=${oid}`).then(res => {
                  if (res !== undefined && res.code === 200) {//invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
                    
                    alert(res.msg);
                  } else {
                    alert(`发票验真原有记录标记清除失败！`);
                  }
                })
              }
            }
            fetching(typePath, {
              method: 'POST',
              body: JSON.stringify(body)
            }).then(res => {
              if (res && exercise.table === 'r6-3-1' && rolesId === 3) {
                fetching(typePath2, {
                  method: 'POST',
                  body: JSON.stringify(fillingBody)
                }).then(res2 => {
                  if (res2.code !== 200) {
                    alert('合同填充失败')
                  }
                })
              } else if (res && form.flow_date && res.statusId === 5) {//流转待签收
                fetching('/dzqz/extsignAuto/LZ', {
                  method: 'POST',
                  body: JSON.stringify({
                    id: form.id
                  })
                }).then(res2 => {
                  try {
                    if (res2.code === 200) {
                      alert('已给开立人邮箱发送《应收账款转让通知(善美融单转让)》')
                    } else {
                      alert('发送《应收账款转让通知(善美融单转让)》失败')
                    }
                  } catch (error) {
                    alert('发送《应收账款转让通知(善美融单转让)》失败')
                  }
                })
                let body1 = {
                  c_id: form.id,
                  mx_number:form.mx_number,
                }
                fetching('/dzqz/filling', {
                  method: 'POST',
                  body: JSON.stringify(body1)
                }).then(res2 => {
                  try {
                    if (res2.code === 200) {
                      if (res2.error) {
                        alert(res2.error)
                        return;
                      }
                      alert('善美融单信息单填充成功')
                    } else {
                      alert(res.msg)
                    }
                  } catch (error) {
                    alert('《善美融单信息单》填充失败')
                  }
                })
                if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
                  let ocId =  form.id;
                  let type = 2;
                  let result = "400";
                  if (res.statusId === 5) {
                    result = "200";
                  } 
                  let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
                  fetching(url, {
                    method: 'GET',
                  }).then(res3 => {
                    try {
                      if (res3.code === 200) {
                        if (res3.error) {
                          alert(res3.error)
                          return;
                        }
                        alert('审核结果推送化工成功')
                      } else {
                        alert(res3.msg)
                      }
                    } catch (error) {
                      alert('审核结果推送失败,请联系管理员')
                    }
                  })
                }
              } else if (res && form.mxf_number && res.statusId === 11) {//融资放款 20210417
                fetching(typePath1, {
                  method: 'POST',
                  body: JSON.stringify({
                    id: form.id
                  })
                }).then(res2 => {
                  if (res2.code === 200) {
                    alert('已给开立人邮箱发送《应收账款转让通知（善美融单保理）》')
                  } else {
                    alert('发送邮件失败')
                  }
                })
                let body1 = {
                  c_id: form.ocId,
                  mx_number:form.mx_number,
                  mxf_number:form.mxf_number,
                }
                fetching(typePath3, {
                  method: 'POST',
                  body: JSON.stringify(body1)
                }).then(res2 => {
                  try {
                    if (res2.code === 200) {
                      if (res2.error) {
                        alert(res2.error)
                        return;
                      }
                      alert('善美融单回单填充成功')
                    } else {//alert(res.msg)
                      
                    }
                  } catch (error) {
                    alert('《善美融单回单》填充失败')
                  }
                })
                       
          
    
              } else if (res && (stateForm.form_no === '2' && (rolesId === 12 || rolesId === 13 || form.returnBy === '退回经办人'))) {
                fetching('/fdd/withdraw', {
                  method: 'POST',
                  body: JSON.stringify({
                    contract_id: form.fdd_file_name
                  })
                })
                if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
                  let type = 0;
                  if (form.mxc_number !== undefined && form.mxc_number !== null && form.mxc_number !== "") {
                    type = 2; 
                  } else {
                    type = 1; 
                  }
                  let ocId =  form.id;
                  let result =  "400";
                  let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
                  fetching(url, {
                    method: 'GET',
                  }).then(res3 => {
                    try {
                      if (res3.code === 200) {
                        if (res3.error) {
                          alert(res3.error)
                          return;
                        }
                        alert('审核结果推送化工成功')
                      } else {
                        alert(res3.msg)
                      }
                    } catch (error) {
                      alert('审核结果推送失败,请联系管理员')
                    }
                  })
                }
              } else if (res && res.issue_id && res.statusId === 5 && !res.mxc_number && !res.mxf_number) {//开立签收
                let body1 = { mx_number: form.mx_number }
                fetching('/dzqz/filling', {
                  method: 'POST',
                  body: JSON.stringify(body1)
                }).then(res2 => {
                  try {
                    if (res2.code === 200) {
                      if (res2.error) {
                        alert(res2.error)
                        return;
                      }
                      alert('善美融单信息单填充成功')
                    } else {
                      alert(res.msg)
                    }
                  } catch (error) {
                    alert('《善美融单信息单》填充失败')
                  }
    
                })      
                if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
                  let ocId =  form.id;
                  let type = 1;
                  let result =  400;
                  if (res.statusId === 5) {
                    result = 200;
                  } 
                  let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
                  fetching(url, {
                    method: 'GET',
                  }).then(res3 => {
                    try {
                      if (res3.code === 200) {
                        if (res3.error) {
                          alert(res3.error)
                          return;
                        }
                        alert('审核结果推送化工成功')
                      } else {
                        alert(res3.msg)
                      }
                    } catch (error) {
                      alert('审核结果推送失败,请联系管理员')
                    }
                  })
                }
              } else if (!res) {//alert('操作失败');
                
              }// window.location.reload()
             
            })
            this.props.onSubmit({
              ...form,
              submit_name: text
            }) //window.location.reload()
           
          }
        }
        
        })
    } else if ( form.distribute === "北金所") {
      fetching(`/msapi/bfe/getNonFactoringStatus?id=${form.id}`).then(res => {
        if (res) {
          if (res.data.factoring === null || res.data.factoring === undefined) {
            alert("北金所融资推送失败无法提交，请重新进行北金所融资推送！")
            return
           }else {
            if (stateForm.form_no === "2") {
              const oid = form.id;
              var type = 0;//type--1:开立；2：流转；3：融资
              if (form.returnBy.indexOf('经办人') !== -1 || (form.returnBy.indexOf('上一级') !== -1 && auth.roles[0].id === 12)) {
               
                if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3' || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4') {  //开立： m2-4:融单开具复核; m2-5-3:已退回融单; r6-2-1:融单开立审核; r6-2-4:已退回融单
                  type = 1;
                } else if (exercise.table === 'm3-1-9' || exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' || exercise.table === 'r6-4-8') {//流转： m3-1-9:融单流转复核; m3-1-10:已退回流转; r6-4-1:融单流转审核; r6-4-8:已退回流转
                  
                  type = 2;
                } else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' || exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') {//融资: m3-2-2:融资复核; m3-2-8:已退回融资; r6-3-1:融资申请审核; r6-3-6:已退回融资
                  
                  type = 3;
                }
                fetching(`/invoiceVerification/fpyzwDelete?type=${type}&oid=${oid}`).then(res => {
                  if (res !== undefined && res.code === 200) {//invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
                    
                    alert(res.msg);
                  } else {
                    alert(`发票验真原有记录标记清除失败！`);
                  }
                })
              }
            }
            fetching(typePath, {
              method: 'POST',
              body: JSON.stringify(body)
            }).then(res => {
              if (res && exercise.table === 'r6-3-1' && rolesId === 2) {
                fetching(typePath2, {
                  method: 'POST',
                  body: JSON.stringify(fillingBody)
                }).then(res2 => {
                  if (res2.code !== 200) {
                    alert('合同填充失败')
                  }
                })
              } else if (res && form.flow_date && res.statusId === 5) {//流转待签收
                fetching('/dzqz/extsignAuto/LZ', {
                  method: 'POST',
                  body: JSON.stringify({
                    id: form.id
                  })
                }).then(res2 => {
                  try {
                    if (res2.code === 200) {
                      alert('已给开立人邮箱发送《应收账款转让通知(善美融单转让)》')
                    } else {
                      alert('发送《应收账款转让通知(善美融单转让)》失败')
                    }
                  } catch (error) {
                    alert('发送《应收账款转让通知(善美融单转让)》失败')
                  }
                })
                let body1 = {
                  c_id: form.id,
                  mx_number:form.mx_number,
                }
                fetching('/dzqz/filling', {
                  method: 'POST',
                  body: JSON.stringify(body1)
                }).then(res2 => {
                  try {
                    if (res2.code === 200) {
                      if (res2.error) {
                        alert(res2.error)
                        return;
                      }
                      alert('善美融单信息单填充成功')
                    } else {
                      alert(res.msg)
                    }
                  } catch (error) {
                    alert('《善美融单信息单》填充失败')
                  }
                })
                if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
                  let ocId =  form.id;
                  let type = 2;
                  let result = "400";
                  if (res.statusId === 5) {
                    result = "200";
                  } 
                  let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
                  fetching(url, {
                    method: 'GET',
                  }).then(res3 => {
                    try {
                      if (res3.code === 200) {
                        if (res3.error) {
                          alert(res3.error)
                          return;
                        }
                        alert('审核结果推送化工成功')
                      } else {
                        alert(res3.msg)
                      }
                    } catch (error) {
                      alert('审核结果推送失败,请联系管理员')
                    }
                  })
                }
              } else if (res && form.mxf_number && res.statusId === 11) {//融资放款 20210417
                fetching(typePath1, {
                  method: 'POST',
                  body: JSON.stringify({
                    id: form.id
                  })
                }).then(res2 => {
                  if (res2.code === 200) {
                    alert('已给开立人邮箱发送《应收账款转让通知（善美融单保理）》')
                  } else {
                    alert('发送邮件失败')
                  }
                })
                let body1 = {
                  c_id: form.ocId,
                  mx_number:form.mx_number,
                  mxf_number:form.mxf_number,
                }
                fetching(typePath3, {
                  method: 'POST',
                  body: JSON.stringify(body1)
                }).then(res2 => {
                  try {
                    if (res2.code === 200) {
                      if (res2.error) {
                        alert(res2.error)
                        return;
                      }
                      alert('善美融单回单填充成功')
                    } else {//alert(res.msg)
                      
                    }
                  } catch (error) {
                    alert('《善美融单回单》填充失败')
                  }
                })
                       
          
    
              } else if (res && (stateForm.form_no === '2' && (rolesId === 12 || rolesId === 13 || form.returnBy === '退回经办人'))) {
                fetching('/fdd/withdraw', {
                  method: 'POST',
                  body: JSON.stringify({
                    contract_id: form.fdd_file_name
                  })
                })
                if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
                  let type = 0;
                  if (form.mxc_number !== undefined && form.mxc_number !== null && form.mxc_number !== "") {
                    type = 2; 
                  } else {
                    type = 1; 
                  }
                  let ocId =  form.id;
                  let result =  "400";
                  let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
                  fetching(url, {
                    method: 'GET',
                  }).then(res3 => {
                    try {
                      if (res3.code === 200) {
                        if (res3.error) {
                          alert(res3.error)
                          return;
                        }
                        alert('审核结果推送化工成功')
                      } else {
                        alert(res3.msg)
                      }
                    } catch (error) {
                      alert('审核结果推送失败,请联系管理员')
                    }
                  })
                }
              } else if (res && res.issue_id && res.statusId === 5 && !res.mxc_number && !res.mxf_number) {//开立签收
                let body1 = { mx_number: form.mx_number }
                fetching('/dzqz/filling', {
                  method: 'POST',
                  body: JSON.stringify(body1)
                }).then(res2 => {
                  try {
                    if (res2.code === 200) {
                      if (res2.error) {
                        alert(res2.error)
                        return;
                      }
                      alert('善美融单信息单填充成功')
                    } else {
                      alert(res.msg)
                    }
                  } catch (error) {
                    alert('《善美融单信息单》填充失败')
                  }
    
                })      
                if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
                  let ocId =  form.id;
                  let type = 1;
                  let result =  400;
                  if (res.statusId === 5) {
                    result = 200;
                  } 
                  let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
                  fetching(url, {
                    method: 'GET',
                  }).then(res3 => {
                    try {
                      if (res3.code === 200) {
                        if (res3.error) {
                          alert(res3.error)
                          return;
                        }
                        alert('审核结果推送化工成功')
                      } else {
                        alert(res3.msg)
                      }
                    } catch (error) {
                      alert('审核结果推送失败,请联系管理员')
                    }
                  })
                }
              } else if (!res) {//alert('操作失败');
                
              }// window.location.reload()
             
            })
            this.props.onSubmit({
              ...form,
              submit_name: text
            }) //window.location.reload()
           
          }
        }
        
        })
    }  else if (rolesId === 5 && exercise.table === 'r6-3-1' && exercise.paymentType === "0") {// 冻结拦截查询
      fetching('/loan/frozenBalance', {
        method: 'POST',
        body: JSON.stringify(body5)
      }).then(res5 => {
        
        if(res5.code===200){ //未冻结
          alert("该笔资金还未进行冻结,请进行冻结操作")
          return;
          //查找是否冻结成功,如果冻结成功,走向rdcw2 发送url
        } else if ( res5.code === 300 ) { //冻结成功
          //退回操作员时，清除原有发票验真的记录（将原有记录标记为已过期不可用状态）
      if (stateForm.form_no === "2") {
        const oid = form.id;
        //type--1:开立；2：流转；3：融资
        var type = 0;
        if (form.returnBy.indexOf('经办人') !== -1 || (form.returnBy.indexOf('上一级') !== -1 && auth.roles[0].id === 12)) {
          //开立： m2-4:融单开具复核; m2-5-3:已退回融单; r6-2-1:融单开立审核; r6-2-4:已退回融单
          if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3' || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4') {
            type = 1;
          } else if (exercise.table === 'm3-1-9' || exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' || exercise.table === 'r6-4-8') {
            //流转： m3-1-9:融单流转复核; m3-1-10:已退回流转; r6-4-1:融单流转审核; r6-4-8:已退回流转
            type = 2;
          } else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' || exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') {
            //融资: m3-2-2:融资复核; m3-2-8:已退回融资; r6-3-1:融资申请审核; r6-3-6:已退回融资
            type = 3;
          }
          fetching(`/invoiceVerification/fpyzwDelete?type=${type}&oid=${oid}`).then(res => {
            if (res !== undefined && res.code === 200) {
              //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
              alert(res.msg);
            } else {
              alert(`发票验真原有记录标记清除失败！`);
            }
          })
        }
      }
     
      fetching(typePath, {
        method: 'POST',
        body: JSON.stringify(body)
      }).then(res => {
        if (res && exercise.table === 'r6-3-1' && rolesId === 3) {
          
          fetching(typePath2, {
            method: 'POST',
            body: JSON.stringify(fillingBody)
          }).then(res2 => {
            if (res2.code !== 200) {
              alert('合同填充失败')
            }
          })
        } else if (res && form.flow_date && res.statusId === 5) {//流转待签收
          fetching('/dzqz/extsignAuto/LZ', {
            method: 'POST',
            body: JSON.stringify({
              id: form.id
            })
          }).then(res2 => {
            try {
              if (res2.code === 200) {
                alert('已给开立人邮箱发送《应收账款转让通知(善美融单转让)》')
              } else {
                alert('发送《应收账款转让通知(善美融单转让)》失败')
              }
            } catch (error) {
              alert('发送《应收账款转让通知(善美融单转让)》失败')
            }
          })
          let body1 = {
            c_id: form.id,
            mx_number:form.mx_number,
          }
          fetching('/dzqz/filling', {
            method: 'POST',
            body: JSON.stringify(body1)
          }).then(res2 => {
            try {
              if (res2.code === 200) {
                if (res2.error) {
                  alert(res2.error)
                  return;
                }
                alert('善美融单信息单填充成功')
              } else {
                alert(res.msg)
              }
            } catch (error) {
              alert('《善美融单信息单》填充失败')
            }
          })
          if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
            let ocId =  form.id;
            let type = 2;
            let result = "400";
            if (res.statusId === 5) {
              result = "200";
            } 
            let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
            fetching(url, {
              method: 'GET',
            }).then(res3 => {
              try {
                if (res3.code === 200) {
                  if (res3.error) {
                    alert(res3.error)
                    return;
                  }
                  alert('审核结果推送化工成功')
                } else {
                  alert(res3.msg)
                }
              } catch (error) {
                alert('审核结果推送失败,请联系管理员')
              }
            })
          }
        } else if (res && form.mxf_number && res.statusId === 11) {//融资放款 20210417
          fetching(typePath1, {
            method: 'POST',
            body: JSON.stringify({
              id: form.id
            })
          }).then(res2 => {
            if (res2.code === 200) {
              alert('已给开立人邮箱发送《应收账款转让通知（善美融单保理）》')
            } else {
              alert('发送邮件失败')
            }
          })
          let body1 = {
            c_id: form.ocId,
            mx_number:form.mx_number,
            mxf_number:form.mxf_number,
          }
          fetching(typePath3, {
            method: 'POST',
            body: JSON.stringify(body1)
          }).then(res2 => {
            try {
              if (res2.code === 200) {
                if (res2.error) {
                  alert(res2.error)
                  return;
                }
                alert('善美融单回单填充成功')
              } else {
                //alert(res.msg)
              }
            } catch (error) {
              alert('《善美融单回单》填充失败')
            }
          })
                 
    

        } else if (res && (stateForm.form_no === '2' && (rolesId === 12 || rolesId === 13 || form.returnBy === '退回经办人'))) {
          fetching('/fdd/withdraw', {
            method: 'POST',
            body: JSON.stringify({
              contract_id: form.fdd_file_name
            })
          })
          if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
            let type = 0;
            if (form.mxc_number !== undefined && form.mxc_number !== null && form.mxc_number !== "") {
              type = 2; 
            } else {
              type = 1; 
            }
            let ocId =  form.id;
            let result =  "400";
            let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
            fetching(url, {
              method: 'GET',
            }).then(res3 => {
              try {
                if (res3.code === 200) {
                  if (res3.error) {
                    alert(res3.error)
                    return;
                  }
                  alert('审核结果推送化工成功')
                } else {
                  alert(res3.msg)
                }
              } catch (error) {
                alert('审核结果推送失败,请联系管理员')
              }
            })
          }
        } else if (res && res.issue_id && res.statusId === 5 && !res.mxc_number && !res.mxf_number) {//开立签收
          let body1 = { mx_number: form.mx_number }
          fetching('/dzqz/filling', {
            method: 'POST',
            body: JSON.stringify(body1)
          }).then(res2 => {
            try {
              if (res2.code === 200) {
                if (res2.error) {
                  alert(res2.error)
                  return;
                }
                alert('善美融单信息单填充成功')
              } else {
                alert(res.msg)
              }
            } catch (error) {
              alert('《善美融单信息单》填充失败')
            }
          })
          if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
            let ocId =  form.id;
            let type = 1;
            let result = "400";
            if (res.statusId === 5) {
              result = "200";
            } 
            let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
            fetching(url, {
              method: 'GET',
            }).then(res3 => {
              try {
                if (res3.code === 200) {
                  if (res3.error) {
                    alert(res3.error)
                    return;
                  }
                  alert('审核结果推送化工成功')
                } else {
                  alert(res3.msg)
                }
              } catch (error) {
                alert('审核结果推送失败,请联系管理员')
              }
            })
          }
        } else if (!res) {
          //alert('操作失败');
        }
        //window.location.reload()
      })
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
      //window.location.reload()
      //alert("冻结成功") 
        } else {
          alert(res5.msg)
          return;//冻结失败原因提示,不走后面逻辑
          }
      })
    } else if (rolesId === 6 && exercise.table === 'r6-3-1' && exercise.paymentType === "0") { // 放款拦截查询
      fetching('/loan/thaw', { 
        method: 'POST',
        body: JSON.stringify(body2)
         }).then(res6 => {
             try {
               if (res6.code === 200) { //放款失败调用放款接口
                 if (res6.error) {
                   alert(res6.error)
                   return;
                 }
                alert('您还没有进行放款操作,请进行放款操作!')
               } else if (res6.code === 300) { //放款成功
                alert("放款成功")  
                //退回操作员时，清除原有发票验真的记录（将原有记录标记为已过期不可用状态）
      if (stateForm.form_no === "2") {
        const oid = form.id;
        //type--1:开立；2：流转；3：融资
        var type = 0;
        if (form.returnBy.indexOf('经办人') !== -1 || (form.returnBy.indexOf('上一级') !== -1 && auth.roles[0].id === 12)) {
          //开立： m2-4:融单开具复核; m2-5-3:已退回融单; r6-2-1:融单开立审核; r6-2-4:已退回融单
          if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3' || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4') {
            type = 1;
          } else if (exercise.table === 'm3-1-9' || exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' || exercise.table === 'r6-4-8') {
            //流转： m3-1-9:融单流转复核; m3-1-10:已退回流转; r6-4-1:融单流转审核; r6-4-8:已退回流转
            type = 2;
          } else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' || exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') {
            //融资: m3-2-2:融资复核; m3-2-8:已退回融资; r6-3-1:融资申请审核; r6-3-6:已退回融资
            type = 3;
          }
          fetching(`/invoiceVerification/fpyzwDelete?type=${type}&oid=${oid}`).then(res => {
            if (res !== undefined && res.code === 200) {
              //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
              alert(res.msg);
            } else {
              alert(`发票验真原有记录标记清除失败！`);
            }
          })
        }
      }
     
      fetching(typePath, {
        method: 'POST',
        body: JSON.stringify(body)
      }).then(res => {
        if (res && exercise.table === 'r6-3-1' && rolesId === 3) {
          fetching(typePath2, {
            method: 'POST',
            body: JSON.stringify(fillingBody)
          }).then(res2 => {
            if (res2.code !== 200) {
              alert('合同填充失败')
            }
          })
        } else if (res && form.flow_date && res.statusId === 5) {//流转待签收
          fetching('/dzqz/extsignAuto/LZ', {
            method: 'POST',
            body: JSON.stringify({
              id: form.id
            })
          }).then(res2 => {
            try {
              if (res2.code === 200) {
                alert('已给开立人邮箱发送《应收账款转让通知(善美融单转让)》')
              } else {
                alert('发送《应收账款转让通知(善美融单转让)》失败')
              }
            } catch (error) {
              alert('发送《应收账款转让通知(善美融单转让)》失败')
            }
          })
          let body1 = {
            c_id: form.id,
            mx_number:form.mx_number,
          }
          fetching('/dzqz/filling', {
            method: 'POST',
            body: JSON.stringify(body1)
          }).then(res2 => {
            try {
              if (res2.code === 200) {
                if (res2.error) {
                  alert(res2.error)
                  return;
                }
                alert('善美融单信息单填充成功')
              } else {
                alert(res.msg)
              }
            } catch (error) {
              alert('《善美融单信息单》填充失败')
            }
          })
          if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
            let ocId =  form.id;
            let type = 2;
            let result = "400";
            if (res.statusId === 5) {
              result = "200";
            } 
            let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
            fetching(url, {
              method: 'GET',
            }).then(res3 => {
              try {
                if (res3.code === 200) {
                  if (res3.error) {
                    alert(res3.error)
                    return;
                  }
                  alert('审核结果推送化工成功')
                } else {
                  alert(res3.msg)
                }
              } catch (error) {
                alert('审核结果推送失败,请联系管理员')
              }
            })
          }
        } else if (res && form.mxf_number && res.statusId === 11) {//融资放款 20210417
          try {
            fetching(typePath1, {
              method: 'POST',
              body: JSON.stringify({
                id: form.id
              })
            }).then(res2 => {
              if (res2.code === 200) {
                alert('已给开立人邮箱发送《应收账款转让通知（善美融单保理）》')
              } else {
                alert('发送邮件失败')
              }
            })
            let body1 = {
              c_id: form.ocId,
              mx_number:form.mx_number,
              mxf_number:form.mxf_number,
            }
            fetching(typePath3, {
              method: 'POST',
              body: JSON.stringify(body1)
            }).then(res2 => {
              try {
                if (res2.code === 200) {
                  if (res2.error) {
                    alert(res2.error)
                    return;
                  }
                  alert('善美融单回单填充成功')
                } else {
                  //alert(res.msg)
                }
              } catch (error) {
                alert('《善美融单回单》填充失败')
              }
            })
          } catch (error){

          }
          
                 
    

        } else if (res && (stateForm.form_no === '2' && (rolesId === 12 || rolesId === 13 || form.returnBy === '退回经办人'))) {
          fetching('/fdd/withdraw', {
            method: 'POST',
            body: JSON.stringify({
              contract_id: form.fdd_file_name
            })
          })
          if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
            let type = 0;
            if (form.mxc_number !== undefined && form.mxc_number !== null && form.mxc_number !== "") {
              type = 2; 
            } else {
              type = 1; 
            }
            let ocId =  form.id;
            let result =  "400";
            let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
            fetching(url, {
              method: 'GET',
            }).then(res3 => {
              try {
                if (res3.code === 200) {
                  if (res3.error) {
                    alert(res3.error)
                    return;
                  }
                  alert('审核结果推送化工成功')
                } else {
                  alert(res3.msg)
                }
              } catch (error) {
                alert('审核结果推送失败,请联系管理员')
              }
            })
          }
        } else if (res && res.issue_id && res.statusId === 5 && !res.mxc_number && !res.mxf_number) {//开立签收
          let body1 = { mx_number: form.mx_number }
          fetching('/dzqz/filling', {
            method: 'POST',
            body: JSON.stringify(body1)
          }).then(res2 => {
            try {
              if (res2.code === 200) {
                if (res2.error) {
                  alert(res2.error)
                  return;
                }
                alert('善美融单信息单填充成功')
              } else {
                alert(res.msg)
              }
            } catch (error) {
              alert('《善美融单信息单》填充失败')
            }
          })
          if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
            let ocId =  form.id;
            let type = 1;
            let result = "400";
            if (res.statusId === 5) {
              result = "200";
            } 
            let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
            fetching(url, {
              method: 'GET',
            }).then(res3 => {
              try {
                if (res3.code === 200) {
                  if (res3.error) {
                    alert(res3.error)
                    return;
                  }
                  alert('审核结果推送化工成功')
                } else {
                  alert(res3.msg)
                }
              } catch (error) {
                alert('审核结果推送失败,请联系管理员')
              }
            })
          }
        } else if (!res) {
         // alert('操作失败');
        }
        //window.location.reload()
      })
      this.props.onSubmit({
        ...form,
        submit_name: text
      })  
      //window.location.reload()                                                     
     } else {
                alert(res6.msg)
                return;
               }
            } catch (error) {
              alert('支付发起失败')
              }
        })
    } else if (rolesId === 12 && form.distribute === "光大银行") {
        let url = `/msapi/ceb/getCebRzStatus?mxf_number=${form.mxf_number}`
            fetching(url, {
              method: 'GET',
            }).then(res6 => {
               try {
                 if (res6.code === 200) { //放款失败调用放款接口
                   if (res6.error) {
                     alert(res6.error)
                     return;
                   }
                  alert('您还没有进行光大融资推送!')
                 } else if (res6.code === 300) { //放款成功
                  //alert("放款成功")  
                  //退回操作员时，清除原有发票验真的记录（将原有记录标记为已过期不可用状态）
        if (stateForm.form_no === "2") {
          const oid = form.id;
          //type--1:开立；2：流转；3：融资
          var type = 0;
          if (form.returnBy.indexOf('经办人') !== -1 || (form.returnBy.indexOf('上一级') !== -1 && auth.roles[0].id === 12)) {
            //开立： m2-4:融单开具复核; m2-5-3:已退回融单; r6-2-1:融单开立审核; r6-2-4:已退回融单
            if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3' || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4') {
              type = 1;
            } else if (exercise.table === 'm3-1-9' || exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' || exercise.table === 'r6-4-8') {
              //流转： m3-1-9:融单流转复核; m3-1-10:已退回流转; r6-4-1:融单流转审核; r6-4-8:已退回流转
              type = 2;
            } else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' || exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') {
              //融资: m3-2-2:融资复核; m3-2-8:已退回融资; r6-3-1:融资申请审核; r6-3-6:已退回融资
              type = 3;
            }
            fetching(`/invoiceVerification/fpyzwDelete?type=${type}&oid=${oid}`).then(res => {
              if (res !== undefined && res.code === 200) {
                //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
                alert(res.msg);
              } else {
                alert(`发票验真原有记录标记清除失败！`);
              }
            })
          }
        }
       
        fetching(typePath, {
          method: 'POST',
          body: JSON.stringify(body)
        }).then(res => {
          if (res && exercise.table === 'r6-3-1' && rolesId === 3) {
            fetching(typePath2, {
              method: 'POST',
              body: JSON.stringify(fillingBody)
            }).then(res2 => {
              if (res2.code !== 200) {
                alert('合同填充失败')
              }
            })
          } else if (res && form.flow_date && res.statusId === 5) {//流转待签收
            fetching('/dzqz/extsignAuto/LZ', {
              method: 'POST',
              body: JSON.stringify({
                id: form.id
              })
            }).then(res2 => {
              try {
                if (res2.code === 200) {
                  alert('已给开立人邮箱发送《应收账款转让通知(善美融单转让)》')
                } else {
                  alert('发送《应收账款转让通知(善美融单转让)》失败')
                }
              } catch (error) {
                alert('发送《应收账款转让通知(善美融单转让)》失败')
              }
            })
            let body1 = {
              c_id: form.id,
              mx_number:form.mx_number,
            }
            fetching('/dzqz/filling', {
              method: 'POST',
              body: JSON.stringify(body1)
            }).then(res2 => {
              try {
                if (res2.code === 200) {
                  if (res2.error) {
                    alert(res2.error)
                    return;
                  }
                  alert('善美融单信息单填充成功')
                } else {
                  alert(res.msg)
                }
              } catch (error) {
                alert('《善美融单信息单》填充失败')
              }
            })
            if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
              let ocId =  form.id;
              let type = 2;
              let result = "400";
              if (res.statusId === 5) {
                result = "200";
              } 
              let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
              fetching(url, {
                method: 'GET',
              }).then(res3 => {
                try {
                  if (res3.code === 200) {
                    if (res3.error) {
                      alert(res3.error)
                      return;
                    }
                    alert('审核结果推送化工成功')
                  } else {
                    alert(res3.msg)
                  }
                } catch (error) {
                  alert('审核结果推送失败,请联系管理员')
                }
              })
            }
          } else if (res && form.mxf_number && res.statusId === 11) {//融资放款 20210417
            try {
              fetching(typePath1, {
                method: 'POST',
                body: JSON.stringify({
                  id: form.id
                })
              }).then(res2 => {
                if (res2.code === 200) {
                  alert('已给开立人邮箱发送《应收账款转让通知（善美融单保理）》')
                } else {
                  alert('发送邮件失败')
                }
              })
              let body1 = {
                c_id: form.ocId,
                mx_number:form.mx_number,
                mxf_number:form.mxf_number,
              }
              fetching(typePath3, {
                method: 'POST',
                body: JSON.stringify(body1)
              }).then(res2 => {
                try {
                  if (res2.code === 200) {
                    if (res2.error) {
                      alert(res2.error)
                      return;
                    }
                    alert('善美融单回单填充成功')
                  } else {
                    //alert(res.msg)
                  }
                } catch (error) {
                  alert('《善美融单回单》填充失败')
                }
              })
            } catch (error){
  
            }
            
                   
      
  
          } else if (res && (stateForm.form_no === '2' && (rolesId === 12 || rolesId === 13 || form.returnBy === '退回经办人'))) {
            fetching('/fdd/withdraw', {
              method: 'POST',
              body: JSON.stringify({
                contract_id: form.fdd_file_name
              })
            })
            if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
              let type = 0;
              if (form.mxc_number !== undefined && form.mxc_number !== null && form.mxc_number !== "") {
                type = 2; 
              } else {
                type = 1; 
              }
              let ocId =  form.id;
              let result =  "400";
              let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
              fetching(url, {
                method: 'GET',
              }).then(res3 => {
                try {
                  if (res3.code === 200) {
                    if (res3.error) {
                      alert(res3.error)
                      return;
                    }
                    alert('审核结果推送化工成功')
                  } else {
                    alert(res3.msg)
                  }
                } catch (error) {
                  alert('审核结果推送失败,请联系管理员')
                }
              })
            }
          } else if (res && res.issue_id && res.statusId === 5 && !res.mxc_number && !res.mxf_number) {//开立签收
            let body1 = { mx_number: form.mx_number }
            fetching('/dzqz/filling', {
              method: 'POST',
              body: JSON.stringify(body1)
            }).then(res2 => {
              try {
                if (res2.code === 200) {
                  if (res2.error) {
                    alert(res2.error)
                    return;
                  }
                  alert('善美融单信息单填充成功')
                } else {
                  alert(res.msg)
                }
              } catch (error) {
                alert('《善美融单信息单》填充失败')
              }
            })
            if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
              let ocId =  form.id;
              let type = 1;
              let result = "400";
              if (res.statusId === 5) {
                result = "200";
              } 
              let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
              fetching(url, {
                method: 'GET',
              }).then(res3 => {
                try {
                  if (res3.code === 200) {
                    if (res3.error) {
                      alert(res3.error)
                      return;
                    }
                    alert('审核结果推送化工成功')
                  } else {
                    alert(res3.msg)
                  }
                } catch (error) {
                  alert('审核结果推送失败,请联系管理员')
                }
              })
            }
          } else if (!res) {
           // alert('操作失败');
          }
          //window.location.reload()
        })
        this.props.onSubmit({
          ...form,
          submit_name: text
        })  
        //window.location.reload()                                                     
        } else {
                  alert(res6.msg)
                  return;
                 }
              } catch (error) {
                alert('支付发起失败')
                }
          })
    } else if ( form.distribute === "北京银行") {
      fetching(`/msapi/bob/bobFinancingStatus?id=${form.id}`).then(resbob => {
        if (resbob) {
          if (resbob === undefined && resbob.data === null ) {
            alert("北京银行融资推送失败无法提交，请重新进行融资推送！")
            return
           } else {
            if (stateForm.form_no === "2") {
              const oid = form.id;
              var type = 0;//type--1:开立；2：流转；3：融资
              if (form.returnBy.indexOf('经办人') !== -1 || (form.returnBy.indexOf('上一级') !== -1 && auth.roles[0].id === 12)) {
               
                if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3' || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4') {  //开立： m2-4:融单开具复核; m2-5-3:已退回融单; r6-2-1:融单开立审核; r6-2-4:已退回融单
                  type = 1;
                } else if (exercise.table === 'm3-1-9' || exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' || exercise.table === 'r6-4-8') {//流转： m3-1-9:融单流转复核; m3-1-10:已退回流转; r6-4-1:融单流转审核; r6-4-8:已退回流转
                  
                  type = 2;
                } else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' || exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') {//融资: m3-2-2:融资复核; m3-2-8:已退回融资; r6-3-1:融资申请审核; r6-3-6:已退回融资
                  
                  type = 3;
                }
                fetching(`/invoiceVerification/fpyzwDelete?type=${type}&oid=${oid}`).then(res => {
                  if (res !== undefined && res.code === 200) {//invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
                    
                    alert(res.msg);
                  } else {
                    alert(`发票验真原有记录标记清除失败！`);
                  }
                })
              }
            }
            fetching(typePath, {
              method: 'POST',
              body: JSON.stringify(body)
            }).then(res => {
              if (res && exercise.table === 'r6-3-1' && rolesId === 3) {
                fetching(typePath2, {
                  method: 'POST',
                  body: JSON.stringify(fillingBody)
                }).then(res2 => {
                  if (res2.code !== 200) {
                    alert('合同填充失败')
                  }
                })
              } else if (res && exercise.table === 'r6-3-1' && rolesId === 16) {
                if (res.msg) {
                  alert(res.msg)
                  return;
                }
              }else if (res && form.flow_date && res.statusId === 5) {//流转待签收
                fetching('/dzqz/extsignAuto/LZ', {
                  method: 'POST',
                  body: JSON.stringify({
                    id: form.id
                  })
                }).then(res2 => {
                  try {
                    if (res2.code === 200) {
                      alert('已给开立人邮箱发送《应收账款转让通知(善美融单转让)》')
                    } else {
                      alert('发送《应收账款转让通知(善美融单转让)》失败')
                    }
                  } catch (error) {
                    alert('发送《应收账款转让通知(善美融单转让)》失败')
                  }
                })
                let body1 = {
                  c_id: form.id,
                  mx_number:form.mx_number,
                }
                fetching('/dzqz/filling', {
                  method: 'POST',
                  body: JSON.stringify(body1)
                }).then(res2 => {
                  try {
                    if (res2.code === 200) {
                      if (res2.error) {
                        alert(res2.error)
                        return;
                      }
                      alert('善美融单信息单填充成功')
                    } else {
                      alert(res.msg)
                    }
                  } catch (error) {
                    alert('《善美融单信息单》填充失败')
                  }
                })
                if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
                  let ocId =  form.id;
                  let type = 2;
                  let result = "400";
                  if (res.statusId === 5) {
                    result = "200";
                  } 
                  let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
                  fetching(url, {
                    method: 'GET',
                  }).then(res3 => {
                    try {
                      if (res3.code === 200) {
                        if (res3.error) {
                          alert(res3.error)
                          return;
                        }
                        alert('审核结果推送化工成功')
                      } else {
                        alert(res3.msg)
                      }
                    } catch (error) {
                      alert('审核结果推送失败,请联系管理员')
                    }
                  })
                }
              } else if (res && form.mxf_number && res.statusId === 11) {//融资放款 20210417
                fetching(typePath1, {
                  method: 'POST',
                  body: JSON.stringify({
                    id: form.id
                  })
                }).then(res2 => {
                  if (res2.code === 200) {
                    alert('已给开立人邮箱发送《应收账款转让通知（善美融单保理）》')
                  } else {
                    alert('发送邮件失败')
                  }
                })
                let body1 = {
                  c_id: form.ocId,
                  mx_number:form.mx_number,
                  mxf_number:form.mxf_number,
                }
                fetching(typePath3, {
                  method: 'POST',
                  body: JSON.stringify(body1)
                }).then(res2 => {
                  try {
                    if (res2.code === 200) {
                      if (res2.error) {
                        alert(res2.error)
                        return;
                      }
                      alert('善美融单回单填充成功')
                    } else {//alert(res.msg)
                      
                    }
                  } catch (error) {
                    alert('《善美融单回单》填充失败')
                  }
                })
                       
          
    
              } else if (res && (stateForm.form_no === '2' && (rolesId === 12 || rolesId === 13 || form.returnBy === '退回经办人'))) {
                fetching('/fdd/withdraw', {
                  method: 'POST',
                  body: JSON.stringify({
                    contract_id: form.fdd_file_name
                  })
                })
                if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
                  let type = 0;
                  if (form.mxc_number !== undefined && form.mxc_number !== null && form.mxc_number !== "") {
                    type = 2; 
                  } else {
                    type = 1; 
                  }
                  let ocId =  form.id;
                  let result =  "400";
                  let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
                  fetching(url, {
                    method: 'GET',
                  }).then(res3 => {
                    try {
                      if (res3.code === 200) {
                        if (res3.error) {
                          alert(res3.error)
                          return;
                        }
                        alert('审核结果推送化工成功')
                      } else {
                        alert(res3.msg)
                      }
                    } catch (error) {
                      alert('审核结果推送失败,请联系管理员')
                    }
                  })
                }
              } else if (res && res.issue_id && res.statusId === 5 && !res.mxc_number && !res.mxf_number) {//开立签收
                let body1 = { mx_number: form.mx_number }
                fetching('/dzqz/filling', {
                  method: 'POST',
                  body: JSON.stringify(body1)
                }).then(res2 => {
                  try {
                    if (res2.code === 200) {
                      if (res2.error) {
                        alert(res2.error)
                        return;
                      }
                      alert('善美融单信息单填充成功')
                    } else {
                      alert(res.msg)
                    }
                  } catch (error) {
                    alert('《善美融单信息单》填充失败')
                  }
    
                })      
                if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
                  let ocId =  form.id;
                  let type = 1;
                  let result =  400;
                  if (res.statusId === 5) {
                    result = 200;
                  } 
                  let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
                  fetching(url, {
                    method: 'GET',
                  }).then(res3 => {
                    try {
                      if (res3.code === 200) {
                        if (res3.error) {
                          alert(res3.error)
                          return;
                        }
                        alert('审核结果推送化工成功')
                      } else {
                        alert(res3.msg)
                      }
                    } catch (error) {
                      alert('审核结果推送失败,请联系管理员')
                    }
                  })
                }
              } else if (!res) {//alert('操作失败');
                
              }// window.location.reload()
             
            })
            this.props.onSubmit({
              ...form,
              submit_name: text
            }) //window.location.reload()
           
          }
        }
        
        })
    } else {
        //退回操作员时，清除原有发票验真的记录（将原有记录标记为已过期不可用状态）
        if (stateForm.form_no === "2") {
          const oid = form.id;
          //type--1:开立；2：流转；3：融资
          var type = 0;
          if (form.returnBy.indexOf('经办人') !== -1 || (form.returnBy.indexOf('上一级') !== -1 && auth.roles[0].id === 12)) {
            //开立： m2-4:融单开具复核; m2-5-3:已退回融单; r6-2-1:融单开立审核; r6-2-4:已退回融单
            if (exercise.table === 'm2-4' || exercise.table === 'm2-5-3' || exercise.table === 'r6-2-1' || exercise.table === 'r6-2-4') {
              type = 1;
            } else if (exercise.table === 'm3-1-9' || exercise.table === 'm3-1-10' || exercise.table === 'r6-4-1' || exercise.table === 'r6-4-8') {
              //流转： m3-1-9:融单流转复核; m3-1-10:已退回流转; r6-4-1:融单流转审核; r6-4-8:已退回流转
              type = 2;
            } else if (exercise.table === 'm3-2-2' || exercise.table === 'm3-2-8' || exercise.table === 'r6-3-1' || exercise.table === 'r6-3-6') {
              //融资: m3-2-2:融资复核; m3-2-8:已退回融资; r6-3-1:融资申请审核; r6-3-6:已退回融资
              type = 3;
            }
            fetching(`/invoiceVerification/fpyzwDelete?type=${type}&oid=${oid}`).then(res => {
              if (res !== undefined && res.code === 200) {
                //invoice_all_list;//发票验真结果列表; invoice_no_list;//发票验真不合格列表
                //alert(res.msg);
              } else {
                alert(`发票验真原有记录标记清除失败！`);
              }
            })
          }
        }
        
        fetching(typePath, {
          method: 'POST',
          body: JSON.stringify(body)
        }).then(res => {
          try{
          if (res && exercise.table === 'r6-3-1' && rolesId === 3) {
            fetching(typePath2, {
              method: 'POST',
              body: JSON.stringify(fillingBody)
            }).then(res2 => {
              if (res2.code !== 200) {
                alert('合同填充失败')
              }
            })
          } else if (res.msg) {
              alert(res.msg)
              return;
          } else if (res && form.flow_date && res.statusId === 5) {//流转待签收
            fetching('/dzqz/extsignAuto/LZ', {
              method: 'POST',
              body: JSON.stringify({
                id: form.id
              })
            }).then(res2 => {
              try {
                if (res2.code === 200) {
                  alert('已给开立人邮箱发送《应收账款转让通知(善美融单转让)》')
                } else {
                  alert('发送《应收账款转让通知(善美融单转让)》失败')
                }
              } catch (error) {
                alert('发送《应收账款转让通知(善美融单转让)》失败')
              }
            })
            let body1 = {
              c_id: form.id,
              mx_number:form.mx_number,
            }
            fetching('/dzqz/filling', {
              method: 'POST',
              body: JSON.stringify(body1)
            }).then(res2 => {
              try {
                if (res2.code === 200) {
                  if (res2.error) {
                    alert(res2.error)
                    return;
                  }
                  alert('善美融单信息单填充成功')
                } else {
                  alert(res.msg)
                }
              } catch (error) {
                alert('《善美融单信息单》填充失败')
              }
            })
            if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
              let ocId =  form.id;
              let type = 2;
              let result = "400";
              if (res.statusId === 5) {
                result = "200";
              } 
              let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
              fetching(url, {
                method: 'GET',
              }).then(res3 => {
                try {
                  if (res3.code === 200) {
                    if (res3.error) {
                      alert(res3.error)
                      return;
                    }
                    alert('审核结果推送化工成功')
                  } else {
                    alert(res3.msg)
                  }
                } catch (error) {
                  alert('审核结果推送失败,请联系管理员')
                }
              })
            }
              
          } else if (res && form.mxf_number && res.statusId === 11) {//融资放款 20210417
            fetching(typePath1, {
              method: 'POST',
              body: JSON.stringify({
                id: form.id
              })
            }).then(res2 => {
              if (res2.code === 200) {
                alert('已给开立人邮箱发送《应收账款转让通知（善美融单保理）》')
              } else {
                alert('发送邮件失败')
              }
            })
            let body1 = {
              c_id: form.ocId,
              mx_number:form.mx_number,
              mxf_number:form.mxf_number,
            }
            fetching(typePath3, {
              method: 'POST',
              body: JSON.stringify(body1)
            }).then(res2 => {
              try {
                if (res2.code === 200) {
                  if (res2.error) {
                    alert(res2.error)
                    return;
                  }
                  alert('善美融单回单填充成功')
                } else {
                  //alert(res.msg)
                }
              } catch (error) {
                alert('《善美融单回单》填充失败')
              }
            })

          } else if (res && (stateForm.form_no === '2' && (rolesId === 12 || rolesId === 13 || form.returnBy === '退回经办人'))) {
            fetching('/fdd/withdraw', {
              method: 'POST',
              body: JSON.stringify({
                contract_id: form.fdd_file_name
              })
            })
            if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
              let type = 0;
              if (form.mxc_number !== undefined && form.mxc_number !== null && form.mxc_number !== "") {
                type = 2; 
              } else {
                type = 1; 
              }
              let ocId =  form.id;
              let result =  "400";
              let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
              fetching(url, {
                method: 'GET',
              }).then(res3 => {
                try {
                  if (res3.code === 200) {
                    if (res3.error) {
                      alert(res3.error)
                      return;
                    }
                    alert('审核结果推送化工成功')
                  } else {
                    alert(res3.msg)
                  }
                } catch (error) {
                  alert('审核结果推送失败,请联系管理员')
                }
              })
            }
            this.props.onSubmit({
              ...form,
              submit_name: text
            })
          } else if (res && res.issue_id && res.statusId === 5 && !res.mxc_number && !res.mxf_number) {//开立签收
            let body1 = { mx_number: form.mx_number }
            fetching('/dzqz/filling', {
              method: 'POST',
              body: JSON.stringify(body1)
            }).then(res2 => {
              try {
                if (res2.code === 200) {
                  if (res2.error) {
                    alert(res2.error)
                    return;
                  }
                  alert('善美融单信息单填充成功')
                } else {
                  alert(res.msg)
                }
              } catch (error) {
                alert('《善美融单信息单》填充失败')
              }

            })
            if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
              let ocId =  form.id;
              let type = 1;
              let result =  400;
              if (res.statusId === 5) {
                result = 200;
              } 
              let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
              fetching(url, {
                method: 'GET',
              //body: JSON.stringify(body1)
              }).then(res3 => {
                try {
                  if (res3.code === 200) {
                    if (res3.error) {
                      alert(res3.error)
                      return;
                    }
                    alert('审核结果推送化工成功')
                  } else {
                    alert(res3.msg)
                  }
                } catch (error) {
                  alert('审核结果推送失败,请联系管理员')
                }
              })
            }
          } else if (!res) {
            alert('操作失败');
          } else if (res && res.statusId === 6 && !res.mxf_number) {
            if (form.pickupUrl !== undefined && form.pickupUrl !== null && form.pickupUrl !== "") {
              let type = 0;
              if (form.mxc_number !== undefined && form.mxc_number !== null && form.mxc_number !== "") {
                type = 2; 
              } else {
                type = 1; 
              }
              let ocId =  form.id;
              let result =  "400";
              let url = `/ct/rongdanAuditing?ocId=${ocId}&type=${type}&result=${result}`
              fetching(url, {
                method: 'GET',
              }).then(res3 => {
                try {
                  if (res3.code === 200) {
                    if (res3.error) {
                      alert(res3.error)
                      return;
                    }
                    alert('审核结果推送化工成功')
                  } else {
                    alert(res3.msg)
                  }
                } catch (error) {
                  alert('审核结果推送失败,请联系管理员')
                }
              })
            }
            alert('拒签成功');
            this.props.onSubmit({
              ...form,
              submit_name: text
            })
            //window.location.reload()
          } 
        } catch (error) {
          alert("错误:"+ error)
       }
         // window.location.reload()
     })
        this.props.onSubmit({
          ...form,
          submit_name: text
        })
      
      }


    } else {
      this.props.onSubmit({
        ...form,
        submit_name: text
      })
      window.location.reload()
    }
  }
  handleChange2 = ({ target: { name, value } }) => {
    const { form } = this.state
    let isverification_code = form.isverification_code
    if (value === '6') {
      isverification_code = true
    }
    this.setState({
      form: { ...form, [name]: value, isverification_code }
    })
  }

  handleChange3 = ({ target: { name, value } }) => {
    const { form } = this.state
    this.setState({
      form: { ...form, [name]: value}
    })
    return value
  }
  render() {
    const { form2, form, imgUrl, userList, second, secondNumber, signatureMsg ,distributeList} = this.state;
    const { exercise , classes, auth } = this.props;
    let rolesId = auth.roles ? auth.roles[0].id : 0;
    let paymentType = exercise.paymentType ? exercise.paymentType : "0";
    const isSign_agreement = () => {
    if (
        (form.form_no === '3' || form.form_no === '5') &&
        (exercise.table === 'm2-4' || exercise.table === 'm3-1-1') && exercise.roleId !== 14 && exercise.roleId !== 21 && exercise.roleId !== 22 ) {
        if (exercise.flow_date) {//流转日期--代表此单是流转单
          return false
        } else return true
      }
      else if ((exercise.table === 'r6-3-1' || exercise.table === 'r6-2-1') && rolesId === 9) {
        return true
      }
      else if (exercise.table === 'm3-2-2' && exercise.StatusId === 5 ) {
        return true
      } 
      else return false;
    }
    // const checkOption = () => {
    //   if (form.form_no === '3' || form.form_no === '5') {
    //     if (exercise.mxf_number) return true;
    //     return false;
    //   } else return true;
    // }
    const check_opinion_label = () => {
      if (form.form_no === '3' && rolesId !== 5) return form2.check_opinion
      else if (form.form_no === '2') return form2.return_reason
      else return form2.remarks

    }

    const distribute = () => {
      if (form.form_no === '3' && rolesId !== 5) return form2.check_opinion
      else if (form.form_no === '2') return form2.return_reason
      else return form2.remarks

    }

    //融单流转树形图--20200930
    const renderTree = (nodes) => (
      <TreeItem key={nodes.id} nodeId={nodes.id} 
      collapseIcon={ (nodes.flagTree === 1 ? <LocationOn/> : ( nodes.type === 1 ? <AccountTree/> : (nodes.type === 2 ? <Attachment/> : <MonetizationOn/> ) ) )} 
      expandIcon={<ExpandMoreIcon />} 
      endIcon={ (nodes.flagTree === 1 ? <LocationOn/> : ( nodes.type === 1 ? <AccountTree/> : (nodes.type === 2 ? <Attachment/> : <MonetizationOn/> ) ) )} 
      label={ (nodes.type === 1 ? nodes.mx_number : nodes.mxt_number) + ":" + nodes.extraction +"-->"+ (nodes.type === 3 ? "陕西善美商业保理有限公司": nodes.receive )+ ":" + nodes.amount }  
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    );

    const status = (handleSubmit, values) => {
      if (form.form_no === '3') {

        return <Grid container justify="center" style={{ padding: '20px 0' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      } else if (form.form_no === '2') {
        return <Grid container justify="center" style={{ padding: '20px 0' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      } else if (form.form_no === '5') {
        return <Grid container justify="center" style={{ padding: '20px 0' }}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      } else if (form.form_no === '6') {
        return <Grid container justify="center" style={{ padding: '20px 0' }}>

          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={false}
            name={form2.submit}
            style={{ marginRight: '10px' }}
          >
            {form2.submit}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSubmit.bind(this, values, form2.cancel)}
            name={form2.cancel}
            disabled={false}
            style={{ marginRight: '10px' }}
          >
            {form2.cancel}
          </Button>
        </Grid>
      }
    }
    //签订协议类型 exercise.table
    // const agreementTple = () => {}
    return (
      <Formik
        //初始化数据
        initialValues={{
          ...form
        }}
        //表单验证
        validationSchema={object().shape(issueFormik(form2, form, rolesId))}
        //验证提交
        onSubmit={(values) => {
          this.handleSubmit(values, form2.check);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
            <form method="post" encType="multipart/form-data">

{((form.form_no === '3' &&  rolesId === 5) || (form.form_no === '3' &&  rolesId === 6)) &&
                  <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.mxf_number}:</Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.mxf_number}
                  </Typography>
                </Grid>}
                {((form.form_no === '3' &&  rolesId === 5) || (form.form_no === '3' &&  rolesId === 6)) &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.applicant}:</Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.applicant}
                  </Typography>
                </Grid>}
                {((form.form_no === '3' &&  rolesId === 5) || (form.form_no === '3' &&  rolesId === 6)) &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.net_financing_amount}:
                        </Typography>
                  {(form.payment_type !== '还款日不固定')  && <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.net_financing_amount}元</span>
                  </Typography>}
                  {(form.payment_type === '还款日不固定')  && <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.amount}元</span>
                  </Typography>}
                </Grid> }
                
                {(form.form_no === '3' &&  rolesId === 5 && exercise.paymentType === "0")  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.frozenAmount}:
                        </Typography>
                  {(form.payment_type !== '还款日不固定')  && <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.net_financing_amount}元</span>
                  </Typography>}
                  {(form.payment_type === '还款日不固定')  && <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.amount}元</span>
                  </Typography>}
                </Grid>}

                {(form.form_no === '3' &&  rolesId === 6)  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.paymentAmount}:
                        </Typography>
                  {(form.payment_type !== '还款日不固定')  && <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.net_financing_amount}元</span>
                  </Typography>}
                  {(form.payment_type === '还款日不固定')  && <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    <span onClick={event => { this.handleClick('right-start', event) }} style={{ cursor: 'pointer', color: '#2196f3', paddingRight: '10px' }}> {values.amount}元</span>
                  </Typography>}
                </Grid>}
                {(form.form_no === '3' &&  rolesId === 5 && exercise.paymentType === "0")  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.frozen_ways}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.repayment_ways}
                  </Typography>
                </Grid>}
                {(form.form_no === '3' &&  rolesId === 6 && exercise.paymentType === "0")  &&
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="body2" gutterBottom display='inline' style={styles} >
                    {form2.payment_ways}:
                        </Typography>
                  <Typography variant="body2" gutterBottom display='inline' style={styles}>
                    {values.repayment_ways}
                  </Typography>
                </Grid>}
                
              { form.form_no !== '8' && rolesId !== 16 &&
                <SimpleExpansionPanel title="历史审核意见">
                  {
                    form.opinion_list.map((item, index) => {
                      return (
                        <div style={{ margin: '10px 0' }} key={index}>
                          <Typography component="p">
                            {item.name}
                          </Typography>
                          <Typography component="p">
                            {item.check_opinion}
                          </Typography>
                        </div>
                      )
                    })

                  }
                </SimpleExpansionPanel>
              }
              <FormControl component="fieldset">

              </FormControl>
              { form.form_no !== '8' && rolesId !== 9 && rolesId !== 16 && <Grid item xs={12}>
                <TextField
                  multiline
                  rows='4'
                  label={check_opinion_label()}
                  value={values.check_opinion}
                  name='check_opinion'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin='normal'
                  fullWidth
                  disabled={false}
                />
                <FormikError touched={touched} errors={errors} name={'check_opinion'} />
              </Grid>}

              {
                 rolesId === 16 &&
                <FormControl style={{ width: '100%', margin: '10px 0' }}>
                  <InputLabel id="demo-simple-select-label">{form2.distribute}</InputLabel>
                  
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="distribute"
                    value={values.distribute}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('distribute', this.handleChange3(e))}
                  >
                   {
                      distributeList.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              }


              {(form.form_no === '8') &&
                <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
                >
                {renderTree(form.RDTree)}
              </TreeView>}

              {(form.form_no === '3' || form.form_no === '2')  && rolesId !== 16 &&
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="gender" name="form_no" value={form.form_no} onChange={this.handleChange2}>
                  <FormControlLabel value="3" control={<Radio />} label="同意" />

                  <FormControlLabel
                    disabled={rolesId === 9 || rolesId === 5 || rolesId === 6 || exercise.StatusId === 5}
                    value="2" control={<Radio />} label="退回" />
                </RadioGroup>}

                {(form.form_no === '3' || form.form_no === '2') && rolesId === 16 &&
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="gender" name="form_no" value={form.form_no} onChange={this.handleChange2}>
                  <FormControlLabel value="3" control={<Radio />} label="分配" />

                  <FormControlLabel
                    disabled={rolesId === 9 || rolesId === 5 || rolesId === 6 || exercise.StatusId === 5}
                    value="2" control={<Radio />} label="退回" />
                </RadioGroup>}
              {(form.form_no === '5' || form.form_no === '6') &&
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="gender" name="form_no" value={form.form_no} onChange={this.handleChange2}>
                  <FormControlLabel value="5" control={<Radio />} label="签收" />
                  <FormControlLabel disabled={exercise.roleId === 14 || form.distribute === '北京银行'} value="6" control={<Radio />} label="拒签收" />
                </RadioGroup>}
              {
                isSign_agreement() &&
                <div>
                  <Grid container>

                    {rolesId !== 9 && form.distribute !== '光大银行' && form.distribute !== '北京银行' && <Grid item xs={12}>
                      <Grid container alignItems="flex-end">
                        <TextField
                          label={form2.verification_code}
                          value={form.verification_code}
                          name='verification_code'
                          onChange={this.handleChange2}
                          margin='normal'
                          style={{ width: '167px' }}
                          disabled={false}
                        />
                        {second === 60 ?
                          <Button onClick={this.onVerificationCode} style={{ margin: ' 0  0 8px 10px' }}
                            variant='contained'>{form2.verification_code2}
                          </Button> :
                          <Button style={{ margin: ' 0  0 8px 10px' }}
                            variant='contained'>{second + form2.verification_code3}
                          </Button>}
                      </Grid>
                      {secondNumber !== '' && <div style={{ color: ' rgba(0, 0, 0, 0.42)', fontSize: '12px' }}>
                        {'验证码也发送到你的手机 ' + form.phone}
                      </div>}
                      <FormikError touched={touched} errors={errors} name={'verification_code'} />
                    </Grid>}
                    <div style={{ margin: '20px 0 10px 0' }}>
                      <Button
                        onClick={this.sign_agreements}
                        color="primary">{form2.sign_agreement}</Button>

                      <Button onClick={this.viewAgreement} style={{ marginLeft: '10px' }}
                        variant='contained' >{form2.view_agreement}</Button>
                    </div>

                  </Grid>
                  <div style={{ marginLeft: '10px' }}>{signatureMsg}</div>

                </div>
              }
              {
                (form.form_no === '3' &&  rolesId === 5) &&
                <Button onClick={this.OfflinePayment} style={{ margin: '10px 0' }}
                  variant='contained' >{'线下/线上切换支付流程'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 5 && paymentType === "0") &&
                <Button onClick={this.frozenBalance} style={{ margin: '10px 0' }}
                  variant='contained' >{'放款金额冻结'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 6 && paymentType === "0") &&
                <Button onClick={this.releasePaymentModel} style={{ margin: '10px 0' }}
                  variant='contained' >{'放款支付'}</Button>   
              }
              {
                (form.form_no === '3' &&  rolesId === 16 && form.distribute === '招商银行') &&
                <Button onClick={this.cmbRzVerify} style={{ margin: '10px 0' }}
                  variant='contained' >{'招行融资推送'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 16 && form.distribute === '浙商银行') &&
                <Button onClick={this.czbRzVerify} style={{ margin: '10px 0' }}
                  variant='contained' >{'浙商银行融资推送'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 16 && form.distribute === '北金所') &&
                <Button onClick={this.bfeRzVerify} style={{ margin: '10px 0' }}
                  variant='contained' >{'北金所融资推送'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 16 && form.distribute === '中信银行') &&
                <Button onClick={this.ccbGetAmount} style={{ margin: '10px 0' }}
                  variant='contained' >{'中信银行额度查询'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 16 && form.distribute === '中信银行') &&
                <Button onClick={this.CCBCustomerFiling} style={{ margin: '10px 0' }}
                  variant='contained' >{'中信银行建档信息推送'}</Button>
              }
              
              {
                (form.form_no === '3' &&  rolesId === 16 && form.distribute === '中信银行') &&
                <Button onClick={this.getCCBCustomerFilingResults} style={{ margin: '10px 0' }}
                  variant='contained' >{'中信银行建档结果查询'}</Button>
              }
              {/* {
                (form.form_no === '3' &&  rolesId === 16 && form.distribute === '中信银行') &&
                <Button onClick={this.ccbRzVerify} style={{ margin: '10px 0' }}
                  variant='contained' >{'中信银行融资推送'}</Button>
              } */}
              {
                (form.form_no === '3' &&  rolesId === 16 && form.distribute !== null && form.distribute.includes('保理')) &&
                <Button onClick={this.paymentRegistrationStatus} style={{ margin: '10px 0' }}
                  variant='contained' >{'融资人收款权限查询'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 16 && form.distribute === '光大银行' ) &&
                <Button onClick={this.financingAuthorizationQuery} style={{ margin: '10px 0' }}
                  variant='contained' >{'融资人授权书查询'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 12 && form.distribute === '北京银行' ) &&
                <Button onClick={this.bobZXSQ} style={{ margin: '10px 0' }}
                  variant='contained' >{'签订北京银行征信授权书'}</Button>
              }
              {
                (form.form_no === '3' && rolesId === 12 && form.distribute === '北京银行') &&
                <Button onClick={this.viewAgreementZX} style={{ margin: '10px 0' }}
                  variant='contained' >{'查看征信授权协议'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 12 && form.distribute === '北京银行' ) &&
                <Button onClick={this.bobRzVerify} style={{ margin: '10px 0' }}
                  variant='contained' >{'北京银行融资推送'}</Button>
              }
              
              {
                (form.form_no === '3' &&  (rolesId === 1 || rolesId === 3 ) ) &&
                <Button onClick={this.receiveRegistrationStatus} style={{ margin: '10px 0' }}
                  variant='contained' >{'接收人收款权限查询'}</Button>
              }
              
              {/*
                (form.form_no === '3' &&  rolesId === 6) &&
                <Button onClick={this.releasePaymentModel} style={{ margin: '10px 0' }}
                  variant='contained' >{'退回冻结金额释放'}</Button>   
              */}

              {
                (form.form_no === '5' && exercise.roleId === 14) &&
                <Button onClick={this.viewAgreement} style={{ margin: '10px 0' }}
                  variant='contained' >{'查看协议'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 12 && form.distribute === '光大银行') &&
                <Button onClick={this.uploadSubmit} style={{ margin: '10px 0' }}
                  variant='contained' >{'光大直融文件上送'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 12 && form.distribute === '光大银行') &&
                <Button onClick={this.onCebSkip} style={{ margin: '10px 0' }}
                  variant='contained' >{'跳转光大进行数字签名'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 12 && form.distribute === '光大银行') &&
                <Button onClick={this.cebFinancingSubmit} style={{ margin: '10px 0' }}
                  variant='contained' >{'光大直融发起'}</Button>
              }
              {
                (form.form_no === '3' &&  rolesId === 16 && form.distribute === '浦发银行') &&
                <Button onClick={this.spdRzVerify} style={{ margin: '10px 0' }}
                  variant='contained' >{'浦发融资推送'}</Button>
              }
              {
                (
                  form.form_no === '6' ||
                  (exercise.table === 'm3-1-1' && form.flow_date)) && <Grid item xs={12}>
                  <Grid container alignItems="flex-end">
                    <TextField
                      label={form2.verification_code}
                      value={form.verification_code}
                      name='verification_code'
                      onChange={this.handleChange2}
                      margin='normal'
                      disabled={false}
                    />
                    {second === 60 ?
                      <Button onClick={this.onVerificationCode} style={{ margin: ' 0  0 8px 10px' }}
                        variant='contained'>{form2.verification_code2}
                      </Button> :
                      <Button style={{ margin: ' 0  0 8px 10px' }}
                        variant='contained'>{second + form2.verification_code3}
                      </Button>}

                  </Grid>
                  {secondNumber !== '' && <div style={{ color: 'rgba(0, 0, 0, 0.42)', fontSize: '12px' }}>
                    {'验证码也发送到你的手机 ' + form.phone}
                  </div>}
                  <FormikError touched={touched} errors={errors} name={'verification_code'} />
                </Grid>
              }
              {
                form.form_no === '2' &&
                <FormControl style={{ width: '100%', margin: '10px 0' }}>
                  <InputLabel id="demo-simple-select-label">{form2.returnBy}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="returnBy"
                    value={values.returnBy}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {
                      userList.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              }
              {
                status(handleSubmit, values)
              }
              <Dialog maxWidth={'md'} onClose={this.viewAgreement} aria-labelledby="customized-dialog-title"
                open={this.state.open2}>
                <MuiDialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  id="customized-dialog-title">
                  <span>{form2.view_agreement}</span>
                </MuiDialogTitle>
                <MuiDialogContent style={{ minWidth: '600px', minHeight: '400px' }}>
                  <img src={imgUrl} alt='' />
                </MuiDialogContent>
                <Button onClick={this.viewAgreement} style={{ width: '100px', margin: '20px 0 20px 24px' }}
                  variant='contained'>返回</Button>
              </Dialog>
            </form >
          )
        }
      />

    )
  }
}

export default withStyles(useStyles)(Form1)
