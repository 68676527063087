import React, { Component } from 'react';
import {
  ViewComfy, Edit, Delete, AccessTimeOutlined
} from '@material-ui/icons'
import {
  Button
} from '@material-ui/core';
import { DownloadTimeForm, DialogForm } from '../../02-core_business/downloadTime';//时间选择弹窗--20201117
import moment from 'moment'
import MaterialTable from '../../vendors/material-table';
import { capitalize, fetching } from '../../config/utils';
import { core_business_url, getProcess } from '../core_business_url';
import { C51Form, C52Form, C53Form } from './index'
import { r5_1, r5_2, r5_3, common } from '../formStatic'
import { loginInfos } from '../../components/numToText'
import { alertDialog as AlertDialog } from '../../components';
import CheckForm from '../../components/commonForm/CheckForm';
import { DialogForm1, CommonDialog } from '../../components/commonForm'
import { formatDate, getNowFormatDate } from '../../components/date';
import { table } from '../common';

// copied from 05-.../DataView.js
class View extends Component {
  state = {
    isOpen:false,
    isLoading: true,
    pageData: {
      totalCount: 0,
      page: 0,
      pageSize: 5,
      orderBy: null
    },
    columns: [{}],
    data: [],
    hasToken: sessionStorage.getItem("authToken"),
    dataUrl: { url: { query: '' } },
    id: 10000,
    roleList: [],
    form2: { ...common },
    exercise: {
      save: '1',
      DialogTitle: '新增',
      open: false,
      status2: '1'
    },
    checkExercise: {},
    alertInfo: {
      open: false,
      content: '',
    },
  };
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  }
  
  stopTimer = () => {
    clearInterval(this.timer);
  };

  openDialog = () => {
    this.stopTimer(); // 停止定时器
  };

  closeDialog = () => {
    this.startTimer(); // 重新启动定时器
  }
  //获取数据
  componentDidMount() {
    const { table } = this.props;
    let auth = loginInfos();
    this.setState({ auth });
    //fetching('/api/roless/find', { method: 'GET' }).then(data => {
    fetching('/msapi/roles/find', { method: 'GET' }).then(data => {
      this.setState({ roleList: data || [] })
    })
    fetching('/table/' + table, { token: this.state.hasToken })
      .then(data => {
        if (data !== undefined && data !== null) {
          const columns = Object.keys(data).map(c => ({ title: data[c], field: c }));
          //根据不同的页面，获取不同的接口地址，渲染不同的数据
          const dataUrl = core_business_url(auth, table).find(item => item.name === table);
          const dataUrl2 = dataUrl || {};
          this.setState({ columns, dataUrl: dataUrl2 });
          this.onQuery();
          if(table === "r5-2" && this.state.auth.roles[0].id === 1){
           this.startTimer(); // 开始定时查询
          }
        }
      })
  }

  //查询数据
  onQuery = async() => {
    const { table } = this.props;
    const { roles = [{ id: 0 }] } = this.state.auth;
    const data_totalamount = [];//data_totalamount--替换集团客户可用额度
    try {
      const { url } = this.state.dataUrl;
      let fetchingData = [];
      if (table === 'r5-4'|| table === 'r5-5'|| table === 'r5-6') {//额度查询
        fetchingData = [fetching(url.query)]
      } else if (table === 'r5-1') {
        if (roles[0].id === 1) fetchingData = [fetching(url.query)]
        else if (roles[0].id === 3)fetchingData = [fetching(url.query1), fetching(url.query3)]
        else fetchingData = [fetching(url.query3)]
      } else if (table === 'r5-2') {//额度分配

        //替换集团客户可用额度
        /*let fetchingData_totalamount = [];
        fetchingData_totalamount = [await fetching('/msapi/totalamount/findc')];
        Promise.all(fetchingData_totalamount).then(values_totalamount => {
          values_totalamount.forEach(item => {
            if (item instanceof Array) {
              data_totalamount.push(item);
            }
          })
        });*/

        if (roles[0].id === 1) {
        if(this.state.isOpen){
      
      return
       
   }  
   
       this.setState({isOpen:true})
          const fetchPromise = fetching(url.query, {
            method: "post",
            body: JSON.stringify(this.state.pageData),
          }).then(res => {
            this.setState(prevState => ({
              pageData: {
                ...prevState.pageData,
                totalCount: res.data.pageData.totalCount,
                page: prevState.pageData.page + 1,
              },
              data: [...prevState.data, ...res.data.data],
              isLoading: (this.state.data.length + 5) < res.data.pageData.totalCount,
            })); 
            if (!this.state.isLoading) {
              this.clearTimer(); // 所有数据加载完成后清除定时器
            }
            console.log(this.state.pageData.page)
            this.setState({isOpen:false})
            return this.state.data;
          });
          fetchingData.push(fetchPromise);
        }
        else if (roles[0].id === 3) fetchingData = [fetching(url.query1), fetching(url.query3), fetching(url.query5)]
        else if (roles[0].id === 8) fetchingData = [fetching(url.query2), fetching(url.query3)]
        else fetchingData = [fetching(url.query3)]
      } else if (table === 'r5-3') {//额度回收
        if (roles[0].id === 1) fetchingData = [fetching(url.query)]
        else if (roles[0].id === 3) fetchingData = [fetching(url.query1), fetching(url.query3), fetching(url.query5)]
        else if (roles[0].id === 8) fetchingData = [fetching(url.query2), fetching(url.query3)]
        else fetchingData = [fetching(url.query3)]
      }
      Promise.all(fetchingData).then(values => {
        let data = []
        values.forEach(item => {
          if (item instanceof Array) {
            data = data.concat(item);
            //this.setState({ data }); 2021.01.12
          }
        })
        if(table === 'r5-2' || table === 'r5-3'){
          data.map(item =>{
            item.usable_amount=parseFloat(item.usable_amount.toFixed(2))
          })
        }
        if (table === 'r5-1') {//企业管理
          try {
            let allDate = []
            data.forEach(item => {
              let data1 = [];
              if (item.subsidiaries instanceof Array) {
                data1 = item.subsidiaries.map((items) => {
                  let item1 = {}
                  if (item.auth.status) {
                    item1.status = item.auth.status.name;
                  }
                  item1._id = item._id
                  item1.enterprise_name = item.enterprise_name
                  item1.enterprise_no = item.enterprise_no
                  //item1.timestamp = item.timestamp
                  item1.timestamp = moment(item.timestamp).format('YYYY-MM-DD')
                  item1.sub_company_name = items.sub_company_name
                  item1.sub_company_no = items.sub_company_no
                  item1.status_id = item.status_id
                  item1.role_id = item.role_id
                  return item1
                })
              }
              allDate = allDate.concat(data1);
              this.setState(prevState => ({
                pageData: {
                  ...prevState.pageData,
                  totalCount:allDate.length
                },
              }));
            })
            this.setState({ data: allDate });
          } catch (error) { }
        } else  if (table === 'r5-4' || table === 'r5-5'|| table === 'r5-6') {
          try {
            data = data.map(item => {
              if (item.organization) {
                item.enterprise_name = item.organization.name;
              }
              item.creditStartDate = formatDate(new Date(Date.parse(item.creditStartDate)), 'yyyy-MM-dd');
              item.creditDueDate = formatDate(new Date(Date.parse(item.creditDueDate)), 'yyyy-MM-dd');
              //item.credit_start_date = item.createdAt
              return item;
            })
            this.setState({ data });
          } catch (error) { }	 
        } else{
          try {
            data = data.map(item => {

              //data_totalamount--替换集团客户可用额度
              /*if (item.usable_amount && table === 'r5-2') {
                const listData = data_totalamount[0];
                for (var i = 0; i < listData.length; i++) {
                  if (listData[i].organizationId === item.enterprise_id) {
                    item.usable_amount = listData[i].usable_amount;
                    break;
                  }
                }
              }*/

              if (item.auth.status) {
                item.status = item.auth.status.name;
              }
              if (item.organization) {
                item.enterprise_name = item.organization.name;
              }
              if (item.role_id && item.status_id) {
                if (Number(item.status_id) === 7) {
                  item.role_name = getProcess(item.role_id, table, this.state.roleList).return_role_name;
                } else {
                  item.role_name = getProcess(item.role_id, table, this.state.roleList).role_name;
                }

              }
              return item;
            })
            this.setState({ data });
          } catch (error) { }
         }
        if(table == 'r5-3' || table == 'r5-4' || table == 'r5-5' || table === 'r5-6' || (table == 'r5-2' && roles[0].id != 1)){ // 对不需要优化的接口进行数据统计查询
          this.setState(prevState => ({
            pageData: {
              ...prevState.pageData,
              totalCount:data.length
            },
          }));
        }
      });
    } catch (e) { }
  }
  onRef = (ref) => {
    this.child = ref
  }


  FormSubmit2 = (newData) => {
	const { table } = this.props;
    if(table === 'r5-2' && this.state.auth.roles[0].id === 1){
      this.closeDialog()
    }
    newData.formDate = new Date();
    setTimeout(() => {
      if (newData.submit_name !== 'cancle') {
        this.onQuery();
      }
      this.setState({
        exercise: { ...this.state.exercise, open: false, checkOpen: false, applyOpen:false }
      })
    }, 500)
  }
  // 对象查找
  findObject = (obj, data) => {
    let index = data.findIndex(item => item.id === obj.id);
    return index;
  }

  handleToggle = (open) => {
    const { form2, exercise } = this.state
	const { table } = this.props;
    if (open) {
	  if(table === 'r5-2' && this.state.auth.roles[0].id === 1){
        this.closeDialog()
      }
      this.setState({
        exercise: { ...exercise, open: false, checkOpen: false, applyOpen:false }
      })
    } else {
	  if(table === 'r5-2' && this.state.auth.roles[0].id === 1){
		this.openDialog()
      }
      this.setState({
        exercise: { ...exercise, DialogTitle: form2.add, save: '1', open: true }
      })
    }
  }
  //删除
  onDelete = (e, row) => {
    
    this.setState({ alertInfo: { ...row, open: true, content: '确定删除该数据吗' } })
  };
  handleConfirm = (alertInfo) => {
    const table = this.props.table || '';
    const { dataUrl } = this.state
    if (alertInfo.flag) {
      if (table === "r5-3") {
        fetching(dataUrl.url.query4 + `${alertInfo._id}`, { method: 'POST' }).then(data => {
          this.setState({ alertInfo: { open: false, content: '' } })
          this.onQuery();
        })
      } else {
      fetching(dataUrl.url.get + `${alertInfo._id}`, { method: 'POST' }).then(data => {
        this.setState({ alertInfo: { open: false, content: '' } })
        this.onQuery();
      })
    }
    } else {
      this.setState({ alertInfo: { open: false, content: '' } })
    }
  }

  //编辑
  onUpdate = (e, row) => {
    const table = this.props.table || '';
    const { form2, dataUrl } = this.state
    if (table === 'r5-1') {
      fetching(dataUrl.url.query4 + `${row._id}`).then(data => {
        this.setState({
          exercise: { ...data, save: '2', DialogTitle: form2.view, open: true, }
        })
      })
    } else {
      this.setState({
        exercise: { ...row, save: '2', DialogTitle: form2.edit, open: true, }
      })
    }
  };

  //查看
  onview = (e, row) => {
    const table = this.props.table || '';
    const { form2, dataUrl } = this.state
    if (table === 'r5-1') {
      fetching(dataUrl.url.query4 + `${row._id}`).then(data => {
        this.setState({
          exercise: { ...data, save: '3', DialogTitle: form2.view, open: true, }

        })
      })
    } else {
   if(table === 'r5-2' && this.state.auth.roles[0].id === 1){
      this.openDialog()
      }
      this.setState({
        exercise: { ...row, save: '3', DialogTitle: form2.view, open: true, }
      })

    }
  }
  //审核
  onCheck = (e, row) => {
    const { form2, dataUrl } = this.state
    const table = this.props.table || '';

    if (table === 'r5-1') {
      fetching(dataUrl.url.query4 + `${row._id}`).then(data => {
        this.setState({
          exercise: { ...data, save: '3', DialogTitle: form2.check, checkOpen: true, }
        })
      })
    } else {
      this.setState({
        exercise: { ...row, save: '3', DialogTitle: form2.check, checkOpen: true, }
      });

    }
  }

  //搜索框Change
  onSearchChange = (query) => {
    console.log('onSearchChange', query);
  }
  //页面更改事件
   onChangePage = (query) => {
    this.setState(prevState => ({
      pageData: {
        ...prevState.pageData,
        page: query
      }
    }), () => {
      this.onQuery(); // 在状态更新后调用查询方法
    });
  }
  //处理每页的行更改（pageSize）
  onChangeRowsPerPage = (query) => {
    this.setState(prevState => ({
      pageData: {
        ...prevState.pageData,
        pageSize: query,
      },
    }));
  }
  componentWillUnmount () {
    this.clearTimer(); // 在组件卸载时清除定时器
  }
  startTimer = () => {
    this.timer = setInterval(this.onQuery, 500); // 500毫秒钟查询一次
  };


  clearTimer = () => {
    clearInterval(this.timer);
  };

  //触发打开时间段选项弹窗--20240407
  exportTableYWD = (e) => {
    const { exercise, form2, dataUrl } = this.state;
    //open: true--详情页；checkOpen: true--公共表单
    //type: 1-后端/全部；2-前端/指定公司
    this.setState({
      exercise: { ...exercise, save: '3', DialogTitle: form2.exportTable, open: false, tableName: dataUrl.name, status2: '1', checkFlag: true, type: 1 }
    })
  }

    //触发打开时间段选项弹窗--20240408
    handleToggleYWD = (open) => {
      const { form2 } = this.state
      if (open) {
        this.setState({
          exercise: { open: false }
        })
      window.location.reload()
      } else {
        this.setState({
          exercise: { ...this.state.exercise, status2: '1', DialogTitle: form2.add, save: '1', open: true }
        })
      }
    }



  render() {
    const { columns, data, dataUrl, exercise, auth, alertInfo,pageData } = this.state;
    const title = dataUrl.title || '';
    const path = capitalize(this.props.table);
    const actions = auth ? actions2(this, this.props.table, auth) : [];
    let roles = auth ? auth.roles : [{ id: 0 }];
    const table = this.props.table || '';
    //审核
    const Check = () => {
      let isBtn = false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <CommonDialog handleToggle={this.handleToggle} exercise={{ ...exercise, isBtn }}>
          <CheckForm dataUrl={dataUrl} auth={auth} exercise={{ ...exercise, table }}
            onSubmit={this.FormSubmit2}
          />
        </CommonDialog>
      </div>
    }
    //企业管理
    const C51 = () => {
      let isBtn = (roles[0].id === 1) ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <DialogForm1 handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName4, isBtn }}
        >
          <C51Form auth={auth} formStatic={{ ...common, ...r5_1 }} exercise={{ ...exercise, table }} dataUrl={dataUrl}
            onSubmit={this.FormSubmit2} />
        </DialogForm1>
      </div>
    }
    // 额度分配
    const C52 = () => {
      let isBtn = (roles[0].id === 1) ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <DialogForm1 handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName5, isBtn }}
        >
          <C52Form auth={auth} formStatic={{ ...common, ...r5_2 }} exercise={{ ...exercise, table }} dataUrl={dataUrl}
            onSubmit={this.FormSubmit2} />
        </DialogForm1>
      </div>
    }
    // 额度回收
    const C53 = () => {
      let isBtn = (roles[0].id === 1) ? true : false;
      return <div style={{ justifyContent: 'flex-end' }}>
        <DialogForm1 handleToggle={this.handleToggle}
          exercise={{ ...exercise, addBtnName: common.addBtnName6, isBtn }}
        >
          <C53Form auth={auth} formStatic={{ ...common, ...r5_3 }} exercise={{ ...exercise, table }} dataUrl={dataUrl}
            onSubmit={this.FormSubmit2} />
        </DialogForm1>
      </div>
    }

    //导出按钮--20240407
    const C6 = () => {
      return <div style={{ textAlign: 'end', marginBottom: '10px' }}>
        <Button variant="contained" color="primary" onClick={this.exportTableYWD}>导出</Button>
      </div>
    }
    //时间段选择弹窗--20240407
    const DownloadTime = () => {
      let isBtn = false;
       return <div className="dovbox" style={{ justifyContent: 'flex-end' }}>
         <DialogForm handleToggle={this.handleToggleYWD} exercise={{ ...exercise, addBtnName: common.exportTable, isBtn }}>
           <DownloadTimeForm form2={{ ...common }} exercise={{ ...exercise }} onSubmit={this.FormSubmit} />
         </DialogForm>
      </div>
    }

    return <div>
      {(table === 'r5-1') && <C51></C51>}
      {(table === 'r5-2') && <C52></C52>}
      {(table === 'r5-3') && <C53></C53>}
      {(table === 'r5-1' || table === 'r5-2' || table === 'r5-3') && <Check></Check>}
      {(table === 'r5-4' || table === 'r5-5') && <C6></C6>}
      {(table === 'r5-4' || table === 'r5-5') && <DownloadTime></DownloadTime>}
     
      <MaterialTable
        page={pageData.page}
        title={title}
        onSearchChange={this.onSearchChange} //搜索框
        // onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        path={path}
        columns={columns}
        data={data}
        options={options}
	actions={actions}
        localization={{
          body:{},
          pagination: {
            labelDisplayedRows: `每页 ${pageData.pageSize} 条 总计 ${pageData.totalCount} 条`,
          },
	header: {
            actions: '操作'
          }
        }}
      />
      <AlertDialog alertInfo={alertInfo} handleConfirm={this.handleConfirm}></AlertDialog>
    </div>
  }
}
const actions2 = (that, name, auth) => {
  const { roles = [{ id: 0 }] } = auth;
  let actions = [];
  let roleId = roles[0].id;
  let name2 = name;
  if (name === 'r5-1' && (roles[0].id !== 1)) {
    name2 = 'sh5-1';
  } else if (name === 'r5-2' && roles[0].id !== 1) {
    name2 = 'sh5-2';
  } else if (name === 'r5-3' && (roles[0].id !== 1)) {
    name2 = 'sh5-3';
  }
  switch (name2) {
    case 'r5-1':
      actions = [
        rowData => ({
          icon: Edit,
          tooltip: '编辑',
          onClick: that.onUpdate,
          hidden: (!(Number(rowData.status_id) === 7 && Number(rowData.role_id) === 3))
        }),
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        rowData => ({
          icon: Delete,
          tooltip: '删除',
          onClick: that.onDelete,
          // hidden: false
          hidden: (!(Number(rowData.status_id) === 7 && Number(rowData.role_id) === 3))
        }),
      ];
      break;
    case 'r5-2':
      actions = [
        rowData => ({
          icon: Edit,
          tooltip: '编辑',
          onClick: that.onUpdate,
          hidden: (!(Number(rowData.status_id) === 1 || (Number(rowData.status_id) === 7 && rowData.role_id === 3)))
        }),
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        rowData => ({
          icon: Delete,
          tooltip: '删除',
          onClick: that.onDelete,
          // hidden: false
          hidden: (!(Number(rowData.status_id) === 1 || (Number(rowData.status_id) === 7 && rowData.role_id === 3)))
        }),
      ];
      break;
    case 'r5-3':
      actions = [
        rowData => ({
          icon: Edit,
          tooltip: '编辑',
          onClick: that.onUpdate,
          hidden: (!(Number(rowData.status_id) === 1 || (Number(rowData.status_id) === 7 && rowData.role_id === 3)))
        }),
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        rowData => ({
          icon: Delete,
          tooltip: '删除',
          onClick: that.onDelete,
          hidden: (!(Number(rowData.status_id) === 1 || (Number(rowData.status_id) === 7 && rowData.role_id === 3)))
        }),
      ];
      break;
    case 'sh5-1':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        rowData => ({
          icon: Edit,
          tooltip: '审核',
          onClick: that.onCheck,
          hidden: ((Number(rowData.status_id) === 9))
        }),
      ];
      break;
    case 'sh5-2':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        rowData => ({
          icon: Edit,
          tooltip: '审核',
          onClick: that.onCheck,
          hidden: ((Number(rowData.status_id) === 9))
        }),
      ];
      break;
    case 'sh5-3':
      actions = [
        { icon: ViewComfy, tooltip: '查看', onClick: that.onview },
        rowData => ({
          icon: Edit,
          tooltip: '审核',
          onClick: that.onCheck,
          hidden: ((Number(rowData.status_id) === 9))
        }),
      ];
      break;
    default:
      actions = [];
  }
  return actions;
}

const localization = {
  header: {
    actions: '操作'
  }
}
const options = {
  pageSize: 5,
  paging: true,
  pagination: true,
  actionsCellStyle: { padding: '0 10px' },
  headerStyle: { //表格头部
    backgroundColor: '#1d4583',
    color: '#FFF',
    whiteSpace: 'nowrap'
  },
  rowStyle: { //表行
    backgroundColor: '#fff',
    whiteSpace: 'nowrap'
  },
  sorting: true,  //排序
  // selection: true //多选框
}
const setView = Comp => (collection, name) => {
  return class extends Component {
    render() {
      return <Comp table={collection} tableName={name} {...this.props} />
    }
  }
}

export const SetView = setView(View);

