import React, { Component } from 'react'
// import Select from 'react-select';
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
    TextField,
    Button,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,

} from '@material-ui/core'
import {
    ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

import { fetching } from '../../../config/utils';
import { HEADERS } from '../../../state/ActionTypes';
import Typography from '@material-ui/core/Typography';
import FormikError from '../../../components/commonForm/formikError';
import { guarantorFormFormik } from './formikValidate';
import { numberfun, commafy, delcommafy } from '../../../components/numToText';
// const { object } = require('yup');

const useStyles = (theme => ({
    formControl: {
        marginTop: '16px',
        marginBottom: '8px',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    width: '100%',
    label: {
        textAlign: 'left',
    },
    radio: {
        flexDirection: 'row',
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const styles = {
    width: '100%',
    margin: '16px 0 8px 0'
}

const guarantorType = ["单位", '个人']
class GuarantorForm extends Component {
    state = {
        guarantyWay: this.props.guarantyWay,
        formStatic: this.props.formStatic,
        form: this.getInitState() || {},
        isExpanded: false,
        // selectCustomerList: [],
    }
    // async componentWillMount() {
    //     const { guarantInfo } = this.props;
    //     if (guarantInfo.guarantor_type === '单位') {
    //         this.queryEnterpriseInfo();
    //     }

    // }
    //如果有异步设置state值的情况，在组件销毁时清除所有的state状态
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }
    queryEnterpriseInfo = () => {
        fetching('/mxbl/guarantor_information', {
            method: 'GET',
        }).then(res => {
            if (res) {
                try {
                    let datas = res.map((item) => {
                        const { guarantor_information_obj = {} } = item;
                        guarantor_information_obj.id = item._id ? item._id : null;
                        guarantor_information_obj.value = guarantor_information_obj.guarantor_enterprise;
                        guarantor_information_obj.label = guarantor_information_obj.guarantor_enterprise;
                        return guarantor_information_obj;
                    })
                    this.setState({ selectCustomerList: datas });
                } catch (error) {
                }
            } else {
            }
        })
    }

    getInitState() {
        const { guarantInfo = {} } = this.props;
        let form1 = {
            // select_customer: "",
            guarantor_type: "个人",
            guarantor_name: "",
            id_card: "",
            guarantee_amount: "",
            guarantee_period: "",
            guarantor_enterprise: "",
            legal_person: "",
            unified_social_credit_code: "",
        };
        try {
            if (guarantInfo.guarantor_id) {
                form1.id = guarantInfo.guarantor_id
                // guarantInfo.select_customer = { value: guarantInfo.guarantor_enterprise, label: guarantInfo.guarantor_enterprise }
            }
            if (guarantInfo.id) {
                form1.guarantor_id = guarantInfo.id
                // guarantInfo.select_customer = { value: guarantInfo.guarantor_enterprise, label: guarantInfo.guarantor_enterprise }
            }

        } catch (error) {
        }
        return { ...form1, ...guarantInfo };
    }

    // 提交
    handleSubmit = (values) => {
        const { guarantInfo = {} } = this.props;
        const id = guarantInfo.id || guarantInfo.guarantor_id || '';
        const { auth } = this.props
        let ids = id ? id : '';
        let method = id ? 'PUT' : 'POST';

        let params = {};
        if (values.guarantor_type === '单位') {
            let { id_card, guarantor_name, ...params1 } = values
            params = params1
        } else {
            let { guarantor_enterprise, legal_person, unified_social_credit_code, ...params1 } = values
            params = params1
        }
        params.auth = auth //JSON.stringify(auth)
        params.id = ids
        const amountList = ['guarantee_amount'];
        amountList.forEach(item => {
            params[item] = delcommafy(params[item]);
        })
        fetching(`/msapi/guarantor/save/${ids}`, {
            method,
            // token: this.state.hasToken,
            headers: HEADERS,
            body: JSON.stringify(params),
        }).then((data) => {
            try {
                if (ids) {
                    this.props.FormSubmit({ guarantInfo3: { ...params } })
                } else {
                    this.props.FormSubmit({ guarantInfo3: { ...params, ...data } })
                }
                this.setState({ isExpanded: false })
            } catch (error) {
                this.props.FormSubmit()
            }
        })
    }
    paneChange = (event, expanded) => {
        this.setState({ isExpanded: expanded })
    }
    //输入框值改变
    handleChange3 = (name, value) => {
        let value2 = value;
        if (name === 'guarantee_amount' || name === 'guarantee_period') {
            value2 = numberfun(value2, 12);
        }
        return value2;
    }
    //输入框失去焦点 金额 千分位
    handleBlur2 = (name, value) => {
        if (name === 'guarantee_amount') {
            return commafy(value);
        }
    }
    render() {
        const { form, isExpanded } = this.state;
        const { formStatic, guarantyWay, classes } = this.props;
        return (
            <Formik
                //初始化数据
                initialValues={{
                    ...form
                }}
                //表单验证
                validate={(values) => {
                    return guarantorFormFormik(values, formStatic);
                }}

                //验证提交
                onSubmit={(values) => {
                    this.handleSubmit(values);
                }}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                        <ExpansionPanel expanded={isExpanded} onChange={this.paneChange}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{guarantyWay.guaranty_way}信息</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div style={{ width: '100%' }}>
                                    <Grid container justify='space-between' alignItems='center'>
                                        <Grid item xs={5}>
                                            <FormControl component="fieldset" style={styles} className={classes.radio}>
                                                <FormLabel component="legend">{formStatic.guarantor_type}</FormLabel>
                                                <RadioGroup className={classes.radio} style={{ flexDirection: 'row' }} aria-label="gender" name='guarantor_type' value={values.guarantor_type}
                                                    onChange={e => setFieldValue('guarantor_type', e.target.value)}>
                                                    {guarantorType.map(item => {
                                                        return (
                                                            <FormControlLabel
                                                                key={item}
                                                                name={item}
                                                                value={item}
                                                                disabled={guarantyWay.save === '3'}
                                                                control={<Radio />}
                                                                label={item} />
                                                        )
                                                    })}
                                                </RadioGroup>
                                            </FormControl>
                                            <FormikError touched={touched} errors={errors} name={'guarantor_type'} />
                                        </Grid>
                                        {values.guarantor_type === '单位' ? (
                                            <Grid item xs={5}>
                                                <TextField
                                                    label={formStatic.guarantor_enterprise}
                                                    value={values.guarantor_enterprise}
                                                    name='guarantor_enterprise'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    margin='normal'
                                                    inputProps={{ maxLength: 50 }}
                                                    fullWidth
                                                    disabled={guarantyWay.save === '3'}
                                                />
                                                <FormikError touched={touched} errors={errors} name={'guarantor_enterprise'} />
                                            </Grid>
                                        ) : (
                                                <Grid item xs={5}>
                                                    <TextField
                                                        label={formStatic.guarantor_name}
                                                        value={values.guarantor_name}
                                                        name='guarantor_name'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        margin='normal'
                                                        inputProps={{ maxLength: 50 }}
                                                        fullWidth
                                                        disabled={guarantyWay.save === '3'}
                                                    />
                                                    <FormikError touched={touched} errors={errors} name={'guarantor_name'} />
                                                </Grid>
                                            )}
                                    </Grid>
                                    {values.guarantor_type === '单位' ? (
                                        <Grid container justify='space-between'>
                                            <Grid item xs={5}>
                                                <TextField
                                                    label={formStatic.legal_person}
                                                    value={values.legal_person}
                                                    name='legal_person'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    margin='normal'
                                                    inputProps={{ maxLength: 50 }}
                                                    fullWidth
                                                    disabled={guarantyWay.save === '3'}
                                                />
                                                <FormikError touched={touched} errors={errors} name={'legal_person'} />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextField
                                                    label={formStatic.unified_social_credit_code}
                                                    value={values.unified_social_credit_code}
                                                    name='unified_social_credit_code'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    margin='normal'
                                                    inputProps={{ maxLength: 50 }}
                                                    fullWidth
                                                    disabled={guarantyWay.save === '3'}
                                                />
                                                <FormikError touched={touched} errors={errors} name={'unified_social_credit_code'} />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                            <Grid container justify='space-between'>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label={formStatic.id_card}
                                                        value={values.id_card}
                                                        name='id_card'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        margin='normal'
                                                        inputProps={{ maxLength: 50 }}
                                                        fullWidth
                                                        disabled={guarantyWay.save === '3'}
                                                    />
                                                    <FormikError touched={touched} errors={errors} name={'id_card'} />
                                                </Grid>
                                            </Grid>
                                        )}

                                    <Grid container justify='space-between'>
                                        <Grid item xs={5}>
                                            <Grid container justify="space-between" alignItems='flex-end' >
                                                <Grid item xs={10}>
                                                    <TextField
                                                        label={formStatic.guarantee_amount}
                                                        value={values.guarantee_amount}
                                                        name='guarantee_amount'
                                                        onChange={e => setFieldValue('guarantee_amount', this.handleChange3.bind(this, 'guarantee_amount', e.target.value)())}
                                                        onBlur={e => setFieldValue('guarantee_amount', this.handleBlur2.bind(this, 'guarantee_amount', e.target.value)())}
                                                        margin='normal'
                                                        inputProps={{ maxLength: 50 }}
                                                        fullWidth
                                                        disabled={guarantyWay.save === '3'}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} style={{ marginBottom: '8px' }}>元</Grid>
                                            </Grid>
                                            <FormikError touched={touched} errors={errors} name={'guarantee_amount'} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid container justify="space-between" alignItems='flex-end' >
                                                <Grid item xs={10}>
                                                    <TextField
                                                        label={formStatic.guarantee_period}
                                                        value={values.guarantee_period}
                                                        name='guarantee_period'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        margin='normal'
                                                        type="number"
                                                        inputProps={{ maxLength: 50 }}
                                                        fullWidth
                                                        disabled={guarantyWay.save === '3'}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} style={{ marginBottom: '8px' }}>月</Grid>
                                            </Grid>
                                            <FormikError touched={touched} errors={errors} name={'guarantee_period'} />
                                        </Grid>
                                    </Grid>

                                    {guarantyWay.save !== '3' &&
                                        <div style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
                                            <Button variant="contained" color="primary" onClick={handleSubmit}>保存 </Button>
                                        </div>
                                    }
                                </div>

                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )}
            />

        )
    }
}

export default withStyles(useStyles)(GuarantorForm)
